import { Component, OnInit, ViewChild } from '@angular/core';


import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
const EXCEL_EXTENSION = '.xlsx';

import * as jsPDF from 'jspdf';
import 'jspdf-autotable';
import { UserOptions } from 'jspdf-autotable';
import { Searchbydate } from 'src/app/models/searchbydate';
import { DatePipe } from '@angular/common';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { MatTableDataSource, MatPaginator, MatDialog, MatSnackBar } from '@angular/material';
import { NGXLogger } from 'ngx-logger';
import { NgxPermissionsService } from 'ngx-permissions';
import { Subscription } from 'rxjs';
import { ConfirmationService } from 'src/app/confirm/confirmation.service';
import { Statvente, Statperiod, Itme } from 'src/app/models/statperiod';
import { ApploaderService } from 'src/app/services/apploader/apploader.service';
import { GenericserviceService } from 'src/app/services/genericservice.service';
import { StatproductService } from '../statistiqueproduct/statproduct.service';
import { StatperiodiqueService } from '../statperiodique/statperiodique.service';

interface jsPDFWithPlugin extends jsPDF {
  autoTable: (options: UserOptions) => jsPDF;
}

@Component({
  selector: 'fury-statistiquevente',
  templateUrl: './statistiquevente.component.html',
  styleUrls: ['./statistiquevente.component.scss']
})
export class StatistiqueventeComponent implements OnInit {

 
  globalstat: boolean = false;
  periodestat: boolean = false;
  global: Statvente[] = [];
  periode: Statperiod;

  public getItemSub: Subscription;
  public datePaiementForm: FormGroup;
  itemPeriode: any[];

  public statPeriodForm: FormGroup;
  public statBydateForm: FormGroup;

  periodefinal: string;

  resultatstat: Statvente[];

  form: FormGroup;


  displayedColumns: string[] = [ 'dateheure', 'numcommande', 'cmdtotal', 'produit', 'qte' , 'pu', 'caisse','paiement','livraison' ];

  dataSource = new MatTableDataSource<Itme>([]);

  @ViewChild(MatPaginator) paginator: MatPaginator;

  constructor(private dialog: MatDialog, public formBuilder: FormBuilder,
    private snackbar: MatSnackBar, private statserv: StatperiodiqueService,
    private datePipe: DatePipe, private agentService: GenericserviceService,
    private confirmService: ConfirmationService, private loader: ApploaderService,
    private permissionsService: NgxPermissionsService, private logger: NGXLogger,
    private statservice: StatproductService, private fb: FormBuilder) { }

  ngOnInit() {

    this.getAllPeriode();



    this.statPeriodForm = new FormGroup({
      periode: new FormControl('', Validators.required),
      type: new FormControl('periode', Validators.required)
    });

    this.statBydateForm = new FormGroup({
      debut: new FormControl('', Validators.required),
      fin: new FormControl('', Validators.required),
      type: new FormControl('date', Validators.required)
    });
  }




  getAllPeriode() {

    this.statserv.getJSON('assets/data/periode.json').subscribe(data => {
      this.logger.trace(" all icon : ", data);
      this.itemPeriode = data;
    },
      error => {
        switch (error.status) {
          default: {
            break;
          }
        }
      });
  }



   getStatByDate() {
    if (this.statBydateForm.valid) {

      this.periodefinal = "";
      this.resultatstat = [];
      this.dataSource.data = [];
      this.dataSource.paginator = this.paginator;

      let to: string = "";
      let from: string = "";
      let date = new Date(this.statBydateForm.get("debut").value);
      let date1 = new Date(this.statBydateForm.get("fin").value);

      if ((date.toString() != 'Invalid Date')) {
        from = this.statBydateForm.get("debut").value;
      }

      if (date1.toString() != null) {
        to = this.statBydateForm.get("fin").value;
      }

      let newDate = new Date(from);

      let newDate1 = new Date(to);

      let request: any = this.statBydateForm.value;

      if ((newDate.toDateString() !== "Invalid Date") && (newDate1.toDateString() !== "Invalid Date")) {
        //;
        request.debut = this.datePipe.transform(newDate, "yyyy-MM-dd HH:mm:ss");
        request.fin = this.datePipe.transform(newDate1, "yyyy-MM-dd HH:mm:ss");

        this.logger.info(request);
        this.statserv.post(request, "stats/statwithpaiement").subscribe(data => {
          this.logger.trace('DATA', data);

          switch (data.status) {

            case 200: {

              console.log("data");
              console.log(data);
              this.resultatstat = data.body;
              this.dataSource.data = data.body;
              this.dataSource.paginator = this.paginator;

              this.periodefinal = request.debut + " au " + request.fin;
              this.loader.close();
              this.snackbar.open(' succes ', 'OK', {
                duration: 10000
              });

              break;
            }

          }
        },
          error => {
            this.logger.error('ERREUR', error);
            switch (error.status) {


              default: {
                this.loader.close();

                this.snackbar.open('Echec !! Une erreur est survenue ', 'OK', {
                  duration: 10000
                });
                break;
              }
            }
          });


      }



    }
  }




   getStatByPeriode() {
    if (this.statPeriodForm.valid) {
      this.resultatstat = [];
      this.periodefinal = "";
      this.dataSource.data = [];
      this.dataSource.paginator = this.paginator;
      let request: any = this.statPeriodForm.value;
      this.statserv.post(request, "stats/statwithpaiement").subscribe(data => {
        this.logger.trace('DATA', data);

        switch (data.status) {

          case 200: {

            console.log("data");
            console.log(data);
            this.resultatstat = data.body;
            this.dataSource.data = data.body;
            this.dataSource.paginator = this.paginator;

            //this.periodefinal = this.resultatstat.debut + " au " + this.resultatstat.fin;
            this.loader.close();
            this.snackbar.open(' succes ', 'OK', {
              duration: 10000
            });

            break;
          }

        }
      },
        error => {
          this.logger.error('ERREUR', error);
          switch (error.status) {


            default: {
              this.loader.close();

              this.snackbar.open('Echec !! Une erreur est survenue ', 'OK', {
                duration: 10000
              });
              break;
            }
          }
        });
    }
  }



  
  public exportXls() {
    if (this.resultatstat && this.resultatstat.length > 0) {
      let itemscsv: any[] = [];

      this.resultatstat.forEach(function (element) {
        var my_json = {
          "Date ": element.dateheure,
          "num_commande": element.numcommande,
          "prix_commande": element.cmdtotal,
          "produit": element.produit,
          "quantite": element.qte,
          "prix_produit": element.pu,
          "Caisses": element.caisse,
          "mod_paiement": element.paiement,
          "livraisonmodename": element.livraison
        };

        console.log("my_json");
        console.log(my_json);
        itemscsv.push(my_json);
      });

      this.exportAsExcelFile(itemscsv, 'statistique-vente');

    }
  }




  public exportAsExcelFile(json: any[], excelFileName: string): void {
    const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(json);
    const workbook: XLSX.WorkBook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
    const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
    this.saveAsExcelFile(excelBuffer, excelFileName);
  }
  private saveAsExcelFile(buffer: any, fileName: string): void {
    const data: Blob = new Blob([buffer], { type: EXCEL_TYPE });
    FileSaver.saveAs(data, fileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION);
  }
 

}
