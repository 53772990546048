import { Component, Inject, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormArray } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef, MatSnackBar } from '@angular/material';
import { NGXLogger } from 'ngx-logger';
import { Subscription } from 'rxjs';
import { Categories } from 'src/app/models/categories';
import { Foods } from 'src/app/models/foods';
import { MenuService } from 'src/app/pages/gestionmenu/menu.service';

@Component({
  selector: 'fury-article-reduction',
  templateUrl: './article-reduction.component.html',
  styleUrls: ['./article-reduction.component.scss']
})
export class ArticleReductionComponent implements OnInit {

  itemCategories: Categories[] = [];
  public items: Foods[] = [];
  public itemSelected: Foods[] = [];

  public getItemSub: Subscription;
  form: FormGroup;

  profils: any[] = [];

  constructor(
    @Inject(MAT_DIALOG_DATA) public defaults: any,
    public dialogRef: MatDialogRef<ArticleReductionComponent>,
    private fb: FormBuilder, private menuservice: MenuService,
    private snackbar: MatSnackBar,
    private logger: NGXLogger
  ) { }

  ngOnInit() {
    this.getallCategories();

    this.form = this.fb.group({
      abtmntlib: ['', Validators.required],
      typecode: ['2', Validators.required],
      type: ['article', Validators.required],
      profilcode: ['', Validators.required],
      categorie: [''],
      linkmenuarticle: this.fb.array([])
    });

    this.getallProfil();

    this.form.get('categorie').valueChanges.subscribe(data => {
      this.getallFood(data);
    });

  }

  get linkmenuarticle(): FormArray {
    return this.form.get("linkmenuarticle") as FormArray
  }

  addLinkmenuarticle(articleid, article, catid) {
    this.linkmenuarticle.push(this.newLinkmenuarticle(articleid, article, catid));
  }

  newLinkmenuarticle(articleid, article, catid): FormGroup {
    return this.fb.group({
      'articleid': [articleid, Validators.required],
      'montant': [0, Validators.required],
      'article': [article, Validators.required],
      'categorieid': [catid, Validators.required],

    })
  }


  addToCart(value: Foods) {
    if (this.itemSelected != null && this.itemSelected.length > 0) {
      let check: boolean = false;
      this.itemSelected.forEach(element => {
        if (element.foodid === value.foodid || element.categorie.categorieid === value.categorie.categorieid ) {
          check = true;
        }
      });
      if (!check) {
        this.itemSelected.push(value);
        this.addLinkmenuarticle(value.foodid, value.foodname, value.categorie.categorieid);
      }
    } else {
      this.itemSelected = [];
      this.itemSelected.push(value);
      this.addLinkmenuarticle(value.foodid, value.foodname, value.categorie.categorieid);

    }
  }


  deleteToCart(value: Foods) {
    let itemsToCheck: any[] = [];
    if (this.itemSelected != null && this.itemSelected.length > 0) {
      this.itemSelected.forEach(element => {
        if (element.foodid !== value.foodid) {
          itemsToCheck.push(element);
        }
      });
      this.itemSelected = itemsToCheck;
    }
  }

  saveMenu() {
    const benFormArray = this.form.get('linkmenuarticle') as FormArray;
    let items: any[] = [];
    let dataCategorie: any[] = [];

    for (let index = 0; index < benFormArray.length; index++) {
      const element = benFormArray.controls[index] as FormGroup;
      let it: any = {};
      if (element.invalid) {
        let montantFormControl = element.controls.montant;
        if (montantFormControl.invalid) {
          this.snackbar.open('Veuillez renseigner correctement tous les montants ', 'OK', {
            duration: 10000
          });
          return;
        }
      }
      console.log(element.value);
      let p: any = {};
      p.articleid = element.value.articleid;
      p.article = element.value.article;
      p.montant = element.value.montant;
      items.push(p);
      dataCategorie.push(element.value.categorieid);
    }
   

    let request: any = this.form.value;
    request.linkmenuarticle = items;
    console.log(request);
    this.dialogRef.close(request);
  }

  getallProfil() {
    this.getItemSub = this.menuservice.getAllFoodByCatid("typecarte/enable")
      .subscribe(data => {

        let responseany: any = data;

        this.logger.info("------------------------");
        this.logger.trace(responseany);
        this.logger.info("------------------------");

        switch (data.status) {

          case 200: {
            this.profils = data.body;
            break;
          }





        }
      },
        error => {
          console.log("error");
          console.log(error);

          switch (error.status) {

            default: {

              break;
            }
          }
        });
  }



  getallCategories() {
    this.getItemSub = this.menuservice.getAllCategories()
      .subscribe(data => {

        let responseany: any = data;

        this.logger.trace(responseany);

        switch (data.status) {

          case 200: {
            this.itemCategories = data.body;
            break;
          }

          default: {


            let response: any = data.body;
            this.itemCategories = response;



            break;
          }



        }
      },
        error => {
          console.log("error");
          console.log(error);

          switch (error.status) {

            default: {

              break;
            }
          }
        });
  }


  getallFood(id) {
    this.getItemSub = this.menuservice.getAllFoodByCatid("parameter/food/bycategoriecode/" + id)
      .subscribe(data => {

        let responseany: any = data;

        this.logger.trace(responseany);

        switch (data.status) {

          case 200: {
            this.items = data.body;
            break;
          }





        }
      },
        error => {
          console.log("error");
          console.log(error);

          switch (error.status) {

            default: {

              break;
            }
          }
        });
  }


}
