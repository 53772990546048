import { Component, OnInit } from '@angular/core';
import { Historiq } from 'src/app/models/Agent';
import { Annulationdto } from 'src/app/models/annulationdto';

import { MatTableDataSource, MatDialog, MatSnackBar } from '@angular/material';
import { Subscription } from 'rxjs';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';
import { AttenteService } from '../attente/attente.service';
import { DatePipe } from '@angular/common';
import { ConfirmationService } from 'src/app/confirm/confirmation.service';
import { ApploaderService } from 'src/app/services/apploader/apploader.service';
import { NgxPermissionsService } from 'ngx-permissions';
import { NGXLogger } from 'ngx-logger';
import { ConfigService } from 'ngx-envconfig';
import { AnnulationService } from './annulation.service';
import { ShowComponent } from './show/show.component';


import * as XLSX from 'xlsx';
import * as FileSaver from 'file-saver';


const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
const EXCEL_EXTENSION = '.xlsx';


@Component({
  selector: 'fury-annulation',
  templateUrl: './annulation.component.html',
  styleUrls: ['./annulation.component.scss']
})
export class AnnulationComponent implements OnInit {

  scrollBarHorizontal = (window.innerWidth < 1200);


  cumul : number = 0 ;
  ca : number = 0 ;

  public items: Historiq[];
  public itemsFinal: Historiq[];
  temp = [];
  rows = [];
  columns = [];
  public commands: Array<Historiq> = [];
  dataSource: MatTableDataSource<Historiq> = new MatTableDataSource([]);

  public getItemSub: Subscription;

  public itemForm: FormGroup;

  constructor(private dialog: MatDialog, public formBuilder: FormBuilder,
    private snackbar: MatSnackBar, private annulationserv: AnnulationService,
    private datePipe: DatePipe,
    private confirmService: ConfirmationService, private loader: ApploaderService,
    private permissionsService: NgxPermissionsService, private logger: NGXLogger,
    private configService: ConfigService, private fb: FormBuilder) { }


  ngOnInit() {

    this.itemForm = this.fb.group({
      to: ['', Validators.compose([Validators.required])],
      from: ['', Validators.compose([Validators.required])],
    });
    
    this.getallCommandes();
  }


  getallCommandes() {

    let request : Annulationdto = new Annulationdto();
    request.date=false ;
    request.type="all";
    this.getItemSub = this.annulationserv.getAllCommandes(request)
      .subscribe(data => {

        let responseany: any = data;

        console.log(responseany);
        this.logger.trace('DATA', data);

        switch (data.status) {

          default: {
            let response: any = data.body;
            let resp1: Historiq[] = response;

            let respfinal: Historiq[] = [];
            for (let rp of resp1) {
              if (rp.client != null) {
                rp.clientname = rp.client.clientemail;
              }
              if (rp.agent != null) {
                rp.agentname = rp.agent.agentemail;
              }
              respfinal.push(rp);
            }

            this.items = this.temp = respfinal;
            this.itemsFinal = respfinal;
            this.cumulAndChiffreAffaire();

            this.dataSource = new MatTableDataSource(this.items);
            break;
          }


        }
      },
        error => {
          console.log("error");
          console.log(error);

          switch (error.status) {

            default: {
              let response: any = [];
              let resp1: Historiq[] = response;
              let respfinal: Historiq[] = [];
              this.items = this.temp = respfinal;
              this.dataSource = new MatTableDataSource(this.items);
              break;
            }
          }
        });
  }

  updateFilter(event) {
    const val = event.target.value.toLowerCase();
    var columns = Object.keys(this.temp[0]);
    // Removes last "$$index" from "column"
    columns.splice(columns.length - 1);

    // console.log(columns);
    if (!columns.length)
      return;

    const rows = this.temp.filter(function (d) {
      for (let i = 0; i <= columns.length; i++) {
        let column = columns[i];
        // console.log(d[column]);
        if (d[column] && d[column].toString().toLowerCase().indexOf(val) > -1) {
          return true;
        }
      }
    });

    this.items = rows;
  }





  
  opendetail(value: any) {

    this.dialog.open(ShowComponent,
      {
        width: '720px',
        data: { title: '', payload: value }
      }
    ).afterClosed().subscribe((res) => {
      console.log(res);
      if (!res) {
        return;
      }
    });
  }




  searchCommandeByDelivery() {

    let to: string = "";
    let from: string = "";
    let date = new Date(this.itemForm.get("from").value);
    let date1 = new Date(this.itemForm.get("to").value);

    if ((date.toString() != 'Invalid Date')) {
      from = this.itemForm.get("from").value;
    }

    if (date1.toString() != null) {
      to = this.itemForm.get("to").value;
    }

    let newDate = new Date(from);

    let newDate1 = new Date(to);

    if ((newDate.toDateString() === "Invalid Date") || (newDate1.toDateString() === "Invalid Date")) {
      this.snackbar.open('Format de date invalide ', 'OK', { duration: 4000 });
    } else {
      if (newDate1 >= newDate) {
        let request : Annulationdto = new Annulationdto();
        request.date=true ;
        request.type="all";
        request.debut = this.datePipe.transform(newDate, "yyyy-MM-dd HH:mm:ss");
        request.fin = this.datePipe.transform(newDate1, "yyyy-MM-dd HH:mm:ss");
        
        this.getItemSub = this.annulationserv.getAllCommandes(request)
          .subscribe(data => {
    
            let responseany: any = data;
    
            console.log(responseany);
            this.logger.trace('DATA', data);
    
            switch (data.status) {
    
              default: {
                let response: any = data.body;
                let resp1: Historiq[] = response;
    
                let respfinal: Historiq[] = [];
                for (let rp of resp1) {
                  if (rp.client != null) {
                    rp.clientname = rp.client.clientemail;
                  }
                  if (rp.agent != null) {
                    rp.agentname = rp.agent.agentemail;
                  }
                  respfinal.push(rp);
                }
    
                this.items = this.temp = respfinal;
                this.itemsFinal = respfinal;
                this.cumulAndChiffreAffaire();
                this.dataSource = new MatTableDataSource(this.items);
                break;
              }
    
    
            }
          },
            error => {
              console.log("error");
              console.log(error);
    
              switch (error.status) {
    
                default: {
                  let response: any = [];
                  let resp1: Historiq[] = response;
                  let respfinal: Historiq[] = [];
                  this.items = this.temp = respfinal;
                  this.dataSource = new MatTableDataSource(this.items);
                  break;
                }
              }
            });
      }else{
        this.snackbar.open('Format de date invalide ', 'OK', { duration: 4000 });

      }

    }

  }






  cumulAndChiffreAffaire(){
    this.cumul=0;
    this.ca=0;
    for (const item of this.items) {
      this.cumul=this.cumul+1;
      this.ca=this.ca+item.commandtotalpricepayer;
    }
  }







  exportToXls() {
    let itemscsv: any[] = [];


    if (this.items.length > 0) {
      this.items.forEach(function (element) {
        console.log(element);

        var my_json = {
          "Reference commande": element.commandreference,
          "Total": element.commandtotalpricepayer,
          "Client": element.client.clientemail,
          "Date annulation ": element.commanddateannulation
        };
        console.log("my_json");
        console.log(my_json);
        itemscsv.push(my_json);
      });
      console.log(itemscsv);

      this.exportAsExcelFile(itemscsv, 'Historique Annulation');

    }
  }
  public exportAsExcelFile(json: any[], excelFileName: string): void {
    const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(json);
    const workbook: XLSX.WorkBook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
    const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
    this.saveAsExcelFile(excelBuffer, excelFileName);
  }
  private saveAsExcelFile(buffer: any, fileName: string): void {
    const data: Blob = new Blob([buffer], { type: EXCEL_TYPE });
    FileSaver.saveAs(data, fileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION);
  }



}
