import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EnabledemandeComponent } from './enabledemande/enabledemande.component';
import { ShowenabledemandeComponent } from './enabledemande/showenabledemande/showenabledemande.component';
import { ReactiveFormsModule } from '@angular/forms';
import { MatChipsModule, MatTableModule, MatPaginatorModule, MatSelectModule, MatListModule, MatDatepickerModule, MatFormFieldModule } from '@angular/material';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { FurySharedModule } from 'src/@fury/fury-shared.module';
import { BreadcrumbsModule } from 'src/@fury/shared/breadcrumbs/breadcrumbs.module';
import { FuryCardModule } from 'src/@fury/shared/card/card.module';
import { ListModule } from 'src/@fury/shared/list/list.module';
import { MaterialModule } from 'src/@fury/shared/material-components.module';
import { QuickInfoWidgetModule } from '../dashboard/widgets/quick-info-widget/quick-info-widget.module';
import { DisabledemandeComponent } from './disabledemande/disabledemande.component';
import { GivecarddemandeComponent } from './givecarddemande/givecarddemande.component';
import { DemandestatusComponent } from './demandestatus/demandestatus.component';

@NgModule({
  declarations: [EnabledemandeComponent, ShowenabledemandeComponent, DisabledemandeComponent, GivecarddemandeComponent, DemandestatusComponent],
  entryComponents: [ShowenabledemandeComponent , DemandestatusComponent],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    MatChipsModule,
    ListModule,
    MatTableModule,
    MatPaginatorModule,
    MatSelectModule,
    MatListModule,
    MatChipsModule,
    MaterialModule,
    BreadcrumbsModule,
    NgxDatatableModule,
    FuryCardModule,
    MatChipsModule,
    QuickInfoWidgetModule,
    MatDatepickerModule,
    MatFormFieldModule,
    FurySharedModule,
  ],
  
})
export class DemandedrhModule { }
