import { Component, OnInit, ViewChild } from '@angular/core';
import { Pointvente } from 'src/app/models/pointvente';
import { MatTableDataSource, MatPaginator, MatDialog, MatSnackBar } from '@angular/material';
import { FormBuilder } from '@angular/forms';
import { DatePipe } from '@angular/common';
import { ConfirmationService } from 'src/app/confirm/confirmation.service';
import { ApploaderService } from 'src/app/services/apploader/apploader.service';
import { NgxPermissionsService } from 'ngx-permissions';
import { NGXLogger } from 'ngx-logger';
import { PointventeService } from './pointvente.service';
import { Subscription } from 'rxjs';
import { AddComponent } from './add/add.component';
import { UpdateComponent } from './update/update.component';
import { Agentstorage } from 'src/app/models/agentstorage';
import { Rolefonction } from 'src/app/models/rolefonction';
import { ConfigService } from 'ngx-envconfig';

@Component({
  selector: 'fury-gestionpointvente',
  templateUrl: './gestionpointvente.component.html',
  styleUrls: ['./gestionpointvente.component.scss']
})
export class GestionpointventeComponent implements OnInit {

  displayedColumns: string[] = ['pointventename', 'pointventecode', 'pointventedatecreation', 'actions'];
  dataSource = new MatTableDataSource<Pointvente>([]);
  @ViewChild(MatPaginator) paginator: MatPaginator;
  public getItemSub: Subscription;
  pointvente: Pointvente[] = [];

  public afficherPointvente: boolean;
  public creationPointvente: boolean;
  public modificationPointvente: boolean;
  public suppressionPointvente: boolean;

  public keyAfficherPointvente = 'afficher point vente';
  public keyCreationPointvente = 'ajouter point vente';
  public keyModificationPointvente = 'modifier point vente';
  public keySuppressionPointvente = 'supprimer point vente';

  constructor(private dialog: MatDialog, public formBuilder: FormBuilder,
    private snackbar: MatSnackBar, private datePipe: DatePipe,
    private configService: ConfigService,
    private confirmService: ConfirmationService, private loader: ApploaderService,
    private permissionsService: NgxPermissionsService, private logger: NGXLogger,
    private pointventeservice: PointventeService) { }

  ngOnInit() {
    this.verifierDroit();
    this.getallPointvente();
  }



  getallPointvente() {
    this.getItemSub = this.pointventeservice.get("pointvente/getall")
      .subscribe(data => {

        let statuscode = data.status;
        this.logger.trace('DATA', data);

        switch (statuscode) {

          case 200: {
            this.pointvente = data.body;

            this.dataSource.data = this.pointvente;;
            this.dataSource.paginator = this.paginator;
            //this.temp = data.body;
            break;
          }

          case 204: {
            //this.snack.open('Aucune TVA disponible', 'OK', { duration: 4000 })
            break;
          }

          default: {
            // this.snack.open('Une erreur est survenue!', 'OK', { duration: 4000 })

            break;
          }

        }
      },
        error => {
          this.logger.error("error");
          this.logger.error(error);

          switch (error.status) {

            default: {

              //    this.snack.open('Une erreur est survenue!', 'OK', { duration: 4000 })

              break;
            }
          }
        });
  }



  createPointvente() {
    
    this.dialog.open(AddComponent, { width: '720px' }).afterClosed().subscribe((res) => {
      console.log(res);

      if (!res) {
        return;
      }

      let request: any = res;
      let u = new Agentstorage();
      u = JSON.parse(localStorage.getItem("curentuser"));
      if (u != null) {
        request.userpisteaudit = u.agent.agentemail + " " + u.agent.agentfirstname + " " + u.agent.agentlastname;
      }
      this.pointventeservice.post(request, "pointvente/add").subscribe(data => {
        this.logger.trace('DATA', data);


        switch (data.status) {

          case 201: {

            console.log("data");
            console.log(data);

            this.getallPointvente();
            this.loader.close();
            this.snackbar.open('Point de vente crée avec succes ', 'OK', {
              duration: 10000
            });

            break;
          }

        }
      },
        error => {
          this.logger.error('ERREUR', error);
          switch (error.status) {

            case 409: {
              //this.getallFood();
              this.loader.close();
              this.snackbar.open('Ce point de vente existe deja ', 'OK', {
                duration: 10000
              });
              break;
            }

            default: {
              this.loader.close();

              this.snackbar.open('Echec !! Une erreur est survenue ', 'OK', {
                duration: 10000
              });
              break;
            }
          }
        });
    });
  }

  deleteItem(row) {
    this.confirmService.confirm({
      message: `Voulez vous supprimer le point de vente : ${row.pointventename}?  `
    })
      .subscribe(res => {
        if (res) {

          console.log("resultat obtenu ");
          console.log(row);

          let u = new Agentstorage();
          u = JSON.parse(localStorage.getItem("curentuser"));
          if (u != null) {
            row.userpisteaudit = u.agent.agentemail + " " + u.agent.agentfirstname + " " + u.agent.agentlastname;
          }

          this.pointventeservice.post(row, "pointvente/delete").subscribe(data => {

            this.getallPointvente();
            this.snackbar.open('Success ', 'OK', {
              duration: 10000
            });

          },
            error => {

              this.snackbar.open('Echec !! Une erreur est survenue ', 'OK', {
                duration: 10000
              });

              console.log("erreur retour");
              console.log(error);

            }
          );

        }

      })
  }

  openPopUp(value) {
    this.dialog.open(UpdateComponent,
      {
        //disableClose: true,
        width: '720px',
        data: { title: '', payload: value }
      }
    ).afterClosed().subscribe((res) => {
      console.log(res);

      if (!res) {
        return;
      }

      let request: any = res;

      let u = new Agentstorage();
      u = JSON.parse(localStorage.getItem("curentuser"));
      if (u != null) {
        request.userpisteaudit = u.agent.agentemail + " " + u.agent.agentfirstname + " " + u.agent.agentlastname;
      }

      this.pointventeservice.post(request, "pointvente/update").subscribe(data => {
        this.logger.trace('DATA', data);


        switch (data.status) {

          case 200: {

            console.log("data");
            console.log(data);

            this.getallPointvente();
            this.loader.close();
            this.snackbar.open('Point de vente mis a jour avec succes ', 'OK', {
              duration: 10000
            });

            break;
          }

        }
      },
        error => {
          this.logger.error('ERREUR', error);
          switch (error.status) {

            case 409: {
              //this.getallFood();
              this.loader.close();
              this.snackbar.open('Ce point de vente existe deja ', 'OK', {
                duration: 10000
              });
              break;
            }

            default: {
              this.loader.close();

              this.snackbar.open('Echec !! Une erreur est survenue ', 'OK', {
                duration: 10000
              });
              break;
            }
          }
        });

    });
  };




  public verifierDroit() {
    this.logger.trace('BEGIN');
    // this.logger.trace('Clé', this.configService.get('keylocalstorage'));

    let agentOnline: Agentstorage;
    agentOnline = JSON.parse(localStorage.getItem(this.configService.get('keylocalstorage')));
    // this.logger.trace(agentOnline);
    let rolefontion: Rolefonction[] = agentOnline.rolefonction;
    // this.logger.trace('rolefontion', rolefontion);

    for (const key in rolefontion) {
      if (rolefontion.hasOwnProperty(key)) {
        // this.logger.trace('KEY', key);
        const element = rolefontion[key].fonction.fonctionname;
        this.logger.trace('element', element);

        if (element == this.keyAfficherPointvente) {
          this.logger.trace('true');
          this.afficherPointvente = true;
        }
        if (element == this.keyCreationPointvente) {
          this.logger.trace('true');
          this.creationPointvente = true;
        }
        if (element == this.keyModificationPointvente) {
          this.logger.trace('true');
          this.modificationPointvente = true;
        }
        if (element == this.keySuppressionPointvente) {
          this.logger.trace('true');
          this.suppressionPointvente = true;
        }
      }
    }

  }

}
