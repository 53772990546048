import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HistodecouvertComponent } from './histodecouvert.component';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { MatInputModule, MatChipsModule, MatSelectModule, MatListModule } from '@angular/material';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { BreadcrumbsModule } from 'src/@fury/shared/breadcrumbs/breadcrumbs.module';
import { FuryCardModule } from 'src/@fury/shared/card/card.module';
import { ListModule } from 'src/@fury/shared/list/list.module';
import { MaterialModule } from 'src/@fury/shared/material-components.module';

@NgModule({
  declarations: [HistodecouvertComponent],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    MaterialModule,
    BreadcrumbsModule,
    MatInputModule,
    FormsModule,
    MatChipsModule,
    ListModule,
    MatSelectModule,
    MatListModule,
    NgxDatatableModule,
    BrowserAnimationsModule,
    FuryCardModule
  ]
})
export class HistodecouvertModule { }
