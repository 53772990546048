import { ConfigService } from 'ngx-envconfig';
import { Injectable } from '@angular/core';
import { Agentstorage } from '../../models/agentstorage';


import { HttpClient, HttpResponse, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CommandeService {

  //http://localhost:8088/parameter/historiqueVente/findallorderbydate
  private getallcomandes = this.configService.get('ipMS') + 'parameter/historiqueVente';
  private getallcomandesbycaissier = this.configService.get('ipMS') + 'parameter/historiqueVente/findallorderbydate';
  private getallcomandesbydate = this.configService.get('ipMS') + 'parameter/historiqueVente/findallorderbymanydate';


  private getallcomandespaginable = this.configService.get('ipMS') + 'parameter/historiqueVente/findorderbypageable';


  private urlsendstat = this.configService.get('ipMS') + 'stats/statforuser';

  authorization: string = " ";
  caissierid: string = " ";


  constructor(private http: HttpClient, private configService: ConfigService) {
    let u = new Agentstorage();
    u = JSON.parse(localStorage.getItem('curentuser'));

    if (u != null) {
      this.authorization = u.token;
      this.caissierid = u.agent.agentid;

      console.log("authorization == " + this.authorization);
    }
  }

  getAllCommabdes(): Observable<HttpResponse<any>> {
    return this.http.get(this.getallcomandes,
      {
        observe: 'response',
        headers: new HttpHeaders({ 'Access-Control-Allow-Origin': '*' })
          .set("content-Type", "application/json")
          .set("Authorization", this.authorization)
      });
  }





  public researchCommande(value: any): Observable<HttpResponse<any>> {
    console.log(value);
    return this.http.post(this.getallcomandes + "/orderpaginable", JSON.stringify(value), {
      observe: 'response',
      headers: new HttpHeaders({ 'Access-Control-Allow-Origin': '*' })
        .set("content-Type", "application/json")
        .set('Authorization', this.authorization)
    });
  }

  public researchDownload(value: any): Observable<HttpResponse<any>> {
    console.log(value);
    return this.http.post(this.getallcomandes + "/orderdownload", JSON.stringify(value), {
      observe: 'response',
      headers: new HttpHeaders({ 'Access-Control-Allow-Origin': '*' })
        .set("content-Type", "application/json")
        .set('Authorization', this.authorization)
    });
  }

  public searchCommande(value: any): Observable<HttpResponse<any>> {
    console.log(value);
    console.log(" */*/*/*/*/*/* ");
    return this.http.post(this.getallcomandesbydate, JSON.stringify(value), {
      observe: 'response',
      headers: new HttpHeaders({ 'Access-Control-Allow-Origin': '*' })
        .set("content-Type", "application/json")
        .set('Authorization', this.authorization)
    });
  }

  getAllCommabdesPaginable(size, limit): Observable<HttpResponse<any>> {
    console.log(this.getallcomandesbycaissier + "" + this.caissierid);

    /*
      return this.http.post(this.getallcomandespaginable, JSON.stringify(value), {
        observe: 'response',
        headers: new HttpHeaders({ 'Access-Control-Allow-Origin': '*' })
          .set("content-Type", "application/json")
          .set('Authorization', this.authorization)
      });
      */
    console.log(this.getallcomandespaginable + "/" + size + "/" + limit);
    return this.http.get<HttpResponse<any>>(this.getallcomandespaginable + "/" + size + "/" + limit,
      {
        headers: new HttpHeaders({ 'Access-Control-Allow-Origin': '*' })
          .set("content-Type", "application/json")
          .set("Authorization", this.authorization)
      });
  }


  getAllCommabdesByAgent(): Observable<HttpResponse<any>> {
    console.log(this.getallcomandesbycaissier + "" + this.caissierid);
    return this.http.get<HttpResponse<any>>(this.getallcomandesbycaissier,
      {
        headers: new HttpHeaders({ 'Access-Control-Allow-Origin': '*' })
          .set("content-Type", "application/json")
          .set("Authorization", this.authorization)
      });


  }




  public getCommandByDate(value: any): Observable<HttpResponse<any>> {
    console.log(value);
    console.log(" */*/*/*/*/*/* ");
    return this.http.post(this.getallcomandesbydate, JSON.stringify(value), {
      observe: 'response',
      headers: new HttpHeaders({ 'Access-Control-Allow-Origin': '*' })
        .set("content-Type", "application/json")
        .set('Authorization', this.authorization)
    });
  }



  public getStatUser(value: any): Observable<HttpResponse<any>> {
    console.log(value);
    return this.http.post(this.urlsendstat, JSON.stringify(value), {
      observe: 'response',
      headers: new HttpHeaders({ 'Access-Control-Allow-Origin': '*' })
        .set("content-Type", "application/json")
        .set('Authorization', this.authorization)
    });
  }



  getAllCommabdesByAgentId(value: string): Observable<HttpResponse<any>> {
    console.log(this.getallcomandesbycaissier + "" + value);
    return this.http.get<HttpResponse<any>>(this.getallcomandesbycaissier + "" + value,
      {
        headers: new HttpHeaders({ 'Access-Control-Allow-Origin': '*' })
          .set("content-Type", "application/json")
          .set("Authorization", this.authorization)
      });
  }

}
