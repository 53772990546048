import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GestioncaissiereComponent } from './gestioncaissiere.component';
import { ReactiveFormsModule } from '@angular/forms';
import { MaterialModule } from 'src/@fury/shared/material-components.module';
import { BreadcrumbsModule } from 'src/@fury/shared/breadcrumbs/breadcrumbs.module';
import { MatInputModule } from '@angular/material';
import { FuryCardModule } from 'src/@fury/shared/card/card.module';

@NgModule({
  declarations: [GestioncaissiereComponent],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    MaterialModule,
    BreadcrumbsModule,
    MatInputModule,
    FuryCardModule
  ]
})
export class GestioncaissiereModule { }
