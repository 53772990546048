import { Fonction } from './fonction';
import { Role } from './Role';

export class RolefonctionDto {
  rolefonctionid: string;
  rolefonctioncode: string;
  rolefonctiondatecreation: any;
  rolefonctionlibelle: string;
  rolename: string;
  role: Role;
  fonction: Fonction[];
  fonctionSup?: Fonction[];
  userpisteaudit : string ;
}
