import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpHeaders } from '@angular/common/http';
import { ConfigService } from 'ngx-envconfig';
import { NGXLogger } from 'ngx-logger';
import { Agentstorage } from 'src/app/models/agentstorage';
import { Observable } from 'rxjs';
import { Role } from 'src/app/models/Role';

@Injectable({
  providedIn: 'root'
})
export class GestionprofilService {

  authorization: string;

  private cruduser = this.configService.get('ipMS') + 'parameter/role';

  constructor(private http: HttpClient, private configService: ConfigService,
    private logger: NGXLogger) {
    let user: Agentstorage = new Agentstorage();
    user = JSON.parse(localStorage.getItem('curentuser'));

    if (user != null) {
      this.authorization = user.token;
    }
  }



  getAllProfil(): Observable<HttpResponse<any>> {
    return this.http.get(this.cruduser, {
      observe: 'response',
        headers: new HttpHeaders({ 'Access-Control-Allow-Origin': '*' })
          .set('content-Type', 'application/json')
          .set('Authorization', this.authorization)
      });
  }



  public createProfil(value: Role): Observable<HttpResponse<any>> {
    console.log("url : " + this.cruduser);
    console.log(JSON.stringify(value));
    return this.http.post(this.cruduser, JSON.stringify(value), {
      observe: 'response',
      headers: new HttpHeaders({ 'Access-Control-Allow-Origin': '*' })
        .set("content-Type", "application/json")
        .set("Authorization", this.authorization)
    });
  }



  public updateProfil(value: Role): Observable<HttpResponse<any>> {
    this.logger.trace("url : " + this.cruduser);
    this.logger.trace(JSON.stringify(value));
    return this.http.put(this.cruduser, JSON.stringify(value), {
      observe: 'response',
      headers: new HttpHeaders({ 'Access-Control-Allow-Origin': '*' })
        .set("content-Type", "application/json")
        .set("Authorization", this.authorization)
    });
  }



  deleteProfil(id: string): Observable<HttpResponse<any>> {
    return this.http.delete(this.cruduser + '/' + id,
      {
        observe: 'response',
        headers: new HttpHeaders({ 'Access-Control-Allow-Origin': '*' })
          .set("content-Type", "application/json")
          .set("Authorization", this.authorization)
      });
  }}
