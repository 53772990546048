import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef, MatSnackBar } from '@angular/material';
import { NGXLogger } from 'ngx-logger';
import { Subscription } from 'rxjs';
import { Categories } from 'src/app/models/categories';
import { Foods } from 'src/app/models/foods';
import { MenuService } from 'src/app/pages/gestionmenu/menu.service';

@Component({
  selector: 'fury-add-menu',
  templateUrl: './add-menu.component.html',
  styleUrls: ['./add-menu.component.scss']
})
export class AddMenuComponent implements OnInit {


  itemCategories: Categories[] = [];
  public items: Foods[] = [];
  public itemSelected: Foods[] = [];

  public getItemSub: Subscription;
  form: FormGroup;


  constructor(
    @Inject(MAT_DIALOG_DATA) public defaults: any,
    public dialogRef: MatDialogRef<AddMenuComponent>,
    private fb: FormBuilder, private menuservice: MenuService,
    private snackbar: MatSnackBar,
    private logger: NGXLogger
  ) { }

  ngOnInit() {
    this.getallCategories();

    this.form = this.fb.group({
      menuabbatementlib: ['', Validators.required],
      menuabbatementdesc: [''],
      categorie: []
    });


    this.form.get('categorie').valueChanges.subscribe(data => {
      this.getallFood(data);
    });

  }


  addToCart(value: Foods) {
    if (this.itemSelected != null && this.itemSelected.length > 0) {
      let check: boolean = false;
      this.itemSelected.forEach(element => {
        if (element.foodid === value.foodid) {
          check = true;
        }
      });
      if (!check) {
        this.itemSelected.push(value);
      }
    } else {
      this.itemSelected = [];
      this.itemSelected.push(value);
    }
  }


  deleteToCart(value: Foods) {
    let itemsToCheck: any[] = [];
    if (this.itemSelected != null && this.itemSelected.length > 0) {
      this.itemSelected.forEach(element => {
        if (element.foodid !== value.foodid) {
          itemsToCheck.push(element);
        }
      });
      this.itemSelected = itemsToCheck;
    }
  }

  saveMenu() {
    if (this.itemSelected != null && this.itemSelected.length > 0) {
      let request: any = {};
      request = this.form.value;
      let menus: any[] = [];
      this.itemSelected.forEach(element => {
        let m: any = {};
        m.menuabbtmntlinkfood = element.foodid;
        m.menuabbtmntlinkcat = element.categorie.categorieid;
        menus.push(m);
      });
      request.menus = menus;
      this.dialogRef.close(request);


    } else {
      this.snackbar.open('Veuillez selectionner au moins un plat ', 'OK', {
        duration: 10000
      });
    }
  }


  getallCategories() {
    this.getItemSub = this.menuservice.getAllCategories()
      .subscribe(data => {

        let responseany: any = data;

        this.logger.trace(responseany);

        switch (data.status) {

          case 200: {
            this.itemCategories = data.body;
            break;
          }

          default: {


            let response: any = data.body;
            this.itemCategories = response;



            break;
          }



        }
      },
        error => {
          console.log("error");
          console.log(error);

          switch (error.status) {

            default: {

              break;
            }
          }
        });
  }


  getallFood(id) {
    this.getItemSub = this.menuservice.getAllFoodByCatid("parameter/food/bycategoriecode/" + id)
      .subscribe(data => {

        let responseany: any = data;

        this.logger.trace(responseany);

        switch (data.status) {

          case 200: {
            this.items = data.body;
            break;
          }





        }
      },
        error => {
          console.log("error");
          console.log(error);

          switch (error.status) {

            default: {

              break;
            }
          }
        });
  }

}
