export class Categorie {

    categorieid: string;

    categoriedatecreation: string;

    categorieisdeleted: string;

    categoriename: string;

    categoriepath: string;


    public constructor() {

    }

}