import { MAT_DIALOG_DATA, MatSnackBar, MatDialogRef } from '@angular/material';
import { GenericserviceService } from 'src/app/services/genericservice.service';
import { FormGroup, FormControl } from '@angular/forms';
import { Livraison } from './../../../../models/Modelivraison';
import { Component, OnInit, Inject } from '@angular/core';



export interface DialogData {
  livraison: Livraison;
}


@Component({
  selector: 'fury-modelivraison-modifier',
  templateUrl: './modelivraison-modifier.component.html',
  styleUrls: ['./modelivraison-modifier.component.scss'],
  providers:[GenericserviceService]
})
export class ModelivraisonModifierComponent implements OnInit {

 
  livraisonForm: FormGroup;
  livraisonmodeId: string;
  livraison: Livraison;




  constructor(public dialogRef: MatDialogRef<ModelivraisonModifierComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private livraisonService: GenericserviceService, private snackbar: MatSnackBar) { }


  onNoClick(): void {
    console.info('---clic on close --- ')
    this.dialogRef.close();
  }

  ngOnInit() {
    this.livraisonService.type='livraison';

    this.livraisonForm = new FormGroup({ livraisonmodename: new FormControl(), livraisonmodeisdeleted: new FormControl() })

    if (this.data) { // edit form
      console.log('checking selected livraison--- : ' + this.data.livraison.livraisonmodename);
      this.livraisonForm.reset({ livraisonmodename: this.data.livraison.livraisonmodename, livraisonmodeisdeleted: this.data.livraison.livraisonmodeisdeleted });
    }

  }


  onSubmit() {

    if (this.livraisonForm.valid) {
      console.log('-----------updating livraison ----------------');

      let updatedLivraison: Livraison = new Livraison();
      updatedLivraison.livraisonmodeid = this.data.livraison.livraisonmodeid;
      updatedLivraison.livraisonmodedatecreation = this.data.livraison.livraisonmodedatecreation;
      updatedLivraison.livraisonmodename = this.livraisonForm.controls['livraisonmodename'].value;
      updatedLivraison.livraisonmodeisdeleted = this.data.livraison.livraisonmodeisdeleted;

      console.log('-----------data to update  ---------------- ' + updatedLivraison.livraisonmodeid + ' ' +
        updatedLivraison.livraisonmodename + ' ' + updatedLivraison.livraisonmodeisdeleted);


      this.livraisonService.update(updatedLivraison).subscribe(data => {

        console.log(data);
        switch (data.status) {
          case 201: {
            // this.loading.dismiss('messagesService.loadMessagesOverview');

            let resp: Livraison;
            resp = data.body;
            console.log("------------------------------------");
            console.log(resp);
            console.log("------------------------------------");


            /* Swal.fire({
               position: 'top-end',
               type: 'success',
               title: 'Your work has been saved',
               showConfirmButton: false,
               timer: 1500
             })*/




            this.snackbar.open('Success modification', 'OK', {
              duration: 2000
            });

            break;
          }
        }
      },
        error => {

          console.log("erreur retour");
          // this.loading.dismiss('messagesService.loadMessagesOverview');
          switch (error.status) {
            case 409:
              //this.FormReg(" Mot de passe ou login incorrect ");
              this.snackbar.open('Echec modification', 'Le mode de livraison existe deja', {
                duration: 2000
              });
              break;
            default: {
              // this.FormReg(" erreur de connexion !!! Veuillez ressayer");
              this.snackbar.open('Echec modification', 'OK', {
                duration: 2000
              });
              break;
            }
          }

        });


      console.log('-----------updating livraison ok ----------------');


      this.dialogRef.close();
    } else {


      // this.validateAllFormFields(this.livraisonForm);
      this.snackbar.open('Veuillez saisir toutes les valeurs svp', 'OK', {
        duration: 2000
      });
    }




  }


}
