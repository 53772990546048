import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { ConfigService } from 'ngx-envconfig';

@Component({
  selector: 'fury-showlivreur',
  templateUrl: './showlivreur.component.html',
  styleUrls: ['./showlivreur.component.scss']
})
export class ShowlivreurComponent implements OnInit {

  item: any;

  codeLivreur: any = this.configService.get('livreur.interne');

  livreurtype: any;


  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
    private configService: ConfigService,
    private dialogRef: MatDialogRef<ShowlivreurComponent>) {
    this.item = this.data.payload;

    console.log(this.item);
    this.livreurtype = this.item.livreurtype.livreurtypeid;
  }


  download(path) {
    let a = document.createElement("a");
    document.body.appendChild(a);
    let url: string;
    //url = path;
    a.href = path;
    a.target = "_blank";
    a.download = "test.pdf";
    a.name = "test"
    a.click();
    document.body.removeChild(a);
  }

  ngOnInit() {
  }

}
