import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HistoriquecarteComponent } from './historiquecarte.component';
import { ConsulterhistcarteComponent } from './consulterhistcarte/consulterhistcarte.component';
import { ReactiveFormsModule } from '@angular/forms';
import { MatChipsModule } from '@angular/material';
import { MaterialModule } from 'src/@fury/shared/material-components.module';
import { BreadcrumbsModule } from 'src/@fury/shared/breadcrumbs/breadcrumbs.module';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { FuryCardModule } from 'src/@fury/shared/card/card.module';
import { FurySharedModule } from 'src/@fury/fury-shared.module';
import { CartecreditComponent } from './cartecredit/cartecredit.component';
import { MatSelectSearchModule } from './mat-select-search/mat-select-search.module';


@NgModule({
  declarations: [HistoriquecarteComponent, ConsulterhistcarteComponent, CartecreditComponent],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    MatChipsModule,
    MaterialModule,
    BreadcrumbsModule,
    NgxDatatableModule,
    FuryCardModule,
    MatChipsModule,
    FurySharedModule,
    MatSelectSearchModule

  ],
  entryComponents : [CartecreditComponent]
})
export class HistoriquecarteModule { }
