import { Component, OnInit, Inject } from '@angular/core';
import { Historiq } from 'src/app/models/Agent';
import { MAT_DIALOG_DATA, MatDialogRef, MatSnackBar } from '@angular/material';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { Agent } from 'src/app/models/Agent';
import { GenericserviceService } from 'src/app/services/genericservice.service';
import { DialogDataAgent } from '../../gestionagent/gestionagent.component';
import { Role } from 'src/app/models/Role';
import { Command } from 'src/app/models/command';
import { GestionlivraisonComponent } from '../../gestionlivraison/gestionlivraison.component';

@Component({
  selector: 'fury-affectercommandeaunlivreur',
  templateUrl: './affectercommandeaunlivreur.component.html',
  styleUrls: ['./affectercommandeaunlivreur.component.scss']
})
export class AffectercommandeaunlivreurComponent implements OnInit {

  item: Command;

  form: FormGroup;
  agents: Agent[];
  selectedRole: string;

  selectedAgent: any;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialogRef: MatDialogRef<AffectercommandeaunlivreurComponent>,
    private formBuilder: FormBuilder,
    private agentService: GenericserviceService,
    private snackbar: MatSnackBar,
  ) {
  }

  ngOnInit() {
    this.buildItemForm(this.data.payload);
    // load list of delivery man
    this.findAllDeliveryMan();

    // construct form
    /*
    this.form = new FormGroup({
      commandreference: new FormControl('', Validators.required),
      agent: new FormControl('', Validators.required),
    });

    this.form = this.formBuilder.group({
      commandreference: ['', Validators.required],
      agent : ['', Validators.required]
  })
  */

  }


  buildItemForm(item) {
    console.log(" *************************  ");
    console.log(item);
    console.log(" *************************  ");
    this.item = item;

    if (this.item) { // set commandid
      this.form = new FormGroup({
        commandreference: new FormControl(this.item.commandreference),
        agent: new FormControl('', Validators.required),
      });
    }
  }


  /**
   * Get list of deliveru man
   */
  findAllDeliveryMan() {
    this.agentService.findAllDeliveryManOpen().subscribe(data => {
      //debugger;
      console.log("data", data);
      if (data.status && data.status === 200) {
        this.agents = data.body['agents'];
      }
    },
      error => {
        console.log("error");
      }
    );
  }


  /**
   * Close dialog form
   */
  onNoClick(): void {
    console.info('---click here to close dialog --- ')
    this.dialogRef.close();
  }


  /**
   * Submit data
   */
  onSubmit() {

    if (this.form.valid) {
      console.log('=============== assign order to delivery man ===============');

      let objAssign = {
        'commandreference': this.form.controls['commandreference'].value,
        'delivery_man_id': this.form.controls['agent'].value,
      }

      console.log('====== data to post ====== ' + JSON.stringify(objAssign));

      this.dialogRef.close(objAssign);


      //      console.log('============ affectation commande ok ============ ');
      //    this.dialogRef.close();
    } else {
      this.snackbar.open('Veuillez renseigner tous les champs SVP !', 'OK', {
        duration: 2000
      });
    }

  }


}
