import { DatePipe } from '@angular/common';
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialog, MatPaginator, MatSnackBar, MatSort, MatTableDataSource } from '@angular/material';
import { ConfigService } from 'ngx-envconfig';
import { NGXLogger } from 'ngx-logger';
import { NgxPermissionsService } from 'ngx-permissions';
import { Subscription } from 'rxjs';
import { ConfirmationService } from 'src/app/confirm/confirmation.service';
import { ApploaderService } from 'src/app/services/apploader/apploader.service';
import { HistodecouvertService } from './histodecouvert.service';

@Component({
  selector: 'fury-histodecouvert',
  templateUrl: './histodecouvert.component.html',
  styleUrls: ['./histodecouvert.component.scss']
})
export class HistodecouvertComponent implements OnInit {

  //abonnementdatecreation , abonnementclient , histomontant , abonnementsoldeavantdecouvert , abonnementsoldeactueldecouvert ,
  displayedColumns: string[] = ['abonnementdatecreation', 'abonnementclient', 'histomontant', 'abonnementsoldeavantdecouvert', 'abonnementsoldecumuldecouvert', 'abonnementtype'];
  dataSource = new MatTableDataSource<any>([]);
  @ViewChild(MatPaginator) paginator: MatPaginator;
  public getItemSub: Subscription;
  demandedrh = [];
  temp = [];
  public itemForm: FormGroup;


  check: any[] = [
    {
      "libele": "TOUS LES DECOUVERTS ",
      "values": "ALL"
    },
    {
      "libele": "RECHARGEMENT",
      "values": "RECHARGEMENT"
    },
    
    {
      "libele": "REMBOURSEMENT",
      "values": "REMBOURSEMENT"
    }
  ]



  constructor(private dialog: MatDialog, public formBuilder: FormBuilder,
    private snackbar: MatSnackBar, private datePipe: DatePipe,
    private configService: ConfigService,
    private confirmService: ConfirmationService, private loader: ApploaderService,
    private permissionsService: NgxPermissionsService, private logger: NGXLogger,
    private histoservice: HistodecouvertService) { }

  ngOnInit() {

    let dateNow: any = new Date();
    dateNow = this.datePipe.transform(dateNow, "yyyy-MM-dd");

    this.itemForm = this.formBuilder.group({
      debut: [dateNow + 'T00:01:01'],
      fin: [dateNow + 'T23:59:59'],
      type: ['ALL']
    });
    this.search();
    //this.getAllHistorique();
  }



  search() {
    let date = new Date(this.itemForm.get("debut").value);
    let date1 = new Date(this.itemForm.get("fin").value);

    let request: any = this.itemForm.value;

    let from;
    let to;

    if ((date.toString() != 'Invalid Date')) {
      from = this.itemForm.get("debut").value;
    }

    if (date1.toString() != null) {
      to = this.itemForm.get("fin").value;
    }

    let newDate = new Date(from);
    let newDate1 = new Date(to);

    if ((newDate.toDateString() !== "Invalid Date") && (newDate1.toDateString() !== "Invalid Date")) {
      if (newDate1 >= newDate) {
        request.debut = this.datePipe.transform(newDate, "yyyy-MM-dd HH:mm:ss");
        request.fin = this.datePipe.transform(newDate1, "yyyy-MM-dd HH:mm:ss");
      } else {
        this.snackbar.open('Format de date invalide ', 'OK', { duration: 4000 });
        return;
      }
    } else {
      this.snackbar.open('Format de date invalide ', 'OK', { duration: 4000 });
      return;
    }

    /*
    if (request.type === "all") {
      request.categorie = "";
    }

    if (request.type !== "all") {
      request.categorie = request.type;
      request.type = "status";
    }
        request.type ;
    */
    console.log(request);
    this.checkByDate(request);


  }


  checkByDate(request) {

    this.histoservice.post(request, "histabonement/getalldecouvertbydate").subscribe(data => {

      this.logger.trace(data);

      switch (data.status) {
        case 200: {
          let response: any = data.body;
          /*
          let resp1: any[] = response.listFileAttente;
          console.log(resp1);
          */

          this.demandedrh = this.temp = response;
          console.log(this.demandedrh);

          this.snackbar.open('Succes', 'OK', {
            duration: 10000
          });
          break;
        }

      }
    },
      error => {
        this.logger.trace("erreur retour");
        // this.loading.dismiss('messagesService.loadMessagesOverview');
        switch (error.status) {



          default: {

            // this.FormReg(" erreur de connexion !!! Veuillez ressayer");
            this.logger.error("Check API", error);

            this.snackbar.open('Echec', 'OK', {
              duration: 10000
            });
            break;
          }
        }
      }
    );
  }

  updateFilter(event) {
    const val = event.target.value.toLowerCase();
    var columns = Object.keys(this.temp[0]);
    // Removes last "$$index" from "column"
    columns.splice(columns.length - 1);


    // console.log(columns);
    if (!columns.length)
      return;

    const rows = this.temp.filter(function (d) {
      for (let i = 0; i <= columns.length; i++) {
        let column = columns[i];
        // console.log(d[column]);
        if (d[column] && d[column].toString().toLowerCase().indexOf(val) > -1) {
          return true;
        }
      }
    });

    this.demandedrh = rows;
  }

  getAllHistorique() {
    this.getItemSub = this.histoservice.get('histabonement/getalldecouvert')
      .subscribe(data => {
        const statuscode = data.status;
        this.logger.trace('DATA', data.body);
        switch (statuscode) {
          case 200: {
            this.demandedrh = this.temp = data.body;
            this.dataSource.data = this.demandedrh;
            this.dataSource.paginator = this.paginator;
            // this.temp = data.body;
            break;
          }

        }
      },
        error => {
          this.logger.error('error');
          this.logger.error(error);
          switch (error.status) {
            default: {
              this.snackbar.open('Une erreur est survenue!', 'OK', { duration: 4000 })
              break;
            }
          }
        });
  }

}
