import { DOCUMENT } from '@angular/common';
import { Component, Inject, Renderer2 } from '@angular/core';
import { MatIconRegistry } from '@angular/material';
import { SidenavService } from './layout/sidenav/sidenav.service';
import { ThemeService } from '../@fury/services/theme.service';
import { Rolefonction } from './models/rolefonction';
import { Fonction } from './models/fonction';
import { SidenavItem } from './layout/sidenav/sidenav-item/sidenav-item.interface';
import { NGXLogger } from 'ngx-logger';

@Component({
  selector: 'fury-root',
  templateUrl: './app.component.html'
})
export class AppComponent {



  mapProil1: Map<string, any[]> = new Map([
  
    /*
      ['code-list-corporate-001', [{
      name: 'Corporate',
      routeOrFunction: '/yolinbo/gestioncorporate',
      icon: 'group',
      position: 48,
    }
    ]],
    */
    ['code-user-001', [{
      name: 'Utilisateur',
      routeOrFunction: '/yolinbo/gestionutilisateur',
      icon: 'group',
      position: 10,
    }
    ]],
    ['code-audit-001', [{
      name: 'Audit',
      routeOrFunction: '/yolinbo/audit',
      icon: 'zoom_in',
      position: 15,
    }
    ]],
    ['code-profil-001', [{
      name: 'Profil',
      routeOrFunction: '/yolinbo/gestionprofil',
      icon: 'developer_board',
      position: 20,
    }
    ]],
    ['code-dash-001', [{
      name: 'Tableau de bord',
      routeOrFunction: '/yolinbo/dashboard',
      icon: 'dashboard',
      position: 40
    }
    ]],

    /*
    ['code-caisse-001', [
      {
        name: 'Journal Caisse',
        routeOrFunction: '/yolinbo/journalcaisse',
        icon: 'filter_list',
        position: 45,
      }
    ]],
    */

    /*
    ["code-param-001", [
      {
        name: 'Parametres',
        routeOrFunction: '/yolinbo/gestionparametres/modelivraison',
        icon: 'settings',
        position: 55,
      }
    ]],
    */
    ['code-cmd-001', [
      {
        name: 'Commandes',
        routeOrFunction: '/yolinbo/gestioncommande',
        icon: 'receipt',
        position: 60,
      }
    ]],
    ['code-aff-point-de-livraison-001', [{
      name: 'Point de livraison',
      routeOrFunction: '/yolinbo/gestionpointlivraison',
      icon: 'room',
      position: 65,
    }
    ]],
    ['code-client-001', [
      {
        name: 'Compte client',
        routeOrFunction: '/yolinbo/gestionclient',
        icon: 'account_circle',
        position: 70,
      }
    ]],


    /*
    ['code-list-formule-001', [{
      name: 'Formule',
      routeOrFunction: '/yolinbo/formuleabonnement',
      icon: 'highlight_off',
      position: 48,
    }
    ]],
    */


    ['cd-an-01', [{
      name: 'Annulation',
      routeOrFunction: '/yolinbo/gestionannulation',
      icon: 'highlight_off',
      position: 45,
    }
    ]],
    ['code-att-001', [{
      name: 'Attente',
      routeOrFunction: '/yolinbo/gestionattente',
      icon: 'history',
      position: 46,
    }
    ]],
    ['code-param-001', [{
      name: 'Parametre',
      routeOrFunction: '/yolinbo/gestionparametre',
      icon: 'settings',
      position: 42,
    }
    ]],
    ['code-pointvente-001', [{
      name: 'Point de vente',
      routeOrFunction: '/yolinbo/gestionpointvente',
      icon: 'room',
      position: 41,
    }
    ]],

    ['code-abattement-001', [{
      name: 'Parcours corporate',
      routeOrFunction: '/yolinbo/simplificationcorp',
      icon: 'fastfood',
      position: 42,
    }
    ]],


    /*
    
    ['code-cat-corpo-001', [{
      name: 'Categorie-corporate',
      routeOrFunction: '/yolinbo/categoriecorp',
      icon: 'receipt',
      position: 44,
    }
    ]],

    ['code-form-corpo-001', [{
      name: 'Formule-corporate',
      routeOrFunction: '/yolinbo/catcorpformule',
      icon: 'account_circle',
      position: 45,
    }
    ]],
    ['code-list-demande-abn-001', [{
      name: 'Demande',
      routeOrFunction: '/yolinbo/demandeabonnement',
      icon: 'room',
      position: 41
      
      ,
      subItems: [
        {
            name: 'Historique',
            routeOrFunction: '/yolinbo/abonnementmasse/historique',
            position: 10
        }
      ]
      
    }
    ]],
    */

    ['code-abonnementmasse-001', [{
      name: 'Abonnement masse',
      routeOrFunction: '/yolinbo/abonnementmassif',
      icon: 'highlight_off',
      position: 30,
    }
    ]]


  ]);



  mapProilAnnexePlat: Map<string, any[]> = new Map(
    [
      ['plat', [
        {
          name: 'Plat',
          icon: 'fastfood',
          position: 45,
          subItems: [

          ]
        }
      ]]
    ]
  );

  mapProilAnnexe: Map<string, any[]> = new Map(
    [
      ['plat', [
        {
          name: 'Plat',
          icon: 'fastfood',
          position: 45,
          subItems: [
            {
              name: 'Menu',
              routeOrFunction: '/yolinbo/gestionmenu',
              position: 10
            },
            {
              name: 'Catégorie',
              routeOrFunction: '/yolinbo/categoriemenu',
              position: 15
            },
            {
              name: 'Tva',
              routeOrFunction: '/yolinbo/gestiontva',
              position: 20
            },
            {
              name: 'Service',
              routeOrFunction: '/yolinbo/gestionheurecmd',
              position: 25
            }
          ]
        }
      ]],
      ['ca', [
        {
          name: 'Carte Abonnement',
          icon: 'fastfood',
          position: 100,
          subItems: [
            {
              name: 'cartes',
              routeOrFunction: '/yolinbo/gestioncarte',
              position: 10
            },
            {
              name: 'historique',
              routeOrFunction: '/yolinbo/gestionhistoriquecarte',
              position: 15
            }
            ,
            {
              name: 'status',
              routeOrFunction: '/yolinbo/gestioncartestatus',
              position: 20
            }

          ]

        }
      ]]
    ]
  );

  mapProil2: Map<string, any[]> = new Map(
    [
      ['m5', [
        {
          name: 'Plat',
          icon: 'fastfood',
          position: 45,
          subItems: [
            {
              name: 'Menu',
              routeOrFunction: '/yolinbo/gestionmenu',
              position: 10
            }
          ]
        }
      ]],
      ['m6', [
        {
          name: 'Plat',
          icon: 'fastfood',
          position: 45,
          subItems: [
            {
              name: 'Catégorie',
              routeOrFunction: '/yolinbo/categoriemenu',
              position: 15
            }
          ]
        }
      ]],
      ['m5 m6', [
        {
          name: 'Plat',
          icon: 'fastfood',
          position: 45,
          subItems: [
            {
              name: 'Menu',
              routeOrFunction: '/yolinbo/gestionmenu',
              position: 10
            },
            {
              name: 'Catégorie',
              routeOrFunction: '/yolinbo/categoriemenu',
              position: 15
            }
          ]
        }
      ]],
      ['m11', [
        {
          name: 'Carte Abonnement',
          icon: 'fastfood',
          position: 100,
          subItems: [
            {
              name: 'cartes',
              routeOrFunction: '/yolinbo/gestioncarte',
              position: 10
            }
          ]

        }
      ]]
      ,
      ['m11 m12', [
        {
          name: 'Carte Abonnement',
          icon: 'fastfood',
          position: 100,
          subItems: [
            {
              name: 'cartes',
              routeOrFunction: '/yolinbo/gestioncarte',
              position: 10
            },
            {
              name: 'historique',
              routeOrFunction: '/yolinbo/gestionhistoriquecarte',
              position: 10
            }

          ]

        }
      ]]
      ,
      ['m11 m12 m13', [
        {
          name: 'Carte Abonnement',
          icon: 'fastfood',
          position: 100,
          subItems: [
            {
              name: 'cartes',
              routeOrFunction: '/yolinbo/gestioncarte',
              position: 10
            },
            {
              name: 'historique',
              routeOrFunction: '/yolinbo/gestionhistoriquecarte',
              position: 10
            }

            ,
            {
              name: 'status',
              routeOrFunction: '/yolinbo/gestioncartestatus',
              position: 10
            }
          ]

        }
      ]],
      ['m11 m13', [
        {
          name: 'Carte Abonnement',
          icon: 'fastfood',
          position: 100,
          subItems: [
            {
              name: 'cartes',
              routeOrFunction: '/yolinbo/gestioncarte',
              position: 10
            },
            {
              name: 'status',
              routeOrFunction: '/yolinbo/gestioncartestatus',
              position: 10
            }
          ]

        }
      ]],
      ['m12 m13', [
        {
          name: 'Carte Abonnement',
          icon: 'fastfood',
          position: 100,
          subItems: [

            {
              name: 'historique',
              routeOrFunction: '/yolinbo/gestionhistoriquecarte',
              position: 10
            }

            ,
            {
              name: 'status',
              routeOrFunction: '/yolinbo/gestioncartestatus',
              position: 10
            }
          ]

        }
      ]],
      ['m13', [
        {
          name: 'Carte Abonnement',
          icon: 'fastfood',
          position: 100,
          subItems: [

            {
              name: 'status',
              routeOrFunction: '/yolinbo/gestioncartestatus',
              position: 10
            }
          ]

        }
      ]]
    ]
  );

  constructor(private sidenavService: SidenavService,
    public logger: NGXLogger,
    private iconRegistry: MatIconRegistry,
    private renderer: Renderer2,
    private themeService: ThemeService,
    @Inject(DOCUMENT) private document: Document) {
    this.iconRegistry.setDefaultFontSetClass('material-icons');
    this.themeService.theme$.subscribe(theme => {
      if (theme[0]) {
        this.renderer.removeClass(this.document.body, theme[0]);
      }

      this.renderer.addClass(this.document.body, theme[1]);
    });


    let profils: Rolefonction[] = [];
    profils = JSON.parse(localStorage.getItem('menusidenav'));
    const userprofilfonction: Fonction[] = [];
    const menusidenav: SidenavItem[] = [];

    if (profils != null && profils.length > 0) {

      this.logger.trace('profils', profils);
      profils.filter((x) => {
        userprofilfonction.push(x.fonction);

      });

      this.logger.trace('userprofilfonction', userprofilfonction);
      this.sidenavService.initializeItemMenu();
      userprofilfonction.filter((x) => {
        let sidenav: any[];
        sidenav = this.mapProil1.get(x.fonctiontoken);
        if (sidenav) {
          sidenav.forEach((myObject, index) => {
            menusidenav.push(myObject);
            // routeOrFunction
          });
        }
      });

      /* Nouvelle Fonction de tri du menu par habilitation */

      const subItemsPlat: any[] = [];

      const subItemsStat: any[] = [];

      const subItemsCA: any[] = [];

      const subItemsLivraison: any[] = [];

      const subItemsCamaster: any[] = [];

      const subItemsConfiguration: any[] = [];
      const subItemsCaisse: any[] = [];


      userprofilfonction.filter((x) => {

     
        if (x.fonctiontoken === 'code-livreur-001') {
          subItemsLivraison.push({
            name: 'Livreur',
            routeOrFunction: '/yolinbo/gestionlivreur',
            position: 15
          });

        }

        if (x.fonctiontoken === 'code-livreur-001') {
          subItemsLivraison.push({
            name: 'File attente',
            routeOrFunction: '/yolinbo/fileattente',
            position: 20
          });
        }

        if (x.fonctiontoken === 'code-livreur-001') {
          subItemsLivraison.push({
            name: 'Commande speciale',
            routeOrFunction: '/yolinbo/specialorder',
            position: 25
          });
        }

        if (x.fonctiontoken === 'code-livraison-001') {
          subItemsLivraison.push({
            name: 'Commande Livrée',
            routeOrFunction: '/yolinbo/gestionlivraison',
            position: 10
          });
        }

        if (x.fonctiontoken === 'code-livraison-001') {
          subItemsLivraison.push({
            name: 'Compte Start',
            routeOrFunction: '/yolinbo/start',
            position: 10
          });
        }

        if (x.fonctiontoken === 'code-livraison-001') {
          subItemsLivraison.push({
            name: 'Compte Solde',
            routeOrFunction: '/yolinbo/solde',
            position: 10
          });
        }




 if (x.fonctiontoken === 'code-liv-allincident-001') {
  subItemsLivraison.push({
    name: 'Gestion incidents',
    routeOrFunction: '/yolinbo/allincident',
    position: 10
  });
}

if (x.fonctiontoken === 'code-liv-allperte-001') {
  subItemsLivraison.push({
    name: 'Gestions pertes',
    routeOrFunction: '/yolinbo/allperte',
    position: 10
  });
}



      });



      userprofilfonction.filter((x) => {

        if (x.fonctiontoken === 'code-plat-001') {
          subItemsPlat.push({
            name: 'Menu',
            routeOrFunction: '/yolinbo/gestionmenu',
            position: 10
          });
        }
        if (x.fonctiontoken === 'code-cat-001') {
          subItemsPlat.push({
            name: 'Catégorie',
            routeOrFunction: '/yolinbo/categoriemenu',
            position: 15
          });

        }
        if (x.fonctiontoken === 'code-tva-001') {

          subItemsPlat.push({
            name: 'TVA',
            routeOrFunction: '/yolinbo/gestiontva',
            position: 20
          });

        }
        if (x.fonctiontoken === 'code-hour-001') {
          subItemsPlat.push({
            name: 'Service',
            routeOrFunction: '/yolinbo/gestionheurecmd',
            position: 25
          });
        }
      });

      userprofilfonction.filter((x) => {
        if (x.fonctiontoken === 'code-carte-001') {
          subItemsCA.push({
            name: 'cartes',
            routeOrFunction: '/yolinbo/gestioncarte',
            position: 10
          });
        }



        if (x.fonctiontoken === 'code-carte-historique-001') {
          subItemsCA.push({
            name: 'historique',
            routeOrFunction: '/yolinbo/gestionhistoriquecarte',
            position: 10
          }
          );
        }

        if (x.fonctiontoken === 'code-carte-statut-001') {
          subItemsCA.push({
            name: 'status',
            routeOrFunction: '/yolinbo/gestioncartestatus',
            position: 10
          });
        }


        if (x.fonctiontoken === 'code-creditercarte-file-001') {
          subItemsCA.push({
            name: 'crediter carte',
            routeOrFunction: '/yolinbo/creditercarte',
            position: 15
          });
        }


        if (x.fonctiontoken === 'code-type-decouvert-001') {
          subItemsCA.push({
            name: 'Type carte',
            routeOrFunction: '/yolinbo/typecarte',
            position: 15
          });
        }

      });

      userprofilfonction.filter((x) => {
        if (x.fonctiontoken === 'code-carte-master-corporate-001') {
          subItemsCamaster.push({
            name: 'crediter',
            routeOrFunction: '/yolinbo/loadbalance',
            position: 15
          }
          );
        }

        /*
        if (x.fonctiontoken === 'code-creditagent-mastercard') {
          subItemsCamaster.push({
            name: 'crediter corporate',
            routeOrFunction: '/yolinbo/loadcredit',
            position: 10
          });
        }
        */


        if (x.fonctiontoken === 'code-add-carte-master-corporate-001') {
          subItemsCamaster.push({
            name: 'historique',
            routeOrFunction: '/yolinbo/cartemasterhistorique',
            position: 20
          });
        }


      });


      userprofilfonction.filter((x) => {
        if (x.fonctiontoken === 'code-decouvert-001') {
          subItemsConfiguration.push({
            name: 'Decouvert',
            routeOrFunction: '/yolinbo/decouvert',
            position: 10
          });

          subItemsConfiguration.push({
            name: 'Historique Decouvert',
            routeOrFunction: '/yolinbo/histodecouvert',
            position: 10
          });
        }
      });



      if (subItemsPlat && subItemsPlat.length > 0) {

        const platSidenavItem: SidenavItem = new SidenavItem();

        platSidenavItem.name = 'Plat';
        platSidenavItem.icon = 'fastfood';
        platSidenavItem.position = 45;
        platSidenavItem.subItems = subItemsPlat;
        menusidenav.push(platSidenavItem);


      }



      userprofilfonction.filter((x) => {
        if (x.fonctiontoken === 'code-stat-periode') {
          subItemsStat.push({
            name: 'statistique categorie',
            routeOrFunction: '/yolinbo/statperiodique',
            position: 10
          });
        }



        if (x.fonctiontoken === 'code-stat-periode-vente') {
          subItemsStat.push({
            name: 'statistique vente',
            routeOrFunction: '/yolinbo/statproduit',
            position: 10
          }
          );
        }


        if (x.fonctiontoken === 'code-stat-periode-globale') {
          subItemsStat.push({
            name: 'statistique globale',
            routeOrFunction: '/yolinbo/statvente',
            position: 10
          });
        }

        if (x.fonctiontoken === 'code-stat-user-001') {
          subItemsStat.push({
            name: 'statistique utilisation',
            routeOrFunction: '/yolinbo/statuser',
            position: 10
          });
        }

        if (x.fonctiontoken === 'code-stat-produit-001') {
          subItemsStat.push({
            name: 'statistique produit',
            routeOrFunction: '/yolinbo/statforproduct',
            position: 10
          });
        }



      });



      if (subItemsCA && subItemsCA.length > 0) {

        const platSidenavItem: SidenavItem = new SidenavItem();

        platSidenavItem.name = 'Carte Abonnement';
        platSidenavItem.icon = 'fastfood';
        platSidenavItem.position = 100;
        platSidenavItem.subItems = subItemsCA;
        menusidenav.push(platSidenavItem);


      }

      if (subItemsStat && subItemsStat.length > 0) {

        const platSidenavItem: SidenavItem = new SidenavItem();

        platSidenavItem.name = 'Statistique';
        platSidenavItem.icon = 'dashboard';
        platSidenavItem.position = 65;
        platSidenavItem.subItems = subItemsStat;
        menusidenav.push(platSidenavItem);
      }

      if (subItemsCamaster && subItemsCamaster.length > 0) {

        const platSidenavItem: SidenavItem = new SidenavItem();

        platSidenavItem.name = 'Rechargement corporate';
        platSidenavItem.icon = 'account_balance';
        platSidenavItem.position = 110;
        platSidenavItem.subItems = subItemsCamaster;
        menusidenav.push(platSidenavItem);


      }

      if (subItemsLivraison && subItemsLivraison.length > 0) {

        const platSidenavItem: SidenavItem = new SidenavItem();

        platSidenavItem.name = 'Livraison';
        platSidenavItem.icon = 'shopping_cart';
        platSidenavItem.position = 130;
        platSidenavItem.subItems = subItemsLivraison;
        menusidenav.push(platSidenavItem);


      }

      if (subItemsConfiguration && subItemsConfiguration.length > 0) {
        const platSidenavItem: SidenavItem = new SidenavItem()
        platSidenavItem.name = 'Configuration';
        platSidenavItem.icon = 'more';
        platSidenavItem.position = 65;
        platSidenavItem.subItems = subItemsConfiguration;
        menusidenav.push(platSidenavItem);
      }

      const subItemsDemande: any[] = [];


      userprofilfonction.filter((x) => {


        if (x.fonctiontoken === 'code-carte-give-001') {
          subItemsDemande.push({
            name: 'Demande attribution',
            routeOrFunction: '/yolinbo/demandedeattribution',
            position: 10
          });
        }

        if (x.fonctiontoken === 'code-carte-lock-001') {
          subItemsDemande.push({
            name: 'Demande blocage',
            routeOrFunction: '/yolinbo/demandeblocage',
            position: 15
          });
        }


        if (x.fonctiontoken === 'code-carte-unlock-001') {
          subItemsDemande.push({
            name: 'Demande deblocage',
            routeOrFunction: '/yolinbo/demandedeblocage',
            position: 20
          });
        }

        if (x.fonctiontoken === 'code-demande-left-crpte-001') {
          subItemsDemande.push({
            name: 'Demande quitter corp',
            routeOrFunction: '/yolinbo/dmdquittecrpte',
            position: 15
          });
        }

        if (x.fonctiontoken === 'code-demande-disable-precpte-001') {
          subItemsDemande.push({
            name: 'Desactiver precompte',
            routeOrFunction: '/yolinbo/dmdprecompte',
            position: 20
          });
        }

        if (x.fonctiontoken === 'code-demande-disable-precpte-001') {
          subItemsDemande.push({
            name: 'Activer precompte',
            routeOrFunction: '/yolinbo/precptedmde',
            position: 20
          });
        }




        if (x.fonctiontoken === 'code-demande-pass-corp-1') {
          subItemsDemande.push({
            name: 'Demande passage corporate',
            routeOrFunction: '/yolinbo/passtocorporate',
            position: 28
          });
        }


        if (x.fonctiontoken === 'code-demande-renewal-card-1') {
          subItemsDemande.push({
            name: 'Demande renouvelement',
            routeOrFunction: '/yolinbo/renewcard',
            position: 29
          });
        }






      });



      if (subItemsDemande && subItemsDemande.length > 0) {

        const platSidenavItem: SidenavItem = new SidenavItem()

        platSidenavItem.name = 'Demande';
        platSidenavItem.icon = 'autorenew';
        platSidenavItem.position = 65;
        platSidenavItem.subItems = subItemsDemande;
        menusidenav.push(platSidenavItem);

      }



      userprofilfonction.filter((x) => {

        if (x.fonctiontoken === 'code-caisse-001') {
          subItemsCaisse.push({
            name: 'Journal caisse',
            routeOrFunction: '/yolinbo/journalcaisse',
            position: 10
          });
        }
        if (x.fonctiontoken === 'code-cloture-caisse-001') {
          subItemsCaisse.push({
            name: 'Cloture caisse',
            routeOrFunction: '/yolinbo/cloturecaisse',
            position: 15
          });
        }

      });

      if (subItemsCaisse && subItemsCaisse.length > 0) {

        const platSidenavItem: SidenavItem = new SidenavItem()

        platSidenavItem.name = 'Caisse';
        platSidenavItem.icon = 'autorenew';
        platSidenavItem.position = 45;
        platSidenavItem.subItems = subItemsCaisse;
        menusidenav.push(platSidenavItem);

      }

      this.sidenavService.addItems(menusidenav);
      this.logger.trace('menusidenav', menusidenav);


    }





  }
}
