import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
// tslint:disable-next-line:max-line-length
import { MatChipsModule, MatTableModule, MatPaginatorModule, MatSelectModule, MatListModule, MatDatepickerModule, MatFormFieldModule } from '@angular/material';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { FurySharedModule } from 'src/@fury/fury-shared.module';
import { BreadcrumbsModule } from 'src/@fury/shared/breadcrumbs/breadcrumbs.module';
import { FuryCardModule } from 'src/@fury/shared/card/card.module';
import { ListModule } from 'src/@fury/shared/list/list.module';
import { MaterialModule } from 'src/@fury/shared/material-components.module';
import { QuickInfoWidgetModule } from '../dashboard/widgets/quick-info-widget/quick-info-widget.module';
import { DemandeAbonnementComponent } from './demandeabonnement.component';
import { UpdateDemandeComponent } from './updatedemande/updatedemande.component';
import { AddDemandeComponent } from './adddemande/adddemande.component';
import { ValideprecompteComponent } from './valideprecompte/valideprecompte.component';
import { PasstocorpComponent } from './passtocorp/passtocorp.component';
import { RenewcardComponent } from './renewcard/renewcard.component';

@NgModule({
  declarations: [
    DemandeAbonnementComponent, UpdateDemandeComponent, AddDemandeComponent,ValideprecompteComponent, PasstocorpComponent, RenewcardComponent
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    MatChipsModule,
    ListModule,
    MatTableModule,
    MatPaginatorModule,
    MatSelectModule,
    MatListModule,
    MatChipsModule,
    MaterialModule,
    BreadcrumbsModule,
    NgxDatatableModule,
    FuryCardModule,
    MatChipsModule,
    QuickInfoWidgetModule,
    MatDatepickerModule,
    MatFormFieldModule,
    FurySharedModule,
  ],
  entryComponents: [AddDemandeComponent, UpdateDemandeComponent , ValideprecompteComponent]
})
export class DemandeAbonnementModule { }
