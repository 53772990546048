import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GestionsoldeComponent } from './gestionsolde.component';
import { ReactiveFormsModule } from '@angular/forms';
import { MatInputModule, MatChipsModule, MatDatepickerModule } from '@angular/material';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatDatetimepickerModule } from '@mat-datetimepicker/core';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { BreadcrumbsModule } from 'src/@fury/shared/breadcrumbs/breadcrumbs.module';
import { FuryCardModule } from 'src/@fury/shared/card/card.module';
import { MaterialModule } from 'src/@fury/shared/material-components.module';
import { ShowDeliveryAccountSoldeComponent } from './show-delivery-account-solde/show-delivery-account-solde.component';

@NgModule({
  declarations: [GestionsoldeComponent, ShowDeliveryAccountSoldeComponent],
  
  imports: [
    CommonModule,
    ReactiveFormsModule,
    MaterialModule,
    BreadcrumbsModule,
    MatInputModule,
    NgxDatatableModule,
    FuryCardModule,
    MatChipsModule,
    MatDatepickerModule,
    MatDatetimepickerModule,
    BrowserAnimationsModule
  ],
  entryComponents : [ShowDeliveryAccountSoldeComponent]
})
export class GestionsoldeModule { }
