import { LivraisonDto } from './../../../models/LivraisonDto';
import { ModelivraisonModifierComponent } from './modelivraison-modifier/modelivraison-modifier.component';
import { DeletedialogmodelivraisonComponent } from './deletedialogmodelivraison/deletedialogmodelivraison.component';
import { Router } from '@angular/router';
import { MatPaginator, MatSort, MatSnackBar, MatDialog, MatTableDataSource } from '@angular/material';
import { GenericserviceService } from 'src/app/services/genericservice.service';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { Livraison } from './../../../models/Modelivraison';
import { Component, OnInit, ViewChild } from '@angular/core';

@Component({
  selector: 'fury-modelivraison',
  templateUrl: './modelivraison.component.html',
  styleUrls: ['./modelivraison.component.scss']
})
export class ModelivraisonComponent implements OnInit {


  private livraisons: Livraison[];
  private selectedValue: Livraison;
  livraisonForm: FormGroup;


  livraisonId: string;

  livraisonmodename: string;
  livraisonisdeleted: boolean;
  livraisondatecreation: string;
  livraison: Livraison;

  displayedColumns: string[] = ['livraisonmodename', 'livraisonmodecode', 'livraisonmodedatecreation', 'actions'];
  dataSource: any;


  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;


  constructor(private router: Router, private livraisonService: GenericserviceService, private formBuilder: FormBuilder, private snackbar: MatSnackBar, public dialog: MatDialog) {

  }



  ngOnInit() {
    this.livraisonService.type = 'livraison';
    this.findAll();


    this.livraisonForm = new FormGroup({
      livraisonmodename: new FormControl('', Validators.required),
      livraisonmodecode: new FormControl('', Validators.required)


    });

    this.livraisonForm = this.formBuilder.group({
      livraisonmodename: ['', Validators.required],
      livraisonmodecode: ['', Validators.required]

    })


  }


  onSubmit() {

    console.info('-----------------------------------------');


    if (this.livraisonForm.valid) {
      // saving object
      let obj: LivraisonDto = new LivraisonDto();
      obj.livraisonmodename = this.livraisonForm.controls['livraisonmodename'].value;
      obj.livraisonmodcode = this.livraisonForm.controls['livraisonmodecode'].value;

      this.livraisonService.saveObject(obj).subscribe(data => {

        console.log(data);
        switch (data.status) {
          case 201: {
            // this.loading.dismiss('messagesService.loadMessagesOverview');

            let resp: Livraison;
            resp = data.body;
            console.log(resp);
            console.log("------------------------------------");
            this.snackbar.open('Success mode de livraison Créé', 'OK', {
              duration: 2000
            });


            if (resp != null) {

              console.log("-------------- success livraison created ----------------");
              this.findAll();
              //this.router.navigateByUrl('./');

              // this.router.navigateByUrl('/dashboard');
            } else {
              // this.FormReg(" Mot de passe ou login incorrect ");
            }
            break;
          }
        }
      },
        error => {
          console.log("erreur retour");
          // this.loading.dismiss('messagesService.loadMessagesOverview');
          switch (error.status) {
            case 409:
              //this.FormReg(" Mot de passe ou login incorrect ");
              this.snackbar.open('Echec modification', 'Le mode de livraison existe deja', {
                duration: 2000
              });
              break;
            case 401:
              //this.FormReg(" Mot de passe ou login incorrect ");
              break;
            default: {
              // this.FormReg(" erreur de connexion !!! Veuillez ressayer");
              this.snackbar.open('Echec modification', 'OK', {
                duration: 2000
              });
              break;
            }
          }

        });

      this.livraisonForm.reset({ livraisonmodename: '' });

      /////////////
    } else {
      this.validateAllFormFields(this.livraisonForm);
      this.snackbar.open('Veuillez saisir toutes les valeurs svp', 'OK', {
        duration: 1500
      });



    }




  }

  validateAllFormFields(formGroup: FormGroup) {
    Object.keys(formGroup.controls).forEach(field => {
      const control = formGroup.get(field);
      if (control instanceof FormControl) {
        control.markAsTouched({ onlySelf: true });
      } else if (control instanceof FormGroup) {
        this.validateAllFormFields(control);
      }
    });
  }


  findAll() {

    this.livraisonService.findAll().subscribe(data => {
      console.log(data);
      switch (data.status) {
        case 200: {
          // this.loading.dismiss('messagesService.loadMessagesOverview');
          let resp: Livraison[];
          resp = data.body;
          console.log("------------------------------------");
          console.log(resp);
          console.log("------------------------------------");
          console.log(resp[0].livraisonmodedatecreation);
          this.livraisons = resp;
          // this.dataSource = valeurs;
          this.dataSource = new MatTableDataSource<Livraison>(resp);
          this.dataSource.paginator = this.paginator;
          this.dataSource.sort = this.sort;


          if (resp != null) {
            //this.resetFunction();


            console.log("------------- livraisons found ------------------");

          } else {

            // this.FormReg(" Mot de passe ou login incorrect ");
          }
          break;
        }
      }
    },
      error => {
        console.log("erreur retour");
        // this.loading.dismiss('messagesService.loadMessagesOverview');
        switch (error.status) {
          case 401:
            //this.FormReg(" Mot de passe ou login incorrect ");
            break;
          default: {
            // this.FormReg(" erreur de connexion !!! Veuillez ressayer");
            break;
          }
        }
      }
    );

  }


  updateLivraison(row) {
    console.log(' edit livraison ');

    if (this.livraisonId) {
      console.log('id ok ----editing ');

      let livraison: Livraison = new Livraison();
      livraison.livraisonmodeid = this.livraisonId;
      livraison.livraisonmodename = this.livraisonForm.controls['livraisonmodename'].value;
      // this.valeurForm.controls['livraisonLastName'].value,
      // this.valeurForm.controls['livraisonEmail'].value;

      // updating object
      this.livraisonService.update(livraison).subscribe();
    }


  }


  deleteLivraison(row) {
    console.log('-- Delete livraison ---  ' + row);

    if (row) {
      let livraison: Livraison = row;
      this.openDialogDeletes(livraison);

    }




  }

  openDialogDelete(livraison): void {
    const dialogRef = this.dialog.open(DeletedialogmodelivraisonComponent, {
      width: '250px',
      data: { livraison: livraison }
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed ' + result);
      //this.animal = result;
      this.findAll()
    });
  }



  openDialogDeletes(livraison): void {
    this.dialog.open(DeletedialogmodelivraisonComponent, {
      width: '450px',
      data: { livraison: livraison }
    }).afterClosed().subscribe((res) => {
      console.log(res);

      if (!res) {
        return;
      }

      this.livraisonService.delete(res).subscribe(
        data => {
          console.log('-- Delete livraison response ---  ' + data.statusText + ' ' + data.status);

          switch (data.status) {
            case 200: {
              this.snackbar.open('Success supprimé', 'OK', {
                duration: 2000
              });
              this.findAll();
              break;
            }
            default:
              this.snackbar.open('Echec suppression, livraison not found', 'veuillez rafraichir la page svp', {
                duration: 2000
              });
              this.findAll();
          }
        }
      );


    });
  }


  openDialog(row): void {
    //console.log('------onpen dialog -------');

    console.log(row);
    this.selectedValue = row;
    if (row) {
      this.callLivraisonDetail();
    }
  }


  callLivraisonDetail() {
    console.log('------onpen dialog -------');

    const dialogRef = this.dialog.open(ModelivraisonModifierComponent, {
      maxWidth: '80%',

      data: { livraison: this.selectedValue }
    });

    dialogRef.afterClosed().subscribe(resp => {

      console.log('The dialog was closed .. resp --- ' + resp);



      this.findAll();
      console.log('======================');
      // this.livraison = result;

    });
  }
}
