import { HttpClient, HttpResponse, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ConfigService } from 'ngx-envconfig';
import { Observable } from 'rxjs';
import { Agentstorage } from '../models/agentstorage';

@Injectable({
  providedIn: 'root'
})
export class TypecarteService {

  private url = this.configService.get('ipMS');

  authorization: string = " ";
  caissierid: string = " ";


  constructor(private http: HttpClient, private configService: ConfigService) {
    let u = new Agentstorage();
    u = JSON.parse(localStorage.getItem('curentuser'));

    if (u != null) {
      this.authorization = u.token;
      this.caissierid = u.agent.agentid;

      console.log("authorization == " + this.authorization);
    }
  }

  getAll(): Observable<HttpResponse<any>> {
    return this.http.get(this.url + "typecarte/enable",
      {
        observe: 'response',
        headers: new HttpHeaders({ 'Access-Control-Allow-Origin': '*' })
          .set("content-Type", "application/json")
          .set("Authorization", this.authorization)
      });
  }

  public post(value: any, url1: string): Observable<HttpResponse<any>> {
    console.log(JSON.stringify(value));
    return this.http.post(this.url + url1, JSON.stringify(value), {
      observe: 'response',
      headers: new HttpHeaders({ 'Access-Control-Allow-Origin': '*' })
        .set("content-Type", "application/json")
        .set("Authorization", this.authorization)
    });
  }



  public put(value: any, url1: string): Observable<HttpResponse<any>> {
    console.log(JSON.stringify(value));
    return this.http.put(this.url + url1, JSON.stringify(value), {
      observe: 'response',
      headers: new HttpHeaders({ 'Access-Control-Allow-Origin': '*' })
        .set("content-Type", "application/json")
        .set("Authorization", this.authorization)
    });
  }



  public delete( url1: string): Observable<HttpResponse<any>> {
    return this.http.delete(this.url + url1, {
      observe: 'response',
      headers: new HttpHeaders({ 'Access-Control-Allow-Origin': '*' })
        .set("content-Type", "application/json")
        .set("Authorization", this.authorization)
    });
  }

}
