import { ConfigService } from 'ngx-envconfig';
import { Injectable } from '@angular/core';
import { Agentstorage } from '../../models/agentstorage';
import { HttpClient, HttpEvent, HttpParams, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CarteabonnementfileService {

  private url = this.configService.get('ipMS') + 'carte/addbyfile/'


  authorization: string = " ";
  caissierid: string = " ";
  cassieremail: string = " ";


  constructor(private http: HttpClient, private configService: ConfigService) {
    let u = new Agentstorage();
    u = JSON.parse(localStorage.getItem('curentuser'));

    if (u != null) {
      this.authorization = u.token;
      this.caissierid = u.agent.agentid;
      this.cassieremail = u.agent.agentemail ;
    }
  }

  pushFileToStorage(file: File): Observable<HttpEvent<{}>> {

    //this.logger.trace("FileNull " + (file == null));
    let formdata: FormData = new FormData();
    formdata.append('file', file);


    let Params = new HttpParams();
    Params = Params.set('formdata', 'value');

    const req = new HttpRequest('POST', this.url + this.cassieremail , formdata, {
      // const req = new HttpRequest('POST', this.repertoireUrl + '/' + 'add' + '/' + repertoire.repertoirename + '/' + file, {

      reportProgress: true,
      responseType: 'text',

    });
    /*this.logger.trace('REQ');
    this.logger.trace(req);*/
    return this.http.request(req);
  }


}
