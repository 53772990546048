export class Cartedto {
    carteabonnementagentid: string;
    carteabonnementcodereference: string;
    carteabonnementcodesecret: string;
    carteabonnementid: string;
    carteabonnementmodecreation: string;
    telephone: string;
    carteabonnementreference : string ;
    agentid : string ;
    clientid: string ;
    userpisteaudit : string ;
    typedemande : string ;
}
