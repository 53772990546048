import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'fury-deletedecouvert',
  templateUrl: './deletedecouvert.component.html',
  styleUrls: ['./deletedecouvert.component.scss']
})
export class DeletedecouvertComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
