import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { NGXLogger } from 'ngx-logger';
import { Subscription } from 'rxjs';
import { ClientCorporate } from 'src/app/models/Clienttype';
import { GestioncorporateService } from '../../gestioncorporate/gestioncorporate.service';

@Component({
  selector: 'fury-dialog-corporate',
  templateUrl: './dialog-corporate.component.html',
  styleUrls: ['./dialog-corporate.component.scss']
})
export class DialogCorporateComponent implements OnInit {

  public getItemSub: Subscription;
  client: ClientCorporate[] = [];

  form: FormGroup;

  isDefault : boolean = false ;


  constructor(@Inject(MAT_DIALOG_DATA) public defaults: any,
    public dialogRef: MatDialogRef<DialogCorporateComponent>,
    private fb: FormBuilder, private pointservice: GestioncorporateService,
    private logger: NGXLogger) {
  }

  ngOnInit() {
    this.getAllCorporate();
    this.form = this.fb.group({
      matricule: ['', Validators.required],
      corporateid: ['', Validators.required]
    });
  }

  
  submit() {
    this.dialogRef.close(this.form.value);
    this.form.disabled;
  }



  getAllCorporate() {
    this.getItemSub = this.pointservice.get('clienttype')
      .subscribe(data => {
        const statuscode = data.status;
        this.logger.trace('DATA', data);
        switch (statuscode) {
          case 200: {
            this.client = data.body;

            // this.temp = data.body;
            break;
          }

          case 204: {
            // this.snack.open('Aucune TVA disponible', 'OK', { duration: 4000 })
            break;
          }

          default: {
            // this.snack.open('Une erreur est survenue!', 'OK', { duration: 4000 })

            break;
          }

        }
      },
        error => {
          this.logger.error('error');
          this.logger.error(error);

          switch (error.status) {

            default: {

              //    this.snack.open('Une erreur est survenue!', 'OK', { duration: 4000 })

              break;
            }
          }
        });
  }

}
