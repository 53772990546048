import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator, MatSort, MatTableDataSource, MatDialog, MatSnackBar } from '@angular/material';
import { Subscription } from 'rxjs';
import { Foods } from 'src/app/models/foods';
import { FormBuilder } from '@angular/forms';
import { MenuService } from './menu.service';
import { ConfirmationService } from 'src/app/confirm/confirmation.service';
import { ApploaderService } from 'src/app/services/apploader/apploader.service';
import { NGXLogger } from 'ngx-logger';
import { ConfigService } from 'ngx-envconfig';
import { Agentstorage } from 'src/app/models/agentstorage';
import { Rolefonction } from 'src/app/models/rolefonction';
import { CreatemenuComponent } from './createmenu/createmenu.component';
import { UpdatemenuComponent } from './updatemenu/updatemenu.component';


import * as XLSX from 'xlsx';
import * as FileSaver from 'file-saver';
import { environment } from 'src/environments/environment';
const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
const EXCEL_EXTENSION = '.xlsx';


@Component({
  selector: 'fury-gestionmenu',
  templateUrl: './gestionmenu.component.html',
  styleUrls: ['./gestionmenu.component.scss']
})
export class GestionmenuComponent implements OnInit {

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  public validsatus: boolean = false;

  public getItemSub: Subscription;
  dataSource: MatTableDataSource<Foods> = new MatTableDataSource([]);
  public items: any[];
  temp = [];
  rows = [];
  //columns = [];

  // Habilitation
  public afficherPlat: boolean;
  public creationPlat: boolean;
  public modificationPlat: boolean;
  public suppressionPlat: boolean;

  public keyAfficherPlat = 'afficher plat';
  public keyCreationPlat = 'creation plat';
  public keyModificationPlat = 'modification plat';
  public keySuppressionPlat = 'suppression plat';


  displayedColumns: string[] = ['foodname', 'foodstockdisponible', 'pointventename', 'foodprice', 'categoriename', 'fooddatemenu', 'fooddownloadimageurl', 'actions'];


  columns: any[] = [
    { name: 'Plat', property: 'foodname', visible: true, isModelProperty: true },
    { name: 'Prix', property: 'foodprice', visible: true, isModelProperty: true },
    { name: 'Categorie', property: 'categoriename', visible: true, isModelProperty: true, }
  ];

  pageSize = 5;

  constructor(private dialog: MatDialog, public formBuilder: FormBuilder,
    private snackbar: MatSnackBar, private menuservice: MenuService,
    private confirmService: ConfirmationService, private loader: ApploaderService,
    private logger: NGXLogger,
    private configService: ConfigService
  ) { }

  ngOnInit() {
    this.verifierDroit();
    this.getallFood();
  }

  get visibleColumns() {
    return this.columns.filter(column => column.visible).map(column => column.property);
  }




  isDisabled(): boolean {
    return this.validsatus;
  }


  updateFilter(event) {
    const val = event.target.value.toLowerCase();
    var columns = Object.keys(this.temp[0]);
    // Removes last "$$index" from "column"
    columns.splice(columns.length - 1);

    console.log(" my column ");
    console.log(columns);
    if (!columns.length)
      return;

    const rows = this.temp.filter(function (d) {
      for (let i = 0; i <= columns.length; i++) {
        let column = columns[i];
        //console.log(d[column]);
        if (d[column] && d[column].toString().toLowerCase().indexOf(val) > -1) {
          return true;
        }
      }
    });

    this.items = rows;
    this.dataSource = new MatTableDataSource(this.items);
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  public verifierDroit() {
    this.logger.trace('BEGIN');
    // this.logger.trace('Clé', this.configService.get('keylocalstorage'));

    let agentOnline: Agentstorage;
    agentOnline = JSON.parse(localStorage.getItem(this.configService.get('keylocalstorage')));
    // this.logger.trace(agentOnline);
    let rolefontion: Rolefonction[] = agentOnline.rolefonction;
    // this.logger.trace('rolefontion', rolefontion);

    for (const key in rolefontion) {
      if (rolefontion.hasOwnProperty(key)) {
        // this.logger.trace('KEY', key);
        const element = rolefontion[key].fonction.fonctionname;
        this.logger.trace('element', element);

        if (element == this.keyAfficherPlat) {
          this.logger.trace('true');
          this.afficherPlat = true;
        }
        if (element == this.keyCreationPlat) {
          this.logger.trace('true');
          this.creationPlat = true;
        }
        if (element == this.keyModificationPlat) {
          this.logger.trace('true');
          this.modificationPlat = true;
        }
        if (element == this.keySuppressionPlat) {
          this.logger.trace('true');
          this.suppressionPlat = true;
        }
      }
    }

  }



  /*
  createMenu() {
    this.dialog.open(CreatemenuComponent, {
      data: {}
    }).afterClosed().subscribe((res) => {
      if (!res) {
        // If user press cancel
        return;
      }
    });
  }
  */

  createMenu() {
    this.dialog.open(CreatemenuComponent, { width: '720px' }).afterClosed().subscribe((res) => {
      console.log(res);

      if (!res) {
        return;
      }

      this.validsatus = true;
      let f: Foods = new Foods;
      f = res;
      f.categorieName = res.categoriename.categoriename;

      let u = new Agentstorage();
      u = JSON.parse(localStorage.getItem("curentuser"));
      if (u != null) {
        f.userpisteaudit = u.agent.agentemail + " " + u.agent.agentfirstname + " " + u.agent.agentlastname;
      }

      console.log("Resultat");
      console.log(res);

      f.foodverify = f.foodremise;
      console.log(f);
      console.log("SIZE-FILE ==> " + (res.file.size/1000));
      console.log("TYPE-FILE ==> " + res.file.type );

      if ((res.file.size / 1000) > 350) {
        this.validsatus = false;

        this.snackbar.open('Echec !! Veuillez selectionner un fichier de taille maximale de 350 KO', 'OK', {
          duration: 100000
        });
        return;
      }
      if ((res.file.type !== "image/jpeg") && (res.file.type !== "image/jpg")) {
        this.validsatus = false;
        this.snackbar.open('Echec !! Veuillez selectionner un fichier  Formats acceptés: JPG, JPEG', 'OK', {
          duration: 100000
        });
        return;
      }

      /*
      var ext = name.substring(name.lastIndexOf('.') + 1);
      if (ext.toLowerCase() == 'png') {
          return true;
      }
      */

      this.loader.open();
      this.menuservice.AddFood(f).subscribe(data => {

        console.log("data");
        console.log(data);
        let dataneutre: any = data.body;



        switch (data.status) {

          case 201: {
            /*

            let selectedFiles: any[] = []   ;

            let currentFileUpload: any = {};

            selectedFiles = dataneutre.file;

            console.log(" selectedFiles ");
            console.log(selectedFiles);
            */

            /*
            currentFileUpload = selectedFiles.item(0);

            console.log(" currentFileUpload ");
            console.log(currentFileUpload);
            */



            console.log("file to send ");
            console.log(res.file);




            this.menuservice.sendFileToStorage(res.file, dataneutre.foodid).subscribe(data => {

              this.getallFood();
              /*
              this.snackbar.open('Success ', 'OK', {
                duration: 10000
              });
              */
              this.validsatus = false;
              this.loader.close();

              let verif: Boolean = this.configService.get('postfile');

              if (verif) {
                this.menuservice.sendFileToStorageDistant(res.file).subscribe(data => {
                  console.log("======================Fichier succes retour ");
                },
                  error => {
                    console.log("======================Fichier erreur retour ");
                    console.log(error);
                  });
              }


            },
              error => {

                this.loader.close();

                this.snackbar.open('Echec !! Une erreur est survenue lors du chargement du fichier', 'OK', {
                  duration: 10000
                });
                this.validsatus = false;

                console.log("======================Fichier erreur retour ");
                console.log(error);

              }
            );

            break;
          }

        }
      },
        error => {
          switch (error.status) {

            case 409: {
              //this.getallFood();
              this.loader.close();
              this.snackbar.open('Ce plat existe deja ', 'OK', {
                duration: 10000
              });
              this.validsatus = false;
              break;
            }

            default: {
              this.loader.close();

              this.snackbar.open('Echec !! Une erreur est survenue ', 'OK', {
                duration: 10000
              });
              this.validsatus = false;
              break;
            }
          }
        });


    });
  }


  ImageIsTrue(value: string) {
    console.log("Value : " + value);
    if (value === "Image") {
      console.log("Is Image");
      return true;
    } else {
      console.log("Is Not Image");
      return false;
    }
  }








  getallFood() {
    this.getItemSub = this.menuservice.getAllFood()
      .subscribe(data => {

        let responseany: any = data;

        this.logger.trace(responseany);

        switch (data.status) {

          default: {

            /*
            console.log("retour");
            console.log(data);
            */
            let response: any = data.body;
            let resp: Foods[] = response;
            let resp1: Foods[] = [];


            if (resp) {
              for (let f of resp) {
                f.categoriename = f.categorie.categoriename;
                f.fooddescription = this.returnDesc(f.fooddescription);
                resp1.push(f);

              }
            }



            this.items = this.temp = resp1;
            this.dataSource = new MatTableDataSource(this.items);
            this.dataSource.paginator = this.paginator;
            this.dataSource.sort = this.sort;

            break;
          }


        }
      },
        error => {
          this.logger.error("error");
          this.logger.error(error);

          switch (error.status) {

            default: {
              //this.loader.close();
              /*
              this.snackbar.open('Erreur suvernue ', 'OK', {
                duration: 10000
              });
              */
              break;
            }
          }
        });
  }


  openPopUp(value) {
    this.dialog.open(UpdatemenuComponent,
      {
        //disableClose: true,
        width: '720px',
        data: { title: '', payload: value }
      }
    ).afterClosed().subscribe((res) => {
      console.log(res);

      if (!res) {
        return;
      }

      this.logger.trace('RES', res);
      let f: Foods = new Foods;
      f = res;
      f.categorieName = res.categoriename;
      f.foodpicture = res.foodpicture;

      let u = new Agentstorage();
      u = JSON.parse(localStorage.getItem("curentuser"));
      if (u != null) {
        f.userpisteaudit = u.agent.agentemail + " " + u.agent.agentfirstname + " " + u.agent.agentlastname;
      }
      f.foodverify = f.foodremise;

      this.loader.open();
      this.logger.trace('OPEN', value);
      this.logger.trace('OPEN', f);

      this.menuservice.updateFood(f).subscribe(data => {
        this.logger.trace('DATA', data);


        switch (data.status) {

          case 201: {

            console.log("data");
            console.log(data);

            this.getallFood();
            this.loader.close();
            this.snackbar.open('Menu mis a jour avec succes ', 'OK', {
              duration: 10000
            });

            break;
          }

        }
      },
        error => {
          this.logger.error('ERREUR', error);
          switch (error.status) {

            case 409: {
              //this.getallFood();
              this.loader.close();
              this.snackbar.open('Ce plat existe deja ', 'OK', {
                duration: 10000
              });
              break;
            }

            default: {
              this.loader.close();

              this.snackbar.open('Echec !! Une erreur est survenue ', 'OK', {
                duration: 10000
              });
              break;
            }
          }
        });


    });
  }


  deleteItem(row) {
    this.confirmService.confirm({
      message: `Voulez vous supprimer le plat : ${row.foodname}?  `
    })
      .subscribe(res => {
        if (res) {

          console.log("resultat obtenu ");
          console.log(row);

          this.menuservice.DeleteFood(row.foodid).subscribe(data => {

            this.getallFood();
            this.snackbar.open('Success ', 'OK', {
              duration: 10000
            });

          },
            error => {

              this.snackbar.open('Echec !! Une erreur est survenue lors du chargement du fichier', 'OK', {
                duration: 10000
              });

              console.log("erreur retour");
              console.log(error);

            }
          );

        }

      })
  }



  returnDesc(value: string) {
    if (value) { // Verifier si une valeur est renseigné
      if (value.length > 60) {
        return value.substring(0, 27) + "...";
      } else {
        return value;
      }
    }
  }





  exportToXls() {
    let itemscsv: any[] = [];


    if (this.items.length > 0) {
      this.items.forEach(function (element) {
        console.log(element);


        var my_json = {
          "Date creation": element.fooddatecreation,
          "Plat": element.foodname,
          "Categorie": element.categorie.categoriename,
          "Pix": element.foodprice,
          "Stock ": element.foodstockdisponible,
          "Stock Alerte": element.foodstockalerte,
          "Date validite  ": element.fooddatemenu
        };
        console.log("my_json");
        console.log(my_json);
        itemscsv.push(my_json);
      });
      console.log(itemscsv);

      this.exportAsExcelFile(itemscsv, 'Historique Plat');

    }
  }
  public exportAsExcelFile(json: any[], excelFileName: string): void {
    const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(json);
    const workbook: XLSX.WorkBook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
    const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
    this.saveAsExcelFile(excelBuffer, excelFileName);
  }
  private saveAsExcelFile(buffer: any, fileName: string): void {
    const data: Blob = new Blob([buffer], { type: EXCEL_TYPE });
    FileSaver.saveAs(data, fileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION);
  }


}
