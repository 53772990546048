import { Component, OnInit } from '@angular/core';
import { Historiq, Client, Statcpteclient } from 'src/app/models/Agent';
import { Subscription } from 'rxjs';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { NGXLogger } from 'ngx-logger';
import { MatSnackBar, MatDialog } from '@angular/material';
import { ConfigService } from 'ngx-envconfig';
import { DatePipe } from '@angular/common';
import { ApploaderService } from 'src/app/services/apploader/apploader.service';
import { GestionclientService } from './gestionclient.service';
import { StatforclientService } from '../statforclient/statforclient.service';
import { Router } from '@angular/router';

import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';

const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
const EXCEL_EXTENSION = '.xlsx';



import * as jsPDF from 'jspdf';
import 'jspdf-autotable';
import { UserOptions } from 'jspdf-autotable';
import { AddclientComponent } from './addclient/addclient.component';
import { Agentstorage } from 'src/app/models/agentstorage';
import { FilecustomerComponent } from './filecustomer/filecustomer.component';


interface jsPDFWithPlugin extends jsPDF {
  autoTable: (options: UserOptions) => jsPDF;
}





@Component({
  selector: 'fury-gestionclient',
  templateUrl: './gestionclient.component.html',
  styleUrls: ['./gestionclient.component.scss']
})
export class GestionclientComponent implements OnInit {


  scrollBarHorizontal = (window.innerWidth < 1200);

  public statcpteclient: Statcpteclient = new Statcpteclient();
  public getItemSub: Subscription;
  public items: Client[];
  temp = [];

  public itemForm: FormGroup;

  user: Client;

  constructor(private clientServ: GestionclientService, private logger: NGXLogger, private fb: FormBuilder, private statforclientserv: StatforclientService,
    private snackbar: MatSnackBar, private configService: ConfigService,
    private datePipe: DatePipe, private dialog: MatDialog, private loader: ApploaderService, private router: Router
  ) { }



  ngOnInit() {
    this.getData();
    this.getStatData();
    this.itemForm = this.fb.group({
      to: ['', Validators.compose([Validators.required])],
      from: ['', Validators.compose([Validators.required])],
    });
  }


  addFile() {
    this.dialog.open(FilecustomerComponent, { width: '720px' }).afterClosed().subscribe((res) => {
      console.log(res);
      if (!res) {
        return;
      }

      this.clientServ.pushFileToStorage(res.file, res.corporate).subscribe(data => {
        this.getData();
        this.snackbar.open('Fichier chargé avec succes ', 'OK', {
          duration: 10000
        });
      },
        error => {
          console.log(error);
          this.snackbar.open('Erreur lors du chargement du fichier ', 'OK', {
            duration: 10000
          });
        });

    });
  }


  createCompteClient() {
    this.dialog.open(AddclientComponent, { width: '720px' }).afterClosed().subscribe((res) => {
      console.log(res);
      if (!res) {
        return;
      }
      const request: any = res;
      let u = new Agentstorage();
      let idCorporate = localStorage.getItem('idCorporate');
      u = JSON.parse(localStorage.getItem('curentuser'));

      if (u != null) {
        request.userpisteaudit = u.agent.agentemail + ' ' + u.agent.agentfirstname + ' ' + u.agent.agentlastname;
      }
      
      this.clientServ.postData(request, 'client/addfrombo/' + idCorporate + '/' + u.agent.agentemail).subscribe(data => {
        this.logger.trace('DATA', data);
        this.getData();
        this.loader.close();
        this.snackbar.open('Compte client crée avec succes ', 'OK', {
          duration: 10000
        });
      },
        error => {
          this.logger.error('ERREUR', error);
          switch (error.status) {

            case 409: {
              // this.getallFood();
              this.loader.close();
              this.snackbar.open('Ce compte client existe déjà ', 'OK', {
                duration: 10000
              });
              break;
            }

            default: {
              this.loader.close();

              this.snackbar.open('Echec !! Une erreur est survenue ', 'OK', {
                duration: 10000
              });
              break;
            }
          }
        });
    });
  }

  updateFilter(event) {
    const val = event.target.value.toLowerCase();
    var columns = Object.keys(this.temp[0]);
    // Removes last "$$index" from "column"
    columns.splice(columns.length - 1);

    // console.log(columns);
    if (!columns.length)
      return;

    const rows = this.temp.filter(function (d) {
      for (let i = 0; i <= columns.length; i++) {
        let column = columns[i];
        // console.log(d[column]);
        if (d[column] && d[column].toString().toLowerCase().indexOf(val) > -1) {
          return true;
        }
      }
    });

    this.items = rows;
  }


  refreshDatatable() {
    this.itemForm.reset();
    this.getData();
  }

  getData() {
    this.getItemSub = this.clientServ.getData()
      .subscribe(data => {

        switch (data.status) {

          case 200: {
            this.logger.trace('data', data);
            this.items = this.temp = data.body;
            break;
          }



          default: {

            this.logger.trace('data', data);
            this.items = this.temp = data.body;

            break;
          }


        }
      },
        error => {
          this.logger.trace('error');
          this.logger.error(error);

          switch (error.status) {

            default: {
              this.snackbar.open('Un erreur est survenue', 'OK', {
                duration: 10000
              });
              break;
            }
          }
        });
  }




  getStatData() {
    this.getItemSub = this.clientServ.getStatData()
      .subscribe(data => {
        switch (data.status) {
          default: {
            this.logger.trace('data', data);
            this.statcpteclient = data.body;
            break;
          }
        }
      },
        error => {
          this.logger.trace('error');
          this.logger.error(error);

          switch (error.status) {

            default: {
              this.snackbar.open('Un erreur est survenue', 'OK', {
                duration: 10000
              });
              break;
            }
          }
        });
  }

  getClientById(value: string) {
    this.getItemSub = this.statforclientserv.getDataForUser(value)
      .subscribe(data => {
        switch (data.status) {
          case 200: {
            this.logger.trace('data', data);
            this.user = this.temp = data.body;
            this.statforclientserv.$user = this.user;

            //let valuefinale = valuefinale + "/" + this.user.clientid;
            let valuefinale = "/yolinbo/statforclient/stat/" + this.user.clientid;
            this.router.navigate([valuefinale]);

            break;
          }

          case 204: {
            break;
          }

          default: {
            /*
                        this.logger.trace('data', data);
                        this.user = this.temp = data.body;
            */
            break;
          }


        }
      },
        error => {
          this.logger.trace('error');
          this.logger.error(error);

          switch (error.status) {

            default: {
              /*
              this.snackbar.open('Un erreur est survenue', 'OK', {
                duration: 10000
              });
              */
              break;
            }
          }
        });
  }



  redirectToDetail(value: Client) {
    this.getClientById(value.clientid);
  }




  public exportCsv() {

    let itemscsv: any[] = [];

    this.items.forEach(function (element) {
      console.log(element);
      var my_json = {
        "Date de creation": element.clientdatecreation,
        "Email": element.clientemail,
        "Contact ": element.clienttelephone,
        "Nom": element.clientfirstname,
        "Prenom": element.clientlastname
      };
      console.log("my_json");
      console.log(my_json);
      itemscsv.push(my_json);
    });
    console.log(itemscsv);

    this.exportAsExcelFile(itemscsv, 'Compte client');
  }


  public exportAsExcelFile(json: any[], excelFileName: string): void {
    const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(json);
    const workbook: XLSX.WorkBook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
    const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
    this.saveAsExcelFile(excelBuffer, excelFileName);
  }
  private saveAsExcelFile(buffer: any, fileName: string): void {
    const data: Blob = new Blob([buffer], { type: EXCEL_TYPE });
    FileSaver.saveAs(data, fileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION);
  }



  exportToPdf() {

    let itemscsv: any[] = [];
    let itemslocal: any[] = this.items;
    if (this.items.length > 0) {
      this.items.forEach(function (element) {
        console.log(element);


        var my_json = {
          "Date de creation": element.clientdatecreation,
          "Email": element.clientemail,
          "Contact ": element.clienttelephone,
          "Nom": element.clientfirstname,
          "Prenom": element.clientlastname
        };

        console.log("my_json");
        console.log(my_json);
        itemscsv.push(my_json);
      });

    }

    console.log(itemscsv);
    var columns = [
      { title: "Date de creation", dataKey: "Date de creation" },
      { title: "Email", dataKey: "Email" },
      { title: "Contact", dataKey: "Contact" },
      { title: "Nom", dataKey: "Nom" },
      { title: "Prenom", dataKey: "Prenom" }
    ];
    var rows = itemscsv;

    const doc = new jsPDF('portrait', 'px', 'a4') as jsPDFWithPlugin;
    doc.autoTable({
      body: rows,
      columns: columns,
      didParseCell: function (data) {
        if (data.column.raw.displayProperty) {
          var prop = data.column.raw.displayProperty;
          var text = data.cell.raw[prop];
          if (text && text.length > 0) {
            data.cell.text = text;
          }
        }
      }
    });
    doc.save('compte-client.pdf');
  }




}
