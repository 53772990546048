import { Component, Inject, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { NGXLogger } from 'ngx-logger';
import { Subscription } from 'rxjs/internal/Subscription';
import { Abattement } from 'src/app/models/pointvente';
import { AbbatementService } from '../../abbatement/abbatement.service';

@Component({
  selector: 'fury-addformule',
  templateUrl: './addformule.component.html',
  styleUrls: ['./addformule.component.scss']
})
export class AddFormuleComponent implements OnInit {
  form: FormGroup;
  constructor(@Inject(MAT_DIALOG_DATA) public defaults: any,
    public dialogRef: MatDialogRef<AddFormuleComponent>,
    private fb: FormBuilder,
    private abbatementService: AbbatementService,
    private logger: NGXLogger) {
  }

  getItemSub: Subscription;
  items: Abattement[];
  scrollBarHorizontal = (window.innerWidth < 1200);
  temp = [];
  rows = [];
  columns = [];

  public abattements : string[] = [];


  ngOnInit() {
    this.getall();
    this.form = this.fb.group({
      formuledescription: ['', Validators.compose([Validators.required])],
      formuleduree: [''],
      formuleisprecompte: ['', Validators.compose([Validators.required])],
      formulelibelle: ['', Validators.compose([Validators.required, Validators.minLength(3)])],
      formulemontant: ['', Validators.compose([Validators.required])],
      //formulemontantdecouvert: [''],
      formulenom: ['', Validators.compose([Validators.required, Validators.minLength(3)])]
      //userpisteaudit: ['', Validators.compose([Validators.required])],
    });
  }

  submit() {
    let request : any = this.form.value ;
    request.lsAbattement=this.abattements ;
    this.logger.trace(request);
    this.dialogRef.close(request);
  }



  onChangeAbtm(event) {
    let result : any = event ;
    if(result.source.selected) {
      this.abattements.push(event.source.value.abattementid);
    } else {
      this.abattements = this.abattements.filter(item => item != event.source.value.abattementid);
    }
  }
  

  


  getall() {
    this.getItemSub = this.abbatementService.getAll('parameter/abattement/getallabattement')
      .subscribe(data => {

        let statuscode = data.status;
        this.logger.trace('DATA', data);

        switch (statuscode) {

          case 200: {
            this.items = data.body;
            break;
          }

          case 204: {
            break;
          }

          default: {

            break;
          }

        }
      },
        error => {
          this.logger.error("error");
          this.logger.error(error);

          switch (error.status) {

            default: {
         //     this.snack.open('Une erreur est survenue!', 'OK', { duration: 4000 })

              break;
            }
          }
        });
  }

}
