import { Component, OnInit } from '@angular/core';
import { AttenteService } from './attente.service';
import { MatDialog, MatSnackBar, MatTableDataSource } from '@angular/material';
import { FormBuilder } from '@angular/forms';
import { DatePipe } from '@angular/common';
import { ConfirmationService } from 'src/app/confirm/confirmation.service';
import { ApploaderService } from 'src/app/services/apploader/apploader.service';
import { NgxPermissionsService } from 'ngx-permissions';
import { NGXLogger } from 'ngx-logger';
import { ConfigService } from 'ngx-envconfig';
import { Historiq } from 'src/app/models/Agent';
import { Subscription } from 'rxjs';
import { ShowComponent } from './show/show.component';



import * as XLSX from 'xlsx';
import * as FileSaver from 'file-saver';


const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
const EXCEL_EXTENSION = '.xlsx';

@Component({
  selector: 'fury-attente',
  templateUrl: './attente.component.html',
  styleUrls: ['./attente.component.scss']
})
export class AttenteComponent implements OnInit {

  scrollBarHorizontal = (window.innerWidth < 1200);


  cumul: number = 0;
  ca: number = 0;

  public items: any[];
  public itemsFinal: Historiq[];
  temp = [];
  rows = [];
  columns = [];
  public commands: Array<Historiq> = [];
  dataSource: MatTableDataSource<Historiq> = new MatTableDataSource([]);

  public getItemSub: Subscription;


  constructor(private dialog: MatDialog, public formBuilder: FormBuilder,
    private snackbar: MatSnackBar, private attenteserv: AttenteService,
    private datePipe: DatePipe,
    private confirmService: ConfirmationService, private loader: ApploaderService,
    private permissionsService: NgxPermissionsService, private logger: NGXLogger,
    private configService: ConfigService, private fb: FormBuilder) { }


  ngOnInit() {
    this.getallCommandes();
  }



  getallCommandes() {
    this.getItemSub = this.attenteserv.getAllCommandes()
      .subscribe(data => {

        let responseany: any = data;

        console.log(responseany);
        this.logger.trace('DATA', data);

        switch (data.status) {

          default: {
            let response: any = data.body;
            let resp1: Historiq[] = response;

            let respfinal: Historiq[] = [];
            for (let rp of resp1) {
              if (rp.client != null) {
                rp.clientname = rp.client.clientemail;
              }
              if (rp.agent != null) {
                rp.agentname = rp.agent.agentemail;
              }
              respfinal.push(rp);
            }

            this.items = this.temp = respfinal;
            this.cumulAndChiffreAffaire();
            this.itemsFinal = respfinal;
            this.dataSource = new MatTableDataSource(this.items);
            break;
          }


        }
      },
        error => {
          console.log("error");
          console.log(error);

          switch (error.status) {

            default: {
              let response: any = [];
              let resp1: Historiq[] = response;
              let respfinal: Historiq[] = [];
              this.items = this.temp = respfinal;
              this.dataSource = new MatTableDataSource(this.items);
              break;
            }
          }
        });
  }

  updateFilter(event) {
    const val = event.target.value.toLowerCase();
    var columns = Object.keys(this.temp[0]);
    // Removes last "$$index" from "column"
    columns.splice(columns.length - 1);

    // console.log(columns);
    if (!columns.length)
      return;

    const rows = this.temp.filter(function (d) {
      for (let i = 0; i <= columns.length; i++) {
        let column = columns[i];
        // console.log(d[column]);
        if (d[column] && d[column].toString().toLowerCase().indexOf(val) > -1) {
          return true;
        }
      }
    });

    this.items = rows;
  }




  cumulAndChiffreAffaire() {
    this.cumul = 0;
    this.ca = 0;
    for (const item of this.items) {
      this.cumul = this.cumul + 1;
      this.ca = this.ca + item.commandtotalpricepayer;
    }
  }




  opendetail(value: any) {

    this.dialog.open(ShowComponent,
      {
        width: '720px',
        data: { title: '', payload: value }
      }
    ).afterClosed().subscribe((res) => {
      console.log(res);
      if (!res) {
        return;
      }
    });
  }








  exportToXls() {
    let itemscsv: any[] = [];


    if (this.items.length > 0) {
      this.items.forEach(function (element) {
        console.log(element);

        var my_json = {
          "Reference commande": element.commandreference,
          "Total": element.commandtotalpricepayer,
          "Client": element.client.clientemail,
          "Date atente ": element.commanddateattente
        };
        console.log("my_json");
        console.log(my_json);
        itemscsv.push(my_json);
      });
      console.log(itemscsv);

      this.exportAsExcelFile(itemscsv, 'Historique Attente');

    }
  }
  public exportAsExcelFile(json: any[], excelFileName: string): void {
    const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(json);
    const workbook: XLSX.WorkBook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
    const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
    this.saveAsExcelFile(excelBuffer, excelFileName);
  }
  private saveAsExcelFile(buffer: any, fileName: string): void {
    const data: Blob = new Blob([buffer], { type: EXCEL_TYPE });
    FileSaver.saveAs(data, fileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION);
  }



}
