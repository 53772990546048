import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MatSnackBar, MAT_DIALOG_DATA } from '@angular/material';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { CustomValidators } from 'ng2-validation';
import { Typecarte } from 'src/app/models/heurecommande';
import { NGXLogger } from 'ngx-logger';
import { Subscription } from 'rxjs/internal/Subscription';
import { TypecarteService } from 'src/app/typecarte/typecarte.service';

@Component({
  selector: 'fury-addcarte',
  templateUrl: './addcarte.component.html',
  styleUrls: ['./addcarte.component.scss']
})
export class AddcarteComponent implements OnInit {

  enabledForm: FormGroup;
  items: Typecarte[];
  getItemSub: Subscription;
  temp = [];


  constructor(@Inject(MAT_DIALOG_DATA) public data: any, private logger: NGXLogger, private typecarteService: TypecarteService ,private snack: MatSnackBar,
    public dialogRef: MatDialogRef<AddcarteComponent>) {
  }
  ngOnInit() {
    this.getall();
    this.enabledForm = new FormGroup({
      carteabonnementcodereference: new FormControl('', [Validators.required, Validators.minLength(16), Validators.maxLength(16), CustomValidators.number]),
      typecartecode: new FormControl('' , [Validators.required])
    });
  }


  onSelectTypecard(item) {
    this.enabledForm.controls['typecartecode'].setValue(item);
  }

  getall() {
    this.getItemSub = this.typecarteService.getAll()
      .subscribe(data => {

        let statuscode = data.status;
        this.logger.trace('DATA', data);

        switch (statuscode) {

          case 200: {
            this.items = data.body;
            this.temp = data.body;
            break;
          }

          case 204: {
            this.snack.open('Aucun type carte disponible', 'OK', { duration: 4000 })
            break;
          }

          default: {
            this.snack.open('Une erreur est survenue!', 'OK', { duration: 4000 })

            break;
          }

        }
      },
        error => {
          this.logger.error("error");
          this.logger.error(error);

          switch (error.status) {

            default: {
              this.snack.open('Une erreur est survenue!', 'OK', { duration: 4000 })

              break;
            }
          }
        });
  }

  buildItemForm(item) {
    //this.value = item;

  }


  submit() {

    this.dialogRef.close(this.enabledForm.value);
  }

}
