export class Searchbydate {
    datedebut: string;
    datefin: string;
    limit: number = 10;
    size: number = 1;
    provenance: boolean;
    livraison: string;
    paiement: string;
    check : boolean = false ;
    checklivraison : boolean = false ;
    checkpaiement : boolean = false ;

}


export class Searchstatuser {
    debut: string;
    fin: string;
}