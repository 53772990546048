import { Component, OnInit, Inject } from '@angular/core';
import { Subscription } from 'rxjs';
import { Categories } from 'src/app/models/categories';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { MenuService } from '../menu.service';
import { NGXLogger } from 'ngx-logger';
import { Heurecommande } from 'src/app/models/heurecommande';
import { Tva } from 'src/app/models/tva';
import { Pointvente } from 'src/app/models/pointvente';

import { HeureService } from '../../heurecmd/heure.service';
import { TvaService } from '../../tva/tva.service';
import { PointventeService } from '../../gestionpointvente/pointvente.service';

@Component({
  selector: 'fury-createmenu',
  templateUrl: './createmenu.component.html',
  styleUrls: ['./createmenu.component.scss']
})
export class CreatemenuComponent implements OnInit {

  selectedFiles: FileList;
  currentFileUpload: File;


  verifyremise: boolean = false;
  verifyremisemontant: boolean = false;


  public getItemSub: Subscription;
  itemCategories: Categories[] = [];
  heurecmd: Heurecommande[] = [];
  tva: Tva[] = [];
  pointvente: Pointvente[] = [];

  validite : any[] = ['permanente','date specifique'];

  checkdate : boolean = true ;

  form: FormGroup;

  constructor(@Inject(MAT_DIALOG_DATA) public defaults: any,
    public dialogRef: MatDialogRef<CreatemenuComponent>,
    private fb: FormBuilder, private menuservice: MenuService,
    private tvaservice: TvaService,
    private pointventeservice: PointventeService,
    private heureservice: HeureService,
    private logger: NGXLogger) {
  }

  //, Validators.maxLength(116)

  ngOnInit() {
    this.getallCategories();
    this.getallHeurecmd();
    this.getallTva();
    this.getallPointvente();

    this.form = this.fb.group({
      foodname: ['', Validators.compose([Validators.required, Validators.maxLength(31)])],
      fooddescription: ['', Validators.compose([Validators.required])],
      foodprice: ['', Validators.compose([Validators.required])],
      foodimage: ['', Validators.compose([Validators.required])],
      categoriename: ['', Validators.compose([Validators.required])],
      foodstockdisponible: ['', Validators.compose([Validators.required])],
      foodstockalerte: ['', Validators.compose([Validators.required])],
      fooddatemenu: ['', Validators.compose([Validators.required])],
      heurecommandeid: ['', Validators.compose([Validators.required])],
      tvaid: ['', Validators.compose([Validators.required])],
      pointventeid: ['', Validators.compose([Validators.required])],
      foodtypekey: ['', Validators.compose([Validators.required])],
      foodtype: ['', Validators.compose([Validators.required])],
      foodverify: new FormControl(false),
      foodremise: new FormControl(false),
      duree: ['', Validators.compose([Validators.required])],
      foodmontantremise: ['']
    });


    /*
     this.form.get('foodverify').valueChanges.subscribe(value => {
      this.verifypv=this.form.get('foodverify').value ;
      this.logger.info("PARAMETRE CHANGE ----> "+this.verifypv);
    })
    */

    // 

    this.form.get('foodtype').valueChanges.subscribe(value => {
     let validity : string = this.form.get('foodtype').value;
      this.logger.info("PARAMETRE CHANGE ----> " + validity);
      if (validity==="permanente") {
        this.form.patchValue({ foodtypekey: "food.key.perm" });
        this.form.patchValue({ fooddatemenu: null });
        this.checkdate=false ;
      } else if (validity==="date specifique") {
        this.form.patchValue({ foodtypekey: "food.key.date" });
        this.checkdate=true ;
      }
      console.log(this.form.value);
    })



    this.form.get('foodremise').valueChanges.subscribe(value => {
      this.verifyremise = this.form.get('foodremise').value;
      this.logger.info("PARAMETRE CHANGE ----> " + this.verifyremise);
      if (this.verifyremise) {
        this.form.controls['foodmontantremise'].setValidators([Validators.required])
      } else {
        this.form.patchValue({ foodmontantremise: 0 });
      }
    })


  }

  selectFile(event) {
    this.selectedFiles = event.target.files;
  }


  getallHeurecmd() {
    this.getItemSub = this.heureservice.get("heurecommande/getall")
      .subscribe(data => {

        let statuscode = data.status;
        this.logger.trace('DATA', data);

        switch (statuscode) {

          case 200: {
            this.heurecmd = data.body;
            break;
          }

          case 204: {
            //this.snack.open('Aucune Heure disponible', 'OK', { duration: 4000 })
            break;
          }

          default: {
            //  this.snack.open('Une erreur est survenue!', 'OK', { duration: 4000 })

            break;
          }

        }
      },
        error => {
          this.logger.error("error");
          this.logger.error(error);

          switch (error.status) {

            default: {
              //  this.snack.open('Une erreur est survenue!', 'OK', { duration: 4000 })

              break;
            }
          }
        });
  }



  getallTva() {
    this.getItemSub = this.tvaservice.get("tva/getall")
      .subscribe(data => {

        let statuscode = data.status;
        this.logger.trace('DATA', data);

        switch (statuscode) {

          case 200: {
            this.tva = data.body;
            //this.temp = data.body;
            break;
          }

          case 204: {
            //this.snack.open('Aucune TVA disponible', 'OK', { duration: 4000 })
            break;
          }

          default: {
            // this.snack.open('Une erreur est survenue!', 'OK', { duration: 4000 })

            break;
          }

        }
      },
        error => {
          this.logger.error("error");
          this.logger.error(error);

          switch (error.status) {

            default: {

              //    this.snack.open('Une erreur est survenue!', 'OK', { duration: 4000 })

              break;
            }
          }
        });
  }



  getallPointvente() {
    this.getItemSub = this.pointventeservice.get("pointvente/getall")
      .subscribe(data => {

        let statuscode = data.status;
        this.logger.trace('DATA', data);

        switch (statuscode) {

          case 200: {
            this.pointvente = data.body;
            //this.temp = data.body;
            break;
          }

          case 204: {
            //this.snack.open('Aucune TVA disponible', 'OK', { duration: 4000 })
            break;
          }

          default: {
            // this.snack.open('Une erreur est survenue!', 'OK', { duration: 4000 })

            break;
          }

        }
      },
        error => {
          this.logger.error("error");
          this.logger.error(error);

          switch (error.status) {

            default: {

              //    this.snack.open('Une erreur est survenue!', 'OK', { duration: 4000 })

              break;
            }
          }
        });
  }

  buildItemForm(item) {

  }

  submit() {
    //    this.form.
    this.currentFileUpload = this.selectedFiles.item(0);
    this.form.addControl('file', new FormControl(this.currentFileUpload, Validators.required));
    this.dialogRef.close(this.form.value);
    this.form.disabled;
  }



  getallCategories() {
    this.getItemSub = this.menuservice.getAllCategories()
      .subscribe(data => {

        let responseany: any = data;

        this.logger.trace(responseany);

        switch (data.status) {

          case 200: {
            this.itemCategories = data.body;
            break;
          }

          default: {


            let response: any = data.body;
            this.itemCategories = response;



            break;
          }



        }
      },
        error => {
          console.log("error");
          console.log(error);

          switch (error.status) {

            default: {

              break;
            }
          }
        });
  }

}
