import { DatePipe } from '@angular/common';
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MatDialog, MatPaginator, MatSnackBar, MatTableDataSource } from '@angular/material';
import { ConfigService } from 'ngx-envconfig';
import { NGXLogger } from 'ngx-logger';
import { NgxPermissionsService } from 'ngx-permissions';
import { Subscription } from 'rxjs/internal/Subscription';
import { ConfirmationService } from 'src/app/confirm/confirmation.service';
import { Agentstorage } from 'src/app/models/agentstorage';
import { ClientCorporate } from 'src/app/models/Clienttype';
import { Rolefonction } from 'src/app/models/rolefonction';
import { ApploaderService } from 'src/app/services/apploader/apploader.service';
import { FormuleAbonnementService } from './demandeabonnement.service';
import { AddDemandeComponent } from './adddemande/adddemande.component';
import { UpdateDemandeComponent } from './updatedemande/updatedemande.component';
import { DemandeabnDto } from 'src/app/models/demandeabnDto';

@Component({
  selector: 'fury-demandeabonnement',
  templateUrl: './demandeabonnement.component.html',
  styleUrls: ['./demandeabonnement.component.scss']
})
export class DemandeAbonnementComponent implements OnInit {



  /*
  displayedColumns: string[] = ['demandeabnexp', 'demandeabnmotif', 'demandeabnstatut',
  'demandeabntype', 'demandeabndate', 'actions'];
  */



  displayedColumns: string[] = ['demandeabndate', 'demandeabntype', 'demandeabnstatut', 'actions'];
  dataSource = new MatTableDataSource<ClientCorporate>([]);
  @ViewChild(MatPaginator) paginator: MatPaginator;
  public getItemSub: Subscription;
  client: ClientCorporate[] = [];
  demandedrh = [];


  public afficherDemandeAbonnement: boolean;
  public creationDemandeAbonnement: boolean;
  public modificationDemandeAbonnement: boolean;
  public suppressionDemandeAbonnement: boolean;

  public keyAfficherDemandeAbonnement = 'afficher demande abonnement';
  public keyCreationDemandeAbonnement = 'ajouter demande abonnement';
  public keyModificationDemandeAbonnement = 'modifier demande abonnement';
  public keySuppressionDemandeAbonnement = 'supprimer demande abonnement';

  constructor(private dialog: MatDialog, public formBuilder: FormBuilder,
    private snackbar: MatSnackBar, private datePipe: DatePipe,
    private configService: ConfigService,
    private confirmService: ConfirmationService, private loader: ApploaderService,
    private permissionsService: NgxPermissionsService, private logger: NGXLogger,
    private pointservice: FormuleAbonnementService) { }

  ngOnInit() {
    this.verifierDroit();
    this.getAllDemande();
  }


  getAllDemande() {
    this.getItemSub = this.pointservice.get('drh/getall')
      .subscribe(data => {
        const statuscode = data.status;
        this.logger.trace('DATA', data);
        switch (statuscode) {
          case 200: {
            this.demandedrh = data.body;
            this.dataSource.data = this.demandedrh;
            this.dataSource.paginator = this.paginator;
            // this.temp = data.body;
            break;
          }
          case 204: {
            // this.snack.open('Aucune TVA disponible', 'OK', { duration: 4000 })
            break;
          }
          default: {
            // this.snack.open('Une erreur est survenue!', 'OK', { duration: 4000 })
            break;
          }
        }
      },
        error => {
          this.logger.error('error');
          this.logger.error(error);

          switch (error.status) {

            default: {

              //    this.snack.open('Une erreur est survenue!', 'OK', { duration: 4000 })

              break;
            }
          }
        });
  }

  createDemandeAbonnement() {
    this.dialog.open(AddDemandeComponent, { width: '720px' }).afterClosed().subscribe((res) => {
      console.log(res);
      if (!res) {
        return;
      }
      const request: any = res;
      let u = new Agentstorage();
      u = JSON.parse(localStorage.getItem('curentuser'));
      if (u != null) {
        request.userpisteaudit = u.agent.agentemail + ' ' + u.agent.agentfirstname + ' ' + u.agent.agentlastname;
      }
      this.pointservice.post(request, 'drh/add').subscribe(data => {
        this.logger.trace('DATA', data);
        this.getAllDemande();
        this.loader.close();
        this.snackbar.open('Formule crée avec succes ', 'OK', {
          duration: 10000
        });
      },
        error => {
          this.logger.error('ERREUR', error);
          switch (error.status) {

            case 409: {
              // this.getallFood();
              this.loader.close();
              this.snackbar.open('Cette formule existe deja ', 'OK', {
                duration: 10000
              });
              break;
            }

            default: {
              this.loader.close();

              this.snackbar.open('Echec !! Une erreur est survenue ', 'OK', {
                duration: 10000
              });
              break;
            }
          }
        });
    });
  }




  deleteItem(row) {
    this.confirmService.confirm({
      message: `Voulez vous supprimer la formule : ${row.formulenom}?  `
    })
      .subscribe(res => {
        if (res) {
          let u = new Agentstorage();
          u = JSON.parse(localStorage.getItem('curentuser'));
          if (u != null) {
            row.userpisteaudit = u.agent.agentemail + ' ' + u.agent.agentfirstname + ' ' + u.agent.agentlastname;
          }

          this.pointservice.delete(`formule/${row.formuleid}`,).subscribe(data => {
            console.log(data);
            this.getAllDemande();
            this.snackbar.open('Success ', 'OK', {
              duration: 10000
            });

          },
            error => {

              this.snackbar.open('Echec !! Une erreur est survenue ', 'OK', {
                duration: 10000
              });
              console.log('erreur retour');
              console.log(error);
            }
          );
        }
      });
  }


  /*
   let message: string = "";
    if (code === 0) {
      message = `CONFIRMEZ VOUS L' INVALIDATION DE LA DEMANDE ?`
    }

    if (code === 1) {
      message = `CONFIRMEZ VOUS LA SORTIE DU CORPORATE ?`
    }
    */

  validerStatut(row) {
    console.log(row);
    let message: string = "";
    let dialogMessage: boolean = false;

    if (row.demandeabnetat === 2) {
      dialogMessage = true;
      message = `CONFIRMEZ VOUS LA DESACTIVATION DU PRECOMPTE ?`
    }
    if (row.demandeabnetat === 3) {
      dialogMessage = true;
      message = `CONFIRMEZ VOUS LA SORTIE DU CORPORATE ?`
    }

    if (dialogMessage) {
      this.confirmService.confirm({
        message: message
      })
        .subscribe(res => {
          if (res) {
            let u = new Agentstorage();
            u = JSON.parse(localStorage.getItem('curentuser'));
            if (u != null) {
              row.userpisteaudit = u.agent.agentemail + ' ' + u.agent.agentfirstname + ' ' + u.agent.agentlastname;
            }
            var request = Object.assign({}, row);

            request.demandeabnstatut = 1;
            console.log("---> UPDATE STATUT");
            console.log(row);

            this.pointservice.post(row, 'drh/updatestatus').subscribe(data => {
              console.log(data);
              this.getAllDemande();
              this.snackbar.open('Success ', 'OK', {
                duration: 10000
              });
            },
              error => {
                this.getAllDemande();
                this.snackbar.open('Echec !! Une erreur est survenue ', 'OK', {
                  duration: 10000
                });
                console.log('erreur retour');
                console.log(error);
              }
            );
          }
        });
    } else {

      var request = Object.assign({}, row);

      this.dialog.open(UpdateDemandeComponent, {
        width: '720px', data: { title: '', payload: request }
      }).afterClosed().subscribe((res) => {
        console.log(res);
        if (!res) {
          return;
        }

        console.log("---> UPDATE STATUT");
        console.log(res);

        this.pointservice.post(res, 'drh/updatestatus').subscribe(data => {
          console.log(data);
          this.getAllDemande();
          this.snackbar.open('Success ', 'OK', {
            duration: 10000
          });
        },
          error => {
            this.getAllDemande();
            this.snackbar.open('Echec !! Une erreur est survenue ', 'OK', {
              duration: 10000
            });
            console.log('erreur retour');
            console.log(error);
          }
        );

      });
    }
  }


  showDialog(row) {
    this.dialog.open(AddDemandeComponent, {
      width: '720px', data: { title: '', payload: row }
    }).afterClosed().subscribe((res) => {
      console.log(res);
      if (!res) {
        return;
      }
    });
  }

  changestatut(row) {
    this.confirmService.confirm({
      message: `CONFIRMEZ VOUS L' INVALIDATION DE LA DEMANDE ?`
    })
      .subscribe(res => {
        if (res) {
          let u = new Agentstorage();
          u = JSON.parse(localStorage.getItem('curentuser'));
          if (u != null) {
            row.userpisteaudit = u.agent.agentemail + ' ' + u.agent.agentfirstname + ' ' + u.agent.agentlastname;
          }
          var request = Object.assign({}, row);
          request.demandeabnstatut = 0;
          console.log("---> UPDATE STATUT");
          console.log(row);

          this.pointservice.post(row, 'drh/updatestatus').subscribe(data => {
            console.log(data);
            this.getAllDemande();
            this.snackbar.open('Success ', 'OK', {
              duration: 10000
            });
          },
            error => {
              this.getAllDemande();
              this.snackbar.open('Echec !! Une erreur est survenue ', 'OK', {
                duration: 10000
              });
              console.log('erreur retour');
              console.log(error);
            }
          );
        }
      });
  }

  openPopUp(value) {
    this.dialog.open(UpdateDemandeComponent,
      {
        // disableClose: true,
        width: '720px',
        data: { title: '', payload: value }
      }
    ).afterClosed().subscribe((res) => {
      console.log(res);

      if (!res) {
        return;
      }

      const request: any = res;

      let u = new Agentstorage();
      u = JSON.parse(localStorage.getItem('curentuser'));
      if (u != null) {
        request.userpisteaudit = u.agent.agentemail + ' ' + u.agent.agentfirstname + ' ' + u.agent.agentlastname;
      }

      this.pointservice.put(request, 'drh/updatestatus').subscribe(data => {
        this.logger.trace('DATA', data);


        switch (data.status) {

          case 200: {

            console.log('data');
            console.log(data);

            this.getAllDemande();
            this.loader.close();
            this.snackbar.open('Formule mis à jour avec succès ', 'OK', {
              duration: 10000
            });

            break;
          }

        }
      },
        error => {
          this.logger.error('ERREUR', error);
          switch (error.status) {

            case 409: {
              // this.getallFood();
              this.loader.close();
              this.snackbar.open('Cette formule existe déjà ', 'OK', {
                duration: 10000
              });
              break;
            }

            default: {
              this.loader.close();

              this.snackbar.open('Echec !! Une erreur est survenue ', 'OK', {
                duration: 10000
              });
              break;
            }
          }
        });

    });
  }


  public verifierDroit() {
    this.logger.trace('BEGIN');
    // this.logger.trace('Clé', this.configService.get('keylocalstorage'));

    let agentOnline: Agentstorage;
    agentOnline = JSON.parse(localStorage.getItem(this.configService.get('keylocalstorage')));
    // this.logger.trace(agentOnline);
    const rolefontion: Rolefonction[] = agentOnline.rolefonction;
    // this.logger.trace('rolefontion', rolefontion);

    for (const key in rolefontion) {
      if (rolefontion.hasOwnProperty(key)) {
        // this.logger.trace('KEY', key);
        const element = rolefontion[key].fonction.fonctionname;
        this.logger.trace('element', element);

        if (element === this.keyAfficherDemandeAbonnement) {
          this.logger.trace('true');
          this.afficherDemandeAbonnement = true;
        }
        if (element === this.keyCreationDemandeAbonnement) {
          this.logger.trace('true');
          this.creationDemandeAbonnement = true;
        }
        if (element === this.keyModificationDemandeAbonnement) {
          this.logger.trace('true');
          this.modificationDemandeAbonnement = true;
        }
        if (element === this.keySuppressionDemandeAbonnement) {
          this.logger.trace('true');
          this.suppressionDemandeAbonnement = true;
        }
      }
    }

  }

}
