import { Component, OnInit, Inject } from '@angular/core';
import { NGXLogger } from 'ngx-logger';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatSnackBar, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';

@Component({
  selector: 'fury-add',
  templateUrl: './add.component.html',
  styleUrls: ['./add.component.scss']
})
export class AddComponent implements OnInit {

  form: FormGroup;

  
  verifyremise: boolean = false;
  verifyremisemontant: any = 0;


  constructor(@Inject(MAT_DIALOG_DATA) public defaults: any,
    public dialogRef: MatDialogRef<AddComponent>,
    private fb: FormBuilder,
    private logger: NGXLogger) {
  }

  ngOnInit() {
    this.form = this.fb.group({
      pointventename: ['', Validators.compose([Validators.required])],
      pointventeremise: new FormControl(false),
      pointventemontantremise: ['']
    });

    this.form.get('pointventeremise').valueChanges.subscribe(value => {
      this.verifyremise = this.form.get('pointventeremise').value;
      this.logger.info("PARAMETRE CHANGE ----> " + this.verifyremise);

      if (this.verifyremise) {
        this.form.controls['pointventemontantremise'].setValidators([Validators.required])
      } else {
        this.form.patchValue({ pointventemontantremise: 0 });
      }
    })

  }



  submit() {
    //    this.form.
    this.dialogRef.close(this.form.value);
  }

}
