import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { StatforclientComponent } from './statforclient.component';
import { StatforallComponent } from './statforall/statforall.component';
import { StatforcommandComponent } from './statforcommand/statforcommand.component';
import { StatforemporteComponent } from './statforemporte/statforemporte.component';
import { StatforlivreComponent } from './statforlivre/statforlivre.component';
import { StatforsurplaceComponent } from './statforsurplace/statforsurplace.component';
import { StatabnComponent } from './statabn/statabn.component';
import { StatcpteComponent } from './statcpte/statcpte.component';

const routes: Routes = [
  {
    path: '',
    component: StatforclientComponent,
    children: [{
      path: 'stat/:id',
      component: StatforallComponent,
      data: { title: 'statistique', breadcrumb: 'statistique' }
    },
    {
      path: 'allcommande/:id',
      component: StatforcommandComponent,
      data: { title: 'Toutes Les Commandes', breadcrumb: 'Toutes Les Commandes' }
    },
    {
      path: 'emporte/:id',
      component: StatforemporteComponent,
      data: { title: 'Commande Emporte', breadcrumb: 'Commande Emporte' }
    },

    {
      path: 'livre/:id',
      component: StatforlivreComponent,
      data: { title: 'Toutes Les Commandes Livrées', breadcrumb: 'Toutes Les Commandes' }
    },
    {
      path: 'surplace/:id',
      component: StatforsurplaceComponent,
      data: { title: 'Commande sur place', breadcrumb: 'Commande Emporte' }
    },
    {
      path: 'abn/:id',
      component: StatabnComponent,
      data: { title: 'Commande par abonnement', breadcrumb: 'Abonnement' }
    },
    {
      path: 'cpteresto/:id',
      component: StatcpteComponent,
      data: { title: 'Commande par compte restau tresor', breadcrumb: 'Compte restau' }
    }


    ]
  }

];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class StatforclientRoutingModule { }
