import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'fury-statuser',
  templateUrl: './statuser.component.html',
  styleUrls: ['./statuser.component.scss']
})
export class StatuserComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
