import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';

@Component({
  selector: 'fury-showfiche',
  templateUrl: './showfiche.component.html',
  styleUrls: ['./showfiche.component.scss']
})
export class ShowficheComponent implements OnInit {

  item: any;


  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
    private dialogRef: MatDialogRef<ShowficheComponent>) {
  }

  ngOnInit() {
    this.item = this.data.payload;
    console.log(this.item.clientfirstname);
    console.log(this.item);
  }

}
