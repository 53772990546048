import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';

@Component({
  selector: 'fury-show',
  templateUrl: './show.component.html',
  styleUrls: ['./show.component.scss']
})
export class ShowComponent implements OnInit {


  item: any;


  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
    private dialogRef: MatDialogRef<ShowComponent>) {
  }

  ngOnInit() {
    this.buildItemForm(this.data.payload);
  }



  buildItemForm(item) {
    console.log(item);
    this.item = item;
  }


}
