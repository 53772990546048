import { HttpClient, HttpResponse, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ConfigService } from 'ngx-envconfig';
import { Observable } from 'rxjs';
import { Agentstorage } from 'src/app/models/agentstorage';

@Injectable({
  providedIn: 'root'
})
export class CategoriecorpoService {
  

  
  private url = this.configService.get('ipMS');
  authorization: string = " ";
  caissierid: string = " ";

  constructor(private http: HttpClient, private configService: ConfigService) {
    let u = new Agentstorage();
    u = JSON.parse(localStorage.getItem('curentuser'));
    if (u != null) {
      this.authorization = u.token;
      this.caissierid = u.agent.agentid;
      console.log("authorization == " + this.authorization);
    }
  }


  getAll(value : any): Observable<HttpResponse<any>> {
    return this.http.get(this.url + value,
      {
        observe: 'response',
        headers: new HttpHeaders({ 'Access-Control-Allow-Origin': '*' })
          .set("content-Type", "application/json")
          .set("Authorization", this.authorization)
      });
  }

  public post(value: any, url1: string): Observable<HttpResponse<any>> {
    console.log(JSON.stringify(value));
    return this.http.post(this.url + url1, JSON.stringify(value), {
      observe: 'response',
      headers: new HttpHeaders({ 'Access-Control-Allow-Origin': '*' })
        .set("content-Type", "application/json")
        .set("Authorization", this.authorization)
    });
  }



}
