import { RolesService } from './../../../../services/roles.service';
import { MatDialogRef, MAT_DIALOG_DATA, MatSnackBar } from '@angular/material';
import { DialogData } from './../role-modifier/role-modifier.component';
import { Component, OnInit, Inject } from '@angular/core';

@Component({
  selector: 'fury-deletedialog',
  templateUrl: './deletedialog.component.html',
  styleUrls: ['./deletedialog.component.scss']
})
export class DeletedialogComponent implements OnInit {

  resp : number = 0 ;


  ngOnInit() {
  }

  constructor(  public dialogRef: MatDialogRef<DeletedialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData, private roleService :RolesService, private snackbar: MatSnackBar) {}

  onNoClick(): void {
    this.dialogRef.close();
  }

  deleteRole() {
    console.log('-- Delete role ---  '+  this.data.role.roleid);

    if (this.data.role.roleid) {
      
      this.roleService.delete(this.data.role.roleid).subscribe(
        data => {
          console.log('-- Delete role response ---  '+ data.statusText+' '+ data.status);

          switch (data.status) {
            case 200: {
              this.snackbar.open('Success supprimé' , 'OK', {
                duration: 2000
              });
              this.resp = 200;
              break;
            }
            default:
            this.snackbar.open('Echec suppression, role not found' , 'veuillez rafraichir la page svp', {
              duration: 2000
            

              
            });
            this.resp = 502;
          }

         
      
        }
      );

      return this.resp;
    }

  }
    

  

}

