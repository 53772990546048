import { AffectercommandeaunlivreurComponent } from './affectercommandeaunlivreur/affectercommandeaunlivreur.component';
import { Component, OnInit } from '@angular/core';
import { Historiq } from 'src/app/models/Agent';
import { MatTableDataSource, MatDialog, MatSnackBar } from '@angular/material';
import { Subscription } from 'rxjs';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ConfirmationService } from 'src/app/confirm/confirmation.service';
import { ApploaderService } from 'src/app/services/apploader/apploader.service';
import { NGXLogger, LoggerConfig } from 'ngx-logger';
import { ConfigService } from 'ngx-envconfig';
import { Agentstorage } from 'src/app/models/agentstorage';
import { Rolefonction } from 'src/app/models/rolefonction';
import { ShowcommandesComponent } from './showcommandes/showcommandes.component';
import { CommandeService } from './commande.service';
import { NgxPermissionsService } from 'ngx-permissions';
import { ExportpdfComponent } from './exportpdf/exportpdf.component';
import { ExportxlsComponent } from './exportxls/exportxls.component';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
const EXCEL_EXTENSION = '.xlsx';
import * as jsPDF from 'jspdf';
import 'jspdf-autotable';
import { UserOptions } from 'jspdf-autotable';
import { DatePipe } from '@angular/common';
import { Searchbydate } from 'src/app/models/searchbydate';

interface jsPDFWithPlugin extends jsPDF {
  autoTable: (options: UserOptions) => jsPDF;
}

@Component({
  selector: 'fury-gestioncommande',
  templateUrl: './gestioncommande.component.html',
  styleUrls: ['./gestioncommande.component.scss']
})
export class GestioncommandeComponent implements OnInit {

  public items: Historiq[];
  public itemsFinal: Historiq[];
  temp = [];
  rows = [];
  columns = [];


  public isLoading: boolean = true;
  public tableOffset = 0;
  public selected = 10; // Selection de pagination par defaut
  public tableLimit = this.configService.get('tableLimit'); // Limit du tableau par defaut
  public pagination;
  public totalElements = 0;
  public pageSizeApi = 0;
  public pageCourante = 1;

  filterModeLivraison: boolean = false;
  filterModePaiement: boolean = false;
  filterModeProvenance: boolean = false;

  public itemForm: FormGroup;

  public commands: Array<Historiq> = [];
  dataSource: MatTableDataSource<Historiq> = new MatTableDataSource([]);

  public getItemSub: Subscription;

  // Habilitation
  public afficherCommande: boolean;
  public voirplusCommande: boolean;


  public keyAfficherCommande = 'afficher commande';
  public keyVoirplusCommande = 'voir details commande';

  provenance: boolean = false;
  provenances: any[] = [
    {
      "libele": "TOUTES LES COMMANDES",
      "values": "all"
    },
    {
      "libele": "COMMANDES EN LIGNE",
      "values": "online"
    }
  ]



  paiement: string = "all";
  checkpaiement: any = false;

  modepaiement: any[] = [
    {
      "libele": "TOUTES LES COMMANDES",
      "values": "all"
    },
    {
      "libele": "CARTE ABONNEMENT",
      "values": "carte abonnement"
    }
    ,
    {
      "libele": "MOBILE MONEY",
      "values": "mobile money"
    }
  ]


  livraison: string = "all";
  checklivraison: any = false;

  /*
  A emporter
   A livrer
   Sur place
   */

  modelivraison: any[] = [
    {
      "libele": "TOUTES LES COMMANDES",
      "values": "all"
    },
    {
      "libele": "A emporter",
      "values": "a emporter"
    },

    {
      "libele": "A livrer",
      "values": "a livrer"
    }
    ,

    {
      "libele": "Sur place",
      "values": "sur place"
    }
  ]

  status: string[] = ['toutes les commandes', 'en ligne', 'caisse', 'carte'];
  //modepaiement: string[] = ['toutes les commandes', 'en ligne', 'caisse', 'carte'];
  //modelivraison: string[] = ['toutes les commandes', 'en ligne', 'caisse', 'carte'];

  selectedStatus: any = 'toutes les commandes';

  constructor(private dialog: MatDialog, public formBuilder: FormBuilder,
    private snackbar: MatSnackBar, private commandeserv: CommandeService,
    private datePipe: DatePipe,
    private confirmService: ConfirmationService, private loader: ApploaderService,
    private permissionsService: NgxPermissionsService, private logger: NGXLogger,
    private configService: ConfigService, private fb: FormBuilder) { }


  ngOnInit() {
    this.refreshDatatable();
    this.verifierDroit();
    //this.getallCommandes();
    //this.search(0, 10);
  }



  refreshDatatable() {
    this.itemForm = this.fb.group({
      /*
      to: ['', Validators.compose([Validators.required])],
      from: ['', Validators.compose([Validators.required])],
      */
      to: [''],
      from: [''],
      provenance: [''],
      livraison: [''],
      paiement: ['']

    });



    this.itemForm.get('provenance').valueChanges.subscribe(data => {
      console.log(data);
      if (data === 'online') {
        this.provenance = true;
      }
      if (data === 'all') {
        this.provenance = false;
      }
    });

    this.itemForm.get('livraison').valueChanges.subscribe(data => {
      this.livraison = data;
      if (data === "all") {
        this.checklivraison = false;
      } else {
        this.checklivraison = true;
      }
      console.log(data);
      console.log("checklivraison -> " + this.checklivraison);
    });


    this.itemForm.get('paiement').valueChanges.subscribe(data => {
      this.paiement = data;
      if (data === "all") {
        this.checkpaiement = false;
      } else {
        this.checkpaiement = true;
      }
      console.log(data);
      console.log("checkpaiement -> " + this.checkpaiement);
    });


    this.provenance = false;
    this.paiement = "all";
    this.livraison = "all";
    this.checklivraison = false;
    this.checkpaiement = false;

    // this.getallCommandes();
    this.search(0, 10);
  }

  public verifierDroit() {
    this.logger.trace('BEGIN');
    // this.logger.trace('Clé', this.configService.get('keylocalstorage'));

    let agentOnline: Agentstorage;
    agentOnline = JSON.parse(localStorage.getItem(this.configService.get('keylocalstorage')));
    // this.logger.trace(agentOnline);
    let rolefontion: Rolefonction[] = agentOnline.rolefonction;
    // this.logger.trace('rolefontion', rolefontion);

    for (const key in rolefontion) {
      if (rolefontion.hasOwnProperty(key)) {
        // this.logger.trace('KEY', key);
        const element = rolefontion[key].fonction.fonctionname;
        this.logger.trace('element', element);

        if (element == this.keyAfficherCommande) {
          this.logger.trace('true');
          this.afficherCommande = true;
        }
        if (element == this.keyVoirplusCommande) {
          this.logger.trace('true');
          this.voirplusCommande = true;
        }

      }
    }

  }


  getallCommandesPaginable(size, limit) {
    this.getItemSub = this.commandeserv.getAllCommabdesPaginable(size, limit)
      .subscribe(data => {

        let responseany: any = data;

        console.log(responseany);
        this.logger.trace('DATA-PAGINABLE', data);

        switch (data.status) {

          default: {

            let response: any = data;
            let resp1: Historiq[] = response.content;
            this.totalElements = response.totalElements;
            this.pageSizeApi = response.size;

            let respfinal: Historiq[] = [];
            for (let rp of resp1) {
              if (rp.client != null) {
                rp.clientname = rp.client.clientemail;
              }
              if (rp.agent != null) {
                rp.agentname = rp.agent.agentemail;
              }
              respfinal.push(rp);
            }

            this.items = this.temp = respfinal;
            this.itemsFinal = respfinal;
            this.checkFilter();
            this.dataSource = new MatTableDataSource(this.items);

            break;
          }


        }
      },
        error => {
          console.log("error");
          console.log(error);

          switch (error.status) {

            default: {
              let response: any = [];
              let resp1: Historiq[] = response;
              let respfinal: Historiq[] = [];
              this.items = this.temp = respfinal;
              this.dataSource = new MatTableDataSource(this.items);
              break;
            }
          }
        });
  }





  checkFilter() {
    console.log("----checkFilter-----  " + this.provenance);
    if (this.items && this.items.length > 0) {
      let respItems: Historiq[] = this.items;

      if (this.provenance) {
        let respProvenance: Historiq[] = [];
        respItems.forEach(element => {
          //Web-frontOffice
          //          if (element.historiqueprovenance && element.historiqueprovenance.toLocaleLowerCase() !== 'web-caisse' && element.historiqueprovenance.toLocaleLowerCase() !== 'borne') {
          if (element.historiqueprovenance && element.historiqueprovenance.toLocaleLowerCase() !== 'web-caisse' && element.historiqueprovenance.toLocaleLowerCase() !== 'borne') {
            respProvenance.push(element);
          }
        });
        respItems = respProvenance;
        this.items = respItems;
      }

      if (this.paiement && this.paiement !== "all") {
        let respItems: Historiq[] = this.items;
        let respPaiement: Historiq[] = [];
        respItems.forEach(element => {
          if (element.paymentmode && element.paymentmode.toLocaleLowerCase() === this.paiement) {
            respPaiement.push(element);
          }
        });
        respItems = respPaiement;
        this.items = respItems;


      }

      if (this.livraison && this.livraison !== "all") {
        let respItems: Historiq[] = this.items;
        let respLivraison: Historiq[] = [];
        respItems.forEach(element => {
          if (element.livraisonmode && element.livraisonmode.toLocaleLowerCase() === this.livraison) {
            respLivraison.push(element);
          }
        });
        respItems = respLivraison;
        this.items = respItems;
      }

    }
  }

  getallCommandes() {
    this.getItemSub = this.commandeserv.getAllCommabdesByAgent()
      .subscribe(data => {

        let responseany: any = data;

        console.log(responseany);
        this.logger.trace('DATA', data);

        switch (data.status) {

          default: {
            let response: any = data;
            let resp1: Historiq[] = response;

            let respfinal: Historiq[] = [];
            for (let rp of resp1) {
              if (rp.client != null) {
                rp.clientname = rp.client.clientemail;
              }
              if (rp.agent != null) {
                rp.agentname = rp.agent.agentemail;
              }
              respfinal.push(rp);
            }

            this.items = this.temp = respfinal;
            this.itemsFinal = respfinal;
            this.dataSource = new MatTableDataSource(this.items);
            break;
          }


        }
      },
        error => {
          console.log("error");
          console.log(error);

          switch (error.status) {

            default: {
              let response: any = [];
              let resp1: Historiq[] = response;
              let respfinal: Historiq[] = [];
              this.items = this.temp = respfinal;
              this.dataSource = new MatTableDataSource(this.items);
              break;
            }
          }
        });
  }

  updateFilter(event) {
    const val = event.target.value.toLowerCase();
    var columns = Object.keys(this.temp[0]);
    // Removes last "$$index" from "column"
    columns.splice(columns.length - 1);

    // console.log(columns);
    if (!columns.length)
      return;

    const rows = this.temp.filter(function (d) {
      for (let i = 0; i <= columns.length; i++) {
        let column = columns[i];
        // console.log(d[column]);
        if (d[column] && d[column].toString().toLowerCase().indexOf(val) > -1) {
          return true;
        }
      }
    });

    this.items = rows;
  }



  verifyFalse(value: any) {
    if (value === true) {
      return 'oui';
    } else if (value === false) {
      return 'non';
    }
  }




  opendetail(value: any) {

    this.dialog.open(ShowcommandesComponent,
      {
        width: '720px',
        data: { title: '', payload: value }
      }
    ).afterClosed().subscribe((res) => {
      console.log(res);
      if (!res) {
        return;
      }
    });
  }


  loadXLS(row) {
    let commandItems = row.commandreference;
    let itemscsv: any[] = [];
    let itemslocal: any[] = row.items;
    if (itemslocal.length > 0) {
      itemslocal.forEach(function (element) {
        var my_json = {
          "N Commande": commandItems,
          "Plat": element.itemname,
          "Prix unitaire": element.itemprice,
          "Quantite": element.itemquantity
        };

        itemscsv.push(my_json);

      });
      this.exportAsExcelFile(itemscsv, 'Details des commandes');

    }
  }

  loadPDF(row) {
    let commandItems = row.commandreference;
    let itemscsv: any[] = [];
    let itemslocal: any[] = row.items;
    if (itemslocal.length > 0) {
      itemslocal.forEach(function (element) {
        var my_json = {
          "N Commande": commandItems,
          "Plat": element.itemname,
          "Prix unitaire": element.itemprice,
          "Quantite": element.itemquantity
        };

        itemscsv.push(my_json);

      });
    }

    console.log(itemscsv);
    var columns = [
      { title: "N Commande", dataKey: "N Commande" },
      { title: "Plat", dataKey: "Plat" },
      { title: "Prix unitaire", dataKey: "Prix unitaire" },
      { title: "Quantite", dataKey: "Quantite" }

    ];
    var rows = itemscsv;

    const doc = new jsPDF('portrait', 'px', 'a4') as jsPDFWithPlugin;
    doc.autoTable({
      body: rows,
      columns: columns,
      didParseCell: function (data) {
        if (data.column.raw.displayProperty) {
          var prop = data.column.raw.displayProperty;
          var text = data.cell.raw[prop];
          if (text && text.length > 0) {
            data.cell.text = text;
          }
        }
      }
    });
    doc.save('commandes-details.pdf');
  }


  exportPdf() {
    let itemscsv: any[] = [];
    let itemslocal: any[] = this.items;
    if (itemslocal.length > 0) {
      itemslocal.forEach(function (element) {
        console.log(element);
        if (element.historiqueventedate.length > 17) {
          element.historiqueventedate = element.historiqueventedate.substr(0, 16);
        }

        if (element.historiquedatelivraison && (element.historiquedatelivraison.length > 17)) {
          element.historiquedatelivraison = element.historiquedatelivraison.substr(0, 16);
        }

        if (element.livraisonStatus) {
          element.livraisonStatus = "OK";
        } else {
          element.livraisonStatus = "NOK";
        }

        let paiement: String;
        if (element.paymentStatus) {
          element.paymentStatus = "OK";
          paiement = "OK";
        } else {
          element.paymentStatus = "NOK";
          paiement = "NOK";
        }
        console.log(" PAIEMENT STATUS " + paiement);

        var my_json = {
          "Date": element.historiqueventedate,
          "N Commande": element.commandreference,
          "Remise": element.commandremise,
          "Montant Total": element.commandtotalpricepayer,
          "Mod paiement": element.paymentmode,
          "Mod livraison": element.livraisonmode,
          "Statut paiement": paiement
        };

        itemscsv.push(my_json);
      });
    }

    console.log(itemscsv);
    var columns = [
      { title: "Date", dataKey: "Date" },
      { title: "N Commande", dataKey: "N Commande" },
      { title: "Remise", dataKey: "Remise" },
      { title: "Montant Total", dataKey: "Montant Total" },
      { title: "Mod paiement", dataKey: "Mod paiement" },
      { title: "Mod paiement", dataKey: "Mod paiement" },
      { title: "Mod livraison", dataKey: "Mod livraison" },
      { title: "Statut paiement", dataKey: "Statut paiement" }
    ];
    var rows = itemscsv;

    const doc = new jsPDF('portrait', 'px', 'a4') as jsPDFWithPlugin;
    doc.autoTable({
      body: rows,
      columns: columns,
      didParseCell: function (data) {
        if (data.column.raw.displayProperty) {
          var prop = data.column.raw.displayProperty;
          var text = data.cell.raw[prop];
          if (text && text.length > 0) {
            data.cell.text = text;
          }
        }
      }
    });
    doc.save('commandes.pdf');
  }

  downloadPdf(value: any[]) {
    let itemscsv: any[] = [];
    let itemslocal: any[] = value;
    if (itemslocal.length > 0) {
      itemslocal.forEach(function (element) {
        console.log(element);
        if (element.historiqueventedate.length > 17) {
          element.historiqueventedate = element.historiqueventedate.substr(0, 16);
        }

        if (element.historiquedatelivraison && (element.historiquedatelivraison.length > 17)) {
          element.historiquedatelivraison = element.historiquedatelivraison.substr(0, 16);
        }

        if (element.livraisonStatus) {
          element.livraisonStatus = "OK";
        } else {
          element.livraisonStatus = "NOK";
        }

        let paiement: String;
        if (element.paymentStatus) {
          element.paymentStatus = "OK";
          paiement = "OK";
        } else {
          element.paymentStatus = "NOK";
          paiement = "NOK";
        }
        console.log(" PAIEMENT STATUS " + paiement);

        var my_json = {
          "Date": element.historiqueventedate,
          "N Commande": element.commandreference,
          "Remise": element.commandremise,
          "Montant Total": element.commandtotalpricepayer,
          "Mod paiement": element.paymentmode,
          "Mod livraison": element.livraisonmode,
          "Statut paiement": paiement
        };

        itemscsv.push(my_json);
      });
    }

    console.log(itemscsv);
    var columns = [
      { title: "Date", dataKey: "Date" },
      { title: "N Commande", dataKey: "N Commande" },
      { title: "Remise", dataKey: "Remise" },
      { title: "Montant Total", dataKey: "Montant Total" },
      { title: "Mod paiement", dataKey: "Mod paiement" },
      { title: "Mod paiement", dataKey: "Mod paiement" },
      { title: "Mod livraison", dataKey: "Mod livraison" },
      { title: "Statut paiement", dataKey: "Statut paiement" }
    ];
    var rows = itemscsv;

    const doc = new jsPDF('portrait', 'px', 'a4') as jsPDFWithPlugin;
    doc.autoTable({
      body: rows,
      columns: columns,
      didParseCell: function (data) {
        if (data.column.raw.displayProperty) {
          var prop = data.column.raw.displayProperty;
          var text = data.cell.raw[prop];
          if (text && text.length > 0) {
            data.cell.text = text;
          }
        }
      }
    });
    doc.save('commandes.pdf');
  }

  exportXls() {

    let itemscsv: any[] = [];
    let itemslocal: any[] = this.items;
    if (itemslocal.length > 0) {
      itemslocal.forEach(function (element) {
        console.log(element);
        if (element.historiqueventedate.length > 17) {
          element.historiqueventedate = element.historiqueventedate.substr(0, 16);
        }

        if (element.historiquedatelivraison && (element.historiquedatelivraison.length > 17)) {
          element.historiquedatelivraison = element.historiquedatelivraison.substr(0, 16);
        }

        if (element.livraisonStatus) {
          element.livraisonStatus = "OK";
        } else {
          element.livraisonStatus = "NOK";
        }

        let paiement: String;
        if (element.paymentStatus) {
          element.paymentStatus = "OK";
          paiement = "OK";
        } else {
          element.paymentStatus = "NOK";
          paiement = "NOK";
        }
        console.log(" PAIEMENT STATUS " + paiement);

        var my_json = {
          "Date": element.historiqueventedate,
          "N Commande": element.commandreference,
          "Remise": element.commandremise,
          "Montant Total": element.commandtotalpricepayer,
          "Mod paiement": element.paymentmode,
          "Mod livraison": element.livraisonmode,
          "Statut paiement": paiement
        };

        itemscsv.push(my_json);
      });
      console.log(itemscsv);

      this.exportAsExcelFile(itemscsv, 'Historique des commandes');
    }
  }

  downloadXls(value: any[]) {

    let itemscsv: any[] = [];
    let itemslocal: any[] = value;
    if (itemslocal.length > 0) {
      itemslocal.forEach(function (element) {
        console.log(element);
        if (element.historiqueventedate.length > 17) {
          element.historiqueventedate = element.historiqueventedate.substr(0, 16);
        }

        if (element.historiquedatelivraison && (element.historiquedatelivraison.length > 17)) {
          element.historiquedatelivraison = element.historiquedatelivraison.substr(0, 16);
        }

        if (element.livraisonStatus) {
          element.livraisonStatus = "OK";
        } else {
          element.livraisonStatus = "NOK";
        }

        let paiement: String;
        if (element.paymentStatus) {
          element.paymentStatus = "OK";
          paiement = "OK";
        } else {
          element.paymentStatus = "NOK";
          paiement = "NOK";
        }
        console.log(" PAIEMENT STATUS " + paiement);

        var my_json = {
          "Date": element.historiqueventedate,
          "N Commande": element.commandreference,
          "Remise": element.commandremise,
          "Montant Total": element.commandtotalpricepayer,
          "Mod paiement": element.paymentmode,
          "Mod livraison": element.livraisonmode,
          "Statut paiement": paiement
        };

        itemscsv.push(my_json);
      });
      console.log(itemscsv);

      this.exportAsExcelFile(itemscsv, 'Historique des commandes');
    }
  }

  /*
  exportPdf() {

    this.dialog.open(ExportpdfComponent,
      {
        width: '720px',
        data: { title: '', payload: '' }
      }
    ).afterClosed().subscribe((res) => {
      console.log(res);
      if (!res) {
        return;
      }
      let valueres: string = res;
      if (valueres === "allcommandepdf") {
        let itemscsv: any[] = [];
        let itemslocal: any[] = this.items;
        if (itemslocal.length > 0) {
          itemslocal.forEach(function (element) {
            console.log(element);
            if (element.historiqueventedate.length > 17) {
              element.historiqueventedate = element.historiqueventedate.substr(0, 16);
            }

            if (element.historiquedatelivraison && (element.historiquedatelivraison.length > 17)) {
              element.historiquedatelivraison = element.historiquedatelivraison.substr(0, 16);
            }

            if (element.livraisonStatus) {
              element.livraisonStatus = "OK";
            } else {
              element.livraisonStatus = "NOK";
            }

            if (element.paymentStatus) {
              element.paymentStatus = "OK";
            } else {
              element.paymentStatus = "NOK";
            }

            var my_json = {
              "ID Transaction": element.commandreference,
              "Date Operation": element.historiqueventedate,
              "Email Client": element.clientname,
              "Total avant Remise": element.historiquetotalprice,
              "Remise": element.commandremise,
              "Total a payer": element.commandtotalpricepayer,
              "Provenance": element.historiqueprovenance,
              //"Mode livraison": element.livraisonmode,
              //"Mode paiement": element.paymentmode,
              "Status paiement": element.paymentStatus,
              "Status livraison": element.livraisonStatus,
              //"Date livraison": element.historiquedatelivraison
            };

            itemscsv.push(my_json);
          });
        }

        console.log(itemscsv);
        var columns = [
          { title: "ID Transaction", dataKey: "ID Transaction" },
          { title: "Date Operation", dataKey: "Date Operation" },
          { title: "Email Client", dataKey: "Email Client" },
          { title: "Total avant Remise", dataKey: "Total avant Remise" },
          { title: "Remise", dataKey: "Remise" },
          { title: "Total a payer", dataKey: "Total a payer" },
          { title: "Provenance", dataKey: "Provenance" },
          { title: "Status paiement", dataKey: "Status paiement" },
          { title: "Status livraison", dataKey: "Status livraison" }
        ];
        var rows = itemscsv;

        const doc = new jsPDF('portrait', 'px', 'a4') as jsPDFWithPlugin;
        doc.autoTable({
          body: rows,
          columns: columns,
          didParseCell: function (data) {
            if (data.column.raw.displayProperty) {
              var prop = data.column.raw.displayProperty;
              var text = data.cell.raw[prop];
              if (text && text.length > 0) {
                data.cell.text = text;
              }
            }
          }
        });
        doc.save('commandes.pdf');

      } else if (valueres === "livrerpdf") {
        let itemscsv: any[] = [];
        let itemslocal: any[] = this.items;
        if (itemslocal.length > 0) {
          itemslocal.forEach(function (element) {
            if (element.livraisonmode === "A livrer") {

              console.log(element);
              if (element.historiqueventedate.length > 17) {
                element.historiqueventedate = element.historiqueventedate.substr(0, 16);
              }

              if (element.historiquedatelivraison && (element.historiquedatelivraison.length > 17)) {
                element.historiquedatelivraison = element.historiquedatelivraison.substr(0, 16);
              }

              if (element.livraisonStatus) {
                element.livraisonStatus = "OK";
              } else {
                element.livraisonStatus = "NOK";
              }

              if (element.paymentStatus) {
                element.paymentStatus = "OK";
              } else {
                element.paymentStatus = "NOK";
              }

              var my_json = {
                "ID Transaction": element.commandreference,
                "Date Operation": element.historiqueventedate,
                "Email Client": element.clientname,
                "Total avant Remise": element.historiquetotalprice,
                "Remise": element.commandremise,
                "Total a payer": element.commandtotalpricepayer,
                "Provenance": element.historiqueprovenance,
                //"Mode livraison": element.livraisonmode,
                //"Mode paiement": element.paymentmode,
                "Status paiement": element.paymentStatus,
                "Status livraison": element.livraisonStatus,
                //"Date livraison": element.historiquedatelivraison
              };

              itemscsv.push(my_json);
            }
          });
        }

        console.log(itemscsv);
        var columns = [
          { title: "ID Transaction", dataKey: "ID Transaction" },
          { title: "Date Operation", dataKey: "Date Operation" },
          { title: "Email Client", dataKey: "Email Client" },
          { title: "Total avant Remise", dataKey: "Total avant Remise" },
          { title: "Remise", dataKey: "Remise" },
          { title: "Total a payer", dataKey: "Total a payer" },

          { title: "Provenance", dataKey: "Provenance" },
          { title: "Status paiement", dataKey: "Status paiement" },
          { title: "Status livraison", dataKey: "Status livraison" }
        ];
        var rows = itemscsv;

        const doc = new jsPDF('portrait', 'px', 'a4') as jsPDFWithPlugin;
        doc.autoTable({
          body: rows,
          columns: columns,
          didParseCell: function (data) {
            if (data.column.raw.displayProperty) {
              var prop = data.column.raw.displayProperty;
              var text = data.cell.raw[prop];
              if (text && text.length > 0) {
                data.cell.text = text;
              }
            }
          }
        });
        doc.save('commandes-a-livrer.pdf');

      }


    });
  }
  */


  /*
  exportXls() {

    this.dialog.open(ExportxlsComponent,
      {
        width: '720px',
        data: { title: '', payload: '' }
      }
    ).afterClosed().subscribe((res) => {
      console.log(res);
      if (!res) {
        return;
      }

      let valueres: string = res;
      if (valueres === "allcommandexls") {
        let itemscsv: any[] = [];
        let itemslocal: any[] = this.items;
        if (itemslocal.length > 0) {
          itemslocal.forEach(function (element) {
            console.log(element);
            if (element.historiqueventedate.length > 17) {
              element.historiqueventedate = element.historiqueventedate.substr(0, 16);
            }

            if (element.historiquedatelivraison && (element.historiquedatelivraison.length > 17)) {
              element.historiquedatelivraison = element.historiquedatelivraison.substr(0, 16);
            }

            if (element.livraisonStatus) {
              element.livraisonStatus = "OK";
            } else {
              element.livraisonStatus = "NOK";
            }

            if (element.paymentStatus) {
              element.paymentStatus = "OK";
            } else {
              element.paymentStatus = "NOK";
            }

            var my_json = {
              "ID Transaction": element.commandreference,
              "Date Operation": element.historiqueventedate,
              "Email Client": element.clientname,

              "Total avant Remise": element.historiquetotalprice,
              "Remise": element.commandremise,
              "Total a payer": element.commandtotalpricepayer,

              "Provenance": element.historiqueprovenance,
              "Mode livraison": element.livraisonmode,
              "Mode paiement": element.paymentmode,
              "Status paiement": element.paymentStatus,
              "Status livraison": element.livraisonStatus,
              "Date livraison": element.historiquedatelivraison
            };

            itemscsv.push(my_json);
          });
          console.log(itemscsv);

          this.exportAsExcelFile(itemscsv, 'Historique des commandes');

        }
      } else if (valueres === "livrerxls") {
        let itemscsv: any[] = [];
        let itemslocal: any[] = this.items;
        if (itemslocal.length > 0) {
          itemslocal.forEach(function (element) {

            if (element.livraisonmode === "A livrer") {

              console.log(element);
              if (element.historiqueventedate.length > 17) {
                element.historiqueventedate = element.historiqueventedate.substr(0, 16);
              }

              if (element.historiquedatelivraison && (element.historiquedatelivraison.length > 17)) {
                element.historiquedatelivraison = element.historiquedatelivraison.substr(0, 16);
              }

              if (element.livraisonStatus) {
                element.livraisonStatus = "OK";
              } else {
                element.livraisonStatus = "NOK";
              }

              if (element.paymentStatus) {
                element.paymentStatus = "OK";
              } else {
                element.paymentStatus = "NOK";
              }

              var my_json = {
                "ID Transaction": element.commandreference,
                "Date Operation": element.historiqueventedate,
                "Email Client": element.clientname,
                "Total avant Remise": element.historiquetotalprice,
                "Remise": element.commandremise,
                "Total a payer": element.commandtotalpricepayer,

                "Provenance": element.historiqueprovenance,
                "Mode livraison": element.livraisonmode,
                "Mode paiement": element.paymentmode,
                "Status paiement": element.paymentStatus,
                "Status livraison": element.livraisonStatus,
                "Date livraison": element.historiquedatelivraison
              };

              itemscsv.push(my_json);
            }

          });
          console.log(itemscsv);
          if (itemscsv.length > 0) {
            this.exportAsExcelFile(itemscsv, 'Historique des commandes à livrer');
          }
        }
      }

    });
  }
  */




  public exportAsExcelFile(json: any[], excelFileName: string): void {
    const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(json);
    const workbook: XLSX.WorkBook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
    const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
    this.saveAsExcelFile(excelBuffer, excelFileName);
  }
  private saveAsExcelFile(buffer: any, fileName: string): void {
    const data: Blob = new Blob([buffer], { type: EXCEL_TYPE });
    FileSaver.saveAs(data, fileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION);
  }


  updateByStat(event) {
    const val = event.value.toLowerCase();
    let rows: any[] = [];
    this.logger.warn("---------------valeur", val);

    this.dataSource = new MatTableDataSource([]);

    if (val === "en ligne") {
      this.itemsFinal.forEach(function (element) {

        if (("" + element.historiqueprovenance).toString().toLocaleLowerCase() !== "web-caisse") {
          rows.push(element);
        }
      });

      this.items = this.temp = rows;
      this.dataSource = new MatTableDataSource();
      this.dataSource.data = rows;

    } else if (val === "caisse") {
      this.itemsFinal.forEach(function (element) {

        if (("" + element.historiqueprovenance).toString().toLocaleLowerCase() === "web-caisse") {
          rows.push(element);
        }
      });

      this.items = this.temp = rows;
      this.dataSource = new MatTableDataSource();
      this.dataSource.data = rows;

    } else if (val === "carte") {
      this.itemsFinal.forEach(function (element) {

        if (element.paymentmode.toString().toLocaleLowerCase() === "carte abonnement") {
          rows.push(element);
        }
      });

      this.items = this.temp = rows;
      this.dataSource = new MatTableDataSource();
      this.dataSource.data = rows;
    } else {
      this.items = this.temp = this.itemsFinal;
      this.dataSource = new MatTableDataSource();
      this.dataSource.data = rows;
    }


  }

  searchCommande() {
    this.getallCommandesPaginable(0, 10);
  }



  search(size, limit) {
    let to: string = "";
    let from: string = "";
    let date = new Date(this.itemForm.get("from").value);
    let date1 = new Date(this.itemForm.get("to").value);

    let searchdate: Searchbydate = new Searchbydate();

    if ((date.toString() != 'Invalid Date')) {
      from = this.itemForm.get("from").value;
    }

    if (date1.toString() != null) {
      to = this.itemForm.get("to").value;
    }

    let newDate = new Date(from);
    let newDate1 = new Date(to);

    if ((newDate.toDateString() !== "Invalid Date") && (newDate1.toDateString() !== "Invalid Date")) {
      if (newDate1 >= newDate) {
        searchdate.datedebut = this.datePipe.transform(newDate, "yyyy-MM-dd HH:mm:ss");
        searchdate.datefin = this.datePipe.transform(newDate1, "yyyy-MM-dd HH:mm:ss");
        searchdate.check = true;
      } else {
        this.snackbar.open('Format de date invalide ', 'OK', { duration: 4000 });
      }
    } else {
      searchdate.check = false;
    }
    searchdate.paiement = this.paiement;
    searchdate.checkpaiement = this.checkpaiement;
    searchdate.provenance = this.provenance;
    searchdate.checklivraison = this.checklivraison;
    searchdate.livraison = this.livraison;
    searchdate.limit = 10;
    searchdate.size = size;
    console.log(searchdate);

    this.commandeserv.researchCommande(searchdate).subscribe(data => {
      console.log(data);
      let resp1: Historiq[] = [];
      let response: any = data.body;

      if (response.content && response.content.length > 0) {
        resp1 = response.content;
      }
      this.totalElements = response.totalElements;
      this.pageSizeApi = response.size;

      let respfinal: Historiq[] = [];
      for (let rp of resp1) {
        if (rp.client != null) {
          rp.clientname = rp.client.clientemail;
        }
        if (rp.agent != null) {
          rp.agentname = rp.agent.agentemail;
        }
        respfinal.push(rp);
      }

      this.items = this.temp = respfinal;
      this.itemsFinal = respfinal;

      //this.checkFilter();
      this.dataSource = new MatTableDataSource(this.items);

    },
      error => {
        this.snackbar.open('Echec !! Une erreur est survenue ', 'OK', {
          duration: 10000
        });
        console.log('erreur retour');
        console.log(error);
      }
    );


  }


  searchPdf(size, limit) {
    let to: string = "";
    let from: string = "";
    let date = new Date(this.itemForm.get("from").value);
    let date1 = new Date(this.itemForm.get("to").value);

    let searchdate: Searchbydate = new Searchbydate();

    if ((date.toString() != 'Invalid Date')) {
      from = this.itemForm.get("from").value;
    }

    if (date1.toString() != null) {
      to = this.itemForm.get("to").value;
    }

    let newDate = new Date(from);
    let newDate1 = new Date(to);

    if ((newDate.toDateString() !== "Invalid Date") && (newDate1.toDateString() !== "Invalid Date")) {
      if (newDate1 >= newDate) {
        searchdate.datedebut = this.datePipe.transform(newDate, "yyyy-MM-dd HH:mm:ss");
        searchdate.datefin = this.datePipe.transform(newDate1, "yyyy-MM-dd HH:mm:ss");
        searchdate.check = true;
      } else {
        this.snackbar.open('Format de date invalide ', 'OK', { duration: 4000 });
      }
    } else {
      searchdate.check = false;
    }
    searchdate.paiement = this.paiement;
    searchdate.checkpaiement = this.checkpaiement;
    searchdate.provenance = this.provenance;
    searchdate.checklivraison = this.checklivraison;
    searchdate.livraison = this.livraison;
    searchdate.limit = 10;
    searchdate.size = size;
    console.log(searchdate);

    this.commandeserv.researchDownload(searchdate).
      subscribe(data => {
        console.log(data);
        let resp1: Historiq[] = [];
        let response: any = data.body;

        if (response && response.length > 0) {
          resp1 = response;
        }
        console.log("-----------A TELECHARGER ------------");
        console.log(resp1);

        /*
        this.totalElements = response.totalElements;
        this.pageSizeApi = response.size;
        */

        let respfinal: Historiq[] = [];
        for (let rp of resp1) {
          if (rp.client != null) {
            rp.clientname = rp.client.clientemail;
          }
          if (rp.agent != null) {
            rp.agentname = rp.agent.agentemail;
          }
          respfinal.push(rp);
        }
        console.log(respfinal);

        this.downloadPdf(respfinal);

        /*
        this.items = this.temp = respfinal;
        this.itemsFinal = respfinal;
  
        //this.checkFilter();
        this.dataSource = new MatTableDataSource(this.items);
        */

      },
        error => {
          this.snackbar.open('Echec !! Une erreur est survenue ', 'OK', {
            duration: 10000
          });
          console.log('erreur retour');
          console.log(error);
        }
      );


  }

  searchXls(size, limit) {
    let to: string = "";
    let from: string = "";
    let date = new Date(this.itemForm.get("from").value);
    let date1 = new Date(this.itemForm.get("to").value);

    let searchdate: Searchbydate = new Searchbydate();

    if ((date.toString() != 'Invalid Date')) {
      from = this.itemForm.get("from").value;
    }

    if (date1.toString() != null) {
      to = this.itemForm.get("to").value;
    }

    let newDate = new Date(from);
    let newDate1 = new Date(to);

    if ((newDate.toDateString() !== "Invalid Date") && (newDate1.toDateString() !== "Invalid Date")) {
      if (newDate1 >= newDate) {
        searchdate.datedebut = this.datePipe.transform(newDate, "yyyy-MM-dd HH:mm:ss");
        searchdate.datefin = this.datePipe.transform(newDate1, "yyyy-MM-dd HH:mm:ss");
        searchdate.check = true;
      } else {
        this.snackbar.open('Format de date invalide ', 'OK', { duration: 4000 });
      }
    } else {
      searchdate.check = false;
    }
    searchdate.paiement = this.paiement;
    searchdate.checkpaiement = this.checkpaiement;
    searchdate.provenance = this.provenance;
    searchdate.checklivraison = this.checklivraison;
    searchdate.livraison = this.livraison;
    searchdate.limit = 10;
    searchdate.size = size;
    console.log(searchdate);

    this.commandeserv.researchDownload(searchdate).
      subscribe(data => {
        console.log(data);
        let resp1: Historiq[] = [];
        let response: any = data.body;

        if (response && response.length > 0) {
          resp1 = response;
        }
        console.log("-----------A TELECHARGER ------------");
        console.log(resp1);

        /*
        this.totalElements = response.totalElements;
        this.pageSizeApi = response.size;
        */

        let respfinal: Historiq[] = [];
        for (let rp of resp1) {
          if (rp.client != null) {
            rp.clientname = rp.client.clientemail;
          }
          if (rp.agent != null) {
            rp.agentname = rp.agent.agentemail;
          }
          respfinal.push(rp);
        }
        console.log(respfinal);

        this.downloadXls(respfinal);

        /*
        this.items = this.temp = respfinal;
        this.itemsFinal = respfinal;
  
        //this.checkFilter();
        this.dataSource = new MatTableDataSource(this.items);
        */

      },
        error => {
          this.snackbar.open('Echec !! Une erreur est survenue ', 'OK', {
            duration: 10000
          });
          console.log('erreur retour');
          console.log(error);
        }
      );


  }

  searchCommandeByDelivery() {

    let to: string = "";
    let from: string = "";
    let date = new Date(this.itemForm.get("from").value);
    let date1 = new Date(this.itemForm.get("to").value);

    if ((date.toString() != 'Invalid Date')) {
      from = this.itemForm.get("from").value;
    }

    if (date1.toString() != null) {
      to = this.itemForm.get("to").value;
    }

    let newDate = new Date(from);

    let newDate1 = new Date(to);


    if ((newDate.toDateString() === "Invalid Date") || (newDate1.toDateString() === "Invalid Date")) {
      this.snackbar.open('Format de date invalide ', 'OK', { duration: 4000 });
    } else {
      if (newDate1 >= newDate) {

        this.loader.open();
        let searchdate: Searchbydate = new Searchbydate();
        searchdate.datedebut = this.datePipe.transform(newDate, "yyyy-MM-dd HH:mm:ss");
        searchdate.datefin = this.datePipe.transform(newDate1, "yyyy-MM-dd HH:mm:ss");


        this.commandeserv.getCommandByDate(searchdate).subscribe(data => {
          this.logger.trace('DATA', data);

          switch (data.status) {

            case 200: {
              this.logger.trace('data', data);
              this.items = this.temp = data.body;
              this.loader.close();

              break;
            }
            case 201: {
              this.logger.trace('data', data);
              this.items = this.temp = data.body;
              this.loader.close();

              break;
            }

            case 204: {
              this.logger.trace('data', data);
              this.items = this.temp = [];
              this.loader.close();

              break;
            }

          }
        },
          error => {
            this.logger.error('ERREUR', error);
            switch (error.status) {


              default: {
                this.loader.close();
                this.snackbar.open('Echec !! Une erreur est survenue ', 'OK', {
                  duration: 10000
                });
                this.items = [];
                break;
              }
            }
          });



      } else {
        this.snackbar.open('Format de date invalide ', 'OK', { duration: 4000 });
      }
    }





  }




  /**
   * affecter une commande à un livreur
   */
  openassign(value: any) {
    debugger;
    console.log('line command', value)
    this.dialog.open(AffectercommandeaunlivreurComponent,
      {
        width: '720px',
        data: { title: 'Affecter cette commande à livreur', payload: value }
      }
    ).afterClosed().subscribe((res) => {
      console.log(res);
      if (!res) {
        return;
      }
    });
  }








  /**
  * Evenement lors du click de la pagination
  * @param $event
  */
  onFooterPage($event) {
    this.isLoading = true; // LoadingIndicator Chargement des infos
    this.tableOffset = $event.page - 1; // Saut de page = 1
    // this.logger.trace('tableOffset', this.tableOffset);
    // Rechercher les infos de la page en cours
    this.search(this.tableOffset.toString(), this.tableLimit.toString()); // Demander la 1ere page (Les pages commencent par 0)
  }


}
