import { DatePipe } from '@angular/common';
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MatTableDataSource, MatPaginator, MatDialog, MatSnackBar } from '@angular/material';
import { ConfigService } from 'ngx-envconfig';
import { NGXLogger } from 'ngx-logger';
import { NgxPermissionsService } from 'ngx-permissions';
import { Subscription } from 'rxjs';
import { ConfirmationService } from 'src/app/confirm/confirmation.service';
import { Agentstorage } from 'src/app/models/agentstorage';
import { ClientCorporate } from 'src/app/models/Clienttype';
import { InitDataFormuleAbat, Formuleabat } from 'src/app/models/formule-abonnement';
import { Rolefonction } from 'src/app/models/rolefonction';
import { ApploaderService } from 'src/app/services/apploader/apploader.service';
import { AddFormuleComponent } from '../../formuleabonnement/addformule/addformule.component';
import { FormuleAbonnementService } from '../../formuleabonnement/formuleabonnement.service';
import { UpdateFormuleComponent } from '../../formuleabonnement/updateformule/updateformule.component';
import { AddStepFormuleComponent } from './add-step-formule/add-step-formule.component';

@Component({
  selector: 'fury-step5-formule',
  templateUrl: './step5-formule.component.html',
  styleUrls: ['./step5-formule.component.scss']
})
export class Step5FormuleComponent implements OnInit {

  displayedColumns: string[] = ['formulenom',
  'formuleisprecompte', 'formuleduree', 'formulemontant', 'formulestatus', 'actions'];
  dataSource = new MatTableDataSource<ClientCorporate>([]);
  @ViewChild(MatPaginator) paginator: MatPaginator;
  public getItemSub: Subscription;
  client: ClientCorporate[] = [];

  public afficherFormuleAbonnement: boolean;
  public creationFormuleAbonnement: boolean;
  public modificationFormuleAbonnement: boolean;
  public suppressionFormuleAbonnement: boolean;

  public keyAfficherFormuleAbonnement = 'afficher formule abonnement';
  public keyCreationFormuleAbonnement = 'ajouter formule abonnement';
  public keyModificationFormuleAbonnement = 'modifier formule abonnement';
  public keySuppressionFormuleAbonnement = 'supprimer formule abonnement';

  constructor(private dialog: MatDialog, public formBuilder: FormBuilder,
    private snackbar: MatSnackBar, private datePipe: DatePipe,
    private configService: ConfigService,
    private confirmService: ConfirmationService, private loader: ApploaderService,
    private permissionsService: NgxPermissionsService, private logger: NGXLogger,
    private snack: MatSnackBar,
    private pointservice: FormuleAbonnementService) { }

  ngOnInit() {
    //this.verifierDroit();
    this.getAllFormule();
  }


  getAllFormule() {
    this.getItemSub = this.pointservice.get('formule')
      .subscribe(data => {
        const statuscode = data.status;
        this.logger.trace('DATA', data);
        switch (statuscode) {
          case 200: {
            this.client = data.body;
            this.dataSource.data = this.client;
            this.dataSource.paginator = this.paginator;
            // this.temp = data.body;
            break;
          }
          case 204: {
            // this.snack.open('Aucune TVA disponible', 'OK', { duration: 4000 })
            break;
          }
          default: {
            // this.snack.open('Une erreur est survenue!', 'OK', { duration: 4000 })
            break;
          }
        }
      },
        error => {
          this.logger.error('error');
          this.logger.error(error);

          switch (error.status) {

            default: {

              //    this.snack.open('Une erreur est survenue!', 'OK', { duration: 4000 })

              break;
            }
          }
        });
  }

  createFormuleAbonnement() {
    this.dialog.open(AddStepFormuleComponent, { width: '720px' }).afterClosed().subscribe((res) => {
      console.log(res);
      if (!res) {
        return;
      }
      const request: any = res;
      let u = new Agentstorage();
      u = JSON.parse(localStorage.getItem('curentuser'));
      if (u != null) {
        request.userpisteaudit = u.agent.agentemail + ' ' + u.agent.agentfirstname + ' ' + u.agent.agentlastname;
      }
      this.pointservice.post(request, 'formule/new').subscribe(data => {
        this.logger.trace('DATA', data);
        this.getAllFormule();
        this.loader.close();
        this.snackbar.open('Formule crée avec succes ', 'OK', {
          duration: 10000
        });
      },
        error => {
          this.logger.error('ERREUR', error);
          switch (error.status) {

            case 409: {
              // this.getallFood();
              this.loader.close();
              this.snackbar.open('Cette formule existe deja ', 'OK', {
                duration: 10000
              });
              break;
            }

            default: {
              this.loader.close();

              this.snackbar.open('Echec !! Une erreur est survenue ', 'OK', {
                duration: 10000
              });
              break;
            }
          }
        });
    });
  }




  deleteItem(row) {
    this.confirmService.confirm({
      message: `Voulez vous supprimer la formule : ${row.formulenom}?  `
    })
      .subscribe(res => {
        if (res) {
          let u = new Agentstorage();
          u = JSON.parse(localStorage.getItem('curentuser'));
          if (u != null) {
            row.userpisteaudit = u.agent.agentemail + ' ' + u.agent.agentfirstname + ' ' + u.agent.agentlastname;
          }

          this.pointservice.delete(`formule/${row.formuleid}`, ).subscribe(data => {
            console.log(data);
            this.getAllFormule();
            this.snackbar.open('Success ', 'OK', {
              duration: 10000
            });

          },
            error => {

              this.snackbar.open('Echec !! Une erreur est survenue ', 'OK', {
                duration: 10000
              });
              console.log('erreur retour');
              console.log(error);
            }
          );
        }
      });
  }



  updateStatusItem( status : any , row : any , etat : number) {
    this.confirmService.confirm({ message:  `Confirmation `+status +` ${row.formulenom}?` })
      .subscribe(res => {
        if (res) {
          let request : any = {} ;
          request.formuleid=row.formuleid;
          this.logger.info(request);
          //request.categoriecorpoid=row.categoriecorpoid ;
          this.pointservice.post(request, "formule/updatestatus/"+etat)
            .subscribe(data => {
              let statuscode = data.status;
              switch (statuscode) {
                case 200: {
                  // Actualisé les données
                  this.getAllFormule();
                  this.snack.open('Succes!', 'OK', { duration: 4000 })
                  break;
                }
             
                default: {
                  this.snack.open('Une erreur est survenue!', 'OK', { duration: 4000 })
                  break;
                }
              }

            },
              error => {
                let statuscode = error.status;
                switch (statuscode) {
                
                  default: {
                    this.snack.open('Une erreur est survenue!', 'OK', { duration: 4000 })
                    break;
                  }
                }

              });
        }

      })


  }



  openPopUp(value) {


    /*
    let formule : Formule ;
    let formuleabats : Formuleabat[] ;
    */

    this.pointservice.requestDataFromMultipleSources('formule/findbyid/'+value.formuleid , 'formule/getformuleabattementid/'+value.formuleid ).subscribe(responseList => {
      let initData : InitDataFormuleAbat  = new InitDataFormuleAbat();
      initData.formule=responseList[0].body ;
      initData.formuleabat=responseList[1].body ;
      console.log(initData);
      if(initData.formuleabat){
       let lsAbattement: string[] = [] ;
       let formuleabat: Formuleabat[] = initData.formuleabat ;
       for (let it of formuleabat) {
         if(it.abattement){
           lsAbattement.push(it.abattement.abattementid);
         }
       }
       initData.lsAbattement=lsAbattement;
      }

      console.log(initData);

         
      this.dialog.open(UpdateFormuleComponent,
        {
          // disableClose: true,
          width: '720px',
          data: { title: '', payload: initData }
        }
      ).afterClosed().subscribe((res) => {
        console.log(res);
  
        if (!res) {
          return;
        }
  
        const request: any = res;
  
        let u = new Agentstorage();
        u = JSON.parse(localStorage.getItem('curentuser'));
        if (u != null) {
          request.userpisteaudit = u.agent.agentemail + ' ' + u.agent.agentfirstname + ' ' + u.agent.agentlastname;
        }
  
        console.log("----- formule to update --- ");
        console.log(request);
        console.log("------------");

        this.pointservice.put(request, 'formule').subscribe(data => {
          this.logger.trace('DATA', data);
  
  
          switch (data.status) {
  
            case 201: {
  
              console.log('data');
              console.log(data);
  
              this.getAllFormule();
              this.loader.close();
              this.snackbar.open('Formule mis à jour avec succès ', 'OK', {
                duration: 10000
              });
  
              break;
            }
  
          }
        },
          error => {
            this.logger.error('ERREUR', error);
            switch (error.status) {
  
              case 409: {
                // this.getallFood();
                this.loader.close();
                this.snackbar.open('Cette formule existe déjà ', 'OK', {
                  duration: 10000
                });
                break;
              }
  
              default: {
                this.loader.close();
  
                this.snackbar.open('Echec !! Une erreur est survenue ', 'OK', {
                  duration: 10000
                });
                break;
              }
            }
          });
  
      });

  });
 
  
  }



  getAllListAb(id : any){
    this.getItemSub = this.pointservice.get('formule/getformuleabattementid/'+id)
    .subscribe(data => {
      const statuscode = data.status;
      this.logger.trace('DATA', data);
      switch (statuscode) {
        case 200: {

          console.log("+++++++++++++");
          console.log( JSON.stringify(data.body));
          console.log("+++++++++++++");

          break;
        }
        case 204: {
          break;
        }
        default: {
          break;
        }
      }
    },
      error => {
        this.logger.error('error');
        this.logger.error(error);

        switch (error.status) {

          default: {
            break;
          }
        }
      });
  }


  public verifierDroit() {
    this.logger.trace('BEGIN');
    // this.logger.trace('Clé', this.configService.get('keylocalstorage'));

    let agentOnline: Agentstorage;
    agentOnline = JSON.parse(localStorage.getItem(this.configService.get('keylocalstorage')));
    // this.logger.trace(agentOnline);
    const rolefontion: Rolefonction[] = agentOnline.rolefonction;
    // this.logger.trace('rolefontion', rolefontion);

    for (const key in rolefontion) {
      if (rolefontion.hasOwnProperty(key)) {
        // this.logger.trace('KEY', key);
        const element = rolefontion[key].fonction.fonctionname;
        this.logger.trace('element', element);

        if (element === this.keyAfficherFormuleAbonnement) {
          this.logger.trace('true');
          this.afficherFormuleAbonnement = true;
        }
        if (element === this.keyCreationFormuleAbonnement) {
          this.logger.trace('true');
          this.creationFormuleAbonnement = true;
        }
        if (element === this.keyModificationFormuleAbonnement) {
          this.logger.trace('true');
          this.modificationFormuleAbonnement = true;
        }
        if (element === this.keySuppressionFormuleAbonnement) {
          this.logger.trace('true');
          this.suppressionFormuleAbonnement = true;
        }
      }
    }

  }


}
