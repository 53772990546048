import { FormGroup, FormControl, Validators } from '@angular/forms';
import { RolesService } from './../../../../services/roles.service';
import { MAT_DIALOG_DATA, MatDialogRef, MatSnackBar } from '@angular/material';
import { Component, OnInit, Inject } from '@angular/core';
import { Role } from 'src/app/models/Role';

//import Swal from 'sweetalert2';


export interface Bool {
  value: string;
  viewValue: string;
}

export interface DialogData {
  role: Role;
}

@Component({
  selector: 'fury-role-modifier',
  templateUrl: './role-modifier.component.html',
  styleUrls: ['./role-modifier.component.scss']
})
export class RoleModifierComponent implements OnInit {

  roleForm: FormGroup;
  roleId: string;
  role: Role;

  isdeleted: Bool[] = [
    {value: 'true', viewValue: 'true'},
    {value: 'false', viewValue: 'false'}
  ];


  constructor( public dialogRef: MatDialogRef<RoleModifierComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private roleService: RolesService ,  private snackbar: MatSnackBar) { }


  onNoClick(): void {
    console.info('---clic on close --- ')
    this.dialogRef.close();
  }

  ngOnInit() {

    this.roleForm = new FormGroup({ rolename: new FormControl(), roleisdeleted: new FormControl() })

    if (this.data) { // edit form
      console.log('checking selected role--- : ' + this.data.role.rolename);
      this.roleForm.reset({ rolename: this.data.role.rolename, roleisdeleted: this.data.role.roleisdeleted });
    }


  }


  onSubmit() {

    if (this.roleForm.valid) {
      console.log('-----------updating role ----------------');

      let updatedRole: Role = new Role();
      updatedRole.roleid = this.data.role.roleid;
      updatedRole.roledatecreation = this.data.role.roledatecreation;
      updatedRole.rolename = this.roleForm.controls['rolename'].value;
      updatedRole.roleisdeleted = this.data.role.roleisdeleted;

      console.log('-----------data to update  ---------------- ' + updatedRole.roleid + ' ' +
        updatedRole.rolename + ' ' + updatedRole.roleisdeleted);


      this.roleService.update(updatedRole).subscribe(data => {

        console.log(data);
        switch (data.status) {
          case 201: {
            // this.loading.dismiss('messagesService.loadMessagesOverview');

            let resp: Role;
            resp = data.body;
            console.log("------------------------------------");
            console.log(resp);
            console.log("------------------------------------");

           
           /* Swal.fire({
              position: 'top-end',
              type: 'success',
              title: 'Your work has been saved',
              showConfirmButton: false,
              timer: 1500
            })*/




            this.snackbar.open('Success modification', 'OK', {
              duration: 2000
            });

            break;
          }
        }
      },
        error => {
         
          console.log("erreur retour");
          // this.loading.dismiss('messagesService.loadMessagesOverview');
          switch (error.status) {
            case 409:
              //this.FormReg(" Mot de passe ou login incorrect ");
              this.snackbar.open('Echec modification', 'Le role existe deja', {
                duration: 2000
              });
              break;
            default: {
              // this.FormReg(" erreur de connexion !!! Veuillez ressayer");
              this.snackbar.open('Echec modification', 'OK', {
                duration: 2000
              });
              break;
            }
          }

        });


      console.log('-----------updating role ok ----------------');


      this.dialogRef.close();
    }  else {

     
       // this.validateAllFormFields(this.roleForm);
        this.snackbar.open('Veuillez saisir toutes les valeurs svp', 'OK', {
          duration: 2000
        });
    }


   

  }


  


}

