import { Component, OnInit, Inject } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef, MatSnackBar } from '@angular/material';
import { ConfirmationService } from '../../../../app/confirm/confirmation.service';
import { NGXLogger } from 'ngx-logger';
import { HeureService } from '../heure.service';
import { Heurecommande } from '../../../models/heurecommande';
import { Agentstorage } from 'src/app/models/agentstorage';

@Component({
  selector: 'fury-addheurecmd',
  templateUrl: './addheurecmd.component.html',
  styleUrls: ['./addheurecmd.component.scss']
})
export class AddheurecmdComponent implements OnInit {

  public itemForm: FormGroup;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<AddheurecmdComponent>,
    private fb: FormBuilder,
    private snack: MatSnackBar,
    private confirmService: ConfirmationService,
    private logger: NGXLogger,
    private heureservic: HeureService) { }

  ngOnInit() {
    this.buildItemForm(this.data.payload);
  }

  buildItemForm(item) {

    console.log("------------");
    console.log(item.heurecommandeheuredebut === undefined);

    if (item && item.heurecommandeheuredebut !== undefined && item.heurecommandeheurefin !== undefined) {


      var tab1 = item.heurecommandeheuredebut.split(':');
      var tab2 = item.heurecommandeheurefin.split(':');

      item.heuredebut = tab1[0];
      item.mindebut = tab1[1];

      item.heurefin = tab2[0];
      item.minfin = tab2[1];
    }
    this.itemForm = this.fb.group({
      heurecommandeid: [item.heurecommandeid || ''],
      heurecommandelibelle: [item.heurecommandelibelle || '', [Validators.required]],
      heuredebut: [item.heuredebut || '', [Validators.required, Validators.min(0), Validators.max(23)]],
      mindebut: [item.mindebut || '', [Validators.required, Validators.min(0), Validators.max(59)]],
      heurefin: [item.heurefin || '', [Validators.required, Validators.min(0), Validators.max(23)]],
      minfin: [item.minfin || '', [Validators.required, Validators.min(0), Validators.max(59)]]
    });
  }

  /*
   heurecommandeheuredebut: string;
    heurecommandeheurefin: string;
    heurecommandeid: string;
    heurecommandelibelle: string;
    heurecommandestatus: string;
    */



  submit() {

    // Verifier si l'id est null (ajout)
    this.logger.debug('Form value', this.itemForm.value);
    let heurecommandeid = this.itemForm.value.heurecommandeid;
    this.logger.debug('Form value', heurecommandeid);

    let res: Heurecommande = this.itemForm.value;
    if(res.heuredebut.length===1){
      res.heuredebut="0"+res.heuredebut;
    }
    if(res.mindebut.length===1){
      res.mindebut="0"+res.mindebut;
    }

    if(res.heurefin.length===1){
      res.heurefin="0"+res.heurefin;
    }
    if(res.minfin.length===1){
      res.minfin="0"+res.minfin;
    }


    res.heurecommandeheuredebut = res.heuredebut + ":" + res.mindebut;
    res.heurecommandeheurefin = res.heurefin + ":" + res.minfin;

    let u = new Agentstorage();
    u = JSON.parse(localStorage.getItem("curentuser"));
    if (u != null) {
      res.userpisteaudit = u.agent.agentemail + " " + u.agent.agentfirstname + " " + u.agent.agentlastname;
    }

    if (!heurecommandeid || heurecommandeid == '') {
      // Ajout
      this.heureservic.post(res, "heurecommande/add")
        .subscribe(data => {
          let statuscode = data.status;

          switch (statuscode) {
            case 200: {
              // this.getItems();
              this.dialogRef.close(this.itemForm.value);
              this.snack.open('Heure de commande Ajouté!', 'OK', { duration: 4000 })
              break;
            }
            case 201: {
              // this.getItems();
              this.dialogRef.close(this.itemForm.value);
              this.snack.open('Heure de commande Ajouté!', 'OK', { duration: 4000 })
              break;
            }
            case 401: {
              this.snack.open('Unauthorized, One or several parameters are not informed', 'OK', { duration: 4000 })
              break;
            }
            case 406: {
              this.snack.open('Not Acceptable, Incorrect Format of the parameters', 'OK', { duration: 4000 })
              break;
            }
            case 409: {
              this.snack.open('Conflict, Object Already exist', 'OK', { duration: 4000 })
              break;
            }
            case 500: {
              this.snack.open('Internal server error!', 'OK', { duration: 4000 })
              break;
            }
            default: {
              this.snack.open('Une erreur est survenue!', 'OK', { duration: 4000 })
              break;
            }
          }

        },
          error => {
            let statuscode = error.status;
            switch (statuscode) {
              case 401: {
                this.snack.open('Unauthorized, One or several parameters are not informed', 'OK', { duration: 4000 })
                break;
              }
              case 406: {
                this.snack.open('Not Acceptable, Incorrect Format of the parameters', 'OK', { duration: 4000 })
                break;
              }
              case 409: {
                this.snack.open('Cette Heure de commande existe deja', 'OK', { duration: 4000 })
                break;
              }
              case 500: {
                this.snack.open('Internal server error!', 'OK', { duration: 4000 })
                break;
              }
              default: {
                this.snack.open('Une erreur est survenue!', 'OK', { duration: 4000 })
                break;
              }
            }

          });

    } else {
      // Modif
      this.heureservic.put(res, "heurecommande/update")
        .subscribe(data => {
          let statuscode = data.status;

          switch (statuscode) {
            case 200: {
              // Actualisé les données
              // this.getItems();
              this.dialogRef.close(this.itemForm.value);
              this.snack.open('Heure de commande modifié!', 'OK', { duration: 4000 });
              break;
            }
            case 201: {
              // Actualisé les données
              // this.getItems();
              this.dialogRef.close(this.itemForm.value);
              this.snack.open('Heure de commande modifié!', 'OK', { duration: 4000 });
              break;
            }
            case 401: {
              this.snack.open('Unauthorized, One or several parameters are not informed', 'OK', { duration: 4000 })
              break;
            }
            case 406: {
              this.snack.open('Not Acceptable, Incorrect Format of the parameters', 'OK', { duration: 4000 })
              break;
            }
            case 409: {
              this.snack.open('Heure de commande existe deja', 'OK', { duration: 4000 })
              break;
            }
            case 500: {
              this.snack.open('Internal server error!', 'OK', { duration: 4000 })
              break;
            }
            default: {
              this.snack.open('Erreur!', 'OK', { duration: 4000 })
              break;
            }

          }

        },
          error => {
            let statuscode = error.status;
            switch (statuscode) {
              case 401: {
                this.snack.open('Unauthorized, One or several parameters are not informed', 'OK', { duration: 4000 })
                break;
              }
              case 406: {
                this.snack.open('Not Acceptable, Incorrect Format of the parameters', 'OK', { duration: 4000 })
                break;
              }
              case 409: {
                this.snack.open('Heure de commande existe deja', 'OK', { duration: 4000 })
                break;
              }
              case 404: {
                this.snack.open('URL introuvable!', 'OK', { duration: 4000 })
                break;
              }
              case 500: {
                this.snack.open('Internal server error!', 'OK', { duration: 4000 })
                break;
              }
              default: {
                this.snack.open('Une erreur est survenue!', 'OK', { duration: 4000 })
                break;
              }
            }
          });
    }
  }

}
