import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GestionmenuComponent } from './gestionmenu.component';
import { IconsRoutingModule } from '../icons/icons-routing.module';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { MaterialModule } from 'src/@fury/shared/material-components.module';
import { BreadcrumbsModule } from 'src/@fury/shared/breadcrumbs/breadcrumbs.module';
import { FuryCardModule } from 'src/@fury/shared/card/card.module';
import { MatInputModule, MatChipsModule, MatSelectModule, MatListModule } from '@angular/material';
import { CreatemenuComponent } from './createmenu/createmenu.component';
import { UpdatemenuComponent } from './updatemenu/updatemenu.component';
import { ConfirmComponent } from 'src/app/confirm/confirm.component';
import { ListModule } from 'src/@fury/shared/list/list.module';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

@NgModule({
  declarations: [GestionmenuComponent, CreatemenuComponent, UpdatemenuComponent ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    MaterialModule,
    BreadcrumbsModule,
    MatInputModule,
    FormsModule,
    MatChipsModule,
    ListModule,
    MatSelectModule,
    MatListModule,
    NgxDatatableModule,
    BrowserAnimationsModule,
    FuryCardModule
  ],
  entryComponents: [CreatemenuComponent,ConfirmComponent, UpdatemenuComponent]
})
export class GestionmenuModule { }
