import { FuryCardModule } from 'src/@fury/shared/card/card.module';
import { MatInputModule, MatSelectModule } from '@angular/material';
import { BreadcrumbsModule } from 'src/@fury/shared/breadcrumbs/breadcrumbs.module';
import { MaterialModule } from './../../../@fury/shared/material-components.module';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { NgModule , CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';

import { GestionagentRoutingModule } from './gestionagent-routing.module';
import { GestionagentComponent } from './gestionagent.component';
import { GestionagentformComponent } from './gestionagentform/gestionagentform.component';
import { DeleteagentComponent } from './deleteagent/deleteagent.component';
@NgModule({
  declarations: [GestionagentComponent, GestionagentformComponent, DeleteagentComponent],
  imports: [
    CommonModule,
    GestionagentRoutingModule,
    ReactiveFormsModule,
    MaterialModule,
    BreadcrumbsModule,
    MatInputModule,
    FuryCardModule,
    FormsModule
  ],
  schemas: [ CUSTOM_ELEMENTS_SCHEMA ]
})
export class GestionagentModule { }
