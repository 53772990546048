import { Component, OnInit, Inject } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef, MatSnackBar } from '@angular/material';
import { ConfirmationService } from 'src/app/confirm/confirmation.service';
import { NGXLogger } from 'ngx-logger';
import { CategoriemenuService } from '../categoriemenu.service';
import { Agentstorage } from 'src/app/models/agentstorage';

@Component({
  selector: 'fury-popupcategoriemenu',
  templateUrl: './popupcategoriemenu.component.html',
  styleUrls: ['./popupcategoriemenu.component.scss']
})
export class PopupcategoriemenuComponent implements OnInit {

  public itemForm: FormGroup;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<PopupcategoriemenuComponent>,
    private fb: FormBuilder,
    private snack: MatSnackBar,
    private confirmService: ConfirmationService,
    private logger: NGXLogger,
    private categoriemenuService: CategoriemenuService) { }

  ngOnInit() {
    this.buildItemForm(this.data.payload);
  }

  buildItemForm(item) {

    this.itemForm = this.fb.group({
      categorieid: [item.categorieid || ''],
      categoriecode: [item.categoriecode || '', []],
      categoriename: [item.categoriename || '', [Validators.required]],
      categoriedatecreation: [item.categoriedatecreation || '', []],
      categorieisdeleted: [item.categorieisdeleted || '', []],

    });
  }

  submit() {

    // Verifier si l'id est null (ajout)
    this.logger.debug('Form value', this.itemForm.value);
    let categorieid = this.itemForm.value.categorieid;
    this.logger.debug('Form value', categorieid);

    let res = this.itemForm.value;
    let u = new Agentstorage();
    u = JSON.parse(localStorage.getItem("curentuser"));
    if (u != null) {
      res.userpisteaudit = u.agent.agentemail + " " + u.agent.agentfirstname + " " + u.agent.agentlastname;
    }

    if (!categorieid || categorieid == '') {
      // Ajout
      this.categoriemenuService.createCategorie(res)
        .subscribe(data => {
          let statuscode = data.status;

          switch (statuscode) {
            case 200: {
              // this.getItems();
              this.dialogRef.close(this.itemForm.value);
              this.snack.open('Catégorie Ajouté!', 'OK', { duration: 4000 })
              break;
            }
            case 201: {
              // this.getItems();
              this.dialogRef.close(this.itemForm.value);
              this.snack.open('Catégorie Ajouté!', 'OK', { duration: 4000 })
              break;
            }
            case 401: {
              this.snack.open('Unauthorized, One or several parameters are not informed', 'OK', { duration: 4000 })
              break;
            }
            case 406: {
              this.snack.open('Not Acceptable, Incorrect Format of the parameters', 'OK', { duration: 4000 })
              break;
            }
            case 409: {
              this.snack.open('Conflict, Object Already exist', 'OK', { duration: 4000 })
              break;
            }
            case 500: {
              this.snack.open('Internal server error!', 'OK', { duration: 4000 })
              break;
            }
            default: {
              this.snack.open('Une erreur est survenue!', 'OK', { duration: 4000 })
              break;
            }
          }

        },
          error => {
            let statuscode = error.status;
            switch (statuscode) {
              case 401: {
                this.snack.open('Unauthorized, One or several parameters are not informed', 'OK', { duration: 4000 })
                break;
              }
              case 406: {
                this.snack.open('Not Acceptable, Incorrect Format of the parameters', 'OK', { duration: 4000 })
                break;
              }
              case 409: {
                this.snack.open('Conflict, Object Already exist', 'OK', { duration: 4000 })
                break;
              }
              case 500: {
                this.snack.open('Internal server error!', 'OK', { duration: 4000 })
                break;
              }
              default: {
                this.snack.open('Une erreur est survenue!', 'OK', { duration: 4000 })
                break;
              }
            }

          });

    } else {
      // Modif
      this.categoriemenuService.updateCategorie(res)
        .subscribe(data => {
          let statuscode = data.status;

          switch (statuscode) {
            case 200: {
              // Actualisé les données
              // this.getItems();
              this.dialogRef.close(this.itemForm.value);
              this.snack.open('Catégorie modifié!', 'OK', { duration: 4000 });
              break;
            }
            case 201: {
              // Actualisé les données
              // this.getItems();
              this.dialogRef.close(this.itemForm.value);
              this.snack.open('Catégorie modifié!', 'OK', { duration: 4000 });
              break;
            }
            case 401: {
              this.snack.open('Unauthorized, One or several parameters are not informed', 'OK', { duration: 4000 })
              break;
            }
            case 406: {
              this.snack.open('Not Acceptable, Incorrect Format of the parameters', 'OK', { duration: 4000 })
              break;
            }
            case 409: {
              this.snack.open('Conflict, Object Already exist', 'OK', { duration: 4000 })
              break;
            }
            case 500: {
              this.snack.open('Internal server error!', 'OK', { duration: 4000 })
              break;
            }
            default: {
              this.snack.open('Erreur!', 'OK', { duration: 4000 })
              break;
            }

          }

        },
          error => {
            let statuscode = error.status;
            switch (statuscode) {
              case 401: {
                this.snack.open('Unauthorized, One or several parameters are not informed', 'OK', { duration: 4000 })
                break;
              }
              case 406: {
                this.snack.open('Not Acceptable, Incorrect Format of the parameters', 'OK', { duration: 4000 })
                break;
              }
              case 409: {
                this.snack.open('Conflict, Object Already exist', 'OK', { duration: 4000 })
                break;
              }
              case 404: {
                this.snack.open('URL introuvable!', 'OK', { duration: 4000 })
                break;
              }
              case 500: {
                this.snack.open('Internal server error!', 'OK', { duration: 4000 })
                break;
              }
              default: {
                this.snack.open('Une erreur est survenue!', 'OK', { duration: 4000 })
                break;
              }
            }
          });
    }




  }


}
