export class Statperiod {
    numero: number;
    debut: string;
    fin: string;
    stat: Stat[];
}


export class Statuser {

    cptemobile: number = 0;
    cpteweb: number = 0;
    cptecorporate: number = 0;
    cptenocorporate: number = 0;
    cmdligne: number = 0;
    cmdcaisse: number = 0;
    cmdborne: number = 0;
    cmdmobile: number = 0;

}


export class Statvente {
    dateheure: string;
    produit: string;
    qte: number;
    pu: number;
    total: number;
    numcommande: string;
    caisse: string;
    paiement: string;
    livraison: string;
    cmdtotal: number;
}

export class Itme {
    qte: number;
    total: number;
    food: string;
    service: string;
    categorie: string;
    pu: number;
    payment: string;
    livraison: string;
    provenance: string;
    date : String ;
}

export class Stat {
    categorie: string;
    service: string;
    nbreplat: number;
    caplat: number;
    debut: string;
    payment: string;
    livraison: string;
    fin: string;
    itmes: Itme[];
    ca : Caitem[];
    provenance : String ;
    qte : String ;
    date : String ;

}

export class statperiodresquest {
    debut: any;
    fin: any;
}


export class Caitem{
    caplat : any ;
    categorie : any ;
}