import { Component, OnInit } from '@angular/core';
import { MatSnackBar, MatDialog, MatDialogRef } from '@angular/material';
import { ConfigService } from 'ngx-envconfig';
import { NGXLogger } from 'ngx-logger';
import { Subscription } from 'rxjs/internal/Subscription';
import { ConfirmationService } from '../confirm/confirmation.service';
import { Agentstorage } from '../models/agentstorage';
import { Typecarte } from '../models/heurecommande';
import { Rolefonction } from '../models/rolefonction';
import { CrudComponent } from './crud/crud.component';
import { TypecarteService } from './typecarte.service';

@Component({
  selector: 'fury-typecarte',
  templateUrl: './typecarte.component.html',
  styleUrls: ['./typecarte.component.scss']
})
export class TypecarteComponent implements OnInit {



  getItemSub: Subscription;
  items: Typecarte[];
  scrollBarHorizontal = (window.innerWidth < 1200);
  temp = [];
  rows = [];
  columns = [];


  public afficherTypecarte: boolean;
  public creationTypecarte: boolean;
  public modificationTypecarte: boolean;
  public suppressionTypecarte: boolean;

  public keyAfficherTypecarte= 'afficher type carte';
  public keyCreationTypecarte = 'ajouter type carte';
  public keyModificationTypecarte = 'modifier type carte';
  public keySuppressionTypecarte = 'supprimer type carte';

  constructor(private logger: NGXLogger, private typecarteService: TypecarteService,
    private snack: MatSnackBar, private confirmService: ConfirmationService,
    private dialog: MatDialog,
    private configService: ConfigService) { }


  ngOnInit() {
    this.verifierDroit();
    this.getall();
  }




  openPopUp(data: any = {}, isNew?) {
    let title = isNew ? 'Ajouter un Type carte' : 'Mettre à jour un Type carte';
    let dialogRef: MatDialogRef<any> = this.dialog.open(CrudComponent, {
      width: '720px',
      disableClose: false,
      data: { title: title, payload: data }
    })
    dialogRef.beforeClose()
      .subscribe(res => {
        if (!res) {
          // If user press cancel
          return;
        }

        if (isNew) {
          this.postType(res,'typecarte');
        }else{
          this.putType(res,'typecarte');
        }

      })
  }


  public verifierDroit() {
    this.logger.trace('BEGIN');
    // this.logger.trace('Clé', this.configService.get('keylocalstorage'));

    let agentOnline: Agentstorage;
    agentOnline = JSON.parse(localStorage.getItem(this.configService.get('keylocalstorage')));
    // this.logger.trace(agentOnline);
    let rolefontion: Rolefonction[] = agentOnline.rolefonction;
    // this.logger.trace('rolefontion', rolefontion);

    for (const key in rolefontion) {
      if (rolefontion.hasOwnProperty(key)) {
        // this.logger.trace('KEY', key);
        const element = rolefontion[key].fonction.fonctionname;
        this.logger.trace('element', element);

        if (element == this.keyAfficherTypecarte) {
          this.logger.trace('true');
          this.afficherTypecarte = true;
        }
        if (element == this.keyCreationTypecarte) {
          this.logger.trace('true');
          this.creationTypecarte = true;
        }
        if (element == this.keyModificationTypecarte) {
          this.logger.trace('true');
          this.modificationTypecarte = true;
        }
        if (element == this.keySuppressionTypecarte) {
          this.logger.trace('true');
          this.suppressionTypecarte = true;
        }
      }
    }

  }

  postType(value: any, url1: string) {
    this.typecarteService.post(value, url1)
      .subscribe(data => {
        let statuscode = data.status;

        switch (statuscode) {
          case 201: {
            this.getall();
            this.snack.open('Succes !', 'OK', { duration: 4000 })
            break;
          }

        }

      },
        error => {
          let statuscode = error.status;
          switch (statuscode) {

           
            case 409: {
              this.snack.open('Ce type carte existe deja', 'OK', { duration: 4000 })
              break;
            }

            default: {
              this.snack.open('Une erreur est survenue!', 'OK', { duration: 4000 })
              break;
            }
          }

        });
  }


  putType(value: any, url1: string) {
    this.typecarteService.put(value, url1)
      .subscribe(data => {
        let statuscode = data.status;
        switch (statuscode) {
          case 201: {
            this.getall();
            this.snack.open('Succes !', 'OK', { duration: 4000 })
            break;
          }
        }
      },
        error => {
          let statuscode = error.status;
          switch (statuscode) {

            case 409: {
              this.snack.open('Ce type carte existe deja', 'OK', { duration: 4000 })
              break;
            }

            default: {
              this.snack.open('Une erreur est survenue!', 'OK', { duration: 4000 })
              break;
            }
          }

        });
  }


  deleteItem(row) {
    this.confirmService.confirm({ message: `Suppression ${row.decouvertlibelle}?` })
      .subscribe(res => {
        if (res) {
          this.typecarteService.delete( "typecarte/"+row.typecarteid)
            .subscribe(data => {
              let statuscode = data.status;
              switch (statuscode) {
                case 200: {
                  // Actualisé les données
                  this.getall();
                  this.snack.open('Type carte Supprimé!', 'OK', { duration: 4000 })
                  break;
                }
             
                default: {
                  this.snack.open('Une erreur est survenue!', 'OK', { duration: 4000 })
                  break;
                }
              }

            },
              error => {
                let statuscode = error.status;
                switch (statuscode) {
                
                  default: {
                    this.snack.open('Une erreur est survenue!', 'OK', { duration: 4000 })
                    break;
                  }
                }

              });
        }

      })


  }

  getall() {
    this.getItemSub = this.typecarteService.getAll()
      .subscribe(data => {

        let statuscode = data.status;
        this.logger.trace('DATA', data);

        switch (statuscode) {

          case 200: {
            this.items = data.body;
            this.temp = data.body;
            break;
          }

          case 204: {
            this.snack.open('Aucun type carte disponible', 'OK', { duration: 4000 })
            break;
          }

          default: {
            this.snack.open('Une erreur est survenue!', 'OK', { duration: 4000 })

            break;
          }

        }
      },
        error => {
          this.logger.error("error");
          this.logger.error(error);

          switch (error.status) {

            default: {
              this.snack.open('Une erreur est survenue!', 'OK', { duration: 4000 })

              break;
            }
          }
        });
  }

}
