import { Component, Inject, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { NGXLogger } from 'ngx-logger';
import { DeliveryService } from 'src/app/services/delivery.service';
import { ConfigService } from 'ngx-envconfig';

@Component({
  selector: 'fury-newlivreur',
  templateUrl: './newlivreur.component.html',
  styleUrls: ['./newlivreur.component.scss']
})
export class NewlivreurComponent implements OnInit {


  selectedFiles: FileList;
  currentFileUpload: File;



  selectedFiles_cni: FileList;
  currentFileUpload_cni: File;



  livraisons: any[] = [];



  civilite: any[] = [
    "M",
    "Mme",
    "Mlle"
  ];


  motorise: any[] = [
    "OUI",
    "NON"
  ];


  permis: any[] = [
    { "libele": "OUI", "value": true },
    { "libele": "NON", "value": false }
  ];

  form: FormGroup;

  constructor(@Inject(MAT_DIALOG_DATA) public defaults: any,
    public dialogRef: MatDialogRef<NewlivreurComponent>,
    private fb: FormBuilder, private deliveryService: DeliveryService,private configService: ConfigService,
    private logger: NGXLogger) {
  }



  /*
  "livreurcivilite": "M",
  "livreurdatenaissance": "2022-04-10",
  "livreuremail": "zagba@delivery.com",
  "livreurtelephone": "0779786011",
  "livreurexperiencepro": "3 ans",
  "livreurfirstname": "zagba",
  "livreurlastname": "requin",
  "livreurismotorise": "OUI",
  "livreurispartenaire": true,
  "livreurlieuresidence": "bagdad",
  "livreurniveauetude": "bac",
  "livreurnumcni": "cno5899",
  "livreurpermisconduire": true,
  "livreurzonetravailid": 115
  "livreurnewsletter": true,
  "livreurpassword": "edan2022",
  "livreurprovenance": "mobile",
  "livreurtypeid": "426fd6b5-3ed8-476e-811f-6c0baa0547fb",
  */

  ngOnInit() {
    this.form = this.fb.group({
      livreurcivilite: ['', Validators.required],
      livreuremail: ['', Validators.required],
      livreurtelephone: ['', Validators.required],
      livreurfirstname: ['', Validators.required],
      livreurlastname: ['', Validators.required],
      livreurexperiencepro: ['', Validators.required],
      livreurismotorise: ['', Validators.required],
      livreurispartenaire: [false, Validators.required],
      livreurlieuresidence: ['', Validators.required],
      livreurdatenaissance: ['', Validators.required],
      livreurniveauetude: ['', Validators.required],
      livreurnumcni: ['', Validators.required],
      livreurpermisconduire: ['', Validators.required],
      livreurpassword: ['', Validators.required],
      livreurzonetravailid: ['', Validators.required],
      livreurtypeid: [this.configService.get('livreur.interne') || '', Validators.required],
      livreurprovenance: ['BACKOFFICE', Validators.required]
    });

    this.form.get('livreurtelephone').valueChanges.subscribe(data => {
      this.phoneNumberLength(data);
    });

    this.findPoint();

  }

  selectFile(event) {
    this.selectedFiles = event.target.files;
  }

  selectFileCni(event) {
    this.selectedFiles_cni = event.target.files;
  }

  phoneNumberLength(data) {
    let regexpNumber = new RegExp("^[+0-9]{0,10}$");
    let keyValue = data || '';
    if (!regexpNumber.test(keyValue)) {
      // set the new value to the formControl
      this.form.patchValue({
        livreurtelephone: keyValue.slice(0, keyValue.length - 1)
      }, {
        emitEvent: false // it will make sure the valuechange won't trigger when applying new value to formcontrol.(line number 30 won't execute)
      });
      // this.keyValue = this.keyValue.slice(0, this.keyValue.length - 1);
      console.log(keyValue);
    }
  }


  findPoint() {

    this.deliveryService.get("parameter/pointlivraison/findall").subscribe(data => {
      switch (data.status) {
        case 200: {
          // this.loading.dismiss('messagesService.loadMessagesOverview');

          this.livraisons = data.body;

          break;
        }
      }
    },
      error => {
        console.log("erreur retour");
        // this.loading.dismiss('messagesService.loadMessagesOverview');
        switch (error.status) {
         
          default: {
            // this.FormReg(" erreur de connexion !!! Veuillez ressayer");
            break;
          }
        }
      }
    );

  }


  submit() {
    console.log(this.form.value);
    this.currentFileUpload = this.selectedFiles.item(0);
    this.currentFileUpload_cni = this.selectedFiles_cni.item(0);

    this.form.addControl('pc', new FormControl(this.currentFileUpload, Validators.required));
    this.form.addControl('cni', new FormControl(this.currentFileUpload_cni, Validators.required));

    console.log(this.form.value);
    this.dialogRef.close(this.form.value);
    this.form.disabled;
  }



}
