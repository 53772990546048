import { ConfigService } from 'ngx-envconfig';
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { NGXLogger } from 'ngx-logger';
import { Agentstorage } from 'src/app/models/agentstorage';
import { Role } from 'src/app/models/Role';

@Injectable({
  providedIn: 'root'
})
export class RoleService {

  private urlRole = this.configService.get('ipMS') + 'parameter/role';


  authorization: string;


  constructor(private http: HttpClient, private configService: ConfigService,
    private logger: NGXLogger) {
    let user: Agentstorage = new Agentstorage();
    user = JSON.parse(localStorage.getItem('curentuser'));

    if (user != null) {
      this.authorization = user.token;
    }
  }


  public updateRole(value: Role): Observable<HttpResponse<any>> {
    console.log("url : " + this.urlRole);
    this.logger.trace("BODY", value);

    return this.http.put(this.urlRole, JSON.stringify(value), {
      observe: 'response',
      headers: new HttpHeaders({ 'Access-Control-Allow-Origin': '*' })
        .set("content-Type", "application/json").set("Authorization", this.authorization)
    });
  }


  public AddRole(value: Role): Observable<HttpResponse<any>> {
    console.log("url : " + this.urlRole);
    console.log(JSON.stringify(value));
    return this.http.post(this.urlRole, JSON.stringify(value), {
      observe: 'response',
      headers: new HttpHeaders({ 'Access-Control-Allow-Origin': '*' })
        .set("content-Type", "application/json")
        .set("Authorization", this.authorization)
    });
  }




  DeleteRole(id: string): Observable<HttpResponse<any>> {

    return this.http.delete(this.urlRole + "/" + id,
      {
        observe: 'response',
        headers: new HttpHeaders({ 'Access-Control-Allow-Origin': '*' })
          .set("content-Type", "application/json")
           .set("Authorization", this.authorization)
      });
  }


  getRole(): Observable<HttpResponse<any>> {
    return this.http.get(this.urlRole,
      {
        observe: 'response',
        headers: new HttpHeaders({ 'Access-Control-Allow-Origin': '*' })
          .set("content-Type", "application/json")
          .set("Authorization", this.authorization)
      });
  }



}

