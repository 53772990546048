import { Component, Inject, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { NGXLogger } from 'ngx-logger';
import { Subscription } from 'rxjs';
import { ClientCorporate } from 'src/app/models/Clienttype';
import { Abattement } from 'src/app/models/pointvente';
import { AbbatementService } from 'src/app/pages/abbatement/abbatement.service';

@Component({
  selector: 'fury-add-step-formule',
  templateUrl: './add-step-formule.component.html',
  styleUrls: ['./add-step-formule.component.scss']
})
export class AddStepFormuleComponent implements OnInit {


  client: ClientCorporate[] = [];
  categoriecorp: any[] = [];


  form: FormGroup;
  constructor(@Inject(MAT_DIALOG_DATA) public defaults: any,
    public dialogRef: MatDialogRef<AddStepFormuleComponent>,
    private fb: FormBuilder,
    private abbatementService: AbbatementService,
    private logger: NGXLogger) {
  }

  getItemSub: Subscription;
  items: Abattement[];
  scrollBarHorizontal = (window.innerWidth < 1200);
  temp = [];
  rows = [];
  columns = [];

  public abattements: string[] = [];


  public typeformule: any[] = [
    {
      "libele": "MENSUEL",
      "duree": 30
    },
    {
      "libele": "TRIMESTRIEL",
      "duree": 90
    },
    {
      "libele": "ANNUEL",
      "duree": 360
    }
  ];



  ngOnInit() {
    this.getall();
    this.getAllCorporate();

    this.form = this.fb.group({
      categorie: ['', Validators.compose([Validators.required])],
      corporate: ['', Validators.compose([Validators.required])],
      formuledescription: ['', Validators.compose([Validators.required])],
      formuleduree: [''],
      formuleisprecompte: ['', Validators.compose([Validators.required])],
      // formulelibelle: ['', Validators.compose([Validators.required, Validators.minLength(3)])],
      formulemontant: ['', Validators.compose([Validators.required])],
      formuletype: ['', Validators.compose([Validators.required])],

      //formulemontantdecouvert: [''],
      formulenom: ['', Validators.compose([Validators.required, Validators.minLength(3)])]
      //userpisteaudit: ['', Validators.compose([Validators.required])],
    });

    this.form.get('corporate').valueChanges.subscribe(data => {
      let corporate : any = data ;
      this.getallCorporate(corporate.clienttypeid);
    });

  
  }

  submit() {
    let request: any = this.form.value;
    request.formulelibelle = request.formulenom;
    request.lsAbattement = this.abattements;
    let categorie : any = request.categorie ;
    let corporate : any = request.corporate ;

    request.categorie = categorie.categoriecorpolibelle;
    request.categorieid = categorie.categoriecorpoid;
    request.corporateid = corporate.clienttypeid;
    request.corporate = corporate.clienttyperaisonsocial;

    let formuletype : any = request.formuletype ;
    request.formuleduree = formuletype.duree ;

    this.logger.trace(request);
    this.dialogRef.close(request);
  }



  onChangeAbtm(event) {
    let result: any = event;
    if (result.source.selected) {
      this.abattements.push(event.source.value.abtmntid);
    } else {
      this.abattements = this.abattements.filter(item => item != event.source.value.abtmntid);
    }
  }



  getAllCorporate() {
    this.getItemSub = this.abbatementService.getAll('parameter/clienttype')
      .subscribe(data => {
        const statuscode = data.status;
        this.logger.trace('DATA', data);
        switch (statuscode) {
          case 200: {
            this.client = data.body;

            // this.temp = data.body;
            break;
          }

          case 204: {
            // this.snack.open('Aucune TVA disponible', 'OK', { duration: 4000 })
            break;
          }

          default: {
            // this.snack.open('Une erreur est survenue!', 'OK', { duration: 4000 })

            break;
          }

        }
      },
        error => {
          this.logger.error('error');
          this.logger.error(error);

          switch (error.status) {

            default: {

              //    this.snack.open('Une erreur est survenue!', 'OK', { duration: 4000 })

              break;
            }
          }
        });
  }



  getall() {
    this.getItemSub = this.abbatementService.getAll('parameter/abtmnt/getall')
      .subscribe(data => {

        let statuscode = data.status;
        this.logger.trace('DATA', data);

        switch (statuscode) {

          case 200: {
            this.items = data.body;
            break;
          }

          case 204: {
            break;
          }

          default: {

            break;
          }

        }
      },
        error => {
          this.logger.error("error");
          this.logger.error(error);

          switch (error.status) {

            default: {
              //     this.snack.open('Une erreur est survenue!', 'OK', { duration: 4000 })

              break;
            }
          }
        });
  }


  getallCorporate(id) {
    this.getItemSub = this.abbatementService.getAll("categoriecorporate/findcategoriecorpo/" + id)
      .subscribe(data => {

        let responseany: any = data;

        this.logger.trace(responseany);

        switch (data.status) {

          case 200: {
            this.categoriecorp = data.body;
            break;
          }

        }
      },
        error => {
          console.log("error");
          console.log(error);

          switch (error.status) {

            default: {

              break;
            }
          }
        });
  }

 
}
