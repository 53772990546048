import { Component, Inject, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef, MatSnackBar } from '@angular/material';
import { NGXLogger } from 'ngx-logger';
import { ConfirmationService } from 'src/app/confirm/confirmation.service';
import { Typecarte } from 'src/app/models/heurecommande';
import { Abattement, Pointvente } from 'src/app/models/pointvente';
import { AbbatementService } from '../abbatement.service';

@Component({
  selector: 'fury-add',
  templateUrl: './add.component.html',
  styleUrls: ['./add.component.scss']
})
export class AddComponent implements OnInit {

  public itemForm: FormGroup;
  public food : string[] =[];
  public formuleabat : string[] =[];
  public pointventes : string[] = [];


  public pointevente : string[] = [] ;
  itemstypecarte: Typecarte[];

  itemspv: Pointvente[];
  itemformule: any[];
  itemsmenu: any[];

  selectedTypecard : any ;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<AddComponent>,
    private fb: FormBuilder,
    private snack: MatSnackBar,
    private confirmService: ConfirmationService,
    private abbatementService: AbbatementService,
    private logger: NGXLogger) { }


    ngOnInit() {
      this.getalltypecarte();
      this.getallpv();
      this.getallformule();
      this.getallmenu();
      this.buildItemForm(this.data.payload);
    }

  
  buildItemForm(item) {
    let decouvert : Abattement = item ;
    this.logger.info(decouvert);
    this.itemForm = this.fb.group({
      abattementid: [decouvert.abattementid || ''],
      abattementlibelle: [decouvert.abattementlibelle || '', [Validators.required]],
      abattementdescription: [decouvert.abattementdescription || ''],
      abattementtypemontant: [decouvert.abattementtypemontant || '', [Validators.required]],
      abattementtype: [decouvert.abattementtype || '', [Validators.required]],
      abattementmontant: [decouvert.abattementmontant || '', [Validators.required, Validators.min(0)]],
      abattementtypecarte: [decouvert.abattementtype || '', [Validators.required]],
      abattementtypecartelib: [decouvert.abattementtypecartelib || '', [Validators.required]],
      abattementmontantarticle: [decouvert.abattementmontantarticle || '']
    });

    this.itemForm.get("abattementtype").valueChanges.subscribe(x => {
      if(this.itemForm.get("abattementtype").value==='panier'){
        console.log('abattementtype value changed');
        this.itemForm.controls["abattementmontantarticle"].clearValidators();
        this.itemForm.controls['abattementmontantarticle'].updateValueAndValidity()
      }else{
        this.itemForm.controls['abattementmontantarticle'].setValidators([Validators.required]);
        this.itemForm.controls['abattementmontantarticle'].updateValueAndValidity();
      }
   })

    if(decouvert){
      this.selectedTypecard=decouvert.abattementtypecartelib;
    }
    this.logger.info(this.itemForm.value);
  }


  getalltypecarte() {
     this.abbatementService.getAll('typecarte/enable')
      .subscribe(data => {

        let statuscode = data.status;
        switch (statuscode) {

          case 200: {
            this.itemstypecarte = data.body;
            break;
          }

        
          default: {

            break;
          }

        }
      },
        error => {
          this.logger.error("error");
          this.logger.error(error);

          switch (error.status) {

            default: {

              break;
            }
          }
        });
  }

  getallpv() {
    this.abbatementService.getAll('parameter/pointvente/getall')
     .subscribe(data => {
       let statuscode = data.status;
       switch (statuscode) {
         case 200: {
          this.logger.trace("point de vente");
          this.logger.trace(data.body);
           this.itemspv = data.body;
           break;
         }
         default: {
           break;
         }
       }
     },
       error => {
         this.logger.error("error");
         this.logger.error(error);
         switch (error.status) {
           default: {
             break;
           }
         }
       });
  }


  getallformule() {
    this.abbatementService.getAll('formule')
     .subscribe(data => {
       let statuscode = data.status;
       switch (statuscode) {
         case 200: {
          this.logger.trace("formule");
          this.logger.trace(data.body);
           this.itemformule = data.body;
           break;
         }
         default: {
           break;
         }

       }
     },
       error => {
         this.logger.error("error");
         this.logger.error(error);
         switch (error.status) {
           default: {
             break;
           }
         }
       });
  }


  getallmenu() {
    this.abbatementService.getAll('parameter/food')
     .subscribe(data => {
       let statuscode = data.status;
       switch (statuscode) {
         case 200: {
          this.logger.trace("food");
          this.logger.trace(data.body);
           this.itemsmenu = data.body;
           break;
         }
         default: {
           break;
         }
       }
     },
       error => {
         this.logger.error("error");
         this.logger.error(error);
         switch (error.status) {
           default: {
             break;
           }
         }
       });
  }

  onSelectTypecard(item) {
    this.itemForm.controls['abattementtypecarte'].setValue(item.typecartecode);
    this.itemForm.controls['abattementtypecartelib'].setValue(item.typecartelibelle);
  }




  selected = -1;

  /*checkbox change event*/
  onChangePv(event) {
    let result : any = event ;
    if(result.source.selected) {
      this.pointventes.push(event.source.value.pointventeid);
    } else {
      this.pointventes = this.pointventes.filter(item => item != event.source.value.pointventeid);
    }
  }

  onChangeFood(event) {
    let result : any = event ;
    if(result.source.selected) {
      this.food.push(event.source.value.foodid);
    } else {
      this.food = this.food.filter(item => item != event.source.value.foodid);
    }
  }

  onChangeFormule(event) {
    let result : any = event ;
    if(result.source.selected) {
      this.formuleabat.push(event.source.value.formuleid);
    } else {
      this.formuleabat = this.formuleabat.filter(item => item != event.source.value.formuleid);
    }
  }


  submit(){
    let response : Abattement = this.itemForm.value ;
    response.food=this.food ;
    response.formuleabat=this.formuleabat ;
    response.pointventes=this.pointventes ;
    this.logger.trace(response);
    this.dialogRef.close(response);
  }


}
