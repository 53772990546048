import { Component, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { MatDialog, MatSnackBar } from '@angular/material';
import { Router } from '@angular/router';
import { ConfigService } from 'ngx-envconfig';
import { NGXLogger } from 'ngx-logger';
import { Subscription } from 'rxjs';
import { ConfirmationService } from 'src/app/confirm/confirmation.service';
import { ApploaderService } from 'src/app/services/apploader/apploader.service';
import { DeliveryService } from 'src/app/services/delivery.service';
import { GenericserviceService } from 'src/app/services/genericservice.service';
import { GlobalconfigComponent } from '../gestionlivreur/globalconfig/globalconfig.component';
import { NewlivreurComponent } from '../gestionlivreur/newlivreur/newlivreur.component';
import { ShowlivreurComponent } from '../gestionlivreur/showlivreur/showlivreur.component';
import { ZonedeliveryComponent } from '../gestionlivreur/zonedelivery/zonedelivery.component';
import { ShowDeliveryAccountComponent } from './show-delivery-account/show-delivery-account.component';

@Component({
  selector: 'fury-gestionstart',
  templateUrl: './gestionstart.component.html',
  styleUrls: ['./gestionstart.component.scss']
})
export class GestionstartComponent implements OnInit {


  public items: any[] = [];
  temp = [];
  rows = [];
  columns = [];
  scrollBarHorizontal = (window.innerWidth < 1200);
  public getItemSub: Subscription;

  // Habilitation
  public creationLivreur: boolean;

  // expandable
  @ViewChild('myTable') table: any;
  expanded: any = {};
  timeout: any;

  modelivraison: any[] = [

    {
      "libele": "TOUS LES LIVREURS",
      "values": "all"
    },
    {
      "libele": "LIVREUR EN ATTENTE",
      "values": "waiting"
    },

    {
      "libele": "LIVREUR DISPONIBLE",
      "values": "open"
    }

  ]

  public itemForm: FormGroup;



  constructor(private logger: NGXLogger,
    private route: Router,
    private dialog: MatDialog,
    public formBuilder: FormBuilder,
    private snackbar: MatSnackBar,
    private confirmService: ConfirmationService,
    private utilisateurService: GenericserviceService,
    private deliveryService: DeliveryService,
    private loader: ApploaderService,


    private fb: FormBuilder,
    private snack: MatSnackBar,

    private configService: ConfigService) { }


  ngOnInit() {





    this.getAll();
  }


  updateFilter(event) {
    const val = event.target.value.toLowerCase();
    var columns = Object.keys(this.temp[0]);
    // Removes last "$$index" from "column"
    columns.splice(columns.length - 1);

    // console.log(columns);
    if (!columns.length)
      return;

    const rows = this.temp.filter(function (d) {
      for (let i = 0; i <= columns.length; i++) {
        let column = columns[i];
        // console.log(d[column]);
        if (d[column] && d[column].toString().toLowerCase().indexOf(val) > -1) {
          return true;
        }
      }
    });

    this.items = rows;
  }



  opendetail(value: any) {



    this.dialog.open(ShowDeliveryAccountComponent,
      {
        width: '720px',
        data: { title: '', payload: value }
      }
    ).afterClosed().subscribe((res) => {
      console.log(res);
      if (!res) {
        return;
      }
    });
  }


  getAll() {
    this.getItemSub = this.deliveryService.get("fileattente/delivery/findalltransactionstart")
      .subscribe(data => {

        switch (data.status) {

          default: {

            this.logger.trace('data', data.body);
            let response: any[] = data.body;
            this.logger.trace('response', response);

            let resp1: any[] = response;

            this.items = this.temp = resp1;
            // this.dataSource = new MatTableDataSource(this.items);

            break;
          }


        }
      },
        error => {
          console.log('error');
          console.log(error);

          switch (error.status) {

            default: {

              break;
            }
          }
        });
  }


  goToRounting(value) {
    this.route.navigateByUrl(value);
  }



}
