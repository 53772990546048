import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StatclientComponent } from './statclient.component';
import { ReactiveFormsModule } from '@angular/forms';
import { MatChipsModule } from '@angular/material';
import { MaterialModule } from 'src/@fury/shared/material-components.module';
import { BreadcrumbsModule } from 'src/@fury/shared/breadcrumbs/breadcrumbs.module';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { FuryCardModule } from 'src/@fury/shared/card/card.module';
import { FurySharedModule } from 'src/@fury/fury-shared.module';
import { QuillModule } from 'ngx-quill';
import { ScrollbarModule } from 'src/@fury/shared/scrollbar/scrollbar.module';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { StatuserComponent } from './statuser/statuser.component';
import { StatlistecommandeComponent } from './statlistecommande/statlistecommande.component';
import { StatcommandempComponent } from './statcommandemp/statcommandemp.component';
import { StatcommandlivreComponent } from './statcommandlivre/statcommandlivre.component';
import { StatcommandsurplaceComponent } from './statcommandsurplace/statcommandsurplace.component';
import { StatclientRoutingModule } from './statclient-routing.module';


@NgModule({
  declarations: [StatclientComponent, StatuserComponent, StatlistecommandeComponent, StatcommandempComponent, StatcommandlivreComponent, StatcommandsurplaceComponent],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    MatChipsModule,
    MaterialModule,
    BreadcrumbsModule,
    NgxDatatableModule,
    FuryCardModule,
    MatChipsModule,
    FurySharedModule,
    MaterialModule,
    ReactiveFormsModule,
    QuillModule,
    ScrollbarModule,
    ScrollingModule,
    StatclientRoutingModule
  ]
})
export class StatclientModule { }
