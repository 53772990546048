import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { NGXLogger } from 'ngx-logger';
import { Abnstat } from 'src/app/models/Agent';

@Component({
  selector: 'fury-soldeabn',
  templateUrl: './soldeabn.component.html',
  styleUrls: ['./soldeabn.component.scss']
})
export class SoldeabnComponent implements OnInit {

  abn_solde = {} as Abnstat;
  action: string;
  transaction: string;

  soldeForm: FormGroup;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<SoldeabnComponent>
    , private logger: NGXLogger
  ) { }

  ngOnInit() {
    this.buildItem(this.data.payload);
  }

  buildItem(value: any) {
    console.log("------Transaction solde");
    console.log(value);
    this.abn_solde = value;
    this.transaction = this.abn_solde.histabnfileref;
    if (this.abn_solde) {
      if (this.abn_solde.action === 0) {
        this.action = "CREDITER";
      }
      if (this.abn_solde.action === 1) {
        this.action = "DEBITER";
      }
      this.soldeForm = new FormGroup({
        montant: new FormControl('', [Validators.required]),
      });
    }

  }


  submit() {
    if (this.abn_solde && this.soldeForm.valid) {
      this.abn_solde.montant = this.soldeForm.get('montant').value;
    }
    console.log("------Transaction MAJ");

    console.log(this.abn_solde);
    this.dialogRef.close(this.abn_solde);
  }

}
