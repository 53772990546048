import { Pointvente } from '../../../models/pointvente';
import { Component, OnInit, Inject } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { NGXLogger } from 'ngx-logger';

@Component({
  selector: 'fury-update',
  templateUrl: './update.component.html',
  styleUrls: ['./update.component.scss']
})
export class UpdateComponent implements OnInit {

  form: FormGroup;

  verifyremise: boolean = false;
  verifyremisemontant: any = 0;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<UpdateComponent>,
    private fb: FormBuilder,
    private logger: NGXLogger) {
  }

  ngOnInit() {
    this.logger.info(this.data.payload);
    let pointvente: Pointvente = this.data.payload;
    if (pointvente) {
      this.verifyremise = pointvente.pointventeremise;

      this.form = this.fb.group({
        pointventeid: [pointvente.pointventeid, Validators.compose([Validators.required])],
        pointventename: [pointvente.pointventename, Validators.compose([Validators.required])],
        pointventeremise: new FormControl(pointvente.pointventeremise),
        pointventemontantremise: [pointvente.pointventemontantremise]
      });

      
      this.form.get('pointventeremise').valueChanges.subscribe(value => {
        this.verifyremise = this.form.get('pointventeremise').value;
        this.logger.info("PARAMETRE CHANGE ----> " + this.verifyremise);

        if (this.verifyremise) {
          this.form.controls['pointventemontantremise'].setValidators([Validators.required])
        } else {
          this.form.patchValue({ pointventemontantremise: 0 });
        }
      })
      
    }

  }



  submit() {
    //    this.form.
    this.dialogRef.close(this.form.value);
  }

}
