import { HttpClient, HttpEvent, HttpHeaders, HttpRequest, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ConfigService } from 'ngx-envconfig';
import { NGXLogger } from 'ngx-logger';
import { Observable } from 'rxjs';
import { Agentstorage } from '../models/agentstorage';

@Injectable({
  providedIn: 'root'
})
export class DeliveryService {

  authorization: string = '';
  private url = this.configService.get('ipMS') ;


  constructor(private http: HttpClient,
    private logger: NGXLogger,
    private configService: ConfigService
  ) {
    let u = new Agentstorage();
    u = JSON.parse(localStorage.getItem('curentuser'));

    if (u != null) {
      this.authorization = u.token;
    }
  }

  get(path): Observable<HttpResponse<any>> {
    return this.http.get(this.url+""+path,
      {
        observe: 'response',
        headers: new HttpHeaders({ 'Access-Control-Allow-Origin': '*' })
          .set("content-Type", "application/json")
          .set("Authorization", this.authorization)
      });
  }


  public post(value: any , path : any): Observable<HttpResponse<any>> {
    console.log(JSON.stringify(value));
    return this.http.post(this.url+""+path, JSON.stringify(value), {
      observe: 'response',
      headers: new HttpHeaders({ 'Access-Control-Allow-Origin': '*' })
        .set("content-Type", "application/json")
        .set("Authorization", this.authorization)
    });
  }


  sendFileToStorage(file: File, filename: string , file1: File, filename1: string , path : string): Observable<HttpEvent<{}>> {
    const formdata: FormData = new FormData();


    formdata.append(filename, file);
    formdata.append(filename1, file1);

    console.log(formdata);
       const req = new HttpRequest('POST', this.url+""+path , formdata, {
      reportProgress: true,
      responseType: 'text'

    });
    return this.http.request(req);
  }


}
