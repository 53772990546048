import { Component, OnInit } from '@angular/core';
import { MatDialog, MatSnackBar, MatDialogRef } from '@angular/material';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DatePipe } from '@angular/common';
import { ConfirmationService } from 'src/app/confirm/confirmation.service';
import { ApploaderService } from 'src/app/services/apploader/apploader.service';
import { NgxPermissionsService } from 'ngx-permissions';
import { NGXLogger } from 'ngx-logger';
import { ConfigService } from 'ngx-envconfig';
import { JournalcaisseService } from './journalcaisse.service';
import { Agent, Caisse } from 'src/app/models/Agent';
import { GenericserviceService } from 'src/app/services/genericservice.service';
import { Chiffreaffairedto } from 'src/app/models/chiffreaffairedto';
import { Subscription } from 'rxjs/internal/Subscription';

import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
const EXCEL_EXTENSION = '.xlsx';
import * as jsPDF from 'jspdf';
import 'jspdf-autotable';
import { UserOptions } from 'jspdf-autotable';
import { ShowComponent } from './show/show.component';
import { StatresponseDto } from 'src/app/models/StatResponse';
import { element } from '@angular/core/src/render3';

interface jsPDFWithPlugin extends jsPDF {
  autoTable: (options: UserOptions) => jsPDF;
}

@Component({
  selector: 'fury-journalcaisse',
  templateUrl: './journalcaisse.component.html',
  styleUrls: ['./journalcaisse.component.scss']
})
export class JournalcaisseComponent implements OnInit {



  cumul: number = 0;
  ca: number = 0;

  public items =[];
  temp = [];
  rows = [];
  columns = [];
  scrollBarHorizontal = (window.innerWidth < 1200);
  public getItemSub: Subscription;

  agents: Caisse[];
  caisse: Caisse;
  public itemForm: FormGroup;

  cas: Chiffreaffairedto[];

  constructor(private dialog: MatDialog, public formBuilder: FormBuilder,
    private snackbar: MatSnackBar, private caisseServ: JournalcaisseService,
    private datePipe: DatePipe, private agentService: GenericserviceService,
    private confirmService: ConfirmationService, private loader: ApploaderService,
    private permissionsService: NgxPermissionsService, private logger: NGXLogger,

    private configService: ConfigService, private fb: FormBuilder) { }

  ngOnInit() {
   
    this.findAll();
    //let finalDate =  this.datePipe.transform(new Date(),"yyyy-MM-dd");
    this.itemForm = this.fb.group({
      to: [  , Validators.compose([Validators.required])],
      from: [ , Validators.compose([Validators.required])],
      caisseid: ['', Validators.compose([Validators.required])]
    });

  }


  submit() {
    let request: any = this.itemForm.value;

    if (this.itemForm.valid) {
      this.logger.info("step 0");

      console.log("*******************");
      console.log(request);
      this.caisse = request;
      console.log("*******************");
      var dateFinal ;
      var dateFinal1 ;

        let date = new Date(request.to);
        let date1 = new Date(request.from);

        if ((date.toString() != 'Invalid Date')  && (date1.toString() != 'Invalid Date')) {
          let from = request.from;
          let newDate = new Date(from);
          dateFinal = this.datePipe.transform(newDate, "yyyy-MM-dd");
          request.from = dateFinal ;

          let to = request.to;
          let newDate1 = new Date(to);
          dateFinal1 = this.datePipe.transform(newDate1, "yyyy-MM-dd");
          request.to = dateFinal1 ;
        }else{
          return ;
        }
   

      this.getItemSub = this.caisseServ.getAllJounalCaisse(request.caisseid, request.from+"/"+request.to)
        .subscribe(data => {

          switch (data.status) {
            default: {

              this.logger.trace('data', data.body);
              let response: any = data.body;
            //  let resp1: Chiffreaffairedto[] = response;

              if (response) {
                let resp1 = response ;

                this.items = this.temp = [];
                this.items=resp1;
                //this.items = this.items.reverse();

              } else {
                this.items = [];
              }

            //  this.cumulAndChiffreAffaire();


              break;
            }


          }
        },
          error => {
            console.log('error');
            console.log(error);

            switch (error.status) {

              default: {

                break;
              }
            }
          });

    }else {
      this.logger.info("step 1");
      if( this.itemForm.get("caisseid").value && this.itemForm.get("to").value==null && this.itemForm.get("to").value==null ){
        let request: any = this.itemForm.value;
        let finalDate =  this.datePipe.transform(new Date(),"yyyy-MM-dd");
        this.getItemSub = this.caisseServ.getAllJounalCaisse(request.caisseid, finalDate)
        .subscribe(data => {

          switch (data.status) {
            default: {

              this.logger.trace('data', data.body);
              let response: any = data.body;
            //  let resp1: Chiffreaffairedto[] = response;

              if (response) {
                let resp1 = response ;
                this.items = this.temp = [];
                this.items.push(resp1);
                //this.items = this.items.reverse();
                this.snackbar.open('Statistique de la journée en cours ', 'OK', { duration: 4000 });

              } else {
                this.items = [];
                this.snackbar.open('Aucune Statistique de la journée en cours ', 'OK', { duration: 4000 });

              }


            //  this.cumulAndChiffreAffaire();


              break;
            }


          }
        },
          error => {
            console.log('error');
            console.log(error);

            switch (error.status) {

              default: {

                break;
              }
            }
          });
      }else{
        this.snackbar.open('Veuillez selectionner une caisse ', 'OK', { duration: 4000 });

      }
    }

  }


  submitForDetail(value: Chiffreaffairedto) {
    let request: any = {};
    request.caisseid = this.itemForm.value.caisseid;
    request.histoactivitedate = value.date;
    this.caisseServ.getInfoCaisse(request)
      .subscribe(data => {
        let statuscode = data.status;

        switch (statuscode) {
          default: {
            console.log("........................");
            console.log(data.body);
            console.log("........................");
            let response: any = data.body;
            if (response) {
              let dialogRef: MatDialogRef<any> = this.dialog.open(ShowComponent, {
                width: '720px',
                disableClose: false,
                data: { payload: response }
              })
              dialogRef.beforeClose()
                .subscribe(res => {
                  if (!res) {
                    // If user press cancel
                    return;
                  }
                })
            } else {
              this.snackbar.open('Aucun detail trouvé ', 'OK', {
                duration: 10000
              });
            }
            break;
          }

        }

      },
        error => {
          let statuscode = error.status;
          this.logger.error('error', error);

          switch (statuscode) {

            default: {
              break;
            }
          }
        });
  }





  findAll() {
    this.caisseServ.findAll().subscribe(data => {
      console.log(data);
      switch (data.status) {
        default: {

          console.log("+++++++++++++++++++++");
          console.log(data.body);
          console.log("+++++++++++++++++++++");

          let resp: Caisse[] = data.body;

          if (resp != null) {
            this.agents = resp;
          } else {
            this.agents = [];
          }
          break;
        }
      }
    },
      error => {
        console.log("erreur retour");
        // this.loading.dismiss('messagesService.loadMessagesOverview');
        switch (error.status) {

          default: {
            break;
          }
        }
      }
    );
  }


  cumulAndChiffreAffaire() {
    this.cumul = 0;
    this.ca = 0;
    for (const item of this.items) {
      this.cumul = this.cumul + item.nombrecommande;
      this.ca = this.ca + item.totalcommande;
    }
  }


  exportToPdf() {
    if (this.items.length > 0) {
      let itemspdf: any[] = [];

      this.items.forEach(function (element) {
        console.log(element);
      

     
      

        var my_json = {
          "Date début": element.heuredebut,
          "Nombre commande": element.nombrecommande,
          "Fond de départ": element.fonddepart,
          "Fond de fin": element.fondfin,
          "Total Comande": element.totalcommande
        };

        console.log("my_json");
        itemspdf.push(my_json);
      });

      var columns = [
        { title: "Date début", dataKey: "Date début" },
        { title: "Nombre commande", dataKey: "Nombre commande" },
        { title: "Fond de départ", dataKey: "Fond de départ" },
        { title: "Fond de fin", dataKey: "Fond de fin" },
        { title: "Total Comande", dataKey: "Total Comande" }
      ];
      var rows = itemspdf;

      const doc = new jsPDF('portrait', 'px', 'a4') as jsPDFWithPlugin;
      doc.autoTable({
        body: rows,
        columns: columns,
        didParseCell: function (data) {
          if (data.column.raw.displayProperty) {
            var prop = data.column.raw.displayProperty;
            var text = data.cell.raw[prop];
            if (text && text.length > 0) {
              data.cell.text = text;
            }
          }
        }
      });
      doc.save('journal-service.pdf');

    }
  }


  exportToXls() {
    let itemscsv: any[] = [];
    let caisse: Caisse = this.caisse;


    if (this.items.length > 0) {
      this.items.forEach(function (element) {
        console.log(element.heuredebut);
        console.log(element);

        if (element.heuredebut && (element.heuredebut.length > 17)) {
          element.heuredebut = element.heuredebut.substr(0, 16);
        }else{
          element.heuredebut ="" ;
        }

        var my_json = {
          "Date et Heure de début": element.heuredebut,
          "Nombre commande": element.nombrecommande,
          "Fond de départ": element.fonddepart,
          "Fond de fin": element.fondfin,
          "Total Comande": element.totalcommande
        };

        console.log("my_json");
        console.log(my_json);
        itemscsv.push(my_json);
      });
      console.log(itemscsv);

      this.exportAsExcelFile(itemscsv, 'Journal de caisse');

    }
  }



  exportToSend() {
    let itemscsv: any[] = [];
    let caisse: Caisse = this.caisse;

    if (this.items.length > 0) {
      this.items.forEach(function (element) {
        console.log(element);

        var my_json = {
          "Date": element.date,
          //"Caisse": caisse.caissename,
          "Total Comande": element.totalcommande,
          "Nombre commande": element.nombrecommande
          /*
          ,
          "Annulation  ": element.nombreannulation,
          "Carte abonnement ": element.totalcarte,
          "Mobile money ": element.totalmobilemoney,
          "Espece ": element.totalespece
          */
        };
        console.log("my_json");
        console.log(my_json);
        itemscsv.push(my_json);
      });
      console.log(itemscsv);
      this.exportAsExcelFileLocal(itemscsv, 'Journal de caisse');

    }
  }


  public exportAsExcelFile(json: any[], excelFileName: string): void {
    const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(json);
    const workbook: XLSX.WorkBook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
    const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
    this.saveAsExcelFile(excelBuffer, excelFileName);
  }

  private saveAsExcelFile(buffer: any, fileName: string): void {
    const data: Blob = new Blob([buffer], { type: EXCEL_TYPE });
    FileSaver.saveAs(data, fileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION);
  }

  public exportAsExcelFileLocal(json: any[], excelFileName: string): void {
    const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(json);
    const workbook: XLSX.WorkBook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
    const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
    this.saveAsExcelFileToLocal(excelBuffer, excelFileName);
  }

  private saveAsExcelFileToLocal(buffer: any, fileName: string): void {
    const data: Blob = new Blob([buffer], { type: EXCEL_TYPE });
    //let final_file: FileSaver = FileSaver.saveAs(data, fileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION);
    var file = new File([data], fileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION);

    console.log("-----------------FICHIER__FINAL---------------");
    console.log(file);
    console.log("-----------------------")

    this.caisseServ.sendFileToStorageDistant(file).subscribe(data => {

      this.snackbar.open('Succes !! mail envoyé avec succes', 'OK', {
        duration: 10000
      });

    },
      error => {

        this.loader.close();

        this.snackbar.open('Echec !! Une erreur est survenue lors du chargement du fichier', 'OK', {
          duration: 10000
        });


      }
    );


  }


}
