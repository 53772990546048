import { Component, Inject, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { NGXLogger } from 'ngx-logger';
import { Subscription } from 'rxjs';
import { FormuleAbonnement, InitDataFormuleAbat } from 'src/app/models/formule-abonnement';
import { Abattement } from 'src/app/models/pointvente';
import { AbbatementService } from '../../abbatement/abbatement.service';

@Component({
  selector: 'fury-updateformule',
  templateUrl: './updateformule.component.html',
  styleUrls: ['./updateformule.component.scss']
})
export class UpdateFormuleComponent implements OnInit {
  form: FormGroup;
  abattements : string[] = [];
  myselectedAbts : string[] = [];

  getItemSub: Subscription;
  items: Abattement[];
  scrollBarHorizontal = (window.innerWidth < 1200);
  temp = [];
  rows = [];
  columns = [];
  selected : any ;



  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<UpdateFormuleComponent>,
    private fb: FormBuilder,
    private abbatementService: AbbatementService,
    private logger: NGXLogger) {
  }


  

  ngOnInit() {
    this.getall();
    const initData: InitDataFormuleAbat = this.data.payload;
    console.log(initData.formule.formuleisprecompte===false);


    if(initData.formule.formuleisprecompte===true){
      this.selected="true";
    }else{
      this.selected="false";
    }

    let formulemontant ;

    if(!initData.formule.formulemontant){
      formulemontant=0;
    }else{
      formulemontant=initData.formule.formulemontant;
    }

    let formuleduree ;

    if(!initData.formule.formuleduree){
      formuleduree=0;
    }else{
      formuleduree=initData.formule.formuleduree;
    }

    if (initData.formule) {
      this.form = this.fb.group({
        formuleid: [initData.formule.formuleid],
        formuledescription: [initData.formule.formuledescription, Validators.compose([Validators.required])],
        formuleduree: [formuleduree],
        formuleisprecompte: [ this.selected , Validators.compose([Validators.required])],
        formulelibelle: [initData.formule.formulelibelle, Validators.compose([Validators.required, Validators.minLength(3)])],
        formulemontant: [formulemontant, Validators.compose([Validators.required])],
        formulemontantdecouvert: [initData.formule.formulemontantdecouvert],
        formulenom: [initData.formule.formulenom, Validators.compose([Validators.required, Validators.minLength(3)])],
        abts: [],

      });
      this.myselectedAbts =  initData.lsAbattement ;
      this.abattements=initData.lsAbattement ;

      /*
      if(formuleabonnement.lsAbattement && formuleabonnement.lsAbattement.length>0){
        let arrayAbattement : any[] = formuleabonnement.lsAbattement ;
        this.logger.info("----------------");
        this.logger.info(arrayAbattement);

        let arrayAbattemenSelect : any[] = [];
        this.abattements=[];
        for (const iteratorpv of arrayAbattement) {
          this.logger.trace(iteratorpv);
          if(iteratorpv!==undefined){
            arrayAbattemenSelect.push(iteratorpv);
            this.abattements.push(iteratorpv);
          }
        }
        this.myselectedAbts=arrayAbattemenSelect ;
        this.logger.info("--------!!!!!--------");
        this.logger.info( this.myselectedAbts);
      }
      */


    }

  }


  onChangeAbtm(event) {
    let result : any = event ;
    if(result.source.selected) {
      this.abattements.push(event.source.value.abattementid);
    } else {
      this.abattements = this.abattements.filter(item => item != event.source.value.abattementid);
    }
  }


  getall() {
    this.getItemSub = this.abbatementService.getAll('parameter/abattement/getallabattement')
      .subscribe(data => {

        let statuscode = data.status;
        this.logger.trace('DATA', data);

        switch (statuscode) {

          case 200: {
            this.items = data.body;
            break;
          }

          case 204: {
            break;
          }

          default: {

            break;
          }

        }
      },
        error => {
          this.logger.error("error");
          this.logger.error(error);

          switch (error.status) {

            default: {
         //     this.snack.open('Une erreur est survenue!', 'OK', { duration: 4000 })

              break;
            }
          }
        });
  }



  submit() {
    // this.form.
    let response : any = this.form.value ;


    this.myselectedAbts = this.myselectedAbts.filter(item => item !== null);
    var abFiltered = this.myselectedAbts.filter(function (el) {
      return el != null;
    });
    response.pointventes=abFiltered ;
    if(this.selected==="false"){
      response.formuleduree=0;
    }
    response.abts=response.pointventes;
    response.lsAbattement=response.pointventes;

     
    console.log("---- BEFORE SUBMIT ----------");
    console.log(response);
    this.dialogRef.close(response);
  }

}
