import { Component, OnInit } from '@angular/core';
import { CmhistoriqueService } from './cmhistorique.service';
import { Historiquecarte } from 'src/app/models/historiquecarte';
import { NGXLogger } from 'ngx-logger';
import { FormBuilder } from '@angular/forms';
import { MatSnackBar, MatDialog } from '@angular/material';
import { ConfigService } from 'ngx-envconfig';
import { DatePipe } from '@angular/common';
import { ApploaderService } from 'src/app/services/apploader/apploader.service';
import { Router } from '@angular/router';
import { ShowComponent } from './show/show.component';
import { Agentstorage } from 'src/app/models/agentstorage';
import { Rolefonction } from 'src/app/models/rolefonction';
import { MotifComponent } from './motif/motif.component';

@Component({
  selector: 'fury-cartemasterhistorique',
  templateUrl: './cartemasterhistorique.component.html',
  styleUrls: ['./cartemasterhistorique.component.scss']
})
export class CartemasterhistoriqueComponent implements OnInit {



  public items: Historiquecarte[];
  temp = [];
  scrollBarHorizontal = (window.innerWidth < 1200);


  public statut: boolean;
  public keyStatutrecharchement = 'Crediter / debiter avec motif un corporate';


  constructor(private historiqserv: CmhistoriqueService, private logger: NGXLogger, private fb: FormBuilder,
    private snackbar: MatSnackBar, private configService: ConfigService,
    private datePipe: DatePipe, private dialog: MatDialog, private loader: ApploaderService, private router: Router) { }

  ngOnInit() {
    this.verifierDroit();
    this.getAllCarte();
  }


  public verifierDroit() {
    this.logger.trace('BEGIN');
    // this.logger.trace('Clé', this.configService.get('keylocalstorage'));

    let agentOnline: Agentstorage;
    agentOnline = JSON.parse(localStorage.getItem(this.configService.get('keylocalstorage')));
    // this.logger.trace(agentOnline);
    let rolefontion: Rolefonction[] = agentOnline.rolefonction;
    // this.logger.trace('rolefontion', rolefontion);

    for (const key in rolefontion) {
      if (rolefontion.hasOwnProperty(key)) {
        // this.logger.trace('KEY', key);
        const element = rolefontion[key].fonction.fonctionname;
        this.logger.trace('element', element);

        if (element == this.keyStatutrecharchement) {
          this.logger.trace('true');
          this.statut = true;
        }

      }
    }

  }


  updateFilter(event) {
    const val = event.target.value.toLowerCase();
    var columns = Object.keys(this.temp[0]);
    // Removes last "$$index" from "column"
    columns.splice(columns.length - 1);

    // console.log(columns);
    if (!columns.length)
      return;

    const rows = this.temp.filter(function (d) {
      for (let i = 0; i <= columns.length; i++) {
        let column = columns[i];
        // console.log(d[column]);
        if (d[column] && d[column].toString().toLowerCase().indexOf(val) > -1) {
          return true;
        }
      }
    });

    this.items = rows;
  }


  showcarte(value: Historiquecarte) {
    this.dialog.open(ShowComponent, {
      //disableClose: true,
      width: '720px',
      data: { title: '', payload: value }
    }).afterClosed().subscribe((res) => {
      console.log(res);
      if (!res) {
        return;
      }
    });
  }


  addMotif(value: Historiquecarte) {
    let u = new Agentstorage();
    u = JSON.parse(localStorage.getItem('curentuser'));
    if (u != null) {
      value.historiquecarteagentemail = u.agent.agentemail;
    }
    this.dialog.open(MotifComponent, {
      //disableClose: true,
      width: '720px',
      data: { title: '', payload: value }
    }).afterClosed().subscribe((res) => {
      console.log(res);
      if (!res) {
        return;

      }

      this.loader.open();

      this.historiqserv.post(res).subscribe(data => {
        this.logger.trace(data);
        switch (data.status) {
          case 201: {
            this.getAllCarte();
            this.loader.close();
            this.snackbar.open('Succes', 'OK', {
              duration: 10000
            });
            break;
          }
        }
      },
        error => {
          this.logger.trace("erreur retour");
          // this.loading.dismiss('messagesService.loadMessagesOverview');
          switch (error.status) {

            case 304: {
              this.snackbar.open('Echec , solde insuffisant', 'OK', {
                duration: 10000
              });
              this.loader.close();
              break;
            }

            case 423: {
              this.snackbar.open('Echec , aucun corporate ou carte master trouvé', 'OK', {
                duration: 10000
              });
              this.loader.close();
              break;


            }

            case 406: {
              this.snackbar.open('Echec , aucune trasaction trouvée', 'OK', {
                duration: 10000
              });
              this.loader.close();
              break;
            }

            default: {
              this.loader.close();

              // this.FormReg(" erreur de connexion !!! Veuillez ressayer");
              this.logger.error("Check API", error);

              this.snackbar.open('Echec', 'OK', {
                duration: 10000
              });
              break;
            }
          }
        }
      );


    });
  }

  getAllCarte() {
    this.historiqserv.getAllHistoriqueCarteabonnement()
      .subscribe(data => {
        switch (data.status) {
          case 200: {
            this.logger.trace('data', data);

            this.items = this.temp = data.body;

            break;
          }

          case 204: {
            break;
          }

          default: {
            /*
                        this.logger.trace('data', data);
                        this.user = this.temp = data.body;
            */
            break;
          }


        }
      },
        error => {
          this.logger.trace('error');
          this.logger.error(error);

          switch (error.status) {

            default: {

              break;
            }
          }
        });
  }


  /*
    exportToCsv() {
      let itemscsv: any[] = [];
      let itemslocal: any[] = this.items;
      let v_array: any[];
  
      if (this.items.length > 0) {
        this.items.forEach(function (element) {
          console.log(element);
          if (element.historiquecartedatecreation.toString().length > 17) {
            element.historiquecartedatecreation = element.historiquecartedatecreation.toString().substr(0, 16);
          }
          var my_json = {
            "ID Transaction": element.historiquecartereftransaction,
            "Date Operation": element.historiquecartedatecreation,
            "Montant Operation": element.historiquecartemontant,
            "Status Operation": element.historiquecartestatutpaiement,
            "Operation": element.historiquecarteaction,
            //"Email client": element.carteabonnement.client.clientemail,
            "Numero de carte": element.carteabonnement.carteabonnementcodereference,
            //"Status" : element.statut
          };
  
          let v: String[] = [];
  
          console.log("my_json");
          console.log(my_json);
          itemscsv.push(my_json);
        });
        console.log(v_array);
  
        console.log(itemscsv);
  
        this.exportAsExcelFile(itemscsv, 'Historique des cartes');
  
      }
  
    }
  */
  /*
    public exportAsExcelFile(json: any[], excelFileName: string): void {
      const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(json);
      const workbook: XLSX.WorkBook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
      const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
      this.saveAsExcelFile(excelBuffer, excelFileName);
    }
    private saveAsExcelFile(buffer: any, fileName: string): void {
      const data: Blob = new Blob([buffer], { type: EXCEL_TYPE });
      FileSaver.saveAs(data, fileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION);
  
    }
  
    headRows() {
      return [{ id: 'ID', name: 'Name', email: 'Email', city: 'City', expenses: 'Sum' }];
    }
  
    footRows() {
      return [{ id: 'ID', name: 'Name', email: 'Email', city: 'City', expenses: 'Sum' }];
    }
  
    exportToPdf() {
  
     let itemscsv: any[] = [];
     let itemslocal: any[] = this.items;
     if (this.items.length > 0) {
       this.items.forEach(function (element) {
         console.log(element);
         if (element.historiquecartedatecreation.toString().length > 17) {
           element.historiquecartedatecreation = element.historiquecartedatecreation.toString().substr(0, 16);
         }
         var my_json = {
           "ID Transaction": element.historiquecartereftransaction,
           "Date Operation": element.historiquecartedatecreation,
           "Montant Operation": element.historiquecartemontant,
           "Status Operation": element.historiquecartestatutpaiement,
           "Operation": element.historiquecarteaction,
           //"Email client": element.carteabonnement.client.clientemail,
           "Numero de carte": element.carteabonnement.carteabonnementcodereference,
           //"Status" : element.statut
         };
  
         console.log("my_json");
         console.log(my_json);
         itemscsv.push(my_json);
       });
  
     }
  
     console.log(itemscsv);
      var columns = [
        { title: "ID Transaction", dataKey: "ID Transaction" },
        { title: "Date Operation", dataKey: "Date Operation" },
        { title: "Montant Operation", dataKey: "Montant Operation" },
        { title: "Status Operation", dataKey: "Status Operation" },
        { title: "Operation", dataKey: "Operation" },
        { title: "Numero de carte", dataKey: "Numero de carte" }
      ];
      var rows = itemscsv ;
  
      const doc = new jsPDF('portrait', 'px', 'a4') as jsPDFWithPlugin;
      doc.autoTable({
        body: rows,
        columns: columns,
        didParseCell: function (data) {
          if (data.column.raw.displayProperty) {
            var prop = data.column.raw.displayProperty;
            var text = data.cell.raw[prop];
            if (text && text.length > 0) {
              data.cell.text = text;
            }
          }
        }
      });
      doc.save('historique-carte.pdf');
    }
    */

  downloadFile(value: any) {
    var form = document.createElement("form");
    form.method = "GET";
    form.action = value.historiquecartedownloadimageurl;
    form.target = "_blank";
    document.body.appendChild(form);
    form.submit();
  }

}
