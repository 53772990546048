import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'fury-valideprecompte',
  templateUrl: './valideprecompte.component.html',
  styleUrls: ['./valideprecompte.component.scss']
})
export class ValideprecompteComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
