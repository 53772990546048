import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { GestioncuisineRoutingModule } from './gestioncuisine-routing.module';
import { GestioncuisineComponent } from './gestioncuisine.component';

@NgModule({
  declarations: [GestioncuisineComponent],
  imports: [
    CommonModule,
    GestioncuisineRoutingModule
  ]
})
export class GestioncuisineModule { }
