import { Component, Inject, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { NGXLogger } from 'ngx-logger';
import { DemandeabnDto } from 'src/app/models/demandeabnDto';

@Component({
  selector: 'fury-adddemande',
  templateUrl: './adddemande.component.html',
  styleUrls: ['./adddemande.component.scss']
})
export class AddDemandeComponent implements OnInit {
  row: DemandeabnDto;
  constructor(@Inject(MAT_DIALOG_DATA) public defaults: any,
    public dialogRef: MatDialogRef<AddDemandeComponent>,
    private fb: FormBuilder,
    private logger: NGXLogger) {
  }

  ngOnInit() {
    this.logger.info(this.defaults.payload);
    this.row = this.defaults.payload;
  }

  submit() {
    this.dialogRef.close(false);
  }

}
