import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MatSnackBar, MatDialog } from '@angular/material';
import { Router } from '@angular/router';
import { ConfigService } from 'ngx-envconfig';
import { NGXLogger } from 'ngx-logger';
import { Agentstorage } from 'src/app/models/agentstorage';
import { ClientCorporate } from 'src/app/models/Clienttype';
import { Historiquecarte } from 'src/app/models/historiquecarte';
import { Rolefonction } from 'src/app/models/rolefonction';
import { ApploaderService } from 'src/app/services/apploader/apploader.service';
import { CmhistoriqueService } from '../../cartemasterhistorique/cmhistorique.service';
import { MotifComponent } from '../../cartemasterhistorique/motif/motif.component';
import { ShowComponent } from '../../journalcaisse/show/show.component';

@Component({
  selector: 'fury-historiquecardmaster',
  templateUrl: './historiquecardmaster.component.html',
  styleUrls: ['./historiquecardmaster.component.scss']
})
export class HistoriquecardmasterComponent implements OnInit {

 

  public items: Historiquecarte[];
  temp = [];
  scrollBarHorizontal = (window.innerWidth < 1200);


  public statut: boolean;
  public keyStatutrecharchement = 'Crediter / debiter avec motif un corporate';


  constructor(private historiqserv: CmhistoriqueService, private logger: NGXLogger, private fb: FormBuilder,
    private snackbar: MatSnackBar, private configService: ConfigService,
    private datePipe: DatePipe, private dialog: MatDialog, private loader: ApploaderService, private router: Router) { }

  ngOnInit() {

    let corporate: ClientCorporate =  JSON.parse(localStorage.getItem('corporate_transaction'));
    if(corporate){
      this.getAllCarte(corporate.clienttypeid);
    }else{
      this.router.navigate(['/yolinbo/gestioncorporate']);
    }
    
  }

  goToHome(){
    this.router.navigate(['/yolinbo/gestioncorporate']);
  }


  refresh(){
    let corporate: ClientCorporate =  JSON.parse(localStorage.getItem('corporate_transaction'));
    if(corporate){
      this.getAllCarte(corporate.clienttypeid);
    }else{
      this.router.navigate(['/yolinbo/gestioncorporate']);
    }
  }


  updateFilter(event) {
    const val = event.target.value.toLowerCase();
    var columns = Object.keys(this.temp[0]);
    // Removes last "$$index" from "column"
    columns.splice(columns.length - 1);

    // console.log(columns);
    if (!columns.length)
      return;

    const rows = this.temp.filter(function (d) {
      for (let i = 0; i <= columns.length; i++) {
        let column = columns[i];
        // console.log(d[column]);
        if (d[column] && d[column].toString().toLowerCase().indexOf(val) > -1) {
          return true;
        }
      }
    });

    this.items = rows;
  }


  showcarte(value: Historiquecarte) {
    this.dialog.open(ShowComponent, {
      //disableClose: true,
      width: '720px',
      data: { title: '', payload: value }
    }).afterClosed().subscribe((res) => {
      console.log(res);
      if (!res) {
        return;
      }
    });
  }



  getAllCarte(id) {
    this.historiqserv.getAllHistoriqueMaster(id)
      .subscribe(data => {
        switch (data.status) {
          case 200: {
            this.logger.trace('data', data);

            this.items = this.temp = data.body;

            break;
          }

          case 204: {
            break;
          }

          default: {
            /*
                        this.logger.trace('data', data);
                        this.user = this.temp = data.body;
            */
            break;
          }


        }
      },
        error => {
          this.logger.trace('error');
          this.logger.error(error);

          switch (error.status) {

            default: {

              break;
            }
          }
        });
  }


  /*
    exportToCsv() {
      let itemscsv: any[] = [];
      let itemslocal: any[] = this.items;
      let v_array: any[];
  
      if (this.items.length > 0) {
        this.items.forEach(function (element) {
          console.log(element);
          if (element.historiquecartedatecreation.toString().length > 17) {
            element.historiquecartedatecreation = element.historiquecartedatecreation.toString().substr(0, 16);
          }
          var my_json = {
            "ID Transaction": element.historiquecartereftransaction,
            "Date Operation": element.historiquecartedatecreation,
            "Montant Operation": element.historiquecartemontant,
            "Status Operation": element.historiquecartestatutpaiement,
            "Operation": element.historiquecarteaction,
            //"Email client": element.carteabonnement.client.clientemail,
            "Numero de carte": element.carteabonnement.carteabonnementcodereference,
            //"Status" : element.statut
          };
  
          let v: String[] = [];
  
          console.log("my_json");
          console.log(my_json);
          itemscsv.push(my_json);
        });
        console.log(v_array);
  
        console.log(itemscsv);
  
        this.exportAsExcelFile(itemscsv, 'Historique des cartes');
  
      }
  
    }
  */
  /*
    public exportAsExcelFile(json: any[], excelFileName: string): void {
      const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(json);
      const workbook: XLSX.WorkBook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
      const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
      this.saveAsExcelFile(excelBuffer, excelFileName);
    }
    private saveAsExcelFile(buffer: any, fileName: string): void {
      const data: Blob = new Blob([buffer], { type: EXCEL_TYPE });
      FileSaver.saveAs(data, fileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION);
  
    }
  
    headRows() {
      return [{ id: 'ID', name: 'Name', email: 'Email', city: 'City', expenses: 'Sum' }];
    }
  
    footRows() {
      return [{ id: 'ID', name: 'Name', email: 'Email', city: 'City', expenses: 'Sum' }];
    }
  
    exportToPdf() {
  
     let itemscsv: any[] = [];
     let itemslocal: any[] = this.items;
     if (this.items.length > 0) {
       this.items.forEach(function (element) {
         console.log(element);
         if (element.historiquecartedatecreation.toString().length > 17) {
           element.historiquecartedatecreation = element.historiquecartedatecreation.toString().substr(0, 16);
         }
         var my_json = {
           "ID Transaction": element.historiquecartereftransaction,
           "Date Operation": element.historiquecartedatecreation,
           "Montant Operation": element.historiquecartemontant,
           "Status Operation": element.historiquecartestatutpaiement,
           "Operation": element.historiquecarteaction,
           //"Email client": element.carteabonnement.client.clientemail,
           "Numero de carte": element.carteabonnement.carteabonnementcodereference,
           //"Status" : element.statut
         };
  
         console.log("my_json");
         console.log(my_json);
         itemscsv.push(my_json);
       });
  
     }
  
     console.log(itemscsv);
      var columns = [
        { title: "ID Transaction", dataKey: "ID Transaction" },
        { title: "Date Operation", dataKey: "Date Operation" },
        { title: "Montant Operation", dataKey: "Montant Operation" },
        { title: "Status Operation", dataKey: "Status Operation" },
        { title: "Operation", dataKey: "Operation" },
        { title: "Numero de carte", dataKey: "Numero de carte" }
      ];
      var rows = itemscsv ;
  
      const doc = new jsPDF('portrait', 'px', 'a4') as jsPDFWithPlugin;
      doc.autoTable({
        body: rows,
        columns: columns,
        didParseCell: function (data) {
          if (data.column.raw.displayProperty) {
            var prop = data.column.raw.displayProperty;
            var text = data.cell.raw[prop];
            if (text && text.length > 0) {
              data.cell.text = text;
            }
          }
        }
      });
      doc.save('historique-carte.pdf');
    }
    */

  downloadFile(value: any) {
    var form = document.createElement("form");
    form.method = "GET";
    form.action = value.historiquecartedownloadimageurl;
    form.target = "_blank";
    document.body.appendChild(form);
    form.submit();
  }

}
