import { Component, Inject, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { NGXLogger } from 'ngx-logger';

@Component({
  selector: 'fury-addlivraison',
  templateUrl: './addlivraison.component.html',
  styleUrls: ['./addlivraison.component.scss']
})
export class AddlivraisonComponent implements OnInit {


  form: FormGroup;

  constructor(@Inject(MAT_DIALOG_DATA) public defaults: any,
    public dialogRef: MatDialogRef<AddlivraisonComponent>,
    private fb: FormBuilder,
    private logger: NGXLogger) {
  }
 
  ngOnInit() {
    this.form = this.fb.group({
      pointlivraisonzone: ['', Validators.compose([Validators.required])],
      pointlivraisonduree: ['', Validators.compose([Validators.required])]
    });
  }



  submit() {
    //    this.form.
    this.dialogRef.close(this.form.value);
  }

}
