import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { Historiquecarte } from 'src/app/models/historiquecarte';
import { NGXLogger } from 'ngx-logger';
import { FormBuilder } from '@angular/forms';
import { MatSnackBar, MatDialog } from '@angular/material';
import { ConfigService } from 'ngx-envconfig';
import { DatePipe } from '@angular/common';
import { ApploaderService } from 'src/app/services/apploader/apploader.service';
import { Router } from '@angular/router';
import { HistoriquecarteService } from './historiquecarte.service';
import { CartecreditComponent } from './cartecredit/cartecredit.component';
import { Agentstorage } from 'src/app/models/agentstorage';
import { crediterca } from 'src/app/models/carteabonnement';

import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';

const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
const EXCEL_EXTENSION = '.xlsx';

import * as jsPDF from 'jspdf';
import 'jspdf-autotable';
import { UserOptions } from 'jspdf-autotable';


interface jsPDFWithPlugin extends jsPDF {
  autoTable: (options: UserOptions) => jsPDF;
}



@Component({
  selector: 'fury-historiquecarte',
  templateUrl: './historiquecarte.component.html',
  styleUrls: ['./historiquecarte.component.scss']
})
export class HistoriquecarteComponent implements OnInit {


  public items: any[];
  temp = [];
  scrollBarHorizontal = (window.innerWidth < 1200);



  constructor(private historiqserv: HistoriquecarteService, private logger: NGXLogger, private fb: FormBuilder,
    private snackbar: MatSnackBar, private configService: ConfigService,
    private datePipe: DatePipe, private dialog: MatDialog, private loader: ApploaderService, private router: Router) { }

  ngOnInit() {
    this.getAllCarte();
  }



  updateFilter(event) {
    const val = event.target.value.toLowerCase();
    var columns = Object.keys(this.temp[0]);
    // Removes last "$$index" from "column"
    columns.splice(columns.length - 1);

    // console.log(columns);
    if (!columns.length)
      return;

    const rows = this.temp.filter(function (d) {
      for (let i = 0; i <= columns.length; i++) {
        let column = columns[i];
        // console.log(d[column]);
        if (d[column] && d[column].toString().toLowerCase().indexOf(val) > -1) {
          return true;
        }
      }
    });

    this.items = rows;
  }


  getAllCarte() {
    this.historiqserv.getAllHistoriqueCarte('historiqueclient')
      .subscribe(data => {
        switch (data.status) {
          case 200: {
            this.logger.trace('data', data);
            this.items = this.temp = data.body;
            break;
          }

          case 204: {
            this.logger.trace('data', data);
            break;
          }

          default: {
            this.logger.trace('data', data);
            /*
                        this.logger.trace('data', data);
                        this.user = this.temp = data.body;
            */
            break;
          }
        }
      },
        error => {
          this.logger.trace('error');
          this.logger.error(error);

          switch (error.status) {

            default: {

              break;
            }
          }
        });
  }



  crediterCarte() {
    this.dialog.open(CartecreditComponent, {
      //disableClose: true,
      width: '720px',
      data: { title: '', payload: '' }
    }).afterClosed().subscribe((res) => {
      console.log(res);
      if (!res) {
        return;
      }

      this.loader.open();

      let ca: crediterca = res;

      let u = new Agentstorage();
      u = JSON.parse(localStorage.getItem('curentuser'));

      if (u != null) {
        ca.carteabonnementagentid = u.agent.agentid;
      }

      this.logger.trace("carte", ca);

      this.historiqserv.crediterCarte(ca).subscribe(data => {
        this.logger.trace(data);
        switch (data.status) {
          case 201: {

            this.getAllCarte();
            this.loader.close();
            this.snackbar.open('Succes', 'OK', {
              duration: 10000
            });
            break;
          }



          case 204: {

            this.loader.close();
            this.snackbar.open('Echec ! carte non trouvé', 'OK', {
              duration: 10000
            });
            break;
          }

        }
      },
        error => {
          this.logger.trace("erreur retour");
          // this.loading.dismiss('messagesService.loadMessagesOverview');
          switch (error.status) {

            case 401: {
              this.loader.close();
              this.snackbar.open('Echec !', 'OK', {
                duration: 10000
              });
              break;
            }

            case 406: {
              this.loader.close();
              this.snackbar.open('Echec ! Agent existe pas', 'OK', {
                duration: 10000
              });
              break;
            }

            default: {
              this.loader.close();

              // this.FormReg(" erreur de connexion !!! Veuillez ressayer");
              this.logger.error("Check API", error);

              this.snackbar.open('Echec', 'OK', {
                duration: 10000
              });
              break;
            }
          }
        }
      );


    });
  }

  exportToCsv() {
    let itemscsv: any[] = [];
    let itemslocal: any[] = this.items;
    let v_array: any[];

    if (this.items.length > 0) {
      this.items.forEach(function (element) {
        console.log(element);
        if (element.historiqueclientdatecreation.toString().length > 17) {
          element.historiqueclientdatecreation = element.historiqueclientdatecreation.toString().substr(0, 16);
        }
        var my_json = {
          "ID Transaction": element.historiqueclientreftransaction,
          "Date Operation": element.historiquecartedatecreation,
          "Montant Operation": element.historiqueclientmontant,
          "Status Operation": element.historiqueclientstatutpaiement,
          "Operation": element.historiqueclienttypetransaction,
          //"Email client": element.carteabonnement.client.clientemail,
          "Numero de carte": element.historiqueclientrefcarte,
          //"Status" : element.statut
        };

        let v: String[] = [];

        console.log("my_json");
        console.log(my_json);
        itemscsv.push(my_json);
      });
      console.log(v_array);

      console.log(itemscsv);

      this.exportAsExcelFile(itemscsv, 'Historique des cartes');

    }

  }


  public exportAsExcelFile(json: any[], excelFileName: string): void {
    const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(json);
    const workbook: XLSX.WorkBook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
    const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
    this.saveAsExcelFile(excelBuffer, excelFileName);
  }
  private saveAsExcelFile(buffer: any, fileName: string): void {
    const data: Blob = new Blob([buffer], { type: EXCEL_TYPE });
    FileSaver.saveAs(data, fileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION);

  }

  headRows() {
    return [{ id: 'ID', name: 'Name', email: 'Email', city: 'City', expenses: 'Sum' }];
  }

  footRows() {
    return [{ id: 'ID', name: 'Name', email: 'Email', city: 'City', expenses: 'Sum' }];
  }

  exportToPdf() {
    /*
    const doc = new jsPDF('portrait', 'px', 'a4') as jsPDFWithPlugin;
    doc.autoTable({
     
    });
    doc.save("historiquecarte.pdf");
    */

   let itemscsv: any[] = [];
   let itemslocal: any[] = this.items;
   if (this.items.length > 0) {
     this.items.forEach(function (element) {
       console.log(element);
       if (element.historiquecartedatecreation.toString().length > 17) {
         element.historiquecartedatecreation = element.historiquecartedatecreation.toString().substr(0, 16);
       }
    

       var my_json = {
        "ID Transaction": element.historiqueclientreftransaction,
        "Date Operation": element.historiquecartedatecreation,
        "Montant Operation": element.historiqueclientmontant,
        "Status Operation": element.historiqueclientstatutpaiement,
        "Operation": element.historiqueclienttypetransaction,
        //"Email client": element.carteabonnement.client.clientemail,
        "Numero de carte": element.historiqueclientrefcarte,
        //"Status" : element.statut
      };

       console.log("my_json");
       console.log(my_json);
       itemscsv.push(my_json);
     });

   }

   console.log(itemscsv);
    var columns = [
      { title: "ID Transaction", dataKey: "ID Transaction" },
      { title: "Date Operation", dataKey: "Date Operation" },
      { title: "Montant Operation", dataKey: "Montant Operation" },
      { title: "Status Operation", dataKey: "Status Operation" },
      { title: "Operation", dataKey: "Operation" },
      { title: "Numero de carte", dataKey: "Numero de carte" }
    ];
    var rows = itemscsv ;

    const doc = new jsPDF('portrait', 'px', 'a4') as jsPDFWithPlugin;
    doc.autoTable({
      body: rows,
      columns: columns,
      didParseCell: function (data) {
        if (data.column.raw.displayProperty) {
          var prop = data.column.raw.displayProperty;
          var text = data.cell.raw[prop];
          if (text && text.length > 0) {
            data.cell.text = text;
          }
        }
      }
    });
    doc.save('historique-carte.pdf');
  }


}
