import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef, MatSnackBar } from '@angular/material';
import { NGXLogger } from 'ngx-logger';
import { ConfirmationService } from 'src/app/confirm/confirmation.service';
import { Agentstorage } from 'src/app/models/agentstorage';
import { Decouvert } from 'src/app/models/heurecommande';

@Component({
  selector: 'fury-adddecouvert',
  templateUrl: './adddecouvert.component.html',
  styleUrls: ['./adddecouvert.component.scss']
})
export class AdddecouvertComponent implements OnInit {

  public itemForm: FormGroup;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<AdddecouvertComponent>,
    private fb: FormBuilder,
    private snack: MatSnackBar,
    private confirmService: ConfirmationService,
    private logger: NGXLogger) { }

  ngOnInit() {
    this.buildItemForm(this.data.payload);
  }

  buildItemForm(item) {
    let decouvert : Decouvert = item ;
    this.logger.info(decouvert);
    this.itemForm = this.fb.group({
      decouvertid: [decouvert.decouvertid || ''],
      decouvertlibelle: [decouvert.decouvertlibelle || '', [Validators.required]],
      decouvertvaleur: [decouvert.decouvertvaleur || '', [Validators.required, Validators.min(0)]]
    });
    this.logger.info(this.itemForm.value);
  }



  submit() {
    // Verifier si l'id est null (ajout)
    this.logger.debug('Form value', this.itemForm.value);
    let res: Decouvert = this.itemForm.value;
    let u = new Agentstorage();
    u = JSON.parse(localStorage.getItem("curentuser"));
    if (u != null) {
      res.userpisteaudit = u.agent.agentemail + " " + u.agent.agentfirstname + " " + u.agent.agentlastname;
    }
    this.dialogRef.close(res);

  }

}
