import { Component, OnInit, ViewChild } from '@angular/core';

import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
const EXCEL_EXTENSION = '.xlsx';

import * as jsPDF from 'jspdf';
import 'jspdf-autotable';
import { UserOptions } from 'jspdf-autotable';
import { DatePipe } from '@angular/common';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { MatTableDataSource, MatPaginator, MatDialog, MatSnackBar } from '@angular/material';
import { ConfigService } from 'ngx-envconfig';
import { NGXLogger } from 'ngx-logger';
import { NgxPermissionsService } from 'ngx-permissions';
import { Subscription } from 'rxjs';
import { ConfirmationService } from 'src/app/confirm/confirmation.service';
import { Categories } from 'src/app/models/categories';
import { Pointvente } from 'src/app/models/pointvente';
import { Statperiod, Stat, Itme, Caitem, statperiodresquest } from 'src/app/models/statperiod';
import { ApploaderService } from 'src/app/services/apploader/apploader.service';
import { GenericserviceService } from 'src/app/services/genericservice.service';
import { MenuService } from '../gestionmenu/menu.service';
import { PointventeService } from '../gestionpointvente/pointvente.service';
import { StatperiodiqueService } from '../statperiodique/statperiodique.service';

interface jsPDFWithPlugin extends jsPDF {
  autoTable: (options: UserOptions) => jsPDF;
}

@Component({
  selector: 'fury-statforproduct',
  templateUrl: './statforproduct.component.html',
  styleUrls: ['./statforproduct.component.scss']
})
export class StatforproductComponent implements OnInit {

  globalstat: boolean = false;
  periodestat: boolean = false;
  global: Statperiod[] = [];
  periode: Statperiod;

  public getItemSub: Subscription;
  public datePaiementForm: FormGroup;
  itemCategories: Categories[] = [];
  pointvente: Pointvente[] = [];
  itemPeriode: any[];

  public statPeriodForm: FormGroup;
  public statBydateForm: FormGroup;
  periodefinal: string;
  resultatstat: Stat;
  form: FormGroup;

  caByProduct : Boolean = false;
  caByItems : Boolean = false ;

  caItems  = [] ;
  caProduct : any = {}  ;
  foodItems  = [] ;

  displayedColumns: string[] = ['date', 'food', 'pu', 'qte' , 'total'];
  dataSource = new MatTableDataSource<Itme>([]);
  @ViewChild(MatPaginator) paginator: MatPaginator;

  constructor(private dialog: MatDialog, public formBuilder: FormBuilder,
    private snackbar: MatSnackBar, private statserv: StatperiodiqueService,
    private datePipe: DatePipe, private agentService: GenericserviceService,
    private confirmService: ConfirmationService, private loader: ApploaderService,
    private permissionsService: NgxPermissionsService, private logger: NGXLogger,
    private pointventeservice: PointventeService, private menuservice: MenuService,
    private foodService : MenuService ,
    private configService: ConfigService, private fb: FormBuilder) { }



  ngOnInit() {
  
    this.statBydateForm = new FormGroup({
      categorie: new FormControl('', Validators.required),
      service: new FormControl('', Validators.required),
      debut: new FormControl('', Validators.required),
      fin: new FormControl('', Validators.required)
    });
    this.getallCategories();

    this.statBydateForm.get('categorie').valueChanges.subscribe(val => {
      this.getallFood(val);
    });

   //  this.getallFood();
  }






  getStatByPeriode() {

  this.caByProduct = false ;
  this.caByItems = false ;

  this.caProduct = {} ;
  this.caItems = [] ;

    if (this.statPeriodForm.valid) {
      this.resultatstat = new Stat();
      this.periodefinal = "";
      this.dataSource.data = [];
      this.dataSource.paginator = this.paginator;
      let request: any = this.statPeriodForm.value;

      //items.categoriecode='all-code-stat' ;
      console.log("----------");
      console.log(request);

      if(request && request.categorie!=='all-code-stat'){
        request.mode="items";
      }else{
        request.mode="all";
      }

      this.statserv.post(request, "stats/statcatservice").subscribe(data => {
        this.logger.trace('DATA', data);

        switch (data.status) {

          case 200: {

            console.log("data");
            console.log(data);
            this.resultatstat = data.body;

            if(request.mode==="all"){
              if(this.resultatstat.ca && this.resultatstat.ca.length>0){
                this.caByItems=true ;
                this.caItems = [] ;
                let caList : Caitem[] = this.resultatstat.ca ;
                let caListItem : any[] = [] ;
                caList.forEach(element => {
                  console.log(element);
                  let x : any = {};
                  x.categorie="CHIFFRE AFFAIRE  "+element.categorie+" : " ;
                  x.caplat=element.caplat ;
                  caListItem.push(x);
                });
                this.caItems=caListItem ;
              }
            }else if("items"){
              this.caByProduct=true ;
              this.caProduct.caplat = this.resultatstat.caplat ;
            }
            
            this.dataSource.data = this.resultatstat.itmes;
            this.dataSource.paginator = this.paginator;

            this.periodefinal = this.resultatstat.debut + " au " + this.resultatstat.fin;
            this.loader.close();
            this.snackbar.open(' succes ', 'OK', {
              duration: 10000
            });

            break;
          }

        }
      },
        error => {
          this.logger.error('ERREUR', error);
          switch (error.status) {


            default: {
              this.loader.close();

              this.snackbar.open('Echec !! Une erreur est survenue ', 'OK', {
                duration: 10000
              });
              break;
            }
          }
        });
    }
  }



  getStatByDate() {

    
  this.caByProduct = false ;
  this.caByItems = false ;

  this.caProduct = {} ;
  this.caItems = [] ;

    if (this.statBydateForm.valid) {

      this.periodefinal = "";
      this.resultatstat = new Stat();
      this.dataSource.data = [];
      this.dataSource.paginator = this.paginator;

      let to: string = "";
      let from: string = "";
      let date = new Date(this.statBydateForm.get("debut").value);
      let date1 = new Date(this.statBydateForm.get("fin").value);

      if ((date.toString() != 'Invalid Date')) {
        from = this.statBydateForm.get("debut").value;
      }

      if (date1.toString() != null) {
        to = this.statBydateForm.get("fin").value;
      }

      let newDate = new Date(from);

      let newDate1 = new Date(to);

      let request: any = this.statBydateForm.value;

      if ((newDate.toDateString() !== "Invalid Date") && (newDate1.toDateString() !== "Invalid Date")) {
        //;
        request.debut = this.datePipe.transform(newDate, "yyyy-MM-dd HH:mm:ss");
        request.fin = this.datePipe.transform(newDate1, "yyyy-MM-dd HH:mm:ss");

        this.logger.info(request);

        console.log("----------");
        console.log(request);
      

        this.statserv.post(request, "stats/statproductsingle").subscribe(data => {
          this.logger.trace('DATA', data);

          switch (data.status) {

            case 200: {

              console.log("data");
              console.log(data);
              this.resultatstat = data.body;

              this.caByProduct=true ;
              this.caProduct.caplat = this.resultatstat.caplat ;
              this.caProduct.nbreplat = this.resultatstat.nbreplat ;
          

              this.dataSource.data = this.resultatstat.itmes;
              this.dataSource.paginator = this.paginator;

              this.periodefinal = request.debut + " au " + request.fin;
              this.loader.close();
              this.snackbar.open(' succes ', 'OK', {
                duration: 10000
              });

              break;
            }

          }
        },
          error => {
            this.logger.error('ERREUR', error);
            switch (error.status) {


              default: {
                this.loader.close();

                this.snackbar.open('Echec !! Une erreur est survenue ', 'OK', {
                  duration: 10000
                });
                break;
              }
            }
          });


      }



    }
  }



  getallPointvente() {
    this.getItemSub = this.pointventeservice.get("pointvente/getall")
      .subscribe(data => {

        let statuscode = data.status;
        this.logger.trace('DATA', data);

        switch (statuscode) {

          case 200: {
            this.pointvente = data.body;
            //this.temp = data.body;
            break;
          }

          case 204: {
            //this.snack.open('Aucune TVA disponible', 'OK', { duration: 4000 })
            break;
          }

          default: {
            // this.snack.open('Une erreur est survenue!', 'OK', { duration: 4000 })

            break;
          }

        }
      },
        error => {
          this.logger.error("error");
          this.logger.error(error);

          switch (error.status) {

            default: {

              //    this.snack.open('Une erreur est survenue!', 'OK', { duration: 4000 })

              break;
            }
          }
        });
  }




  getallCategories() {
    this.getItemSub = this.menuservice.getAllCategories()
      .subscribe(data => {

        let responseany: any = data;

        this.logger.trace(responseany);

        switch (data.status) {

          case 200: {

            this.itemCategories = data.body;
            
            break;
          }

          default: {


            let response: any = data.body;
            this.itemCategories = response;



            break;
          }



        }
      },
        error => {
          console.log("error");
          console.log(error);

          switch (error.status) {

            default: {

              break;
            }
          }
        });
  }


  getallFood(id) {
    this.getItemSub = this.menuservice.getAllFoodByCatid("parameter/categorie/"+id+"/foods")
      .subscribe(data => {

        let responseany: any = data;

        this.logger.trace(responseany);

        switch (data.status) {

          case 200: {

            this.foodItems = data.body;
            console.log( this.foodItems);
           
            break;
          }

          default: {


            let response: any = data.body;
            this.itemCategories = response;



            break;
          }



        }
      },
        error => {
          console.log("error");
          console.log(error);

          switch (error.status) {

            default: {

              break;
            }
          }
        });
  }

  getAllPeriode() {

    this.statserv.getJSON('assets/data/periode.json').subscribe(data => {
      this.logger.trace(" all icon : ", data);
      this.itemPeriode = data;
    },
      error => {
        switch (error.status) {
          default: {
            break;
          }
        }
      });
  }


  getGlobalStat() {

    this.loader.open();


    this.datePaiementForm = new FormGroup({
      startdate: new FormControl('', Validators.required),
      enddate: new FormControl('', Validators.required),
    });
    this.globalstat = true;
    this.periodestat = false;
    this.getItemSub = this.statserv.getAllStat()
      .subscribe(data => {

        switch (data.status) {

          default: {
            let response: any = data.body;
            this.global = response;
            this.logger.trace('globalstat', this.global);
            this.loader.close();

            break;
          }


        }
      },
        error => {
          console.log('error');
          console.log(error);

          switch (error.status) {

            default: {
              this.loader.close();

              break;
            }
          }
        });
  }



  getPeriodeStat() {
    this.globalstat = false;
    this.periodestat = true;
    let datededebut = this.datePaiementForm.get('startdate').value;
    let datedefin = this.datePaiementForm.get('enddate').value;
    this.logger.trace(datededebut);
    this.logger.trace(datedefin);

    let to: string = "";
    let from: string = "";
    let date = new Date(this.datePaiementForm.get('startdate').value);
    let date1 = new Date(this.datePaiementForm.get('enddate').value);

    if ((date.toString() != 'Invalid Date')) {
      from = this.datePaiementForm.get('startdate').value;
    }

    if (date1.toString() != null) {
      to = this.datePaiementForm.get('enddate').value;
    }

    let newDate = new Date(from);

    let newDate1 = new Date(to);


    // construire obj to send
    let statperiodPost: statperiodresquest = new statperiodresquest();

    if ((newDate.toDateString() !== "Invalid Date") && (newDate1.toDateString() !== "Invalid Date")) {
      //;
      statperiodPost.debut = this.datePipe.transform(newDate, "yyyy-MM-dd HH:mm:ss");
      statperiodPost.fin = this.datePipe.transform(newDate1, "yyyy-MM-dd HH:mm:ss");
    }

    this.statserv.getPeriode(statperiodPost).subscribe(data => {
      this.logger.trace('DATA', data);


      switch (data.status) {

        default: {
          let response: any = data.body;
          this.periode = response;
          this.logger.trace('globalstat', this.periodestat);

          break;
        }

      }

    }
      ,
      error => {
        this.logger.error('ERREUR', error);
        switch (error.status) {



          default: {
            this.loader.close();

            this.snackbar.open('Echec !! Une erreur est survenue ', 'OK', {
              duration: 10000
            });
            break;
          }
        }
      });
  }






  exportToPdf() {

    if (this.resultatstat && this.resultatstat.itmes && this.resultatstat.itmes.length > 0) {
      let itemspdf: any[] = [];
      this.resultatstat.itmes.forEach(function (element) {
        var my_json = {
          "categorie": element.categorie,
          "Produits": element.food,
          "Nombre de produits vendu": element.qte,
          "Prix unitaire": element.pu,
          "total": element.total
        };

        console.log("my_json");
        console.log(my_json);
        itemspdf.push(my_json);
      });


      var columns = [
        { title: "categorie", dataKey: "categorie" },
        { title: "Produits", dataKey: "Produits" },
        { title: "Nombre de produits vendu", dataKey: "Nombre de produits vendu" },
        { title: "Prix unitaire", dataKey: "Prix unitaire" },
        { title: "total", dataKey: "total" }
      ];
      var rows = itemspdf;

      const doc = new jsPDF('portrait', 'px', 'a4') as jsPDFWithPlugin;
      doc.autoTable({
        body: rows,
        columns: columns,
        didParseCell: function (data) {
          if (data.column.raw.displayProperty) {
            var prop = data.column.raw.displayProperty;
            var text = data.cell.raw[prop];
            if (text && text.length > 0) {
              data.cell.text = text;
            }
          }
        }
      });
      doc.save('statistique-par-service.pdf');
    }

  }




  public exportXls() {
    if (this.resultatstat && this.resultatstat.itmes && this.resultatstat.itmes.length > 0) {
      let itemscsv: any[] = [];
      this.resultatstat.itmes.forEach(function (element) {
        var my_json = {
          "categorie": element.categorie,
          "Produits": element.food,
          "Nombre de produits vendu": element.qte,
          "Prix unitaire": element.pu,
          "total": element.total
        };

        console.log("my_json");
        console.log(my_json);
        itemscsv.push(my_json);
      });

      this.exportAsExcelFile(itemscsv, 'statistique-par-service');

    }
  }




  public exportAsExcelFile(json: any[], excelFileName: string): void {
    const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(json);
    const workbook: XLSX.WorkBook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
    const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
    this.saveAsExcelFile(excelBuffer, excelFileName);
  }
  private saveAsExcelFile(buffer: any, fileName: string): void {
    const data: Blob = new Blob([buffer], { type: EXCEL_TYPE });
    FileSaver.saveAs(data, fileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION);
  }
}
