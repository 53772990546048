import { DeletedialogComponent } from './deletedialog/deletedialog.component';
import { RoleModifierComponent } from './role-modifier/role-modifier.component';
import { MatSort, MatTableDataSource, MatPaginator, MatDialogRef, MAT_DIALOG_DATA, MatDialog, MatSnackBar } from '@angular/material';
import { Router } from '@angular/router';
import { FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';
import { Role } from './../../../models/Role';
import { RolesService } from './../../../services/roles.service';
import { Component, OnInit, ViewChild, Inject } from '@angular/core';
import { result } from 'lodash-es';


export interface DialogData {
  role: Role;
}

@Component({
  selector: 'fury-roles',
  templateUrl: './roles.component.html',
  styleUrls: ['./roles.component.scss'],
  providers: [RolesService]
})
export class RolesComponent implements OnInit {


  private roles: Role[];
  private selectedValue: Role;
  roleForm: FormGroup;


  roleId: string;

  rolename: string;
  roleisdeleted: boolean;
  roledatecreation: string;
  role: Role;

  displayedColumns: string[] = ['rolename', 'roledatecreation', 'roleisdeleted', 'actions'];
  dataSource: any;

  
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  constructor(private router: Router, private roleService: RolesService,private formBuilder: FormBuilder, private snackbar: MatSnackBar, public dialog: MatDialog) {
  }



  ngOnInit() {
    this.findAll();


    this.roleForm = new FormGroup({
      rolename: new FormControl('', Validators.required)

    });

    this.roleForm = this.formBuilder.group({
      rolename: ['', Validators.required]
  })
    

  }


  onSubmit() {

    console.info('-----------------------------------------');


    if (this.roleForm.valid) {
       // saving object
    
      this.roleService.save(this.roleForm.controls['rolename'].value).subscribe(data => {


        console.log(data);
        switch (data.status) {
          case 201: {
            // this.loading.dismiss('messagesService.loadMessagesOverview');
            
            let resp: Role;
            resp = data.body;
            console.log(resp);
            console.log("------------------------------------");
            this.snackbar.open('Success role Créé', 'OK', {
              duration: 2000
            });
            

            if (resp != null) {

              console.log("-------------- success role created ----------------");
              this.findAll();
              //this.router.navigateByUrl('./');

              // this.router.navigateByUrl('/dashboard');
            } else {
              // this.FormReg(" Mot de passe ou login incorrect ");
            }
            break;
          }
        }
      },
        error => {
          console.log("erreur retour");
          // this.loading.dismiss('messagesService.loadMessagesOverview');
          switch (error.status) {
            case 409:
              //this.FormReg(" Mot de passe ou login incorrect ");
              this.snackbar.open('Echec modification', 'Le role existe deja', {
                duration: 2000
              });
              break;
            case 401:
              //this.FormReg(" Mot de passe ou login incorrect ");
              break;
            default: {
              // this.FormReg(" erreur de connexion !!! Veuillez ressayer");
              this.snackbar.open('Echec modification', 'OK', {
                duration: 2000
              });
              break;
            }
          }

        });

        this.roleForm.reset({rolename: ''});
       
      /////////////
  } else {
      this.validateAllFormFields(this.roleForm);
      this.snackbar.open('Veuillez saisir toutes les valeurs svp', 'OK', {
        duration: 2000
      });



  }

    


}

  validateAllFormFields(formGroup: FormGroup) {
    Object.keys(formGroup.controls).forEach(field => {
        const control = formGroup.get(field);
        if (control instanceof FormControl) {
            control.markAsTouched({ onlySelf: true });
        } else if (control instanceof FormGroup) {
            this.validateAllFormFields(control);
        }
    });
}


  findAll() {

    this.roleService.findAll().subscribe(data => {
      console.log(data);
      switch (data.status) {
        case 200: {
          // this.loading.dismiss('messagesService.loadMessagesOverview');
          
          let resp: Role[];
          resp = data.body;
          console.log("------------------------------------");
          console.log(resp);
          console.log("------------------------------------");
          console.log(resp[0].roledatecreation);
          this.roles = resp;
          // this.dataSource = valeurs;
          this.dataSource = new MatTableDataSource<Role>(resp);
          this.dataSource.paginator = this.paginator;
          this.dataSource.sort = this.sort;


          if (resp != null) {
            //this.resetFunction();


            console.log("------------- roles founded ------------------");

          } else {

            // this.FormReg(" Mot de passe ou login incorrect ");
          }
          break;
        }
      }
    },
      error => {
        console.log("erreur retour");
        // this.loading.dismiss('messagesService.loadMessagesOverview');
        switch (error.status) {
          case 401:
            //this.FormReg(" Mot de passe ou login incorrect ");
            break;
          default: {
            // this.FormReg(" erreur de connexion !!! Veuillez ressayer");
            break;
          }
        }
      }
    );

  }


  updateRole(row) {
    console.log(' edit role ');

    if (this.roleId) {
      console.log('id ok ----editing ');

      let role: Role = new Role();
      role.roleid = this.roleId;
      role.rolename = this.roleForm.controls['rolename'].value;
      // this.valeurForm.controls['roleLastName'].value,
      // this.valeurForm.controls['roleEmail'].value;

      // updating object
      this.roleService.update(role).subscribe();
    }


  }


  deleteRole(row) {
    console.log('-- Delete role ---  '+  row);

    if (row) {
      let role : Role = row ;
      this.openDialogDelete(role);
      /*this.roleService.delete(role.roleid).subscribe(
        res => {
          this.findAll();
          //this.router.navigate(['/valeur']);
          console.log('----  done ---- ');
          this.snackbar.open('Success supprimé' , 'OK', {
            duration: 2000
          });
      
        }
      );*/
    }


    

  }

  openDialogDelete(role): void {
    const dialogRef = this.dialog.open(DeletedialogComponent, {
      width: '250px',
      data: {role: role}
    });

    dialogRef.afterClosed().subscribe(resp => {
      console.log('The dialog was closed' + resp );
      //this.animal = result;
      this.findAll();
    });

  }


  openDialog(row): void {
    //console.log('------onpen dialog -------');

    console.log(row);
    this.selectedValue = row;
    if (row) {
      this.callRoleDetail();
    }
  }

  
  callRoleDetail() {
    console.log('------onpen dialog -------');

    const dialogRef = this.dialog.open(RoleModifierComponent, {
      maxWidth: '80%',

      data: { role: this.selectedValue }
    });

    dialogRef.afterClosed().subscribe(resp => {

      console.log('The dialog was closed .. resp --- ' +  resp);



      this.findAll() ;
      console.log('======================');
     // this.role = result;

    });
  }
}
