import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';

@Component({
  selector: 'fury-exportxls',
  templateUrl: './exportxls.component.html',
  styleUrls: ['./exportxls.component.scss']
})
export class ExportxlsComponent implements OnInit {

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
  public dialogRef: MatDialogRef<ExportxlsComponent>) {
}
  ngOnInit() {
  }




  allcommandexls(){
    this.dialogRef.close("allcommandexls");
  }

  livrerxls(){
    this.dialogRef.close("livrerxls");
  }

}
