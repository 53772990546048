import { Component, Inject, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { NGXLogger } from 'ngx-logger';
import { Subscription } from 'rxjs';
import { Formule, Categoriecorpo } from 'src/app/models/param';
import { CatcorpoformuleService } from '../catcorpoformule.service';

@Component({
  selector: 'fury-update',
  templateUrl: './update.component.html',
  styleUrls: ['./update.component.scss']
})
export class UpdateComponent implements OnInit {

 
  form: FormGroup;
  constructor(@Inject(MAT_DIALOG_DATA) public defaults: any,
    public dialogRef: MatDialogRef<UpdateComponent>,
    private fb: FormBuilder,
    private catcorpoformuleService: CatcorpoformuleService,
    private logger: NGXLogger) {
  }

  getItemSub: Subscription;
  itemsformule: Formule[];
  itemscategoriecorpo: Categoriecorpo[];

  selectedFormulecard : string ;
  selectedCatcorpocard : string ;

  scrollBarHorizontal = (window.innerWidth < 1200);
  temp = [];
  rows = [];
  columns = [];

  public catcorpo : string[] = [];

  selectedFormule : any ;

  ngOnInit() {
    this.getallcatcorpo();
    this.getallformule();
    let decouvert : any = {} = this.defaults.payload ;
    this.logger.fatal(decouvert);
    this.selectedFormulecard  = decouvert.formule.formuleid ;
    this.selectedCatcorpocard  = decouvert.categoriecorpo.categoriecorpoid ;
    this.logger.trace(decouvert);

    this.form = this.fb.group({
      catcorpoformuleid:  [ decouvert.catcorpoformuleid || '', Validators.compose([Validators.required])],
      formuleid:  [ decouvert.formule.formuleid || '', Validators.compose([Validators.required])],
      catcorpoid: [ decouvert.categoriecorpo.categoriecorpoid || '', Validators.compose([Validators.required])]
      //userpisteaudit: ['', Validators.compose([Validators.required])],
    });
    
  }

  submit() {
    let request : any = this.form.value ;
    this.logger.trace(request);
    this.dialogRef.close(request);
  }


  onChangeCatcorpo(event) {
    this.form.controls['catcorpoid'].setValue(event.source.value);
  }

  onChangeFormule(event) {
    this.form.controls['formuleid'].setValue(event.source.value);
  }


  getallcatcorpo() {
    this.getItemSub = this.catcorpoformuleService.getAll('categoriecorporate/getallcategoriecorpo')
      .subscribe(data => {

        let statuscode = data.status;
        this.logger.trace('DATA', data);

        switch (statuscode) {

          case 200: {
            this.itemscategoriecorpo = data.body;
            break;
          }

          case 204: {
            break;
          }

          default: {

            break;
          }

        }
      },
        error => {
          this.logger.error("error");
          this.logger.error(error);

          switch (error.status) {

            default: {
         //     this.snack.open('Une erreur est survenue!', 'OK', { duration: 4000 })

              break;
            }
          }
        });
  }

  getallformule() {
    this.getItemSub = this.catcorpoformuleService.getAll('formule')
      .subscribe(data => {

        let statuscode = data.status;
        this.logger.trace('DATA', data);

        switch (statuscode) {

          case 200: {
            this.itemsformule = data.body;
            break;
          }

          case 204: {
            break;
          }

          default: {

            break;
          }

        }
      },
        error => {
          this.logger.error("error");
          this.logger.error(error);

          switch (error.status) {

            default: {
         //     this.snack.open('Une erreur est survenue!', 'OK', { duration: 4000 })

              break;
            }
          }
        });
  }


}
