export class Clienttype {

    clienttypeid: string;
    clienttypename: string;
    clienttypedatecreation: string;
    clienttypeisdeleted: boolean;
    public constructor() {
    }
}

export class ClientCorporate {
    clienttypeid: string;
    clienttypeisdeleted: boolean;
    clienttypedatecreation: string;
    clienttypemasterkey: string;
    clienttyperaisonsocial: string;
    clienttypelocalisation: string;
    clienttypeadressepostale: string;
    clienttypeemail: string;
    clienttypetelephone: string;
    clienttypestatutjuridique: string;
    public constructor() {
    }
}