import { RolesComponent } from './roles/roles.component';
import { ParamettresRoutes } from './paramettres.routing';
import { RouterModule } from '@angular/router';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ParametragesComponent } from './parametrages.component';
import { ReactiveFormsModule } from '@angular/forms';
import { MaterialModule } from 'src/@fury/shared/material-components.module';
import { BreadcrumbsModule } from 'src/@fury/shared/breadcrumbs/breadcrumbs.module';
import { MatInputModule } from '@angular/material';
import { FuryCardModule } from 'src/@fury/shared/card/card.module';
import { ModelivraisonComponent } from './modelivraison/modelivraison.component';
import { CategorieComponent } from './categorie/categorie.component';
import { PaymentmodeComponent } from './paymentmode/paymentmode.component';
import { TypeclientComponent } from './typeclient/typeclient.component';
import { RoleModifierComponent } from './roles/role-modifier/role-modifier.component';
import { DeletedialogComponent } from './roles/deletedialog/deletedialog.component';
import { PaymentmodeModifierComponent } from './paymentmode/paymentmode-modifier/paymentmode-modifier.component';
import { DeletedialogpaymentComponent } from './paymentmode/deletedialogpayment/deletedialogpayment.component';
import { DeletedialogclientypeComponent } from './typeclient/deletedialogclientype/deletedialogclientype.component';
import { ClienttypeModifierComponent } from './typeclient/clienttype-modifier/clienttype-modifier.component';
import { DeletedialogmodelivraisonComponent } from './modelivraison/deletedialogmodelivraison/deletedialogmodelivraison.component';
import { ModelivraisonModifierComponent } from './modelivraison/modelivraison-modifier/modelivraison-modifier.component';
import { DeletedialogcategorieComponent } from './categorie/deletedialogcategorie/deletedialogcategorie.component';
import { CategorieModifierComponent } from './categorie/categorie-modifier/categorie-modifier.component';

@NgModule({

  declarations: [
    ParametragesComponent,
  RolesComponent,
  ModelivraisonComponent,
  CategorieComponent,
  PaymentmodeComponent,
  TypeclientComponent,
  //ModifierRoleModal,
  RoleModifierComponent,
  DeletedialogComponent,
  PaymentmodeModifierComponent,
  DeletedialogpaymentComponent,
  DeletedialogclientypeComponent,
  ClienttypeModifierComponent,
  DeletedialogmodelivraisonComponent,
  ModelivraisonModifierComponent,
  DeletedialogcategorieComponent,
  CategorieModifierComponent
],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    MaterialModule,
    BreadcrumbsModule,
    MatInputModule,
    FuryCardModule,
    RouterModule.forChild(ParamettresRoutes),

  ],
 
  entryComponents: [
    RoleModifierComponent,
    DeletedialogComponent,
    PaymentmodeModifierComponent,
    DeletedialogpaymentComponent,
    DeletedialogclientypeComponent,
    ClienttypeModifierComponent,
    ModelivraisonModifierComponent,
    DeletedialogmodelivraisonComponent
   
]
})
export class ParametragesModule { }
