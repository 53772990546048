import { GenericserviceService } from './../../../../services/genericservice.service';
import { Clienttype } from './../../../../models/Clienttype';
import { MatDialogRef, MAT_DIALOG_DATA, MatSnackBar } from '@angular/material';
import { FormGroup, FormControl } from '@angular/forms';
import { Component, OnInit, Inject } from '@angular/core';




export interface DialogData {
  clienttype: Clienttype;
}
@Component({
  selector: 'fury-clienttype-modifier',
  templateUrl: './clienttype-modifier.component.html',
  styleUrls: ['./clienttype-modifier.component.scss']
})
export class ClienttypeModifierComponent implements OnInit {

 
  clienttypeForm: FormGroup;
  clienttypeId: string;
  clienttype: Clienttype;




  constructor(public dialogRef: MatDialogRef<ClienttypeModifierComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private clienttypeService: GenericserviceService, private snackbar: MatSnackBar) { }


  onNoClick(): void {
    console.info('---clic on close --- ')
    this.dialogRef.close();
  }

  ngOnInit() {
    this.clienttypeService.type='clienttype';

    this.clienttypeForm = new FormGroup({ clienttypename: new FormControl(), clienttypeisdeleted: new FormControl() })

    if (this.data) { // edit form
      console.log('checking selected clienttype--- : ' + this.data.clienttype.clienttypename);
      this.clienttypeForm.reset({ clienttypename: this.data.clienttype.clienttypename, clienttypeisdeleted: this.data.clienttype.clienttypeisdeleted });
    }

  }


  onSubmit() {

    if (this.clienttypeForm.valid) {
      console.log('-----------updating clienttype ----------------');

      let updatedClienttype: Clienttype = new Clienttype();
      updatedClienttype.clienttypeid = this.data.clienttype.clienttypeid;
      updatedClienttype.clienttypedatecreation = this.data.clienttype.clienttypedatecreation;
      updatedClienttype.clienttypename = this.clienttypeForm.controls['clienttypename'].value;
      updatedClienttype.clienttypeisdeleted = this.data.clienttype.clienttypeisdeleted;

      console.log('-----------data to update  ---------------- ' + updatedClienttype.clienttypeid + ' ' +
        updatedClienttype.clienttypename + ' ' + updatedClienttype.clienttypeisdeleted);


      this.clienttypeService.update(updatedClienttype).subscribe(data => {

        console.log(data);
        switch (data.status) {
          case 201: {
            // this.loading.dismiss('messagesService.loadMessagesOverview');

            let resp: Clienttype;
            resp = data.body;
            console.log("------------------------------------");
            console.log(resp);
            console.log("------------------------------------");


            /* Swal.fire({
               position: 'top-end',
               type: 'success',
               title: 'Your work has been saved',
               showConfirmButton: false,
               timer: 1500
             })*/




            this.snackbar.open('Success modification', 'OK', {
              duration: 2000
            });

            break;
          }
        }
      },
        error => {

          console.log("erreur retour");
          // this.loading.dismiss('messagesService.loadMessagesOverview');
          switch (error.status) {
            case 409:
              //this.FormReg(" Mot de passe ou login incorrect ");
              this.snackbar.open('Echec modification', 'Le mode de payment existe deja', {
                duration: 2000
              });
              break;
            default: {
              // this.FormReg(" erreur de connexion !!! Veuillez ressayer");
              this.snackbar.open('Echec modification', 'OK', {
                duration: 2000
              });
              break;
            }
          }

        });


      console.log('-----------updating clienttype ok ----------------');


      this.dialogRef.close();
    } else {


      // this.validateAllFormFields(this.clienttypeForm);
      this.snackbar.open('Veuillez saisir toutes les valeurs svp', 'OK', {
        duration: 2000
      });
    }




  }

}
