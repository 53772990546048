import { Component, Inject, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { NGXLogger } from 'ngx-logger';
import { Abnstat } from 'src/app/models/Agent';

@Component({
  selector: 'fury-statutabn',
  templateUrl: './statutabn.component.html',
  styleUrls: ['./statutabn.component.scss']
})
export class StatutabnComponent implements OnInit {




  abn_solde = {} as Abnstat;
  action: string;
  transaction: string;

  soldeForm: FormGroup;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<StatutabnComponent>
    , private logger: NGXLogger
  ) { }

  ngOnInit() {
    this.buildItem(this.data.payload);
  }

  buildItem(value: any) {
    console.log("------Transaction status");
    console.log(value);
    this.abn_solde = value;
    this.transaction = this.abn_solde.histabnfileref;
    if (this.abn_solde) {
      if (this.abn_solde.action === 0) {
        this.action = "ANNULER";
      }
      if (this.abn_solde.action === 1) {
        this.action = "ACTIVER";
      }
      if (this.abn_solde.action === 2) {
        this.action = "DESACTIVER";
      }
    }

  }


  submit() {
    console.log(this.abn_solde);
    this.dialogRef.close(this.abn_solde);
  }
}
