import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatChipsModule } from '@angular/material';
import { FurySharedModule } from '../../../@fury/fury-shared.module';
import { BreadcrumbsModule } from '../../../@fury/shared/breadcrumbs/breadcrumbs.module';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { ReactiveFormsModule } from '@angular/forms';
import { MaterialModule } from '../../../@fury/shared/material-components.module';
import { FuryCardModule } from '../../../@fury/shared/card/card.module';
import { CarteabonnementComponent } from './carteabonnement.component';
import { ActivecaComponent } from './activeca/activeca.component';
import { LierclientcaComponent } from './lierclientca/lierclientca.component';
import { AddcarteComponent } from './addcarte/addcarte.component';
import { ShowcarteComponent } from './showcarte/showcarte.component';
import { ShowmsgComponent } from './showmsg/showmsg.component';
import { AppProgressbarComponent } from '../apps/app-progressbar/app-progressbar.component';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { MatSelectSearchModule } from '../historiquecarte/mat-select-search/mat-select-search.module';




@NgModule({

  entryComponents: [ActivecaComponent, LierclientcaComponent, AddcarteComponent , ShowcarteComponent , ShowmsgComponent],
  declarations: [CarteabonnementComponent, ActivecaComponent, LierclientcaComponent, AddcarteComponent, ShowcarteComponent,AppProgressbarComponent , ShowmsgComponent],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    MatChipsModule,
    MaterialModule,
    BreadcrumbsModule,
    NgxDatatableModule,
    FuryCardModule,
    NgxMatSelectSearchModule,
    MatSelectSearchModule,
    MatChipsModule,
    FurySharedModule,
  ]
})
export class CarteabonnementModule { }
