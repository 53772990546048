import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef, MatSnackBar } from '@angular/material';
import { ActivatedRoute } from '@angular/router';
import { ConfigService } from 'ngx-envconfig';
import { NGXLogger } from 'ngx-logger';
import { Subscription } from 'rxjs';
import { AutreService } from 'src/@fury/services/autre.service';
import { ConfirmationService } from 'src/app/confirm/confirmation.service';
import { Checkingcloture } from 'src/app/models/checkingcloture';
import { HistoactiviteDto } from 'src/app/models/histoactiviteDto';
import { ApploaderService } from 'src/app/services/apploader/apploader.service';
import { HistoactiviteService } from '../histoactivite.service';

@Component({
  selector: 'fury-popupcloturecaisse',
  templateUrl: './popupcloturecaisse.component.html',
  styleUrls: ['./popupcloturecaisse.component.scss']
})
export class PopupcloturecaisseComponent implements OnInit {

  public itemForm: FormGroup;
  public getItemSub: Subscription;
  requestToPass :  Checkingcloture  ;

  date : String = "" ;


  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<PopupcloturecaisseComponent>,
    private fb: FormBuilder,
    private snack: MatSnackBar,
    private logger: NGXLogger,
    private autreService: AutreService,
    private configService: ConfigService,
    private histoactiviteService: HistoactiviteService,
    private loader: ApploaderService,
    private route: ActivatedRoute,
    private confirmService: ConfirmationService
  ) { }

  

  ngOnInit() {

    this.requestToPass = this.data.payload ;
    this.date = this.requestToPass.histoactivitedatecreation ;
    this.itemForm = this.fb.group({
      cloturecaisse: ['', [Validators.required]],
    });
  }


  submit(){
    this.loader.open('Verification en cours, patienter SVP');
    this.checkHistoActiviteByCaisseAndDate(this.requestToPass.caisseid, this.requestToPass.histoactivitedatecreation);
  }


  checkHistoActiviteByCaisseAndDate(caisseid: string, date: string) {
    let histoactivite: HistoactiviteDto = new HistoactiviteDto();
    histoactivite.caisseid = caisseid;
    histoactivite.histoactivitedate = date;
    this.logger.info(histoactivite);
    this.getItemSub = this.histoactiviteService.getHistoactiviteByCaisseAndDate(histoactivite)
      .subscribe(data => {
        this.loader.close();
        let statuscode = data.status;
        this.logger.trace('DATA cloture popup', data);
        switch (statuscode) {

          case 200: {
            let fondcaisse = data.body.histoactivitefonddepart;
            let solde = this.itemForm.get('cloturecaisse').value - fondcaisse;
            if (solde != this.requestToPass.chiffreaffaire) {
              this.snack.open('Solde different du chiffre d\'affaire de la journée', 'OK', { duration: 4000 });
              this.confirmationFondcaisse(solde, this.requestToPass.chiffreaffaire); // Alerte utilisateur avant insertion
            } else {
              this.snack.open('Cloture de caisse renseigné, remplissez le billetage SVP', 'OK', { duration: 4000 });
              this.logger.trace('Cloture de caisse', this.itemForm.get('cloturecaisse').value);
              this.dialogRef.close({cloturecaisse: this.itemForm.get('cloturecaisse').value, differencepercu: (solde - this.requestToPass.chiffreaffaire)});
            }
            break;
          }

          case 204: {
            break;
          }

          default: {
            this.snack.open('Une erreur est survenue!', 'OK', { duration: 4000 });

            break;
          }

        }
      },
        error => {
          this.logger.error('error');
          this.logger.error(error);

          this.loader.close();

          switch (error.status) {

            default: {
              this.snack.open('Une erreur est survenue!', 'OK', { duration: 4000 });

              break;
            }
          }
        });
  }


  confirmationFondcaisse(solde: number, chiffreaffaire: number) {
    this.logger.trace('Confirmation fond de caisse, solde et chiffre affaire', solde, chiffreaffaire);
    this.confirmService.confirm({
      title: 'Voulez vous continuer ?',
      message: `Vous avez ` + ( (solde < chiffreaffaire) ? 'un manquant' : 'un trop percu') +
       `  .Montant: ${Math.abs(solde - chiffreaffaire)} FCFA?  `
    })
      .subscribe(res => {
        if (res) {
          this.snack.open('Cloture de caisse renseigné, remplissez le billetage SVP', 'OK', { duration: 4000 });
          this.logger.trace('Cloture de caisse', this.itemForm.get('cloturecaisse').value);
          // this.dialogRef.close(this.itemForm.get('cloturecaisse').value);
          this.dialogRef.close({cloturecaisse: this.itemForm.get('cloturecaisse').value, differencepercu: (solde - this.requestToPass.chiffreaffaire)});
        }
      });
  }


}
