import { DatePipe } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar, MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { Router } from '@angular/router';
import { ConfigService } from 'ngx-envconfig';
import { NGXLogger } from 'ngx-logger';
import { Subscription } from 'rxjs';
import { Clienttype } from 'src/app/models/Agent';
import { ApploaderService } from 'src/app/services/apploader/apploader.service';
import { LoadbalanceService } from '../../cartemasterloadbalance/loadbalance.service';
import { GestioncorporateService } from '../../gestioncorporate/gestioncorporate.service';

@Component({
  selector: 'fury-filecustomer',
  templateUrl: './filecustomer.component.html',
  styleUrls: ['./filecustomer.component.scss']
})
export class FilecustomerComponent implements OnInit {

  fileSelected: File;
  form: FormGroup;


  public getItemSub: Subscription;
  client: Clienttype[];


  constructor(@Inject(MAT_DIALOG_DATA) public defaults: any,
    public dialogRef: MatDialogRef<FilecustomerComponent>,
    private fb: FormBuilder, private pointservice: GestioncorporateService,
    private logger: NGXLogger) { }


  ngOnInit() {
    this.getAllCorporate();
    this.form = this.fb.group({
      corporateid: ['', [Validators.required]]
    });
  }


  getAllCorporate() {
    this.getItemSub = this.pointservice.get('clienttype')
      .subscribe(data => {
        const statuscode = data.status;
        this.logger.trace('DATA', data);
        switch (statuscode) {
          case 200: {
            console.log('**************');
            this.client = data.body;
            console.log(this.client);
            // this.temp = data.body;
            break;
          }

          case 204: {
            // this.snack.open('Aucune TVA disponible', 'OK', { duration: 4000 })
            break;
          }

          default: {
            // this.snack.open('Une erreur est survenue!', 'OK', { duration: 4000 })

            break;
          }

        }
      },
        error => {
          this.logger.error('error');
          this.logger.error(error);

          switch (error.status) {

            default: {

              //    this.snack.open('Une erreur est survenue!', 'OK', { duration: 4000 })

              break;
            }
          }
        });
  }


  public fileChangeEvent($event) {
    console.log('FILECHANGE');

    this.fileSelected = $event.target.files[0];
    let path = URL.createObjectURL($event.target.files[0]);
    console.log('PATH');
    console.log(path);

    const constFileSelected: File = $event.target.files[0];
    console.log(constFileSelected);
    console.log('FILENAME');
    console.log(constFileSelected.name);
    console.log(constFileSelected.type);
    console.log(constFileSelected.size);
    console.log(constFileSelected.lastModified);

    // Fonction pour afficher le contenu du fichier
    // this.check(path, constFileSelected.type);
  }


  submit() {
    let value: any = {};
    value.corporate = this.form.get('corporateid').value;
    value.file = this.fileSelected;
    this.dialogRef.close(value);
  }
  
}
