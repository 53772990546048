import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialog, MatSnackBar, MatTableDataSource } from '@angular/material';
import { ConfigService } from 'ngx-envconfig';
import { NGXLogger } from 'ngx-logger';
import { NgxPermissionsService } from 'ngx-permissions';
import { Subscription } from 'rxjs';
import { ConfirmationService } from 'src/app/confirm/confirmation.service';
import { ApploaderService } from 'src/app/services/apploader/apploader.service';
import { DeliveryService } from 'src/app/services/delivery.service';
import { ShowficheComponent } from './showfiche/showfiche.component';

@Component({
  selector: 'fury-fileattente',
  templateUrl: './fileattente.component.html',
  styleUrls: ['./fileattente.component.scss']
})
export class FileattenteComponent implements OnInit {

  public items: any[];
  public itemsFinal: any[];
  temp = [];
  rows = [];
  columns = [];


  public itemForm: FormGroup;

  public commands: Array<any> = [];
  dataSource: MatTableDataSource<any> = new MatTableDataSource([]);
  public getItemSub: Subscription;

  check: any[] = [
    {
      "libele": "TOUTES LES COMMANDES",
      "values": "all"
    },
    {
      "libele": "EN ATTENTE PRISE EN COMPTE CUISINE",
      "values": "delivery.level1"
    },
    {
      "libele": "EN ATTENTE FIN PREPARATION",
      "values": "delivery.level2"
    },
    {
      "libele": "EN ATTENTE PRISE EN COMPTE EXPEDITEUR",
      "values": "delivery.level3"
    },
    {
      "libele": "EN ATTENTE FIN PACKAGING",
      "values": "delivery.level4"
    },
    {
      "libele": "EN ATTENTE ATTRIBUTION LIVREUR",
      "values": "delivery.level5"
    },
  ]


  constructor(private dialog: MatDialog, public formBuilder: FormBuilder,
    private snackbar: MatSnackBar, private commandeserv: DeliveryService,
    private datePipe: DatePipe,
    private confirmService: ConfirmationService, private loader: ApploaderService,
    private permissionsService: NgxPermissionsService, private logger: NGXLogger,
    private configService: ConfigService, private fb: FormBuilder) { }


  ngOnInit() {
    this.refreshDatatable();
    this.search();
  }

  refreshDatatable() {


    let dateNow: any = new Date();
    dateNow = this.datePipe.transform(dateNow, "yyyy-MM-dd");

    this.itemForm = this.fb.group({
      debut: [dateNow + 'T00:01:01'],
      fin: [dateNow + 'T23:59:59'],
      mode: ['a livrer'],
      type: ['all'],
      categorie: ['']
    });

    /*
    this.itemForm.get('type').valueChanges.subscribe(data => {
      console.log(data);
      if (data === 'all') {
        this.itemForm.patchValue({
          type: 'all',
          categorie: '',

        });
      }
      if (data !== 'all') {
        this.itemForm.patchValue({
          type: 'status',
          categorie: data,
        });
      }
    });
    */

  }



  updateFilter(event) {
    const val = event.target.value.toLowerCase();
    var columns = Object.keys(this.temp[0]);
    // Removes last "$$index" from "column"
    columns.splice(columns.length - 1);

    // console.log(columns);
    if (!columns.length)
      return;

    const rows = this.temp.filter(function (d) {
      for (let i = 0; i <= columns.length; i++) {
        let column = columns[i];
        // console.log(d[column]);
        if (d[column] && d[column].toString().toLowerCase().indexOf(val) > -1) {
          return true;
        }
      }
    });

    this.items = rows;
  }


  search() {
    let date = new Date(this.itemForm.get("debut").value);
    let date1 = new Date(this.itemForm.get("fin").value);

    let request: any = this.itemForm.value;

    let from;
    let to;

    if ((date.toString() != 'Invalid Date')) {
      from = this.itemForm.get("debut").value;
    }

    if (date1.toString() != null) {
      to = this.itemForm.get("fin").value;
    }

    let newDate = new Date(from);
    let newDate1 = new Date(to);

    if ((newDate.toDateString() !== "Invalid Date") && (newDate1.toDateString() !== "Invalid Date")) {
      if (newDate1 >= newDate) {
        request.debut = this.datePipe.transform(newDate, "yyyy-MM-dd HH:mm:ss");
        request.fin = this.datePipe.transform(newDate1, "yyyy-MM-dd HH:mm:ss");
      } else {
        this.snackbar.open('Format de date invalide ', 'OK', { duration: 4000 });
        return;
      }
    } else {
      this.snackbar.open('Format de date invalide ', 'OK', { duration: 4000 });
      return;
    }

    if (request.type === "all") {
      request.categorie = "";
    }

    if (request.type !== "all") {
      request.categorie = request.type;
      request.type = "status";
    }

    console.log(request);

    this.commandeserv.post(request, "fileattente/list").subscribe(data => {

      this.logger.trace(data);


      switch (data.status) {
        case 200: {

          let response: any = data.body;

          let resp1: any[] = response.listFileAttente;
          console.log(resp1);

          this.items = this.temp = resp1;

          this.snackbar.open('Succes', 'OK', {
            duration: 10000
          });
          break;
        }

      }
    },
      error => {
        this.logger.trace("erreur retour");
        // this.loading.dismiss('messagesService.loadMessagesOverview');
        switch (error.status) {



          default: {

            // this.FormReg(" erreur de connexion !!! Veuillez ressayer");
            this.logger.error("Check API", error);

            this.snackbar.open('Echec', 'OK', {
              duration: 10000
            });
            break;
          }
        }
      }
    );

  }


  getCommande(ref) {
    this.getItemSub = this.commandeserv.get("fileattente/findbyid/" + ref)
      .subscribe(data => {
        switch (data.status) {
          default: {
            this.logger.trace('data', data.body);
            let response: any = data.body;


            this.dialog.open(ShowficheComponent,
              {
                width: '720px',
                data: { title: '', payload: response }
              }
            ).afterClosed().subscribe((res) => {
              console.log(res);
              if (!res) {
                return;
              }
            });
            break;
          }
        }
      },
        error => {
          console.log('error');
          console.log(error);
          switch (error.status) {
            default: {
              break;
            }
          }
        });
  }

  verifyFalse(value: any) {
    if (value === true) {
      return 'oui';
    } else if (value === false) {
      return 'non';
    }
  }

  verifyStatus(value: any) {
    if (value === "delivery.level1") {
      return 'EN ATTENTE PRISE EN COMPTE CUISINE';
    } else if (value === "delivery.level2") {
      return 'EN ATTENTE FIN PREPARATION CUISINE';
    } else if (value === "delivery.level3") {
      return 'EN ATTENTE PRISE EN COMPTE EXPEDITEUR';
    } else if (value === "delivery.level4") {
      return 'EN ATTENTE FIN PACKAGING';
    } else if (value === "delivery.level5") {
      return 'EN ATTENTE ATTRIBUTION LIVREUR';
    } else if (value === "delivery.level6") {
      return 'EN COURS DE LIVRAISON';
    }
  }


}
