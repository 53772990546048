import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { NGXLogger } from 'ngx-logger';

@Component({
  selector: 'fury-demandestatus',
  templateUrl: './demandestatus.component.html',
  styleUrls: ['./demandestatus.component.scss']
})
export class DemandestatusComponent implements OnInit {

  demande : any ;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<DemandestatusComponent>
    ,private logger: NGXLogger
  ) { }

  ngOnInit() {
    this.buildItem(this.data.payload);
  }

  buildItem(value : any){
    this.demande=value ;
  }
}
