import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { ConfigService } from 'ngx-envconfig';
import { NGXLogger } from 'ngx-logger';
import { DeliveryService } from 'src/app/services/delivery.service';

@Component({
  selector: 'fury-zonedelivery',
  templateUrl: './zonedelivery.component.html',
  styleUrls: ['./zonedelivery.component.scss']
})
export class ZonedeliveryComponent implements OnInit {

  form: FormGroup;
  livraisons: any[] = [];

  constructor(@Inject(MAT_DIALOG_DATA) public defaults: any,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<ZonedeliveryComponent>,
    private fb: FormBuilder, private deliveryService: DeliveryService, private configService: ConfigService,
    private logger: NGXLogger) {
  }

  ngOnInit() {
    let livreur: any = this.data.payload;
    this.form = this.fb.group({
      livreurlieuresidence: [livreur.livreurlieuresidence || '', Validators.required],
      livreurzonetravailid: [livreur.livreurzonetravailid || '', Validators.required],
    });
    this.findPoint();
  }

  findPoint() {
    this.deliveryService.get("parameter/pointlivraison/findall").subscribe(data => {
      switch (data.status) {
        case 200: {
          // this.loading.dismiss('messagesService.loadMessagesOverview');
          this.livraisons = data.body;
          console.log(this.livraisons);
          break;
        }
      }
    },
      error => {
        console.log("erreur retour");
        // this.loading.dismiss('messagesService.loadMessagesOverview');
        switch (error.status) {

          default: {
            // this.FormReg(" erreur de connexion !!! Veuillez ressayer");
            break;
          }
        }
      }
    );

  }


  submit() {
    this.dialogRef.close(this.form.value);
    this.form.disabled;
  }

}
