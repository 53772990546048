import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GestioncommandeComponent } from './gestioncommande.component';
import { ReactiveFormsModule } from '@angular/forms';
import { MaterialModule } from 'src/@fury/shared/material-components.module';
import { BreadcrumbsModule } from 'src/@fury/shared/breadcrumbs/breadcrumbs.module';
import { MatInputModule, MatChipsModule } from '@angular/material';
import { FuryCardModule } from 'src/@fury/shared/card/card.module';
import { ShowcommandesComponent } from './showcommandes/showcommandes.component';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { ExportpdfComponent } from './exportpdf/exportpdf.component';
import { ExportxlsComponent } from './exportxls/exportxls.component';
import { AffectercommandeaunlivreurComponent } from './affectercommandeaunlivreur/affectercommandeaunlivreur.component';

@NgModule({
  declarations: [GestioncommandeComponent, ShowcommandesComponent, ExportpdfComponent, ExportxlsComponent],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    MaterialModule,
    BreadcrumbsModule,
    MatInputModule,
    NgxDatatableModule,
    FuryCardModule,
    MatChipsModule,
  ],
  entryComponents: [ShowcommandesComponent, ExportpdfComponent, ExportxlsComponent]


})
export class GestioncommandeModule { }
