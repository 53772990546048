import { Component, OnInit } from '@angular/core';
import { MatSnackBar, MatDialog, MatDialogRef } from '@angular/material';
import { ConfigService } from 'ngx-envconfig';
import { NGXLogger } from 'ngx-logger';
import { Subscription } from 'rxjs';
import { ConfirmationService } from 'src/app/confirm/confirmation.service';
import { Agentstorage } from 'src/app/models/agentstorage';
import { CategoriecorpoService } from '../../categoriecorpo/categoriecorpo.service';
import { GestioncorporateService } from '../../gestioncorporate/gestioncorporate.service';
import { ArticleReductionComponent } from './article-reduction/article-reduction.component';
import { MenuReductionComponent } from './menu-reduction/menu-reduction.component';
import { PanierReductionComponent } from './panier-reduction/panier-reduction.component';
import { PvReductionComponent } from './pv-reduction/pv-reduction.component';

@Component({
  selector: 'fury-step3-reduction',
  templateUrl: './step3-reduction.component.html',
  styleUrls: ['./step3-reduction.component.scss']
})
export class Step3ReductionComponent implements OnInit {

  getItemSub: Subscription;
  items: any[];
  scrollBarHorizontal = (window.innerWidth < 1200);
  temp = [];
  rows = [];
  columns = [];

  constructor(private logger: NGXLogger, private categoriecorposerv: CategoriecorpoService,
    private snack: MatSnackBar, private confirmService: ConfirmationService,
    private dialog: MatDialog,
    private pointservice: GestioncorporateService,
    private snackbar: MatSnackBar,
    private configService: ConfigService) { }


  ngOnInit() {
    this.getall();
  }



  
  updateFilter(event) {
    const val = event.target.value.toLowerCase();
    var columns = Object.keys(this.temp[0]);
    // Removes last "$$index" from "column"
    columns.splice(columns.length - 1);

    // console.log(columns);
    if (!columns.length)
      return;

    const rows = this.temp.filter(function (d) {
      for (let i = 0; i <= columns.length; i++) {
        let column = columns[i];
        // console.log(d[column]);
        if (d[column] && d[column].toString().toLowerCase().indexOf(val) > -1) {
          return true;
        }
      }
    });
    this.items = rows;
  }



  openPopUpMenu(data: any = {}, isNew?) {
    let title = 'Ajouter un menu' ;
    let dialogRef: MatDialogRef<any> = this.dialog.open(MenuReductionComponent, {
      width: '720px',
      disableClose: false,
      data: { title: title, payload: data }
    })
    dialogRef.beforeClose()
      .subscribe(res => {
        if (!res) {
          // If user press cancel
          return;
        }

        let url: string = "parameter/abtmnt/add";

        /*
        if (isNew) {
          url = "parameter/abattement/add"
        }
        */

        this.logger.trace("URL : " + url);

        
        this.categoriecorposerv.post(res, url)
          .subscribe(data => {
            let statuscode = data.status;

            switch (statuscode) {
              case 200: {
                this.getall();
                this.snack.open('Succes !', 'OK', { duration: 4000 })
                break;
              }

            }

          },
            error => {
              let statuscode = error.status;
              switch (statuscode) {

            
                case 409: {
                  this.snack.open('Ce menu existe deja', 'OK', { duration: 4000 })
                  break;
                }

                default: {
                  this.snack.open('Une erreur est survenue!', 'OK', { duration: 4000 })
                  break;
                }
              }

            });

            
      })
  }


  openPopUpArticle(data: any = {}, isNew?) {
    let title = 'Ajouter un menu' ;
    let dialogRef: MatDialogRef<any> = this.dialog.open(ArticleReductionComponent, {
      width: '720px',
      disableClose: false,
      data: { title: title, payload: data }
    })
    dialogRef.beforeClose()
      .subscribe(res => {
        if (!res) {
          // If user press cancel
          return;
        }

        let url: string = "parameter/abtmnt/add";

        /*
        if (isNew) {
          url = "parameter/abattement/add"
        }
        */

        this.logger.trace("URL : " + url);

        
        this.categoriecorposerv.post(res, url)
          .subscribe(data => {
            let statuscode = data.status;

            switch (statuscode) {
              case 200: {
                this.getall();
                this.snack.open('Succes !', 'OK', { duration: 4000 })
                break;
              }

            }

          },
            error => {
              let statuscode = error.status;
              switch (statuscode) {

            
                case 409: {
                  this.snack.open('Ce menu existe deja', 'OK', { duration: 4000 })
                  break;
                }

                default: {
                  this.snack.open('Une erreur est survenue!', 'OK', { duration: 4000 })
                  break;
                }
              }

            });

            
      })
  }


  openPopUpPanier(data: any = {}, isNew?) {
    let title = 'Ajouter un menu' ;
    let dialogRef: MatDialogRef<any> = this.dialog.open(PanierReductionComponent, {
      width: '720px',
      disableClose: false,
      data: { title: title, payload: data }
    })
    dialogRef.beforeClose()
      .subscribe(res => {
        if (!res) {
          // If user press cancel
          return;
        }

        let url: string = "parameter/abtmnt/add";

        /*
        if (isNew) {
          url = "parameter/abattement/add"
        }
        */

        this.logger.trace("URL : " + url);

        
        this.categoriecorposerv.post(res, url)
          .subscribe(data => {
            let statuscode = data.status;

            switch (statuscode) {
              case 200: {
                this.getall();
                this.snack.open('Succes !', 'OK', { duration: 4000 })
                break;
              }

            }

          },
            error => {
              let statuscode = error.status;
              switch (statuscode) {

            
                case 409: {
                  this.snack.open('Ce menu existe deja', 'OK', { duration: 4000 })
                  break;
                }

                default: {
                  this.snack.open('Une erreur est survenue!', 'OK', { duration: 4000 })
                  break;
                }
              }

            });

            
      })
  }

  openPopUpPv(data: any = {}, isNew?) {
    let title = 'Ajouter un menu' ;
    let dialogRef: MatDialogRef<any> = this.dialog.open(PvReductionComponent, {
      width: '720px',
      disableClose: false,
      data: { title: title, payload: data }
    })
    dialogRef.beforeClose()
      .subscribe(res => {
        if (!res) {
          // If user press cancel
          return;
        }

        let url: string = "parameter/abtmnt/add";

        /*
        if (isNew) {
          url = "parameter/abattement/add"
        }
        */

        this.logger.trace("URL : " + url);

        
        this.categoriecorposerv.post(res, url)
          .subscribe(data => {
            let statuscode = data.status;

            switch (statuscode) {
              case 200: {
                this.getall();
                this.snack.open('Succes !', 'OK', { duration: 4000 })
                break;
              }

            }

          },
            error => {
              let statuscode = error.status;
              switch (statuscode) {

            
                case 409: {
                  this.snack.open('Ce menu existe deja', 'OK', { duration: 4000 })
                  break;
                }

                default: {
                  this.snack.open('Une erreur est survenue!', 'OK', { duration: 4000 })
                  break;
                }
              }

            });

            
      })
  }


  deleteItem(row) {
    this.confirmService.confirm({
      message: `Voulez vous supprimer la reduction : ${row.abtmntlib}?  `
    })
      .subscribe(res => {
        if (res) {
          let u = new Agentstorage();
          u = JSON.parse(localStorage.getItem('curentuser'));
          if (u != null) {
            row.userpisteaudit = u.agent.agentemail + ' ' + u.agent.agentfirstname + ' ' + u.agent.agentlastname;
          }

          this.pointservice.post(row, "abtmnt/delete").subscribe(data => {
            console.log(data);
            this.getall();
            this.snackbar.open('Success ', 'OK', {
              duration: 10000
            });

          },
            error => {

              this.snackbar.open('Echec !! Une erreur est survenue ', 'OK', {
                duration: 10000
              });
              console.log('erreur retour');
              console.log(error);
            }
          );
        }
      });
  }

  getall() {
    this.getItemSub = this.categoriecorposerv.getAll('parameter/abtmnt/getall')
      .subscribe(data => {

        let statuscode = data.status;
        this.logger.trace('DATA', data);

        switch (statuscode) {

          case 200: {
            this.items = data.body;
            this.temp = data.body;
            break;
          }


          default: {
            this.snack.open('Une erreur est survenue!', 'OK', { duration: 4000 })
            break;
          }

        }
      },
        error => {
          this.logger.error("error");
          this.logger.error(error);

          switch (error.status) {

            default: {
              this.snack.open('Une erreur est survenue!', 'OK', { duration: 4000 })

              break;
            }
          }
        });
  }

}
