import { Component, OnInit, Inject } from '@angular/core';
import { Param } from 'src/app/models/param';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { NGXLogger } from 'ngx-logger';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { CustomValidators } from 'ng2-validation';

@Component({
  selector: 'fury-update',
  templateUrl: './update.component.html',
  styleUrls: ['./update.component.scss']
})
export class UpdateComponent implements OnInit {


  updateForm: FormGroup;


  param: Param;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<UpdateComponent>
    , private logger: NGXLogger
  ) { }

  ngOnInit() {

    this.buildItem(this.data.payload);
  }

  buildItem(value: any) {
    this.param = value;
    this.updateForm = new FormGroup({
      value: new FormControl(this.param.value, [Validators.required, CustomValidators.number]),
      parameterdescription: new FormControl(this.param.parameterdescription, [Validators.required])
    });
  }


  submit() {

    this.dialogRef.close(this.updateForm.value);
  }




}
