import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { Agentstorage } from '../models/agentstorage';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  public authToken;
  private isAuthenticated = true; // Set this value dynamically

  constructor(private router: Router) { }
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    let u = new Agentstorage();
    u = JSON.parse(localStorage.getItem("curentuser"));
    if (u != null) {
      return true
    }
    this.router.navigate(['/']);
    return false;
  }
}
