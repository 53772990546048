import { ParametragesComponent } from './parametrages.component';
import { RolesComponent } from './roles/roles.component';
import { Routes } from '@angular/router';
import { ModelivraisonComponent } from './modelivraison/modelivraison.component';


export const ParamettresRoutes: Routes = [
   {
      path: '',
      redirectTo: 'roles',
      pathMatch: 'full'
   },{
      path: '',
      component: ParametragesComponent,
      children: [ {
        path: 'roles',
        component: RolesComponent
       },
       {
        path: 'modelivraison',
        component: ModelivraisonComponent
       }
    ]
   }
];
