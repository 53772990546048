import { Billetagefin } from './billetagefin';
import { Billetagedebut } from './billetagedebut';
export class HistoactiviteDto {
  histoactiviteid: string;
  histoactivitedate: string;
  histoactiviteheuredebut: any;
  histoactiviteheurefin: any;
  histoactivitefonddepart: number;
  histoactivitefondfin: number;
  //agentid: string;
  caisseid: string;
  histoactivitetype: string;
  billetagedebut: Billetagedebut[];
  billetagefin: Billetagefin[];
  histoactivitedifferencesolde: number;
  histoactivitechiffreaffaire: number;
  histoactivitecloseinbo : any ;
  agentid : any ;
}
