import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatChipsModule } from '@angular/material';
import { MaterialModule } from 'src/@fury/shared/material-components.module';
import { BreadcrumbsModule } from 'src/@fury/shared/breadcrumbs/breadcrumbs.module';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { FuryCardModule } from 'src/@fury/shared/card/card.module';
import { FurySharedModule } from 'src/@fury/fury-shared.module';
import { ReactiveFormsModule } from '@angular/forms';
import { GestionclientComponent } from './gestionclient.component';
import { ShowclientComponent } from './showclient/showclient.component';
import { AddclientComponent } from './addclient/addclient.component';
import { FilecustomerComponent } from './filecustomer/filecustomer.component';

@NgModule({
  declarations: [AddclientComponent, GestionclientComponent, ShowclientComponent, FilecustomerComponent],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    MatChipsModule,
    MaterialModule,
    BreadcrumbsModule,
    NgxDatatableModule,
    FuryCardModule,
    MatChipsModule,
    FurySharedModule
  ],
  entryComponents: [ShowclientComponent, AddclientComponent, FilecustomerComponent]
})
export class GestionclientModule { }
