import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GestionprofilComponent } from './gestionprofil.component';
import { AddgestionprofilComponent } from './addgestionprofil/addgestionprofil.component';
import { ModifgestionprofilComponent } from './modifgestionprofil/modifgestionprofil.component';
import { ReactiveFormsModule } from '@angular/forms';
import { MaterialModule } from 'src/@fury/shared/material-components.module';
import { BreadcrumbsModule } from 'src/@fury/shared/breadcrumbs/breadcrumbs.module';
import { MatInputModule } from '@angular/material';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { FuryCardModule } from 'src/@fury/shared/card/card.module';

@NgModule({
  declarations: [GestionprofilComponent, AddgestionprofilComponent, ModifgestionprofilComponent],
  imports: [
    
    CommonModule,
    ReactiveFormsModule,
    MaterialModule,
    BreadcrumbsModule,
    MatInputModule,
    NgxDatatableModule,
    FuryCardModule
  ],
})
export class GestionprofilModule { }
