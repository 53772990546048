import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'fury-statcommandemp',
  templateUrl: './statcommandemp.component.html',
  styleUrls: ['./statcommandemp.component.scss']
})
export class StatcommandempComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
