import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, FormArray, FormControl } from '@angular/forms';
import { Subscription } from 'rxjs';
import { RolefonctionDto } from 'src/app/models/rolefonctionDto';
import { Role } from 'src/app/models/Role';
import { Rolefonction } from 'src/app/models/rolefonction';
import { NGXLogger } from 'ngx-logger';
import { GestionprofilService } from '../gestionprofil.service';
import { FonctionService } from 'src/@fury/services/fonction.service';
import { RolefonctionService } from 'src/@fury/services/rolefonction.service';
import { MatSnackBar } from '@angular/material';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { AutreService } from 'src/@fury/services/autre.service';
import { Fonction } from 'src/app/models/fonction';
import { Agentstorage } from 'src/app/models/agentstorage';

@Component({
  selector: 'fury-modifgestionprofil',
  templateUrl: './modifgestionprofil.component.html',
  styleUrls: ['./modifgestionprofil.component.scss']
})
export class ModifgestionprofilComponent implements OnInit {


  public checkboxGroup: FormGroup;
  public fonction: any;
  getItemSub: Subscription;
  itemsFonctionGroupby: any;
  objToSend: RolefonctionDto = new RolefonctionDto();
  role: Role = new Role();
  paramRoleid: string;
  rolefonctionbyid: Rolefonction;
  public roleName: string = '';

  interests;
  listeASupprimer;

  constructor(private logger: NGXLogger, public fb: FormBuilder,
    private gestionprofilService: GestionprofilService,
    private fonctionService: FonctionService,
    private rolefonctionService: RolefonctionService,
    private snack: MatSnackBar,
    private route: Router,
    private activatedRoute: ActivatedRoute,
    public autreService: AutreService) { }

  ngOnInit() {

    // let tab = ['bleu', 'rouge', 'vert', 'bleu'];
    // let result = this.autreService.supprimerDoublon(tab);
    // this.logger.trace('RESULT', result);

    this.buildItemForm();

    this.getFonctionGroupbytype();

    // Verifier si un parametre est entrant
    this.activatedRoute.queryParams.forEach((params: Params) => {
      this.paramRoleid = params['profil'];
      this.logger.trace(params);
      // this.paramRoleid = params.profil;
      this.logger.trace(this.paramRoleid);
    });

    if (this.paramRoleid) {
      this.logger.trace('Parametre existant, modification');
      this.getRolefonctionByRoleid(this.paramRoleid);


    } else {
      this.logger.trace('Parametre non existante, ajout');

    }





  }

  buildItemForm() {

    this.checkboxGroup = this.fb.group({
      rolename: ['', []],
      //fonction: this.fb.array([])
      fonction: this.fb.array([]),
      fonctionSup: this.fb.array([])


    });

    this.interests = <FormArray>this.checkboxGroup.get('fonction') as FormArray;
    this.listeASupprimer = <FormArray>this.checkboxGroup.get('fonctionSup') as FormArray;



  }

  /**
   * Fonction pour coché les fonctions deja existantes
   */
  getResult(value: Fonction) {
    // this.logger.trace('valeur', value);
    for (const key in this.rolefonctionbyid) {
      if (this.rolefonctionbyid.hasOwnProperty(key)) {
        const element = this.rolefonctionbyid[key];
        // this.logger.trace('ELEMENT', element.fonction.fonctionname);
        // this.logger.trace('VALUE', value.fonctionname);
        if (value.fonctionname == element.fonction.fonctionname) {
          // this.logger.trace('TRUE');
          // this.logger.trace('VALUE', value);

          // this.interests = <FormArray>this.checkboxGroup.get('fonction') as FormArray;
          this.interests.push(new FormControl(value));
          return true;


        } else {
          // return false;
        }
        // value.fonctionname = element.fonction.fonctionname;

      }
    }




  }

  getRolefonctionByRoleid(roleid: string) {
    this.logger.debug('Obj reçu pour envoi au service', roleid);
    this.getItemSub = this.rolefonctionService.findbyRoleid(roleid)
      .subscribe(data => {

        let statuscode = data.status;
        this.logger.trace('DATA', data);

        switch (statuscode) {

          case 200: {
            this.rolefonctionbyid = data.body;
            this.roleName = data.body[0].role.rolename;
            this.logger.trace('roleName', this.roleName);

            this.logger.trace('rolefonctionbyid', this.rolefonctionbyid);

            break;
          }



          default: {
            this.snack.open('Une erreur est survenue!', 'OK', { duration: 4000 })

            break;
          }

        }
      },
        error => {
          this.logger.error("error");
          this.logger.error(error);

          switch (error.status) {

            case 401: {
              this.snack.open('Non authorisé', 'OK', { duration: 7000 })
              break;
            }

            default: {
              this.snack.open('Une erreur est survenue!', 'OK', { duration: 4000 })

              break;
            }
          }
        });
  }

  submit() {
    this.logger.trace('Debut enregistrement/modification de profil');
    this.logger.debug('Form value', this.checkboxGroup.value);
    this.objToSend = this.checkboxGroup.value;
    // this.role.rolename = this.checkboxGroup.value.rolename;
    this.role.rolename = this.roleName;
    this.objToSend.role = this.role;
    this.objToSend.rolename = this.roleName;


    this.logger.trace('Array without doublons', this.supprimerDoublonJSON(this.checkboxGroup.value.fonction));
    this.objToSend.fonction = this.supprimerDoublonJSON(this.checkboxGroup.value.fonction);
    // this.objToSend.fonction = this.autreService.supprimerDoublon(this.checkboxGroup.value.fonction);
    this.logger.debug('Obj to send', this.objToSend);

    let u = new Agentstorage();
    u = JSON.parse(localStorage.getItem("curentuser"));
    if (u != null) {
      this.objToSend.userpisteaudit = u.agent.agentemail + " " + u.agent.agentfirstname + " " + u.agent.agentlastname;
      this.logger.debug('++++++++++++++++++++++++++++++++++++++++++++++', this.objToSend);
    }

    if (this.paramRoleid) {
      // Fonction de modification
      this.modifierProfil(this.objToSend);
    } else {
      // Fonction d'enregistrement
      this.enregisterProfil(this.objToSend);
    }


  }

  enregisterProfil(obj: RolefonctionDto) {
    this.logger.debug('Obj reçu pour envoi au service', this.objToSend);
    this.getItemSub = this.rolefonctionService.createRoleFonction(obj)
      .subscribe(data => {

        let statuscode = data.status;
        this.logger.trace('DATA', data);

        switch (statuscode) {

          case 201: {
            this.snack.open('Creation reussi', 'OK', { duration: 4000 })
            // Retour vers la page de liste de profil
            this.route.navigate(['/yolinbo/gestionprofil']);

            break;
          }



          default: {
            this.snack.open('Une erreur est survenue!', 'OK', { duration: 4000 })

            break;
          }

        }
      },
        error => {
          this.logger.error("error");
          this.logger.error(error);

          switch (error.status) {

            case 401: {
              this.snack.open('Choisir au moins une fonctionnalité', 'OK', { duration: 7000 })
              break;
            }

            case 409: {
              this.snack.open('Le profil existe deja, essayé avec un autre nom', 'OK', { duration: 7000 })
              break;
            }

            default: {
              this.snack.open('Une erreur est survenue!', 'OK', { duration: 4000 })

              break;
            }
          }
        });
  }

  modifierProfil(obj: RolefonctionDto) {
    this.logger.debug('Obj reçu pour envoi au service', this.objToSend);
    this.getItemSub = this.rolefonctionService.modifierRoleFonction(obj)
      .subscribe(data => {

        let statuscode = data.status;
        this.logger.trace('DATA', data);

        switch (statuscode) {

          case 201: {
            this.snack.open('Modification reussi', 'OK', { duration: 4000 })
            // Retour vers la page de liste de profil
            this.route.navigate(['/yolinbo/gestionprofil']);

            break;
          }



          default: {
            this.snack.open('Une erreur est survenue!', 'OK', { duration: 4000 })

            break;
          }

        }
      },
        error => {
          this.logger.error("error");
          this.logger.error(error);

          switch (error.status) {

            case 401: {
              this.snack.open('Choisir au moins une fonctionnalité', 'OK', { duration: 7000 })
              break;
            }

            case 409: {
              this.snack.open('Le profil existe deja', 'OK', { duration: 7000 })
              break;
            }

            default: {
              this.snack.open('Une erreur est survenue!', 'OK', { duration: 4000 })

              break;
            }
          }
        });
  }

  onChange(event) {
    console.log(event)

    // this.interests = <FormArray>this.checkboxGroup.get('fonction') as FormArray;
    // console.log(this.interests)

    this.logger.trace('event', event.source.value);


    if (event.checked) {
      this.interests.push(new FormControl(event.source.value));
      const index = this.listeASupprimer.controls.findIndex(x => x.value === event.source.value);
      this.logger.trace('index', index);
      this.listeASupprimer.removeAt(index);
    } else {
      const i = this.interests.controls.findIndex(x => x.value === event.source.value);
      this.listeASupprimer.push(new FormControl(event.source.value));
      this.logger.trace('ListeASupprimer', this.listeASupprimer);
      this.interests.removeAt(i);
    }
  }


  getFonctionGroupbytype() {
    this.getItemSub = this.fonctionService.getFonctionGroupbytype()
      .subscribe(data => {

        switch (data.status) {

          default: {

            this.logger.trace('data', data);

            this.itemsFonctionGroupby = data.body;

            // this.fonction = data.body;
            // const formArray = this.checkboxGroup.get('fonction') as FormArray;
            // this.fonction.forEach(x => formArray.push(new FormControl(false)));

            break;
          }


        }
      },
        error => {
          this.logger.error('error');
          this.logger.error(error);

          switch (error.status) {

            default: {

              break;
            }
          }
        });
  }

  /**
 * Fonction pour supprimer les doublons dans un tableau d'objet JSON
 */
  public supprimerDoublonJSON(tableau: any[]) {
    let unique = {};
    let arr: Fonction[] = [];
    for (const key in tableau) {
      if (tableau.hasOwnProperty(key)) {
        const element = tableau[key];
        if (!unique[element.fonctionname]) {
          unique[element.fonctionname] = true;
          arr.push(element);
        }
      }
    }
    return arr;
  }



}
