import { Component, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { MatDialog, MatSnackBar } from '@angular/material';
import { Router } from '@angular/router';
import { ConfigService } from 'ngx-envconfig';
import { NGXLogger } from 'ngx-logger';
import { Subscription } from 'rxjs';
import { ConfirmationService } from 'src/app/confirm/confirmation.service';
import { ApploaderService } from 'src/app/services/apploader/apploader.service';
import { DeliveryService } from 'src/app/services/delivery.service';
import { GenericserviceService } from 'src/app/services/genericservice.service';
import { ShowDeliveryAccountSoldeComponent } from './show-delivery-account-solde/show-delivery-account-solde.component';

@Component({
  selector: 'fury-gestionsolde',
  templateUrl: './gestionsolde.component.html',
  styleUrls: ['./gestionsolde.component.scss']
})
export class GestionsoldeComponent implements OnInit {



  public items: any[] = [];
  temp = [];
  rows = [];
  columns = [];
  scrollBarHorizontal = (window.innerWidth < 1200);
  public getItemSub: Subscription;

  attente : any = "EN ATTENTE" ;

  // Habilitation
  public creationLivreur: boolean;

  // expandable
  @ViewChild('myTable') table: any;
  expanded: any = {};
  timeout: any;

  modelivraison: any[] = [

    {
      "libele": "TOUS LES LIVREURS",
      "values": "all"
    },
    {
      "libele": "LIVREUR EN ATTENTE",
      "values": "waiting"
    },

    {
      "libele": "LIVREUR DISPONIBLE",
      "values": "open"
    }

  ]

  public itemForm: FormGroup;



  constructor(private logger: NGXLogger,
    private route: Router,
    private dialog: MatDialog,
    public formBuilder: FormBuilder,
    private snackbar: MatSnackBar,
    private confirmService: ConfirmationService,
    private utilisateurService: GenericserviceService,
    private deliveryService: DeliveryService,
    private loader: ApploaderService,


    private fb: FormBuilder,
    private snack: MatSnackBar,

    private configService: ConfigService) { }


  ngOnInit() {





    this.getAll();
  }


  updateFilter(event) {
    const val = event.target.value.toLowerCase();
    var columns = Object.keys(this.temp[0]);
    // Removes last "$$index" from "column"
    columns.splice(columns.length - 1);

    // console.log(columns);
    if (!columns.length)
      return;

    const rows = this.temp.filter(function (d) {
      for (let i = 0; i <= columns.length; i++) {
        let column = columns[i];
        // console.log(d[column]);
        if (d[column] && d[column].toString().toLowerCase().indexOf(val) > -1) {
          return true;
        }
      }
    });

    this.items = rows;
  }




  saveSolde(transaction: any, etat: any) {

    let message = "";

    if (etat == 1) {
      message = "Voulez vous valider cette transaction ?";
    } else {
      message = "Voulez vous invalider cette transaction ?";
    }


    transaction.etat = etat;

    this.confirmService.confirm({ message: message })
      .subscribe(res => {

        console.log(res);
        if (!res) {
          return;
        }


        this.deliveryService.post(transaction, "livreurorder/soldedebit/check").subscribe(data => {
          this.logger.trace("***************************");

          this.logger.trace(data);

          this.logger.trace("***************************");

          switch (data.status) {
            case 200: {
              this.getAll();
              this.snackbar.open('Succes', 'OK', {
                duration: 10000
              });
              break;
            }


          }
        },
          error => {
            this.logger.trace("erreur retour");
            // this.loading.dismiss('messagesService.loadMessagesOverview');
            switch (error.status) {

              case 417: {

                // this.FormReg(" erreur de connexion !!! Veuillez ressayer");

                this.snackbar.open('Echec !  le livreur a un solde inferieur', 'OK', {
                  duration: 10000
                });
                break;
              }

              case 406: {

                // this.FormReg(" erreur de connexion !!! Veuillez ressayer");

                this.snackbar.open('Echec !  erreur gateway', 'OK', {
                  duration: 10000
                });
                break;
              }


              default: {

                // this.FormReg(" erreur de connexion !!! Veuillez ressayer");
                this.logger.error("Check API", error);

                this.snackbar.open('Echec', 'OK', {
                  duration: 10000
                });
                break;
              }
            }
          }
        );
      });
  }


  opendetail(value: any) {


    this.dialog.open(ShowDeliveryAccountSoldeComponent,
      {
        width: '720px',
        data: { title: '', payload: value }
      }
    ).afterClosed().subscribe((res) => {
      console.log(res);
      if (!res) {
        return;
      }
    });
  }


  getAll() {
    this.getItemSub = this.deliveryService.get("fileattente/delivery/findalltransactionsolde")
      .subscribe(data => {

        switch (data.status) {

          default: {

            this.logger.trace('data', data.body);
            let response: any[] = data.body;
            this.logger.trace('response', response);

            let resp1: any[] = response;

            this.items = this.temp = resp1;
            // this.dataSource = new MatTableDataSource(this.items);

            break;
          }


        }
      },
        error => {
          console.log('error');
          console.log(error);

          switch (error.status) {

            default: {

              break;
            }
          }
        });
  }


  goToRounting(value) {
    this.route.navigateByUrl(value);
  }

}
