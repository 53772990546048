import { Component, Inject, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { NGXLogger } from 'ngx-logger';
import { Subscription } from 'rxjs';
import { Clienttype } from 'src/app/models/Clienttype';
import { GestioncorporateService } from '../../gestioncorporate/gestioncorporate.service';

@Component({
  selector: 'fury-addclient',
  templateUrl: './addclient.component.html',
  styleUrls: ['./addclient.component.scss']
})
export class AddclientComponent implements OnInit {

  public getItemSub: Subscription;
  client: Clienttype;
  form: FormGroup;
  corporate_id: any;
  constructor(@Inject(MAT_DIALOG_DATA) public defaults: any,
    public dialogRef: MatDialogRef<AddclientComponent>,
    private fb: FormBuilder, private pointservice: GestioncorporateService,
    private logger: NGXLogger) {
  }

  ngOnInit() {
    this.getAllCorporate();
    this.form = this.fb.group({
      clientcivilite: ['', Validators.compose([Validators.required])],
      clientemail: ['', Validators.compose([Validators.required, Validators.email])],
      clientfirstname: ['', Validators.compose([Validators.required])],
      clientlastname: ['', Validators.compose([Validators.required])],
      clienttelephone: ['', Validators.compose([Validators.required])],
      clientmatricule: ['', Validators.compose([Validators.required])]
    });
  }

  onSelectCorporate(item) {
    localStorage.setItem('idCorporate', item.source.value);
  }

  getAllCorporate() {
    this.getItemSub = this.pointservice.get('clienttype')
      .subscribe(data => {
        const statuscode = data.status;
        this.logger.trace('DATA', data);
        switch (statuscode) {
          case 200: {
            console.log('**************');
            this.client = data.body;
            console.log(this.client);
            // this.temp = data.body;
            break;
          }

          case 204: {
            // this.snack.open('Aucune TVA disponible', 'OK', { duration: 4000 })
            break;
          }

          default: {
            // this.snack.open('Une erreur est survenue!', 'OK', { duration: 4000 })

            break;
          }

        }
      },
        error => {
          this.logger.error('error');
          this.logger.error(error);

          switch (error.status) {

            default: {

              //    this.snack.open('Une erreur est survenue!', 'OK', { duration: 4000 })

              break;
            }
          }
        });
  }



  submit() {
    this.dialogRef.close(this.form.value);
  }

}
