import { DeletedialogpaymentComponent } from './deletedialogpayment/deletedialogpayment.component';
import { PaymentmodeModifierComponent } from './paymentmode-modifier/paymentmode-modifier.component';
import { Router } from '@angular/router';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { PaymentmodeService } from './../../../services/paymentmode.service';
import { MatSort, MatPaginator, MatSnackBar, MatDialog, MatTableDataSource } from '@angular/material';
import { Component, OnInit, ViewChild } from '@angular/core';
import { Paymentmode } from 'src/app/models/Paymentmode';

@Component({
  selector: 'fury-paymentmode',
  templateUrl: './paymentmode.component.html',
  styleUrls: ['./paymentmode.component.scss']
})
export class PaymentmodeComponent implements OnInit {


  private paymentmodes: Paymentmode[];
  private selectedValue: Paymentmode;
  paymentmodeForm: FormGroup;


  paymentmodeId: string;

  paymentmodename: string;
  paymentmodeisdeleted: boolean;
  paymentmodedatecreation: string;
  paymentmode: Paymentmode;

  displayedColumns: string[] = ['paymentmodename', 'paymentmodedatecreation', 'paymentmodecode', 'actions'];
  dataSource: any;


  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  constructor(private paymentmodeService: PaymentmodeService, private formBuilder: FormBuilder, private snackbar: MatSnackBar, public dialog: MatDialog) {
  }



  ngOnInit() {
    this.findAll();


    this.paymentmodeForm = new FormGroup({
      paymentmodename: new FormControl('', Validators.required),
      paymentmodecode: new FormControl('', Validators.required)


    });

    this.paymentmodeForm = this.formBuilder.group({
      paymentmodename: ['', Validators.required],
      paymentmodecode: ['', Validators.required]

    })


  }


  onSubmit() {

    console.info('-----------------------------------------');


    if (this.paymentmodeForm.valid) {
      // saving object

      this.paymentmodeService.save(this.paymentmodeForm.controls['paymentmodename'].value,
        this.paymentmodeForm.controls['paymentmodecode'].value).subscribe(data => {


          console.log(data);
          switch (data.status) {
            case 201: {
              // this.loading.dismiss('messagesService.loadMessagesOverview');

              let resp: Paymentmode;
              resp = data.body;
              console.log(resp);
              console.log("------------------------------------");
              this.snackbar.open('Success mode de payment Créé', 'OK', {
                duration: 2000
              });


              if (resp != null) {

                console.log("-------------- success paymentmode created ----------------");
                this.findAll();
                //this.router.navigateByUrl('./');

                // this.router.navigateByUrl('/dashboard');
              } else {
                // this.FormReg(" Mot de passe ou login incorrect ");
              }
              break;
            }
          }
        },
          error => {
            console.log("erreur retour");
            // this.loading.dismiss('messagesService.loadMessagesOverview');
            switch (error.status) {
              case 409:
                //this.FormReg(" Mot de passe ou login incorrect ");
                this.snackbar.open('Echec creation', 'Le mode de payment existe deja', {
                  duration: 2000
                });
                break;
              case 401:
                //this.FormReg(" Mot de passe ou login incorrect ");
                break;
              default: {
                // this.FormReg(" erreur de connexion !!! Veuillez ressayer");
                this.snackbar.open('Echec creation', 'OK', {
                  duration: 2000
                });
                break;
              }
            }

          });

      this.paymentmodeForm.reset({ paymentmodename: '' });

      /////////////
    } else {
      this.validateAllFormFields(this.paymentmodeForm);
      this.snackbar.open('Veuillez saisir toutes les valeurs svp', 'OK', {
        duration: 2000
      });



    }




  }

  validateAllFormFields(formGroup: FormGroup) {
    Object.keys(formGroup.controls).forEach(field => {
      const control = formGroup.get(field);
      if (control instanceof FormControl) {
        control.markAsTouched({ onlySelf: true });
      } else if (control instanceof FormGroup) {
        this.validateAllFormFields(control);
      }
    });
  }


  findAll() {

    this.paymentmodeService.findAll().subscribe(data => {
      console.log(data);
      switch (data.status) {
        case 200: {
          // this.loading.dismiss('messagesService.loadMessagesOverview');
          let resp: Paymentmode[];
          resp = data.body;
          console.log("------------------------------------");
          console.log(resp);
          console.log("------------------------------------");
          console.log(resp[0].paymentmodedatecreation);
          this.paymentmodes = resp;
          // this.dataSource = valeurs;
          this.dataSource = new MatTableDataSource<Paymentmode>(resp);
          this.dataSource.paginator = this.paginator;
          this.dataSource.sort = this.sort;


          if (resp != null) {
            //this.resetFunction();


            console.log("------------- paymentmodes founded ------------------");

          } else {

            // this.FormReg(" Mot de passe ou login incorrect ");
          }
          break;
        }
      }
    },
      error => {
        console.log("erreur retour");
        // this.loading.dismiss('messagesService.loadMessagesOverview');
        switch (error.status) {
          case 401:
            //this.FormReg(" Mot de passe ou login incorrect ");
            break;
          default: {
            // this.FormReg(" erreur de connexion !!! Veuillez ressayer");
            break;
          }
        }
      }
    );

  }


  updatePaymentmode(row) {
    console.log(' edit paymentmode ');

    if (this.paymentmodeId) {
      console.log('id ok ----editing ');

      let paymentmode: Paymentmode = new Paymentmode();
      paymentmode.paymentmodeid = this.paymentmodeId;
      paymentmode.paymentmodename = this.paymentmodeForm.controls['paymentmodename'].value;
      // this.valeurForm.controls['paymentmodeLastName'].value,
      // this.valeurForm.controls['paymentmodeEmail'].value;

      // updating object
      this.paymentmodeService.update(paymentmode).subscribe();
    }


  }


  deletePaymentMode(row) {
    console.log('-- Delete paymentmode ---  ' + row);

    if (row) {
      let paymentmode: Paymentmode = row;
      this.openDialogDeletes(paymentmode);
    }
  }







  openDialogDelete(paymentmode): void {
    const dialogRef = this.dialog.open(DeletedialogpaymentComponent, {
      width: '250px',
      data: { paymentmode: paymentmode }
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed' + result);
      //this.animal = result;
      this.findAll()
    });
  }

  openDialogDeletes(paymentmode): void {
    this.dialog.open(DeletedialogpaymentComponent, {
      width: '450px',
      data: { paymentmode: paymentmode }
    }).afterClosed().subscribe((res) => {
      console.log(res);

      if (!res) {
        return;
      }

      this.paymentmodeService.delete(res).subscribe(
        data => {
          console.log('-- Delete livraison response ---  ' + data.statusText + ' ' + data.status);

          switch (data.status) {
            case 200: {
              this.snackbar.open('Success supprimé', 'OK', {
                duration: 2000
              });
              this.findAll();
              break;
            }
            default:
              this.snackbar.open('Echec suppression, paiement not found', 'veuillez rafraichir la page svp', {
                duration: 2000
              });
              this.findAll();
          }
        }
      );


    });
  }


  openDialog(row): void {
    //console.log('------onpen dialog -------');

    console.log(row);
    this.selectedValue = row;
    if (row) {
      this.callPaymentmodeDetail();
    }
  }


  callPaymentmodeDetail() {
    console.log('------onpen dialog -------');

    const dialogRef = this.dialog.open(PaymentmodeModifierComponent, {
      maxWidth: '80%',

      data: { paymentmode: this.selectedValue }
    });

    dialogRef.afterClosed().subscribe(resp => {

      console.log('The dialog was closed .. resp --- ' + resp);



      this.findAll();
      console.log('======================');
      // this.paymentmode = result;

    });
  }


}
