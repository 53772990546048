import { HttpClient, HttpResponse, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ConfigService } from 'ngx-envconfig';
import { NGXLogger } from 'ngx-logger';
import { Observable } from 'rxjs';
import { Agentstorage } from 'src/app/models/agentstorage';

@Injectable({
  providedIn: 'root'
})
export class HistodecouvertService {

  private url = this.configService.get('ipMS') + 'parameter/';

  // parameter/tva/getall

  authorization: string;

  constructor(private http: HttpClient, private configService: ConfigService,
    private logger: NGXLogger) {
      let u = new Agentstorage();
      u = JSON.parse(localStorage.getItem('curentuser'));
      if (u != null) {
        this.authorization = u.token;
        console.log(this.authorization);
      }
  }

  public post(value: any, path: string): Observable<HttpResponse<any>> {
    return this.http.post(this.url + '' + path, JSON.stringify(value), {
      observe: 'response',
      headers: new HttpHeaders({ 'Access-Control-Allow-Origin': '*' })
        .set('content-Type', 'application/json')
        .set('Authorization', this.authorization)
    });
  }


  public put(value: any, path: string): Observable<HttpResponse<any>> {
    return this.http.put(this.url + '' + path, JSON.stringify(value), {
      observe: 'response',
      headers: new HttpHeaders({ 'Access-Control-Allow-Origin': '*' })
        .set('content-Type', 'application/json')
        .set('Authorization', this.authorization)
    });
  }

  delete(path: string): Observable<HttpResponse<any>> {
    return this.http.delete(this.url + '' + path, {
      observe: 'response',
      headers: new HttpHeaders({ 'Access-Control-Allow-Origin': '*' })
        .set('content-Type', 'application/json')
        .set('Authorization', this.authorization)
    });
  }

  get(path: string): Observable<HttpResponse<any>> {
    return this.http.get(this.url + '' + path, {
      observe: 'response',
      headers: new HttpHeaders({ 'Access-Control-Allow-Origin': '*' })
        .set('content-Type', 'application/json')
        .set('Authorization', this.authorization)
    });
  }
}
