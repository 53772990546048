import { Component, OnInit, Inject } from '@angular/core';
import { DateAdapter } from 'angular-calendar';
import { MomentDateAdapter } from '@angular/material-moment-adapter';
import { MomentDatetimeAdapter } from '@mat-datetimepicker/moment'
import { DatetimeAdapter, MAT_DATETIME_FORMATS } from '@mat-datetimepicker/core';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { Subscription } from 'rxjs';
import { Moment, utc } from 'moment';
import { MAT_DIALOG_DATA, MatDialogRef, MatSnackBar } from '@angular/material';
import { GestionutilisateurService } from '../gestionutilisateur.service';
import { NGXLogger } from 'ngx-logger';
import { RoleService } from 'src/@fury/services/role.service';
import { ConfirmationService } from 'src/app/confirm/confirmation.service';
import { Agent } from 'src/app/models/Agent';
import { CustomValidators } from 'ng2-validation';
import { PopupgestionutilisateurComponent } from '../popupgestionutilisateur/popupgestionutilisateur.component';
import { DatePipe } from '@angular/common';
import { Agentstorage } from 'src/app/models/agentstorage';

@Component({
  selector: 'fury-popupupdategestionutilisateur',
  templateUrl: './popupupdategestionutilisateur.component.html',
  styleUrls: ['./popupupdategestionutilisateur.component.scss'],
  providers: [
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter
    },
    {
      provide: DatetimeAdapter,
      useClass: MomentDatetimeAdapter
    },
    {
      provide: MAT_DATETIME_FORMATS,
      useValue: {
        parse: {
          dateInput: "L",
          monthInput: "MMMM",
          timeInput: "LT",
          datetimeInput: "L LT"
        },
        display: {
          dateInput: "L",
          monthInput: "MMMM",
          datetimeInput: "L LT",
          timeInput: "LT",
          monthYearLabel: "MMM YYYY",
          dateA11yLabel: "LL",
          monthYearA11yLabel: "MMMM YYYY",
          popupHeaderDateLabel: "ddd, DD MMM"
        }
      }
    }
  ]

})
export class PopupupdategestionutilisateurComponent implements OnInit {



  form: FormGroup;
  public getRoleSub: Subscription;
  // roles: Role = new Role();
  roles: any;
  today: Moment;
  currentYear: number;
  telephone: string;
  email: string;



  hide = true;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any, private datePipe: DatePipe,
    public dialogRef: MatDialogRef<PopupgestionutilisateurComponent>,
    private fb: FormBuilder, private utilisateurService: GestionutilisateurService,
    private snack: MatSnackBar, private logger: NGXLogger,
    private roleService: RoleService, private confirmService: ConfirmationService
  ) { }

  ngOnInit() {

    // Obtenir l'année en cours
    this.currentYear = new Date(Date.now()).getFullYear();
    // Assigner date d'aujourdhui à la variable
    this.today = utc().year(this.currentYear);
    this.logger.trace('today', this.today);

    this.getallRole();
    this.buildItemForm(this.data.payload);

  }

  buildItemForm(item) {
    this.logger.trace('ITEM', item);
    const agentpassword = new FormControl('', Validators.required);
    const agentconfirmpassword = new FormControl('', CustomValidators.equalTo(agentpassword));
    // new FormControl({value: c.cessiontransactionid, disabled: true}) 
    this.telephone = item.agenttelephone;
    this.email = item.agentemail;

    let agentdatefin: any = '';
    if (item.agentdatefin) {
      agentdatefin = this.datePipe.transform(item.agentdatefin, "yyyy-MM-dd");
    }
    this.logger.trace("agentdatefin", agentdatefin);

    this.form = this.fb.group({
      agentid: [item.agentid || ''],
      //agentemail: new FormControl({ value: item.agentemail, disabled: true }),
      agentemail: [item.agentemail || '', [Validators.required]],
      agentfirstname: [item.agentfirstname || '', [Validators.required]],
      agentlastname: [item.agentlastname || '', []],
      agentpassword: '',
      agenttelephone: [item.agenttelephone || '', [Validators.required]],
      agentdatefin: [agentdatefin],
      role: [item.role.rolename || '', [Validators.required]],
      agentisactive: [item.agentisactive || false || '', []]
    });

    console.log(" ********************************* ");
    console.log(this.form.status);



  }

  submit() {

    // Verifier si l'id est null (ajout)
    this.logger.debug('Form value', this.form.value);
    let agentid = this.form.value.agentid;
    this.logger.debug('Form value', agentid);

    let res: Agent = this.form.value;
    res.roleName = this.form.value.role;
    this.logger.trace('Agent obj', res);
    res.agentemail = this.email;
    res.agenttelephone = res.agenttelephone;
    res.agentpassword = "password";

    let u = new Agentstorage();
    u = JSON.parse(localStorage.getItem("curentuser"));
    if (u != null) {
      res.userpisteaudit = u.agent.agentemail + " " + u.agent.agentfirstname + " " + u.agent.agentlastname;
    }

    if (!agentid || agentid == '') {
      // Ajout
      this.utilisateurService.createUser(res)
        .subscribe(data => {
          let statuscode = data.status;

          switch (statuscode) {
            case 200: {
              // this.getItems();
              this.dialogRef.close(this.form.value);
              this.snack.open('Agent Ajouté!', 'OK', { duration: 4000 })
              break;
            }
            case 201: {
              // this.getItems();
              this.dialogRef.close(this.form.value);
              this.snack.open('Agent Ajouté!', 'OK', { duration: 4000 })
              break;
            }
            case 401: {
              this.snack.open('Unauthorized, One or several parameters are not informed', 'OK', { duration: 4000 })
              break;
            }
            case 406: {
              this.snack.open('Not Acceptable, Incorrect Format of the parameters', 'OK', { duration: 4000 })
              break;
            }
            case 409: {
              this.snack.open('Conflict, Object Already exist', 'OK', { duration: 4000 })
              break;
            }
            case 500: {
              this.snack.open('Internal server error!', 'OK', { duration: 4000 })
              break;
            }
            default: {
              this.snack.open('Une erreur est survenue!', 'OK', { duration: 4000 })
              break;
            }
          }

        },
          error => {
            let statuscode = error.status;
            this.logger.error('error', error);
            switch (statuscode) {
              case 401: {
                this.snack.open('Unauthorized, One or several parameters are not informed', 'OK', { duration: 4000 })
                break;
              }
              case 406: {
                this.snack.open('Not Acceptable, Incorrect Format of the parameters', 'OK', { duration: 4000 })
                break;
              }
              case 409: {
                this.snack.open('Conflict, Object Already exist', 'OK', { duration: 4000 })
                break;
              }
              case 500: {
                this.snack.open('Internal server error!', 'OK', { duration: 4000 })
                break;
              }
              default: {
                this.snack.open('Une erreur est survenue!', 'OK', { duration: 4000 })
                break;
              }
            }

          });

    } else {
      // Modif
      this.utilisateurService.updateUser(res)
        .subscribe(data => {
          let statuscode = data.status;

          switch (statuscode) {
            case 200: {
              // Actualisé les données
              // this.getItems();
              this.dialogRef.close(this.form.value);
              this.snack.open('Agent modifié!', 'OK', { duration: 4000 });
              break;
            }
            case 201: {
              // Actualisé les données
              // this.getItems();
              this.dialogRef.close(this.form.value);
              this.snack.open('Agent modifié!', 'OK', { duration: 4000 });
              break;
            }
            case 401: {
              this.snack.open('Unauthorized, One or several parameters are not informed', 'OK', { duration: 4000 })
              break;
            }
            case 406: {
              this.snack.open('Not Acceptable, Incorrect Format of the parameters', 'OK', { duration: 4000 })
              break;
            }
            case 409: {
              this.snack.open('Conflict, Object Already exist', 'OK', { duration: 4000 })
              break;
            }
            case 500: {
              this.snack.open('Internal server error!', 'OK', { duration: 4000 })
              break;
            }
            default: {
              this.snack.open('Erreur!', 'OK', { duration: 4000 })
              break;
            }

          }

        },
          error => {
            let statuscode = error.status;
            this.logger.error('error', error);

            switch (statuscode) {
              case 401: {
                this.snack.open('Unauthorized, One or several parameters are not informed', 'OK', { duration: 4000 })
                break;
              }
              case 406: {
                this.snack.open('Not Acceptable, Incorrect Format of the parameters', 'OK', { duration: 4000 })
                break;
              }
              case 409: {
                this.snack.open('Conflict, Object Already exist', 'OK', { duration: 4000 })
                break;
              }
              case 404: {
                this.snack.open('URL introuvable!', 'OK', { duration: 4000 })
                break;
              }
              case 500: {
                this.snack.open('Internal server error!', 'OK', { duration: 4000 })
                break;
              }
              default: {
                this.snack.open('Une erreur est survenue!', 'OK', { duration: 4000 })
                break;
              }
            }
          });
    }

  }

  getallRole() {
    this.getRoleSub = this.roleService.getRole()
      .subscribe(data => {

        switch (data.status) {

          default: {

            this.logger.trace('role data', data);

            this.roles = data.body;

            break;
          }


        }
      },
        error => {
          console.log('error');
          console.log(error);

          switch (error.status) {

            default: {

              break;
            }
          }
        });
  }

  deleteItem(row) {

    this.confirmService.confirm({ message: `Supprimé ${row.categoriename}?` })
      .subscribe(res => {
        if (res) {
          this.utilisateurService.deleteUser(row.categorieid)
            .subscribe(data => {
              let statuscode = data.status;
              switch (statuscode) {
                case 200: {
                  // Actualisé les données
                  this.getallRole();
                  this.snack.open('Catégorie Supprimé!', 'OK', { duration: 4000 })
                  break;
                }
                case 500: {
                  this.snack.open('Internal server error!', 'OK', { duration: 4000 })
                  break;
                }
                default: {
                  this.snack.open('Une erreur est survenue!', 'OK', { duration: 4000 })
                  break;
                }
              }

            },
              error => {
                let statuscode = error.status;
                switch (statuscode) {
                  case 404: {
                    this.snack.open('Not found!', 'OK', { duration: 4000 })
                    break;
                  }
                  case 500: {
                    this.snack.open('Internal server error!', 'OK', { duration: 4000 })
                    break;
                  }
                  default: {
                    this.snack.open('Une erreur est survenue!', 'OK', { duration: 4000 })
                    break;
                  }
                }

              });
        }


      })
  }


}
