import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { ConfigService } from 'ngx-envconfig';

@Component({
  selector: 'fury-show-delivery-account-solde',
  templateUrl: './show-delivery-account-solde.component.html',
  styleUrls: ['./show-delivery-account-solde.component.scss']
})
export class ShowDeliveryAccountSoldeComponent implements OnInit {

 

  item: any;


   constructor(@Inject(MAT_DIALOG_DATA) public data: any,
    private configService: ConfigService,
    private dialogRef: MatDialogRef<ShowDeliveryAccountSoldeComponent>) {
    this.item = this.data.payload;

  }



  ngOnInit() {
  }


}
