import { GestionagentComponent } from './pages/gestionagent/gestionagent.component';
import { RolesComponent } from './pages/parametrages/roles/roles.component';
import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { LayoutComponent } from './layout/layout.component';
import { GestionmenuComponent } from './pages/gestionmenu/gestionmenu.component';
import { GestioncommandeComponent } from './pages/gestioncommande/gestioncommande.component';
import { GestioncaissiereComponent } from './pages/gestioncaissiere/gestioncaissiere.component';
// import { ParametragesComponent } from './pages/parametrages/parametrages.component';
import { MonprofilComponent } from './pages/monprofil/monprofil.component';
import { ParametragesComponent } from './pages/parametrages/parametrages.component';
import { ModelivraisonComponent } from './pages/parametrages/modelivraison/modelivraison.component';
import { CategorieComponent } from './pages/parametrages/categorie/categorie.component';
import { PaymentmodeComponent } from './pages/parametrages/paymentmode/paymentmode.component';
import { TypeclientComponent } from './pages/parametrages/typeclient/typeclient.component';
import { GestionlivreurComponent } from './pages/gestionlivreur/gestionlivreur.component';


import { GestionprofilComponent } from './pages/gestionprofil/gestionprofil.component';
import { AddgestionprofilComponent } from './pages/gestionprofil/addgestionprofil/addgestionprofil.component';
import { ModifgestionprofilComponent } from './pages/gestionprofil/modifgestionprofil/modifgestionprofil.component';
import { GestionutilisateurComponent } from './pages/gestionutilisateur/gestionutilisateur.component';
import { PisteauditComponent } from './pages/pisteaudit/pisteaudit.component';
import { CategoriemenuComponent } from './pages/categoriemenu/categoriemenu.component';
import { AuthGuard } from './auth/auth.guard';
import { GestionlivraisonComponent } from './pages/gestionlivraison/gestionlivraison.component';
import { GestionclientComponent } from './pages/gestionclient/gestionclient.component';
import { CarteabonnementComponent } from './pages/carteabonnement/carteabonnement.component';
import { CartestatusComponent } from './pages/cartestatus/cartestatus.component';
import { HistoriquecarteComponent } from './pages/historiquecarte/historiquecarte.component';

import { CarteabonnementfileComponent } from './pages/carteabonnementfile/carteabonnementfile.component';
import { TvaComponent } from './pages/tva/tva.component';
import { HeurecmdComponent } from './pages/heurecmd/heurecmd.component';
import { AttenteComponent } from './pages/attente/attente.component';
import { AnnulationComponent } from './pages/annulation/annulation.component';
import { JournalcaisseComponent } from './pages/journalcaisse/journalcaisse.component';
import { CreditercarteComponent } from './pages/creditercarte/creditercarte.component';
import { GestionparametreComponent } from './pages/gestionparametre/gestionparametre.component';
import { CartemasterloadbalanceComponent } from './pages/cartemasterloadbalance/cartemasterloadbalance.component';
import { CartemasterloadcreditComponent } from './pages/cartemasterloadcredit/cartemasterloadcredit.component';
import { CartemasterhistoriqueComponent } from './pages/cartemasterhistorique/cartemasterhistorique.component';
import { StatperiodiqueComponent } from './pages/statperiodique/statperiodique.component';
import { GestionpointventeComponent } from './pages/gestionpointvente/gestionpointvente.component';
import { GestionpointlivraisonComponent } from './pages/gestionpointlivraison/gestionpointlivraison.component';
import { StatistiqueproductComponent } from './pages/statistiqueproduct/statistiqueproduct.component';
import { StatistiqueventeComponent } from './pages/statistiquevente/statistiquevente.component';
import { GestioncorporateComponent } from './pages/gestioncorporate/gestioncorporate.component';
import { FormuleAbonnementComponent } from './pages/formuleabonnement/formuleabonnement.component';
import { DemandeAbonnementComponent } from './pages/demandeabonnement/demandeabonnement.component';
import { GestionstatuserComponent } from './pages/gestionstatuser/gestionstatuser.component';
import { AbonnementmasseComponent } from './pages/abonnementmasse/abonnementmasse.component';
import { HistabnComponent } from './pages/abonnementmasse/histabn/histabn.component';
import { DecouvertComponent } from './pages/decouvert/decouvert.component';
import { TypecarteComponent } from './typecarte/typecarte.component';
import { EnabledemandeComponent } from './pages/demandedrh/enabledemande/enabledemande.component';
import { DisabledemandeComponent } from './pages/demandedrh/disabledemande/disabledemande.component';
import { GivecarddemandeComponent } from './pages/demandedrh/givecarddemande/givecarddemande.component';
import { AbbatementComponent } from './pages/abbatement/abbatement.component';
import { CategoriecorpoComponent } from './pages/categoriecorpo/categoriecorpo.component';
import { CatcorpoformuleComponent } from './pages/catcorpoformule/catcorpoformule.component';
import { PasstocorpComponent } from './pages/demandeabonnement/passtocorp/passtocorp.component';
import { RenewcardComponent } from './pages/demandeabonnement/renewcard/renewcard.component';
import { DemandedisableprecompteComponent } from './pages/demandedisableprecompte/demandedisableprecompte.component';
import { DemandequitcrptComponent } from './pages/demandequitcrpt/demandequitcrpt.component';
import { PrecptedemandeComponent } from './pages/precptedemande/precptedemande.component';
import { HistoriquecardmasterComponent } from './pages/gestioncorporate/historiquecardmaster/historiquecardmaster.component';
import { StatforproductComponent } from './pages/statforproduct/statforproduct.component';
import { CloturecaisseComponent } from './pages/cloturecaisse/cloturecaisse.component';
import { HistodecouvertComponent } from './pages/histodecouvert/histodecouvert.component';
import { Pointlivraison } from './models/pointvente';
import { PointlivraisonzoneComponent } from './pages/gestionpointlivraison/pointlivraisonzone/pointlivraisonzone.component';
import { FileattenteComponent } from './pages/fileattente/fileattente.component';
import { WaitingcmdComponent } from './pages/gestionlivreur/waitingcmd/waitingcmd.component';
import { SpecialorderComponent } from './pages/specialorder/specialorder.component';
import { GestionstartComponent } from './pages/gestionstart/gestionstart.component';
import { GestionsoldeComponent } from './pages/gestionsolde/gestionsolde.component';
import { GestionincidentComponent } from './pages/gestionincident/gestionincident.component';
import { GestionperteComponent } from './pages/gestionperte/gestionperte.component';
import { SimplificationcorporateComponent } from './pages/simplificationcorporate/simplificationcorporate.component';




const routes: Routes = [
  {
    path: '',
    loadChildren: './pages/authentication/login/login.module#LoginModule',
  },
  {
    path: 'register',
    loadChildren: './pages/authentication/register/register.module#RegisterModule',
  },
  {
    path: 'forgot-password',
    loadChildren: './pages/authentication/forgot-password/forgot-password.module#ForgotPasswordModule',
  },
  /* {
     path: 'coming-soon',
     loadChildren: './pages/coming-soon/coming-soon.module#ComingSoonModule',
   },*/
  {
    path: 'yolinbo',
    canActivate: [AuthGuard],
    component: LayoutComponent,
    children: [
      {
        path: 'dashboard',
        loadChildren: './pages/dashboard/dashboard.module#DashboardModule',
        pathMatch: 'full'
      },
      {
        path: 'gestionattente',
        component: AttenteComponent
      },
      {
        path: 'gestionannulation',
        component: AnnulationComponent
      },
      {
        path: 'gestionpointvente',
        component: GestionpointventeComponent
      },
      {
        path: 'journalcaisse',
        component: JournalcaisseComponent
      },
      {
        path: 'loadbalance',
        component: CartemasterloadbalanceComponent
      },
      {
        path: 'loadcredit',
        component: CartemasterloadcreditComponent
      },
      {
        path: 'cartemasterhistorique',
        component: CartemasterhistoriqueComponent
      },
      {
        path: 'statperiodique',
        component: StatperiodiqueComponent
      },
      {
        path: 'statforproduct',
        component: StatforproductComponent
      },
      {
        path: 'statuser',
        component: GestionstatuserComponent
      },
      {
        path: 'specialorder',
        component: SpecialorderComponent
      },
      {
        path: 'decouvert',
        component: DecouvertComponent
      },
      {
        path: 'typecarte',
        component: TypecarteComponent
      },

      {
        path: 'categoriecorp',
        component: CategoriecorpoComponent
      },
      {
        path: 'catcorpformule',
        component: CatcorpoformuleComponent
      },
      {
        path: 'passtocorporate',
        component: PasstocorpComponent
      },
      {
        path: 'renewcard',
        component: RenewcardComponent
      },
      {
        path: 'histodecouvert',
        component: HistodecouvertComponent
      },
      {
        path: 'gestionparametres',
        component: ParametragesComponent,
        children: [{
          path: 'roles',
          component: RolesComponent
        },

        {
          path: 'modelivraison',
          component: ModelivraisonComponent
        },
        {
          path: 'categorie',
          component: CategorieComponent
        },
        {
          path: 'paymentmode',
          component: PaymentmodeComponent
        },
        {
          path: 'typeclient',
          component: TypeclientComponent
        },
        {
          path: 'statclient',
          loadChildren: './pages/statclient/statclient.module#StatclientModule',
        },


        ]
      },
      /*
      {
        path: 'apps/inbox',
        loadChildren: './pages/apps/inbox/inbox.module#InboxModule',
      },
      {
        path: 'apps/calendar',
        loadChildren: './pages/apps/calendar/calendar.module#CalendarAppModule',
      },
      {
        path: 'apps/chat',
        loadChildren: './pages/apps/chat/chat.module#ChatModule',
      },
      {
        path: 'components',
        loadChildren: './pages/components/components.module#ComponentsModule',
      },
      {
        path: 'forms/form-elements',
        loadChildren: './pages/forms/form-elements/form-elements.module#FormElementsModule',
      },
      {
        path: 'forms/form-wizard',
        loadChildren: './pages/forms/form-wizard/form-wizard.module#FormWizardModule',
      },
      {
        path: 'icons',
        loadChildren: './pages/icons/icons.module#IconsModule',
      },
      {
        path: 'level1/level2/level3/level4/level5',
        loadChildren: './pages/level5/level5.module#Level5Module',
      },
      {
        path: 'maps/google-maps',
        loadChildren: './pages/maps/google-maps/google-maps.module#GoogleMapsModule',
      },
      {
        path: 'tables/all-in-one-table',
        loadChildren: './pages/tables/all-in-one-table/all-in-one-table.module#AllInOneTableModule',
      },
      {
        path: 'drag-and-drop',
        loadChildren: './pages/drag-and-drop/drag-and-drop.module#DragAndDropModule'
      },
      {
        path: 'editor',
        loadChildren: './pages/editor/editor.module#EditorModule',
      },
      {
        path: 'blank',
        loadChildren: './pages/blank/blank.module#BlankModule',
      }
     , */
      /*
       {
         path: 'gestionmenu',
         component:GestionmenuComponent
       },
       {
         path: 'gestioncommande',
         component:GestioncommandeComponent
       }
       ,
       {
         path: 'gestioncaissiere',
         component:GestioncaissiereComponent
       },
       {
         path: 'gestionagent',
         component:GestionagentComponent
       }
       ,
       {
         path: 'gestionparametres',
         component:ParametragesComponent,
         children: [ {
           path: 'roles',
           component: RolesComponent
          },
 
          {
           path: 'modelivraison',
           component: ModelivraisonComponent
          },
          {
           path: 'categorie',
           component: CategorieComponent
          },
          {
           path: 'paymentmode',
           component: PaymentmodeComponent
          },
          {
           path: 'typeclient',
           component: TypeclientComponent
          }
       ]
      }
       ,
       */
      {
        path: 'monprofil',
        component: MonprofilComponent
      }
      ,
      {
        path: 'gestionprofil',
        component: GestionprofilComponent
      }
      ,
      {
        path: 'ajouterprofil',
        component: AddgestionprofilComponent
      }
      ,
      {
        path: 'modifierprofil',
        component: ModifgestionprofilComponent
      }
      ,
      {
        path: 'gestionutilisateur',
        component: GestionutilisateurComponent
      },
      {
        path: 'gestionparametre',
        component: GestionparametreComponent
      }
      ,
      {
        path: 'audit',
        component: PisteauditComponent
      },
      {
        path: 'gestionmenu',
        component: GestionmenuComponent
      },
      {
        path: 'gestionpointlivraison',
        component: GestionpointlivraisonComponent
      },
      {
        path: 'gestionpointzone',
        component: PointlivraisonzoneComponent
      },
      {
        path: 'categoriemenu',
        component: CategoriemenuComponent
      },
      {
        path: 'gestionlivraison',
        component: GestionlivraisonComponent
      },
      {
        path: 'fileattente',
        component: FileattenteComponent
      },
      {
        path: 'gestioncorporate',
        component: GestioncorporateComponent
      },
      {
        path: 'historiquecorporate',
        component: HistoriquecardmasterComponent
      },
      {
        path: 'gestioncommande',
        component: GestioncommandeComponent
      },
      {
        path: 'formuleabonnement',
        component: FormuleAbonnementComponent
      },
      {
        path: 'demandeabonnement',
        component: DemandeAbonnementComponent
      },
      {
        path: 'demandeblocage',
        component: EnabledemandeComponent
      },
      {
        path: 'precptedmde',
        component: PrecptedemandeComponent
      },
      {
        path: 'dmdquittecrpte',
        component: DemandequitcrptComponent
      },
      {
        path: 'dmdprecompte',
        component: DemandedisableprecompteComponent
      },
      {
        path: 'abattement',
        component: AbbatementComponent
      },
      {
        path: 'demandedeblocage',
        component: DisabledemandeComponent
      },
      {
        path: 'demandedeattribution',
        component: GivecarddemandeComponent
      },
      {
        path: 'gestionlivreur',
        component: GestionlivreurComponent
      },
      {
        path: 'waitorder',
        component: WaitingcmdComponent
      },
      {
        path: 'start',
        component: GestionstartComponent
      }
      ,
      {
        path: 'solde',
        component: GestionsoldeComponent
      }
      ,
      {
        path: 'gestionclient',
        component: GestionclientComponent
      },
      {
        path: 'statforclient',
        loadChildren: './pages/statforclient/statforclient.module#StatforclientModule',
      },
      {
        path: 'gestioncarte',
        component: CarteabonnementComponent
      },
      {
        path: 'ajoutercartebyfile',
        component: CarteabonnementfileComponent
      },
      {
        path: 'gestioncartestatus',
        component: CartestatusComponent
      },
      {
        path: 'gestionhistoriquecarte',
        component: HistoriquecarteComponent
      },
      {
        path: 'abonnementmassif',
        component: AbonnementmasseComponent
      },
      {
        path: 'historique/abonnementmassif',
        component: HistabnComponent
      },
      {
        path: 'gestiontva',
        component: TvaComponent
      },
      {
        path: 'gestionheurecmd',
        component: HeurecmdComponent
      },
      {
        path: 'cloturecaisse',
        component: CloturecaisseComponent
      },
      {
        path: 'creditercarte',
        component: CreditercarteComponent
      },
      {
        path: 'statproduit',
        component: StatistiqueproductComponent
      },
      {
        path: 'statvente',
        component: StatistiqueventeComponent
      },
      
      {
        path: 'allincident',
        component: GestionincidentComponent
      }
      ,
      {
        path: 'allperte',
        component: GestionperteComponent
      },

      {
        path: 'simplificationcorp',
        component: SimplificationcorporateComponent
      }


    ]
  }




];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    initialNavigation: 'enabled',
    // preloadingStrategy: PreloadAllModules,
    scrollPositionRestoration: 'enabled',
    anchorScrolling: 'enabled',
    onSameUrlNavigation: 'reload'
  })],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
