import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpHeaders } from '@angular/common/http';
import { ConfigService } from 'ngx-envconfig';
import { NGXLogger } from 'ngx-logger';
import { Agentstorage } from 'src/app/models/agentstorage';
import { Observable } from 'rxjs';
import { Client } from 'src/app/models/Agent';

@Injectable({
  providedIn: 'root'
})
export class StatforclientService {
  authorization: string;

  private getalllivraison = this.configService.get('ipMS') + 'order/getallhistoriqueventebylivraison';
  private changeStatusLivraison = this.configService.get('ipMS') + 'order/livraisonstatus';
  private getallcommandbylivraisondate = this.configService.get('ipMS') + 'order/getallhistoriqueventebylivraisondate';

  private getuserparam = this.configService.get('ipMS') + 'parameter/client/findbyid/';

  private getusercommande = this.configService.get('ipMS') + 'parameter/historiqueVente/findbyclientid/';

  private urlcard = this.configService.get('ipMS')  ;

  


  //GET /parameter/historiqueVente/findbyclientid/{id}


  public userparamid: string;
  private user: Client;

  /**
   * Getter $user
   * @return {Client}
   */
  public get $user(): Client {

    let u: Client;
    u = JSON.parse(localStorage.getItem("clientparam"));
    return u ;
  }

  /**
   * Setter $user
   * @param {Client} value
   */
  public set $user(value: Client) {
    localStorage.setItem('clientparam', JSON.stringify(value));
  }


  constructor(private http: HttpClient, private configService: ConfigService,
    private logger: NGXLogger) {
    let user: Agentstorage = new Agentstorage();
    user = JSON.parse(localStorage.getItem('curentuser'));

    if (user != null) {
      this.authorization = user.token;
    }
  }


  public post(value: any, path: string): Observable<HttpResponse<any>> {
    return this.http.post(this.urlcard + '' + path, JSON.stringify(value), {
      observe: 'response',
      headers: new HttpHeaders({ 'Access-Control-Allow-Origin': '*' })
        .set('content-Type', 'application/json')
        .set('Authorization', this.authorization)
    });
  }

  getDataForUser(value: any): Observable<HttpResponse<any>> {
    return this.http.get(this.getuserparam + "" + value, {
      observe: 'response',
      headers: new HttpHeaders({ 'Access-Control-Allow-Origin': '*' })
        .set('content-Type', 'application/json')
        .set('Authorization', this.authorization)
    });
  }


  getCardForUser(value: any): Observable<HttpResponse<any>> {
    return this.http.get(this.urlcard + "" + value, {
      observe: 'response',
      headers: new HttpHeaders({ 'Access-Control-Allow-Origin': '*' })
        .set('content-Type', 'application/json')
        .set('Authorization', this.authorization)
    });
  }



  getAllCommande(value: any): Observable<HttpResponse<any>> {
    return this.http.get(this.getusercommande + "" + value, {
      observe: 'response',
      headers: new HttpHeaders({ 'Access-Control-Allow-Origin': '*' })
        .set('content-Type', 'application/json')
        .set('Authorization', this.authorization)
    });
  }



  check(path : string ): Observable<HttpResponse<any>> {
    return this.http.get(this.urlcard + "" + path, {
      observe: 'response',
      headers: new HttpHeaders({ 'Access-Control-Allow-Origin': '*' })
        .set('content-Type', 'application/json')
        .set('Authorization', this.authorization)
    });
  }

}
