import { NgModule, LOCALE_ID } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GestionutilisateurComponent } from './gestionutilisateur.component';
import { PopupgestionutilisateurComponent } from './popupgestionutilisateur/popupgestionutilisateur.component';
import { VoirhabilitationComponent } from './voirhabilitation/voirhabilitation.component';
import { ReactiveFormsModule } from '@angular/forms';
import { MaterialModule } from 'src/@fury/shared/material-components.module';
import { BreadcrumbsModule } from 'src/@fury/shared/breadcrumbs/breadcrumbs.module';
import { MatInputModule, MatChipsModule, MatDatepickerModule, MAT_DATE_LOCALE } from '@angular/material';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { FuryCardModule } from 'src/@fury/shared/card/card.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatDatetimepickerModule } from '@mat-datetimepicker/core';
import { PopupupdategestionutilisateurComponent } from './popupupdategestionutilisateur/popupupdategestionutilisateur.component';

@NgModule({
  declarations: [GestionutilisateurComponent, PopupgestionutilisateurComponent, VoirhabilitationComponent, PopupupdategestionutilisateurComponent],
  
  imports: [

    CommonModule,
    ReactiveFormsModule,
    MaterialModule,
    BreadcrumbsModule,
    MatInputModule,
    NgxDatatableModule,
    FuryCardModule,
    MatChipsModule,
    MatDatepickerModule,
    MatDatetimepickerModule,
    BrowserAnimationsModule

  ],
    providers: [
      MatDatepickerModule,
      {
        provide: LOCALE_ID,
        useValue: "fr-FR"
      },
      {
        provide: MAT_DATE_LOCALE,
        useExisting: LOCALE_ID
      }
    ],
  entryComponents: [PopupgestionutilisateurComponent, VoirhabilitationComponent , PopupupdategestionutilisateurComponent]
})
export class GestionutilisateurModule { }
