import { Component, Inject, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormArray } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef, MatSnackBar } from '@angular/material';
import { NGXLogger } from 'ngx-logger';
import { Subscription } from 'rxjs';
import { MenuService } from 'src/app/pages/gestionmenu/menu.service';
import { AddMenuComponent } from '../../step2-menu/add-menu/add-menu.component';

@Component({
  selector: 'fury-pv-reduction',
  templateUrl: './pv-reduction.component.html',
  styleUrls: ['./pv-reduction.component.scss']
})
export class PvReductionComponent implements OnInit {

  menus: any[] = [];
  profils: any[] = [];

  articles: any[] = [];


  foods: any[] = [];

  public itemSelected: any[] = [];


  public getItemSub: Subscription;
  form: FormGroup;

  profil: string;

  constructor(
    @Inject(MAT_DIALOG_DATA) public defaults: any,
    public dialogRef: MatDialogRef<AddMenuComponent>,
    private fb: FormBuilder, private menuservice: MenuService,
    private snackbar: MatSnackBar,
    private logger: NGXLogger
  ) { }

  ngOnInit() {
    this.form = this.fb.group({
      abtmntlib: ['', Validators.required],
      typecode: ['4', Validators.required],
      type: ['point de vente', Validators.required],
      profilcode: ['param.typecard.gold', Validators.required],
      linkmenuarticle: this.fb.array([])
    });

    this.getallMenu();
  }


  get linkmenuarticle(): FormArray {
    return this.form.get("linkmenuarticle") as FormArray
  }

  addLinkmenuarticle(articleid, pointvente) {
    this.linkmenuarticle.push(this.newLinkmenuarticle(articleid, pointvente));
  }

  newLinkmenuarticle(articleid, pointvente): FormGroup {
    return this.fb.group({
      'pointventeid': [articleid, Validators.required],
      'montant': [0, Validators.required],
      'pointvente': [pointvente, Validators.required],

    })
  }



  addToCart(value: any) {
    if (this.itemSelected != null && this.itemSelected.length > 0) {
      let check: boolean = false;
      this.itemSelected.forEach(element => {
        if (element.pointventeid === value.pointventeid) {
          check = true;
        }
      });
      if (!check) {
        this.itemSelected.push(value);
        this.addLinkmenuarticle(value.pointventeid, value.pointventename);
      }
    } else {
      this.itemSelected = [];
      this.itemSelected.push(value);
      this.addLinkmenuarticle(value.pointventeid, value.pointventename);

    }
  }



  getallMenu() {
    this.getItemSub = this.menuservice.getAllFoodByCatid("parameter/pointvente/getall")
      .subscribe(data => {

        let responseany: any = data;

        this.logger.trace(responseany);

        switch (data.status) {

          case 200: {
            this.menus = data.body;
            break;
          }





        }
      },
        error => {
          console.log("error");
          console.log(error);

          switch (error.status) {

            default: {

              break;
            }
          }
        });
  }



  saveReduction() {

    const benFormArray = this.form.get('linkmenuarticle') as FormArray;

    console.log(benFormArray);
    let items: any[] = [];

    for (let index = 0; index < benFormArray.length; index++) {
      const element = benFormArray.controls[index] as FormGroup;
      let it: any = {};
      if (element.invalid) {
        let montantFormControl = element.controls.montant;
        if (montantFormControl.invalid) {
          this.snackbar.open('Veuillez renseigner correctement tous les montants ', 'OK', {
            duration: 10000
          });
          return;
        }
      }
      console.log(element.value);
      let p: any = {};
      p.pointventeid = element.value.pointventeid;
      p.montant = element.value.montant;
      items.push(p);

    }


    if (items != null && items.length == 0) {
      this.snackbar.open('Veuillez selectionner des point de ventes ', 'OK', {
        duration: 10000
      });
      return;
    }

    let request: any = this.form.value;
    request.linkmenuarticle = items;
    console.log(request);
    this.dialogRef.close(request);

  }


  count(arr) {
    return arr.reduce(function (a, b) {
      a[b] = a[b] + 1 || 1
      return a;
    }, {});
  }


}
