import { DialogData } from './../paymentmode-modifier/paymentmode-modifier.component';
import { MatDialogRef, MatSnackBar, MAT_DIALOG_DATA } from '@angular/material';
import { Component, OnInit, Inject } from '@angular/core';
import { PaymentmodeService } from 'src/app/services/paymentmode.service';


@Component({
  selector: 'fury-deletedialogpayment',
  templateUrl: './deletedialogpayment.component.html',
  styleUrls: ['./deletedialogpayment.component.scss']
})
export class DeletedialogpaymentComponent implements OnInit {

  resp: number = 0;


  ngOnInit() {
  }

  constructor(public dialogRef: MatDialogRef<DeletedialogpaymentComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData, private paymentmodeService: PaymentmodeService, private snackbar: MatSnackBar) { }

  onNoClick(): void {
    this.dialogRef.close();
  }

  
  deletePaymentmode() {
    console.log('-- Delete paymentmode ---  ' + this.data.paymentmode.paymentmodeid);
    this.dialogRef.close(this.data.paymentmode.paymentmodeid);
  }

}
