import { Observable } from 'rxjs/Observable';
import { shareReplay, map } from 'rxjs/operators';
import { AuthResp } from './../models/AuthResp';
import { Credentials } from './../models/Credentials';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import * as moment from "moment";
import 'rxjs/add/operator/do';



@Injectable({
  providedIn: 'root'
})
export class AuthService {

 // pri http://localhost:8088/yolin/session/login/root
  //http://81.95.153.117:8088/parameter/root

 // url = 'http://localhost:8088/yolin/session/login/root';

  url = 'http://81.95.153.117:8088/yolin/session/login/root';

   

  constructor(private http: HttpClient  ) {
  }
    
  //const  headers = new  HttpHeaders().set("X-CustomHttpHeader", "CUSTOM_VALUE");
  
 

  
login(username:string, password:string ) : Observable<HttpResponse<any>> {
  console.info('***** service ****  '+ JSON.stringify({username, password}));
    return  this.http.post<AuthResp>(this.url, JSON.stringify({username, password}), {
      observe: 'response',
      headers: new HttpHeaders({ 'Access-Control-Allow-Origin': '*' })
        .set("content-Type", "application/json")
            
    });
}



 

    
 setSession( authResult:AuthResp) {
  console.info('***** set session '+ authResult.token)
  //const expiresAt = moment().add(authResult.expiresIn,'second');
  //localStorage.setItem('id_token', authResult.idToken);
  //localStorage.setItem("expires_at", JSON.stringify(expiresAt.valueOf()) );
  console.info('----session set up')

  sessionStorage.setItem('access_token', authResult.token);


}          

logout() {
  localStorage.removeItem("access_token");
  localStorage.removeItem("expires_at");

    this.http.get<any>('http://81.95.153.117:8088/yolin/session/loginout', {
    observe: 'response',
    headers: new HttpHeaders({ 'Access-Control-Allow-Origin': '*' })
      .set("content-Type", "application/json")
          
  });

}

public isLoggedIn() {
  return moment().isBefore(this.getExpiration());
}

isLoggedOut() {
  return !this.isLoggedIn();
}

getExpiration() {
  const expiration = localStorage.getItem("expires_at");
  const expiresAt = JSON.parse(expiration);
  return moment(expiresAt);
} 


}
