import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Subscription } from 'rxjs';
import { Historiq } from 'src/app/models/Agent';
import { Command } from 'src/app/models/command';

@Component({
  selector: 'fury-showlivraison',
  templateUrl: './showlivraison.component.html',
  styleUrls: ['./showlivraison.component.scss']
})
export class ShowlivraisonComponent implements OnInit {


  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<ShowlivraisonComponent>,
    private fb: FormBuilder,
  ) { }

  public getItemSub: Subscription;
  public item: Command;
  public itemForm: FormGroup;


  ngOnInit() {
    this.buildItemForm(this.data.payload);
  }

  buildItemForm(value) {
    console.log(" valeur obtenue ");
    console.log(value);
    this.item = value;
  }


  getStatus(value) {

    if (value == false) {
      return "NON";
    } else if (value == true) {
      return "OUI";
    }
  }



}
