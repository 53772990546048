import { DatePipe } from '@angular/common';
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MatTableDataSource, MatPaginator, MatDialog, MatSnackBar } from '@angular/material';
import { ConfigService } from 'ngx-envconfig';
import { NGXLogger } from 'ngx-logger';
import { NgxPermissionsService } from 'ngx-permissions';
import { Subscription } from 'rxjs';
import { ConfirmationService } from 'src/app/confirm/confirmation.service';
import { Agentstorage, DemandeDto } from 'src/app/models/agentstorage';
import { DemandeabnDto } from 'src/app/models/demandeabnDto';
import { ApploaderService } from 'src/app/services/apploader/apploader.service';
import { DemandedrhService } from '../../demandedrh/demandedrh.service';

import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
const EXCEL_EXTENSION = '.xlsx';
import * as jsPDF from 'jspdf';
import 'jspdf-autotable';
import { UserOptions } from 'jspdf-autotable';

@Component({
  selector: 'fury-passtocorp',
  templateUrl: './passtocorp.component.html',
  styleUrls: ['./passtocorp.component.scss']
})
export class PasstocorpComponent implements OnInit {

  nbre : number = 0;
 
  displayedColumns: string[] = ['demandeabndate', 'demandeabnmotif', 'demandeabnexp', 'demandeabnexpemail' , 'demandeabncorporate', 'demandeabntype' ,'actions' ];
  dataSource = new MatTableDataSource<DemandeDto>([]);
  @ViewChild(MatPaginator) paginator: MatPaginator;
  public getItemSub: Subscription;
  demandedrh = [];

  constructor(private dialog: MatDialog, public formBuilder: FormBuilder,
    private snackbar: MatSnackBar, private datePipe: DatePipe,
    private configService: ConfigService,
    private confirmService: ConfirmationService, private loader: ApploaderService,
    private permissionsService: NgxPermissionsService, private logger: NGXLogger,
    private pointservice: DemandedrhService) { }

  ngOnInit() {
    this.getAllDemande();
    this.getStatDemande();
  }

  getAllDemande() {
    this.getItemSub = this.pointservice.get('drh/findbyetat/7')
      .subscribe(data => {
        const statuscode = data.status;
        this.logger.trace('DATA', data);
        switch (statuscode) {
          case 200: {
            this.demandedrh = data.body;
            this.dataSource.data = this.demandedrh;
            this.dataSource.paginator = this.paginator;
            // this.temp = data.body;
            break;
          }
          case 204: {
            // this.snack.open('Aucune TVA disponible', 'OK', { duration: 4000 })
            this.demandedrh=[];
            break;
          }
          default: {
            // this.snack.open('Une erreur est survenue!', 'OK', { duration: 4000 })
            break;
          }
        }
      },
        error => {
          this.logger.error('error');
          this.logger.error(error);
          switch (error.status) {
            default: {
               this.snackbar.open('Une erreur est survenue!', 'OK', { duration: 4000 })
              break;
            }
          }
        });
  }

  getStatDemande() {
    this.getItemSub = this.pointservice.get('drh/findnowstat/7')
      .subscribe(data => {
        const statuscode = data.status;
        this.logger.trace('DATA', data);
        switch (statuscode) {
          case 200: {
            this.nbre=data.body;
            // this.temp = data.body;
            break;
          }
          case 204: {
            // this.snack.open('Aucune TVA disponible', 'OK', { duration: 4000 })
            this.nbre=0;
            break;
          }
          default: {
            // this.snack.open('Une erreur est survenue!', 'OK', { duration: 4000 })
            break;
          }
        }
      },
        error => {
          this.logger.error('error');
          this.logger.error(error);
          switch (error.status) {
            default: {
               this.snackbar.open('Une erreur est survenue!', 'OK', { duration: 4000 });
               this.nbre=0;
              break;
            }
          }
        });
  }


  extractDemande() {
    this.getItemSub = this.pointservice.get('drh/findnowstat/7')
      .subscribe(data => {
        const statuscode = data.status;
        this.logger.trace('DATA', data);
        switch (statuscode) {
          case 200: {
            this.nbre=data.body;
            // this.temp = data.body;

            if(this.nbre && this.nbre>0){
              let count = 0 ;
              let nbrefinal = this.nbre ;
              let rows  = this.demandedrh.filter(function (d) {
                console.log(d)
                
                let c : any = d ;
                console.log(c);
                
                if((c.demandeabntype.toLowerCase().includes("demande enregistree")  && count<nbrefinal )) {
                  count=count+1;
                  return true;
                }
                
              });
              console.log(rows);

              if(rows && rows.length>0){
                let itemscsv: any[] = [];
                rows.forEach(function (element) {
                  console.log(element);

         

                if (element.demandeabndate.length > 17) {
                    element.demandeabndate = element.demandeabndate.substr(0, 16);
                }
                
                  var my_json = {
                    "Date de creation": element.demandeabndate,
                    "Matricule": element.demandeabnmotif,
                    "Email": element.demandeabnexpemail,
                    "Nom prenom": element.demandeabnexp,
                    "Corporate": element.demandeabncorporate,
                    "Status": element.demandeabntype
                  };
                  console.log("my_json");
                  console.log(my_json);
                  itemscsv.push(my_json);
                });
                console.log(itemscsv);
            
                this.exportAsExcelFile(itemscsv, 'demande passage corporate');

              }

            }
           

          
            break;
          }
          case 204: {
            // this.snack.open('Aucune TVA disponible', 'OK', { duration: 4000 })
            this.nbre=0;
            break;
          }
          default: {
            // this.snack.open('Une erreur est survenue!', 'OK', { duration: 4000 })
            break;
          }
        }
      },
        error => {
          this.logger.error('error');
          this.logger.error(error);
          switch (error.status) {
            default: {
               this.snackbar.open('Une erreur est survenue!', 'OK', { duration: 4000 });
               this.nbre=0;
              break;
            }
          }
        });
  }


  

  public exportAsExcelFile(json: any[], excelFileName: string): void {
    const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(json);
    const workbook: XLSX.WorkBook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
    const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
    this.saveAsExcelFile(excelBuffer, excelFileName);
  }
  private saveAsExcelFile(buffer: any, fileName: string): void {
    const data: Blob = new Blob([buffer], { type: EXCEL_TYPE });
    FileSaver.saveAs(data, fileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION);
  }

  

  validerStatut(row , etat : number) {
    console.log(row);
    let message: string = "";
    let dialogMessage: boolean = false;

    if (etat === 1) {
      dialogMessage = true;
      message = `CONFIRMEZ VOUS LE CHANGEMENT DE STATUS ?`
    }
    if (etat === 0) {
      dialogMessage = true;
      message = `CONFIRMEZ VOUS L' INVALIDATION DE LA DEMANDE  ?`
    }

    if (dialogMessage) {
      this.confirmService.confirm({
        message: message
      })
        .subscribe(res => {
          if (res) {
            let u = new Agentstorage();
            u = JSON.parse(localStorage.getItem('curentuser'));
            if (u != null) {
              row.userpisteaudit = u.agent.agentemail + ' ' + u.agent.agentfirstname + ' ' + u.agent.agentlastname;
            }
            var request = Object.assign({}, row);

            row.demandeabnstatus = etat;
            console.log("---> UPDATE STATUT");
            console.log(row);

            this.pointservice.post(row, 'drh/updatestatus').subscribe(data => {
              console.log(data);
              this.getAllDemande();
              this.snackbar.open('Success ', 'OK', {
                duration: 10000
              });
            },
              error => {
                this.getAllDemande();
                this.snackbar.open('Echec !! Une erreur est survenue ', 'OK', {
                  duration: 10000
                });
                console.log('erreur retour');
                console.log(error);
              }
            );
          }
        });
    }
  }

}
