import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Subscription } from 'rxjs';
import { Historiq, LivraisonStatus, deliveryStatus } from 'src/app/models/Agent';
import { Command } from 'src/app/models/command';

@Component({
  selector: 'fury-changestatus',
  templateUrl: './changestatus.component.html',
  styleUrls: ['./changestatus.component.scss']
})
export class ChangestatusComponent implements OnInit {

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<ChangestatusComponent>,
    private fb: FormBuilder,
  ) { }

  public getItemSub: Subscription;
  public item: Command;
  public itemForm: FormGroup;


  ngOnInit() {
    this.buildItemForm(this.data.payload);
  }

  buildItemForm(value) {
    console.log(" valeur obtenue ");
    console.log(value);
    this.item = value;
  }


  getStatus(value) {

    if (value == false) {
      return "NON";
    } else if (value == true) {
      return "OUI";
    }
  }

  submit() {
    let status: deliveryStatus = new deliveryStatus();
    status.commandid = this.item.commandid;
    console.log(status);
    this.dialogRef.close(status);
  }

}
