import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef, MatSnackBar } from '@angular/material';
import { NGXLogger } from 'ngx-logger';
import { Subscription } from 'rxjs/internal/Subscription';
import { ConfirmationService } from 'src/app/confirm/confirmation.service';
import { Clienttype } from 'src/app/models/Clienttype';
import { Abattement } from 'src/app/models/pointvente';
import { AbbatementService } from '../../abbatement/abbatement.service';
import { GestioncorporateService } from '../../gestioncorporate/gestioncorporate.service';

@Component({
  selector: 'fury-add',
  templateUrl: './add.component.html',
  styleUrls: ['./add.component.scss']
})
export class AddComponent implements OnInit {

  getItemSub: Subscription;
  public itemForm: FormGroup;
  client: Clienttype[];

  // categorie corpo 

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<AddComponent>,
    private fb: FormBuilder,
    private snack: MatSnackBar,
    private confirmService: ConfirmationService,
    private abbatementService: AbbatementService,
    private pointservice: GestioncorporateService,
    private logger: NGXLogger) { }

  ngOnInit() {
    this.getAllCorporate();
    this.itemForm = this.fb.group({
      categoriecorpolibelle: ['', [Validators.required]],
      categoriecorpodescription: [''],
      clienttype: ['', [Validators.required]],
    });
  }


  onSelectTypecard(item) {
    this.itemForm.controls['clienttype'].setValue(item.clienttypeid);
  }


  getAllCorporate() {


    //    this.getItemSub = this.pointservice.get('clienttype/findbyenable')

    this.getItemSub = this.pointservice.get('clienttype')
      .subscribe(data => {
        const statuscode = data.status;
        this.logger.trace('DATA', data);
        switch (statuscode) {
          case 200: {
            console.log('**************');
            this.client = data.body;
            console.log(this.client);
            // this.temp = data.body;
            break;
          }

          case 204: {
            // this.snack.open('Aucune TVA disponible', 'OK', { duration: 4000 })
            break;
          }

          default: {
            // this.snack.open('Une erreur est survenue!', 'OK', { duration: 4000 })

            break;
          }

        }
      },
        error => {
          this.logger.error('error');
          this.logger.error(error);

          switch (error.status) {

            default: {

              //    this.snack.open('Une erreur est survenue!', 'OK', { duration: 4000 })

              break;
            }
          }
        });
  }


  submit() {
    let response: any = this.itemForm.value;
    this.dialogRef.close(response);
  }

}
