import { Injectable } from '@angular/core';
import { MatDialogRef, MatDialog } from '@angular/material';
import { ApploaderComponent } from './apploader.component';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ApploaderService {

  dialogRef: MatDialogRef<ApploaderComponent>;
  constructor(private dialog: MatDialog) { }

  public open(title: string = ''): Observable<boolean> {
    this.dialogRef = this.dialog.open(ApploaderComponent, { disableClose: true, backdropClass: 'light-backdrop'});
    this.dialogRef.updateSize('200px');
    this.dialogRef.componentInstance.title = title;
    return this.dialogRef.afterClosed();
  }

  public close() {
    if(this.dialogRef)
      this.dialogRef.close();
  }}
