import { Observable } from 'rxjs/internal/Observable';
import { HttpClient, HttpResponse, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Role } from '../models/Role';

@Injectable({
  providedIn: 'root'
})
export class RolesService {

   // http://81.95.153.117:8088/yolin/session/login/root
  //http://81.95.153.117:8088/parameter/root http://81.95.153.117:8088/parameter/role
//private apiUrl = 'http://crossroadtest.net:8088/parameter/role';
private apiUrl = 'http://81.95.153.117:8088/parameter/role';



   

  constructor(private http: HttpClient  ) {
  }
    



  
save(name:string ) : Observable<HttpResponse<Role>> {
  console.info('--- creating role --- '+ JSON.stringify({name}));
    return  this.http.post<Role>(this.apiUrl, name, {
      observe: 'response',
      headers: new HttpHeaders({ 'Access-Control-Allow-Origin': '*' })
        .set("content-Type", "application/json")
             
    });
}


update(role:Role ) : Observable<HttpResponse<Role>> {
  console.info('----- update role --- '+ JSON.stringify(role));
    return  this.http.put<Role>(this.apiUrl, role, {
      observe: 'response',
      headers: new HttpHeaders({ 'Access-Control-Allow-Origin': '*' })
        .set("content-Type", "application/json")          
    });
}


delete(id:string ) : Observable<HttpResponse<any>> {
  console.info('----- delete role ----- ' + JSON.stringify({id}));
   return  this.http.delete<any>(this.apiUrl+'/'+id,  {
    observe: 'response',
    headers: new HttpHeaders({ 'Access-Control-Allow-Origin': '*' })
      .set("content-Type", "application/json")          
  });

   /* return this.http.delete<any>('url', {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' })
  });
  return this.http.delete(url, {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' })
})*/

  
}


findAll() : Observable<HttpResponse<any>> {
  console.info('--- getting all roles------ ' );

  return  this.http.get<Role[]>(this.apiUrl,  {
    observe: 'response',
    headers: new HttpHeaders({ 'Access-Control-Allow-Origin': '*' })
      .set("content-Type", "application/json")
           
  });
}

}

