import { Router } from '@angular/router';
import { AuthService } from './../../../services/auth.service';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'fury-toolbar-user',
  templateUrl: './toolbar-user.component.html',
  styleUrls: ['./toolbar-user.component.scss']
})
export class ToolbarUserComponent implements OnInit {

  isOpen: boolean;

  constructor(private  auth : AuthService , private router :Router) { }

  ngOnInit() {
  }

  toggleDropdown() {
    this.isOpen = !this.isOpen;
  }

  onClickOutside() {
    this.isOpen = false;
  }


  logOUt(){
    console.info("------------- click on logout ------")

    this.auth.logout();
   this.router.navigate(['/'], { replaceUrl: true });

  }


  goToProfil(){
   this.router.navigate(['/'], { replaceUrl: true });

  }

}
