import { MatSnackBar } from '@angular/material';
import { Injectable } from '@angular/core';
import { NGXLogger } from 'ngx-logger';



@Injectable({
  providedIn: 'root'
})
export class AutreService {


  constructor(
    private snack: MatSnackBar,
    private logger: NGXLogger,
  ) { }


  /**
   * Fonction pour supprimer les doublons dans un tableau
   */
  public supprimerDoublon(tableau: any[]) {
    let unique = {};
    tableau.forEach(element => {
      if(!unique[element]) {
        unique[element] = true;
      }
    });
    return Object.keys(unique);
  }



  /**
   * Fonction pour obtenir la date courante
   * @param jour: nombre de jour à ajouter sur la date du jour
   */
  getCurrentDate(jour?: number): string {

    let timestampDate = Date.now();

    let currentDate = new Date(timestampDate);
    let localTime = currentDate.toLocaleTimeString('en-GB', { timeZone: 'UTC' });
    let indexMonth = currentDate.getMonth() + 1;
    let indexDay;
    if (jour) {
      // Convertir le nombre de jour en heure
      let heure = jour * 24;
      currentDate.setTime(currentDate.getTime() + heure * 3600 * 1000);
      // Recuperer la nouvelle date (mois et jour)
      indexDay = currentDate.getDate();
      indexMonth = currentDate.getMonth() + 1;

    } else {
      indexDay = currentDate.getDate();

    }

    let miliseconds = currentDate.getMilliseconds();
    //console.log(currentDate.getMilliseconds);

    let dateFinale = currentDate.getFullYear() + '-' + ((indexMonth<10)? '0'+indexMonth : indexMonth) + '-' + ((indexDay<10)? '0'+indexDay : indexDay ) + ' ' + localTime + '.' + miliseconds;
    const formattedCurrentDate = dateFinale;
    // console.log('formattedCurrentDate');
    // console.log(formattedCurrentDate);
    return formattedCurrentDate;
  }

  /**
   * Fonction pour convertir une date en string
   * @param date
   * @param heure --- Defini si la fonction doit retourner la date avec les heures et millisecondes
   */
  convertDateToString(date: string, heure?: boolean, jour?: number): string {

    let dateRecu = new Date(date);
    let localTime = dateRecu.toLocaleTimeString('en-GB', { timeZone: 'UTC' });
    let indexMonth = dateRecu.getMonth() + 1;
    // let indexDay = dateRecu.getDate();
    let miliseconds = dateRecu.getMilliseconds();
    // console.log(currentDate.getMilliseconds);

    let indexDay;

    if (jour) {
      // Convertir le nombre de jour en heure
      let heure = jour * 24;
      dateRecu.setTime(dateRecu.getTime() + heure * 3600 * 1000);
      // Recuperer la nouvelle date (mois et jour)
      indexDay = dateRecu.getDate();
      indexMonth = dateRecu.getMonth() + 1;

    } else {
      indexDay = dateRecu.getDate();

    }


    if (!heure) {
      // let dateFinale = dateRecu.getFullYear() + '-' +((indexMonth<10)? '0'+indexMonth : indexMonth) + '-' + ((indexDay<10)? '0'+indexDay : indexDay ) + ' ' + localTime + '.' + miliseconds;
      let dateFinale = dateRecu.getFullYear() + '-' + ((indexMonth<10)? '0'+indexMonth : indexMonth) + '-' + ((indexDay<10)? '0'+indexDay : indexDay );
      const formattedCurrentDate = dateFinale;
      // console.log('formattedCurrentDate');
      // console.log(formattedCurrentDate);
      return formattedCurrentDate;
    }else {

      let dateFinale = dateRecu.getFullYear() + '-' + ((indexMonth<10)? '0'+indexMonth : indexMonth) + '-' + ((indexDay<10)? '0'+indexDay : indexDay ) + ' ' + dateRecu.toLocaleTimeString('en-GB', { timeZone: 'UTC' }) + '.' + dateRecu.getMilliseconds() ;
      const formattedCurrentDate = dateFinale;
      return formattedCurrentDate;
    }


  }



  /**
   * Compares two Date objects and returns e number value that represents
   * the result:
   * 0 if the two dates are equal.
   * 1 if the first date is greater than second.
   * -1 if the first date is less than second.
   * @param date1 First date object to compare.
   * @param date2 Second date object to compare.
   */
  public compareDate(date1: Date, date2: Date): number
  {
    // With Date object we can compare dates them using the >, <, <= or >=.
    // The ==, !=, ===, and !== operators require to use date.getTime(),
    // so we need to create a new instance of Date with 'new Date()'
    let d1 = new Date(date1);
    let d2 = new Date(date2);

    // Check if the dates are equal
    let same = d1.getTime() === d2.getTime();
    if (same) return 0;

    // Check if the first is greater than second
    if (d1 > d2) return 1;

    // Check if the first is less than second
    if (d1 < d2) return -1;
  }


      /**
 * Return an
 * @param {DOMElement} el A dom element of a textarea or input text.
 * @return {Object} reference Object with 2 properties (start and end) with the identifier of the location of the cursor and selected text.
 **/
 getInputSelection(el) {
  const doc = document as any;

  var start = 0, end = 0, normalizedValue, range,
      textInputRange, len, endRange;

  if (typeof el.selectionStart == 'number' && typeof el.selectionEnd == 'number') {
      start = el.selectionStart;
      end = el.selectionEnd;
  } else {
      range = doc.selection.createRange();

      if (range && range.parentElement() == el) {
          len = el.value.length;
          normalizedValue = el.value.replace(/\r\n/g, "\n");

          // Create a working TextRange that lives only in the input
          textInputRange = el.createTextRange();
          textInputRange.moveToBookmark(range.getBookmark());

          // Check if the start and end of the selection are at the very end
          // of the input, since moveStart/moveEnd doesn't return what we want
          // in those cases
          endRange = el.createTextRange();
          endRange.collapse(false);

          if (textInputRange.compareEndPoints("StartToEnd", endRange) > -1) {
              start = end = len;
          } else {
              start = -textInputRange.moveStart("character", -len);
              start += normalizedValue.slice(0, start).split("\n").length - 1;

              if (textInputRange.compareEndPoints("EndToEnd", endRange) > -1) {
                  end = len;
              } else {
                  end = -textInputRange.moveEnd("character", -len);
                  end += normalizedValue.slice(0, end).split("\n").length - 1;
              }
          }
      }
  }

  return {
      start: start,
      end: end
  };
}


}
