import { Component, Inject, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef, MatSnackBar } from '@angular/material';
import { NGXLogger } from 'ngx-logger';
import { ConfirmationService } from 'src/app/confirm/confirmation.service';
import { Typecarte } from 'src/app/models/heurecommande';
import { Abattement, Pointvente } from 'src/app/models/pointvente';
import { AbbatementService } from '../abbatement.service';

@Component({
  selector: 'fury-update',
  templateUrl: './update.component.html',
  styleUrls: ['./update.component.scss']
})
export class UpdateComponent implements OnInit {

  public itemForm: FormGroup;
  public food : string[] =[];
  public formuleabat : string[] =[];
  public pointventes : string[] = [];


  public pointevente : string[] = [] ;
  itemstypecarte: Typecarte[];

  itemspv: Pointvente[];
  itemformule: any[];
  itemsmenu: any[];

  selectedTypecard : any ;

 myselectedFoods : string[] = [];
 myselectedPvs : string[] = [];
 myselectedFormules : string[] = [];

 // foodForm: FormControl = new FormControl(this.myselectedFoods);
 foodForm: FormControl = new FormControl() ;
 pvForm: FormControl ;
 formuleForm: FormControl ;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<UpdateComponent>,
    private fb: FormBuilder,
    private snack: MatSnackBar,
    private confirmService: ConfirmationService,
    private abbatementService: AbbatementService,
    private logger: NGXLogger) { }

    ngOnInit() {
      this.getalltypecarte();
      this.getallpv();
      this.getallformule();
      this.getallmenu();
      this.buildItemForm(this.data.payload);
    }

  
  buildItemForm(item) {
    let decouvert : Abattement = item ;

    this.logger.trace('------------> DECOUVERT ');
    this.logger.info(decouvert);
    this.itemForm = this.fb.group({
      abattementid: [decouvert.abattementid || ''],
      abattementlibelle: [decouvert.abattementlibelle || '', [Validators.required]],
      abattementdescription: [decouvert.abattementdescription || ''],
      abattementtypemontant: [decouvert.abattementtypemontant || '', [Validators.required]],
      abattementtype: [decouvert.abattementtype || '', [Validators.required]],
      abattementmontant: [decouvert.abattementmontant || '', [Validators.required, Validators.min(0)]],
      abattementtypecarte: [decouvert.abattementtype || '', [Validators.required]],
      abattementtypecartelib: [decouvert.abattementtypecartelib || '', [Validators.required]],
      abattementmontantarticle: [decouvert.abattementmontantarticle || '']
    });

    this.itemForm.get("abattementtype").valueChanges.subscribe(x => {
      if(this.itemForm.get("abattementtype").value==='panier'){
        console.log('abattementtype value changed');
        this.itemForm.controls["abattementmontantarticle"].clearValidators();
        this.itemForm.controls['abattementmontantarticle'].updateValueAndValidity()
      }else{
        this.itemForm.controls['abattementmontantarticle'].setValidators([Validators.required]);
        this.itemForm.controls['abattementmontantarticle'].updateValueAndValidity();
      }
   })

    this.itemForm.controls['abattementtype'].valueChanges.subscribe(value => {
      console.log(value);
    });

    this.foodForm = new FormControl(decouvert.food);
    //this.myselectedFoods=decouvert.foods ;
    let arrayFood : any[] = decouvert.foods ;
    let arrayFoodSelect : any[] = [];

    let arrayPv : any[] = decouvert.pointventes ;
    let arrayPvSelect : any[] = [];


    let arrayFormule : any[] = decouvert.formules ;
    let arrayFormuleSelect : any[] = [];

    for (const iteratorfood of arrayFood) {
      this.logger.trace(iteratorfood);
      arrayFoodSelect.push(iteratorfood.foodid);
    }

    this.myselectedFoods=arrayFoodSelect ;
    this.food=this.myselectedFoods ;
    this.logger.trace('----- myselectedFoods -----');
    this.logger.trace(this.myselectedFoods);
     

    this.pointventes=[];
    for (const iteratorpv of arrayPv) {
      this.logger.trace('------------> Pointe de vente iterator ');
      this.logger.trace(iteratorpv);
      if(iteratorpv!==undefined){
        arrayPvSelect.push(iteratorpv.pointventeid);
        this.pointventes.push(iteratorpv.pointventeid);
      }
    }
    this.myselectedPvs=arrayPvSelect ;
    this.logger.trace('------------> Pointe de vente repertorié ');
    this.logger.trace(this.pointventes);

    for (const iteratorform of arrayFormule) {
      this.logger.trace(iteratorform);
      arrayFormuleSelect.push(iteratorform.formuleid);
    }
    this.myselectedFormules=arrayFormuleSelect ;
    this.formuleabat=this.myselectedFormules ;
    //this.selectedTypecard  = decouvert.abattementtypecartelib ;
    this.selectedTypecard  = decouvert.abattementtypecarte ;

    
  }


  getalltypecarte() {
     this.abbatementService.getAll('typecarte/enable')
      .subscribe(data => {

        let statuscode = data.status;
        switch (statuscode) {

          case 200: {
            this.itemstypecarte = data.body;
            console.log(this.itemstypecarte);
            break;
          }

        
          default: {

            break;
          }

        }
      },
        error => {
          this.logger.error("error");
          this.logger.error(error);

          switch (error.status) {

            default: {

              break;
            }
          }
        });
  }

  getallpv() {
    this.abbatementService.getAll('parameter/pointvente/getall')
     .subscribe(data => {
       let statuscode = data.status;
       switch (statuscode) {
         case 200: {
          this.logger.trace("point de vente");
          this.logger.trace(data.body);
           this.itemspv = data.body;
           break;
         }
         default: {
           break;
         }
       }
     },
       error => {
         this.logger.error("error");
         this.logger.error(error);
         switch (error.status) {
           default: {
             break;
           }
         }
       });
  }


  getallformule() {
    this.abbatementService.getAll('formule')
     .subscribe(data => {
       let statuscode = data.status;
       switch (statuscode) {
         case 200: {
          this.logger.trace("formule");
          this.logger.trace(data.body);
           this.itemformule = data.body;
           break;
         }
         default: {
           break;
         }

       }
     },
       error => {
         this.logger.error("error");
         this.logger.error(error);
         switch (error.status) {
           default: {
             break;
           }
         }
       });
  }


  getallmenu() {
    this.abbatementService.getAll('parameter/food')
     .subscribe(data => {
       let statuscode = data.status;
       switch (statuscode) {
         case 200: {
          this.logger.trace("food");
          this.logger.trace(data.body);
           this.itemsmenu = data.body;
           break;
         }
         default: {
           break;
         }
       }
     },
       error => {
         this.logger.error("error");
         this.logger.error(error);
         switch (error.status) {
           default: {
             break;
           }
         }
       });
  }

  onSelectTypecard(item) {
    for (const iterator of this.itemstypecarte) {
      if(iterator.typecartelibelle=item){
        this.itemForm.controls['abattementtypecarte'].setValue(iterator.typecartecode);
        this.itemForm.controls['abattementtypecartelib'].setValue(iterator.typecartelibelle);
        this.logger.trace(this.itemForm);

      }
    }
  }




  selected = -1;

  /*checkbox change event*/
  onChangePv(event) {
    let result : any = event ;
    this.logger.trace('<------------ onChangePv ------------>');
    this.logger.trace(result);
    if(result.source.selected) {
      this.pointventes.push(event.source.value);
    } else {
     // this.pointventes = this.pointventes.filter(item => item !== event.source.value);
     this.logger.trace('<------------ onChangePv ... BEFORE ------------>');
     this.logger.trace( this.pointventes);
      let pointventes =[];
      this.pointventes.forEach(element => {
        this.logger.trace(element);
        if(element!==undefined && element !== result.source.value ){
          pointventes.push(element);
        }
      });
      this.logger.trace('<------------ onChangePv ... Pointevente ------------>');
      this.logger.trace(pointventes);
      this.pointventes=pointventes ;

    }
  }

  onChangeFood(event) {
    let result : any = event ;
    if(result.source.selected) {
      this.food.push(event.source.value.foodid);
    } else {
      this.food = this.food.filter(item => item != event.source.value.foodid);
    }
  }

  onChangeFormule(event) {
    let result : any = event ;
    if(result.source.selected) {
      this.formuleabat.push(event.source.value.formuleid);
    } else {
      this.formuleabat = this.formuleabat.filter(item => item != event.source.value.formuleid);
    }
  }


  submit(){
    let response : Abattement = this.itemForm.value ;
    this.myselectedFoods = this.myselectedFoods.filter(item => item !== null);
    this.myselectedFormules = this.myselectedFormules.filter(item => item !== null);
    this.myselectedPvs = this.myselectedPvs.filter(item => item !== null);

    var fodFiltered = this.myselectedFoods.filter(function (el) {
      return el != null;
    });
    response.food=fodFiltered ;

    var formFiltered =  this.myselectedFormules.filter(function (el) {
      return el != null;
    });
    response.formuleabat=formFiltered ;
   

    var pvFiltered = this.myselectedPvs.filter(function (el) {
      return el != null;
    });
    response.pointventes=pvFiltered ;
    this.onSelectTypecard(this.itemForm.get("abattementtypecartelib").value);
    this.logger.trace("FINAL OBJECT ---->");
    this.logger.trace(response);

    this.dialogRef.close(response);
  }

}
