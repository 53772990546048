import { Component, OnInit } from '@angular/core';
import { MatSnackBar, MatDialog, MatDialogRef } from '@angular/material';
import { ConfigService } from 'ngx-envconfig';
import { NGXLogger } from 'ngx-logger';
import { Subscription } from 'rxjs/internal/Subscription';
import { ConfirmationService } from 'src/app/confirm/confirmation.service';
import { Agentstorage } from 'src/app/models/agentstorage';
import { Abattement } from 'src/app/models/pointvente';
import { Rolefonction } from 'src/app/models/rolefonction';
import { AbbatementService } from './abbatement.service';
import { AddComponent } from './add/add.component';
import { ShowComponent } from './show/show.component';
import { UpdateComponent } from './update/update.component';

@Component({
  selector: 'fury-abbatement',
  templateUrl: './abbatement.component.html',
  styleUrls: ['./abbatement.component.scss']
})
export class AbbatementComponent implements OnInit {

  getItemSub: Subscription;
  items: Abattement[];
  scrollBarHorizontal = (window.innerWidth < 1200);
  temp = [];
  rows = [];
  columns = [];

  public afficherAbattement: boolean;
  public creationAbattement: boolean;
  public modificationAbattement: boolean;
  public suppressionAbattement: boolean;

  public keyAfficherAbattement= 'afficher abattement';
  public keyCreationAbattement = 'ajouter abattement';
  public keyModificationAbattement = 'modifier abattement';
  public keySuppressionAbattement = 'supprimer abattement';


  constructor(private logger: NGXLogger, private abbatementService: AbbatementService,
    private snack: MatSnackBar, private confirmService: ConfirmationService,
    private dialog: MatDialog,
    private configService: ConfigService) { }

  ngOnInit() {
    this.verifierDroit();
    this.getall();
  }



  
  updateStatusItem( status : any , row : any , etat : number) {
    this.confirmService.confirm({ message:  `Confirmation `+status +` ${row.abattementlibelle}?` })
      .subscribe(res => {
        if (res) {
          let request : any = {} ;
          request.abattementid=row.abattementid;
          this.logger.info(request);
          //request.categoriecorpoid=row.categoriecorpoid ;
          this.abbatementService.post(request, "parameter/abattement/updatestatus/"+etat)
            .subscribe(data => {
              let statuscode = data.status;
              switch (statuscode) {
                case 200: {
                  // Actualisé les données
                  this.getall();
                  this.snack.open('Succes!', 'OK', { duration: 4000 })
                  break;
                }
             
                default: {
                  this.snack.open('Une erreur est survenue!', 'OK', { duration: 4000 })
                  break;
                }
              }

            },
              error => {
                let statuscode = error.status;
                switch (statuscode) {
                
                  default: {
                    this.snack.open('Une erreur est survenue!', 'OK', { duration: 4000 })
                    break;
                  }
                }

              });
        }

      })


  }
   
  
  showcarte(value: any) {
    this.dialog.open(ShowComponent, {
      //disableClose: true,
      width: '720px',
      data: { title: '', payload: value }
    }).afterClosed().subscribe((res) => {
      console.log(res);
      if (!res) {
        return;
      }
    });
  }

  public verifierDroit() {
    this.logger.trace('BEGIN');
    // this.logger.trace('Clé', this.configService.get('keylocalstorage'));

    let agentOnline: Agentstorage;
    agentOnline = JSON.parse(localStorage.getItem(this.configService.get('keylocalstorage')));
    // this.logger.trace(agentOnline);
    let rolefontion: Rolefonction[] = agentOnline.rolefonction;
    // this.logger.trace('rolefontion', rolefontion);

    for (const key in rolefontion) {
      if (rolefontion.hasOwnProperty(key)) {
        // this.logger.trace('KEY', key);
        const element = rolefontion[key].fonction.fonctionname;
        this.logger.trace('element', element);

        if (element == this.keyAfficherAbattement) {
          this.logger.trace('true');
          this.afficherAbattement = true;
        }
        if (element == this.keyCreationAbattement) {
          this.logger.trace('true');
          this.creationAbattement = true;
        }
        if (element == this.keyModificationAbattement) {
          this.logger.trace('true');
          this.modificationAbattement = true;
        }
        if (element == this.keySuppressionAbattement) {
          this.logger.trace('true');
          this.suppressionAbattement = true;
        }
      }
    }

  }


  updateFilter(event) {
    const val = event.target.value.toLowerCase();
    var columns = Object.keys(this.temp[0]);
    // Removes last "$$index" from "column"
    columns.splice(columns.length - 1);

    // console.log(columns);
    if (!columns.length)
      return;

    const rows = this.temp.filter(function (d) {
      for (let i = 0; i <= columns.length; i++) {
        let column = columns[i];
        // console.log(d[column]);
        if (d[column] && d[column].toString().toLowerCase().indexOf(val) > -1) {
          return true;
        }
      }
    });

    this.items = rows;
  }

  getall() {
    this.getItemSub = this.abbatementService.getAll('parameter/abattement/getallabattement')
      .subscribe(data => {

        let statuscode = data.status;
        this.logger.trace('DATA', data);

        switch (statuscode) {

          case 200: {
            this.items = data.body;
            this.temp = data.body;
            break;
          }

          case 204: {
            this.snack.open('Aucun decouvert disponible', 'OK', { duration: 4000 })
            break;
          }

          default: {
            this.snack.open('Une erreur est survenue!', 'OK', { duration: 4000 })

            break;
          }

        }
      },
        error => {
          this.logger.error("error");
          this.logger.error(error);

          switch (error.status) {

            default: {
              this.snack.open('Une erreur est survenue!', 'OK', { duration: 4000 })

              break;
            }
          }
        });
  }

  openPopUp(data: any = {}, isNew?) {
    let title = isNew ? 'Ajouter un abattement' : 'Mettre à jour un abattement';
    let dialogRef: MatDialogRef<any> = this.dialog.open(AddComponent, {
      width: '720px',
      disableClose: false,
      data: { title: title, payload: data }
    })
    dialogRef.beforeClose()
      .subscribe(res => {
        if (!res) {
          // If user press cancel
          return;
        }

        let url: string = "parameter/abattement/update";

        if (isNew) {
          url = "parameter/abattement/add"
        }

        this.logger.trace("URL : " + url);

        this.abbatementService.post(res, url)
          .subscribe(data => {
            let statuscode = data.status;

            switch (statuscode) {
              case 200: {
                this.getall();
                this.snack.open('Succes !', 'OK', { duration: 4000 })
                break;
              }

            }

          },
            error => {
              let statuscode = error.status;
              switch (statuscode) {

                /*
                case 423: {
                  this.snack.open('Montant incorrecte , veuillez respecter le plafond des decouverts', 'OK', { duration: 4000 })
                  break;
                }
                */
                case 409: {
                  this.snack.open('Cet abattement existe deja', 'OK', { duration: 4000 })
                  break;
                }

                default: {
                  this.snack.open('Une erreur est survenue!', 'OK', { duration: 4000 })
                  break;
                }
              }

            });
      })
  }


  openPopUpdate(data: Abattement) {
    this.abbatementService.getAll('parameter/abattement/getabattement/'+data.abattementid)
    .subscribe(data => {
      let statuscode = data.status;
      switch (statuscode) {
        case 200: {
         let abattement : Abattement = data.body ;
         let title = 'Mettre à jour un abattement';
         let dialogRef: MatDialogRef<any> = this.dialog.open(UpdateComponent, {
           width: '720px',
           disableClose: false,
           data: { title: title, payload: abattement }
         })
         dialogRef.beforeClose()
           .subscribe(res => {
             if (!res) {
               // If user press cancel
               return;
             }
             this.abbatementService.post(res, 'parameter/abattement/update')
             .subscribe(data => {
               let statuscode = data.status;
   
               switch (statuscode) {
                 case 200: {
                   this.getall();
                   this.snack.open('Succes !', 'OK', { duration: 4000 })
                   break;
                 }
               }
             },
               error => {
                 let statuscode = error.status;
                 switch (statuscode) {
   
                   /*
                   case 423: {
                     this.snack.open('Montant incorrecte , veuillez respecter le plafond des decouverts', 'OK', { duration: 4000 })
                     break;
                   }
                   */
                   case 409: {
                     this.snack.open('Cet abattement existe deja', 'OK', { duration: 4000 })
                     break;
                   }
   
                   default: {
                     this.snack.open('Une erreur est survenue!', 'OK', { duration: 4000 })
                     break;
                   }
                 }
   
               });
             
           });
           
         break;
        }
      }
    },
      error => {
        let statuscode = error.status;
        switch (statuscode) {
          default: {
            break;
          }
        }

      });
  }




  deleteItem(row) {
    this.confirmService.confirm({ message: `Suppression ${row.abattementlibelle}?` })
      .subscribe(res => {
        if (res) {
          let request : Abattement = new Abattement();
         
          request.abattementid=row.abattementid ;
          this.abbatementService.post(request, "parameter/abattement/delete")
            .subscribe(data => {
              let statuscode = data.status;
              switch (statuscode) {
                case 200: {
                  // Actualisé les données
                  this.getall();
                  this.snack.open('Abbatement Supprimé!', 'OK', { duration: 4000 })
                  break;
                }
             
                default: {
                  this.snack.open('Une erreur est survenue!', 'OK', { duration: 4000 })
                  break;
                }
              }

            },
              error => {
                let statuscode = error.status;
                switch (statuscode) {
                
                  default: {
                    this.snack.open('Une erreur est survenue!', 'OK', { duration: 4000 })
                    break;
                  }
                }

              });
        }

      })


  }

}
