import { DeleteagentComponent } from './deleteagent/deleteagent.component';
import { Router } from '@angular/router';
import { AgentDto  } from './../../models/AgentDto';
import { Agent  } from './../../models/Agent';

import { GestionagentformComponent } from './gestionagentform/gestionagentform.component';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { GenericserviceService } from './../../services/genericservice.service';
import { MatDialog, MatSnackBar, MatPaginator, MatSort, MatTableDataSource } from '@angular/material';
import { Component, OnInit, ViewChild } from '@angular/core';


export interface DialogDataAgent {
  agent: Agent;
}

@Component({
  selector: 'fury-gestionagent',
  templateUrl: './gestionagent.component.html',
  styleUrls: ['./gestionagent.component.scss']
})
export class GestionagentComponent implements OnInit {

agentDto : AgentDto;
private agents: Agent[];
private selectedValue: Agent;
agentForm: FormGroup;


agentId: string;

agentisdeleted: boolean;
agentdatecreation: string;
agent: Agent;

displayedColumns: string[] = ['agentlastname', 'agentfirstname', 'role', 'agentdatecreation', 'agentisdeleted', 'actions'];
dataSource: any;


@ViewChild(MatPaginator) paginator: MatPaginator;
@ViewChild(MatSort) sort: MatSort;

constructor(   private agentService: GenericserviceService, private formBuilder: FormBuilder, private snackbar: MatSnackBar, public dialog: MatDialog) {
}


 
  

  
  onpenDialogCreate() {
    console.log('------onpen dialog new agent creation  -------');
    this.agentDto = new AgentDto;

    const dialogRef = this.dialog.open(GestionagentformComponent, {
      maxWidth: '80%',

      data: { agentDto: this.agentDto }
    });

    dialogRef.afterClosed().subscribe(resp => {

      console.log('The dialog was closed .. resp --- ' +  resp);



      //this.findAll() ;
      console.log('======================');
     // this.agentDto = result;

    });
  }

 


  ngOnInit() {

    this.agentService.type='agent';
    this.findAll();


  }


  
    




  validateAllFormFields(formGroup: FormGroup) {
    Object.keys(formGroup.controls).forEach(field => {
        const control = formGroup.get(field);
        if (control instanceof FormControl) {
            control.markAsTouched({ onlySelf: true });
        } else if (control instanceof FormGroup) {
            this.validateAllFormFields(control);
        }
    });
}


  findAll() {

    this.agentService.findAll().subscribe(data => {
      console.log(data);
      switch (data.status) {
        case 200: {
          // this.loading.dismiss('messagesService.loadMessagesOverview');
          
          let resp: Agent[];
          resp = data.body;
          console.log("------------------------------------");
          console.log(resp);
          console.log("------------------------------------");
          console.log(resp[0].agentdatecreation);
          this.agents = resp;
          // this.dataSource = valeurs;
          this.dataSource = new MatTableDataSource<Agent>(resp);
          this.dataSource.paginator = this.paginator;
          this.dataSource.sort = this.sort;


          if (resp != null) {
            //this.resetFunction();


            console.log("------------- agents founded ------------------");

          } else {

            // this.FormReg(" Mot de passe ou login incorrect ");
          }
          break;
        }
      }
    },
      error => {
        console.log("erreur retour");
        // this.loading.dismiss('messagesService.loadMessagesOverview');
        switch (error.status) {
          case 401:
            //this.FormReg(" Mot de passe ou login incorrect ");
            break;
          default: {
            // this.FormReg(" erreur de connexion !!! Veuillez ressayer");
            break;
          }
        }
      }
    );

  }


  updateAgent(row) {
    console.log(' edit agent ');

    if (this.agentId) {
      console.log('id ok ----editing ');

      let agent: Agent = new Agent();
      agent.agentid = this.agentId;
      agent.agentfirstname = this.agentForm.controls['agentfirstname'].value;
      // this.valeurForm.controls['agentLastName'].value,
      // this.valeurForm.controls['agentEmail'].value;

      // updating object
      this.agentService.update(agent).subscribe();
    }


  }


  deleteAgent(row) {
    console.log('-- Delete agent ---  '+  row);

    if (row) {
      let agent : Agent = row ;
      this.openDialogDelete(agent);
      /*this.agentService.delete(agent.agentid).subscribe(
        res => {
          this.findAll();
          //this.router.navigate(['/valeur']);
          console.log('----  done ---- ');
          this.snackbar.open('Success supprimé' , 'OK', {
            duration: 2000
          });
      
        }
      );*/
    }


    

  }

  openDialogDelete(agent): void {
    const dialogRef = this.dialog.open(DeleteagentComponent, {
      width: '250px',
      data: {agent: agent}
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed' + result);
      //this.animal = result;
      this.findAll();
    });
  }


  openDialog(row): void {
    console.log('------onpen dialog ------- '+ row);

    console.log(row);
    this.selectedValue = row;
    if (row) {
      this.callAgentDetail();
    }
  }

  
  callAgentDetail() {
  //  console.log('------onpen dialog -------');

    const dialogRef = this.dialog.open(GestionagentformComponent, {
      maxWidth: '80%',

      data: { agent: this.selectedValue }
    });

    dialogRef.afterClosed().subscribe(resp => {

      console.log('The dialog was closed .. resp --- ' +  resp);



      this.findAll() ;
      console.log('======================');
     // this.agent = result;

    });
  }
}
