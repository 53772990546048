import { Component, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material';
import { Router, ActivatedRoute } from '@angular/router';
import { NGXLogger } from 'ngx-logger';
import { Subscription } from 'rxjs';
import { ApploaderService } from 'src/app/services/apploader/apploader.service';
import { StatforclientService } from '../statforclient.service';


import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';

const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
const EXCEL_EXTENSION = '.xlsx';

@Component({
  selector: 'fury-statabn',
  templateUrl: './statabn.component.html',
  styleUrls: ['./statabn.component.scss']
})
export class StatabnComponent implements OnInit {


  scrollBarHorizontal = (window.innerWidth < 1200);

  public getItemSub: Subscription;
  temp = [];
  items = [];

  constructor(private router: Router, private statforclientserv: StatforclientService, private activatedRoute: ActivatedRoute
    , private logger: NGXLogger, private loader: ApploaderService, private snackbar: MatSnackBar) { }

  ngOnInit() {
    this.activatedRoute.params.subscribe(params => {
      let id = params['id'];
      this.getAllCommandByClient(id);
    });
  }




  getAllCommandByClient(id: string) {

    this.getItemSub = this.statforclientserv.check('parameter/histabonement/checkpayment/' + id)
      .subscribe(data => {
        switch (data.status) {
          case 200: {
            this.logger.trace('data', data);
            this.items = this.temp = data.body;
            console.log("*************");
            console.log(this.items);
            break;
          }
        }
      },
        error => {
          this.logger.trace('error');
          this.logger.error(error);

          switch (error.status) {

            default: {

              this.logger.trace('data', error);
              break;
            }
          }
        });
  }




  verifyFalse(value: any) {
    if (value === true) {
      return 'oui';
    } else if (value === false) {
      return 'non';
    }
  }




  updateFilter(event) {
    const val = event.target.value.toLowerCase();
    var columns = Object.keys(this.temp[0]);
    // Removes last "$$index" from "column"
    columns.splice(columns.length - 1);

    // console.log(columns);
    if (!columns.length)
      return;

    const rows = this.temp.filter(function (d) {
      for (let i = 0; i <= columns.length; i++) {
        let column = columns[i];
        // console.log(d[column]);
        if (d[column] && d[column].toString().toLowerCase().indexOf(val) > -1) {
          return true;
        }
      }
    });

    this.items = rows;
  }




  public exportCsv() {

    let itemscsv: any[] = [];

    this.items.forEach(function (element) {
      console.log(element);
      var my_json = {
        "Date ": element.abonnementdatecreation,
        "formule": element.abonnementformule,
        "montant ": element.histomontant,
        "solde": element.abonnementsoldeactuel,
        "decouvert": element.abonnementsoldeactueldecouvert

      };
      console.log("my_json");
      console.log(my_json);
      itemscsv.push(my_json);
    });
    console.log(itemscsv);

    this.exportAsExcelFile(itemscsv, 'Toutes les commandes');
  }


  public exportAsExcelFile(json: any[], excelFileName: string): void {
    const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(json);
    const workbook: XLSX.WorkBook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
    const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
    this.saveAsExcelFile(excelBuffer, excelFileName);
  }
  private saveAsExcelFile(buffer: any, fileName: string): void {
    const data: Blob = new Blob([buffer], { type: EXCEL_TYPE });
    FileSaver.saveAs(data, fileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION);
  }

}
