import { Categories } from './categories';
import { Heurecommande } from './heurecommande';
import { TvaComponent } from '../pages/tva/tva.component';
export class Foods {

      categorieset() {
            this.categoriename = this.categorie.categoriename;
      }

      foodid: string;
      foodname: string;
      categorieName: string;
      fooddatecreation: string;
      fooddescription: string;
      foodpicture: string;
      foodprice: number;
      categorie: Categories;
      categoriename: string;
      foodstockdisponible: number;
      fooddownloadimageurl: string;
      heurecommande: Heurecommande;
      tva: TvaComponent;
      heurecommandeid: string;
      tvaid: string;
      foodverify: boolean;
      foodremise: boolean;

      userpisteaudit: string;






}



