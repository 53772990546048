export class Paymentmode {

    paymentmodeid: string;
    paymentmodename: string;
    paymentmodedatecreation: string;
    paymentmodeisdeleted: boolean ;
    paymentmodecode: string;


    public constructor() {

    }


}