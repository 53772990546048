import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TvaComponent } from './tva.component';
import { ReactiveFormsModule } from '@angular/forms';
import { MatChipsModule } from '@angular/material';
import { MaterialModule } from 'src/@fury/shared/material-components.module';
import { BreadcrumbsModule } from 'src/@fury/shared/breadcrumbs/breadcrumbs.module';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { FuryCardModule } from 'src/@fury/shared/card/card.module';
import { AddtvaComponent } from './addtva/addtva.component';
import { UpdatetvaComponent } from './updatetva/updatetva.component';

@NgModule({
  declarations: [TvaComponent, AddtvaComponent, UpdatetvaComponent],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    MatChipsModule,
    MaterialModule,
    BreadcrumbsModule,
    NgxDatatableModule,
    FuryCardModule,
    MatChipsModule,
  ],
  entryComponents: [AddtvaComponent, UpdatetvaComponent]
})
export class TvaModule { }
