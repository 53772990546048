import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { NGXLogger } from 'ngx-logger';
import { HistoriqAbnFile } from 'src/app/models/Agent';
import { Agentstorage } from 'src/app/models/agentstorage';
import { LoadfileComponent } from '../loadfile/loadfile.component';

@Component({
  selector: 'fury-addloadfile',
  templateUrl: './addloadfile.component.html',
  styleUrls: ['./addloadfile.component.scss']
})
export class AddloadfileComponent implements OnInit {



  fileSelected: File;
  reference: string;
  histabn: HistoriqAbnFile;


  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<AddloadfileComponent>,
    private logger: NGXLogger) { }

  ngOnInit() {
    this.buildItem(this.data.payload);
  }


  buildItem(value: any) {
    this.histabn = value;
    this.reference = this.histabn.histabnfiletransid;
  }

  public fileChangeEvent($event) {
    console.log('FILECHANGE');

    this.fileSelected = $event.target.files[0];
    let path = URL.createObjectURL($event.target.files[0]);
    console.log('PATH');
    console.log(path);

    const constFileSelected: File = $event.target.files[0];
    console.log(constFileSelected);
    console.log('FILENAME');
    console.log(constFileSelected.name);
    console.log(constFileSelected.type);
    console.log(constFileSelected.size);
    console.log(constFileSelected.lastModified);

    // Fonction pour afficher le contenu du fichier
    // this.check(path, constFileSelected.type);
  }


  submit() {
    let value: any = {};
    value.file = this.fileSelected;
    let u = new Agentstorage();
    u = JSON.parse(localStorage.getItem('curentuser'));
    if (u != null) {
      value.agentemail = u.agent.agentemail;
    }
    if (this.histabn != null) {
      value.transaction = this.histabn.histabnfiletransid;
    }
    this.dialogRef.close(value);
  }

}
