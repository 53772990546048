import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GestionlivraisonComponent } from './gestionlivraison.component';
import { MatChipsModule } from '@angular/material';
import { MaterialModule } from 'src/@fury/shared/material-components.module';
import { BreadcrumbsModule } from 'src/@fury/shared/breadcrumbs/breadcrumbs.module';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { FuryCardModule } from 'src/@fury/shared/card/card.module';
import { FurySharedModule } from 'src/@fury/fury-shared.module';
import { ReactiveFormsModule } from '@angular/forms';
import { ChangestatusComponent } from './changestatus/changestatus.component';
import { ShowlivraisonComponent } from './showlivraison/showlivraison.component';
import { QuickInfoWidgetModule } from '../dashboard/widgets/quick-info-widget/quick-info-widget.module';
import { AffectercommandeaunlivreurComponent } from '../gestioncommande/affectercommandeaunlivreur/affectercommandeaunlivreur.component';
import { DeliveryCancelComponent } from './delivery-cancel/delivery-cancel.component';

@NgModule({
  declarations: [GestionlivraisonComponent, ChangestatusComponent, ShowlivraisonComponent, AffectercommandeaunlivreurComponent, DeliveryCancelComponent],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    MatChipsModule,
    MaterialModule,
    BreadcrumbsModule,
    NgxDatatableModule,
    FuryCardModule,
    MatChipsModule,
    FurySharedModule,
    QuickInfoWidgetModule

  ],
  entryComponents: [ChangestatusComponent, ShowlivraisonComponent, AffectercommandeaunlivreurComponent, DeliveryCancelComponent]

})
export class GestionlivraisonModule { }
