import { Component, OnInit, Inject } from '@angular/core';
import { Historiq } from 'src/app/models/Agent';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';

@Component({
  selector: 'fury-showcommandes',
  templateUrl: './showcommandes.component.html',
  styleUrls: ['./showcommandes.component.scss']
})
export class ShowcommandesComponent implements OnInit {


  item: any;


  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
    private dialogRef: MatDialogRef<ShowcommandesComponent>) {
  }

  ngOnInit() {
    this.buildItemForm(this.data.payload);
  }



  buildItemForm(item) {
    console.log(" *************************  ");
    console.log(item);
    console.log(" *************************  ");

    this.item = item;
  }





  verifyFalse(value: any) {
    if (value === true) {
      return 'oui';
    } else if (value === false) {
      return 'non';
    }
  }
}
