import { Component, Inject, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { NGXLogger } from 'ngx-logger';

@Component({
  selector: 'fury-zone',
  templateUrl: './zone.component.html',
  styleUrls: ['./zone.component.scss']
})
export class ZoneComponent implements OnInit {

  form: FormGroup;

  constructor(@Inject(MAT_DIALOG_DATA) public defaults: any,
    public dialogRef: MatDialogRef<ZoneComponent>,
    private fb: FormBuilder,
    private logger: NGXLogger) {
  }
 
  ngOnInit() {
    this.form = this.fb.group({
      pointlivraisonquartiernom: ['', Validators.compose([Validators.required])],
      pointlivraisonquartierprix: ['', Validators.compose([Validators.required])]
    });
  }



  submit() {
    //    this.form.
    this.dialogRef.close(this.form.value);
  }

}
