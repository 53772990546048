import { Component, Inject, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { CustomValidators } from 'ng2-validation';
import { NGXLogger } from 'ngx-logger';
import { Notif, Param } from 'src/app/models/param';
import { UpdateComponent } from '../update/update.component';

@Component({
  selector: 'fury-cancelcmd',
  templateUrl: './cancelcmd.component.html',
  styleUrls: ['./cancelcmd.component.scss']
})
export class CancelcmdComponent implements OnInit {


  updateForm: FormGroup;

  param: Notif;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<UpdateComponent>
    , private logger: NGXLogger
  ) { }

  ngOnInit() {
    this.buildItem(this.data.payload);
  }

  buildItem(value: any) {
    this.param = value;
    this.updateForm = new FormGroup({
      commandreference: new FormControl(this.param.commandreference, [Validators.required]),
      remove_assignment_comment: new FormControl("", [Validators.required])

    });
  }


  submit() {

    this.dialogRef.close(this.updateForm.value);
  }


}
