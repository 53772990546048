import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatSnackBar } from '@angular/material';
import { ConfigService } from 'ngx-envconfig';
import { NGXLogger } from 'ngx-logger';
import { NgxPermissionsService } from 'ngx-permissions';
import { ConfirmationService } from 'src/app/confirm/confirmation.service';
import {  Searchstatuser } from 'src/app/models/searchbydate';
import { Statuser } from 'src/app/models/statperiod';
import { ApploaderService } from 'src/app/services/apploader/apploader.service';
import { CommandeService } from '../gestioncommande/commande.service';

@Component({
  selector: 'fury-gestionstatuser',
  templateUrl: './gestionstatuser.component.html',
  styleUrls: ['./gestionstatuser.component.scss']
})
export class GestionstatuserComponent implements OnInit {

  public itemForm: FormGroup;

  public items: Statuser = new Statuser();

  constructor(private dialog: MatDialog, public formBuilder: FormBuilder,
    private snackbar: MatSnackBar, private commandeserv: CommandeService,
    private datePipe: DatePipe,
    private confirmService: ConfirmationService, private loader: ApploaderService,
    private permissionsService: NgxPermissionsService, private logger: NGXLogger,
    private configService: ConfigService, private fb: FormBuilder) { }



  ngOnInit() {
    this.itemForm = this.fb.group({
      to: ['', Validators.compose([Validators.required])],
      from: ['', Validators.compose([Validators.required])],
    });
  }








  sendRequest() {

    let to: string = "";
    let from: string = "";
    let date = new Date(this.itemForm.get("from").value);
    let date1 = new Date(this.itemForm.get("to").value);

    if ((date.toString() != 'Invalid Date')) {
      from = this.itemForm.get("from").value;
    }

    if (date1.toString() != null) {
      to = this.itemForm.get("to").value;
    }

    let newDate = new Date(from);

    let newDate1 = new Date(to);


    if ((newDate.toDateString() === "Invalid Date") || (newDate1.toDateString() === "Invalid Date")) {
      this.snackbar.open('Format de date invalide ', 'OK', { duration: 4000 });
    } else {
      if (newDate1 >= newDate) {

        this.loader.open();
        let searchdate : Searchstatuser = new Searchstatuser();
        searchdate.debut = this.datePipe.transform(newDate, "yyyy-MM-dd HH:mm:ss");
        searchdate.fin = this.datePipe.transform(newDate1, "yyyy-MM-dd HH:mm:ss");


        this.commandeserv.getStatUser(searchdate).subscribe(data => {
          this.logger.trace('DATA', data);

          switch (data.status) {

            case 200: {
              this.logger.trace('data', data);
              this.items = data.body;
              this.loader.close();
              break;

            }
            case 201: {
              this.logger.trace('data', data);
              this.items = data.body;
              this.loader.close();
              break;

            }

            case 204: {
              this.logger.trace('data', data);
              this.items = new Statuser();
              this.loader.close();
              break;

            }

          }
        },
          error => {
            this.logger.error('ERREUR', error);
            switch (error.status) {


              default: {
                this.loader.close();
                this.snackbar.open('Echec !! Une erreur est survenue ', 'OK', {
                  duration: 10000
                });
                this.items = new Statuser();
                break;
              }
            }
          });

      } else {
        this.snackbar.open('Format de date invalide ', 'OK', { duration: 4000 });
      }
    }





  }







}
