import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { GestionagentformComponent } from './pages/gestionagent/gestionagentform/gestionagentform.component';
import { GestionagentModule } from './pages/gestionagent/gestionagent.module';
import { GestionpointlivraisonModule } from './pages/gestionpointlivraison/gestionpointlivraison.module';
import { AuthInterceptor } from './pages/authentication/AuthInterceptor';
import { ParametragesModule } from './pages/parametrages/parametrages.module';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule, LOCALE_ID } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ApploaderComponent } from './services/apploader/apploader.component';
import { ApploaderService } from './services/apploader/apploader.service';
import { JournalcaisseModule } from './pages/journalcaisse/journalcaisse.module';

import 'hammerjs'; // Needed for Touch functionality of Material Components
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LayoutModule } from './layout/layout.module';
import { AgmCoreModule } from '@agm/core';
import { environment } from '../environments/environment';
import { PendingInterceptorModule } from '../@fury/shared/loading-indicator/pending-interceptor.module';
import {
  MAT_FORM_FIELD_DEFAULT_OPTIONS,
  MAT_SNACK_BAR_DEFAULT_OPTIONS,
  MatFormFieldDefaultOptions,
  MatSnackBarConfig,
  MAT_DATE_LOCALE
} from '@angular/material';

//GestionclientModule
import { GestionclientModule } from './pages/gestionclient/gestionclient.module';


import { GestionmenuModule } from './pages/gestionmenu/gestionmenu.module';
import { GestioncommandeModule } from './pages/gestioncommande/gestioncommande.module';
import { GestioncaissiereModule } from './pages/gestioncaissiere/gestioncaissiere.module';
import { GestionventecaisseModule } from './pages/gestionventecaisse/gestionventecaisse.module';
import { MonprofilModule } from './pages/monprofil/monprofil.module';
import { GestionlivraisonModule } from './pages/gestionlivraison/gestionlivraison.module';


import { GestionprofilModule } from './pages/gestionprofil/gestionprofil.module';
import { GestionutilisateurModule } from './pages/gestionutilisateur/gestionutilisateur.module';
import { PisteauditModule } from './pages/pisteaudit/pisteaudit.module';
import { ConfigModule } from 'ngx-envconfig';
import { LoggerModule, NgxLoggerLevel } from 'ngx-logger';
import { LocationStrategy, HashLocationStrategy, registerLocaleData, DatePipe } from '@angular/common';
import { ConfirmComponent } from './confirm/confirm.component';
import { CategoriemenuModule } from './pages/categoriemenu/categoriemenu.module';
import localeFr from '@angular/common/locales/fr';
import { NgxPermissionsModule } from 'ngx-permissions';
import { CategorieModifierComponent } from './pages/parametrages/categorie/categorie-modifier/categorie-modifier.component';
import { DeletedialogcategorieComponent } from './pages/parametrages/categorie/deletedialogcategorie/deletedialogcategorie.component';
import { StatclientModule } from './pages/statclient/statclient.module';
import { StatforclientModule } from './pages/statforclient/statforclient.module';
import { CarteabonnementModule } from './pages/carteabonnement/carteabonnement.module';

import { HistoriquecarteModule } from './pages/historiquecarte/historiquecarte.module';
import { CartestatusModule } from './pages/cartestatus/cartestatus.module';

import { CarteabonnementfileModule } from './pages/carteabonnementfile/carteabonnementfile.module';
import { AppProgressbarComponent } from './pages/apps/app-progressbar/app-progressbar.component';
import { TvaModule } from './pages/tva/tva.module';
import { HeurecmdModule } from './pages/heurecmd/heurecmd.module';
import { AttenteModule } from './pages/attente/attente.module';
import { AnnulationModule } from './pages/annulation/annulation.module';
import { GestioncuisineModule } from './pages/gestioncuisine/gestioncuisine.module';
import { CreditercarteModule } from './pages/creditercarte/creditercarte.module';
import { GestionparametreModule } from './pages/gestionparametre/gestionparametre.module';

import { GestionlivreurModule } from './pages/gestionlivreur/gestionlivreur.module';
import { CartemasterhistoriqueModule } from './pages/cartemasterhistorique/cartemasterhistorique.module';
import { CartemasterloadbalanceModule } from './pages/cartemasterloadbalance/cartemasterloadbalance.module';
import { CartemasterloadcreditModule } from './pages/cartemasterloadcredit/cartemasterloadcredit.module';
import { StatperiodiqueModule } from './pages/statperiodique/statperiodique.module';
import { GestionpointventeModule } from './pages/gestionpointvente/gestionpointvente.module';
import { AddComponent } from './pages/gestionpointvente/add/add.component';
import { UpdateComponent } from './pages/gestionpointvente/update/update.component';
import { StatistiqueproductModule } from './pages/statistiqueproduct/statistiqueproduct.module';
import { StatistiqueventeModule } from './pages/statistiquevente/statistiquevente.module';
import { GestioncorporateModule } from './pages/gestioncorporate/gestioncorporate.module';
import { FormuleAbonnementModule } from './pages/formuleabonnement/formuleabonnement.module';
import { DemandeAbonnementModule } from './pages/demandeabonnement/demandeabonnement.module';
import { GestionstatuserModule } from './pages/gestionstatuser/gestionstatuser.module';
import { AbonnementmasseModule } from './pages/abonnementmasse/abonnementmasse.module';
import { DecouvertModule } from './pages/decouvert/decouvert.module';
import { TypecarteModule } from './typecarte/typecarte.module';
import { DemandedrhModule } from './pages/demandedrh/demandedrh.module';
import { AbbatementModule } from './pages/abbatement/abbatement.module';
import { CategoriecorpoModule } from './pages/categoriecorpo/categoriecorpo.module';
import { CatcorpoformuleModule } from './pages/catcorpoformule/catcorpoformule.module';
import { DemandedisableprecompteModule } from './pages/demandedisableprecompte/demandedisableprecompte.module';
import { DemandequitcrptModule } from './pages/demandequitcrpt/demandequitcrpt.module';
import { DmdprecpteComponent } from './dmdprecpte/dmdprecpte.component';
import { PrecptedemandeModule } from './pages/precptedemande/precptedemande.module';
import { StatforproductModule } from './pages/statforproduct/statforproduct.module';
import { CloturecaisseModule } from './pages/cloturecaisse/cloturecaisse.module';
import { HistodecouvertModule } from './pages/histodecouvert/histodecouvert.module';
import { FileattenteComponent } from './pages/fileattente/fileattente.component';
import { FileattenteModule } from './pages/fileattente/fileattente.module';
import { SpecialorderModule } from './pages/specialorder/specialorder.module';
import { GestionstartModule } from './pages/gestionstart/gestionstart.module';
import { GestionsoldeModule } from './pages/gestionsolde/gestionsolde.module';
import { GestionincidentModule } from './pages/gestionincident/gestionincident.module';
import { GestionperteModule } from './pages/gestionperte/gestionperte.module';
import { SimplificationcorporateModule } from './pages/simplificationcorporate/simplificationcorporate.module';

registerLocaleData(localeFr);


@NgModule({
  imports: [
    // Angular Core Module // Don't remove!
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,

    // Fury Core Modules
    AppRoutingModule,
    StatforclientModule,
    DemandedisableprecompteModule,
    DemandequitcrptModule,
    // Layout Module (Sidenav, Toolbar, Quickpanel, Content)
    LayoutModule,
    ReactiveFormsModule,
    FormsModule,
    GestionpointventeModule,
    GestionmenuModule,
    CategoriemenuModule,
    GestioncommandeModule,
    GestioncaissiereModule,
    GestionventecaisseModule,
    GestionparametreModule,
    CarteabonnementModule,
    GestionpointlivraisonModule,
    GestionstatuserModule,
    CreditercarteModule,
    StatistiqueproductModule,
    StatistiqueventeModule,
    AttenteModule,
    AnnulationModule,
    StatperiodiqueModule,
    HistoriquecarteModule,
    CartestatusModule,
    GestionagentModule,
    TvaModule,
    GestionlivreurModule,
    HeurecmdModule,
    MonprofilModule,
    SpecialorderModule,
    ParametragesModule,
    GestionprofilModule,
    GestionutilisateurModule,
    GestioncorporateModule,
    DecouvertModule,
    PisteauditModule,
    GestionlivraisonModule,
    GestionsoldeModule,
    GestionclientModule,
    StatclientModule,
    GestionstartModule,
    CarteabonnementfileModule,
    JournalcaisseModule,
    GestioncuisineModule,
    CartemasterhistoriqueModule,
    CartemasterloadbalanceModule,
    CartemasterloadcreditModule,
    FormuleAbonnementModule,
    DemandeAbonnementModule,
    DemandedrhModule,
    AbonnementmasseModule,
    AbbatementModule,
    TypecarteModule,
    CategoriecorpoModule,
    PrecptedemandeModule,
    CatcorpoformuleModule,
    StatforproductModule,
    CloturecaisseModule,
    HistodecouvertModule,
    GestionincidentModule,
    GestionperteModule,
    FileattenteModule,
    SimplificationcorporateModule,
    // Google Maps Module
    AgmCoreModule.forRoot({
      apiKey: environment.googleMapsApiKey
    }),

    // ngx-envconfig
    // ConfigModule.forRoot({state: 'developpement'}),
    ConfigModule.forRoot({ state: environment.state }),

    // Logger
    // tslint:disable-next-line:max-line-length
    LoggerModule.forRoot({ serverLoggingUrl: environment.serverLoggingUrl, level: NgxLoggerLevel.TRACE, serverLogLevel: NgxLoggerLevel.ERROR }),
    LoggerModule.forRoot({
      level: environment.state === 'production' ? NgxLoggerLevel.OFF :
        environment.state === 'developpement' ? NgxLoggerLevel.TRACE : NgxLoggerLevel.TRACE,
      serverLogLevel: NgxLoggerLevel.OFF
    }),

    NgxPermissionsModule.forRoot(),

    // Displays Loading Bar when a Route Request or HTTP Request is pending
    PendingInterceptorModule,


    // Register a Service Worker (optional)
    // ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production })
  ],
  declarations: [AppComponent, ApploaderComponent, ConfirmComponent, DmdprecpteComponent],
  bootstrap: [AppComponent],
  providers: [
    DatePipe,
    {
      provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
      useValue: {
        appearance: 'fill'
      } as MatFormFieldDefaultOptions
    },
    {
      provide: MAT_SNACK_BAR_DEFAULT_OPTIONS,
      useValue: {
        duration: 5000,
        horizontalPosition: 'end',
        verticalPosition: 'bottom'
      } as MatSnackBarConfig
    },
    { provide: LocationStrategy, useClass: HashLocationStrategy },
    ApploaderService,
    {
      provide: LOCALE_ID,
      useValue: 'fr-FR'
    },
    {
      provide: MAT_DATE_LOCALE,
      useExisting: LOCALE_ID
    },

  ]
  ,
  entryComponents: [
    ApploaderComponent, ConfirmComponent, DeletedialogcategorieComponent, CategorieModifierComponent, AddComponent, UpdateComponent
  ]
})
export class AppModule {
}
