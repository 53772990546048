import { Component, Inject, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { NGXLogger } from 'ngx-logger';
import { Pointlivraison, Pointvente } from 'src/app/models/pointvente';

@Component({
  selector: 'fury-updatelivraison',
  templateUrl: './updatelivraison.component.html',
  styleUrls: ['./updatelivraison.component.scss']
})
export class UpdatelivraisonComponent implements OnInit {


  form: FormGroup;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<UpdatelivraisonComponent>,
    private fb: FormBuilder,
    private logger: NGXLogger) {
  }

  ngOnInit() {
    this.logger.info(this.data.payload);
    let pointvente: any = this.data.payload;
    if (pointvente) {
      this.form = this.fb.group({
        pointlivraisonid: [pointvente.pointlivraisonid, Validators.compose([Validators.required])],
        pointlivraisonzone: [pointvente.pointlivraisonzone, Validators.compose([Validators.required])],
        pointlivraisonduree: [pointvente.pointlivraisonduree, Validators.compose([Validators.required])],
      });
    }
  }



  submit() {
    //    this.form.
    this.dialogRef.close(this.form.value);
  }

}
