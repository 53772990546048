
export class LivraisonDto {
    livraisonmodename: string;

     livraisonmodcode: string;

    public constructor() {

    }

}