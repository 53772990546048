import { Component, Inject, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { NGXLogger } from 'ngx-logger';
import { DemandeDto } from 'src/app/models/agentstorage';
import { FormuleAbonnement } from 'src/app/models/formule-abonnement';

@Component({
  selector: 'fury-updatedemande',
  templateUrl: './updatedemande.component.html',
  styleUrls: ['./updatedemande.component.scss']
})
export class UpdateDemandeComponent implements OnInit {
  form: FormGroup;
  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<UpdateDemandeComponent>,
    private fb: FormBuilder,
    private logger: NGXLogger) {
  }

  ngOnInit() {
    this.logger.info(this.data.payload);
    this.form = this.fb.group({
      decouvert: [, Validators.compose([Validators.required])],
    });
  }



  submit() {
    let demandeAbn: DemandeDto = this.data.payload;
    if (demandeAbn) {
      if (this.form.get("decouvert").value === '1') {
        demandeAbn.demandeabndcvrt = true;
      } else if (this.form.get("decouvert").value === '2') {
        demandeAbn.demandeabndcvrt = false;
      }

      demandeAbn.demandeabnstatus = 1;
      demandeAbn.demandeabnprecpte = true;

      console.log(" OBJECT BEFORE SEND");
      console.log(demandeAbn);

      this.dialogRef.close(demandeAbn);
    } else {
      this.dialogRef.close(false);
    }
  }

}
