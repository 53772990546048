import { Paymentmode } from 'src/app/models/Paymentmode';
import { PaymentmodeService } from './../../../../services/paymentmode.service';
import { MatDialogRef, MAT_DIALOG_DATA, MatSnackBar } from '@angular/material';
import { FormGroup, FormControl } from '@angular/forms';
import { Component, OnInit, Inject } from '@angular/core';


export interface DialogData {
  paymentmode: Paymentmode;
}
@Component({
  selector: 'fury-paymentmode-modifier',
  templateUrl: './paymentmode-modifier.component.html',
  styleUrls: ['./paymentmode-modifier.component.scss']
})
export class PaymentmodeModifierComponent implements OnInit {


  paymentmodeForm: FormGroup;
  paymentmodeId: string;
  paymentmode: Paymentmode;




  constructor(public dialogRef: MatDialogRef<PaymentmodeModifierComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private paymentmodeService: PaymentmodeService, private snackbar: MatSnackBar) { }


  onNoClick(): void {
    console.info('---clic on close --- ')
    this.dialogRef.close();
  }

  ngOnInit() {

    this.paymentmodeForm = new FormGroup({ paymentmodename: new FormControl(), paymentmodecode: new FormControl(),  paymentmodeisdeleted: new FormControl() })

    if (this.data) { // edit form
      console.log('checking selected paymentmode--- : ' + this.data.paymentmode.paymentmodename);
      this.paymentmodeForm.reset({ paymentmodename: this.data.paymentmode.paymentmodename, paymentmodecode: this.data.paymentmode.paymentmodecode, paymentmodeisdeleted: this.data.paymentmode.paymentmodeisdeleted });
    }

  }


  onSubmit() {

    if (this.paymentmodeForm.valid) {
      console.log('-----------updating paymentmode ----------------');

      let updatedPaymentmode: Paymentmode = new Paymentmode();
      updatedPaymentmode.paymentmodeid = this.data.paymentmode.paymentmodeid;
      updatedPaymentmode.paymentmodedatecreation = this.data.paymentmode.paymentmodedatecreation;
      updatedPaymentmode.paymentmodename = this.paymentmodeForm.controls['paymentmodename'].value;
      updatedPaymentmode.paymentmodecode = this.paymentmodeForm.controls['paymentmodecode'].value;

      updatedPaymentmode.paymentmodeisdeleted = this.data.paymentmode.paymentmodeisdeleted;

      console.log('-----------data to update  ---------------- ' + updatedPaymentmode.paymentmodeid + ' ' +
        updatedPaymentmode.paymentmodename + ' ' + updatedPaymentmode.paymentmodecode+ ' ' + updatedPaymentmode.paymentmodeisdeleted);


      this.paymentmodeService.update(updatedPaymentmode).subscribe(data => {

        console.log(data);
        switch (data.status) {
          case 201: {
            // this.loading.dismiss('messagesService.loadMessagesOverview');

            let resp: Paymentmode;
            resp = data.body;
            console.log("------------------------------------");
            console.log(resp);
            console.log("------------------------------------");


            /* Swal.fire({
               position: 'top-end',
               type: 'success',
               title: 'Your work has been saved',
               showConfirmButton: false,
               timer: 1500
             })*/




            this.snackbar.open('Success modification', 'OK', {
              duration: 2000
            });

            break;
          }
        }
      },
        error => {

          console.log("erreur retour");
          // this.loading.dismiss('messagesService.loadMessagesOverview');
          switch (error.status) {
            case 409:
              //this.FormReg(" Mot de passe ou login incorrect ");
              this.snackbar.open('Echec modification', 'Le mode de payment existe deja', {
                duration: 2000
              });
              break;
            default: {
              // this.FormReg(" erreur de connexion !!! Veuillez ressayer");
              this.snackbar.open('Echec modification', 'OK', {
                duration: 2000
              });
              break;
            }
          }

        });


      console.log('-----------updating paymentmode ok ----------------');


      this.dialogRef.close();
    } else {


      // this.validateAllFormFields(this.paymentmodeForm);
      this.snackbar.open('Veuillez saisir toutes les valeurs svp', 'OK', {
        duration: 2000
      });
    }




  }




}
