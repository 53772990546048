import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'fury-showmsg',
  templateUrl: './showmsg.component.html',
  styleUrls: ['./showmsg.component.scss']
})
export class ShowmsgComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
