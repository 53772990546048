import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'fury-showenabledemande',
  templateUrl: './showenabledemande.component.html',
  styleUrls: ['./showenabledemande.component.scss']
})
export class ShowenabledemandeComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
