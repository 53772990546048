import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'fury-gestioncuisine',
  templateUrl: './gestioncuisine.component.html',
  styleUrls: ['./gestioncuisine.component.scss']
})
export class GestioncuisineComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
