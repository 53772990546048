import { NGXLogger } from 'ngx-logger';
import { ConfigService } from 'ngx-envconfig';
import { HttpResponse, HttpHeaders, HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs/internal/Observable';
import { Injectable } from '@angular/core';
import { Agentstorage } from 'src/app/models/agentstorage';
import { HistoactiviteDto } from 'src/app/models/histoactiviteDto';

@Injectable({
  providedIn: 'root'
})
export class HistoactiviteService {

  authorization: string;

  private crudHistoactivite = this.configService.get('ipMS') + 'histoactivite';
  private crudHistoactiviteLocal = this.configService.get('ipLocalMS') + 'histoactivite';
  private statUrl = this.configService.get('ipMS');


  constructor(private http: HttpClient, private configService: ConfigService,
    private logger: NGXLogger) {
    let user: Agentstorage = new Agentstorage();
    user = JSON.parse(localStorage.getItem("curentuser"));

    //user = JSON.parse(localStorage.getItem(this.configService.get('keyUserOnline')));

    if (user != null) {
      this.authorization = user.token;
    }
  }

  getHistoactivite(): Observable<HttpResponse<any>> {
    return this.http.get(this.crudHistoactivite, {
      observe: 'response',
        headers: new HttpHeaders({ 'Access-Control-Allow-Origin': '*' })
          .set('content-Type', 'application/json')
          .set('Authorization', this.authorization)
      });
  }

  findHistoactivitebyCaisseid(caisseid: string): Observable<HttpResponse<any>> {
    return this.http.get(this.crudHistoactivite + '/findbycaisseid/' + caisseid, {
      observe: 'response',
        headers: new HttpHeaders({ 'Access-Control-Allow-Origin': '*' })
          .set('content-Type', 'application/json')
          .set('Authorization', this.authorization)
      });
  }


  getHistoactiviteByCaisseAndDate(value: HistoactiviteDto): Observable<HttpResponse<any>> {
    return this.http.post(this.crudHistoactivite + '/' + 'findbycaissedate', value, {
      observe: 'response',
        headers: new HttpHeaders({ 'Access-Control-Allow-Origin': '*' })
          .set('content-Type', 'application/json')
          .set('Authorization', this.authorization)
      });
  }

  getPrecHistoactiviteByCaisseAndDate(value: HistoactiviteDto): Observable<HttpResponse<any>> {
    return this.http.post(this.crudHistoactivite + '/' + 'findprecbycaissedate', value, {
      observe: 'response',
        headers: new HttpHeaders({ 'Access-Control-Allow-Origin': '*' })
          .set('content-Type', 'application/json')
          .set('Authorization', this.authorization)
      });
  }



  public createHistoactivite(value: HistoactiviteDto): Observable<HttpResponse<any>> {
    return this.http.post(this.crudHistoactivite, JSON.stringify(value), {
      observe: 'response',
      headers: new HttpHeaders({ 'Access-Control-Allow-Origin': '*' })
        .set("content-Type", "application/json")
        .set("Authorization", this.authorization)
    });
  }



  public updateHistoactivite(value: HistoactiviteDto): Observable<HttpResponse<any>> {
    return this.http.put(this.crudHistoactivite, JSON.stringify(value), {
      observe: 'response',
      headers: new HttpHeaders({ 'Access-Control-Allow-Origin': '*' })
        .set("content-Type", "application/json")
        .set("Authorization", this.authorization)
    });
  }




  public getChiffreAffaireByCaisse(caisseid: string, date: string): Observable<HttpResponse<any>> {
    return this.http.get(this.statUrl + 'stats/chiffreaffaire/bycaisse/' + caisseid + '/' + date, {
      observe: 'response',
      headers: new HttpHeaders({ 'Access-Control-Allow-Origin': '*' })
        .set('content-Type', 'application/json')
        .set('Authorization', this.authorization)
    });
  }


}
