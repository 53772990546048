import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GestionlivreurComponent } from './gestionlivreur.component';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { MaterialModule } from 'src/@fury/shared/material-components.module';
import { BreadcrumbsModule } from 'src/@fury/shared/breadcrumbs/breadcrumbs.module';
import { MatInputModule, MatChipsModule, MatDatepickerModule } from '@angular/material';
import { FuryCardModule } from 'src/@fury/shared/card/card.module';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { MatDatetimepickerModule } from '@mat-datetimepicker/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ShowlivreurComponent } from './showlivreur/showlivreur.component';
import { NewlivreurComponent } from './newlivreur/newlivreur.component';
import { GlobalconfigComponent } from './globalconfig/globalconfig.component';
import { WaitingcmdComponent } from './waitingcmd/waitingcmd.component';
import { DeliverycmdComponent } from './deliverycmd/deliverycmd.component';
import { ZonedeliveryComponent } from './zonedelivery/zonedelivery.component';

@NgModule({
  declarations: [GestionlivreurComponent, ShowlivreurComponent, NewlivreurComponent, GlobalconfigComponent, WaitingcmdComponent, DeliverycmdComponent, ZonedeliveryComponent],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    MaterialModule,
    BreadcrumbsModule,
    MatInputModule,
    NgxDatatableModule,
    FuryCardModule,
    MatChipsModule,
    MatDatepickerModule,
    MatDatetimepickerModule,
    BrowserAnimationsModule
  ],
  entryComponents: [ShowlivreurComponent , NewlivreurComponent , GlobalconfigComponent , ZonedeliveryComponent]
})
export class GestionlivreurModule { }
