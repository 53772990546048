import { Component, HostBinding, HostListener, Input, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { SidenavItem } from './sidenav-item/sidenav-item.interface';
import { SidenavState } from './sidenav-state.enum';
import { SidenavService } from './sidenav.service';
import { ThemeService } from '../../../@fury/services/theme.service';

@Component({
  selector: 'fury-sidenav',
  templateUrl: './sidenav.component.html',
  styleUrls: ['./sidenav.component.scss']
})
export class SidenavComponent implements OnInit, OnDestroy {

  sidenavUserVisible$ = this.themeService.config$.pipe(map(config => config.sidenavUserVisible));

  @Input()
  @HostBinding('class.collapsed')
  collapsed: boolean;

  @HostBinding('class.expanded')
  expanded: boolean;

  items$: Observable<SidenavItem[]>;

  sidenavState$: Observable<SidenavState>;
  sidenavState: string;

  isCollapsedState: boolean;

  constructor(private router: Router,
    private sidenavService: SidenavService,
    private themeService: ThemeService) {
  }

  ngOnInit() {


    /*

    let myitems: SidenavItem[] = [];


    myitems.push({ "name": "Dashboard", "routeOrFunction": "/dashboard", "icon": "dashboard", "position": 10, "pathMatchExact": true });

 
    myitems.push(
      { "name": "Gestion Des Agents", "routeOrFunction": "/dashboard/gestionagent", "icon": "perm_identity", "position": 40 }
    );

    
  
    myitems.push(
      { "name": "Parametres", "routeOrFunction": "/dashboard/gestionparametres", "icon": "settings", "position": 50 }
    );
    */
  

    this.items$ = this.sidenavService.items$.pipe(
      map((items: SidenavItem[]) => this.sidenavService.sortRecursive(items, 'position'))
    );


    /*
    let myitems : SidenavItem[] = [] ;
    let s1 : SidenavItem = new SidenavItem() ;
    s1.name="Dashboard";
    myitems.push(s1);
    this.items$ =new Observable<SidenavItem[]>();
    */


  }

  /*
    getAllItems(){
      this.sidenavService.getSidenavItem().subscribe(data => {
        this.items$ = data;
      })
    }
    */

  toggleCollapsed() {
    this.sidenavService.toggleCollapsed();
  }

  @HostListener('mouseenter')
  @HostListener('touchenter')
  onMouseEnter() {
    if (this.collapsed) {
      this.expanded = true;
    }
  }

  @HostListener('mouseleave')
  @HostListener('touchleave')
  onMouseLeave() {
    if (this.collapsed) {
      this.expanded = false;
    }
  }

  ngOnDestroy() {
  }
}
