import { Component, OnInit, Inject } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { Carteabonnement } from 'src/app/models/carteabonnement';
import { ChangestatusComponent } from '../../gestionlivraison/changestatus/changestatus.component';
import { CarteabonnementService } from '../../carteabonnement/carteabonnement.service';
import { ReplaySubject, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'fury-cartecredit',
  templateUrl: './cartecredit.component.html',
  styleUrls: ['./cartecredit.component.scss']
})
export class CartecreditComponent implements OnInit {


  items: Carteabonnement[] = [];
  private _onDestroy = new Subject<void>();
  public filteredCarteabonnement: ReplaySubject<Carteabonnement[]> = new ReplaySubject<Carteabonnement[]>(1);
  public carteabonnementFilterCtrl: FormControl = new FormControl();

  statusForm: FormGroup;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<ChangestatusComponent>,
    private carteserv: CarteabonnementService) {
  }

  ngOnInit() {

    this.getAllCarte();


    this.carteabonnementFilterCtrl.valueChanges
      .pipe(takeUntil(this._onDestroy))
      .subscribe(() => {
        this.filterCarteabonnement();
      });
    this.statusForm = new FormGroup({
      carteabonnementcodereference: new FormControl('', [Validators.required]),
      solde: new FormControl('', [Validators.required])
    });

  }




  getAllCarte() {
    this.carteserv.getAllCarteabonnement()
      .subscribe(data => {
        switch (data.status) {
          case 200: {
            this.items = data.body;
            this.filteredCarteabonnement.next(this.items.slice());

            break;
          }

        }
      },
        error => {


          switch (error.status) {

            default: {
              /*
              this.snackbar.open('Un erreur est survenue', 'OK', {
                duration: 10000
              });
              */
              break;
            }
          }
        });
  }


  submit() {
    this.dialogRef.close(this.statusForm.value);
  }



  private filterCarteabonnement() {
    if (!this.items) {
      return;
    }
    // get the search keyword
    let search = this.carteabonnementFilterCtrl.value;
    if (!search) {
      this.filteredCarteabonnement.next(this.items.slice());
      return;
    } else {
      search = search.toLowerCase();
    }
    // filter the banks
    this.filteredCarteabonnement.next(
      this.items.filter(bank => bank.carteabonnementcodereference.toLowerCase().indexOf(search) > -1)
    );
  }

}
