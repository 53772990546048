import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialog, MatSnackBar, MatTableDataSource } from '@angular/material';
import { ActivatedRoute, Params } from '@angular/router';
import { ConfigService } from 'ngx-envconfig';
import { NGXLogger } from 'ngx-logger';
import { NgxPermissionsService } from 'ngx-permissions';
import { Subscription } from 'rxjs';
import { ConfirmationService } from 'src/app/confirm/confirmation.service';
import { ApploaderService } from 'src/app/services/apploader/apploader.service';
import { DeliveryService } from 'src/app/services/delivery.service';
import { ShowficheComponent } from '../../fileattente/showfiche/showfiche.component';

@Component({
  selector: 'fury-waitingcmd',
  templateUrl: './waitingcmd.component.html',
  styleUrls: ['./waitingcmd.component.scss']
})
export class WaitingcmdComponent implements OnInit {


  public items: any[];
  public itemsFinal: any[];
  temp = [];
  rows = [];
  columns = [];

  id : string ;
  state : any ;
  title : string ;

  public itemForm: FormGroup;

  public commands: Array<any> = [];
  dataSource: MatTableDataSource<any> = new MatTableDataSource([]);
  public getItemSub: Subscription;

  constructor(private dialog: MatDialog, public formBuilder: FormBuilder,
    private snackbar: MatSnackBar, private commandeserv: DeliveryService,
    private datePipe: DatePipe, private activatedRoute: ActivatedRoute ,
    private confirmService: ConfirmationService, private loader: ApploaderService,
    private permissionsService: NgxPermissionsService, private logger: NGXLogger,
    private configService: ConfigService, private fb: FormBuilder) { }
    
  ngOnInit() {

    this.activatedRoute.queryParams.forEach((params: Params) => {
      this.id = params['key'];
      this.state = params['state'];
      this.getById();

    });

    /*
    this.activatedRoute.params.subscribe(params => {
       this.id = params['key'];
      console.log("******* id ***** : " + this.id);
      this.getById();
    });
    */
  }



  updateFilter(event) {
    const val = event.target.value.toLowerCase();
    var columns = Object.keys(this.temp[0]);
    // Removes last "$$index" from "column"
    columns.splice(columns.length - 1);

    // console.log(columns);
    if (!columns.length)
      return;

    const rows = this.temp.filter(function (d) {
      for (let i = 0; i <= columns.length; i++) {
        let column = columns[i];
        // console.log(d[column]);
        if (d[column] && d[column].toString().toLowerCase().indexOf(val) > -1) {
          return true;
        }
      }
    });

    this.items = rows;
  }

  verifyFalse(value: any) {
    if (value === true) {
      return 'oui';
    } else if (value === false) {
      return 'non';
    }
  }

  verifyStatus(value: any) {
    if (value === "delivery.level1") {
      return 'EN ATTENTE PRISE EN COMPTE CUISINE';
    } else if (value === "delivery.level2") {
      return 'EN ATTENTE FIN PREPARATION CUISINE';
    } else if (value === "delivery.level3") {
      return 'EN ATTENTE PRISE EN COMPTE EXPEDITEUR';
    } else if (value === "delivery.level4") {
      return 'EN ATTENTE FIN PACKAGING';
    } else if (value === "delivery.level5") {
      return 'EN ATTENTE ATTRIBUTION LIVREUR';
    } else if (value === "delivery.level6") {
      return 'EN COURS DE LIVRAISON';
    }  else if (value === "delivery.ok") {
      return 'LIVREE';
    }
  }

  getCommande(ref) {
    this.getItemSub = this.commandeserv.get("fileattente/findbyid/" + ref)
      .subscribe(data => {
        switch (data.status) {
          default: {
            this.logger.trace('data', data.body);
            let response: any = data.body;


            this.dialog.open(ShowficheComponent,
              {
                width: '720px',
                data: { title: '', payload: response }
              }
            ).afterClosed().subscribe((res) => {
              console.log(res);
              if (!res) {
                return;
              }
            });
            break;
          }
        }
      },
        error => {
          console.log('error');
          console.log(error);
          switch (error.status) {
            default: {
              break;
            }
          }
        });
  }
  
  getById() {

    let url : string ;
    if(this.state==1){
      this.title="COMMANDE EN COURS" ;
      url="livreurorder/inwaiting/";
    }

    if(this.state==2){
      this.title="COMMANDE LIVREE" ;
      url="livreurorder/valide/";
    }

    this.getItemSub = this.commandeserv.get(url+ this.id)
      .subscribe(data => {
        switch (data.status) {
          case 200: {
            this.logger.trace('data', data);
            let response: any = data.body;
            let resp1: any[] = response.listFileAttente;
            console.log(resp1);
  
            this.items = this.temp = resp1;
            break;
          }

          default: {
            this.items = [] ;
            break;
          }


        }
      },
        error => {
          this.logger.trace('error');
          this.logger.error(error);

          switch (error.status) {

            default: {
              this.items = [] ;
              /*
              this.snackbar.open('Un erreur est survenue', 'OK', {
                duration: 10000
              });
              */
              break;
            }
          }
        });
  }

}
