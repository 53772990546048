import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'fury-statcommandsurplace',
  templateUrl: './statcommandsurplace.component.html',
  styleUrls: ['./statcommandsurplace.component.scss']
})
export class StatcommandsurplaceComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
