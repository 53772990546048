import { Component, OnInit } from '@angular/core';
import { Client } from 'src/app/models/Agent';
import { Subscription } from 'rxjs';
import { Router, ActivatedRoute } from '@angular/router';
import { StatforclientService } from '../statforclient.service';
import { NGXLogger } from 'ngx-logger';
import { ApploaderService } from 'src/app/services/apploader/apploader.service';
import { MatSnackBar } from '@angular/material';
import { Statspaiement } from 'src/app/models/statspaiement';

import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';

const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
const EXCEL_EXTENSION = '.xlsx';

@Component({
  selector: 'fury-statforall',
  templateUrl: './statforall.component.html',
  styleUrls: ['./statforall.component.scss']
})
export class StatforallComponent implements OnInit {

  itemsAE: Statspaiement = new Statspaiement();
  itemsSP: Statspaiement = new Statspaiement();
  itemsAL: Statspaiement = new Statspaiement();


  items = [];
  //itemsEmporte = [];


  user: Client;

  public getItemSub: Subscription;
  temp = [];

  constructor(private router: Router, private statforclientserv: StatforclientService, private activatedRoute: ActivatedRoute
    , private logger: NGXLogger, private loader: ApploaderService, private snackbar: MatSnackBar) { }

  ngOnInit() {
    this.activatedRoute.params.subscribe(params => {
      let id = params['id'];
      this.getClientById(id);
      this.getAllCommandByClient(id);
    });
  }

  redirectTo(value: string) {
    //router.navigate(['user', user.id, 'details']);
    let valuefinale: string = value;
    valuefinale = valuefinale + "/" + this.statforclientserv.userparamid;

    this.router.navigate([valuefinale]);
  }




  verifyFalse(value: any) {
    if (value === true) {
      return 'oui';
    } else if (value === false) {
      return 'non';
    }
  }

  getClientById(value: string) {
    this.getItemSub = this.statforclientserv.getDataForUser(value)
      .subscribe(data => {
        switch (data.status) {
          case 200: {
            this.logger.trace('data', data);
            this.user = this.temp = data.body;
            this.statforclientserv.$user = this.user;
            break;
          }

          case 204: {
            break;
          }

          default: {
            /*
                        this.logger.trace('data', data);
                        this.user = this.temp = data.body;
            */
            break;
          }


        }
      },
        error => {
          this.logger.trace('error');
          this.logger.error(error);

          switch (error.status) {

            default: {
              /*
              this.snackbar.open('Un erreur est survenue', 'OK', {
                duration: 10000
              });
              */
              break;
            }
          }
        });
  }


  reloadDelivery(value: string) {

  }



  getAllCommandByClient(id: string) {
    console.log("******* getAllCommandByClient ***** : " + id);

    this.getItemSub = this.statforclientserv.getAllCommande(id)
      .subscribe(data => {
        switch (data.status) {
          case 200: {
            this.logger.trace('data', data);
            this.items = this.temp = data.body;

            let itemsSurPlace = [];
            let itemsAemporter = [];
            let itemsAlivrer = [];

            itemsSurPlace = this.items.filter(
              historiq => historiq.livraisonmode === 'Sur place');


            itemsAemporter = this.items.filter(
              historiq => historiq.livraisonmode === 'A emporter');


            itemsAlivrer = this.items.filter(
              historiq => historiq.livraisonmode === 'A livrer');

            this.itemsSP.nombre = itemsSurPlace.length;
            this.itemsAE.nombre = itemsAemporter.length;
            this.itemsAL.nombre = itemsAlivrer.length;

            const sumSurplace = itemsSurPlace
              .reduce((sum, historiq) => sum + historiq.totalcommandprix, 0);

            const sumAemporter = itemsAemporter
              .reduce((sum, historiq) => sum + historiq.totalcommandprix, 0);

            const sumAlivrer = itemsAlivrer
              .reduce((sum, historiq) => sum + historiq.totalcommandprix, 0);



            this.itemsSP.somme = sumSurplace;
            this.itemsAE.somme = sumAemporter;
            this.itemsAL.somme = sumAlivrer;




            break;
          }

          case 204: {
            break;
          }

          default: {
            /*
                        this.logger.trace('data', data);
                        this.user = this.temp = data.body;
            */
            this.logger.trace('data', data);
            break;
          }


        }
      },
        error => {
          this.logger.trace('error');
          this.logger.error(error);

          switch (error.status) {

            default: {
              /*
              this.snackbar.open('Un erreur est survenue', 'OK', {
                duration: 10000
              });
              */
              this.logger.trace('data', error);
              break;
            }
          }
        });
  }





}
