import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpHeaders } from '@angular/common/http';
import { ConfigService } from 'ngx-envconfig';
import { Agentstorage } from 'src/app/models/agentstorage';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ParametreService {


  private findall = this.configService.get('ipMS') + 'parameter/utils/getall';
  private push = this.configService.get('ipMS') + 'parameter/utils/update';

  private delivery = this.configService.get('delivery');

  private ipMs = this.configService.get('ipMS')  ;

  authorization: string = " ";
  caissierid: string = " ";


  constructor(private http: HttpClient, private configService: ConfigService) {
    let u = new Agentstorage();
    u = JSON.parse(localStorage.getItem('curentuser'));

    if (u != null) {
      this.authorization = u.token;
      this.caissierid = u.agent.agentid;

      console.log("authorization == " + this.authorization);
    }
  }

  getAllParametre(): Observable<HttpResponse<any>> {
    return this.http.get(this.findall,
      {
        observe: 'response',
        headers: new HttpHeaders({ 'Access-Control-Allow-Origin': '*' })
          .set("content-Type", "application/json")
          .set("Authorization", this.authorization)
      });
  }


  getParametre( url : String): Observable<HttpResponse<any>> {
    return this.http.get(this.ipMs+""+url,
      {
        observe: 'response',
        headers: new HttpHeaders({ 'Access-Control-Allow-Origin': '*' })
          .set("content-Type", "application/json")
          .set("Authorization", this.authorization)
      });
  }

  updateLimit(nbre: number, url: string): Observable<HttpResponse<any>> {
    return this.http.get(this.delivery + url + nbre,
      {
        observe: 'response',
        headers: new HttpHeaders({ 'Access-Control-Allow-Origin': '*' })
          .set("content-Type", "application/json")
          .set("Authorization", this.authorization)
      });
  }



  getLimit(url: string): Observable<HttpResponse<any>> {
    return this.http.get(this.delivery + url,
      {
        observe: 'response',
        headers: new HttpHeaders({ 'Access-Control-Allow-Origin': '*' })
          .set("content-Type", "application/json")
          .set("Authorization", this.authorization)
      });
  }




  public post(value: any): Observable<HttpResponse<any>> {
    console.log(value);
    console.log(" */*/*/*/*/*/* ");
    return this.http.post(this.push, JSON.stringify(value), {
      observe: 'response',
      headers: new HttpHeaders({ 'Access-Control-Allow-Origin': '*' })
        .set("content-Type", "application/json")
        .set('Authorization', this.authorization)
    });
  }


  public postNew(url : String , value: any): Observable<HttpResponse<any>> {
    console.log(value);
    return this.http.post(this.ipMs+""+url, JSON.stringify(value), {
      observe: 'response',
      headers: new HttpHeaders({ 'Access-Control-Allow-Origin': '*' })
        .set("content-Type", "application/json")
        .set('Authorization', this.authorization)
    });
  }


}
