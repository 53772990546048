import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { NGXLogger } from 'ngx-logger';
import { Router } from '@angular/router';
import { GestionprofilService } from './gestionprofil.service';
import { MatSnackBar } from '@angular/material';
import { ConfigService } from 'ngx-envconfig';
import { Agentstorage } from 'src/app/models/agentstorage';
import { Rolefonction } from 'src/app/models/rolefonction';
import { ConfirmationService } from 'src/app/confirm/confirmation.service';
import { RolefonctionService } from 'src/@fury/services/rolefonction.service';
import { Agent } from 'src/app/models/Agent';


import * as XLSX from 'xlsx';
import * as FileSaver from 'file-saver';


const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
const EXCEL_EXTENSION = '.xlsx';

@Component({
  selector: 'fury-gestionprofil',
  templateUrl: './gestionprofil.component.html',
  styleUrls: ['./gestionprofil.component.scss']
})
export class GestionprofilComponent implements OnInit {


  public items: any[];
  temp = [];
  rows = [];
  columns = [];
  scrollBarHorizontal = (window.innerWidth < 1200);
  getItemSub: Subscription;

  // Habilitation
  public afficherProfil: boolean;
  public creationProfil: boolean;
  public modificationProfil: boolean;
  public suppressionProfil: boolean;

  public keyAfficherProfil = 'afficher profil';
  public keyCreationProfil = 'creation profil';
  public keyModificationProfil = 'modification profil';
  public keySuppressionProfil = 'suppression profil';

  constructor(private logger: NGXLogger,
    private route: Router,
    private gestionprofilService: GestionprofilService,
    private rolefonctionService: RolefonctionService,
    private confirmService: ConfirmationService,
    private snack: MatSnackBar,
    private configService: ConfigService) { }

  ngOnInit() {
    this.verifierDroit();
    this.getProfil();
  }

  updateFilter(event) {
    const val = event.target.value.toLowerCase();
    var columns = Object.keys(this.temp[0]);
    // Removes last "$$index" from "column"
    columns.splice(columns.length - 1);

    // console.log(columns);
    if (!columns.length)
      return;

    const rows = this.temp.filter(function (d) {
      for (let i = 0; i <= columns.length; i++) {
        let column = columns[i];
        // console.log(d[column]);
        if (d[column] && d[column].toString().toLowerCase().indexOf(val) > -1) {
          return true;
        }
      }
    });

    this.items = rows;
  }

  public verifierDroit() {
    this.logger.trace('BEGIN');
    // this.logger.trace('Clé', this.configService.get('keylocalstorage'));

    let agentOnline: Agentstorage;
    agentOnline = JSON.parse(localStorage.getItem(this.configService.get('keylocalstorage')));
    // this.logger.trace(agentOnline);
    let rolefontion: Rolefonction[] = agentOnline.rolefonction;
    // this.logger.trace('rolefontion', rolefontion);

    for (const key in rolefontion) {
      if (rolefontion.hasOwnProperty(key)) {
        // this.logger.trace('KEY', key);
        const element = rolefontion[key].fonction.fonctionname;
        this.logger.trace('element', element);

        if (element == this.keyAfficherProfil) {
          this.logger.trace('true');
          this.afficherProfil = true;
        }
        if (element == this.keyCreationProfil) {
          this.logger.trace('true');
          this.creationProfil = true;
        }
        if (element == this.keyModificationProfil) {
          this.logger.trace('true');
          this.modificationProfil = true;
        }
        if (element == this.keySuppressionProfil) {
          this.logger.trace('true');
          this.suppressionProfil = true;
        }
      }
    }

  }

  createProfil() {
    this.logger.trace('Debut Creation de profil');
    this.route.navigate(['/yolinbo/ajouterprofil']);

  }

  modifierProfil(res) {
    this.logger.trace('Obj', res);
    this.route.navigate(['/yolinbo/modifierprofil'], { queryParams: { profil: res.roleid } });
  }


  getProfil() {
    this.getItemSub = this.gestionprofilService.getAllProfil()
      .subscribe(data => {

        switch (data.status) {

          default: {

            this.logger.trace('data', data);
            this.items = this.temp = data.body;

            break;
          }


        }
      },
        error => {
          this.logger.error('error');
          this.logger.error(error);

          switch (error.status) {

            default: {

              break;
            }
          }
        });
  }


  deleteItem(row) {

    this.confirmService.confirm({ message: `Supprimé ${row.rolename}?` })
      .subscribe(res => {
        if (res) {
          this.rolefonctionService.deleteAllprofil(row.roleid)
            .subscribe(data => {
              let statuscode = data.status;
              switch (statuscode) {
                case 200: {
                  // Actualisé les données
                  this.getProfil();
                  this.snack.open('Profil Supprimé!', 'OK', { duration: 4000 })
                  break;
                }
                case 500: {
                  this.snack.open('Internal server error!', 'OK', { duration: 4000 })
                  break;
                }
                default: {
                  this.snack.open('Une erreur est survenue!', 'OK', { duration: 4000 })
                  break;
                }
              }

            },
              error => {
                let statuscode = error.status;
                switch (statuscode) {
                  case 404: {
                    this.snack.open('Not found!', 'OK', { duration: 4000 })
                    break;
                  }
                  case 500: {
                    this.snack.open('Internal server error!', 'OK', { duration: 4000 })
                    break;
                  }
                  default: {
                    this.snack.open('Une erreur est survenue!', 'OK', { duration: 4000 })
                    break;
                  }
                }

              });
        }


      })
  }

 


  exportToXls() {
    let itemscsv: any[] = [];


    if (this.items.length > 0) {
      this.items.forEach(function (element) {
        console.log(element);

        var my_json = {
          "Profil": element.rolename,
          "Departement": element.roledepartement,
          "Date creation ": element.roledatecreation
        };
        console.log("my_json");
        console.log(my_json);
        itemscsv.push(my_json);
      });
      console.log(itemscsv);

      this.exportAsExcelFile(itemscsv, 'Historique Profil');

    }
  }
  public exportAsExcelFile(json: any[], excelFileName: string): void {
    const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(json);
    const workbook: XLSX.WorkBook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
    const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
    this.saveAsExcelFile(excelBuffer, excelFileName);
  }
  private saveAsExcelFile(buffer: any, fileName: string): void {
    const data: Blob = new Blob([buffer], { type: EXCEL_TYPE });
    FileSaver.saveAs(data, fileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION);
  }

}
