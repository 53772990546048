import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { NGXLogger } from 'ngx-logger';
import { Subscription } from 'rxjs';

@Component({
  selector: 'fury-show',
  templateUrl: './show.component.html',
  styleUrls: ['./show.component.scss']
})
export class ShowComponent implements OnInit {


  
   
  getItemSub: Subscription;

  selectedTypecard : any ;
  abbatementSelected : any ;


  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<ShowComponent>,
    private logger: NGXLogger) { }

  ngOnInit() {
    this.buildItemForm(this.data.payload);
  }

  buildItemForm(item){
    this.abbatementSelected = item ;
    this.logger.trace( this.abbatementSelected);

  }

}
