import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { Tva } from '../../models/tva';
import { NGXLogger } from 'ngx-logger';
import { MatSnackBar, MatDialog, MatDialogRef } from '@angular/material';
import { ConfirmationService } from 'src/app/confirm/confirmation.service';
import { ConfigService } from 'ngx-envconfig';

import { TvaService } from './tva.service';
import { AddtvaComponent } from './addtva/addtva.component';
import { Agentstorage } from 'src/app/models/agentstorage';
import { Rolefonction } from 'src/app/models/rolefonction';


import * as XLSX from 'xlsx';
import * as FileSaver from 'file-saver';
const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
const EXCEL_EXTENSION = '.xlsx';

@Component({
  selector: 'fury-tva',
  templateUrl: './tva.component.html',
  styleUrls: ['./tva.component.scss']
})
export class TvaComponent implements OnInit {


  getItemSub: Subscription;
  items: Tva[];
  scrollBarHorizontal = (window.innerWidth < 1200);
  temp = [];
  rows = [];
  columns = [];


  public afficherTva: boolean;
  public creationTva: boolean;
  public modificationTva: boolean;
  public suppressionTva: boolean;

  public keyAfficherTva = 'afficher tva';
  public keyCreationTva = 'creation tva';
  public keyModificationTva = 'modification tva';
  public keySuppressionTva = 'suppression tva';


  constructor(private logger: NGXLogger, private tvaservice: TvaService,
    private snack: MatSnackBar, private confirmService: ConfirmationService,
    private dialog: MatDialog,
    private configService: ConfigService) { }


  ngOnInit() {

    this.verifierDroit();
    this.getallTva();
  }

  updateFilter(event) {
    const val = event.target.value.toLowerCase();
    var columns = Object.keys(this.temp[0]);
    // Removes last "$$index" from "column"
    columns.splice(columns.length - 1);

    // console.log(columns);
    if (!columns.length)
      return;

    const rows = this.temp.filter(function (d) {
      for (let i = 0; i <= columns.length; i++) {
        let column = columns[i];
        // console.log(d[column]);
        if (d[column] && d[column].toString().toLowerCase().indexOf(val) > -1) {
          return true;
        }
      }
    });

    this.items = rows;
  }




  public verifierDroit() {
    this.logger.trace('BEGIN');
    // this.logger.trace('Clé', this.configService.get('keylocalstorage'));

    let agentOnline: Agentstorage;
    agentOnline = JSON.parse(localStorage.getItem(this.configService.get('keylocalstorage')));
    // this.logger.trace(agentOnline);
    let rolefontion: Rolefonction[] = agentOnline.rolefonction;
    // this.logger.trace('rolefontion', rolefontion);

    for (const key in rolefontion) {
      if (rolefontion.hasOwnProperty(key)) {
        // this.logger.trace('KEY', key);
        const element = rolefontion[key].fonction.fonctionname;
        this.logger.trace('element', element);

        if (element == this.keyAfficherTva) {
          this.logger.trace('true');
          this.afficherTva = true;
        }
        if (element == this.keyCreationTva) {
          this.logger.trace('true');
          this.creationTva = true;
        }
        if (element == this.keyModificationTva) {
          this.logger.trace('true');
          this.modificationTva = true;
        }
        if (element == this.keySuppressionTva) {
          this.logger.trace('true');
          this.suppressionTva = true;
        }
      }
    }

  }



  getallTva() {
    this.getItemSub = this.tvaservice.get("tva/getall")
      .subscribe(data => {

        let statuscode = data.status;
        this.logger.trace('DATA', data);

        switch (statuscode) {

          case 200: {
            this.items = data.body;
            this.temp = data.body;
            break;
          }

          case 204: {
            this.snack.open('Aucune TVA disponible', 'OK', { duration: 4000 })
            break;
          }

          default: {
            this.snack.open('Une erreur est survenue!', 'OK', { duration: 4000 })

            break;
          }

        }
      },
        error => {
          this.logger.error("error");
          this.logger.error(error);

          switch (error.status) {

            default: {
              this.snack.open('Une erreur est survenue!', 'OK', { duration: 4000 })

              break;
            }
          }
        });
  }





  openPopUp(data: any = {}, isNew?) {
    let title = isNew ? 'Ajouter une TVA' : 'Mettre à jour une TVA';
    let dialogRef: MatDialogRef<any> = this.dialog.open(AddtvaComponent, {
      width: '720px',
      disableClose: true,
      data: { title: title, payload: data }
    })
    dialogRef.beforeClose()
      .subscribe(res => {
        if (!res) {
          // If user press cancel
          return;
        }
        this.getallTva();

      })
  }





  deleteItem(row) {

    let u = new Agentstorage();
    u = JSON.parse(localStorage.getItem("curentuser"));
    if (u != null) {
      row.userpisteaudit = u.agent.agentemail + " " + u.agent.agentfirstname + " " + u.agent.agentlastname;
    }

    this.confirmService.confirm({ message: `Supprimé ${row.tvalilbelle}?` })
      .subscribe(res => {
        if (res) {
          this.tvaservice.post(row, "tva/delete")
            .subscribe(data => {
              let statuscode = data.status;
              switch (statuscode) {
                case 200: {
                  // Actualisé les données
                  this.getallTva();
                  this.snack.open('TVA Supprimé!', 'OK', { duration: 4000 })
                  break;
                }
                case 500: {
                  this.snack.open('Internal server error!', 'OK', { duration: 4000 })
                  break;
                }
                default: {
                  this.snack.open('Une erreur est survenue!', 'OK', { duration: 4000 })
                  break;
                }
              }

            },
              error => {
                let statuscode = error.status;
                switch (statuscode) {
                  case 404: {
                    this.snack.open('Not found!', 'OK', { duration: 4000 })
                    break;
                  }
                  case 500: {
                    this.snack.open('Internal server error!', 'OK', { duration: 4000 })
                    break;
                  }
                  default: {
                    this.snack.open('Une erreur est survenue!', 'OK', { duration: 4000 })
                    break;
                  }
                }

              });
        }

      })
  }






  exportToXls() {
    let itemscsv: any[] = [];


    if (this.items.length > 0) {
      this.items.forEach(function (element) {
        console.log(element);


        var my_json = {
          "Tva": element.tvalilbelle,
          "Pourcentage": element.tvavaleur
        };
        console.log("my_json");
        console.log(my_json);
        itemscsv.push(my_json);
      });
      console.log(itemscsv);

      this.exportAsExcelFile(itemscsv, 'Historique Plat TVA');

    }
  }
  public exportAsExcelFile(json: any[], excelFileName: string): void {
    const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(json);
    const workbook: XLSX.WorkBook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
    const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
    this.saveAsExcelFile(excelBuffer, excelFileName);
  }
  private saveAsExcelFile(buffer: any, fileName: string): void {
    const data: Blob = new Blob([buffer], { type: EXCEL_TYPE });
    FileSaver.saveAs(data, fileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION);
  }


}
