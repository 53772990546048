import { Component, Inject, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef, MatSnackBar } from '@angular/material';
import { Param } from 'src/app/models/param';

@Component({
  selector: 'fury-updatepanier',
  templateUrl: './updatepanier.component.html',
  styleUrls: ['./updatepanier.component.scss']
})
export class UpdatepanierComponent implements OnInit {

  public itemForm: FormGroup;

  public cart : Param ;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<UpdatepanierComponent>,
    private fb: FormBuilder,
    private snack: MatSnackBar,
) { }

  ngOnInit() {
    this.cart = this.data.payload ;
    this.itemForm = this.fb.group({
      parameterid: [this.cart.parameterid || ''],
      parametercode: [this.cart.parametercode || '', [Validators.required]],
      parameterdescription: [this.cart.parameterdescription || '', [Validators.required]],
      parameterheure: [this.cart.parameterheure || '', [Validators.required, Validators.min(0), Validators.max(23)]],
      parametermin: [this.cart.parametermin || '', [Validators.required, Validators.min(0), Validators.max(59)]],
      checking: [this.cart.checking || '', [Validators.required]],
    });
  }


  submit(){
    this.dialogRef.close(this.itemForm.value);
  }

 

}
