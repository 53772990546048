import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GestionparametreComponent } from './gestionparametre.component';
import { UpdateComponent } from './update/update.component';
import { ShowComponent } from './show/show.component';
import { ReactiveFormsModule } from '@angular/forms';
import { MaterialModule } from 'src/@fury/shared/material-components.module';
import { BreadcrumbsModule } from 'src/@fury/shared/breadcrumbs/breadcrumbs.module';
import { MatInputModule, MatChipsModule } from '@angular/material';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { FuryCardModule } from 'src/@fury/shared/card/card.module';
import { UpdatelimitcmdComponent } from './updatelimitcmd/updatelimitcmd.component';
import { CancelcmdComponent } from './cancelcmd/cancelcmd.component';
import { UpdatepanierComponent } from './updatepanier/updatepanier.component';

@NgModule({
  declarations: [GestionparametreComponent, UpdateComponent, ShowComponent, UpdatelimitcmdComponent, CancelcmdComponent, UpdatepanierComponent],
  entryComponents: [UpdateComponent, ShowComponent , UpdatelimitcmdComponent , UpdatepanierComponent],

  imports: [
    CommonModule,
    ReactiveFormsModule,
    MaterialModule,
    BreadcrumbsModule,
    MatInputModule,
    NgxDatatableModule,
    FuryCardModule,
    MatChipsModule,
  ]
})
export class GestionparametreModule { }
