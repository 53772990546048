import { Component, OnInit, ViewChild } from '@angular/core';
import { Agent } from 'src/app/models/Agent';
import { MatPaginator, MatSort, MatSnackBar, MatDialog, MatTableDataSource } from '@angular/material';
import { GenericserviceService } from 'src/app/services/genericservice.service';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Subscription } from 'rxjs';
import { NGXLogger } from 'ngx-logger';
import { Router } from '@angular/router';
import { ConfirmationService } from 'src/app/confirm/confirmation.service';
import { GestionutilisateurService } from '../gestionutilisateur/gestionutilisateur.service';
import { ConfigService } from 'ngx-envconfig';
import { DeliveryService } from 'src/app/services/delivery.service';
import { ShowlivreurComponent } from './showlivreur/showlivreur.component';
import { NewlivreurComponent } from './newlivreur/newlivreur.component';
import { ApploaderService } from 'src/app/services/apploader/apploader.service';
import { GlobalconfigComponent } from './globalconfig/globalconfig.component';
import { ZonedeliveryComponent } from './zonedelivery/zonedelivery.component';

@Component({
  selector: 'fury-gestionlivreur',
  templateUrl: './gestionlivreur.component.html',
  styleUrls: ['./gestionlivreur.component.scss']
})

export class GestionlivreurComponent implements OnInit {


  public items: Agent[];
  temp = [];
  rows = [];
  columns = [];
  scrollBarHorizontal = (window.innerWidth < 1200);
  public getItemSub: Subscription;

  // Habilitation
  public creationLivreur: boolean;

  // expandable
  @ViewChild('myTable') table: any;
  expanded: any = {};
  timeout: any;

  modelivraison: any[] = [

    {
      "libele": "TOUS LES LIVREURS",
      "values": "all"
    },
    {
      "libele": "LIVREUR EN ATTENTE",
      "values": "waiting"
    },

    {
      "libele": "LIVREUR DISPONIBLE",
      "values": "open"
    }

  ]

  public itemForm: FormGroup;



  constructor(private logger: NGXLogger,
    private route: Router,
    private dialog: MatDialog,
    public formBuilder: FormBuilder,
    private snackbar: MatSnackBar,
    private confirmService: ConfirmationService,
    private utilisateurService: GenericserviceService,
    private deliveryService: DeliveryService,
    private loader: ApploaderService,


    private fb: FormBuilder,
    private snack: MatSnackBar,

    private configService: ConfigService) { }


  ngOnInit() {


    this.itemForm = this.fb.group({

      deliverymode: ['all'],

    });

    this.itemForm.get('deliverymode').valueChanges.subscribe(data => {
      console.log(data);
      if (data === 'all') {
        this.getallUser();
      }
      if (data === 'waiting') {
        this.getWaiting();
      }

      if (data === 'open') {
        this.getOpen();
      }
    });

    this.getallUser();
  }


  checking(value) {
    console.log(value);
    console.log(value === 'inactif');
  }

  updateFilter(event) {
    const val = event.target.value.toLowerCase();
    var columns = Object.keys(this.temp[0]);
    // Removes last "$$index" from "column"
    columns.splice(columns.length - 1);

    // console.log(columns);
    if (!columns.length)
      return;

    const rows = this.temp.filter(function (d) {
      for (let i = 0; i <= columns.length; i++) {
        let column = columns[i];
        // console.log(d[column]);
        if (d[column] && d[column].toString().toLowerCase().indexOf(val) > -1) {
          return true;
        }
      }
    });

    this.items = rows;
  }

  createDelivery() {
    this.dialog.open(NewlivreurComponent, { width: '720px' }).afterClosed().subscribe((res) => {
      console.log(res);

      if (!res) {
        return;
      }

      let request: any = res;

      if (((request.pc.size / 1000) > 350) || (request.cni.size / 1000) > 350) {

        this.snackbar.open('Echec !! Veuillez selectionner un fichier de taille maximale de 350 KO', 'OK', {
          duration: 100000
        });
        return;
      }

      this.loader.open();
      this.deliveryService.post(request, "livreur").subscribe(data => {

        console.log("data");
        console.log(data);
        let dataneutre: any = data.body;



        switch (data.status) {

          case 201: {
            this.deliveryService.sendFileToStorage(res.pc, "pc", res.cni, "cni", "livreur/upload/file?code=" + dataneutre.livreurid).subscribe(data => {
              this.loader.close();

              this.getallUser();
              this.snackbar.open('succes', 'OK', {
                duration: 10000
              });
            },
              error => {

                this.loader.close();

                console.log(error);

                this.snackbar.open('Echec !! Une erreur est survenue lors du chargement du fichier', 'OK', {
                  duration: 10000
                });

              }
            );


            break;
          }

        }
      },
        error => {
          switch (error.status) {

            case 409: {
              //this.getallFood();
              this.loader.close();
              this.snackbar.open('Email ou telephone existe deja ', 'OK', {
                duration: 10000
              });
              break;
            }

            default: {
              this.loader.close();

              this.snackbar.open('Echec !! Une erreur est survenue ', 'OK', {
                duration: 10000
              });
              break;
            }
          }
        });



    });
  }



  delete(livreur: any) {

    this.confirmService.confirm({ message: "voulez-supprimer le livreur : " + livreur.livreurfirstname + " " + livreur.livreurlastname })
      .subscribe(res => {

        console.log(res);
        if (!res) {
          return;
        }


        this.deliveryService.post(livreur, "livreur/remove").subscribe(data => {
          this.logger.trace("***************************");

          this.logger.trace(data);

          this.logger.trace("***************************");

          switch (data.status) {
            case 200: {
              this.getallUser();
              this.snackbar.open('Succes', 'OK', {
                duration: 10000
              });
              break;
            }


          }
        },
          error => {
            this.logger.trace("erreur retour");
            // this.loading.dismiss('messagesService.loadMessagesOverview');
            switch (error.status) {



              default: {

                // this.FormReg(" erreur de connexion !!! Veuillez ressayer");
                this.logger.error("Check API", error);

                this.snackbar.open('Echec', 'OK', {
                  duration: 10000
                });
                break;
              }
            }
          }
        );
      });
  }




  updateMode(value: any, state: any) {

    let request: any = {};
    let message = "";
    request.userid = value.livreurid;
    if (state === 1) {
      message = "VOULEZ-VOUS ACTIVER LE LIVREUR : " + value.livreurfirstname + " " + value.livreurlastname;
      request.statut = "actif";
    }

    if (state === 2) {
      message = "VOULEZ-VOUS DESACTIVER LE LIVREUR : " + value.livreurfirstname + " " + value.livreurlastname;
      request.statut = "inactif";

    }

    this.confirmService.confirm({ message: message })
      .subscribe(res => {

        console.log(res);
        if (!res) {
          return;
        }

        this.deliveryService.post(request, "livreur/changemode").subscribe(data => {
          this.logger.trace("***************************");

          this.logger.trace(data);

          this.logger.trace("***************************");

          switch (data.status) {
            case 200: {
              this.getallUser();
              this.snackbar.open('Succes', 'OK', {
                duration: 10000
              });
              break;
            }


          }
        },
          error => {
            this.logger.trace("erreur retour");
            // this.loading.dismiss('messagesService.loadMessagesOverview');
            switch (error.status) {



              default: {

                // this.FormReg(" erreur de connexion !!! Veuillez ressayer");
                this.logger.error("Check API", error);

                this.snackbar.open('Echec', 'OK', {
                  duration: 10000
                });
                break;
              }
            }
          }
        );
      })
  }


  updateState(value: any, state: any) {

    let request: any = {};
    let message = "";
    request.userid = value.livreurid;
    if (state === 1) {
      message = "VOULEZ-VOUS ACTIVER LE COMPTE LIVREUR : " + value.livreurfirstname + " " + value.livreurlastname;
      request.statut = "valider";
    }

    if (state === 2) {
      message = "VOULEZ-VOUS REFUSER LE COMPTE LIVREUR : " + value.livreurfirstname + " " + value.livreurlastname;
      request.statut = "invalider";

    }

    this.confirmService.confirm({ message: message })
      .subscribe(res => {

        console.log(res);
        if (!res) {
          return;
        }

        this.deliveryService.post(request, "livreur/changestate").subscribe(data => {
          this.logger.trace("***************************");

          this.logger.trace(data);

          this.logger.trace("***************************");

          switch (data.status) {
            case 200: {
              this.getallUser();
              this.snackbar.open('Succes', 'OK', {
                duration: 10000
              });
              break;
            }


          }
        },
          error => {
            this.logger.trace("erreur retour");
            // this.loading.dismiss('messagesService.loadMessagesOverview');
            switch (error.status) {



              default: {

                // this.FormReg(" erreur de connexion !!! Veuillez ressayer");
                this.logger.error("Check API", error);

                this.snackbar.open('Echec', 'OK', {
                  duration: 10000
                });
                break;
              }
            }
          }
        );
      })
  }




  updateZone(value: any) {
    this.dialog.open(ZonedeliveryComponent,
      {
        width: '720px',
        data: { title: '', payload: value }
      }
    ).afterClosed().subscribe((res) => {
      console.log(res);
      if (!res) {
        return;
      }

      res.livreurid = value.livreurid;

      this.deliveryService.post(res, "livreur/changezone").subscribe(data => {
        this.logger.trace("***************************");

        this.logger.trace(data);

        this.logger.trace("***************************");

        switch (data.status) {
          case 200: {

            this.getallUser();
            this.snackbar.open('Succes', 'OK', {
              duration: 10000
            });
            break;
          }


        }
      },
        error => {
          this.logger.trace("erreur retour");
          // this.loading.dismiss('messagesService.loadMessagesOverview');
          switch (error.status) {



            default: {

              this.snackbar.open('Echec', 'OK', {
                duration: 10000
              });
              break;
            }
          }
        }
      );


    });
  }


  opendetail(value: any) {

    this.checking(value.livreurstatutcompte);


    this.dialog.open(ShowlivreurComponent,
      {
        width: '720px',
        data: { title: '', payload: value }
      }
    ).afterClosed().subscribe((res) => {
      console.log(res);
      if (!res) {
        return;
      }
    });
  }


  getallUser() {
    this.getItemSub = this.deliveryService.get("livreur/all")
      .subscribe(data => {

        switch (data.status) {

          default: {

            this.logger.trace('data', data.body);
            let response: any[] = data.body;
            this.logger.trace('response', response);

            let resp1: any[] = response;

            this.items = this.temp = resp1;
            // this.dataSource = new MatTableDataSource(this.items);


            break;
          }


        }
      },
        error => {
          console.log('error');
          console.log(error);

          switch (error.status) {

            default: {

              break;
            }
          }
        });
  }


  getConfig() {

    this.getItemSub = this.deliveryService.get("livreur/allconfig/" + this.configService.get("solde.config"))
      .subscribe(data => {

        switch (data.status) {

          default: {

            let config: any = data.body;
            console.log(config);
            this.dialog.open(GlobalconfigComponent, { width: '720px', data: { title: '', payload: config } }).afterClosed().subscribe((res) => {

              if (!res) {
                return;
              }
              console.log("----------------------");

              console.log(res);

              this.sendGlobalConfig(res);

            });

            break;
          }


        }
      },
        error => {
          console.log('error');
          console.log(error);

          switch (error.status) {

            default: {

              break;
            }
          }
        });
  }

  goToRounting(value) {
    this.route.navigateByUrl(value);
  }

  sendGlobalConfig(request: any) {
    this.deliveryService.post(request, "livreur/updateallconfig").subscribe(data => {
      this.logger.trace("***************************");

      this.logger.trace(data);

      this.logger.trace("***************************");

      switch (data.status) {
        case 200: {
          this.snackbar.open('Succes', 'OK', {
            duration: 10000
          });
          break;
        }


      }
    },
      error => {
        this.logger.trace("erreur retour");
        // this.loading.dismiss('messagesService.loadMessagesOverview');
        switch (error.status) {



          default: {

            this.snackbar.open('Echec', 'OK', {
              duration: 10000
            });
            break;
          }
        }
      }
    );
  }

  getWaiting() {
    this.getItemSub = this.deliveryService.get("livreur/waiting")
      .subscribe(data => {

        switch (data.status) {

          default: {

            this.logger.trace('data', data.body);
            let response: any[] = data.body;
            this.logger.trace('response', response);

            let resp1: any[] = response;

            this.items = this.temp = resp1;
            // this.dataSource = new MatTableDataSource(this.items);


            break;
          }


        }
      },
        error => {
          console.log('error');
          console.log(error);

          switch (error.status) {

            default: {

              break;
            }
          }
        });
  }


  getOpen() {
    this.getItemSub = this.deliveryService.get("livreur/open")
      .subscribe(data => {

        switch (data.status) {

          default: {

            this.logger.trace('data', data.body);
            let response: any[] = data.body;
            this.logger.trace('response', response);

            let resp1: any[] = response;

            this.items = this.temp = resp1;
            // this.dataSource = new MatTableDataSource(this.items);


            break;
          }


        }
      },
        error => {
          console.log('error');
          console.log(error);

          switch (error.status) {

            default: {

              break;
            }
          }
        });
  }




}
