import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'fury-statlistecommande',
  templateUrl: './statlistecommande.component.html',
  styleUrls: ['./statlistecommande.component.scss']
})
export class StatlistecommandeComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
