export class Pointvente {
    pointventeid: string;
    pointventedatecreation: string;
    pointventeisdeleted: boolean;
    pointventename: string;
    pointventecode: string;
    pointventeremise: boolean;
    pointventemontantremise: string

}


export class Pointlivraison {
    pointlivraisonid: number;
    pointlivraisonlibele: string;
    pointgeographie: boolean;
    pointdescription: string;
    userpisteaudit: string;
}


export class Abattement {
    abattementid: string;
    abattementdate: string;
    abattementdescription: string;
    abattementenable: boolean;
    abattementlibelle: string;
    abattementmontant: number;
    abattementtype: string;
    abattementtypecarte: string;
    abattementtypecartelib: string;
    abattementtypemontant: string;
    abattementmontantarticle : any ;
    food : string[] ;
    formuleabat : string[] ;
    pointventes : any[] ;
    userpisteaudit: string;
    formules : any[] ;
    foods : any[] ;

}

