import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpHeaders } from '@angular/common/http';
import { ConfigService } from 'ngx-envconfig';
import { NGXLogger } from 'ngx-logger';
import { Agentstorage } from 'src/app/models/agentstorage';
import { Observable } from 'rxjs';
import { Agent } from 'src/app/models/Agent';

@Injectable({
  providedIn: 'root'
})
export class CategoriemenuService {


  authorization: string;
  private apiCategorie = this.configService.get('ipMS') + 'parameter/categorie';

  constructor(private http: HttpClient, private configService: ConfigService,
    private logger: NGXLogger) {
    let user: Agentstorage = new Agentstorage();
    user = JSON.parse(localStorage.getItem('curentuser'));

    if (user != null) {
      this.authorization = user.token;
    }
  }



  getAllCategorie(): Observable<HttpResponse<any>> {
    return this.http.get(this.apiCategorie , {
      observe: 'response',
        headers: new HttpHeaders({ 'Access-Control-Allow-Origin': '*' })
          .set('content-Type', 'application/json')
          .set('Authorization', this.authorization)
      });
  }



  public createCategorie(value: Agent): Observable<HttpResponse<any>> {
    this.logger.trace('url : ' + this.apiCategorie);
    this.logger.trace(JSON.stringify(value));
    return this.http.post(this.apiCategorie, JSON.stringify(value), {
      observe: 'response',
      headers: new HttpHeaders({ 'Access-Control-Allow-Origin': '*' })
        .set("content-Type", "application/json")
        .set("Authorization", this.authorization)
    });
  }



  public updateCategorie(value: Agent): Observable<HttpResponse<any>> {
    this.logger.trace("url : " + this.apiCategorie);
    this.logger.trace(JSON.stringify(value));
    return this.http.put(this.apiCategorie, JSON.stringify(value), {
      observe: 'response',
      headers: new HttpHeaders({ 'Access-Control-Allow-Origin': '*' })
        .set("content-Type", "application/json")
        .set("Authorization", this.authorization)
    });
  }



  deleteCategorie(id: string): Observable<any> {
    return this.http.delete(this.apiCategorie + "/" + id,
      {
        observe: 'response',
        headers: new HttpHeaders({ 'Access-Control-Allow-Origin': '*' })
          .set("content-Type", "application/json")
          .set("Authorization", this.authorization)
      });}


    }
