import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AbonnementmasseComponent } from './abonnementmasse.component';
import { ReactiveFormsModule } from '@angular/forms';
import { MatChipsModule } from '@angular/material';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { FurySharedModule } from 'src/@fury/fury-shared.module';
import { BreadcrumbsModule } from 'src/@fury/shared/breadcrumbs/breadcrumbs.module';
import { FuryCardModule } from 'src/@fury/shared/card/card.module';
import { MaterialModule } from 'src/@fury/shared/material-components.module';
import { QuickInfoWidgetModule } from '../dashboard/widgets/quick-info-widget/quick-info-widget.module';
import { HistabnComponent } from './histabn/histabn.component';
import { LoadfileComponent } from './loadfile/loadfile.component';
import { AddloadfileComponent } from './addloadfile/addloadfile.component';
import { SoldeabnComponent } from './soldeabn/soldeabn.component';
import { StatutabnComponent } from './statutabn/statutabn.component';

@NgModule({
  declarations: [AbonnementmasseComponent, HistabnComponent, LoadfileComponent, AddloadfileComponent, SoldeabnComponent, StatutabnComponent],
  entryComponents: [LoadfileComponent, AddloadfileComponent, SoldeabnComponent, StatutabnComponent],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    MatChipsModule,
    MaterialModule,
    BreadcrumbsModule,
    NgxDatatableModule,
    FuryCardModule,
    MatChipsModule,
    FurySharedModule,
    QuickInfoWidgetModule
  ]
})
export class AbonnementmasseModule { }
