import { Component, OnInit, Inject } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { NGXLogger } from 'ngx-logger';

@Component({
  selector: 'fury-updatelimitcmd',
  templateUrl: './updatelimitcmd.component.html',
  styleUrls: ['./updatelimitcmd.component.scss']
})
export class UpdatelimitcmdComponent implements OnInit {


  form: FormGroup;

  constructor(@Inject(MAT_DIALOG_DATA) public defaults: any,
    public dialogRef: MatDialogRef<UpdatelimitcmdComponent>,
    private fb: FormBuilder,
    private logger: NGXLogger) {
  }

  ngOnInit() {
    this.form = this.fb.group({
      nbre: ['', Validators.compose([Validators.required])],
    });
  }

  submit() {
    //    this.form.
    this.dialogRef.close(this.form.value);
  }


}
