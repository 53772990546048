import { ClienttypeModifierComponent } from './clienttype-modifier/clienttype-modifier.component';
import { DeletedialogclientypeComponent } from './deletedialogclientype/deletedialogclientype.component';
import { GenericserviceService } from './../../../services/genericservice.service';
import { Router } from '@angular/router';
import { MatPaginator, MatSort, MatSnackBar, MatDialog, MatTableDataSource } from '@angular/material';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { Clienttype } from './../../../models/Clienttype';
import { Component, OnInit, ViewChild } from '@angular/core';

@Component({
  selector: 'fury-typeclient',
  templateUrl: './typeclient.component.html',
  styleUrls: ['./typeclient.component.scss'],
  providers: [GenericserviceService]
})
export class TypeclientComponent implements OnInit {


  private clienttypes: Clienttype[];
  private selectedValue: Clienttype;
  clienttypeForm: FormGroup;


  clienttypeId: string;

  clienttypename: string;
  clienttypeisdeleted: boolean;
  clienttypedatecreation: string;
  clienttype: Clienttype;

  displayedColumns: string[] = ['clienttypename', 'clienttypedatecreation', 'clienttypeisdeleted', 'actions'];
  dataSource: any;

  
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  constructor(private router: Router, private clienttypeService: GenericserviceService,private formBuilder: FormBuilder, private snackbar: MatSnackBar, public dialog: MatDialog) {
  }



  ngOnInit() {
    this.clienttypeService.type='clienttype';
    this.findAll();


    this.clienttypeForm = new FormGroup({
      clienttypename: new FormControl('', Validators.required)

    });

    this.clienttypeForm = this.formBuilder.group({
      clienttypename: ['', Validators.required]
  })
    

  }


  onSubmit() {

    console.info('-----------------------------------------');


    if (this.clienttypeForm.valid) {
       // saving object
    
      this.clienttypeService.save(this.clienttypeForm.controls['clienttypename'].value).subscribe(data => {


        console.log(data);
        switch (data.status) {
          case 201: {
            // this.loading.dismiss('messagesService.loadMessagesOverview');
            
            let resp: Clienttype;
            resp = data.body;
            console.log(resp);
            console.log("------------------------------------");
            this.snackbar.open('Success type de client Créé', 'OK', {
              duration: 2000
            });
            

            if (resp != null) {

              console.log("-------------- success clienttype created ----------------");
              this.findAll();
              //this.router.navigateByUrl('./');

              // this.router.navigateByUrl('/dashboard');
            } else {
              // this.FormReg(" Mot de passe ou login incorrect ");
            }
            break;
          }
        }
      },
        error => {
          console.log("erreur retour");
          // this.loading.dismiss('messagesService.loadMessagesOverview');
          switch (error.status) {
            case 409:
              //this.FormReg(" Mot de passe ou login incorrect ");
              this.snackbar.open('Echec modification', 'Le type de client existe deja', {
                duration: 2000
              });
              break;
            case 401:
              //this.FormReg(" Mot de passe ou login incorrect ");
              break;
            default: {
              // this.FormReg(" erreur de connexion !!! Veuillez ressayer");
              this.snackbar.open('Echec modification', 'OK', {
                duration: 2000
              });
              break;
            }
          }

        });

        this.clienttypeForm.reset({clienttypename: ''});
       
      /////////////
  } else {
      this.validateAllFormFields(this.clienttypeForm);
      this.snackbar.open('Veuillez saisir toutes les valeurs svp', 'OK', {
        duration: 2000
      });



  }

    


}

  validateAllFormFields(formGroup: FormGroup) {
    Object.keys(formGroup.controls).forEach(field => {
        const control = formGroup.get(field);
        if (control instanceof FormControl) {
            control.markAsTouched({ onlySelf: true });
        } else if (control instanceof FormGroup) {
            this.validateAllFormFields(control);
        }
    });
}


  findAll() {

    this.clienttypeService.findAll().subscribe(data => {
      console.log(data);
      switch (data.status) {
        case 200: {
          // this.loading.dismiss('messagesService.loadMessagesOverview');
          let resp: Clienttype[];
          resp = data.body;
          console.log("------------------------------------");
          console.log(resp);
          console.log("------------------------------------");
          console.log(resp[0].clienttypedatecreation);
          this.clienttypes = resp;
          // this.dataSource = valeurs;
          this.dataSource = new MatTableDataSource<Clienttype>(resp);
          this.dataSource.paginator = this.paginator;
          this.dataSource.sort = this.sort;


          if (resp != null) {
            //this.resetFunction();


            console.log("------------- clienttypes founded ------------------");

          } else {

            // this.FormReg(" Mot de passe ou login incorrect ");
          }
          break;
        }
      }
    },
      error => {
        console.log("erreur retour");
        // this.loading.dismiss('messagesService.loadMessagesOverview');
        switch (error.status) {
          case 401:
            //this.FormReg(" Mot de passe ou login incorrect ");
            break;
          default: {
            // this.FormReg(" erreur de connexion !!! Veuillez ressayer");
            break;
          }
        }
      }
    );

  }


  updateClienttype(row) {
    console.log(' edit clienttype ');

    if (this.clienttypeId) {
      console.log('id ok ----editing ');

      let clienttype: Clienttype = new Clienttype();
      clienttype.clienttypeid = this.clienttypeId;
      clienttype.clienttypename = this.clienttypeForm.controls['clienttypename'].value;
      // this.valeurForm.controls['clienttypeLastName'].value,
      // this.valeurForm.controls['clienttypeEmail'].value;

      // updating object
      this.clienttypeService.update(clienttype).subscribe();
    }


  }


  deleteClienttype(row) {
    console.log('-- Delete clienttype ---  '+  row);

    if (row) {
      let clienttype : Clienttype = row ;
      this.openDialogDelete(clienttype);
     
    }

  }

  openDialogDelete(clienttype): void {
    const dialogRef = this.dialog.open(DeletedialogclientypeComponent, {
      width: '250px',
      data: {clienttype: clienttype}
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed' + result);
      //this.animal = result;

      this.findAll()
    });
  }


  openDialog(row): void {
    //console.log('------onpen dialog -------');

    console.log(row);
    this.selectedValue = row;
    if (row) {
      this.callClienttypeDetail();
    }
  }

  
  callClienttypeDetail() {
    console.log('------onpen dialog -------');

    const dialogRef = this.dialog.open(ClienttypeModifierComponent, {
      maxWidth: '80%',

      data: { clienttype: this.selectedValue }
    });

    dialogRef.afterClosed().subscribe(resp => {

      console.log('The dialog was closed .. resp --- ' +  resp);



      this.findAll() ;
      console.log('======================');
     // this.clienttype = result;

    });
  }
}
