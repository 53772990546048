import { ConfigService } from 'ngx-envconfig';
import { HttpResponse, HttpHeaders, HttpClient, HttpParams, HttpRequest, HttpEvent } from '@angular/common/http';
import { Observable } from 'rxjs';
import { NGXLogger } from 'ngx-logger';
import { Injectable } from '@angular/core';
import { Agentstorage } from '../../models/agentstorage';

@Injectable({
  providedIn: 'root'
})
export class GestionclientService {


  authorization: string;


  //parameter/historiqueVente
  // private getalllivraison = this.configService.get('ipMS') + 'order/getallcommandbylivraison';
  //POST /order/getallcommandbylivraisondate
  //    private getalllivraison = this.configService.get('ipMS') + 'parameter/historiqueVente';

  private getallclient = this.configService.get('ipMS') + 'parameter/client';
  private getallclientcptestat = this.configService.get('ipMS') + 'parameter/client/statcpte';
  private url = this.configService.get('ipMS') + 'parameter/';

  constructor(private http: HttpClient, private configService: ConfigService,
    private logger: NGXLogger) {
    let user: Agentstorage = new Agentstorage();
    user = JSON.parse(localStorage.getItem('curentuser'));
    if (user != null) {
      this.authorization = user.token;
    }
  }

  postData(value: any, path: string): Observable<HttpResponse<any>> {
    return this.http.post(this.url + '' + path, JSON.stringify(value), {
      observe: 'response',
      headers: new HttpHeaders({ 'Access-Control-Allow-Origin': '*' })
        .set('content-Type', 'application/json')
        .set('Authorization', this.authorization)
    });
  }

  getData(): Observable<HttpResponse<any>> {
    return this.http.get(this.getallclient, {
      observe: 'response',
      headers: new HttpHeaders({ 'Access-Control-Allow-Origin': '*' })
        .set('content-Type', 'application/json')
        .set('Authorization', this.authorization)
    });
  }

  getStatData(): Observable<HttpResponse<any>> {
    return this.http.get(this.getallclientcptestat, {
      observe: 'response',
      headers: new HttpHeaders({ 'Access-Control-Allow-Origin': '*' })
        .set('content-Type', 'application/json')
        .set('Authorization', this.authorization)
    });
  }



  pushFileToStorage(file: File, corporateid: string): Observable<HttpEvent<{}>> {

    let user: Agentstorage = new Agentstorage();
    user = JSON.parse(localStorage.getItem('curentuser'));

    //this.logger.trace("FileNull " + (file == null));
    let formdata: FormData = new FormData();
    formdata.append('file', file);

    let Params = new HttpParams();
    Params = Params.set('formdata', 'value');

    //    const req = new HttpRequest('POST', this.url + "client/addcorporate/" + corporateid + "/" + user.agent.agentemail, formdata, {

    const req = new HttpRequest('POST', this.url + "client/addcorporatebyselect/" + corporateid , formdata, {
      reportProgress: true,
      responseType: 'text',

    });
    
    /*this.logger.trace('REQ');
    this.logger.trace(req);*/
    return this.http.request(req);
  }

}
