import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { JournalcaisseComponent } from './journalcaisse.component';
import { ReactiveFormsModule } from '@angular/forms';
import { MatChipsModule } from '@angular/material';
import { MaterialModule } from 'src/@fury/shared/material-components.module';
import { BreadcrumbsModule } from 'src/@fury/shared/breadcrumbs/breadcrumbs.module';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { FuryCardModule } from 'src/@fury/shared/card/card.module';
import { FurySharedModule } from 'src/@fury/fury-shared.module';
import { MatSelectSearchModule } from '../historiquecarte/mat-select-search/mat-select-search.module';
import { ShowComponent } from './show/show.component';
import { QuickInfoWidgetModule } from '../dashboard/widgets/quick-info-widget/quick-info-widget.module';

@NgModule({
  declarations: [JournalcaisseComponent, ShowComponent],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    MatChipsModule,
    MaterialModule,
    BreadcrumbsModule,
    NgxDatatableModule,
    FuryCardModule,
    MatChipsModule,
    FurySharedModule,
    MatSelectSearchModule,
    QuickInfoWidgetModule
  ],
  entryComponents : [ShowComponent]
})
export class JournalcaisseModule { }
