import { Injectable } from '@angular/core';
import { NGXLogger } from 'ngx-logger';
import { ConfigService } from 'ngx-envconfig';
import { Agentstorage } from 'src/app/models/agentstorage';
import { HttpClient, HttpEvent, HttpHeaders, HttpRequest, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class JournalcaisseService {

  private apiUrl = this.configService.get('ipMS') + 'parameter/';
  private apiUrljournal = this.configService.get('ipMS') + 'stats/chiffreaffaire/bycaissier/';
  private apiUrlDetailjournal = this.configService.get('ipMS') + 'histoactivite/findbyagentdate';

  private apiUrlDetailjournalCaisse = this.configService.get('ipMS') + 'histoactivite/findbycaissedate';

  private apiUrljournalaisse = this.configService.get('ipMS') + 'stats/chiffreaffaire/bycaisse/';

  authorization: string = '';

  private url = this.configService.get('ipMS') ;

  private apiUrlcloture = this.configService.get('ipMS') + 'parameter/utils/sendmailcloture?email='

  constructor(private http: HttpClient,
    private logger: NGXLogger,
    private configService: ConfigService
  ) {
    let u = new Agentstorage();
    u = JSON.parse(localStorage.getItem('curentuser'));

    if (u != null) {
      this.authorization = u.token;
    }
  }


  public getInfoCaisse(value: any): Observable<HttpResponse<any>> {
    this.logger.trace("url : " + this.apiUrlDetailjournal);
    this.logger.trace(JSON.stringify(value));
    return this.http.post(this.apiUrlDetailjournalCaisse, JSON.stringify(value), {
      observe: 'response',
      headers: new HttpHeaders({ 'Access-Control-Allow-Origin': '*' })
        .set("content-Type", "application/json")
        .set("Authorization", this.authorization)
    });
  }

  getAllJounalCaisse(caissier: string, agentid: string): Observable<HttpResponse<any>> {
    //return this.http.get(this.apiUrljournal + caissier + "/" + agentid,
    return this.http.get(this.apiUrljournalaisse + caissier+ "/" + agentid,
      {
        observe: 'response',
        headers: new HttpHeaders({ 'Access-Control-Allow-Origin': '*' })
          .set("content-Type", "application/json")
          .set("Authorization", this.authorization)
      });
  }

  findAll(): Observable<HttpResponse<any>> {
    return this.http.get(this.apiUrl + "caisse/getallcaisse",
      {
        observe: 'response',
        headers: new HttpHeaders({ 'Access-Control-Allow-Origin': '*' })
          .set("content-Type", "application/json")
          .set("Authorization", this.authorization)
      });
  }



  findByUrl( param : String): Observable<HttpResponse<any>> {
    return this.http.get(this.url + param,
      {
        observe: 'response',
        headers: new HttpHeaders({ 'Access-Control-Allow-Origin': '*' })
          .set("content-Type", "application/json")
          .set("Authorization", this.authorization)
      });
  }


  sendFileToStorageDistant(file: File): Observable<HttpEvent<{}>> {
    let u = new Agentstorage();
    u = JSON.parse(localStorage.getItem('curentuser'));

    if (u) {
      console.log("FILE SEND SUCCES ");
      const formdata: FormData = new FormData();
      let url: string = this.apiUrlcloture + '' + u.agent.agentemail;
      formdata.append('file', file);
      const req = new HttpRequest('POST', url, formdata, {
        reportProgress: true,
        responseType: 'text'
      });
      return this.http.request(req);
    } else {
      console.log("FILE SEND ECHEC ");
      return null;
    }


    //this.http.post(this.uploadfoodpicture + "?name=" + filename,formdata,{headers});
  }


}
