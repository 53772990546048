import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FileattenteComponent } from './fileattente.component';
import { ReactiveFormsModule } from '@angular/forms';
import { MatInputModule, MatChipsModule, MatDatepickerModule } from '@angular/material';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatDatetimepickerModule } from '@mat-datetimepicker/core';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { BreadcrumbsModule } from 'src/@fury/shared/breadcrumbs/breadcrumbs.module';
import { FuryCardModule } from 'src/@fury/shared/card/card.module';
import { MaterialModule } from 'src/@fury/shared/material-components.module';
import { ShowficheComponent } from './showfiche/showfiche.component';

@NgModule({
  declarations: [FileattenteComponent, ShowficheComponent],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    MaterialModule,
    BreadcrumbsModule,
    MatInputModule,
    NgxDatatableModule,
    FuryCardModule,
    MatChipsModule,
    MatDatepickerModule,
    MatDatetimepickerModule,
    BrowserAnimationsModule
  ],
  entryComponents: [ShowficheComponent]
})
export class FileattenteModule { }
