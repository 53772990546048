import { Component, OnInit, Inject } from '@angular/core';
import { Subscription } from 'rxjs';
import { Rolefonction } from 'src/app/models/rolefonction';
import { MAT_DIALOG_DATA, MatDialog, MatSnackBar } from '@angular/material';
import { NGXLogger } from 'ngx-logger';
import { FormBuilder } from '@angular/forms';
import { ConfirmationService } from 'src/app/confirm/confirmation.service';
import { ConfigService } from 'ngx-envconfig';
import { RolefonctionService } from 'src/@fury/services/rolefonction.service';

@Component({
  selector: 'fury-voirhabilitation',
  templateUrl: './voirhabilitation.component.html',
  styleUrls: ['./voirhabilitation.component.scss']
})
export class VoirhabilitationComponent implements OnInit {

 
  getItemSub: Subscription;
  public items: Rolefonction[];
  

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
    private logger: NGXLogger,
    private dialog: MatDialog,
    public formBuilder: FormBuilder,
    private snack: MatSnackBar,
    private confirmService: ConfirmationService,
    private configService: ConfigService,
    private rolefonctionService: RolefonctionService) { }

  ngOnInit() {
    this.logger.trace('ID role', this.data.payload.role.roleid);
    this.getRolefonctionByRoleid(this.data.payload.role.roleid);
  }

  getRolefonctionByRoleid(roleid: string) {
    this.logger.debug('Obj reçu pour envoi au service', roleid);
    this.getItemSub = this.rolefonctionService.findbyRoleid(roleid)
      .subscribe(data => {

        let statuscode = data.status;
        this.logger.trace('DATA', data);

        switch (statuscode) {

          case 200: {
            this.items = data.body;

            this.logger.trace('rolefonctionbyid', this.items);

            break;
          }



          default: {
            this.snack.open('Une erreur est survenue!', 'OK', { duration: 4000 })

            break;
          }

        }
      },
        error => {
          this.logger.error("error");
          this.logger.error(error);

          switch (error.status) {

            case 401: {
              this.snack.open('Non authorisé', 'OK', { duration: 7000 })
              break;
            }

            default: {
              this.snack.open('Une erreur est survenue!', 'OK', { duration: 4000 })

              break;
            }
          }
        });
  }

}
