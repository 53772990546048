import { DatePipe } from '@angular/common';
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MatDialog, MatPaginator, MatSnackBar, MatTableDataSource } from '@angular/material';
import { Router } from '@angular/router';
import { ConfigService } from 'ngx-envconfig';
import { NGXLogger } from 'ngx-logger';
import { NgxPermissionsService } from 'ngx-permissions';
import { Subscription } from 'rxjs/internal/Subscription';
import { ConfirmationService } from 'src/app/confirm/confirmation.service';
import { Agentstorage } from 'src/app/models/agentstorage';
import { Pointlivraison } from 'src/app/models/pointvente';
import { Rolefonction } from 'src/app/models/rolefonction';
import { ApploaderService } from 'src/app/services/apploader/apploader.service';
import { AddlivraisonComponent } from './addlivraison/addlivraison.component';
import { GestionpointlivraisonService } from './gestionpointlivraison.service';
import { UpdatelivraisonComponent } from './updatelivraison/updatelivraison.component';

@Component({
  selector: 'fury-gestionpointlivraison',
  templateUrl: './gestionpointlivraison.component.html',
  styleUrls: ['./gestionpointlivraison.component.scss']
})
export class GestionpointlivraisonComponent implements OnInit {


  displayedColumns: string[] = ['pointlivraisonzone', 'pointlivraisonduree', 'actions'];
  dataSource = new MatTableDataSource<Pointlivraison>([]);
  @ViewChild(MatPaginator) paginator: MatPaginator;
  public getItemSub: Subscription;
  pointvente: Pointlivraison[] = [];


  public afficherPointlivraison: boolean;
  public creationPointlivraison: boolean;
  public modificationPointlivraison: boolean;
  public suppressionPointlivraison: boolean;

  public keyAfficherPointlivraison = 'afficher point livraison';
  public keyCreationPointlivraison = 'ajouter point livraison';
  public keyModificationPointlivraison = 'modifier point livraison';
  public keySuppressionPointlivraison = 'supprimer point livraison';

  constructor(private dialog: MatDialog, public formBuilder: FormBuilder,
    private snackbar: MatSnackBar, private datePipe: DatePipe,
    private configService: ConfigService, private router: Router,
    private confirmService: ConfirmationService, private loader: ApploaderService,
    private permissionsService: NgxPermissionsService, private logger: NGXLogger,
    private pointservice: GestionpointlivraisonService) { }

  ngOnInit() {
    this.verifierDroit();
    this.getallPointlivraison();
  }


  goToRouting(value) {
    this.router.navigateByUrl('/yolinbo/gestionpointzone?key=' + value.pointlivraisonid);
  }

  getallPointlivraison() {
    this.getItemSub = this.pointservice.get("pointlivraison/getall")
      .subscribe(data => {

        let statuscode = data.status;
        this.logger.trace('DATA', data);

        switch (statuscode) {

          case 200: {
            this.pointvente = data.body;

            this.dataSource.data = this.pointvente;;
            this.dataSource.paginator = this.paginator;
            //this.temp = data.body;
            break;
          }

          case 204: {
            //this.snack.open('Aucune TVA disponible', 'OK', { duration: 4000 })
            break;
          }

          default: {
            // this.snack.open('Une erreur est survenue!', 'OK', { duration: 4000 })

            break;
          }

        }
      },
        error => {
          this.logger.error("error");
          this.logger.error(error);

          switch (error.status) {

            default: {

              //    this.snack.open('Une erreur est survenue!', 'OK', { duration: 4000 })

              break;
            }
          }
        });
  }

  createPointlivraison() {
    this.dialog.open(AddlivraisonComponent, { width: '720px' }).afterClosed().subscribe((res) => {
      console.log(res);

      if (!res) {
        return;
      }

      let request: any = res;
      let u = new Agentstorage();
      u = JSON.parse(localStorage.getItem("curentuser"));
      if (u != null) {
        request.userpisteaudit = u.agent.agentemail + " " + u.agent.agentfirstname + " " + u.agent.agentlastname;
      }
      this.pointservice.post(request, "pointlivraison/add").subscribe(data => {
        this.logger.trace('DATA', data);
        switch (data.status) {

          case 201: {

            console.log("data");
            console.log(data);

            this.getallPointlivraison();
            this.loader.close();
            this.snackbar.open('Point de livraison crée avec succes ', 'OK', {
              duration: 10000
            });

            break;
          }

        }
      },
        error => {
          this.logger.error('ERREUR', error);
          switch (error.status) {

            case 409: {
              //this.getallFood();
              this.loader.close();
              this.snackbar.open('Ce point de livraison existe deja ', 'OK', {
                duration: 10000
              });
              break;
            }

            default: {
              this.loader.close();

              this.snackbar.open('Echec !! Une erreur est survenue ', 'OK', {
                duration: 10000
              });
              break;
            }
          }
        });
    });
  }




  deleteItem(row) {
    this.confirmService.confirm({
      message: `Voulez vous supprimer le point de livraison : ${row.pointlivraisonlibele}?  `
    })
      .subscribe(res => {
        if (res) {

          console.log("resultat obtenu ");
          console.log(row);

          let u = new Agentstorage();
          u = JSON.parse(localStorage.getItem("curentuser"));
          if (u != null) {
            row.userpisteaudit = u.agent.agentemail + " " + u.agent.agentfirstname + " " + u.agent.agentlastname;
          }

          this.pointservice.post(row, "pointlivraison/delete").subscribe(data => {

            this.getallPointlivraison();
            this.snackbar.open('Success ', 'OK', {
              duration: 10000
            });

          },
            error => {

              this.snackbar.open('Echec !! Une erreur est survenue ', 'OK', {
                duration: 10000
              });
              console.log("erreur retour");
              console.log(error);
            }
          );
        }
      })
  }




  openPopUp(value) {
    this.dialog.open(UpdatelivraisonComponent,
      {
        //disableClose: true,
        width: '720px',
        data: { title: '', payload: value }
      }
    ).afterClosed().subscribe((res) => {
      console.log(res);

      if (!res) {
        return;
      }

      let request: any = res;

      let u = new Agentstorage();
      u = JSON.parse(localStorage.getItem("curentuser"));
      if (u != null) {
        request.userpisteaudit = u.agent.agentemail + " " + u.agent.agentfirstname + " " + u.agent.agentlastname;
      }

      this.pointservice.post(request, "pointlivraison/update").subscribe(data => {
        this.logger.trace('DATA', data);


        switch (data.status) {

          case 200: {

            console.log("data");
            console.log(data);

            this.getallPointlivraison();
            this.loader.close();
            this.snackbar.open('Point de livraison mis a jour avec succes ', 'OK', {
              duration: 10000
            });

            break;
          }

        }
      },
        error => {
          this.logger.error('ERREUR', error);
          switch (error.status) {

            case 409: {
              //this.getallFood();
              this.loader.close();
              this.snackbar.open('Ce point de livraison existe deja ', 'OK', {
                duration: 10000
              });
              break;
            }

            default: {
              this.loader.close();

              this.snackbar.open('Echec !! Une erreur est survenue ', 'OK', {
                duration: 10000
              });
              break;
            }
          }
        });

    });
  };


  public verifierDroit() {
    this.logger.trace('BEGIN');
    // this.logger.trace('Clé', this.configService.get('keylocalstorage'));

    let agentOnline: Agentstorage;
    agentOnline = JSON.parse(localStorage.getItem(this.configService.get('keylocalstorage')));
    // this.logger.trace(agentOnline);
    let rolefontion: Rolefonction[] = agentOnline.rolefonction;
    // this.logger.trace('rolefontion', rolefontion);

    for (const key in rolefontion) {
      if (rolefontion.hasOwnProperty(key)) {
        // this.logger.trace('KEY', key);
        const element = rolefontion[key].fonction.fonctionname;
        this.logger.trace('element', element);

        if (element == this.keyAfficherPointlivraison) {
          this.logger.trace('true');
          this.afficherPointlivraison = true;
        }
        if (element == this.keyCreationPointlivraison) {
          this.logger.trace('true');
          this.creationPointlivraison = true;
        }
        if (element == this.keyModificationPointlivraison) {
          this.logger.trace('true');
          this.modificationPointlivraison = true;
        }
        if (element == this.keySuppressionPointlivraison) {
          this.logger.trace('true');
          this.suppressionPointlivraison = true;
        }
      }
    }

  }

}
