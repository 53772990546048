import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpHeaders } from '@angular/common/http';
import { ConfigService } from 'ngx-envconfig';
import { Agentstorage } from 'src/app/models/agentstorage';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AnnulationService {
  
  //http://localhost:8088/parameter/historiqueVente/findallorderbydate
  private getallcomandes = this.configService.get('ipMS') + 'parameter/cancel/get';
 
  authorization: string = " ";
  caissierid: string = " ";


  constructor(private http: HttpClient, private configService: ConfigService) {
    let u = new Agentstorage();
    u = JSON.parse(localStorage.getItem('curentuser'));

    if (u != null) {
      this.authorization = u.token;
      this.caissierid = u.agent.agentid;

      console.log("authorization == " + this.authorization);
    }
  }

  public getAllCommandes(value: any): Observable<HttpResponse<any>> {
    console.log(value);
    return this.http.post(this.getallcomandes, JSON.stringify(value), {
      observe: 'response',
      headers: new HttpHeaders({ 'Access-Control-Allow-Origin': '*' })
        .set("content-Type", "application/json")
        .set('Authorization', this.authorization)
    });
  }

}
