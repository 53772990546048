import { Role } from './Role';
import { Addresse } from './addresse';


export class Caisse {
  caisseid: string;
  caissecode: string;
  caissename: string;
  caisseip: string;
  caissemacethernet: string;
  caissemacwifi: string;
  caissedatecreation: any;
  caissedernieremodification: any;

}

export class Agent {

  agentid: string;
  agentdatecreation: string;
  agentdatelastcon: string;
  agentemail: string;
  agentfirstname: string;
  agentisdeleted: string;
  agentlastname: string;
  agentpassword: string;
  agenttelephone: string;
  agentmatricule: string;

  available: boolean;
  delivery_on_hold: number;

  role: Role;
  addresses: Addresse[];

  agentdatedebut: Date;
  agentdatefin: String;

  agentisactive: boolean;
  agentlogin: string;

  roleName: string;

  userpisteaudit: string;
}


export class livraison {

  livraisonmodename: string;
}

export class paymentmode {
  paymentmodename: string;
}




export interface Item {
  itemid: string;
  itemname: string;
  itemprice: number;
  itemquantity: number;
  itemref: string;
}

export interface Address2 {
  addressid: string;
  addressisdeleted: boolean;
  addresslocation: string;
}

export interface Clienttype {
  clienttypedatecreation: Date;
  clienttypeid: string;
  clienttypeisdeleted: boolean;
  clienttypename: string;
}

export class Statcpteclient {
  web: number = 0;
  mobile: number = 0;
}

export interface Client {
  addresses: Address2[];
  clientdatecreation: Date;
  clientdatelastcon: Date;
  clientemail: string;
  clientfirstname: string;
  clientid: string;
  clientisdeleted: boolean;
  clientlastname: string;
  clientpassword: string;
  clienttelephone: string;
  clienttype: Clienttype;
}


export interface Historiq {

  agent: Agent;
  client: Client;
  commanddateannulation: any;
  clientname: string;
  agentname: string;
  commandereference: string;
  commandreference: string;
  historiquetotalprice: number;
  historiqueventedate: any;
  historiqueventeid: string;
  items: Item[];
  livraisonStatus: boolean;
  livraisonmode: string;
  paymentStatus: boolean;
  paymentmode: string;
  totalcommandprix: number;
  commandlieulivraison: string;
  commanddatelivraison: any;
  historiqueprovenance: string;
  commandtotalpricepayer: number;
  commandremise: number;

}


export class LivraisonStatus {
  commandid: string;
  paymentStatus: boolean;
  agentid: string;
}

export class deliveryStatus {
  commandid: string;
}

export interface HistoriqAbnFile {
  histabnfileid: string;
  histabnfiletransid: string;
  histabnfiledate: string;
  histabnfilenbre: number;
  histabnfilemontant: string;
  histabnfilestatus: string;
  histabnfileagent: string;
  histabnfileref: string;
  histabnfileenable: boolean;
}


export interface Abonnement {
  abonnementid: string;
  abonnementformuleid: string;
  abonnementprecompte: boolean;
  abonnementdecouvert: boolean;
  abonnementsoldeinitial: number;
  abonnementsoldeavant: number;
  abonnementsoldeactuel: number;
  abonnementsoldeinitialdecouvert: number;
  abonnementsoldeavantdecouvert: number;
  abonnementsoldeactueldecouvert: number;
  abonnementdevise: string;
  abonnementmodecreation: string;
  abonnementmodepaiement: string;
  abonnementstatut: string;
  abonnementenable: boolean;
  abonnementisdeleted: boolean;
  abonnementdureevalidite: number;
  abonnementdatecreation: string;
  abonnementdatedebut: string;
  abonnementdatefin: string;
  abonnementdateactivation: string;
  abonnementemail: string;
  client: Client;
}

export interface Abnstat {
  abnid: string;
  action: number;
  agent: string;
  clientid: string;
  histabnfileref: string;
  montant: number;
}