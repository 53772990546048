import { ConfigService } from 'ngx-envconfig';
import { Injectable } from '@angular/core';
import { Agentstorage } from '../../models/agentstorage';
import { HttpClient, HttpResponse, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CarteabonnementService {

  private findallcarteabonnement = this.configService.get('ipMS') + 'carte';

  private addcarteabonnement = this.configService.get('ipMS') + 'carte';
  private enabledcarteabonnement = this.configService.get('ipMS') + 'carte/activation';
  private linkcarteabonnement = this.configService.get('ipMS') + 'carte/linkcard';

  private cardprint = this.configService.get('ipMS') + 'carte/saveprint';

  private cardfound = this.configService.get('ipMS') + 'carte/statutfound';


  authorization: string = " ";
  caissierid: string = " ";


  constructor(private http: HttpClient, private configService: ConfigService) {
    let u = new Agentstorage();
    u = JSON.parse(localStorage.getItem('curentuser'));

    if (u != null) {
      this.authorization = u.token;
      this.caissierid = u.agent.agentid;
    }
  }

  getAllCarteabonnement(): Observable<HttpResponse<any>> {
    return this.http.get(this.findallcarteabonnement,
      {
        observe: 'response',
        headers: new HttpHeaders({ 'Access-Control-Allow-Origin': '*' })
          .set("content-Type", "application/json")
          .set("Authorization", this.authorization)
      });
  }

  public createCarte(value: any): Observable<HttpResponse<any>> {
    console.log(JSON.stringify(value));
    return this.http.post(this.addcarteabonnement, JSON.stringify(value), {
      observe: 'response',
      headers: new HttpHeaders({ 'Access-Control-Allow-Origin': '*' })
        .set("content-Type", "application/json")
        .set("Authorization", this.authorization)
    });
  }



  public ActivateCarte(value: any): Observable<HttpResponse<any>> {
    console.log(JSON.stringify(value));
    return this.http.put(this.enabledcarteabonnement, JSON.stringify(value), {
      observe: 'response',
      headers: new HttpHeaders({ 'Access-Control-Allow-Origin': '*' })
        .set("content-Type", "application/json")
        .set("Authorization", this.authorization)
    });
  }

  public LierCarte(value: any): Observable<HttpResponse<any>> {
    console.log(JSON.stringify(value));
    return this.http.post(this.linkcarteabonnement, JSON.stringify(value), {
      observe: 'response',
      headers: new HttpHeaders({ 'Access-Control-Allow-Origin': '*' })
        .set("content-Type", "application/json")
        .set("Authorization", this.authorization)
    });
  }


  public printCard(value: any): Observable<HttpResponse<any>> {
    console.log(JSON.stringify(value));
    return this.http.post(this.cardprint, JSON.stringify(value), {
      observe: 'response',
      headers: new HttpHeaders({ 'Access-Control-Allow-Origin': '*' })
        .set("content-Type", "application/json")
        .set("Authorization", this.authorization)
    });
  }

  public foundCard(value: any): Observable<HttpResponse<any>> {
    console.log(JSON.stringify(value));
    return this.http.post(this.cardfound, JSON.stringify(value), {
      observe: 'response',
      headers: new HttpHeaders({ 'Access-Control-Allow-Origin': '*' })
        .set("content-Type", "application/json")
        .set("Authorization", this.authorization)
    });
  }

}
