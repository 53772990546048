import { GenericserviceService } from 'src/app/services/genericservice.service';
import { FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';
import { MatTableDataSource, MatPaginator, MatSort, MatDialog, MatSnackBar } from '@angular/material';
import { Categorie } from './../../../models/Categorie';
import { CategorieModifierComponent } from './categorie-modifier/categorie-modifier.component';
import { Component, OnInit, ViewChild } from '@angular/core';
import { DeletedialogcategorieComponent } from './deletedialogcategorie/deletedialogcategorie.component';

@Component({
  selector: 'fury-categorie',
  templateUrl: './categorie.component.html',
  styleUrls: ['./categorie.component.scss']
})
export class CategorieComponent implements OnInit {


  private categories: Categorie[];
  private selectedValue: Categorie;
  categorieForm: FormGroup;


  categorieId: string;

  categoriename: string;
  categorieisdeleted: boolean;
  categoriedatecreation: string;
  categorie: Categorie;

  displayedColumns: string[] = ['categoriename', 'categoriedatecreation', 'categoriepath', 'actions'];
  dataSource: any;


  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  constructor(private categorieService: GenericserviceService, private formBuilder: FormBuilder, private snackbar: MatSnackBar, public dialog: MatDialog) {
  }

  selectedFiles: FileList;
  currentFileUpload: File;


  selectFile(event) {
    this.selectedFiles = event.target.files;
  }



  ngOnInit() {

    this.categorieService.type = 'categorie';

    this.findAll();

    this.categorieForm = new FormGroup({
      categoriename: new FormControl('', Validators.required)

    });

    this.categorieForm = this.formBuilder.group({
      categoriename: ['', Validators.required]
    })

  }


  onSubmit() {

    console.info('-----------------------------------------');


    if (this.categorieForm.valid) {
      // saving object

      let cat: CategorieDto = new CategorieDto();
      cat.categoriename = this.categorieForm.controls['categoriename'].value;
      this.categorieService.saveCategorie(cat).subscribe(data => {

        console.log(data);
        switch (data.status) {
          case 201: {
            // this.loading.dismiss('messagesService.loadMessagesOverview');

            let resp: Categorie;
            resp = data.body;
            console.log(resp);
            console.log("------------------------------------");
           

            if (resp != null) {


              this.categorieService.sendFileToStorage(this.selectedFiles.item(0), resp.categorieid).subscribe(data => {

                this.findAll();

                this.snackbar.open('Success categorie Créé', 'OK', {
                  duration: 2000
                });
    

  
              },
                error => {
  
  
                  this.snackbar.open('Echec !! Une erreur est survenue lors du chargement du fichier', 'OK', {
                    duration: 10000
                  });
  
                  console.log("======================Fichier erreur retour ");
                  console.log(error);
  
                }
              );

              console.log("-------------- success categorie created ----------------");
              //this.router.navigateByUrl('./');

              // this.router.navigateByUrl('/dashboard');
            } else {
              // this.FormReg(" Mot de passe ou login incorrect ");
            }
            break;
          }
        }
      },
        error => {
          console.log("erreur retour");
          // this.loading.dismiss('messagesService.loadMessagesOverview');
          switch (error.status) {
            case 409:
              //this.FormReg(" Mot de passe ou login incorrect ");
              this.snackbar.open('Echec modification', 'Le categorie existe deja', {
                duration: 2000
              });
              break;
            case 401:
              //this.FormReg(" Mot de passe ou login incorrect ");
              break;
            default: {
              // this.FormReg(" erreur de connexion !!! Veuillez ressayer");
              this.snackbar.open('Echec modification', 'OK', {
                duration: 2000
              });
              break;
            }
          }

        });

      this.categorieForm.reset({ categoriename: '' });

      /////////////
    } else {
      this.validateAllFormFields(this.categorieForm);
      this.snackbar.open('Veuillez saisir toutes les valeurs svp', 'OK', {
        duration: 2000
      });



    }




  }

  validateAllFormFields(formGroup: FormGroup) {
    Object.keys(formGroup.controls).forEach(field => {
      const control = formGroup.get(field);
      if (control instanceof FormControl) {
        control.markAsTouched({ onlySelf: true });
      } else if (control instanceof FormGroup) {
        this.validateAllFormFields(control);
      }
    });
  }


  findAll() {

    this.categorieService.findAll().subscribe(data => {
      console.log(data);
      switch (data.status) {
        case 200: {
          // this.loading.dismiss('messagesService.loadMessagesOverview');

          let resp: Categorie[];
          resp = data.body;
          console.log("------------------------------------");
          console.log(resp);
          console.log("------------------------------------");
          console.log(resp[0].categoriedatecreation);
          this.categories = resp;
          // this.dataSource = valeurs;
          this.dataSource = new MatTableDataSource<Categorie>(resp);
          this.dataSource.paginator = this.paginator;
          this.dataSource.sort = this.sort;


          if (resp != null) {
            //this.resetFunction();


            console.log("------------- categories founded ------------------");

          } else {

            // this.FormReg(" Mot de passe ou login incorrect ");
          }
          break;
        }
      }
    },
      error => {
        console.log("erreur retour");
        // this.loading.dismiss('messagesService.loadMessagesOverview');
        switch (error.status) {
          case 401:
            //this.FormReg(" Mot de passe ou login incorrect ");
            break;
          default: {
            // this.FormReg(" erreur de connexion !!! Veuillez ressayer");
            break;
          }
        }
      }
    );

  }


  updateCategorie(row) {
    console.log(' edit categorie ');

    if (this.categorieId) {
      console.log('id ok ----editing ');

      let categorie: Categorie = new Categorie();
      categorie.categorieid = this.categorieId;
      categorie.categoriename = this.categorieForm.controls['categoriename'].value;
      // this.valeurForm.controls['categorieLastName'].value,
      // this.valeurForm.controls['categorieEmail'].value;

      // updating object
      this.categorieService.update(categorie).subscribe();
    }


  }


  deleteCategorie(row) {
    console.log('-- Delete categorie ---  ' + row);

    if (row) {
      let categorie: Categorie = row;
      this.openDialogDeletes(categorie);
      /*this.categorieService.delete(categorie.categorieid).subscribe(
        res => {
          this.findAll();
          //this.router.navigate(['/valeur']);
          console.log('----  done ---- ');
          this.snackbar.open('Success supprimé' , 'OK', {
            duration: 2000
          });
      
        }
      );*/
    }




  }

  openDialogDelete(categorie): void {
    const dialogRef = this.dialog.open(DeletedialogcategorieComponent, {
      width: '250px',
      data: { categorie: categorie }
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
      //this.animal = result;
      this.findAll();
    });
  }




  openDialogDeletes(categorie): void {
    this.dialog.open(DeletedialogcategorieComponent, {
      width: '450px',
      data: { categorie: categorie }
    }).afterClosed().subscribe((res) => {
      console.log(res);

      if (!res) {
        return;
      }

      this.categorieService.delete(res).subscribe(
        data => {
          console.log('-- Delete livraison response ---  ' + data.statusText + ' ' + data.status);

          switch (data.status) {
            case 200: {
              this.snackbar.open('Success supprimé', 'OK', {
                duration: 2000
              });
              this.findAll();
              break;
            }
            default:
              this.snackbar.open('Echec suppression, categorie not found', 'veuillez rafraichir la page svp', {
                duration: 2000
              });
              this.findAll();
          }
        }
      );


    });
  }


  openDialog(row): void {
    //console.log('------onpen dialog -------');

    console.log(row);
    this.selectedValue = row;
    if (row) {
      this.callCategorieDetail();
    }
  }


  callCategorieDetail() {
    console.log('------onpen dialog -------');

    const dialogRef = this.dialog.open(CategorieModifierComponent, {
      maxWidth: '80%',

      data: { categorie: this.selectedValue }
    });

    dialogRef.afterClosed().subscribe(resp => {

      console.log('The dialog was closed .. resp --- ' + resp);



      this.findAll();
      console.log('======================');
      // this.categorie = result;

    });
  }
}

export class CategorieDto {
  categoriename: string;
  categoriecode: string;
}

