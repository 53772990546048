import { ConfigService } from 'ngx-envconfig';
import { HttpResponse, HttpHeaders, HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { NGXLogger } from 'ngx-logger';
import { Injectable } from '@angular/core';
import { Agentstorage } from 'src/app/models/agentstorage';


@Injectable({
  providedIn: 'root'
})
export class PisteauditService {


  authorization: string;

  private crudaudit = this.configService.get('ipMS') + 'parameter/pisteaudit/byorder';

  constructor(private http: HttpClient, private configService: ConfigService,
    private logger: NGXLogger) {
    let user: Agentstorage = new Agentstorage();
    user = JSON.parse(localStorage.getItem('curentuser'));

    if (user != null) {
      this.authorization = user.token;
    }
  }


  getData(): Observable<HttpResponse<any>> {
    return this.http.get(this.crudaudit, {
      observe: 'response',
        headers: new HttpHeaders({ 'Access-Control-Allow-Origin': '*' })
          .set('content-Type', 'application/json')
          .set('Authorization', this.authorization)
      });
  }


}
