import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { StatforclientRoutingModule } from './statforclient-routing.module';
import { StatforclientComponent } from './statforclient.component';
import { StatforallComponent } from './statforall/statforall.component';
import { StatforcommandComponent } from './statforcommand/statforcommand.component';
import { MaterialModule } from 'src/@fury/shared/material-components.module';
import { ReactiveFormsModule } from '@angular/forms';
import { StatforemporteComponent } from './statforemporte/statforemporte.component';
import { StatforlivreComponent } from './statforlivre/statforlivre.component';
import { StatforsurplaceComponent } from './statforsurplace/statforsurplace.component';
import { BreadcrumbsModule } from 'src/@fury/shared/breadcrumbs/breadcrumbs.module';
import { MatInputModule, MatChipsModule } from '@angular/material';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { FuryCardModule } from 'src/@fury/shared/card/card.module';
import { StatabnComponent } from './statabn/statabn.component';
import { StatcpteComponent } from './statcpte/statcpte.component';
import { DialogCorporateComponent } from './dialog-corporate/dialog-corporate.component';

@NgModule({
  declarations: [StatforclientComponent, StatforallComponent, StatforcommandComponent, StatforemporteComponent, StatforlivreComponent, StatforsurplaceComponent, StatabnComponent, StatcpteComponent, DialogCorporateComponent],
  imports: [
    CommonModule,
    StatforclientRoutingModule,
    CommonModule,
    MaterialModule,
    ReactiveFormsModule,

    CommonModule,
    ReactiveFormsModule,
    MaterialModule,
    BreadcrumbsModule,
    MatInputModule,
    NgxDatatableModule,
    FuryCardModule,
    MatChipsModule,
  ],
  entryComponents: [DialogCorporateComponent]
})
export class StatforclientModule { }
