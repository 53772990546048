import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MatDialog, MatSnackBar, MatTableDataSource } from '@angular/material';
import { Router } from '@angular/router';
import { ConfigService } from 'ngx-envconfig';
import { NGXLogger } from 'ngx-logger';
import { NgxPermissionsService } from 'ngx-permissions';
import { Subscription } from 'rxjs';
import { ConfirmationService } from 'src/app/confirm/confirmation.service';
import { HistoriqAbnFile } from 'src/app/models/Agent';
import { Agentstorage } from 'src/app/models/agentstorage';
import { Rolefonction } from 'src/app/models/rolefonction';
import { ApploaderService } from 'src/app/services/apploader/apploader.service';
import { AbonnementService } from './abonnement.service';
import { AddloadfileComponent } from './addloadfile/addloadfile.component';
import { LoadfileComponent } from './loadfile/loadfile.component';

@Component({
  selector: 'fury-abonnementmasse',
  templateUrl: './abonnementmasse.component.html',
  styleUrls: ['./abonnementmasse.component.scss']
})
export class AbonnementmasseComponent implements OnInit {


  public addNewAbonnement: boolean;
  public keyAddAbonnement = 'Effectuer en abonnement';

  public items: HistoriqAbnFile[];
  temp = [];
  rows = [];
  columns = [];
  public historiq: Array<HistoriqAbnFile> = [];
  dataSource: MatTableDataSource<HistoriqAbnFile> = new MatTableDataSource([]);

  public getItemSub: Subscription;


  constructor(private dialog: MatDialog, public formBuilder: FormBuilder,
    private router: Router,
    private snackbar: MatSnackBar, private abnserv: AbonnementService,
    private datePipe: DatePipe,
    private confirmService: ConfirmationService, private loader: ApploaderService,
    private permissionsService: NgxPermissionsService, private logger: NGXLogger,
    private configService: ConfigService, private fb: FormBuilder) { }


  ngOnInit() {
    this.verifierDroit();
    this.getallAbonnement();
  }



  public verifierDroit() {
    this.logger.trace('BEGIN');
    // this.logger.trace('Clé', this.configService.get('keylocalstorage'));

    let agentOnline: Agentstorage;
    agentOnline = JSON.parse(localStorage.getItem(this.configService.get('keylocalstorage')));
    // this.logger.trace(agentOnline);
    let rolefontion: Rolefonction[] = agentOnline.rolefonction;
    // this.logger.trace('rolefontion', rolefontion);

    for (const key in rolefontion) {
      if (rolefontion.hasOwnProperty(key)) {
        // this.logger.trace('KEY', key);
        const element = rolefontion[key].fonction.fonctionname;
        this.logger.trace('element', element);

        if (element == this.keyAddAbonnement) {
          this.logger.trace('true');
          this.addNewAbonnement = true;
        }

      }
    }

  }


  getallAbonnement() {
    this.getItemSub = this.abnserv.getAllabonnement('carte/getallabnbyfile')
      .subscribe(data => {
        let responseany: any = data;
        console.log(responseany);
        this.logger.trace('DATA', data);

        switch (data.status) {

          default: {
            let response: any = data.body;
            let respfinal: HistoriqAbnFile[] = response;

            this.items = this.temp = respfinal;

            this.dataSource = new MatTableDataSource(this.items);
            break;
          }


        }
      },
        error => {
          console.log("error");
          console.log(error);

          switch (error.status) {

            default: {
              let respfinal: HistoriqAbnFile[] = [];
              this.items = this.temp = respfinal;
              this.dataSource = new MatTableDataSource(this.items);
              break;
            }
          }
        });
  }


  showAbn(abn: HistoriqAbnFile) {
    if (abn) {
      localStorage.setItem('hist_abn_parameter', JSON.stringify(abn));
      this.router.navigate(['/yolinbo/historique/abonnementmassif']);
    }
  }

  loadFile() {
    this.dialog.open(LoadfileComponent, { width: '720px' }).afterClosed().subscribe((res) => {
      console.log(res);
      if (!res) {
        return;
      }
      this.abnserv.pushFileToStorage(res.file, res.agentemail).subscribe(data => {
        this.getallAbonnement();
        this.snackbar.open('Fichier chargé avec succes ', 'OK', {
          duration: 10000
        });
      },
        error => {

          this.logger.error('ERREUR', error);
          switch (error.status) {

            case 406: {
              // this.getallFood();
              this.loader.close();
              this.snackbar.open('fichier vide ou format non accepté ', 'OK', {
                duration: 50000
              });
              break;
            }

            case 423: {
              // this.getallFood();
              this.loader.close();
              this.snackbar.open('Aucun abonnement enregistré ', 'OK', {
                duration: 50000
              });
              break;
            }

            default: {
              this.loader.close();

              this.snackbar.open('Echec !! Une erreur est survenue ', 'OK', {
                duration: 50000
              });
              break;
            }
          }

        });

    });
  }

  addLoadFile(row) {
    this.dialog.open(AddloadfileComponent, { width: '720px', data: { title: '', payload: row } }).afterClosed().subscribe((res) => {
      console.log(res);
      if (!res) {
        return;
      }
      this.abnserv.pushFileToStorage(res.file, res.agentemail + "/" + res.transaction).subscribe(data => {
        this.getallAbonnement();
        this.snackbar.open('Fichier chargé avec succes ', 'OK', {
          duration: 10000
        });
      },
        error => {
          this.logger.error('ERREUR', error);
          switch (error.status) {

            case 412: {
              // this.getallFood();
              this.loader.close();
              this.snackbar.open('Aucune historique trouvée pour cette transaction ', 'OK', {
                duration: 50000
              });
              break;
            }


            case 406: {
              // this.getallFood();
              this.loader.close();
              this.snackbar.open('fichier vide ou format non accepté ', 'OK', {
                duration: 50000
              });
              break;
            }

            case 423: {
              // this.getallFood();
              this.loader.close();
              this.snackbar.open('Aucun abonnement enregistré ', 'OK', {
                duration: 50000
              });
              break;
            }

            default: {
              this.loader.close();

              this.snackbar.open('Echec !! Une erreur est survenue ', 'OK', {
                duration: 50000
              });
              break;
            }
          }

        });

    });
  }

  updateFilter(event) {
    const val = event.target.value.toLowerCase();
    var columns = Object.keys(this.temp[0]);
    // Removes last "$$index" from "column"
    columns.splice(columns.length - 1);

    // console.log(columns);
    if (!columns.length)
      return;

    const rows = this.temp.filter(function (d) {
      for (let i = 0; i <= columns.length; i++) {
        let column = columns[i];
        // console.log(d[column]);
        if (d[column] && d[column].toString().toLowerCase().indexOf(val) > -1) {
          return true;
        }
      }
    });

    this.items = rows;
  }

}
