import { DialogData } from './../categorie-modifier/categorie-modifier.component';
import { GenericserviceService } from './../../../../services/genericservice.service';
import { MatDialogRef, MAT_DIALOG_DATA, MatSnackBar } from '@angular/material';
import { Component, OnInit, Inject } from '@angular/core';

@Component({
  selector: 'fury-deletedialogcategorie',
  templateUrl: './deletedialogcategorie.component.html',
  styleUrls: ['./deletedialogcategorie.component.scss']
})
export class DeletedialogcategorieComponent implements OnInit {
  resp: number = 0;


  ngOnInit() {
  }

  constructor(public dialogRef: MatDialogRef<DeletedialogcategorieComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData, private categorieService: GenericserviceService, private snackbar: MatSnackBar) { }

  onNoClick(): void {
    this.dialogRef.close();
  }

  deleteCategorie() {
    console.log('-- Delete categorie ---  ' + this.data.categorie.categorieid);
    this.dialogRef.close(this.data.categorie.categorieid);

  }


}
