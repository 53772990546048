import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { AbstractControlStatus } from '@angular/forms/src/directives/ng_control_status';
import { MatDialog, MatSnackBar, MatTableDataSource } from '@angular/material';
import { Router } from '@angular/router';
import { ConfigService } from 'ngx-envconfig';
import { NGXLogger } from 'ngx-logger';
import { NgxPermissionsService } from 'ngx-permissions';
import { Subscription } from 'rxjs';
import { ConfirmationService } from 'src/app/confirm/confirmation.service';
import { Abnstat, Abonnement, HistoriqAbnFile } from 'src/app/models/Agent';
import { Agentstorage } from 'src/app/models/agentstorage';
import { Rolefonction } from 'src/app/models/rolefonction';
import { ApploaderService } from 'src/app/services/apploader/apploader.service';
import { AbonnementService } from '../abonnement.service';
import { SoldeabnComponent } from '../soldeabn/soldeabn.component';
import { StatutabnComponent } from '../statutabn/statutabn.component';

@Component({
  selector: 'fury-histabn',
  templateUrl: './histabn.component.html',
  styleUrls: ['./histabn.component.scss']
})
export class HistabnComponent implements OnInit {

  reference: string = '';
  //histabn: HistoriqAbnFile;

  public soldeAbonnement: boolean;
  public enabledAbonnement: boolean;


  public keySoldeAbonnement = 'Debiter / Crediter un abonnement';
  public keyEnabledAbonnement = 'Activer / Desactiver / Annuler un abonnement';


  public items: Abonnement[];
  temp = [];
  rows = [];
  columns = [];
  public historiq: Array<Abonnement> = [];
  dataSource: MatTableDataSource<Abonnement> = new MatTableDataSource([]);
  public getItemSub: Subscription;

  constructor(private dialog: MatDialog, public formBuilder: FormBuilder,
    private router: Router,
    private snackbar: MatSnackBar, private abnserv: AbonnementService,
    private datePipe: DatePipe,
    private confirmService: ConfirmationService, private loader: ApploaderService,
    private permissionsService: NgxPermissionsService, private logger: NGXLogger,
    private configService: ConfigService, private fb: FormBuilder) { }

  ngOnInit() {
    this.verifierDroit();
    let histabn: HistoriqAbnFile = JSON.parse(localStorage.getItem('hist_abn_parameter'));
    if (histabn) {
      this.reference = histabn.histabnfiletransid
      this.getallAbonnement('carte/getallabnbytransaction/' + histabn.histabnfiletransid);
    } else {
      this.router.navigate(['/yolinbo/abonnementmassif']);
    }
  }


  showAbn() {
    localStorage.removeItem('hist_abn_parameter');
    this.router.navigate(['/yolinbo/abonnementmassif']);
  }


  getallAbonnement(url: string) {

    this.getItemSub = this.abnserv.getAllabonnement(url)
      .subscribe(data => {

        let responseany: any = data;

        console.log(responseany);
        this.logger.trace('DATA', data);

        switch (data.status) {

          default: {
            let response: any = data.body;
            let respfinal: Abonnement[] = response;

            this.items = this.temp = respfinal;

            this.dataSource = new MatTableDataSource(this.items);
            break;
          }


        }
      },
        error => {
          console.log("error");
          console.log(error);

          switch (error.status) {

            default: {
              let respfinal: Abonnement[] = [];
              this.items = this.temp = respfinal;
              this.dataSource = new MatTableDataSource(this.items);
              break;
            }
          }
        });
  }


  updateFilter(event) {
    const val = event.target.value.toLowerCase();
    var columns = Object.keys(this.temp[0]);
    // Removes last "$$index" from "column"
    columns.splice(columns.length - 1);

    // console.log(columns);
    if (!columns.length)
      return;

    const rows = this.temp.filter(function (d) {
      for (let i = 0; i <= columns.length; i++) {
        let column = columns[i];
        // console.log(d[column]);
        if (d[column] && d[column].toString().toLowerCase().indexOf(val) > -1) {
          return true;
        }
      }
    });

    this.items = rows;
  }


  updateSolde(row: Abonnement, param: number) {
    let abn_solde = {} as Abnstat;
    let histabn: HistoriqAbnFile = JSON.parse(localStorage.getItem('hist_abn_parameter'));
    let u = new Agentstorage();
    u = JSON.parse(localStorage.getItem('curentuser'));

    if (histabn && u) {
      this.reference = histabn.histabnfiletransid;
      abn_solde.abnid = row.abonnementid;
      abn_solde.action = param;
      abn_solde.agent = u.agent.agentemail;
      abn_solde.clientid = row.client.clientid;
      abn_solde.histabnfileref = histabn.histabnfiletransid;
      this.dialog.open(SoldeabnComponent, {
        //disableClose: true,
        width: '720px',
        data: { title: '', payload: abn_solde }
      }).afterClosed().subscribe((res) => {
        console.log(res);
        if (!res) {
          return;
        }

        console.log("<------------------ MISE A JOUR DU SOLDE --------------->");
        this.abnserv.post("carte/updateabnsolde", res).subscribe(data => {
          switch (data.status) {
            case 200: {

              let histabn: HistoriqAbnFile = JSON.parse(localStorage.getItem('hist_abn_parameter'));
              if (histabn) {
                this.reference = histabn.histabnfiletransid
                this.getallAbonnement('carte/getallabnbytransaction/' + histabn.histabnfiletransid);
              } else {
                this.router.navigate(['/yolinbo/abonnementmassif']);
              }
              this.snackbar.open('Succes ', 'OK', {
                duration: 10000
              });

              break;
            }

            case 203: {
              // this.getallFood();
              this.loader.close();
              this.snackbar.open('Operation inconnue ', 'OK', {
                duration: 50000
              });
              break;
            }

            case 204: {
              // this.getallFood();
              this.loader.close();
              this.snackbar.open('Abonnement n existe pas ', 'OK', {
                duration: 50000
              });
              break;
            }

            case 304: {
              // this.getallFood();
              this.loader.close();
              this.snackbar.open('CARTE MASTER CORPORATE credit insuffisant ', 'OK', {
                duration: 50000
              });
              break;
            }
          }
        },
          error => {

            this.logger.error('ERREUR', error);
            switch (error.status) {

              case 203: {
                // this.getallFood();
                this.loader.close();
                this.snackbar.open('Operation inconnue ', 'OK', {
                  duration: 50000
                });
                break;
              }

              case 204: {
                // this.getallFood();
                this.loader.close();
                this.snackbar.open('Abonnement n existe pas ', 'OK', {
                  duration: 50000
                });
                break;
              }

              case 304: {
                // this.getallFood();
                this.loader.close();
                this.snackbar.open('CARTE MASTER CORPORATE credit insuffisant ', 'OK', {
                  duration: 50000
                });
                break;
              }


              case 406: {
                // this.getallFood();
                this.loader.close();
                this.snackbar.open('CARTE MASTER CORPORATE n existe', 'OK', {
                  duration: 50000
                });
                break;
              }

              case 412: {
                // this.getallFood();
                this.loader.close();
                this.snackbar.open('CARTE MASTER CORPORATE credit agent insufisant', 'OK', {
                  duration: 50000
                });
                break;
              }

              default: {
                this.loader.close();

                this.snackbar.open('Echec !! Une erreur est survenue ', 'OK', {
                  duration: 50000
                });
                break;
              }
            }

          });


      });

    } else {
      this.router.navigate(['/yolinbo/abonnementmassif']);
    }

  }


  updateStatut(row: Abonnement, param: number) {
    let abn_solde = {} as Abnstat;
    let histabn: HistoriqAbnFile = JSON.parse(localStorage.getItem('hist_abn_parameter'));
    let u = new Agentstorage();
    u = JSON.parse(localStorage.getItem('curentuser'));

    if (histabn && u) {
      this.reference = histabn.histabnfiletransid;
      abn_solde.abnid = row.abonnementid;
      abn_solde.action = param;
      abn_solde.agent = u.agent.agentemail;
      abn_solde.clientid = row.client.clientid;
      abn_solde.histabnfileref = histabn.histabnfiletransid;
      this.dialog.open(StatutabnComponent, {
        //disableClose: true,
        width: '720px',
        data: { title: '', payload: abn_solde }
      }).afterClosed().subscribe((res) => {
        console.log(res);
        if (!res) {
          return;
        }

        console.log("<------------------ MISE A JOUR DU STATUT --------------->");
        this.abnserv.post("carte/updateabnstatut", res).subscribe(data => {
          switch (data.status) {
            case 200: {

              let histabn: HistoriqAbnFile = JSON.parse(localStorage.getItem('hist_abn_parameter'));
              if (histabn) {
                this.reference = histabn.histabnfiletransid
                this.getallAbonnement('carte/getallabnbytransaction/' + histabn.histabnfiletransid);
              } else {
                this.router.navigate(['/yolinbo/abonnementmassif']);
              }
              this.snackbar.open('Succes ', 'OK', {
                duration: 10000
              });

              break;
            }

            case 203: {
              // this.getallFood();
              this.loader.close();
              this.snackbar.open('Operation inconnue ', 'OK', {
                duration: 50000
              });
              break;
            }

            case 204: {
              // this.getallFood();
              this.loader.close();
              this.snackbar.open('Abonnement n existe pas ', 'OK', {
                duration: 50000
              });
              break;
            }

            case 304: {
              // this.getallFood();
              this.loader.close();
              this.snackbar.open('CARTE MASTER CORPORATE credit insuffisant ', 'OK', {
                duration: 50000
              });
              break;
            }
          }
        },
          error => {

            this.logger.error('ERREUR', error);
            switch (error.status) {

              case 203: {
                // this.getallFood();
                this.loader.close();
                this.snackbar.open('Operation inconnue ', 'OK', {
                  duration: 50000
                });
                break;
              }

              case 204: {
                // this.getallFood();
                this.loader.close();
                this.snackbar.open('Abonnement n existe pas ', 'OK', {
                  duration: 50000
                });
                break;
              }

              case 304: {
                // this.getallFood();
                this.loader.close();
                this.snackbar.open('CARTE MASTER CORPORATE credit insuffisant ', 'OK', {
                  duration: 50000
                });
                break;
              }


              case 406: {
                // this.getallFood();
                this.loader.close();
                this.snackbar.open('CARTE MASTER CORPORATE n existe', 'OK', {
                  duration: 50000
                });
                break;
              }

              case 412: {
                // this.getallFood();
                this.loader.close();
                this.snackbar.open('CARTE MASTER CORPORATE credit agent insufisant', 'OK', {
                  duration: 50000
                });
                break;
              }

              default: {
                this.loader.close();

                this.snackbar.open('Echec !! Une erreur est survenue ', 'OK', {
                  duration: 50000
                });
                break;
              }
            }

          });


      });

    } else {
      this.router.navigate(['/yolinbo/abonnementmassif']);
    }

  }


  public verifierDroit() {
    this.logger.trace('BEGIN');
    // this.logger.trace('Clé', this.configService.get('keylocalstorage'));

    let agentOnline: Agentstorage;
    agentOnline = JSON.parse(localStorage.getItem(this.configService.get('keylocalstorage')));
    // this.logger.trace(agentOnline);
    let rolefontion: Rolefonction[] = agentOnline.rolefonction;
    // this.logger.trace('rolefontion', rolefontion);

    for (const key in rolefontion) {
      if (rolefontion.hasOwnProperty(key)) {
        // this.logger.trace('KEY', key);
        const element = rolefontion[key].fonction.fonctionname;
        this.logger.trace('element', element);

        if (element == this.keyEnabledAbonnement) {
          this.logger.trace('true');
          this.enabledAbonnement = true;
        }

        if (element == this.keySoldeAbonnement) {
          this.logger.trace('true');
          this.soldeAbonnement = true;
        }

      }
    }

  }
}
