import { DialogData } from './../modelivraison-modifier/modelivraison-modifier.component';
import { GenericserviceService } from 'src/app/services/genericservice.service';
import { MatDialogRef, MatSnackBar, MAT_DIALOG_DATA } from '@angular/material';
import { Component, OnInit, Inject } from '@angular/core';

@Component({
  selector: 'fury-deletedialogmodelivraison',
  templateUrl: './deletedialogmodelivraison.component.html',
  styleUrls: ['./deletedialogmodelivraison.component.scss']
})
export class DeletedialogmodelivraisonComponent implements OnInit {

  resp: number = 0;



  constructor(public dialogRef: MatDialogRef<DeletedialogmodelivraisonComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData, private livraisonService: GenericserviceService, private snackbar: MatSnackBar) { }


  ngOnInit() {
    this.livraisonService.type = 'livraison'
  }
  onNoClick(): void {
    this.dialogRef.close();
  }

  deleteLivraison() {
    console.log('-- Delete livraison ---  ' + this.data.livraison.livraisonmodeid);

    if (this.data.livraison.livraisonmodeid) {

      this.livraisonService.delete(this.data.livraison.livraisonmodeid).subscribe(
        data => {
          console.log('-- Delete livraison response ---  ' + data.statusText + ' ' + data.status);

          switch (data.status) {
            case 200: {
              this.snackbar.open('Success supprimé', 'OK', {
                duration: 2000
              });
              this.resp = 200;
              break;
            }
            default:
              this.snackbar.open('Echec suppression, livraison not found', 'veuillez rafraichir la page svp', {
                duration: 2000


              });
              this.resp = 502;
          }



        }
      );

      return this.resp;
    }

  }










  deleteLivraisons() {
    console.log('-- Delete livraison ---  ' + this.data.livraison.livraisonmodeid);
    this.dialogRef.close(this.data.livraison.livraisonmodeid);
  }

}
