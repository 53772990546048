import { Component, OnInit } from '@angular/core';
import { MatDialog, MatSnackBar, MatTableDataSource } from '@angular/material';
import { FormBuilder } from '@angular/forms';
import { ParametreService } from './parametre.service';
import { DatePipe } from '@angular/common';
import { ConfirmationService } from 'src/app/confirm/confirmation.service';
import { ApploaderService } from 'src/app/services/apploader/apploader.service';
import { NgxPermissionsService } from 'ngx-permissions';
import { NGXLogger } from 'ngx-logger';
import { ConfigService } from 'ngx-envconfig';
import { Param } from 'src/app/models/param';
import { Historiq } from 'src/app/models/Agent';
import { Subscription } from 'rxjs/internal/Subscription';
import { UpdateComponent } from './update/update.component';
import { UpdatelimitcmdComponent } from './updatelimitcmd/updatelimitcmd.component';
import { Rolefonction } from 'src/app/models/rolefonction';
import { Agentstorage } from 'src/app/models/agentstorage';
import { CancelcmdComponent } from './cancelcmd/cancelcmd.component';
import { Command } from 'src/app/models/command';
import { UpdatepanierComponent } from './updatepanier/updatepanier.component';

@Component({
  selector: 'fury-gestionparametre',
  templateUrl: './gestionparametre.component.html',
  styleUrls: ['./gestionparametre.component.scss']
})
export class GestionparametreComponent implements OnInit {

  public modificationLimit: boolean;
  public keyModificationLimit = 'limite des commandes';

  public items: Param[];
  temp = [];
  rows = [];
  columns = [];
  dataSource: MatTableDataSource<Param> = new MatTableDataSource([]);
  public getItemSub: Subscription;

  public limit: any;

  scrollBarHorizontal = (window.innerWidth < 1200);

  constructor(private dialog: MatDialog, public formBuilder: FormBuilder,
    private snackbar: MatSnackBar, private parametreserv: ParametreService,
    private datePipe: DatePipe,
    private confirmService: ConfirmationService, private loader: ApploaderService,
    private permissionsService: NgxPermissionsService, private logger: NGXLogger,
    private configService: ConfigService, private fb: FormBuilder) { }

  ngOnInit() {
    this.verifierDroit();
    this.getAllLimit();
    this.getallParameters();
  }

  getallParameters() {
    this.getItemSub = this.parametreserv.getAllParametre()
      .subscribe(data => {

        let responseany: any = data;

        console.log(responseany);
        this.logger.trace('DATA', data);

        switch (data.status) {

          default: {
            let response: any = data;
            this.items = this.temp = response.body;
            this.dataSource = new MatTableDataSource(this.items);
            break;
          }


        }
      },
        error => {
          console.log("error");
          console.log(error);

          switch (error.status) {

            default: {

              this.items = this.temp = [];
              this.dataSource = new MatTableDataSource(this.items);
              break;
            }
          }
        });
  }


  getParameterCart() {
    this.getItemSub = this.parametreserv.getParametre("parameter/utils/getparameterfordelay")
      .subscribe(data => {

        let responseany: any = data;

        console.log(responseany);
        this.logger.trace('DATA', data);

        switch (data.status) {

          default: {
            let resultat: Param = data.body;

            this.dialog.open(UpdatepanierComponent,
              {
                width: '720px',
                data: { title: '', payload: resultat }
              }
            ).afterClosed().subscribe((res) => {
              console.log(res);
              if (!res) {
                return;
              }

              let request : Param = res ;

              if(request){
                this.postDelay(request);
              }

              console.log(request);

            });


            console.log(resultat);
            this.loader.close();
            break;
          }
        }
      },
        error => {
          console.log("error");
          console.log(error);

          switch (error.status) {

            default: {
              this.loader.close();

              break;
            }
          }
        });
  }


  postDelay(value : Param){
    this.getItemSub = this.parametreserv.postNew("parameter/utils/updateparameterfordelay" , value)
    .subscribe(data => {

      let responseany: any = data;

      switch (data.status) {

        default: {
          
      console.log(responseany);
      this.snackbar.open('', 'Succes', {
        duration: 10000
      });
          break;
        }
      }
     
    },
      error => {
        console.log("error");
        console.log(error);

        switch (error.status) {

          default: {
            this.loader.close();
            this.snackbar.open('Echec', 'Veuillez veuillez verifier vos parametres saisies', {
              duration: 10000
            });
            break;
          }
        }
      });
  }


  getAllLimit() {
    this.getItemSub = this.parametreserv.getLimit("parameters/show")
      .subscribe(data => {

        let responseany: any = data;

        console.log(responseany);
        this.logger.trace('DATA', data);

        switch (data.status) {

          default: {
            let resultat: any = data.body;
            let parameters = resultat.parameters;
            this.limit = parameters.delivery_assignment;
            this.loader.close();

            break;
          }
        }
      },
        error => {
          console.log("error");
          console.log(error);

          switch (error.status) {

            default: {
              this.loader.close();

              break;
            }
          }
        });
  }


  updateParamLimit() {
    this.dialog.open(UpdatelimitcmdComponent, {
      //disableClose: true,
      width: '720px'
    }).afterClosed().subscribe((res) => {
      console.log(res);
      if (!res) {
        return;
      }
      this.loader.open();

      let request: any = res;
      let nber: number = request.nbre;

      if (nber >= 0) {

        this.getItemSub = this.parametreserv.updateLimit(nber, "parameter/set_delivery_assignment/")
          .subscribe(data => {

            let responseany: any = data;

            console.log(responseany);
            this.logger.trace('DATA', data);

            switch (data.status) {

              default: {
                this.getItemSub = this.parametreserv.updateLimit(nber, "parameter/set_delivery_threshold/")
                  .subscribe(data => {

                    let responseany: any = data;

                    console.log(responseany);
                    this.logger.trace('DATA', data);

                    switch (data.status) {

                      default: {
                        this.loader.close();
                        this.getAllLimit();
                        this.snackbar.open('Succes', 'Mise a jour effectuée correctement', {
                          duration: 10000
                        });
                        break;
                      }
                    }
                  },
                    error => {
                      console.log("error");
                      console.log(error);

                      switch (error.status) {

                        default: {
                          this.loader.close();
                          this.snackbar.open('Echec', 'Veuillez veuillez verifier vos parametres saisies', {
                            duration: 10000
                          });
                          break;
                        }
                      }
                    });
              }
            }
          },
            error => {
              console.log("error");
              console.log(error);

              switch (error.status) {

                default: {
                  this.loader.close();
                  this.snackbar.open('Echec', 'Veuillez veuillez verifier vos parametres saisies', {
                    duration: 10000
                  });
                  break;
                }
              }
            });

      } else {
        this.snackbar.open('Echec', 'Veuillez saisir un nombre correcte', {
          duration: 10000
        });
      }

    });

  }


  updateParam(value: Param) {
    this.dialog.open(UpdateComponent, {
      //disableClose: true,
      width: '720px',
      data: { title: '', payload: value }
    }).afterClosed().subscribe((res) => {
      console.log(res);
      if (!res) {
        return;
      }

      this.loader.open();

      let request: Param = res;
      value.value = request.value;
      value.parameterdescription=request.parameterdescription;

      /*
      let u = new Agentstorage();
      u = JSON.parse(localStorage.getItem('curentuser'));
 
      if (u != null) {
        ca.carteabonnementagentid = u.agent.agentid;
        ca.carteabonnementmodecreation = "backoffice";
        ca.userpisteaudit = u.agent.agentemail + " " + u.agent.agentfirstname + " " + u.agent.agentlastname;
 
      }
      */



      this.logger.trace("value", value);

      this.parametreserv.post(value).subscribe(data => {
        this.logger.trace(data);
        switch (data.status) {
          case 200: {

            this.getallParameters();
            this.loader.close();
            this.snackbar.open('Succes', 'OK', {
              duration: 10000
            });
            break;
          }
        }
      },
        error => {
          this.logger.trace("erreur retour");
          // this.loading.dismiss('messagesService.loadMessagesOverview');
          switch (error.status) {

            default: {
              this.loader.close();

              // this.FormReg(" erreur de connexion !!! Veuillez ressayer");
              this.logger.error("Check API", error);

              this.snackbar.open('Echec', 'OK', {
                duration: 10000
              });
              break;
            }
          }
        }
      );


    });
  }

 

  public verifierDroit() {
    this.logger.trace('BEGIN');
    // this.logger.trace('Clé', this.configService.get('keylocalstorage'));

    let agentOnline: Agentstorage;
    agentOnline = JSON.parse(localStorage.getItem(this.configService.get('keylocalstorage')));
    // this.logger.trace(agentOnline);
    let rolefontion: Rolefonction[] = agentOnline.rolefonction;
    // this.logger.trace('rolefontion', rolefontion);

    for (const key in rolefontion) {
      if (rolefontion.hasOwnProperty(key)) {
        // this.logger.trace('KEY', key);
        const element = rolefontion[key].fonction.fonctionname;
        this.logger.trace('element', element);

        if (element == this.keyModificationLimit) {
          this.logger.trace('true');
          this.modificationLimit = true;
        }


      }
    }

  }

}
