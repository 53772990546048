import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { NGXLogger } from 'ngx-logger';
import { Carteabonnement } from 'src/app/models/carteabonnement';

@Component({
  selector: 'fury-showcarte',
  templateUrl: './showcarte.component.html',
  styleUrls: ['./showcarte.component.scss']
})
export class ShowcarteComponent implements OnInit {

  carte : Carteabonnement ;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<ShowcarteComponent>
    ,private logger: NGXLogger
  ) { }

  ngOnInit() {
    this.buildItem(this.data.payload);
  }

  buildItem(value : any){
    this.carte=value ;
  }
 

}
