import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'fury-parametrages',
  templateUrl: './parametrages.component.html',
  styleUrls: ['./parametrages.component.scss']
})
export class ParametragesComponent implements OnInit {

  navLinks = [
    //{path: 'roles', label: 'Roles'},
    {path: 'modelivraison', label: 'Modes de Livraison'}  ,
    {path: 'paymentmode', label: 'Modes de Paiement'}  ,
    {path: 'categorie', label: 'Categories des plats'} 
    //{path: 'typeclient', label: 'Types de client'} 

  ]

  constructor() { }

  ngOnInit() {
  }

}
