export class Role {

    roleid: string;
    rolename: string;
    roleisdeleted: boolean;
    roledatecreation: any;
    roledepartement: string;
    userpisteaudit : string ;


    public constructor() {

    }
}