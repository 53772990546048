import { GenericserviceService } from 'src/app/services/genericservice.service';
import { Categorie } from './../../../../models/Categorie';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef, MatSnackBar } from '@angular/material';
import { Component, OnInit, Inject } from '@angular/core';



export interface DialogData {
  categorie: Categorie;
}

@Component({
  selector: 'fury-categorie-modifier',
  templateUrl: './categorie-modifier.component.html',
  styleUrls: ['./categorie-modifier.component.scss']
})
export class CategorieModifierComponent implements OnInit {

  categorieForm: FormGroup;
  categorieId: string;
  categorie: Categorie;

  


  constructor( public dialogRef: MatDialogRef<CategorieModifierComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private categorieService: GenericserviceService ,  private snackbar: MatSnackBar) { }


  onNoClick(): void {
    console.info('---clic on close --- ')
    this.dialogRef.close();
  }

  ngOnInit() {

    this.categorieForm = new FormGroup({ categoriename: new FormControl(), categorieisdeleted: new FormControl() })

    if (this.data) { // edit form
      console.log('checking selected categorie--- : ' + this.data.categorie.categoriename);
      this.categorieForm.reset({ categoriename: this.data.categorie.categoriename, categorieisdeleted: this.data.categorie.categorieisdeleted });
    }


  }


  onSubmit() {

    if (this.categorieForm.valid) {
      console.log('-----------updating categorie ----------------');

      let updatedCategorie: Categorie = new Categorie();
      updatedCategorie.categorieid = this.data.categorie.categorieid;
      updatedCategorie.categoriedatecreation = this.data.categorie.categoriedatecreation;
      updatedCategorie.categoriename = this.categorieForm.controls['categoriename'].value;
      updatedCategorie.categorieisdeleted = this.data.categorie.categorieisdeleted;

      console.log('-----------data to update  ---------------- ' + updatedCategorie.categorieid + ' ' +
        updatedCategorie.categoriename + ' ' + updatedCategorie.categorieisdeleted);


      this.categorieService.update(updatedCategorie).subscribe(data => {

        console.log(data);
        switch (data.status) {
          case 201: {
            // this.loading.dismiss('messagesService.loadMessagesOverview');

            let resp: Categorie;
            resp = data.body;
            console.log("------------------------------------");
            console.log(resp);
            console.log("------------------------------------");

           
           /* Swal.fire({
              position: 'top-end',
              type: 'success',
              title: 'Your work has been saved',
              showConfirmButton: false,
              timer: 1500
            })*/




            this.snackbar.open('Success modification', 'OK', {
              duration: 2000
            });

            break;
          }
        }
      },
        error => {
         
          console.log("erreur retour");
          // this.loading.dismiss('messagesService.loadMessagesOverview');
          switch (error.status) {
            case 409:
              //this.FormReg(" Mot de passe ou login incorrect ");
              this.snackbar.open('Echec modification', 'La categorie existe deja', {
                duration: 2000
              });
              break;
            default: {
              // this.FormReg(" erreur de connexion !!! Veuillez ressayer");
              this.snackbar.open('Echec modification', 'OK', {
                duration: 2000
              });
              break;
            }
          }

        });


      console.log('-----------updating categorie ok ----------------');


      this.dialogRef.close();
    }  else {

     
       // this.validateAllFormFields(this.categorieForm);
        this.snackbar.open('Veuillez saisir toutes les valeurs svp', 'OK', {
          duration: 2000
        });
    }
  }


}
