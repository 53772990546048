import { Component, Inject, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormArray } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef, MatSnackBar } from '@angular/material';
import { NGXLogger } from 'ngx-logger';
import { Subscription } from 'rxjs';
import { CategoriecorpoService } from 'src/app/pages/categoriecorpo/categoriecorpo.service';

@Component({
  selector: 'fury-add-condition',
  templateUrl: './add-condition.component.html',
  styleUrls: ['./add-condition.component.scss']
})
export class AddConditionComponent implements OnInit {


  getItemSub: Subscription;


  public type: any[] = [
    {
      "type": "MENU",
      "typecode": 1
    },
    {
      "type": "PANIER",
      "typecode": 2
    },
    {
      "type": "ARTICLE",
      "typecode": 3
    },
    {
      "type": "POINT DE VENTE",
      "typecode": 4
    }
  ];

  public comparaison: any[] = [
    {
      "comp": "INFERIEUR",
      "compcode": 1
    },
    {
      "comp": "SUPERIEUR",
      "compcode": 2
    },
    {
      "comp": "EGAL",
      "compcode": 3
    }
  ];


  categorie: any[] = [];

  form: FormGroup;


  constructor(@Inject(MAT_DIALOG_DATA) public defaults: any,
    public dialogRef: MatDialogRef<AddConditionComponent>,
    private fb: FormBuilder,
    private snackbar: MatSnackBar,

    private categoriecorposerv: CategoriecorpoService,
    private logger: NGXLogger) {
  }

  ngOnInit() {
    this.form = this.fb.group({
      conditionabtmntlib: ['', Validators.compose([Validators.required])],
      categorie: ['', Validators.compose([Validators.required])],
      abtmnt: this.fb.array([])
    });
    this.getall();
  }


  get abtmnt(): FormArray {
    return this.form.get("abtmnt") as FormArray
  }

  addAbtmnt() {
    this.abtmnt.push(this.newAbtmnt());
  }

  deleteAbtmnt() {
    let arrayAbtmnt = this.form.get("abtmnt") as FormArray;
    let listAbtnt : any[] = arrayAbtmnt.value ;
    console.log(arrayAbtmnt);
    if(listAbtnt!=null && listAbtnt.length>0){
      this.abtmnt.removeAt(this.abtmnt.length-1);
    }
  }

  newAbtmnt(): FormGroup {
    return this.fb.group({
      'type': ["", Validators.required],
      'valeur': [0, Validators.required],
      'comp': ["", Validators.required],
    })
  }


  submit() {

    const benFormArray = this.form.get('abtmnt') as FormArray;

    console.log(benFormArray);
    let items: any[] = [];

    for (let index = 0; index < benFormArray.length; index++) {
      const element = benFormArray.controls[index] as FormGroup;
      let it: any = {};
      if (element.invalid) {
        let montantFormControl = element.controls.montant;
        if (montantFormControl.invalid) {
          this.snackbar.open('Veuillez renseigner correctement tous les montants ', 'OK', {
            duration: 10000
          });
          return;
        }
      }
      console.log(element.value);
      let p: any = {};
      let type: any = element.value.type;
      p.type = type.type;
      p.typecode = type.typecode;

      let comp: any = element.value.comp;
      p.comp = comp.comp;
      p.compcode = comp.compcode;

      p.valeur = element.value.valeur;
      items.push(p);

    }


    if (items == null || items.length == 0) {
      this.snackbar.open('Veuillez ajouter des conditions d execution  ', 'OK', {
        duration: 10000
      });
      return;
    }

    if (items.length > 4) {
      this.snackbar.open('Une condtion ne peut pas contenir plus de 4 enregistrement  ', 'OK', {
        duration: 10000
      });
      return;
    }

    for (let index = 0; index < items.length; index++) {
      const p = items[index] as any;
      let i = items.filter(proj => proj.type === p.type);
      if (i != null && i.length > 1) {
        this.snackbar.open('Vous avez  ' + p.type + ' existe plus d une fois', 'OK', {
          duration: 10000
        });

        return;
      }
    }




    let request: any = this.form.value;
    request.categorieid = request.categorie.categoriecorpoid;
    request.categorie = request.categorie.categoriecorpolibelle;
    request.abtmnt = items;

    this.logger.trace(request);
    this.dialogRef.close(request);
  }

  getall() {
    this.getItemSub = this.categoriecorposerv.getAll('categoriecorporate/getallcategoriecorpo')
      .subscribe(data => {

        let statuscode = data.status;
        this.logger.trace('DATA', data);

        switch (statuscode) {

          case 200: {
            this.categorie = data.body;
            break;
          }



        }
      },
        error => {
          this.logger.error("error");
          this.logger.error(error);

          switch (error.status) {

            default: {

              break;
            }
          }
        });
  }

}
