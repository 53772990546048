import { Subscription } from 'rxjs';
import { PisteauditService } from './pisteaudit.service';
import { NGXLogger } from 'ngx-logger';
import { MatSnackBar } from '@angular/material';
import { ConfigService } from 'ngx-envconfig';
import { Agentstorage } from 'src/app/models/agentstorage';
import { Rolefonction } from 'src/app/models/rolefonction';
import { Pisteaudit } from 'src/app/models/pisteaudit';
import { Component, OnInit } from '@angular/core';

import * as XLSX from 'xlsx';
import * as FileSaver from 'file-saver';


const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
const EXCEL_EXTENSION = '.xlsx';

@Component({
  selector: 'fury-pisteaudit',
  templateUrl: './pisteaudit.component.html',
  styleUrls: ['./pisteaudit.component.scss']
})
export class PisteauditComponent implements OnInit {


  public getItemSub: Subscription;
  public items: Pisteaudit[];
  temp = [];

  // Habilitation
  public afficherAudit: boolean;

  public keyAfficherAudit = 'afficher audit';

  scrollBarHorizontal = (window.innerWidth < 1200);


  constructor(private auditService: PisteauditService, private logger: NGXLogger,
    private snackbar: MatSnackBar, private configService: ConfigService) { }

  ngOnInit() {
    this.getData();
    this.verifierDroit();
  }

  updateFilter(event) {
    const val = event.target.value.toLowerCase();
    var columns = Object.keys(this.temp[0]);
    // Removes last "$$index" from "column"
    columns.splice(columns.length - 1);

    // console.log(columns);
    if (!columns.length)
      return;

    const rows = this.temp.filter(function (d) {
      for (let i = 0; i <= columns.length; i++) {
        let column = columns[i];
        // console.log(d[column]);
        if (d[column] && d[column].toString().toLowerCase().indexOf(val) > -1) {
          return true;
        }
      }
    });

    this.items = rows;
  }

  public verifierDroit() {

    let agentOnline: Agentstorage;
    agentOnline = JSON.parse(localStorage.getItem(this.configService.get('keylocalstorage')));
    // this.logger.trace(agentOnline);
    let rolefontion: Rolefonction[] = agentOnline.rolefonction;
    // this.logger.trace('rolefontion', rolefontion);

    for (const key in rolefontion) {
      if (rolefontion.hasOwnProperty(key)) {
        // this.logger.trace('KEY', key);
        const element = rolefontion[key].fonction.fonctionname;
        this.logger.trace('element', element);

        if (element == this.keyAfficherAudit) {
          this.afficherAudit = true;
        }

      }
    }

  }


  getData() {
    this.getItemSub = this.auditService.getData()
      .subscribe(data => {
        switch (data.status) {
          case 200: {
            this.logger.trace('data', data);
            this.items = this.temp = data.body;

            break;
          }

          case 204: {
            this.snackbar.open('Aucune audit pour l\'instant', 'OK', {
              duration: 10000
            });

            break;
          }

          default: {

            this.logger.trace('data', data);
            this.items = this.temp = data.body;

            break;
          }


        }
      },
        error => {
          this.logger.trace('error');
          this.logger.error(error);

          switch (error.status) {

            default: {
              this.snackbar.open('Un erreur est survenue', 'OK', {
                duration: 10000
              });
              break;
            }
          }
        });
  }




  exportToCsv() {
    let itemscsv: any[] = [];


    if (this.items.length > 0) {
      this.items.forEach(function (element) {
        console.log(element);

        var my_json = {
          "Libele": element.pisteauditlibelle,
          "Type": element.pisteaudittype,
          "Commentaire": element.pisteauditcommentaire,
          "Status": element.pisteauditstatut

          //"Status" : element.statut
        };
        console.log("my_json");
        console.log(my_json);
        itemscsv.push(my_json);
      });
      console.log(itemscsv);

      this.exportAsExcelFile(itemscsv, 'PISTE AUDIT');

    }
  }
  public exportAsExcelFile(json: any[], excelFileName: string): void {
    const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(json);
    const workbook: XLSX.WorkBook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
    const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
    this.saveAsExcelFile(excelBuffer, excelFileName);
  }
  private saveAsExcelFile(buffer: any, fileName: string): void {
    const data: Blob = new Blob([buffer], { type: EXCEL_TYPE });
    FileSaver.saveAs(data, fileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION);
  }




}
