import { Component, OnInit } from '@angular/core';
import { ConfigService } from 'ngx-envconfig';
import { NGXLogger } from 'ngx-logger';
import { Agentstorage } from 'src/app/models/agentstorage';
import { Observable, Subscription } from 'rxjs';
import { Categories } from 'src/app/models/categories';
import { CategoriemenuService } from './categoriemenu.service';
import { MatSnackBar, MatDialog, MatDialogRef } from '@angular/material';
import { ConfirmationService } from 'src/app/confirm/confirmation.service';
import { Rolefonction } from 'src/app/models/rolefonction';
import { PopupcategoriemenuComponent } from './popupcategoriemenu/popupcategoriemenu.component';


import * as XLSX from 'xlsx';
import * as FileSaver from 'file-saver';
const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
const EXCEL_EXTENSION = '.xlsx';


@Component({
  selector: 'fury-categoriemenu',
  templateUrl: './categoriemenu.component.html',
  styleUrls: ['./categoriemenu.component.scss']
})
export class CategoriemenuComponent implements OnInit {

  
  
  getItemSub: Subscription;
  items: Categories[];
  scrollBarHorizontal = (window.innerWidth < 1200);
  temp = [];
  rows = [];
  columns = [];

  // Habilitation
  public afficherCategorie: boolean;
  public creationCategorie: boolean;
  public modificationCategorie: boolean;
  public suppressionCategorie: boolean;

  public keyAfficherCategorie = 'afficher categorie';
  public keyCreationCategorie = 'creation categorie';
  public keyModificationCategorie = 'modification categorie';
  public keySuppressionCategorie = 'suppression categorie';


  constructor(private logger: NGXLogger, private categoriemenuService: CategoriemenuService,
    private snack: MatSnackBar, private confirmService: ConfirmationService,
    private dialog: MatDialog,
    private configService: ConfigService) { }

  ngOnInit() {
    this.verifierDroit();
    this.getallCategorie();
   }

   updateFilter(event) {
    const val = event.target.value.toLowerCase();
    var columns = Object.keys(this.temp[0]);
    // Removes last "$$index" from "column"
    columns.splice(columns.length - 1);

    // console.log(columns);
    if (!columns.length)
      return;

    const rows = this.temp.filter(function (d) {
      for (let i = 0; i <= columns.length; i++) {
        let column = columns[i];
        // console.log(d[column]);
        if (d[column] && d[column].toString().toLowerCase().indexOf(val) > -1) {
          return true;
        }
      }
    });

    this.items = rows;
  }

  public verifierDroit() {
    this.logger.trace('BEGIN');
    // this.logger.trace('Clé', this.configService.get('keylocalstorage'));

    let agentOnline: Agentstorage;
    agentOnline = JSON.parse(localStorage.getItem(this.configService.get('keylocalstorage')));
    // this.logger.trace(agentOnline);
    let rolefontion: Rolefonction[] = agentOnline.rolefonction;
    // this.logger.trace('rolefontion', rolefontion);

    for (const key in rolefontion) {
      if (rolefontion.hasOwnProperty(key)) {
        // this.logger.trace('KEY', key);
        const element = rolefontion[key].fonction.fonctionname;
        this.logger.trace('element', element);

        if (element == this.keyAfficherCategorie) {
          this.logger.trace('true');
          this.afficherCategorie = true;
        }
        if (element == this.keyCreationCategorie) {
          this.logger.trace('true');
          this.creationCategorie = true;
        }
        if (element == this.keyModificationCategorie) {
          this.logger.trace('true');
          this.modificationCategorie = true;
        }
        if (element == this.keySuppressionCategorie) {
          this.logger.trace('true');
          this.suppressionCategorie = true;
        }
      }
    }

  }

  getallCategorie() {
    this.getItemSub = this.categoriemenuService.getAllCategorie()
      .subscribe(data => {

        let statuscode = data.status;
        this.logger.trace('DATA', data);

        switch (statuscode) {

          case 200: {
            this.items = data.body;
            this.temp = data.body;
            break;
          }

          case 204: {
            this.snack.open('Aucune catégorie disponible', 'OK', { duration: 4000 })
            break;
          }

          default: {
            this.snack.open('Une erreur est survenue!', 'OK', { duration: 4000 })

            break;
          }

        }
      },
        error => {
          this.logger.error("error");
          this.logger.error(error);

          switch (error.status) {

            default: {
              this.snack.open('Une erreur est survenue!', 'OK', { duration: 4000 })

              break;
            }
          }
        });
  }

  openPopUp(data: any = {}, isNew?) {
    let title = isNew ? 'Ajouter une catégorie' : 'Mettre à jour une catégorie';
    let dialogRef: MatDialogRef<any> = this.dialog.open(PopupcategoriemenuComponent, {
      width: '720px',
      disableClose: true,
      data: { title: title, payload: data }
    })
    dialogRef.beforeClose()
      .subscribe(res => {
        if(!res) {
          // If user press cancel
          return;
        }
        if (isNew) {
          this.logger.debug('isNew');
          this.getallCategorie();
        } else {
          this.logger.debug('isNotNew');
          this.getallCategorie();
        }
      })
  }

  deleteItem(row) {

    this.confirmService.confirm({message: `Supprimé ${row.categoriename}?`})
    .subscribe(res => {
      if (res) {
        this.categoriemenuService.deleteCategorie(row.categorieid)
          .subscribe(data => {
            let statuscode = data.status;
            switch (statuscode) {
              case 200: {
                // Actualisé les données
                this.getallCategorie();
                this.snack.open('Catégorie Supprimé!', 'OK', { duration: 4000 })
                break;
              }
              case 500: {
                this.snack.open('Internal server error!', 'OK', { duration: 4000 })
                break;
              }
              default: {
                this.snack.open('Une erreur est survenue!', 'OK', { duration: 4000 })
                break;
              }
            }

          },
          error => {
            let statuscode = error.status;
            switch (statuscode) {
              case 404: {
                this.snack.open('Not found!', 'OK', { duration: 4000 })
                break;
              }
              case 500: {
                this.snack.open('Internal server error!', 'OK', { duration: 4000 })
                break;
              }
              default: {
                this.snack.open('Une erreur est survenue!', 'OK', { duration: 4000 })
                break;
              }
            }

          });
      }


    })


  }


  exportToXls() {
    let itemscsv: any[] = [];


    if (this.items.length > 0) {
      this.items.forEach(function (element) {
        console.log(element);


        var my_json = {
          "Date creation": element.categoriedatecreation,
          "Categorie": element.categoriename,
          "Code": element.categoriecode
        
        };
        console.log("my_json");
        console.log(my_json);
        itemscsv.push(my_json);
      });
      console.log(itemscsv);

      this.exportAsExcelFile(itemscsv, 'Historique Plat categorie');

    }
  }
  public exportAsExcelFile(json: any[], excelFileName: string): void {
    const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(json);
    const workbook: XLSX.WorkBook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
    const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
    this.saveAsExcelFile(excelBuffer, excelFileName);
  }
  private saveAsExcelFile(buffer: any, fileName: string): void {
    const data: Blob = new Blob([buffer], { type: EXCEL_TYPE });
    FileSaver.saveAs(data, fileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION);
  }



}
