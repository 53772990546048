import { DialogDataAgent } from './../gestionagent.component';
import { GenericserviceService } from './../../../services/genericservice.service';
import { AgentDto } from './../../../models/AgentDto';
import { MatDialogRef, MatSnackBar, MAT_DIALOG_DATA } from '@angular/material';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { Component, OnInit, Inject } from '@angular/core';
import { RolesService } from 'src/app/services/roles.service';
import { Role } from 'src/app/models/Role';
import { Agent } from 'src/app/models/Agent';

@Component({
  selector: 'fury-gestionagentform',
  templateUrl: './gestionagentform.component.html',
  styleUrls: ['./gestionagentform.component.scss'],
  providers:[GenericserviceService , RolesService]

})
export class GestionagentformComponent implements OnInit {

  form: FormGroup;
  orders = [];

  public roles: Role[];

  selectedRole: string;

  constructor(public dialogRef: MatDialogRef<GestionagentformComponent> , private formBuilder: FormBuilder,
     private roleService: RolesService, private agentService: GenericserviceService ,
     @Inject(MAT_DIALOG_DATA) public data: DialogDataAgent, private snackbar: MatSnackBar) {
  

  }
  /*
  
  of(this.getOrders()).subscribe(orders => {
        this.orders = orders;
        this.form.controls.orders.patchValue(this.orders[0].id);
      });
  */
  ngOnInit() {

    this.agentService.type ='agent';



    this.findAllRoles();



    this.form = new FormGroup({
      agentrole: new FormControl('', Validators.required),
      agentlastname: new FormControl('', Validators.required),
      agentfirstname: new FormControl('', Validators.required),
      agentemail: new FormControl('', Validators.required),
      agentpassword: new FormControl('', Validators.required),
      agenttelephone: new FormControl('', Validators.required)
    });

    this.form = this.formBuilder.group({
      agentrole: ['', Validators.required],
      agentlastname : ['', Validators.required],
      agentfirstname: ['', Validators.required],
      agentemail: ['', Validators.required],
      agentpassword: ['', Validators.required],
      agenttelephone : ['', Validators.required],
  })

  //      agenttelephone: new FormControl('', Validators.required),


  if (this.data.agent) { // edit form
    this.form = new FormGroup({
      agentrole: new FormControl({value: this.data.agent.role.rolename}) ,
      agentlastname: new FormControl( this.data.agent.agentlastname , Validators.required),
      agentfirstname: new FormControl( this.data.agent.agentfirstname ,Validators.required),
      agentemail: new FormControl({value: this.data.agent.agentemail, disabled: true}) ,
      agentpassword:  new FormControl( this.data.agent.agentpassword,Validators.required),
      agenttelephone: new FormControl({value: this.data.agent.agenttelephone, disabled: true}) 
    });
  }




  }

  findAllRoles() {

    this.roleService.findAll().subscribe(data => {
      console.log(data);
      switch (data.status) {
        case 200: {
          // this.loading.dismiss('messagesService.loadMessagesOverview');

          let resp: Role[];
          resp = data.body;
          this.orders = resp;
          this.roles = resp;
          console.log("------------------------------------");
          console.log(resp);
          console.log("------------------------------------");


          if (resp != null) {
            //this.resetFunction();


            console.log("------------- roles founded ------------------");

          } else {

            // this.FormReg(" Mot de passe ou login incorrect ");
          }
          break;
        }
      }
    },
      error => {
        console.log("erreur retour");
        // this.loading.dismiss('messagesService.loadMessagesOverview');
        switch (error.status) {
          case 401:
            //this.FormReg(" Mot de passe ou login incorrect ");
            break;
          default: {
            // this.FormReg(" erreur de connexion !!! Veuillez ressayer");
            break;
          }
        }
      }
    );

  }

  onNoClick(): void {
    console.info('---clic on close dialog --- ')
    this.dialogRef.close();
  }


  onSubmit() {

    if (this.form.valid) {
      console.log('-----------creating role ----------------');

      let agentDto: AgentDto = new AgentDto();
      agentDto.agentlastname = this.form.controls['agentlastname'].value;
      agentDto.agentfirstname = this.form.controls['agentfirstname'].value;
      agentDto.agentemail = this.form.controls['agentemail'].value;
      agentDto.agentpassword = this.form.controls['agentpassword'].value;
      agentDto.agenttelephone = this.form.controls['agenttelephone'].value;
      agentDto.roleName = this.form.controls['agentrole'].value;


      console.log('-----------data to create  ---------------- ' +  JSON.stringify(agentDto) );


      this.agentService.saveObject(agentDto).subscribe(data => {

        console.log(data);
        switch (data.status) {
          case 201: {
            // this.loading.dismiss('messagesService.loadMessagesOverview');

            let resp: Role;
            resp = data.body;
            console.log("------------------------------------");
            console.log(resp);
            console.log("------------------------------------");

           
           /* Swal.fire({
              position: 'top-end',
              type: 'success',
              title: 'Your work has been saved',
              showConfirmButton: false,
              timer: 1500
            })*/




            this.snackbar.open('Success Creation', 'OK', {
              duration: 2000
            });

            break;
          }
        }
      },
        error => {
         
          console.log("erreur retour");
          // this.loading.dismiss('messagesService.loadMessagesOverview');
          switch (error.status) {
            case 409:
              //this.FormReg(" Mot de passe ou login incorrect ");
              this.snackbar.open('Echec creation', 'l\'agent existe deja', {
                duration: 2000
              });
              break;
            default: {
              // this.FormReg(" erreur de connexion !!! Veuillez ressayer");
              this.snackbar.open('Echec creation', 'OK', {
                duration: 2000
              });
              break;
            }
          }

        });


      console.log('-----------agent creation ok ----------------');


      this.dialogRef.close();
    }  else {

     
       // this.validateAllFormFields(this.roleForm);
        this.snackbar.open('Veuillez saisir toutes les valeurs svp', 'OK', {
          duration: 2000
        });
    }


   

  }


}
