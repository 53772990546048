import { Rolefonction } from './rolefonction';
import { Agent } from './Agent';

export class Agentstorage {
    agent: Agent;
    token: string;
    rolefonction: Rolefonction[];
}

export interface DemandeDto {
        demandeabnagent: string;
        demandeabndcvrt: boolean;
        demandeabnetat: number;
        demandeabnexp: string;
        demandeabnid: string;
        demandeabnidclient: string;
        demandeabnmode: string;
        demandeabnmotif: string;
        demandeabnprecpte: boolean;
        demandeabnstatus: number;
}