import { Component, OnInit } from '@angular/core';
import { CarteabonnementService } from './carteabonnement.service';
import { NGXLogger } from 'ngx-logger';
import { MatSnackBar, MatDialog } from '@angular/material';
import { DatePipe } from '@angular/common';
import { ConfigService } from 'ngx-envconfig';
import { ApploaderService } from 'src/app/services/apploader/apploader.service';
import { FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { Carteabonnement } from 'src/app/models/carteabonnement';
import { Cartedto } from 'src/app/models/cartedto';

import { CarteabonnementModule } from './carteabonnement.module';
import { AddcarteComponent } from './addcarte/addcarte.component';
import { Agentstorage } from 'src/app/models/agentstorage';
import { ActivecaComponent } from './activeca/activeca.component';
import { LierclientcaComponent } from './lierclientca/lierclientca.component';
import { ShowcarteComponent } from './showcarte/showcarte.component';


import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
const EXCEL_EXTENSION = '.xlsx';
import * as jsPDF from 'jspdf';
import 'jspdf-autotable';
import { UserOptions } from 'jspdf-autotable';
import { Rolefonction } from 'src/app/models/rolefonction';
import { Cardstatut } from 'src/app/models/cardstatut';
import { ConfirmationService } from 'src/app/confirm/confirmation.service';
import { DecouvertService } from '../decouvert/decouvert.service';


interface jsPDFWithPlugin extends jsPDF {
  autoTable: (options: UserOptions) => jsPDF;
}




@Component({
  selector: 'fury-carteabonnement',
  templateUrl: './carteabonnement.component.html',
  styleUrls: ['./carteabonnement.component.scss']
})
export class CarteabonnementComponent implements OnInit {

  public items: Carteabonnement[];
  temp = [];

  scrollBarHorizontal = (window.innerWidth < 1200);

  // Habilitation
  public liaisonCarte: boolean;
  public activationCarte: boolean;

  public keyLiaisonCarte = 'liaison carte';
  public keyActivationCarte = 'activation carte';


  constructor(private caserv: CarteabonnementService, private logger: NGXLogger, private fb: FormBuilder,  private snack: MatSnackBar,
    private decouvertService: DecouvertService,
    private snackbar: MatSnackBar, private configService: ConfigService, private confirmService: ConfirmationService,
    private datePipe: DatePipe, private dialog: MatDialog, private loader: ApploaderService, private router: Router) { }

  ngOnInit() {
    this.verifierDroit();
    this.getAllCarte();
  }


  updateFilter(event) {
    const val = event.target.value.toLowerCase();
    var columns = Object.keys(this.temp[0]);
    // Removes last "$$index" from "column"
    columns.splice(columns.length - 1);

    // console.log(columns);
    if (!columns.length)
      return;

      
    const rows = this.temp.filter(function (d) {
      console.log(d)
      
      let c : Carteabonnement = d ;
      console.log(c.clientemail);
      
      if((c.clientemail && c.clientemail.toLowerCase().includes(val) )  || c.carteabonnementcodereference.toLowerCase().includes(val) || (c.carteabonnementstatut && c.carteabonnementstatut.toLowerCase().includes(val) ) ) {
        return true;
      }
      
      /*
      for (let i = 0; i <= columns.length; i++) {
        let column = columns[i];
        //console.log(column) 
        // console.log(d[column]);
        if (d[column] && d[column].toString().toLowerCase().indexOf(val) > -1) {
          return true;
        }
      }
      */
    });
    
   

    this.items = rows;
  }


  search(event): void {
    console.log("VALEUR SAISIE : ")
    const value = event.target.value.toLowerCase();
      const row = this.items.filter((val) => 
      val.carteabonnementcodereference.toLowerCase().includes(value) 

      );
      this.items = row ;
  }


  public verifierDroit() {
    this.logger.trace('BEGIN');
    // this.logger.trace('Clé', this.configService.get('keylocalstorage'));

    let agentOnline: Agentstorage;
    agentOnline = JSON.parse(localStorage.getItem(this.configService.get('keylocalstorage')));
    // this.logger.trace(agentOnline);
    let rolefontion: Rolefonction[] = agentOnline.rolefonction;
    // this.logger.trace('rolefontion', rolefontion);

    for (const key in rolefontion) {
      if (rolefontion.hasOwnProperty(key)) {
        // this.logger.trace('KEY', key);
        const element = rolefontion[key].fonction.fonctionname;
        this.logger.trace('element', element);

        if (element == this.keyActivationCarte) {
          this.logger.trace('true');
          this.activationCarte = true;
        }

        if (element == this.keyLiaisonCarte) {
          this.logger.trace('true');
          this.liaisonCarte = true;
        }

      }
    }

  }


  goToRoute(value: any) {
    this.router.navigate([value]);
  }


  getAllCarte() {
    this.caserv.getAllCarteabonnement()
      .subscribe(data => {
        switch (data.status) {
          case 200: {
            this.logger.trace('data', data);

            let items: Carteabonnement[]  = data.body;
            //this.items = this.temp = data.body;

            /*
            let valuefinale = "/yolinbo/statforclient/stat/" + this.user.clientid;
            this.router.navigate([valuefinale]);
            */

            let carteabonnements: Carteabonnement[] = [];

            items.forEach(function (value) {
              let ca: Carteabonnement = value;
              if (ca.client) {
                ca.clienttelephone = ca.client.clienttelephone;
                ca.clientfirstname = ca.client.clientfirstname;
                ca.clientemail = ca.client.clientemail;
              }else{
                ca.clientemail = "";
                ca.clienttelephone = "";
                ca.clientfirstname = "";
              }

              carteabonnements.push(ca);
            });
            this.items = this.temp = carteabonnements;
            this.logger.trace('items', this.items);

            break;
          }

          case 204: {
            break;
          }

          default: {
            /*
                        this.logger.trace('data', data);
                        this.user = this.temp = data.body;
            */
            break;
          }


        }
      },
        error => {
          this.logger.trace('error');
          this.logger.error(error);

          switch (error.status) {

            default: {
              /*
              this.snackbar.open('Un erreur est survenue', 'OK', {
                duration: 10000
              });
              */
              break;
            }
          }
        });
  }


  


  addCarte() {
    this.dialog.open(AddcarteComponent, {
      //disableClose: true,
      width: '720px',
      data: { title: '', payload: '' }
    }).afterClosed().subscribe((res) => {
      console.log(res);
      if (!res) {
        return;
      }

      this.loader.open();

      let ca: Cartedto = res;

      let u = new Agentstorage();
      u = JSON.parse(localStorage.getItem('curentuser'));

      if (u != null) {
        
        ca.carteabonnementagentid = u.agent.agentid;
        ca.carteabonnementmodecreation = "backoffice";
        ca.userpisteaudit = u.agent.agentemail + " " + u.agent.agentfirstname + " " + u.agent.agentlastname;

      }



      this.logger.trace("carte", ca);

      this.caserv.createCarte(ca).subscribe(data => {
        this.logger.trace(data);
        switch (data.status) {
          case 201: {

            this.getAllCarte();
            this.loader.close();
            this.snackbar.open('Succes', 'OK', {
              duration: 10000
            });
            break;
          }
        }
      },
        error => {
          this.logger.trace("erreur retour");
          // this.loading.dismiss('messagesService.loadMessagesOverview');
          switch (error.status) {

            default: {
              this.loader.close();

              // this.FormReg(" erreur de connexion !!! Veuillez ressayer");
              this.logger.error("Check API", error);

              this.snackbar.open('Echec', 'OK', {
                duration: 10000
              });
              break;
            }
          }
        }
      );


    });
  }


  enabledCarte(value: Carteabonnement) {
    this.dialog.open(ActivecaComponent, {
      //disableClose: true,
      width: '720px',
      data: { title: '', payload: value }
    }).afterClosed().subscribe((res) => {
      console.log(res);
      if (!res) {
        return;
      }

      this.loader.open();

      let ca: Cartedto = res;

      let u = new Agentstorage();
      u = JSON.parse(localStorage.getItem('curentuser'));

      if (u != null) {
        ca.agentid = u.agent.agentid;
        ca.userpisteaudit = u.agent.agentemail + " " + u.agent.agentfirstname + " " + u.agent.agentlastname;
      }
      ca.carteabonnementreference = value.carteabonnementcodereference;

      this.logger.trace("carte", ca);

      this.caserv.ActivateCarte(ca).subscribe(data => {
        this.logger.trace(data);
        switch (data.status) {
          case 201: {

            this.getAllCarte();
            this.loader.close();
            this.snackbar.open('Succes', 'OK', {
              duration: 10000
            });
            break;
          }



          case 204: {

            this.loader.close();
            this.snackbar.open('Echec ! carte non trouvé', 'OK', {
              duration: 10000
            });
            break;
          }

        }
      },
        error => {
          this.logger.trace("erreur retour");
          // this.loading.dismiss('messagesService.loadMessagesOverview');
          switch (error.status) {

            case 401: {
              this.loader.close();
              this.snackbar.open('Echec ! Agent non trouvé', 'OK', {
                duration: 10000
              });
              break;
            }

            case 406: {
              this.loader.close();
              this.snackbar.open('Echec ! Carte deja activée', 'OK', {
                duration: 10000
              });
              break;
            }

            default: {
              this.loader.close();

              // this.FormReg(" erreur de connexion !!! Veuillez ressayer");
              this.logger.error("Check API", error);

              this.snackbar.open('Echec', 'OK', {
                duration: 10000
              });
              break;
            }
          }
        }
      );


    });
  }




  linkCarte(value: Carteabonnement) {
    this.dialog.open(LierclientcaComponent, {
      //disableClose: true,
      width: '720px',
      data: { title: '', payload: value }
    }).afterClosed().subscribe((res) => {
      console.log(res);
      if (!res) {
        return;
      }

      this.loader.open();

      let ca: Cartedto = res;

      let u = new Agentstorage();
      u = JSON.parse(localStorage.getItem('curentuser'));

      if (u != null) {
        ca.agentid = u.agent.agentid;
        ca.userpisteaudit = u.agent.agentemail + " " + u.agent.agentfirstname + " " + u.agent.agentlastname;
      }
      ca.carteabonnementcodereference = value.carteabonnementcodereference;

      this.logger.trace("carte", ca);

      this.caserv.LierCarte(ca).subscribe(data => {
        this.logger.trace("***************************");

        this.logger.trace(data);

        this.logger.trace("***************************");

        switch (data.status) {
          case 201: {
            this.loader.close();
            this.getAllCarte();
            this.snackbar.open('Succes', 'OK', {
              duration: 10000
            });
            break;
          }

          case 200: {
            this.loader.close();
            this.getAllCarte();
            this.snackbar.open('Carte deja lié ou client deja occupé Veuillez verifier vos parametres', 'OK', {
              duration: 10000
            });
            break;
          }

          case 204: {

            this.loader.close();
            this.snackbar.open('Echec ! carte non trouvé', 'OK', {
              duration: 10000
            });
            break;
          }

        }
      },
        error => {
          this.logger.trace("erreur retour");
          // this.loading.dismiss('messagesService.loadMessagesOverview');
          switch (error.status) {


            case 406: {
              this.loader.close();
              this.snackbar.open('Echec ! Carte bloquée / inactve', 'OK', {
                duration: 10000
              });
              break;
            }

            default: {
              this.loader.close();

              // this.FormReg(" erreur de connexion !!! Veuillez ressayer");
              this.logger.error("Check API", error);

              this.snackbar.open('Echec', 'OK', {
                duration: 10000
              });
              break;
            }
          }
        }
      );


    });
  }



  unabledlinkCarte(row : Carteabonnement) {
    this.confirmService.confirm({ message: `Delier la carte ${row.carteabonnementcodereference} ?` })
      .subscribe(res => {
        if (res) {

          let request : Cartedto = new Cartedto() ;
          request.typedemande=row.carteabonnementcodereference ;
          request.carteabonnementcodereference=row.carteabonnementcodereference ;
          request.clientid=row.client.clientid ;
          this.decouvertService.post(request, "carte/deliercarte")
            .subscribe(data => {
              let statuscode = data.status;
              switch (statuscode) {
                case 201: {
                  // Actualisé les données
                  this.getAllCarte();
                  this.snack.open('Carte deliée!', 'OK', { duration: 4000 })
                  break;
                }
             
                default: {
                  this.snack.open('Une erreur est survenue!', 'OK', { duration: 4000 })
                  break;
                }
              }

            },
              error => {
                let statuscode = error.status;
                switch (statuscode) {
                
                  default: {
                    this.logger.error(error);
                    this.snack.open('Une erreur est survenue!', 'OK', { duration: 4000 })
                    break;
                  }
                }

              });
        }

      })


  }

  showcarte(value: Carteabonnement) {
    this.dialog.open(ShowcarteComponent, {
      //disableClose: true,
      width: '720px',
      data: { title: '', payload: value }
    }).afterClosed().subscribe((res) => {
      console.log(res);
      if (!res) {
        return;
      }
    });
  }


  public exportXls() {

    let itemscsv: any[] = [];

    this.items.forEach(function (element) {
      console.log(element);
      if (element.carteabonnementdatecreation.length > 17) {
        element.carteabonnementdatecreation = element.carteabonnementdatecreation.substr(0, 16);
      }

      if (element.carteabonnementdateactivation && (element.carteabonnementdateactivation.length > 17)) {
        element.carteabonnementdateactivation = element.carteabonnementdateactivation.substr(0, 16);
      }

      var my_json = {
        "Date de creation": element.carteabonnementdatecreation,
        "Date activation": element.carteabonnementdateactivation,
        "Email Client": element.clientemail,
        "Carte physique": element.carteabonnementcodereference,
        "Carte virtuelle": element.carteabonnementvirtuelle,
        "Mode creation": element.carteabonnementmodecreation,
        "Email Agent": element.carteabonnementagentemail,
        "Telphone acheteur": element.carteabonnementphonebuyer,
        "Solde initial": element.carteabonnementsoldeinitial,
        "Solde actuel": element.carteabonnementsoldeactuel,
        "Solde avant": element.carteabonnementsoldeavant,
        "Status": element.carteabonnementstatut
      };
      console.log("my_json");
      console.log(my_json);
      itemscsv.push(my_json);
    });
    console.log(itemscsv);

    this.exportAsExcelFile(itemscsv, 'Carte abonnement');
  }


  public exportAsExcelFile(json: any[], excelFileName: string): void {
    const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(json);
    const workbook: XLSX.WorkBook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
    const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
    this.saveAsExcelFile(excelBuffer, excelFileName);
  }
  private saveAsExcelFile(buffer: any, fileName: string): void {
    const data: Blob = new Blob([buffer], { type: EXCEL_TYPE });
    FileSaver.saveAs(data, fileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION);
  }





  exportToPdf() {


    let itemscsv: any[] = [];
    let itemslocal: any[] = this.items;
    if (this.items.length > 0) {
      this.items.forEach(function (element) {
        console.log(element);
        if (element.carteabonnementdatecreation.length > 17) {
          element.carteabonnementdatecreation = element.carteabonnementdatecreation.substr(0, 16);
        }

        if (element.carteabonnementdateactivation && (element.carteabonnementdateactivation.length > 17)) {
          element.carteabonnementdateactivation = element.carteabonnementdateactivation.substr(0, 16);
        }

        var my_json = {
          "Date de creation": element.carteabonnementdatecreation,
          "Date activation": element.carteabonnementdateactivation,
          "Email Client": element.clientemail,
          "Code carte": element.carteabonnementcodereference,
          "Carte virtuelle": element.carteabonnementvirtuelle,
          "Mode creation": element.carteabonnementmodecreation,
          "Email Agent": element.carteabonnementagentemail,
          "Telphone acheteur": element.carteabonnementphonebuyer,
          "Solde initial": element.carteabonnementsoldeinitial,
          "Solde actuel": element.carteabonnementsoldeactuel,
          "Solde avant": element.carteabonnementsoldeavant,
          "Status": element.carteabonnementstatut
        };

        console.log("my_json");
        console.log(my_json);
        itemscsv.push(my_json);
      });

    }

    console.log(itemscsv);
    var columns = [
      { title: "Carte physique", dataKey: "Code carte" },
      { title: "Carte virtuelle", dataKey: "Carte virtuelle" },
      { title: "Date de creation", dataKey: "Date de creation" },
      { title: "Date activation", dataKey: "Date activation" },
      { title: "Email Client", dataKey: "Email Client" },
      { title: "Mode creation", dataKey: "Mode creation" },
      { title: "Email Agent", dataKey: "Email Agent" },
      { title: "Telphone acheteur", dataKey: "Telphone acheteur" },
      { title: "Solde initial", dataKey: "Solde initial" },
      { title: "Solde actuel", dataKey: "Solde actuel" },
      { title: "Solde avant", dataKey: "Solde avant" },
      { title: "Status", dataKey: "Status" }
    ];
    var rows = itemscsv;

    const doc = new jsPDF('portrait', 'px', 'a4') as jsPDFWithPlugin;
    doc.autoTable({
      body: rows,
      columns: columns,
      didParseCell: function (data) {
        if (data.column.raw.displayProperty) {
          var prop = data.column.raw.displayProperty;
          var text = data.cell.raw[prop];
          if (text && text.length > 0) {
            data.cell.text = text;
          }
        }
      }
    });
    doc.save('carte-abonnement.pdf');
  }




  printCarte(value: Carteabonnement) {

    this.confirmService.confirm({ message: `Voulez vous confirmer l'impression de la carte ${value.carteabonnementcodereference}?` })
      .subscribe(res => {

        /*
      this.dialog.open(LierclientcaComponent, {
        //disableClose: true,
        width: '720px',
        data: { title: '', payload: value }
      }).afterClosed().subscribe((res) => {
        */
        console.log(res);
        if (!res) {
          return;
        }

        this.loader.open();

        let ca: Cardstatut = new Cardstatut();

        let u = new Agentstorage();
        u = JSON.parse(localStorage.getItem('curentuser'));

        if (u != null) {
          ca.user = u.agent.agentemail + " " + u.agent.agentfirstname + " " + u.agent.agentlastname;
        }
        ca.code = value.carteabonnementcodereference;

        this.logger.trace("carte", ca);

        this.caserv.printCard(ca).subscribe(data => {
          this.logger.trace("***************************");

          this.logger.trace(data);

          this.logger.trace("***************************");

          switch (data.status) {
            case 200: {
              this.loader.close();
              this.getAllCarte();
              this.snackbar.open('Succes', 'OK', {
                duration: 10000
              });
              break;
            }

            case 204: {

              this.loader.close();
              this.snackbar.open('Echec ! carte non trouvé', 'OK', {
                duration: 10000
              });
              break;
            }

          }
        },
          error => {
            this.logger.trace("erreur retour");
            // this.loading.dismiss('messagesService.loadMessagesOverview');
            switch (error.status) {


              case 423: {
                this.loader.close();
                this.snackbar.open('Echec ! Carte bloquée / inactve', 'OK', {
                  duration: 10000
                });
                break;
              }

              default: {
                this.loader.close();

                // this.FormReg(" erreur de connexion !!! Veuillez ressayer");
                this.logger.error("Check API", error);

                this.snackbar.open('Echec', 'OK', {
                  duration: 10000
                });
                break;
              }
            }
          }
        );


      });
  }


  foundCarte(value: Carteabonnement, action: string, message: string) {

    message = message + " " + value.carteabonnementcodereference + " ?";

    this.confirmService.confirm({ message: message })
      .subscribe(res => {

        /*
    this.dialog.open(LierclientcaComponent, {
      //disableClose: true,
      width: '720px',
      data: { title: '', payload: value }
    }).afterClosed().subscribe((res) => {
      console.log(res);
      */
        if (!res) {
          return;
        }

        this.loader.open();

        let ca: Cardstatut = new Cardstatut();

        let u = new Agentstorage();
        u = JSON.parse(localStorage.getItem('curentuser'));

        if (u != null) {
          ca.user = u.agent.agentemail + " " + u.agent.agentfirstname + " " + u.agent.agentlastname;
        }
        ca.code = value.carteabonnementcodereference;
        ca.action = action;

        this.logger.trace("carte", ca);

        this.caserv.foundCard(ca).subscribe(data => {
          this.logger.trace("***************************");

          this.logger.trace(data);

          this.logger.trace("***************************");

          switch (data.status) {
            case 200: {
              this.loader.close();
              this.getAllCarte();
              this.snackbar.open('Succes', 'OK', {
                duration: 10000
              });
              break;
            }

            case 204: {

              this.loader.close();
              this.snackbar.open('Echec ! carte non trouvé', 'OK', {
                duration: 10000
              });
              break;
            }

          }
        },
          error => {
            this.logger.trace("erreur retour");
            // this.loading.dismiss('messagesService.loadMessagesOverview');
            switch (error.status) {


              case 423: {
                this.loader.close();
                this.snackbar.open('Echec ! Carte bloquée / inactve', 'OK', {
                  duration: 10000
                });
                break;
              }

              default: {
                this.loader.close();

                // this.FormReg(" erreur de connexion !!! Veuillez ressayer");
                this.logger.error("Check API", error);

                this.snackbar.open('Echec', 'OK', {
                  duration: 10000
                });
                break;
              }
            }
          }
        );


      });
  }






}
