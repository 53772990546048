import { Observable } from 'rxjs/Observable';
import { HttpClient, HttpResponse, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Paymentmode } from '../models/Paymentmode';
import { NGXLogger } from 'ngx-logger';
import { ConfigService } from 'ngx-envconfig';
import { Agentstorage } from '../models/agentstorage';

@Injectable({
  providedIn: 'root'
})
export class PaymentmodeService {


  // http://crossroadtest.net:8088/yolin/session/login/root
  //http://crossroadtest.net:8088/parameter/root http://crossroadtest.net:8088/parameter/paymentMode
  //private apiUrl = 'http://crossroadtest.net:8089/parameter/paymentmode';
  private apiUrl = 'http://crossroadtest.net:8088/parameter/paymentmode';

  authorization: string = '';


  constructor(private http: HttpClient,
    private logger: NGXLogger,
    private configService: ConfigService
  ) {
    let u = new Agentstorage();
    u = JSON.parse(localStorage.getItem('curentuser'));

    if (u != null) {
      this.authorization = u.token;
    }
  }



  save(paymentmodename: string, paymentmodecode: string): Observable<HttpResponse<Paymentmode>> {
    console.info('--- creating paymentMode --- ' + JSON.stringify({ paymentmodename, paymentmodecode }));
    return this.http.post<Paymentmode>(this.apiUrl, JSON.stringify({ paymentmodename, paymentmodecode }), {
      observe: 'response',
      headers: new HttpHeaders({ 'Access-Control-Allow-Origin': '*' })
        .set("content-Type", "application/json")
        .set('Authorization', this.authorization)


    });
  }


  update(paymentMode: Paymentmode): Observable<HttpResponse<Paymentmode>> {
    console.info('----- update paymentMode --- ' + JSON.stringify(paymentMode));
    return this.http.put<Paymentmode>(this.apiUrl, paymentMode, {
      observe: 'response',
      headers: new HttpHeaders({ 'Access-Control-Allow-Origin': '*' })
        .set("content-Type", "application/json")
        .set('Authorization', this.authorization)

    });
  }


  delete(id: string): Observable<HttpResponse<any>> {
    console.info('----- delete paymentMode ----- ' + JSON.stringify({ id }));
    return this.http.delete<any>(this.apiUrl + '/' + id, {
      observe: 'response',
      headers: new HttpHeaders({ 'Access-Control-Allow-Origin': '*' })
        .set("content-Type", "application/json")
        .set('Authorization', this.authorization)

    });

    /* return this.http.delete<any>('url', {
       headers: new HttpHeaders({ 'Content-Type': 'application/json' })
   });
   return this.http.delete(url, {
     headers: new HttpHeaders({ 'Content-Type': 'application/json' })
   })*/


  }


  findAll(): Observable<HttpResponse<any>> {
    console.info('--- getting all paymentModes------ ');

    return this.http.get<Paymentmode[]>(this.apiUrl, {
      observe: 'response',
      headers: new HttpHeaders({ 'Access-Control-Allow-Origin': '*' })
        .set("content-Type", "application/json")

    });
  }

}
