import { Component, OnInit, Inject } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { AddcarteComponent } from '../addcarte/addcarte.component';

@Component({
  selector: 'fury-activeca',
  templateUrl: './activeca.component.html',
  styleUrls: ['./activeca.component.scss']
})
export class ActivecaComponent implements OnInit {



  enabledForm: FormGroup;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<AddcarteComponent>) {
  }
  ngOnInit() {
    this.enabledForm = new FormGroup({
      telephone: new FormControl('', [Validators.required])
    });
  }

  buildItemForm(item) {
    //this.value = item;

  }


  submit() {

    this.dialogRef.close(this.enabledForm.value);
  }



}
