import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef, MatSnackBar } from '@angular/material';
import { NGXLogger } from 'ngx-logger';
import { ConfirmationService } from 'src/app/confirm/confirmation.service';
import { Typecarte } from 'src/app/models/heurecommande';

@Component({
  selector: 'fury-crud',
  templateUrl: './crud.component.html',
  styleUrls: ['./crud.component.scss']
})
export class CrudComponent implements OnInit {

  public itemForm: FormGroup;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<CrudComponent>,
    private fb: FormBuilder,
    private snack: MatSnackBar,
    private confirmService: ConfirmationService,
    private logger: NGXLogger) { }

  ngOnInit() {
    this.buildItemForm(this.data.payload);
  }

  buildItemForm(item) {
    let typecarte : Typecarte = item ;
    this.logger.info(typecarte);
    this.itemForm = this.fb.group({
      typecarteid: [typecarte.typecarteid || ''],
      typecartelibelle: [typecarte.typecartelibelle || '', [Validators.required]],
      typecartedescription: [typecarte.typecartedescription || ''],
      typecartecode: [typecarte.typecartecode || '']

    });
    this.logger.info(this.itemForm.value);
  }



  submit() {
    // Verifier si l'id est null (ajout)
    this.logger.debug('Form value', this.itemForm.value);
    let res: Typecarte = this.itemForm.value;
    this.dialogRef.close(res);

  }

}
