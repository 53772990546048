import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpHeaders, HttpEvent, HttpRequest } from '@angular/common/http';
import { NGXLogger } from 'ngx-logger';
import { ConfigService } from 'ngx-envconfig';
import { Agentstorage } from 'src/app/models/agentstorage';
import { Foods } from 'src/app/models/foods';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class MenuService {

  // private crudFood = 'http://81.95.153.117:8088/parameter/food';

  // private getFoodById = 'http://81.95.153.117:8088/test/parameter/food/findbyid/';

  // private downloadfoodpicture = 'http://81.95.153.117:8088/test/parameter/food/downloadfoodpicture/';

  // private uploadfoodpicture = 'http://81.95.153.117:8088/parameter/food/uploadfoodpicture';

  // private getallcategories = 'http://81.95.153.117:8088/parameter/categorie';

  private crudFood = this.configService.get('ipMS') + 'parameter/food';

  private getFoodById = this.configService.get('ipMS') + 'test/parameter/food/findbyid/';

  private downloadfoodpicture = this.configService.get('ipMS') + 'test/parameter/food/downloadfoodpicture/';

  private uploadfoodpicture = this.configService.get('ipMS') + 'parameter/food/uploadfoodpicture';

  private getallcategories = this.configService.get('ipMS') + 'parameter/categorie';

  private getall = this.configService.get('ipMS') ;

  authorization: string = " ";



  constructor(private http: HttpClient, private logger: NGXLogger, private configService: ConfigService) {
    let u = new Agentstorage();
    u = JSON.parse(localStorage.getItem("curentuser"));

    if (u != null) {
      this.authorization = u.token;
      console.log("************************************************ ");
      console.log(this.authorization);
    }
  }

  public updateFood(value: Foods): Observable<HttpResponse<any>> {
    console.log("url : " + this.crudFood);
    this.logger.trace("BODY", value);

    return this.http.put(this.crudFood, JSON.stringify(value), {
      observe: 'response',
      headers: new HttpHeaders({ 'Access-Control-Allow-Origin': '*' })
        .set("content-Type", "application/json").set("Authorization", this.authorization)
    });
  }


  public AddFood(value: Foods): Observable<HttpResponse<any>> {
    console.log("url : " + this.crudFood);
    console.log(JSON.stringify(value));
    return this.http.post(this.crudFood, JSON.stringify(value), {
      observe: 'response',
      headers: new HttpHeaders({ 'Access-Control-Allow-Origin': '*' })
        .set("content-Type", "application/json")
        .set("Authorization", this.authorization)
    });
  }




  DeleteFood(id: string): Observable<Foods> {

    return this.http.delete<Foods>(this.crudFood + "/" + id,
      {
        headers: new HttpHeaders({ 'Access-Control-Allow-Origin': '*' })
          .set("content-Type", "application/json")
          .set("Authorization", this.authorization)
      });
  }


  getAllFood(): Observable<HttpResponse<any>> {
    console.log("getAllFood");
    return this.http.get(this.crudFood,
      {
        observe: 'response',
        headers: new HttpHeaders({ 'Access-Control-Allow-Origin': '*' })
          .set("content-Type", "application/json")
          .set("Authorization", this.authorization)
      });
  }


  getAllCategories(): Observable<HttpResponse<any>> {
    console.log("getAllCategories");
    return this.http.get(this.getallcategories,
      {
        observe: 'response',
        headers: new HttpHeaders({ 'Access-Control-Allow-Origin': '*' })
          .set("content-Type", "application/json")
          .set("Authorization", this.authorization)
      });
  }

  getAllFoodByCatid(value : String): Observable<HttpResponse<any>> {
    console.log("getAllCategories");
    return this.http.get(this.getall+value ,
      {
        observe: 'response',
        headers: new HttpHeaders({ 'Access-Control-Allow-Origin': '*' })
          .set("content-Type", "application/json")
          .set("Authorization", this.authorization)
      });
  }

  

  sendFileToStorage(file: File, filename: string): Observable<HttpEvent<{}>> {
    const formdata: FormData = new FormData();


    /*
    let headers: HttpHeaders = new HttpHeaders();
    headers = headers.append('Content-Type', 'application/json');
    headers = headers.append('Access-Control-Allow-Origin', '*');
    headers = headers.append("Authorization", this.authorization);
    */

    formdata.append('file', file);


    console.log(formdata);

    //    const req = new HttpRequest('POST', this.uploadfoodpicture + "?foodName=" + filename, formdata, {


       const req = new HttpRequest('POST', this.uploadfoodpicture + "?foodid=" + filename, formdata, {
      reportProgress: true,
      responseType: 'text'
      /*
      ,
      headers: headers
      */

    });
    return this.http.request(req);
    //this.http.post(this.uploadfoodpicture + "?name=" + filename,formdata,{headers});
  }

  sendFileToStorageDistant(file: File): Observable<HttpEvent<{}>> {
    const formdata: FormData = new FormData();

    /*
    let headers: HttpHeaders = new HttpHeaders();
    headers = headers.append('Content-Type', 'application/json');
    headers = headers.append('Access-Control-Allow-Origin', '*');
    headers = headers.append("Authorization", this.authorization);
    */

    let url: string = this.configService.get('postfileurl');
    formdata.append('file', file);
    const req = new HttpRequest('POST', url, formdata, {
      reportProgress: true,
      responseType: 'text'
      /*
      ,
      headers: headers
      */
    });
    return this.http.request(req);
    //this.http.post(this.uploadfoodpicture + "?name=" + filename,formdata,{headers});
  }


  public downloadPicture(value: string): Observable<any> {
    return this.http.get<any>(this.downloadfoodpicture + "" + value, {
      headers: new HttpHeaders({ 'Access-Control-Allow-Origin': '*' })
        .set("content-Type", "application/json").set("Authorization", this.authorization)
    });
  }
}
