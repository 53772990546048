
export class Livraison {

    livraisonmodeid: string;

    livraisonmodedatecreation: string;

    livraisonmodeisdeleted: boolean;

    livraisonmodename: string;

     livraisonmodcode: string;


    public constructor() {

    }

}