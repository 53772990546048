import { MatSnackBar } from '@angular/material';
import { GenericserviceService } from './../../services/genericservice.service';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { Component, OnInit } from '@angular/core';
import { Root } from 'src/app/models/Root';
import { DISABLED } from '@angular/forms/src/model';
import { Agentstorage } from 'src/app/models/agentstorage';
import { Agent } from 'src/app/models/Agent';
import { Router } from '@angular/router';
import { CustomValidators } from 'ng2-validation';
import { MonprofilService } from './monprofil.service';

@Component({
  selector: 'fury-monprofil',
  templateUrl: './monprofil.component.html',
  styleUrls: ['./monprofil.component.scss']
})
export class MonprofilComponent implements OnInit {

  form: FormGroup;

  root: Root;

  agent: Agent;
  passwordForm: FormGroup;


  selectedRole: string;

  constructor(private formBuilder: FormBuilder, private agentService: GenericserviceService,
    private snackbar: MatSnackBar, private router: Router , private profilserv : MonprofilService,
    private snack: MatSnackBar) {
  }

  ngOnInit() {


    let u = new Agentstorage();
    u = JSON.parse(localStorage.getItem("curentuser"));
    if (u != null) {
      this.agent = u.agent;
    } else {
      this.router.navigate(['/']);
    }

    const agentpassword = new FormControl('', Validators.required);
    const agentconfirmpassword = new FormControl('', CustomValidators.equalTo(agentpassword));

    this.passwordForm = this.formBuilder.group({
      userId: new FormControl(this.agent.agentid, Validators.required),
      oldpassword: new FormControl('', Validators.required),
      newPassword: agentpassword,
      confirmpassword: agentconfirmpassword
    });

    this.form = this.formBuilder.group({
      rootid: ['', Validators.required],
      rootlogin: ['', Validators.required],
      rootpassword: ['', Validators.required],
      rootlastcom: ['', Validators.required],
      rootdatecreation: ['', Validators.required],
    });


    //localStorage.setItem('curentuser', JSON.stringify(resp));
    let retrievedObject = localStorage.getItem('curentuser');
    console.info('--mon profil --- object found ' + retrievedObject);
    this.root = JSON.parse(retrievedObject);
    console.info('--mon profil --- object found ' + this.root.rootlogin);


    /*if(retrievedObject){
    this.form =  new FormGroup({
      rootid: new FormControl (this.root.rootid, Validators.required),
      rootlogin: new FormControl ({value: this.root.rootlogin , disabled: true}),
      rootpassword: new FormControl (this.root.rootpassord ),
      rootlastcom: new FormControl ('', Validators.required),
      rootdatecreation: new FormControl ({value: this.root.rootdatecreation, disabled:true}),
    });*/
  }



  public submit(values: Object): void {
    if (this.passwordForm.valid) {

      let userform: any = this.passwordForm.value;

      this.profilserv.updatepassword(userform).subscribe(
        data => {
          console.log(data);
          switch (data.status) {
            case 200: {
              this.snack.open('Mot de passe  mis à jour !', 'OK', { duration: 4000 })
              this.passwordForm.reset();
              break;
            }
          }

        },

        error => {
          console.log(error);
          switch (error.status) {

            default: {
              this.snack.open('Ancien mot de passe incorrecte ', 'OK', { duration: 4000 })
              break;
            }
          }
        }
      );

    }

  }



}

    //      agenttelephone: new FormControl('', Validators.required),


 // }








