import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'fury-gestioncaissiere',
  templateUrl: './gestioncaissiere.component.html',
  styleUrls: ['./gestioncaissiere.component.scss']
})
export class GestioncaissiereComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
