import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MatSnackBar } from '@angular/material';
import { Router } from '@angular/router';
import { CustomValidators } from 'ng2-validation';
import { ConfigService } from 'ngx-envconfig';
import { NGXLogger } from 'ngx-logger';
import { NgxPermissionsService } from 'ngx-permissions';
import { Subscription } from 'rxjs';
import { ConfirmationService } from 'src/app/confirm/confirmation.service';
import { Agent, Caisse } from 'src/app/models/Agent';
import { Agentstorage } from 'src/app/models/agentstorage';
import { Billetagefin } from 'src/app/models/billetagefin';
import { Checkingcloture } from 'src/app/models/checkingcloture';
import { HistoactiviteDto } from 'src/app/models/histoactiviteDto';
import { ApploaderService } from 'src/app/services/apploader/apploader.service';
import { GenericserviceService } from 'src/app/services/genericservice.service';
import { JournalcaisseService } from '../journalcaisse/journalcaisse.service';
import { HistoactiviteService } from './histoactivite.service';
import { PopupcloturecaisseComponent } from './popupcloturecaisse/popupcloturecaisse.component';

@Component({
  selector: 'fury-cloturecaisse',
  templateUrl: './cloturecaisse.component.html',
  styleUrls: ['./cloturecaisse.component.scss']
})
export class CloturecaisseComponent implements OnInit {


  agents: Caisse[];
  public getItemSub: Subscription;
  dateToday = new Date();

  displayFondCaisse = false;

  caisse: Caisse;

  histoActivite: HistoactiviteDto;


  public chiffreaffaire: any;

  public getChiffreaffaireSub: Subscription;

  public itemForm: FormGroup;

  requestToPass :  Checkingcloture  ;

  public checkingCloture : Boolean =false ;


  public quantiteForm: FormGroup;
  public encaissementForm: FormGroup;


  quantite: number;
  monnaieActive: number = 5;
  cloturecaisse: number;
  differencepercu: number;

  selectedMoney = 'monnaie5f';

  public agent: Agent;

  
  clotureprec: string;

  constructor(private dialog: MatDialog, public formBuilder: FormBuilder,
    private snackbar: MatSnackBar, private caisseServ: JournalcaisseService,
    private datePipe: DatePipe, private agentService: GenericserviceService,
    private confirmService: ConfirmationService, private loader: ApploaderService,
    private permissionsService: NgxPermissionsService, private logger: NGXLogger,
    private histoactiviteService: HistoactiviteService,     private router: Router,

    private snack: MatSnackBar,

    private configService: ConfigService, private fb: FormBuilder) { }

  ngOnInit() {

    let u = new Agentstorage();
    u = JSON.parse(localStorage.getItem('curentuser'));

    if (u != null) {
      this.agent=u.agent ;
    }

    this. findAll();
    this.checkingCloture=false ;
    this.itemForm = this.fb.group({
      to: [  this.datePipe.transform(new Date(),"yyyy-MM-dd") , Validators.compose([Validators.required])],
      caisseid: ['', Validators.compose([Validators.required])]
    });

    this.quantiteForm = this.fb.group({
      quantite: ['', [Validators.required, CustomValidators.number]],
    });

    this.encaissementForm = this.fb.group({
      monnaie5f: ['', [Validators.required, CustomValidators.number]],
      monnaie10f: ['', [Validators.required, CustomValidators.number]],
      monnaie25f: ['', [Validators.required, CustomValidators.number]],
      monnaie50f: ['', [Validators.required, CustomValidators.number]],
      monnaie100f: ['', [Validators.required, CustomValidators.number]],
      monnaie200f: ['', [Validators.required, CustomValidators.number]],
      monnaie250f: ['', [Validators.required, CustomValidators.number]],
      monnaie500f: ['', [Validators.required, CustomValidators.number]],
      monnaie500bf: ['', [Validators.required, CustomValidators.number]],
      monnaie1000f: ['', [Validators.required, CustomValidators.number]],
      monnaie2000f: ['', [Validators.required, CustomValidators.number]],
      monnaie5000f: ['', [Validators.required, CustomValidators.number]],
      monnaie10000f: ['', [Validators.required, CustomValidators.number]],

    });

    
  }



  buildItemForm() {

    this.quantiteForm = this.fb.group({
      quantite: ['', [Validators.required, CustomValidators.number]],
    });

    this.encaissementForm = this.fb.group({
      monnaie5f: ['', [Validators.required, CustomValidators.number]],
      monnaie10f: ['', [Validators.required, CustomValidators.number]],
      monnaie25f: ['', [Validators.required, CustomValidators.number]],
      monnaie50f: ['', [Validators.required, CustomValidators.number]],
      monnaie100f: ['', [Validators.required, CustomValidators.number]],
      monnaie200f: ['', [Validators.required, CustomValidators.number]],
      monnaie250f: ['', [Validators.required, CustomValidators.number]],
      monnaie500f: ['', [Validators.required, CustomValidators.number]],
      monnaie500bf: ['', [Validators.required, CustomValidators.number]],
      monnaie1000f: ['', [Validators.required, CustomValidators.number]],
      monnaie2000f: ['', [Validators.required, CustomValidators.number]],
      monnaie5000f: ['', [Validators.required, CustomValidators.number]],
      monnaie10000f: ['', [Validators.required, CustomValidators.number]],

    });

  }

  /**
   * Raffraichir les formulaires d'encaissement
   */
  rafraichirEncaissement() {

    this.logger.trace('Debut du raffraichissement');
    let qte = 0;
    this.encaissementForm.patchValue({
      monnaie5f: qte
    });
    this.encaissementForm.patchValue({
      monnaie10f: qte
    });
    this.encaissementForm.patchValue({
      monnaie25f: qte
    });
    this.encaissementForm.patchValue({
      monnaie50f: qte
    });
    this.encaissementForm.patchValue({
      monnaie100f: qte
    });
    this.encaissementForm.patchValue({
      monnaie200f: qte
    });
    this.encaissementForm.patchValue({
      monnaie250f: qte
    });
    this.encaissementForm.patchValue({
      monnaie500f: qte
    });
    this.encaissementForm.patchValue({
      monnaie500bf: qte
    });
    this.encaissementForm.patchValue({
      monnaie1000f: qte
    });
    this.encaissementForm.patchValue({
      monnaie2000f: qte
    });
    this.encaissementForm.patchValue({
      monnaie5000f: qte
    });
    this.encaissementForm.patchValue({
      monnaie10000f: qte
    });

  }

  getTotal(){
    let total = this.encaissementForm.get('monnaie5f').value * 5 +
    this.encaissementForm.get('monnaie10f').value * 10 +
    this.encaissementForm.get('monnaie25f').value * 25 +
    this.encaissementForm.get('monnaie50f').value * 50 +
    this.encaissementForm.get('monnaie100f').value * 100 +
    this.encaissementForm.get('monnaie200f').value * 200 +
    this.encaissementForm.get('monnaie250f').value * 250 +
    this.encaissementForm.get('monnaie500f').value * 500 +
    this.encaissementForm.get('monnaie1000f').value * 1000 +
    this.encaissementForm.get('monnaie2000f').value * 2000 +
    this.encaissementForm.get('monnaie5000f').value * 5000 +
    this.encaissementForm.get('monnaie10000f').value * 10000;

    // this.logger.trace('Total', total);
    return total;
  }

  setQuantite(value){
    this.logger.trace('Valeur', value);
    this.monnaieActive = value;
    this.selectedMoney = 'monnaie' + value + 'f';

  }

  validationQuantite(){

    let qte = this.quantiteForm.get('quantite').value;
    // Remettre le champ a 0
    this.quantiteForm.patchValue({
      quantite: ''
    });

    switch (this.monnaieActive.toString()) {
      case '5':
        this.encaissementForm.patchValue({
          monnaie5f: qte
        });
        break;

      case '10':
        this.encaissementForm.patchValue({
          monnaie10f: qte
        });
        break;

      case '25':
        this.encaissementForm.patchValue({
          monnaie25f: qte
        });
        break;
      case '50':
       this.encaissementForm.patchValue({
          monnaie50f: qte
        });
      break;
    case '100':
      this.encaissementForm.patchValue({
        monnaie100f: qte
      });
      break;

    case '200':
      this.encaissementForm.patchValue({
        monnaie200f: qte
      });
      break;
    case '250':
      this.encaissementForm.patchValue({
        monnaie250f: qte
      });
      break;
    case '500':
      this.encaissementForm.patchValue({
        monnaie500f: qte
      });
      break;
    case '500b':
      this.encaissementForm.patchValue({
        monnaie500bf: qte
      });
      break;
    case '1000':
      this.encaissementForm.patchValue({
        monnaie1000f: qte
      });
      break;
    case '2000':
      this.encaissementForm.patchValue({
        monnaie2000f: qte
      });
      break;
    case '5000':
      this.encaissementForm.patchValue({
        monnaie5000f: qte
      });
      break;
    case '10000':
      this.encaissementForm.patchValue({
        monnaie10000f: qte
      });
      break;

      default:
        this.logger.trace('Piece ou billet non connu');
        break;
    }



  }


  setValueCalculatrice(value) {
    this.logger.trace('Valeur à ajouté: ', value);
    // this.logger.trace('Form: ', this.quantiteForm.get('quantite').value);


    if (value == 'reset') { // Si le bouton de suppression est cliqué
      this.quantiteForm.reset();

    } else if (value == 'del') { // Si le bouton de suppression est cliqué
      let nombre = this.quantiteForm.get('quantite').value.toString();
      this.quantiteForm.patchValue({
        quantite: nombre.substring(0, nombre.length - 1)
      });

    } else if(value == '.' && this.quantiteForm.get('quantite').value.toString().includes('.')) {
      // Ne rien faire - ne plus ajouter de '.' si un existe deja .

    } else {
      this.quantiteForm.patchValue({
        quantite: this.quantiteForm.get('quantite').value + value
      });

      }
  }

  
  validationBilletage() {
    this.logger.trace('Validation billetage');
    // Verifier si le billetage est correct

    console.log(" cloture caisse ==> "+this.cloturecaisse);
    console.log(" get total ==> "+this.getTotal());


    if(this.cloturecaisse == this.getTotal()) {
      this.logger.trace('Billetage correct');
      // Enregistrement du cloture de caisse et debut de la journée.

      // Initialisation des objets et array
      let histoactiviteDto: HistoactiviteDto = new HistoactiviteDto();
      // let billetagedebut: Billetagedebut = new Billetagedebut();
      // let billetagefin: Billetagefin = new Billetagefin();
      //let billetagedebutArray : Billetagedebut[] = [];
      let billetagefinArray: Billetagefin[] = [];

      this.logger.trace('Obj chiffreaffaire', this.chiffreaffaire);


      // Formation du tableau billetage
      billetagefinArray.push(
        {
          billetagefinnom: 5,
          billetagefinquantite: Number(this.encaissementForm.get('monnaie5f').value),
          billetagefindate: '',
          billetagefinid: '',
          histoactivite: null,
        },

        {
          billetagefinnom: 10,
          billetagefinquantite: Number(this.encaissementForm.get('monnaie10f').value),
          billetagefindate: '',
          billetagefinid: '',
          histoactivite: null,
        },

        {
          billetagefinnom: 25,
          billetagefinquantite: Number(this.encaissementForm.get('monnaie25f').value),
          billetagefindate: '',
          billetagefinid: '',
          histoactivite: null,
        },

        {
          billetagefinnom: 50,
          billetagefinquantite: Number(this.encaissementForm.get('monnaie50f').value),
          billetagefindate: '',
          billetagefinid: '',
          histoactivite: null,
        },

        {
          billetagefinnom: 100,
          billetagefinquantite: Number(this.encaissementForm.get('monnaie100f').value),
          billetagefindate: '',
          billetagefinid: '',
          histoactivite: null,
        },

        {
          billetagefinnom: 200,
          billetagefinquantite: Number(this.encaissementForm.get('monnaie200f').value),
          billetagefindate: '',
          billetagefinid: '',
          histoactivite: null,
        },

        {
          billetagefinnom: 250,
          billetagefinquantite: Number(this.encaissementForm.get('monnaie250f').value),
          billetagefindate: '',
          billetagefinid: '',
          histoactivite: null,
        },

        {
          billetagefinnom: 500,
          billetagefinquantite: Number(this.encaissementForm.get('monnaie500f').value),
          billetagefindate: '',
          billetagefinid: '',
          histoactivite: null,
        },

        {
          billetagefinnom: 1000,
          billetagefinquantite: Number(this.encaissementForm.get('monnaie1000f').value),
          billetagefindate: '',
          billetagefinid: '',
          histoactivite: null,
        },

        {
          billetagefinnom: 2000,
          billetagefinquantite: Number(this.encaissementForm.get('monnaie2000f').value),
          billetagefindate: '',
          billetagefinid: '',
          histoactivite: null,
        },

        {
          billetagefinnom: 5000,
          billetagefinquantite: Number(this.encaissementForm.get('monnaie5000f').value),
          billetagefindate: '',
          billetagefinid: '',
          histoactivite: null,
        },

        {
          billetagefinnom: 10000,
          billetagefinquantite: Number(this.encaissementForm.get('monnaie10000f').value),
          billetagefindate: '',
          billetagefinid: '',
          histoactivite: null,
        }
      );

      histoactiviteDto.caisseid = this.caisse.caisseid;
      histoactiviteDto.histoactivitefondfin = this.cloturecaisse;
      histoactiviteDto.billetagefin = billetagefinArray;
      histoactiviteDto.histoactivitedifferencesolde = this.differencepercu;
      histoactiviteDto.histoactivitecloseinbo=true;
      histoactiviteDto.agentid=this.agent.agentid ;

      histoactiviteDto.histoactivitechiffreaffaire = this.chiffreaffaire ? this.chiffreaffaire.totalcommande : 0;
      /*
      if (this.clotureprec) { // verifier si on a une operation de fermeture de la veille
        histoactiviteDto.histoactivitedate = this.date;
      } else {
        histoactiviteDto.histoactivitedate = this.autreService.convertDateToString(this.autreService.getCurrentDate());
      }
      */

      histoactiviteDto.histoactivitedate = this.requestToPass.histoactivitedatecreation ;
      this.logger.trace('Obj histoactiviteDto', histoactiviteDto);
      this.updateHistoactivite(histoactiviteDto);


    }else{
      this.logger.trace('Chiffre affaire different du billetage');
      this.snack.open('Chiffre affaire different du billetage', 'OK', { duration: 8000 });

    }

  }
  
  updateHistoactivite(value: HistoactiviteDto) {

    this.getItemSub = this.histoactiviteService.updateHistoactivite(value)
      .subscribe(data => {
        this.loader.close();
        let statuscode = data.status;
        this.logger.trace('DATA', data);

        switch (statuscode) {

          case 201: {
            if (this.clotureprec) { // verifier si on a une operation de fermeture de la veille

              // Retour au fond de caisse
              this.snack.open('Cloture de caisse enregistré avec succès', 'OK', { duration: 4000 });
              this.checkingCloture=false;
              break;

            } else {

              // Ouvrir les stats
              this.snack.open('Bonne fin de journée', 'OK', { duration: 4000 });
              this.checkingCloture=false;
              break;
            }

          }

          default: {

            break;
          }

        }
      },
        error => {
          this.logger.error('error');
          this.logger.error(error);

          this.loader.close();

          switch (error.status) {

            default: {
              this.snack.open('Une erreur est survenue! reessayer SVP', 'OK', { duration: 4000 });

              break;
            }
          }
        });
  }

  submit(){
    this.caisse= new Caisse();
    this.caisse.caisseid=this.itemForm.get("caisseid").value ;
    this.checkingCloture=false ;

    this.checkHistoActiviteByCaisseAndDate( this.itemForm.get("caisseid").value , this.itemForm.get("to").value ) ;
  }

  findAll() {
    this.caisseServ.findAll().subscribe(data => {
      console.log(data);
      switch (data.status) {
        default: {

          console.log("+++++++++++++++++++++");
          console.log(data.body);
          console.log("+++++++++++++++++++++");

          let resp: Caisse[] = data.body;

          if (resp != null) {
            this.agents = resp;
          } else {
            this.agents = [];
          }
          break;
        }
      }
    },
      error => {
        console.log("erreur retour");
        // this.loading.dismiss('messagesService.loadMessagesOverview');
        switch (error.status) {

          default: {
            break;
          }
        }
      }
    );
  }


  checkHistoActiviteByCaisseAndDate(caisseid: string, date: string) {

    this.logger.info(caisseid+" --- "+date);

    let histoactivite: HistoactiviteDto = new HistoactiviteDto();
    histoactivite.caisseid = caisseid;
    histoactivite.histoactivitedate = date;

    this.requestToPass =  new Checkingcloture();
    this.requestToPass.caisseid  = caisseid;
    this.requestToPass.histoactivitedatecreation  = date;


    this.logger.info(histoactivite);
    this.getItemSub = this.histoactiviteService.getHistoactiviteByCaisseAndDate(histoactivite)
      .subscribe(data => {
        let statuscode = data.status;
        this.logger.trace('DATA', data);

        switch (statuscode) {

          case 200: {
            this.loader.close();
            // Verifier si la journée est pas deja cloturé
            if (data.body.histoactiviteheurefin) {
            // Ne pas ouvrir le caisse - redirection vers la page agent
            this.snack.open('La journée est deja cloturé, repasser demain SVP', 'OK', { duration: 10000 });
            //this.router.navigate(['/yolinbo/gestionventecaisse']);

            } else {

              let dateAujordui : string = data.body.histoactiviteheuredebut ;
             dateAujordui = dateAujordui.trim().substring(0,10);
            this.logger.info(dateAujordui);
            this.histoActivite = data.body;

            this.getChiffreAffaireByCaisse(caisseid , dateAujordui);

              this.loader.close();

            // Ouvrir le caisse
            //this.router.navigate(['/yolinbo/gestionpaiement']);
            }

            break;
          }

          case 204: {
            // verification de la cloture de la veille
            this.checkPrecHistoActiviteByCaisseAndDate(caisseid, date);
            // Ouverture du popup de recolte du fond de caisse
            // this.popupFondCaisse();
            break;
          }

          default: {
            this.loader.close();
            this.snack.open('Une erreur est survenue! Serveur indisponible', 'OK', { duration: 4000 });
            //this.router.navigate(['/yolinbo/gestionventecaisse']);
            break;
          }

        }
      },
        error => {
          this.logger.error('error');
          this.logger.error(error);

          this.loader.close();

          this.displayFondCaisse = true; // Afficher le fond de caisse

          switch (error.status) {

            default: {
              this.snack.open('Une erreur est survenue!', 'OK', { duration: 4000 });

              break;
            }
          }
        });
  }

  /**
   * Verifier l'historique de la veille
   */
  checkPrecHistoActiviteByCaisseAndDate(caisseid: string, date: string) {
    let histoactivite: HistoactiviteDto = new HistoactiviteDto();
    histoactivite.caisseid = caisseid;
    histoactivite.histoactivitedate = date;
    this.logger.info(histoactivite);
    this.displayFondCaisse = false;
    this.getItemSub = this.histoactiviteService.getPrecHistoactiviteByCaisseAndDate(histoactivite)
      .subscribe(data => {
        this.loader.close();
        this.displayFondCaisse = true; // Afficher le fond de caisse
        let statuscode = data.status;
        this.logger.trace('DATA', data);

        switch (statuscode) {

          case 200: {
            // Verifier si la journée est pas deja cloturé
            if (!data.body.histoactiviteheurefin) {
            // Ne pas ouvrir le caisse - redirection vers la page agent
            this.logger.trace('La precedente journée à pas été cloturé, ouvrir la page de cloture de caisse');
            this.snack.open('Vous devez cloturé la journée precedente SVP', 'OK', { duration: 10000 });
            let dateAujordui : string = data.body.histoactiviteheuredebut ;
            dateAujordui = dateAujordui.trim().substring(0,10);
            this.logger.info(dateAujordui);
            this.histoActivite = data.body;

            this.getChiffreAffaireByCaisse(caisseid , dateAujordui);
            
            // this.router.navigate(['/yolinbo/cloturecaisse']);

            //---- pour aujordui
            //this.router.navigate(['/yolinbo/cloturecaisse'], {queryParams: {clotureprec: 'true',
             //date: this.autreService.convertDateToString(data.body.histoactivitedatecreation)}});
             //----

            } else {
              // Ouvrir le caisse
              this.logger.trace('La precedente journée à été cloturé, ouvrir la fond de caisse');
              // Ouverture du popup de recolte du fond de caisse
                          //---- pour aujordui  this.popupFondCaisse();
                          this.snack.open('La precedente journée à été cloturé, ouvrir le fond de caisse', 'OK', { duration: 10000 });

              break;
            }

            break;
          }

          case 204: {
            //this.snack.open('Bon retour, saisissez votre fond de caisse SVP', 'OK', { duration: 4000 });
            this.logger.trace('Pas d\'ancien historique, ouvrir le popup de fond de caisse');

            // Ouverture du popup de recolte du fond de caisse


                        //---- pour aujordui this.popupFondCaisse();
            break;
          }

          default: {
            this.snack.open('Une erreur est survenue!', 'OK', { duration: 4000 });

            break;
          }

        }
      },
        error => {
          this.logger.error('error');
          this.logger.error(error);

          this.loader.close();
          // this.router.navigate(['/yolinbo/gestionventecaisse']);


          switch (error.status) {

            default: {
              this.snack.open('Une erreur est survenue! serveur indisponible', 'OK', { duration: 8000 });

              break;
            }
          }
        });
  }



  getChiffreAffaireByCaisse(caisseid: string, date: string) {
    this.getChiffreaffaireSub = this.histoactiviteService.getChiffreAffaireByCaisse(caisseid, date)
      .subscribe(data => {
        this.loader.close();
        let statuscode = data.status;
        this.logger.trace('DATA chiffre affaire', data);
        this.requestToPass.histoactivitedatecreation=date ;

        switch (statuscode) {

          case 200: {

            this.chiffreaffaire = data.body;
            //this.chiffreaffaire = data.body;
            this.requestToPass.chiffreaffaire=data.body.totalcommande ;
             this.popupClotureCaisse(this.requestToPass); // afficher popup de cloture de caisse



            break;
          }

          case 204: {
            this.snack.open('Aucune historique trouvé', 'OK', { duration: 4000 });
            //this.chiffreaffaire = data.body;
            this.requestToPass.chiffreaffaire=0 ; ;
            this.popupClotureCaisse(this.requestToPass); // afficher popup de cloture de caisse

            break;
          }


          default: {
            this.snack.open('Une erreur est survenue!', 'OK', { duration: 4000 });
            //this.router.navigate(['yolinbo/gestionpaiement']); // retour a la caisse

            break;
          }

        }
      },
        error => {
          this.logger.error('error');
          this.logger.error(error);

          this.loader.close();

        //  this.router.navigate(['yolinbo/gestionpaiement']); // retour a la caisse


          switch (error.status) {

            default: {
              this.snack.open('Une erreur est survenue!', 'OK', { duration: 4000 });

              break;
            }
          }
        });
  }

  popupClotureCaisse(chiffreaffaire) {
    this.logger.trace('Popup cloture caisse, open popup');
    let dialogRef: MatDialogRef<any> = this.dialog.open(PopupcloturecaisseComponent, {
      disableClose: true,
      data: { title: '', payload: chiffreaffaire }

    })
    dialogRef.beforeClosed()
      .subscribe(res => {
        if (!res) {
       
          return;
        } else {
          this.logger.trace('response', res);
          this.cloturecaisse = res.cloturecaisse;
          this.differencepercu = res.differencepercu;
          this.checkingCloture=true ;

        }
      });
  }

  /**
   * Enregistrer l'historique d'activité et le billetage
   */
  createHistoactivite(value: HistoactiviteDto) {

    this.getItemSub = this.histoactiviteService.createHistoactivite(value)
      .subscribe(data => {
        this.loader.close();
        let statuscode = data.status;
        this.logger.trace('DATA', data);

        switch (statuscode) {

          case 201: {
            // Ouvrir le caisse
            this.snack.open('Bonne journée', 'OK', { duration: 4000 });
            //---- pour aujordui this.router.navigate(['/yolinbo/gestionpaiement']);
            break;
          }

          default: {

            break;
          }

        }
      },
        error => {
          this.logger.error('error');
          this.logger.error(error);

          this.loader.close();

          switch (error.status) {

            default: {
              this.snack.open('Une erreur est survenue! reessayer SVP', 'OK', { duration: 4000 });

              break;
            }
          }
        });
  }





}
