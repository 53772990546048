import { Component, OnInit } from '@angular/core';
import { MatSnackBar, MatDialog, MatDialogRef } from '@angular/material';
import { ConfigService } from 'ngx-envconfig';
import { NGXLogger } from 'ngx-logger';
import { Subscription } from 'rxjs';
import { ConfirmationService } from 'src/app/confirm/confirmation.service';
import { Agentstorage } from 'src/app/models/agentstorage';
import { Catcorpoformule } from 'src/app/models/param';
import { Rolefonction } from 'src/app/models/rolefonction';
import { AddComponent } from './add/add.component';
import { CatcorpoformuleService } from './catcorpoformule.service';
import { UpdateComponent } from './update/update.component';

@Component({
  selector: 'fury-catcorpoformule',
  templateUrl: './catcorpoformule.component.html',
  styleUrls: ['./catcorpoformule.component.scss']
})
export class CatcorpoformuleComponent implements OnInit {

  getItemSub: Subscription;
  items: Catcorpoformule[];
  scrollBarHorizontal = (window.innerWidth < 1200);
  temp = [];
  rows = [];
  columns = [];


  
  public afficherFormulecorporate: boolean;
  public creationFormulecorporate: boolean;
  public modificationFormulecorporate: boolean;
  public suppressionFormulecorporate: boolean;

  public keyAfficherFormulecorporate= 'afficher formule-corporate';
  public keyCreationFormulecorporate = 'ajouter formule-corporate';
  public keyModificationFormulecorporate = 'modifier formule-corporate';
  public keySuppressionFormulecorporate = 'supprimer formule-corporate';

  constructor(private logger: NGXLogger, private catcorpoformuleService: CatcorpoformuleService,
    private snack: MatSnackBar, private confirmService: ConfirmationService,
    private dialog: MatDialog,
    private configService: ConfigService) { }

  ngOnInit() {
    this.verifierDroit();
    this.getall();

  }

  
  public verifierDroit() {
    this.logger.trace('BEGIN');
    // this.logger.trace('Clé', this.configService.get('keylocalstorage'));

    let agentOnline: Agentstorage;
    agentOnline = JSON.parse(localStorage.getItem(this.configService.get('keylocalstorage')));
    // this.logger.trace(agentOnline);
    let rolefontion: Rolefonction[] = agentOnline.rolefonction;
    // this.logger.trace('rolefontion', rolefontion);

    for (const key in rolefontion) {
      if (rolefontion.hasOwnProperty(key)) {
        // this.logger.trace('KEY', key);
        const element = rolefontion[key].fonction.fonctionname;
        this.logger.trace('element', element);

        if (element == this.keyAfficherFormulecorporate) {
          this.logger.trace('true');
          this.afficherFormulecorporate = true;
        }
        if (element == this.keyCreationFormulecorporate) {
          this.logger.trace('true');
          this.creationFormulecorporate = true;
        }
        if (element == this.keyModificationFormulecorporate) {
          this.logger.trace('true');
          this.modificationFormulecorporate = true;
        }
        if (element == this.keySuppressionFormulecorporate) {
          this.logger.trace('true');
          this.suppressionFormulecorporate = true;
        }
      }
    }

  }



  
  updateFilter(event) {
    const val = event.target.value.toLowerCase();
    var columns = Object.keys(this.temp[0]);
    // Removes last "$$index" from "column"
    columns.splice(columns.length - 1);

    // console.log(columns);
    if (!columns.length)
      return;

    const rows = this.temp.filter(function (d) {
      for (let i = 0; i <= columns.length; i++) {
        let column = columns[i];
        // console.log(d[column]);
        if (d[column] && d[column].toString().toLowerCase().indexOf(val) > -1) {
          return true;
        }
      }
    });

    this.items = rows;
  }

  getall() {
    this.getItemSub = this.catcorpoformuleService.getAll('catcorpoformule/getall')
      .subscribe(data => {

        let statuscode = data.status;
        this.logger.trace('DATA', data);

        switch (statuscode) {

          case 200: {
            this.items = data.body;
            this.temp = data.body;
            break;
          }

          case 204: {
            this.snack.open('Aucune corpo-formule disponible', 'OK', { duration: 4000 })
            break;
          }

          default: {
            this.snack.open('Une erreur est survenue!', 'OK', { duration: 4000 })

            break;
          }

        }
      },
        error => {
          this.logger.error("error");
          this.logger.error(error);

          switch (error.status) {

            default: {
              this.snack.open('Une erreur est survenue!', 'OK', { duration: 4000 })

              break;
            }
          }
        });
  }

  openPopUp(data: any = {}, isNew?) {
    let title = isNew ? 'Lier une categorie corporate à formule' : 'Mettre à jour une  categorie corporate à formule';
    let dialogRef: MatDialogRef<any> = this.dialog.open(AddComponent, {
      width: '720px',
      disableClose: false,
      data: { title: title, payload: data }
    })
    dialogRef.beforeClose()
      .subscribe(res => {
        if (!res) {
          // If user press cancel
          return;
        }

        let url: string = "catcorpoformule/add";

        this.logger.trace("URL : " + url);
       let u = JSON.parse(localStorage.getItem('curentuser'));
        if (u != null) {
          res.userpisteaudit = u.agent.agentemail + ' ' + u.agent.agentfirstname + ' ' + u.agent.agentlastname;
        }
        
        this.catcorpoformuleService.post(res, url)
          .subscribe(data => {
            let statuscode = data.status;

            switch (statuscode) {
              case 200: {
                this.getall();
                this.snack.open('Succes !', 'OK', { duration: 4000 })
                break;
              }

            }

          },
            error => {
              let statuscode = error.status;
              switch (statuscode) {

            
                case 409: {
                  this.snack.open('Echec veuillez eviter les doublon', 'OK', { duration: 4000 })
                  break;
                }

                default: {
                  this.snack.open('Une erreur est survenue!', 'OK', { duration: 4000 })
                  break;
                }
              }

            });

            
      })
  }

  openPopUpdate(data: any = {}, isNew?) {
    let title =  'Mettre à jour une  categorie corporate à formule';
    let dialogRef: MatDialogRef<any> = this.dialog.open(UpdateComponent, {
      width: '720px',
      disableClose: false,
      data: { title: title, payload: data }
    })
    dialogRef.beforeClose()
      .subscribe(res => {
        if (!res) {
          // If user press cancel
          return;
        }

        let url: string = "catcorpoformule/update";

        this.logger.trace("URL : " + url);
       let u = JSON.parse(localStorage.getItem('curentuser'));
        if (u != null) {
          res.userpisteaudit = u.agent.agentemail + ' ' + u.agent.agentfirstname + ' ' + u.agent.agentlastname;
        }
        
        this.catcorpoformuleService.post(res, url)
          .subscribe(data => {
            let statuscode = data.status;

            switch (statuscode) {
              case 200: {
                this.getall();
                this.snack.open('Succes !', 'OK', { duration: 4000 })
                break;
              }

            }

          },
            error => {
              let statuscode = error.status;
              switch (statuscode) {

            
                case 409: {
                  this.snack.open('Echec veuillez eviter les doublon', 'OK', { duration: 4000 })
                  break;
                }

                default: {
                  this.snack.open('Une erreur est survenue!', 'OK', { duration: 4000 })
                  break;
                }
              }

            });

            
      })
  }

  deleteItem(row) {
    this.confirmService.confirm({ message: `Confirmez la suppression ?` })
      .subscribe(res => {
        if (res) {
         
          let request : any = {} ;
          request.catcorpoformuleid=row.catcorpoformuleid ;
          this.catcorpoformuleService.post(request, "catcorpoformule/delete")
            .subscribe(data => {
              let statuscode = data.status;
              switch (statuscode) {
                case 200: {
                  // Actualisé les données
                  this.getall();
                  this.snack.open('Succes!', 'OK', { duration: 4000 })
                  break;
                }
             
                default: {
                  this.snack.open('Une erreur est survenue!', 'OK', { duration: 4000 })
                  break;
                }
              }

            },
              error => {
                let statuscode = error.status;
                switch (statuscode) {
                
                  default: {
                    this.snack.open('Une erreur est survenue!', 'OK', { duration: 4000 })
                    break;
                  }
                }

              });
        }

      })


  }

}
