export class AgentDto {


    agentid: string;
    agentdatecreation: string;
    agentdatelastcon: string
    agentemail: string;
    agentfirstname: string;
    agentlastname: string;
    agentpassword: string;
    agenttelephone: string;
    roleName: string;
    userpisteaudit : string ;

}