import { Component, Inject, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { NGXLogger } from 'ngx-logger';
import { ClientCorporate } from 'src/app/models/Clienttype';

@Component({
  selector: 'fury-updatecorporate',
  templateUrl: './updatecorporate.component.html',
  styleUrls: ['./updatecorporate.component.scss']
})
export class UpdatecorporateComponent implements OnInit {


  form: FormGroup;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<UpdatecorporateComponent>,
    private fb: FormBuilder,
    private logger: NGXLogger) {
  }

  ngOnInit() {
    this.logger.info(this.data.payload);
    const clientcorporate: ClientCorporate = this.data.payload;
    if (clientcorporate) {
      this.form = this.fb.group({
        clienttypeid: [clientcorporate.clienttypeid, Validators.compose([Validators.required])],
        clienttypeadressepostale: [clientcorporate.clienttypeadressepostale, Validators.compose([Validators.required])],
        clienttypeemail: [clientcorporate.clienttypeemail, Validators.compose([Validators.required, Validators.email])],
        clienttypelocalisation: [clientcorporate.clienttypelocalisation, Validators.compose([Validators.required])],
        clienttyperaisonsocial: [clientcorporate.clienttyperaisonsocial, Validators.compose([Validators.required])],
        clienttypestatutjuridique: [clientcorporate.clienttypestatutjuridique, Validators.compose([Validators.required])],
        clienttypetelephone: [clientcorporate.clienttypetelephone, Validators.compose([Validators.required])]
      });
    }

  }



  submit() {
    //    this.form.
    this.dialogRef.close(this.form.value);
  }

}
