import { HttpResponse, HttpHeaders, HttpClient, HttpEvent, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs/Observable';
import { Injectable } from '@angular/core';
import { NGXLogger } from 'ngx-logger';
import { ConfigService } from 'ngx-envconfig';
import { Agentstorage } from '../models/agentstorage';
import { Agent } from 'src/app/models/Agent';

@Injectable({
  providedIn: 'root'
})
export class GenericserviceService {

  // http://crossroadtest.net:8088/yolin/session/login/root
  //http://crossroadtest.net:8088/parameter/root http://crossroadtest.net:8088/parameter/object
  //private apiUrl = 'http://crossroadtest.net:8088/parameter/object';
  private apiUrl = this.configService.get('ipMS') + 'parameter/';
  private uploadfoodpicture = this.configService.get('ipMS') + 'parameter/categorie/uploadfiledoccategorie/';

  //private apiUrl = 'http://crossroadtest.net:8088/parameter/';

  private apiDeliveryUrl = this.configService.get('delivery');


  authorization: string = '';

  constructor(private http: HttpClient,
    private logger: NGXLogger,
    private configService: ConfigService
  ) {
    let u = new Agentstorage();
    u = JSON.parse(localStorage.getItem('curentuser'));

    if (u != null) {
      this.authorization = u.token;
    }
  }

  public type: string;



  saveObject(object: any): Observable<HttpResponse<any>> {
    console.info('--- creating object ' + this.type + ' ---- ' + JSON.stringify({ object }));
    return this.http.post<any>(this.apiUrl + this.type, object, {
      observe: 'response',
      headers: new HttpHeaders({ 'Access-Control-Allow-Origin': '*' })
        .set("content-Type", "application/json")
        .set('Authorization', this.authorization)


    });
  }


  public saveCategorie(value: any): Observable<HttpResponse<any>> {
    return this.http.post<any>(this.apiUrl + this.type, JSON.stringify(value), {
      observe: 'response',
      headers: new HttpHeaders({ 'Access-Control-Allow-Origin': '*' })
        .set("content-Type", "application/json")
        .set('Authorization', this.authorization)
    });

  }


  save(name: string): Observable<HttpResponse<any>> {
    console.info('--- creating object ' + this.type + ' ---- ' + JSON.stringify({ name }));
    return this.http.post<any>(this.apiUrl + this.type, name, {
      observe: 'response',
      headers: new HttpHeaders({ 'Access-Control-Allow-Origin': '*' })
        .set("content-Type", "application/json")
        .set('Authorization', this.authorization)


    });
  }


  update(object: any): Observable<HttpResponse<any>> {
    console.info('----- update object ' + this.type + ' ---- ' + JSON.stringify(object));
    return this.http.put<any>(this.apiUrl + this.type, object, {
      observe: 'response',
      headers: new HttpHeaders({ 'Access-Control-Allow-Origin': '*' })
        .set("content-Type", "application/json")
        .set('Authorization', this.authorization)

    });
  }


  delete(id: string): Observable<HttpResponse<any>> {
    console.info('----- delete object ' + this.type + ' ---- ' + JSON.stringify({ id }));
    return this.http.delete<any>(this.apiUrl + this.type + '/' + id, {
      observe: 'response',
      headers: new HttpHeaders({ 'Access-Control-Allow-Origin': '*' })
        .set("content-Type", "application/json")
        .set('Authorization', this.authorization)

    });

    /* return this.http.delete<any>('url', {
       headers: new HttpHeaders({ 'Content-Type': 'application/json' })
   });
   return this.http.delete(url, {
     headers: new HttpHeaders({ 'Content-Type': 'application/json' })
 })*/


  }


  findAll(): Observable<HttpResponse<any>> {
    console.info('--- getting all objects  ' + this.type + ' ---- ');

    return this.http.get<any[]>(this.apiUrl + this.type, {
      observe: 'response',
      headers: new HttpHeaders({ 'Access-Control-Allow-Origin': '*' })
        .set("content-Type", "application/json")

    });
  }



  sendFileToStorage(file: File, filename: string): Observable<HttpEvent<{}>> {
    const formdata: FormData = new FormData();


    formdata.append('file', file);
    const req = new HttpRequest('POST', this.uploadfoodpicture + filename, formdata, {
      reportProgress: true,
      responseType: 'text'

    });
    return this.http.request(req);
  }


  /**
   * Get all delivery man
   * 
   * @date 2020-07-16
   * @author Rodolpge AGNERO
   * 
   * 
   */
  findAllDeliveryMan(): Observable<HttpResponse<any>> {

    console.info(':::: getting all livreurs ::::');
    return this.http.get<Agent[]>(this.apiDeliveryUrl + 'delivery_man/list', {
      observe: 'response',
      headers: new HttpHeaders({ 'Access-Control-Allow-Origin': '*' })
        .set("content-Type", "application/json")
    });
  }

  findAllDeliveryManOpen(): Observable<HttpResponse<any>> {

    console.info(':::: getting all livreurs ::::');
    return this.http.get<Agent[]>(this.apiDeliveryUrl + 'delivery_man/available_for_delivery', {
      observe: 'response',
      headers: new HttpHeaders({ 'Access-Control-Allow-Origin': '*' })
        .set("content-Type", "application/json")
    });
  }

  /**
  * Get all delivery man
  * 
  * @date 2020-07-16
  * @author Rodolpge AGNERO
  * 
  * @param object
  */
  saveObjectAssign(object: any): Observable<HttpResponse<any>> {
    console.info('--- creating object assign command' + this.type + ' ---- ' + JSON.stringify({ object }));
    return this.http.post<any>(this.apiDeliveryUrl + 'command/assign_delivery_man', object, {
      observe: 'response',
      headers: new HttpHeaders({ 'Access-Control-Allow-Origin': '*' })
        .set("content-Type", "application/json")
    });
  }

}
