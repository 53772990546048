import { Component, OnInit } from '@angular/core';
import { MatSnackBar, MatDialog, MatDialogRef } from '@angular/material';
import { ConfigService } from 'ngx-envconfig';
import { NGXLogger } from 'ngx-logger';
import { Subscription } from 'rxjs/internal/Subscription';
import { ConfirmationService } from 'src/app/confirm/confirmation.service';
import { Agentstorage } from 'src/app/models/agentstorage';
import { Clienttype } from 'src/app/models/Clienttype';
import { Rolefonction } from 'src/app/models/rolefonction';
import { GestioncorporateService } from '../gestioncorporate/gestioncorporate.service';
import { AddComponent } from './add/add.component';
import { CategoriecorpoService } from './categoriecorpo.service';
import { ShowComponent } from './show/show.component';
import { UpdateComponent } from './update/update.component';

@Component({
  selector: 'fury-categoriecorpo',
  templateUrl: './categoriecorpo.component.html',
  styleUrls: ['./categoriecorpo.component.scss']
})
export class CategoriecorpoComponent implements OnInit {

  getItemSub: Subscription;
  items: any[];
  scrollBarHorizontal = (window.innerWidth < 1200);
  temp = [];
  rows = [];
  columns = [];


  
  public afficherCategoriecorporate: boolean;
  public creationCategoriecorporate: boolean;
  public modificationCategoriecorporate: boolean;
  public suppressionCategoriecorporate: boolean;

  public keyAfficherCategoriecorporate= 'afficher categorie-corporate';
  public keyCreationCategoriecorporate = 'ajouter categorie-corporate';
  public keyModificationCategoriecorporate = 'modifier categorie-corporate';
  public keySuppressionCategoriecorporate = 'supprimer categorie-corporate';


  constructor(private logger: NGXLogger, private categoriecorposerv: CategoriecorpoService,
    private snack: MatSnackBar, private confirmService: ConfirmationService,
    private dialog: MatDialog,
    private configService: ConfigService) { }


  ngOnInit() {
    this.verifierDroit();
    this.getall();
  }


  public verifierDroit() {
    this.logger.trace('BEGIN');
    // this.logger.trace('Clé', this.configService.get('keylocalstorage'));

    let agentOnline: Agentstorage;
    agentOnline = JSON.parse(localStorage.getItem(this.configService.get('keylocalstorage')));
    // this.logger.trace(agentOnline);
    let rolefontion: Rolefonction[] = agentOnline.rolefonction;
    // this.logger.trace('rolefontion', rolefontion);

    for (const key in rolefontion) {
      if (rolefontion.hasOwnProperty(key)) {
        // this.logger.trace('KEY', key);
        const element = rolefontion[key].fonction.fonctionname;
        this.logger.trace('element', element);

        if (element == this.keyAfficherCategoriecorporate) {
          this.logger.trace('true');
          this.afficherCategoriecorporate = true;
        }
        if (element == this.keyCreationCategoriecorporate) {
          this.logger.trace('true');
          this.creationCategoriecorporate = true;
        }
        if (element == this.keyModificationCategoriecorporate) {
          this.logger.trace('true');
          this.modificationCategoriecorporate = true;
        }
        if (element == this.keySuppressionCategoriecorporate) {
          this.logger.trace('true');
          this.suppressionCategoriecorporate = true;
        }
      }
    }

  }

  
  updateFilter(event) {
    const val = event.target.value.toLowerCase();
    var columns = Object.keys(this.temp[0]);
    // Removes last "$$index" from "column"
    columns.splice(columns.length - 1);

    // console.log(columns);
    if (!columns.length)
      return;

    const rows = this.temp.filter(function (d) {
      for (let i = 0; i <= columns.length; i++) {
        let column = columns[i];
        // console.log(d[column]);
        if (d[column] && d[column].toString().toLowerCase().indexOf(val) > -1) {
          return true;
        }
      }
    });
    this.items = rows;
  }

  getall() {
    this.getItemSub = this.categoriecorposerv.getAll('categoriecorporate/getallcategoriecorpo')
      .subscribe(data => {

        let statuscode = data.status;
        this.logger.trace('DATA', data);

        switch (statuscode) {

          case 200: {
            this.items = data.body;
            this.temp = data.body;
            break;
          }

          case 204: {
            this.snack.open('Aucun decouvert disponible', 'OK', { duration: 4000 })
            break;
          }

          default: {
            this.snack.open('Une erreur est survenue!', 'OK', { duration: 4000 })
            break;
          }

        }
      },
        error => {
          this.logger.error("error");
          this.logger.error(error);

          switch (error.status) {

            default: {
              this.snack.open('Une erreur est survenue!', 'OK', { duration: 4000 })

              break;
            }
          }
        });
  }

  openPopUp(data: any = {}, isNew?) {
    let title = isNew ? 'Ajouter une categorie corporate' : 'Mettre à jour une categorie corporate';
    let dialogRef: MatDialogRef<any> = this.dialog.open(AddComponent, {
      width: '720px',
      disableClose: false,
      data: { title: title, payload: data }
    })
    dialogRef.beforeClose()
      .subscribe(res => {
        if (!res) {
          // If user press cancel
          return;
        }

        let url: string = "categoriecorporate/add";

        /*
        if (isNew) {
          url = "parameter/abattement/add"
        }
        */

        this.logger.trace("URL : " + url);

        
        this.categoriecorposerv.post(res, url)
          .subscribe(data => {
            let statuscode = data.status;

            switch (statuscode) {
              case 200: {
                this.getall();
                this.snack.open('Succes !', 'OK', { duration: 4000 })
                break;
              }

            }

          },
            error => {
              let statuscode = error.status;
              switch (statuscode) {

            
                case 409: {
                  this.snack.open('Cet abattement existe deja', 'OK', { duration: 4000 })
                  break;
                }

                default: {
                  this.snack.open('Une erreur est survenue!', 'OK', { duration: 4000 })
                  break;
                }
              }

            });

            
      })
  }


  openPopUpDate(data: any = {}, isNew?) {
    let title = isNew ? 'Ajouter une categorie corporate' : 'Mettre à jour une categorie corporate';
    let dialogRef: MatDialogRef<any> = this.dialog.open(UpdateComponent, {
      width: '720px',
      disableClose: false,
      data: { title: title, payload: data }
    })
    dialogRef.beforeClose()
      .subscribe(res => {
        if (!res) {
          // If user press cancel
          return;
        }

        let url: string = "categoriecorporate/update";

        /*
        if (isNew) {
          url = "parameter/abattement/add"
        }
        */

        this.logger.trace("URL : " + url);
        this.logger.trace(">---------- RES " );
        this.logger.trace(res );

        
        this.categoriecorposerv.post(res, url)
          .subscribe(data => {
            let statuscode = data.status;

            switch (statuscode) {
              case 200: {
                this.getall();
                this.snack.open('Succes !', 'OK', { duration: 4000 })
                break;
              }

            }

          },
            error => {
              let statuscode = error.status;
              switch (statuscode) {

                
            
                case 409: {
                  this.snack.open('Cette categorie existe deja', 'OK', { duration: 4000 })
                  break;
                }

                default: {
                  this.snack.open('Une erreur est survenue!', 'OK', { duration: 4000 })
                  break;
                }
              }

            });

            
      })
  }


 
  
  showcarte(value: any) {
    this.dialog.open(ShowComponent, {
      //disableClose: true,
      width: '720px',
      data: { title: '', payload: value }
    }).afterClosed().subscribe((res) => {
      console.log(res);
      if (!res) {
        return;
      }
    });
  }


  updateStatusItem( status : any , row , etat : number) {
    this.confirmService.confirm({ message:  `Confirmation `+status +` ${row.categoriecorpolibelle}?` })
      .subscribe(res => {
        if (res) {
          let request : any = {};
          request.categoriecorpoid=row.categoriecorpoid ;
          this.categoriecorposerv.post(request, "categoriecorporate/updatestatus/"+etat)
            .subscribe(data => {
              let statuscode = data.status;
              switch (statuscode) {
                case 200: {
                  // Actualisé les données
                  this.getall();
                  this.snack.open('Succes!', 'OK', { duration: 4000 })
                  break;
                }
             
                default: {
                  this.snack.open('Une erreur est survenue!', 'OK', { duration: 4000 })
                  break;
                }
              }

            },
              error => {
                let statuscode = error.status;
                switch (statuscode) {
                
                  default: {
                    this.snack.open('Une erreur est survenue!', 'OK', { duration: 4000 })
                    break;
                  }
                }

              });
        }

      })


  }

  
  deleteItem(row) {
    this.confirmService.confirm({ message: `Suppression ${row.categoriecorpolibelle}?` })
      .subscribe(res => {
        if (res) {
          let request : any = {};
          request.categoriecorpoid=row.categoriecorpoid ;
          this.categoriecorposerv.post(request, "categoriecorporate/delete")
            .subscribe(data => {
              let statuscode = data.status;
              switch (statuscode) {
                case 200: {
                  // Actualisé les données
                  this.getall();
                  this.snack.open('Abbatement Supprimé!', 'OK', { duration: 4000 })
                  break;
                }
             
                default: {
                  this.snack.open('Une erreur est survenue!', 'OK', { duration: 4000 })
                  break;
                }
              }

            },
              error => {
                let statuscode = error.status;
                switch (statuscode) {
                
                  default: {
                    this.snack.open('Une erreur est survenue!', 'OK', { duration: 4000 })
                    break;
                  }
                }

              });
        }

      })


  }

}
