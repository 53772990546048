import { Component, OnInit } from '@angular/core';
import { MatSnackBar, MatDialog, MatDialogRef } from '@angular/material';
import { ConfigService } from 'ngx-envconfig';
import { NGXLogger } from 'ngx-logger';
import { Subscription } from 'rxjs';
import { ConfirmationService } from 'src/app/confirm/confirmation.service';
import { Agentstorage } from 'src/app/models/agentstorage';
import { CategoriecorpoService } from '../../categoriecorpo/categoriecorpo.service';
import { GestioncorporateService } from '../../gestioncorporate/gestioncorporate.service';
import { AddConditionComponent } from './add-condition/add-condition.component';
import { UpdateConditionComponent } from './update-condition/update-condition.component';

@Component({
  selector: 'fury-step6-condition',
  templateUrl: './step6-condition.component.html',
  styleUrls: ['./step6-condition.component.scss']
})
export class Step6ConditionComponent implements OnInit {

  getItemSub: Subscription;
  items: any[];
  scrollBarHorizontal = (window.innerWidth < 1200);
  temp = [];
  rows = [];
  columns = [];

  constructor(private logger: NGXLogger, private categoriecorposerv: CategoriecorpoService,
    private snack: MatSnackBar, private confirmService: ConfirmationService,
    private pointservice: GestioncorporateService,
    private snackbar: MatSnackBar,
    private dialog: MatDialog,
    private configService: ConfigService) { }


  ngOnInit() {
    this.getall();
  }


  deleteItem(row) {
    this.confirmService.confirm({
      message: `Voulez vous supprimer la condition : ${row.conditionabtmntlib}?  `
    })
      .subscribe(res => {
        if (res) {
          let u = new Agentstorage();
          u = JSON.parse(localStorage.getItem('curentuser'));
          if (u != null) {
            row.userpisteaudit = u.agent.agentemail + ' ' + u.agent.agentfirstname + ' ' + u.agent.agentlastname;
          }

          this.pointservice.post(row, "abtmnt/deletecondition").subscribe(data => {
            console.log(data);
            this.getall();
            this.snackbar.open('Success ', 'OK', {
              duration: 10000
            });

          },
            error => {

              this.snackbar.open('Echec !! Une erreur est survenue ', 'OK', {
                duration: 10000
              });
              console.log('erreur retour');
              console.log(error);
            }
          );
        }
      });
  }

  openPopUp(data: any = {}, isNew?) {
    let title = 'Ajouter un menu';
    let dialogRef: MatDialogRef<any> = this.dialog.open(AddConditionComponent, {
      width: '720px',
      disableClose: false,
      data: { title: title, payload: data }
    })
    dialogRef.beforeClose()
      .subscribe(res => {
        if (!res) {
          // If user press cancel
          return;
        }

        console.log(res);

        let url: string = "parameter/abtmnt/addcondition";

        /*
        if (isNew) {
          url = "parameter/abattement/add"
        }
        */

        this.logger.trace("URL : " + url);


        this.categoriecorposerv.post(res, url)
          .subscribe(data => {
            let statuscode = data.status;

            switch (statuscode) {
              case 200: {
                this.getall();
                this.snack.open('Succes !', 'OK', { duration: 4000 })
                break;
              }

            }

          },
            error => {
              let statuscode = error.status;
              switch (statuscode) {
                case 423: {
                  this.snack.open('Cette categorie corporate a deja une condition d execution', 'OK', { duration: 4000 })
                  break;
                }

                default: {
                  this.snack.open('Une erreur est survenue!', 'OK', { duration: 4000 })
                  break;
                }
              }

            });


      })
  }

  getall() {
    this.getItemSub = this.categoriecorposerv.getAll('parameter/abtmnt/getallcondition')
      .subscribe(data => {

        let statuscode = data.status;
        this.logger.trace('DATA', data);

        switch (statuscode) {

          case 200: {
            this.items = data.body;
            this.temp = data.body;
            break;
          }


          default: {
            this.snack.open('Une erreur est survenue!', 'OK', { duration: 4000 })
            break;
          }

        }
      },
        error => {
          this.logger.error("error");
          this.logger.error(error);

          switch (error.status) {

            default: {
              this.snack.open('Une erreur est survenue!', 'OK', { duration: 4000 })

              break;
            }
          }
        });
  }




  checkUpdate(row: any) {
    this.getItemSub = this.categoriecorposerv.getAll('parameter/abtmnt/findonecondition/' + row.conditionabtmntid)
      .subscribe(data => {

        let statuscode = data.status;
        this.logger.trace('DATA', data);

        switch (statuscode) {

          case 200: {
            let value: any = data.body;
            this.openPopUpdate(value);
            break;
          }


          default: {
            this.snack.open('Une erreur est survenue!', 'OK', { duration: 4000 })
            break;
          }

        }
      },
        error => {
          this.logger.error("error");
          this.logger.error(error);

          switch (error.status) {

            default: {
              this.snack.open('Une erreur est survenue!', 'OK', { duration: 4000 })

              break;
            }
          }
        });
  }


  openPopUpdate(data: any = {}, isNew?) {
    let title = 'Ajouter un menu';
    let dialogRef: MatDialogRef<any> = this.dialog.open(UpdateConditionComponent, {
      width: '720px',
      disableClose: false,
      data: { title: title, payload: data }
    })
    dialogRef.beforeClose()
      .subscribe(res => {
        if (!res) {
          // If user press cancel
          return;
        }

        console.log(res);

        let url: string = "parameter/abtmnt/updatecondition";

        /*
        if (isNew) {
          url = "parameter/abattement/add"
        }
        */

        this.logger.trace("URL : " + url);


        this.categoriecorposerv.post(res, url)
          .subscribe(data => {
            let statuscode = data.status;

            switch (statuscode) {
              case 200: {
                this.getall();
                this.snack.open('Succes !', 'OK', { duration: 4000 })
                break;
              }

            }

          },
            error => {
              let statuscode = error.status;
              switch (statuscode) {
                case 423: {
                  this.snack.open('Cette categorie corporate a deja une condition d execution', 'OK', { duration: 4000 })
                  break;
                }

                default: {
                  this.snack.open('Une erreur est survenue!', 'OK', { duration: 4000 })
                  break;
                }
              }

            });


      })
  }

}
