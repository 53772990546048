import { Component, Inject, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { ConfigService } from 'ngx-envconfig';
import { NGXLogger } from 'ngx-logger';
import { DeliveryService } from 'src/app/services/delivery.service';

@Component({
  selector: 'fury-globalconfig',
  templateUrl: './globalconfig.component.html',
  styleUrls: ['./globalconfig.component.scss']
})
export class GlobalconfigComponent implements OnInit {

  form: FormGroup;
  item : any ;

  constructor(@Inject(MAT_DIALOG_DATA) public defaults: any,
    public dialogRef: MatDialogRef<GlobalconfigComponent>,
    private fb: FormBuilder, private deliveryService: DeliveryService,private configService: ConfigService,
    private logger: NGXLogger) {
      this.item = this.defaults.payload;
    console.log(this.item.bonusinitial);
  }

  ngOnInit() {
    this.form = this.fb.group({
      soldeconfigid: [ this.item.soldeconfigid, Validators.required],
      delivery: [ this.item.delivery  || '', Validators.required],
      bonusinitial: [ this.item.bonusinitial , Validators.required],
      solde: [this.item.solde , Validators.required],
      total: [this.item.total  || '', Validators.required],
    });
    console.log(this.form.value);

  }


  submit() {
   
    this.dialogRef.close(this.form.value);
    this.form.disabled;
  }

}
