import { Component, OnInit } from '@angular/core';
import { MatSnackBar, MatDialog, MatDialogRef } from '@angular/material';
import { ConfigService } from 'ngx-envconfig';
import { NGXLogger } from 'ngx-logger';
import { Subscription } from 'rxjs';
import { ConfirmationService } from 'src/app/confirm/confirmation.service';
import { CategoriecorpoService } from '../../categoriecorpo/categoriecorpo.service';
import { AddComponent } from '../../gestionpointvente/add/add.component';
import { AddMenuComponent } from './add-menu/add-menu.component';

@Component({
  selector: 'fury-step2-menu',
  templateUrl: './step2-menu.component.html',
  styleUrls: ['./step2-menu.component.scss']
})
export class Step2MenuComponent implements OnInit {

  getItemSub: Subscription;
  items: any[];
  scrollBarHorizontal = (window.innerWidth < 1200);
  temp = [];
  rows = [];
  columns = [];

  constructor(private logger: NGXLogger, private categoriecorposerv: CategoriecorpoService,
    private snack: MatSnackBar, private confirmService: ConfirmationService,
    private dialog: MatDialog,
    private configService: ConfigService) { }


  ngOnInit() {
    this.getall();
  }



  
  updateFilter(event) {
    const val = event.target.value.toLowerCase();
    var columns = Object.keys(this.temp[0]);
    // Removes last "$$index" from "column"
    columns.splice(columns.length - 1);

    // console.log(columns);
    if (!columns.length)
      return;

    const rows = this.temp.filter(function (d) {
      for (let i = 0; i <= columns.length; i++) {
        let column = columns[i];
        // console.log(d[column]);
        if (d[column] && d[column].toString().toLowerCase().indexOf(val) > -1) {
          return true;
        }
      }
    });
    this.items = rows;
  }

  getall() {
    this.getItemSub = this.categoriecorposerv.getAll('parcours/corporate/getallmenu')
      .subscribe(data => {

        let statuscode = data.status;
        this.logger.trace('DATA', data);

        switch (statuscode) {

          case 200: {
            this.items = data.body;
            this.temp = data.body;
            break;
          }


          default: {
            this.snack.open('Une erreur est survenue!', 'OK', { duration: 4000 })
            break;
          }

        }
      },
        error => {
          this.logger.error("error");
          this.logger.error(error);

          switch (error.status) {

            default: {
              this.snack.open('Une erreur est survenue!', 'OK', { duration: 4000 })

              break;
            }
          }
        });
  }


  openPopUp(data: any = {}, isNew?) {
    let title = 'Ajouter un menu' ;
    let dialogRef: MatDialogRef<any> = this.dialog.open(AddMenuComponent, {
      width: '720px',
      disableClose: false,
      data: { title: title, payload: data }
    })
    dialogRef.beforeClose()
      .subscribe(res => {
        if (!res) {
          // If user press cancel
          return;
        }

        console.log(res);

        let url: string = "parcours/corporate/addmemenuabbtmnt";

        /*
        if (isNew) {
          url = "parameter/abattement/add"
        }
        */

        this.logger.trace("URL : " + url);

        
        this.categoriecorposerv.post(res, url)
          .subscribe(data => {
            let statuscode = data.status;

            switch (statuscode) {
              case 200: {
                this.getall();
                this.snack.open('Succes !', 'OK', { duration: 4000 })
                break;
              }

            }

          },
            error => {
              let statuscode = error.status;
              switch (statuscode) {

            
                case 409: {
                  this.snack.open('Ce menu existe deja', 'OK', { duration: 4000 })
                  break;
                }

                default: {
                  this.snack.open('Une erreur est survenue!', 'OK', { duration: 4000 })
                  break;
                }
              }

            });

            
      })
  }

  deleteItem(row) {
    this.confirmService.confirm({ message: `Suppression ${row.menuabbatementlib}?` })
      .subscribe(res => {
        if (res) {
          this.categoriecorposerv.post(row, "parcours/corporate/deletemenuabbtmnt")
            .subscribe(data => {
              let statuscode = data.status;
              switch (statuscode) {
                case 200: {
                  // Actualisé les données
                  this.getall();
                  this.snack.open('menu Supprimé!', 'OK', { duration: 4000 })
                  break;
                }
             
                default: {
                  this.snack.open('Une erreur est survenue!', 'OK', { duration: 4000 })
                  break;
                }
              }

            },
              error => {
                let statuscode = error.status;
                switch (statuscode) {
                
                  default: {
                    this.snack.open('Une erreur est survenue!', 'OK', { duration: 4000 })
                    break;
                  }
                }

              });
        }

      })


  }

}
