import { Component, Injectable, OnInit, ViewChild } from '@angular/core';
import { MatTableDataSource, MatPaginator, MatDialog, MatSnackBar } from '@angular/material';
import { FormBuilder } from '@angular/forms';
import { DatePipe } from '@angular/common';
import { NgxPermissionsService } from 'ngx-permissions';
import { NGXLogger } from 'ngx-logger';
import { forkJoin, Observable, Subscription } from 'rxjs';
import { ConfigService } from 'ngx-envconfig';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Agentstorage } from '../../models/agentstorage';
import { InitDataFormuleAbat } from 'src/app/models/formule-abonnement';

@Injectable({
  providedIn: 'root'
})
export class FormuleAbonnementService {

  private url = this.configService.get('ipMS');

  // parameter/tva/getall

  authorization: string;

  constructor(private http: HttpClient, private configService: ConfigService,
    private logger: NGXLogger) {
      let u = new Agentstorage();
      u = JSON.parse(localStorage.getItem('curentuser'));
      if (u != null) {
        this.authorization = u.token;
        console.log('************************************************ ');
        console.log(this.authorization);
      }
  }

  public post(value: any, path: string): Observable<HttpResponse<any>> {
    return this.http.post(this.url + '' + path, JSON.stringify(value), {
      observe: 'response',
      headers: new HttpHeaders({ 'Access-Control-Allow-Origin': '*' })
        .set('content-Type', 'application/json')
        .set('Authorization', this.authorization)
    });
  }


  public put(value: any, path: string): Observable<HttpResponse<any>> {
    return this.http.put(this.url + '' + path, JSON.stringify(value), {
      observe: 'response',
      headers: new HttpHeaders({ 'Access-Control-Allow-Origin': '*' })
        .set('content-Type', 'application/json')
        .set('Authorization', this.authorization)
    });
  }

  delete(path: string): Observable<HttpResponse<any>> {
    return this.http.delete(this.url + '' + path, {
      observe: 'response',
      headers: new HttpHeaders({ 'Access-Control-Allow-Origin': '*' })
        .set('content-Type', 'application/json')
        .set('Authorization', this.authorization)
    });
  }

  get(path: string): Observable<HttpResponse<any>> {
    return this.http.get(this.url + '' + path, {
      observe: 'response',
      headers: new HttpHeaders({ 'Access-Control-Allow-Origin': '*' })
        .set('content-Type', 'application/json')
        .set('Authorization', this.authorization)
    });
  }



  public requestDataFromMultipleSources( path1 : String , path2 : String ): Observable<any> {
    let response1 = this.http.get(this.url + '' + path1, {
      observe: 'response',
      headers: new HttpHeaders({ 'Access-Control-Allow-Origin': '*' })
        .set('content-Type', 'application/json')
        .set('Authorization', this.authorization)
    });

    let response2 = this.http.get(this.url + '' + path2, {
      observe: 'response',
      headers: new HttpHeaders({ 'Access-Control-Allow-Origin': '*' })
        .set('content-Type', 'application/json')
        .set('Authorization', this.authorization)
    });
    // Observable.forkJoin (RxJS 5) changes to just forkJoin() in RxJS 6
    return forkJoin([response1, response2]);
  }





  }




  



