import { Component, OnInit } from '@angular/core';
import { Agentstorage } from 'src/app/models/agentstorage';
import { Agent, Client } from 'src/app/models/Agent';
import { Router } from '@angular/router';
import { StatforclientService } from './statforclient.service';
import { NGXLogger } from 'ngx-logger';
import { ApploaderService } from 'src/app/services/apploader/apploader.service';
import { Subscription } from 'rxjs/internal/Subscription';
import { MatDialog, MatSnackBar } from '@angular/material';
import { Carteabonnement } from 'src/app/models/carteabonnement';
import { ConfirmationService } from 'src/app/confirm/confirmation.service';
import { ConfigService } from 'ngx-envconfig';
import { DialogCorporateComponent } from './dialog-corporate/dialog-corporate.component';

@Component({
  selector: 'fury-statforclient',
  templateUrl: './statforclient.component.html',
  styleUrls: ['./statforclient.component.scss']
})
export class StatforclientComponent implements OnInit {

  user: Client;
  carte: Carteabonnement;


  soldecarte: number = 0;
  soldeabn: number = 0;

  corporate : string = this.configService.get('corporateDefault') ;

  public getItemSub: Subscription;
  temp = [];
  scrollBarHorizontal = (window.innerWidth < 1200);

  constructor(private router: Router, private statforclientserv: StatforclientService, private dialog: MatDialog ,
     private snack: MatSnackBar, private confirmService: ConfirmationService, private configService: ConfigService ,
    private logger: NGXLogger, private loader: ApploaderService, private snackbar: MatSnackBar) {
    console.log("constructor**************************");
    this.user = this.statforclientserv.$user;

  }

  ngOnInit() {
    this.user = this.statforclientserv.$user;
    if (this.user) {

      this.getCardById(this.user.clientid);
      this.soldeCarte(this.user.clientid);
      this.soldeAbn(this.user.clientid);

    }
  }

  redirectTo(value: string) {
    //router.navigate(['user', user.id, 'details']);
    let valuefinale: string = value;

    if (this.statforclientserv.$user != null) {
      valuefinale = valuefinale + "/" + this.statforclientserv.$user.clientid;
      this.router.navigate([valuefinale]);
    }

  }


  goToBack() {
    this.router.navigate(['/yolinbo/gestionclient']);

  }



  getClientById(value: string) {
    this.getItemSub = this.statforclientserv.getDataForUser(value)
      .subscribe(data => {
        switch (data.status) {
          case 200: {
            this.logger.trace('data', data);
            this.user = this.temp = data.body;
            this.statforclientserv.$user = this.user;
            if (this.user) {
              this.getCardById(this.user.clientid);
            }
            break;
          }

          case 204: {
            break;
          }

          default: {
            /*
                        this.logger.trace('data', data);
                        this.user = this.temp = data.body;
            */
            break;
          }


        }
      },
        error => {
          this.logger.trace('error');
          this.logger.error(error);

          switch (error.status) {

            default: {
              /*
              this.snackbar.open('Un erreur est survenue', 'OK', {
                duration: 10000
              });
              */
              break;
            }
          }
        });
  }


  getCardById(value: string) {
    this.getItemSub = this.statforclientserv.getCardForUser('carte/checkclientcard/' + value)
      .subscribe(data => {
        switch (data.status) {
          case 200: {
            this.logger.trace("+++++++++++++++++++++++++++++++");
            this.logger.trace(data);

            this.carte = data.body;
            break;
          }

          case 204: {
            break;
          }

          default: {
            /*
                        this.logger.trace('data', data);
                        this.user = this.temp = data.body;
            */
            break;
          }


        }
      },
        error => {
          this.logger.trace('error');
          this.logger.error(error);

          switch (error.status) {

            default: {
              /*
              this.snackbar.open('Un erreur est survenue', 'OK', {
                duration: 10000
              });
              */
              break;
            }
          }
        });
  }


  corporateNew(row){
    let request : any = {};

    this.dialog.open(DialogCorporateComponent, { width: '720px' }).afterClosed().subscribe((res) => {
      console.log(res);

      if (!res) {
        return;
      }
      request= res ;
      request.clientid = row.clientid;
      request.isdefault = false;

      this.statforclientserv.post(request, "parameter/drh/changecorporatebo").subscribe(data => {
        console.log(data.body);

        this.snackbar.open('Success ', 'OK', {
          duration: 10000
        });
        this.goToBack();


      },
        error => {

          this.snackbar.open('Echec !! Une erreur est survenue ', 'OK', {
            duration: 10000
          });
          console.log('erreur retour');
          console.log(error);
        }
      );


    });
  }


  corporateDefault(row) {

    console.log(row);
    let message: string = "";
    let request: any = {};
    request.clientid = row.clientid;
    request.isdefault = true;
    request.matricule = "";

    this.confirmService.confirm({
      message: "VOULEZ VOUS DESACTIVER LE CORPORATE ?"
    })
      .subscribe(res => {
        if (res) {

          /*
          let u = new Agentstorage();
          u = JSON.parse(localStorage.getItem('curentuser'));
          if (u != null) {
            row.userpisteaudit = u.agent.agentemail + ' ' + u.agent.agentfirstname + ' ' + u.agent.agentlastname;
          }
          */

          this.statforclientserv.post(request, "parameter/drh/changecorporatebo").subscribe(data => {
            console.log(data.body);

            this.snackbar.open('Success ', 'OK', {
              duration: 10000
            });
            this.goToBack();


          },
            error => {

              this.snackbar.open('Echec !! Une erreur est survenue ', 'OK', {
                duration: 10000
              });
              console.log('erreur retour');
              console.log(error);
            }
          );
        }
      });
  }

  savePrecompte(row, etat) {

    console.log(row);
    let message: string = "";
    let request: any = {};
    request.clientid = row.clientid;
    if (etat == 1) {
      request.isdefault = true;
      message = "voulez vous activer le precompte ? ";
    }


    if (etat == 2) {
      request.isdefault = false;
      message = "voulez vous desactiver le precompte ? ";
    }
    this.confirmService.confirm({
      message: message
    })
      .subscribe(res => {
        if (res) {

          /*
          let u = new Agentstorage();
          u = JSON.parse(localStorage.getItem('curentuser'));
          if (u != null) {
            row.userpisteaudit = u.agent.agentemail + ' ' + u.agent.agentfirstname + ' ' + u.agent.agentlastname;
          }
          */

          this.statforclientserv.post(request, "parameter/drh/changeprecomptebo").subscribe(data => {
            console.log(data.body);

            this.snackbar.open('Success ', 'OK', {
              duration: 10000
            });
            this.goToBack();


          },
            error => {

              this.snackbar.open('Echec !! Une erreur est survenue ', 'OK', {
                duration: 10000
              });
              console.log('erreur retour');
              console.log(error);
            }
          );
        }
      });
  }

  sendMailToAction(value: string, i: number) {
    this.getItemSub = this.statforclientserv.getCardForUser('parameter/utils/mailtocard/' + value + '/' + i)
      .subscribe(data => {
        switch (data.status) {
          case 200: {
            this.logger.trace("+++++++++++++++++++++++++++++++");
            this.logger.trace(data);

            this.snack.open('Mail envoyé avec succès', 'OK', { duration: 4000 })
            break;
          }

          case 204: {
            break;
          }

          default: {
            /*
                        this.logger.trace('data', data);
                        this.user = this.temp = data.body;
            */
            break;
          }


        }
      },
        error => {
          this.logger.trace('error');
          this.logger.error(error);

          switch (error.status) {

            default: {
              /*
              this.snackbar.open('Un erreur est survenue', 'OK', {
                duration: 10000
              });
              */
              break;
            }
          }
        });
  }





  soldeCarte(value: string) {
    // Obtention du solde de la carte
    this.logger.trace("Obtention du solde de la carte----> ");

    // this.loader.open('Recherche du solde en cours...');
    this.statforclientserv.check('parameter/client/solde/' + value).subscribe(data => {
      this.loader.close();

      switch (data.status) {
        case 200:
          this.soldecarte = data.body;
          this.logger.trace('Infos solde ', this.soldecarte);
          /* this.snackbar.open('Solde OK ', 'OK', {
            duration: 5000
          }); */
          break;
        case 204:
          //this.openNotification('Notification', 'Impossible d\'obtenir le solde');

          this.snackbar.open('Echec !! Impossible d\'obtenir le solde carte ', 'OK', {
            duration: 10000
          });

          /* this.snackbar.open('Aucune carte trouvé ', 'OK', {
            duration: 5000
          }); */
          break;
        default:

          break;
      }
    },
      error => {
        this.loader.close();
        this.logger.error('Erreur', error);


      });
  }



  soldeAbn(value: string) {
    // Obtention du solde de la carte
    this.logger.trace("Obtention du solde de l abonnement----> ");

    // this.loader.open('Recherche du solde en cours...');
    this.statforclientserv.check('abonnement/solde/' + value).subscribe(data => {
      this.loader.close();

      switch (data.status) {
        case 200:
          this.soldeabn = data.body;
          this.logger.trace('Infos abn ', this.soldecarte);
          /* this.snackbar.open('Solde OK ', 'OK', {
            duration: 5000
          }); */
          break;
        case 204:
          //this.openNotification('Notification', 'Impossible d\'obtenir le solde');

          /*
          this.snackbar.open('Echec !! Impossible d\'obtenir le solde carte ', 'OK', {
            duration: 10000
          });
          */

          /* this.snackbar.open('Aucune carte trouvé ', 'OK', {
            duration: 5000
          }); */
          break;
        default:

          break;
      }
    },
      error => {
        this.loader.close();
        this.logger.error('Erreur', error);


      });
  }





}
