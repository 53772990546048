import { Component, OnInit } from '@angular/core';
import { Statusblocage } from 'src/app/models/statusblocage';
import { CartestatusService } from './cartestatus.service';
import { NGXLogger } from 'ngx-logger';
import { FormBuilder } from '@angular/forms';
import { MatSnackBar, MatDialog } from '@angular/material';
import { ConfigService } from 'ngx-envconfig';
import { DatePipe } from '@angular/common';
import { ApploaderService } from 'src/app/services/apploader/apploader.service';
import { Router } from '@angular/router';
import { Agentstorage } from 'src/app/models/agentstorage';
import { ChangestatusComponent } from './changestatus/changestatus.component';


@Component({
  selector: 'fury-cartestatus',
  templateUrl: './cartestatus.component.html',
  styleUrls: ['./cartestatus.component.scss']
})
export class CartestatusComponent implements OnInit {

  scrollBarHorizontal = (window.innerWidth < 1200);


  public items: Statusblocage[];
  temp = [];

  constructor(private carteserv: CartestatusService, private logger: NGXLogger, private fb: FormBuilder,
    private snackbar: MatSnackBar, private configService: ConfigService,
    private datePipe: DatePipe, private dialog: MatDialog, private loader: ApploaderService, private router: Router) { }


  ngOnInit() {
    this.getAllStatusCarte();
  }


  updateFilter(event) {
    const val = event.target.value.toLowerCase();
    var columns = Object.keys(this.temp[0]);
    // Removes last "$$index" from "column"
    columns.splice(columns.length - 1);

    // console.log(columns);
    if (!columns.length)
      return;

    const rows = this.temp.filter(function (d) {
      for (let i = 0; i <= columns.length; i++) {
        let column = columns[i];
        // console.log(d[column]);
        if (d[column] && d[column].toString().toLowerCase().indexOf(val) > -1) {
          return true;
        }
      }
    });

    this.items = rows;
  }


  getAllStatusCarte() {
    this.carteserv.getallstatusblocage()
      .subscribe(data => {
        switch (data.status) {
          case 200: {
            this.logger.trace('data', data);
            this.items = this.temp = data.body;
            break;
          }

          case 204: {
            break;
          }

          default: {
            /*
                        this.logger.trace('data', data);
                        this.user = this.temp = data.body;
            */
            break;
          }


        }
      },
        error => {
          this.logger.trace('error');
          this.logger.error(error);

          switch (error.status) {

            default: {
              /*
              this.snackbar.open('Un erreur est survenue', 'OK', {
                duration: 10000
              });
              */
              break;
            }
          }
        });
  }


  changeStatusCarte(row: any) {
    this.dialog.open(ChangestatusComponent, {
      //disableClose: true,
      width: '720px',
      data: { title: '', payload: row }
    }).afterClosed().subscribe((res) => {
      console.log(res);
      if (!res) {
        return;
      }

      this.loader.open();
      let ca: Statusblocage = res;
      let u = new Agentstorage();
      u = JSON.parse(localStorage.getItem('curentuser'));

      if (u != null) {
        ca.statutblocageagentid = u.agent.agentid;
        ca.statutblocageagentemail = u.agent.agentemail;
      }

      this.logger.trace("carte", ca);

      this.carteserv.statusCarte(ca, res.status).subscribe(data => {
        this.logger.trace(data);
        switch (data.status) {
          case 200: {
            this.loader.close();
            this.getAllStatusCarte();
            this.snackbar.open('Succes', 'OK', {
              duration: 10000
            });
            break;
          }
          case 204: {
            this.loader.close();
            this.snackbar.open('Echec ! carte non trouvé', 'OK', {
              duration: 10000
            });
            break;
          }

        }
      },
        error => {

          this.logger.trace("***********************");
          this.logger.trace("erreur found");
          let response: string = error.error;
          this.logger.trace(response);
          this.logger.trace(error.status);
          this.logger.trace("***********************");

          // this.loading.dismiss('messagesService.loadMessagesOverview');
          switch (error.status) {

            case 401: {
              this.loader.close();
              this.snackbar.open('Echec ! Agent non trouvé', 'OK', {
                duration: 10000
              });
              break;
            }

            case 406: {
              this.loader.close();
              this.snackbar.open('Echec ! Carte deja activée', 'OK', {
                duration: 10000
              });
              break;
            }

            case 423: {

              this.logger.trace(error);
              this.loader.close();
              this.snackbar.open('Echec ! status carte abonnement ' + response, 'OK', {
                duration: 10000
              });
              break;
            }


            default: {
              this.loader.close();
              this.logger.error("Check API", error);
              this.snackbar.open('Echec', 'OK', {
                duration: 10000
              });
              break;
            }

          }
        }
      );
    });
  }







}
