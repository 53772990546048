import { Component, OnInit } from '@angular/core';
import { CreditercarteService } from './creditercarte.service';
import { NGXLogger } from 'ngx-logger';
import { FormBuilder } from '@angular/forms';
import { MatSnackBar, MatDialog } from '@angular/material';
import { ConfigService } from 'ngx-envconfig';
import { DatePipe } from '@angular/common';
import { ApploaderService } from 'src/app/services/apploader/apploader.service';
import { Router } from '@angular/router';
import { crediterca } from 'src/app/models/carteabonnement';
import { Agentstorage } from 'src/app/models/agentstorage';
import { CardcreditComponent } from './cardcredit/cardcredit.component';
import { HttpEventType } from '@angular/common/http';

@Component({
  selector: 'fury-creditercarte',
  templateUrl: './creditercarte.component.html',
  styleUrls: ['./creditercarte.component.scss']
})
export class CreditercarteComponent implements OnInit {


  fileSelected: File;


  constructor(private historiqserv: CreditercarteService, private logger: NGXLogger, private fb: FormBuilder,
    private snackbar: MatSnackBar, private configService: ConfigService,
    private datePipe: DatePipe, private dialog: MatDialog, private loader: ApploaderService, private router: Router) { }

  ngOnInit() {
  }




  crediterCarte() {
    this.dialog.open(CardcreditComponent, {
      //disableClose: true,
      width: '720px',
      data: { title: '', payload: '' }
    }).afterClosed().subscribe((res) => {
      console.log(res);
      if (!res) {
        return;
      }

      this.loader.open();

      let ca: crediterca = res;

      let u = new Agentstorage();
      u = JSON.parse(localStorage.getItem('curentuser'));

      if (u != null) {
        ca.carteabonnementagentid = u.agent.agentid;
      }
      ca.historiquecarteprovenance = "Backoffice";
      this.logger.trace("carte", ca);

      this.historiqserv.crediterCarte(ca).subscribe(data => {
        this.logger.trace(data);
        switch (data.status) {
          case 201: {

            this.loader.close();
            this.snackbar.open('Succes', 'OK', {
              duration: 10000
            });
            break;
          }



          case 204: {

            this.loader.close();
            this.snackbar.open('Echec ! carte non trouvé', 'OK', {
              duration: 10000
            });
            break;
          }

        }
      },
        error => {
          this.logger.trace("erreur retour");
          // this.loading.dismiss('messagesService.loadMessagesOverview');
          switch (error.status) {

            case 401: {
              this.loader.close();
              this.snackbar.open('Echec !', 'OK', {
                duration: 10000
              });
              break;
            }

            case 406: {
              this.loader.close();
              this.snackbar.open('Echec ! Agent existe pas', 'OK', {
                duration: 10000
              });
              break;
            }

            default: {
              this.loader.close();

              // this.FormReg(" erreur de connexion !!! Veuillez ressayer");
              this.logger.error("Check API", error);

              this.snackbar.open('Echec', 'OK', {
                duration: 10000
              });
              break;
            }
          }
        }
      );


    });
  }





  public fileChangeEvent($event) {
    console.log('FILECHANGE');

    this.fileSelected = $event.target.files[0];
    let path = URL.createObjectURL($event.target.files[0]);
    console.log('PATH');
    console.log(path);

    const constFileSelected: File = $event.target.files[0];
    console.log(constFileSelected);
    console.log('FILENAME');
    console.log(constFileSelected.name);
    console.log(constFileSelected.type);
    console.log(constFileSelected.size);
    console.log(constFileSelected.lastModified);

    // Fonction pour afficher le contenu du fichier
    // this.check(path, constFileSelected.type);
  }


  chargerFichier() {
    // Verifier si le fichier a ete choisi
    if (this.fileSelected == null) {



      this.snackbar.open('Le choix du repertoire est obligatoire', 'OK', { duration: 7000 });
    } else {

      console.log('type fileSelected');
      console.log(this.fileSelected.type);
      let type = this.fileSelected.type;

      // Verifier si le type de fichier est conforme (.csv, .txt, .xlsx, .xls)
      // if(type === 'application/vnd.ms-excel' || type === 'text/csv' || type === 'text/plain' || type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') {
      if (type === 'application/vnd.ms-excel' || type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') {

        console.log('type fileSelected');
        console.log(this.fileSelected.size);
        let taille: number = this.fileSelected.size;

        console.log('Debut de la modif');
        // Send to MS
        this.loader.open();
        this.historiqserv.pushFileToStorage(this.fileSelected)
          .subscribe(/*data => {
  
    
    }*/event => {

              if (event.type === HttpEventType.DownloadProgress) {
                // {
                // loaded:11, // Number of bytes uploaded or downloaded.
                // total :11 // Total number of bytes to upload or download
                // }
                // console.log('event1');
                // console.log(event.loaded);
              }

              if (event.type === HttpEventType.UploadProgress) {
                // console.log('event2');
                // console.log(event.loaded);


                // Calcul du pourcentage chargé
                const percentDone = Math.round(100 * event.loaded / event.total);

              }

              if (event.type === HttpEventType.Response) {

                console.log('DONE');
                // console.log(event.body);
                this.snackbar.open('Fichier chargé avec succès', 'OK', { duration: 4000 });

                this.loader.close();

              }


            }, error => {
              let statuscode = error.status;
              console.log('error' + error.error, error);


              switch (statuscode) {
                case 404: {
                  this.loader.close();
                  this.snackbar.open('URL introuvable!, contacter le support', 'OK', { duration: 4000 })
                  break;
                }
                case 401: {
                  this.loader.close();
                  this.loader.close();
                  this.snackbar.open('Fichier invalide', 'OK', { duration: 4000 });

                  break;
                }
                case 409: {
                  this.loader.close();
                  this.snackbar.open('Conflit,information dupliquée!', 'OK', { duration: 4000 })
                  break;
                }
                case 500: {
                  this.loader.close();
                  this.snackbar.open('Erreur, verifier votre repertoire', 'OK', { duration: 4000 })
                  break;
                }
                default: {
                  this.loader.close();
                  this.snackbar.open('Une erreur est survenue! verifier votre repertoire', 'OK', { duration: 4000 })
                  break;
                }

              }

            },
          );


      } else {
        this.snackbar.open('Format non supporté', 'OK', { duration: 7000 });
      }
    }
  }


}
