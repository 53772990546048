import { DatePipe } from '@angular/common';
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MatTableDataSource, MatPaginator, MatDialog, MatSnackBar } from '@angular/material';
import { ConfigService } from 'ngx-envconfig';
import { NGXLogger } from 'ngx-logger';
import { NgxPermissionsService } from 'ngx-permissions';
import { Subscription } from 'rxjs';
import { ConfirmationService } from 'src/app/confirm/confirmation.service';
import { DemandeDto, Agentstorage } from 'src/app/models/agentstorage';
import { ApploaderService } from 'src/app/services/apploader/apploader.service';
import { DemandedrhService } from '../demandedrh/demandedrh.service';
import { DemandestatusComponent } from '../demandedrh/demandestatus/demandestatus.component';
import { ShowquitcorpComponent } from './showquitcorp/showquitcorp.component';

@Component({
  selector: 'fury-demandequitcrpt',
  templateUrl: './demandequitcrpt.component.html',
  styleUrls: ['./demandequitcrpt.component.scss']
})
export class DemandequitcrptComponent implements OnInit {

  displayedColumns: string[] = ['demandeabndate', 'demandeabnexp', 'demandeabnexpemail', 'demandeabnstatut', 'actions'];
  dataSource = new MatTableDataSource<DemandeDto>([]);
  @ViewChild(MatPaginator) paginator: MatPaginator;
  public getItemSub: Subscription;
  demandedrh = [];

  constructor(private dialog: MatDialog, public formBuilder: FormBuilder,
    private snackbar: MatSnackBar, private datePipe: DatePipe,
    private configService: ConfigService,
    private confirmService: ConfirmationService, private loader: ApploaderService,
    private permissionsService: NgxPermissionsService, private logger: NGXLogger,
    private pointservice: DemandedrhService) { }

  ngOnInit() {
    this.getAllDemande();
  }

  getAllDemande() {
    this.getItemSub = this.pointservice.get('drh/findbyetat/3')
      .subscribe(data => {
        const statuscode = data.status;
        this.logger.trace('DATA', data);
        switch (statuscode) {
          case 200: {
            this.demandedrh = data.body;
            this.dataSource.data = this.demandedrh;
            this.dataSource.paginator = this.paginator;
            // this.temp = data.body;
            break;
          }
          case 204: {
            // this.snack.open('Aucune TVA disponible', 'OK', { duration: 4000 })
            this.demandedrh=[];
            break;
          }
          default: {
            // this.snack.open('Une erreur est survenue!', 'OK', { duration: 4000 })
            break;
          }
        }
      },
        error => {
          this.logger.error('error');
          this.logger.error(error);
          switch (error.status) {
            default: {
               this.snackbar.open('Une erreur est survenue!', 'OK', { duration: 4000 })
              break;
            }
          }
        });
  }



  show(value: any ) {
    this.dialog.open(ShowquitcorpComponent, {
      //disableClose: true,
      width: '520px',
      data: { title: '', payload: value }
    }).afterClosed().subscribe((res) => {
      console.log(res);
      if (!res) {
        return;
      }
    });
  }


  validerStatut(row , etat : number) {
    console.log(row);
    let message: string = "";
    let dialogMessage: boolean = false;

    if (etat === 1) {
      dialogMessage = true;
      message = `CONFIRMEZ VOUS LA VALIDATION DE LA REQUETE ?`
    }
    if (etat === 0) {
      dialogMessage = true;
      message = `CONFIRMEZ VOUS L INVALIDATION DE LA REQUETE ?`
    }

    if (dialogMessage) {
      this.confirmService.confirm({
        message: message
      })
        .subscribe(res => {
          if (res) {
            let u = new Agentstorage();
            u = JSON.parse(localStorage.getItem('curentuser'));
            if (u != null) {
              row.userpisteaudit = u.agent.agentemail + ' ' + u.agent.agentfirstname + ' ' + u.agent.agentlastname;
            }
            var request = Object.assign({}, row);

            row.demandeabnstatus = etat;
            console.log("---> UPDATE STATUT");
            console.log(row);

            this.pointservice.post(row, 'drh/updatestatus').subscribe(data => {
              console.log(data);
              this.getAllDemande();
              this.snackbar.open('Success ', 'OK', {
                duration: 10000
              });
            },
              error => {
                this.getAllDemande();
                this.snackbar.open('Echec !! Une erreur est survenue ', 'OK', {
                  duration: 10000
                });
                console.log('erreur retour');
                console.log(error);
              }
            );
          }
        });
    }
  }

}
