import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
// tslint:disable-next-line:max-line-length
import { MatChipsModule, MatTableModule, MatPaginatorModule, MatSelectModule, MatListModule, MatDatepickerModule, MatFormFieldModule, MatInputModule } from '@angular/material';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { FurySharedModule } from 'src/@fury/fury-shared.module';
import { BreadcrumbsModule } from 'src/@fury/shared/breadcrumbs/breadcrumbs.module';
import { FuryCardModule } from 'src/@fury/shared/card/card.module';
import { ListModule } from 'src/@fury/shared/list/list.module';
import { MaterialModule } from 'src/@fury/shared/material-components.module';
import { QuickInfoWidgetModule } from '../dashboard/widgets/quick-info-widget/quick-info-widget.module';
import { FormuleAbonnementComponent } from './formuleabonnement.component';
import { AddFormuleComponent } from './addformule/addformule.component';
import { UpdateFormuleComponent } from './updateformule/updateformule.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

@NgModule({
  declarations: [FormuleAbonnementComponent, AddFormuleComponent, UpdateFormuleComponent],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    MatChipsModule,
    ListModule,
    MatTableModule,
    MatPaginatorModule,
    MatSelectModule,
    MatListModule,
    MatChipsModule,
    MaterialModule,
    BreadcrumbsModule,
    NgxDatatableModule,
    FuryCardModule,
    MatChipsModule,
    QuickInfoWidgetModule,
    MatDatepickerModule,
    MatFormFieldModule,
    FurySharedModule,


    MatInputModule,
    FormsModule,
    BrowserAnimationsModule
  ],
  entryComponents: [AddFormuleComponent, UpdateFormuleComponent]
})
export class FormuleAbonnementModule { }
