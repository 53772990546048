import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'fury-deleteagent',
  templateUrl: './deleteagent.component.html',
  styleUrls: ['./deleteagent.component.scss']
})
export class DeleteagentComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
