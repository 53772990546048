import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpHeaders } from '@angular/common/http';
import { ConfigService } from 'ngx-envconfig';
import { NGXLogger } from 'ngx-logger';
import { Agentstorage } from 'src/app/models/agentstorage';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class MonprofilService {

  
  authorization: string;

  private cruduser = this.configService.get('ipMS') + 'yolin/session/agent/renewpassword';

  constructor(private http: HttpClient, private configService: ConfigService,
    private logger: NGXLogger) {
    let user: Agentstorage = new Agentstorage();
    user = JSON.parse(localStorage.getItem('curentuser'));

    if (user != null) {
      this.authorization = user.token;
    }
  }



  public updatepassword(value: any): Observable<HttpResponse<any>> {
    console.log(value);
    return this.http.post(this.cruduser, JSON.stringify(value), {
      observe: 'response',
      headers: new HttpHeaders({ 'Access-Control-Allow-Origin': '*' })
        .set("content-Type", "application/json")
        .set('Authorization', this.authorization)

    });
  }


  
}
