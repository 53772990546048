
import { NGXLogger } from 'ngx-logger';
import { ConfigService } from 'ngx-envconfig';
import { HttpResponse, HttpHeaders, HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs/internal/Observable';
import { Injectable } from '@angular/core';
import { Agentstorage } from 'src/app/models/agentstorage';
import { RolefonctionDto } from 'src/app/models/rolefonctionDto';


@Injectable({
  providedIn: 'root'
})
export class RolefonctionService {

  authorization: string;

  private crudrolefonction = this.configService.get('ipMS') + 'parameter/rolefonction';

  constructor(private http: HttpClient, private configService: ConfigService,
    private logger: NGXLogger) {
    let user: Agentstorage = new Agentstorage();
    user = JSON.parse(localStorage.getItem('curentuser'));

    if (user != null) {
      this.authorization = user.token;
    }
  }


  public createRoleFonction(value: RolefonctionDto): Observable<HttpResponse<any>> {
    this.logger.trace("url : " + this.crudrolefonction + '/' + 'createprofil');
    this.logger.trace(JSON.stringify(value));
    return this.http.post(this.crudrolefonction + '/' + 'createprofil', JSON.stringify(value), {
      observe: 'response',
      headers: new HttpHeaders({ 'Access-Control-Allow-Origin': '*' })
        .set("content-Type", "application/json")
        .set("Authorization", this.authorization)
    });
  }

  public modifierRoleFonction(value: RolefonctionDto): Observable<HttpResponse<any>> {
    this.logger.trace("url : " + this.crudrolefonction + '/' + 'createprofil');
    this.logger.trace(JSON.stringify(value));
    return this.http.put(this.crudrolefonction + '/' + 'updateprofil', JSON.stringify(value), {
      observe: 'response',
      headers: new HttpHeaders({ 'Access-Control-Allow-Origin': '*' })
        .set("content-Type", "application/json")
        .set("Authorization", this.authorization)
    });
  }

  public findbyRoleid(value: string): Observable<HttpResponse<any>> {
    this.logger.trace("url : " + this.crudrolefonction + '/' + 'findbyroleid');
    this.logger.trace(JSON.stringify(value));
    return this.http.get(this.crudrolefonction + '/' + 'findbyroleid' + '/' + value, {
      observe: 'response',
      headers: new HttpHeaders({ 'Access-Control-Allow-Origin': '*' })
        .set("content-Type", "application/json")
        .set("Authorization", this.authorization)
    });
  }

  public deleteAllprofil(value: string): Observable<HttpResponse<any>> {
    this.logger.trace('url : ' + this.crudrolefonction + '/deleteall/' + value);
    this.logger.trace(JSON.stringify(value));
    return this.http.delete(this.crudrolefonction + '/' + 'deleteall' + '/' + value, {
      observe: 'response',
      headers: new HttpHeaders({ 'Access-Control-Allow-Origin': '*' })
        .set("content-Type", "application/json")
        .set("Authorization", this.authorization)
    });
  }


}
