import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpHeaders } from '@angular/common/http';
import { NGXLogger } from 'ngx-logger';
import { ConfigService } from 'ngx-envconfig';
import { Agentstorage } from 'src/app/models/agentstorage';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class StatperiodiqueService {


  private apiUrl = this.configService.get('ipMS') + 'stats/statbycommanditem';
  private apiUrlperiode = this.configService.get('ipMS') + 'stats/statbyitemcommand';
  private url = this.configService.get('ipMS');

  authorization: string = '';

  constructor(private http: HttpClient,
    private logger: NGXLogger,
    private configService: ConfigService
  ) {
    let u = new Agentstorage();
    u = JSON.parse(localStorage.getItem('curentuser'));

    if (u != null) {
      this.authorization = u.token;
    }
  }


  public getPeriode(value: any): Observable<HttpResponse<any>> {
    this.logger.trace("url : " + this.apiUrlperiode);
    this.logger.trace(JSON.stringify(value));
    return this.http.post(this.apiUrlperiode, JSON.stringify(value), {
      observe: 'response',
      headers: new HttpHeaders({ 'Access-Control-Allow-Origin': '*' })
        .set("content-Type", "application/json")
        .set("Authorization", this.authorization)
    });
  }


  public post(value: any, api: string): Observable<HttpResponse<any>> {
    this.logger.trace(JSON.stringify(value));
    return this.http.post(this.url + api, JSON.stringify(value), {
      observe: 'response',
      headers: new HttpHeaders({ 'Access-Control-Allow-Origin': '*' })
        .set("content-Type", "application/json")
        .set("Authorization", this.authorization)
    });
  }

  getAllStat(): Observable<HttpResponse<any>> {
    //return this.http.get(this.apiUrljournal + caissier + "/" + agentid,
    return this.http.get(this.apiUrl,
      {
        observe: 'response',
        headers: new HttpHeaders({ 'Access-Control-Allow-Origin': '*' })
          .set("content-Type", "application/json")
          .set("Authorization", this.authorization)
      });
  }


  public getJSON(value: any): Observable<any> {
    return this.http.get(value);
  }



}
