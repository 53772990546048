import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormArray, FormControl } from '@angular/forms';
import { Subscription } from 'rxjs';
import { RolefonctionDto } from 'src/app/models/rolefonctionDto';
import { Role } from 'src/app/models/Role';
import { Rolefonction } from 'src/app/models/rolefonction';
import { NGXLogger } from 'ngx-logger';
import { GestionprofilService } from '../gestionprofil.service';
import { FonctionService } from 'src/@fury/services/fonction.service';
import { RolefonctionService } from 'src/@fury/services/rolefonction.service';
import { MatSnackBar } from '@angular/material';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { AutreService } from 'src/@fury/services/autre.service';
import { Agentstorage } from 'src/app/models/agentstorage';

@Component({
  selector: 'fury-addgestionprofil',
  templateUrl: './addgestionprofil.component.html',
  styleUrls: ['./addgestionprofil.component.scss']
})
export class AddgestionprofilComponent implements OnInit {


  public checkboxGroup: FormGroup;
  public fonction: any;
  getItemSub: Subscription;
  itemsFonctionGroupby: any;
  objToSend: RolefonctionDto = new RolefonctionDto();
  role: Role = new Role();
  paramRoleid: string;
  rolefonctionbyid: Rolefonction;
  public roleName: string = '';

  constructor(private logger: NGXLogger, public fb: FormBuilder,
    private gestionprofilService: GestionprofilService,
    private fonctionService: FonctionService,
    private rolefonctionService: RolefonctionService,
    private snack: MatSnackBar,
    private route: Router,
    private activatedRoute: ActivatedRoute,
    public autreService: AutreService) { }

  ngOnInit() {

    // let tab = ['bleu', 'rouge', 'vert', 'bleu'];
    // let result = this.autreService.supprimerDoublon(tab);
    // this.logger.trace('RESULT', result);

    this.buildItemForm();

    this.getFonctionGroupbytype();

    // Verifier si un parametre est entrant
    this.activatedRoute.queryParams.forEach((params: Params) => {
    this.paramRoleid = params['profil'];
    this.logger.trace(params);
    // this.paramRoleid = params.profil;
    this.logger.trace(this.paramRoleid);

      });

    if (this.paramRoleid) {
      this.logger.trace('Parametre existant, modification');
      this.getRolefonctionByRoleid(this.paramRoleid);


    } else {
      this.logger.trace('Parametre non existante, ajout');

    }



  }

  buildItemForm() {

    this.checkboxGroup = this.fb.group({
      rolename: ['', Validators.compose([Validators.required])],
      roledepartement: [''],

      //fonction: this.fb.array([])
      fonction: this.fb.array([])

    });


  }

  getResult(value: string) {
    // this.logger.trace('valeur', value);
    if (value == 'afficher profil') {
      const interests = <FormArray>this.checkboxGroup.get('fonction') as FormArray;
    // console.log('interests', interests);

      interests.push(new FormControl(value));
      console.log('interests', interests);

    // if (interests.controls.indexOf(value) != -1) {
    //   interests.push(new FormControl(value));

    // }

    // if (event.checked) {
    //   interests.push(new FormControl(value));
    // } else {
    //   const i = interests.controls.findIndex(x => x.value === event.source.value);
    //   interests.removeAt(i);
    // }
      return true;
    } else {
      return false;
    }


  }

  getRolefonctionByRoleid(roleid: string) {
    this.logger.debug('Obj reçu pour envoi au service', roleid);
    this.getItemSub = this.rolefonctionService.findbyRoleid(roleid)
      .subscribe(data => {

        let statuscode = data.status;
        this.logger.trace('DATA', data);

        switch (statuscode) {

          case 200: {
            this.rolefonctionbyid = data.body;
            this.roleName = data.body[0].role.rolename;
            this.logger.trace('roleName', this.roleName);

            this.logger.trace('rolefonctionbyid', this.rolefonctionbyid);

            break;
          }



          default: {
            this.snack.open('Une erreur est survenue!', 'OK', { duration: 4000 })

            break;
          }

        }
      },
        error => {
          this.logger.error("error");
          this.logger.error(error);

          switch (error.status) {

            case 401: {
              this.snack.open('Non authorisé', 'OK', { duration: 7000 })
              break;
            }

            default: {
              this.snack.open('Une erreur est survenue!', 'OK', { duration: 4000 })

              break;
            }
          }
        });
  }

  submit() {
    this.logger.trace('Debut enregitrement de profil');
    this.logger.debug('Form value', this.checkboxGroup.value);
    this.objToSend = this.checkboxGroup.value;
    this.role.rolename = this.checkboxGroup.value.rolename;
    this.role.roledepartement = this.checkboxGroup.value.roledepartement;
    this.objToSend.role = this.role;
    this.logger.debug('Obj to send', this.objToSend);
    let u = new Agentstorage();
    u = JSON.parse(localStorage.getItem("curentuser"));
    if (u != null) {
      this.objToSend.userpisteaudit = u.agent.agentemail + " " + u.agent.agentfirstname + " " + u.agent.agentlastname;
      this.logger.debug('++++++++++++++++++++++++++++++++++++++++++++++', this.objToSend);
    }
    if (this.paramRoleid) {
      // Fonction de modification
      this.modifierProfil(this.objToSend);
    } else {
      // Fonction d'enregistrement
      this.enregisterProfil(this.objToSend);
    }


  }

  enregisterProfil(obj: RolefonctionDto) {
    this.logger.debug('Obj reçu pour envoi au service', this.objToSend);
    this.getItemSub = this.rolefonctionService.createRoleFonction(obj)
      .subscribe(data => {

        let statuscode = data.status;
        this.logger.trace('DATA', data);

        switch (statuscode) {

          case 201: {
            this.snack.open('Creation reussi', 'OK', { duration: 4000 })
            // Retour vers la page de liste de profil
            this.route.navigate(['/yolinbo/gestionprofil']);

            break;
          }



          default: {
            this.snack.open('Une erreur est survenue!', 'OK', { duration: 4000 })

            break;
          }

        }
      },
        error => {
          this.logger.error("error");
          this.logger.error(error);

          switch (error.status) {

            case 401: {
              this.snack.open('Choisir au moins une fonctionnalité', 'OK', { duration: 7000 })
              break;
            }

            case 409: {
              this.snack.open('Le profil existe deja, essayé avec un autre nom', 'OK', { duration: 7000 })
              break;
            }

            default: {
              this.snack.open('Une erreur est survenue!', 'OK', { duration: 4000 })

              break;
            }
          }
        });
  }

  modifierProfil(obj: RolefonctionDto) {
    this.logger.debug('Obj reçu pour envoi au service', this.objToSend);
    this.getItemSub = this.rolefonctionService.modifierRoleFonction(obj)
      .subscribe(data => {

        let statuscode = data.status;
        this.logger.trace('DATA', data);

        switch (statuscode) {

          case 201: {
            this.snack.open('Modification reussi', 'OK', { duration: 4000 })
            // Retour vers la page de liste de profil
            this.route.navigate(['/yolinbo/gestionprofil']);

            break;
          }



          default: {
            this.snack.open('Une erreur est survenue!', 'OK', { duration: 4000 })

            break;
          }

        }
      },
        error => {
          this.logger.error("error");
          this.logger.error(error);

          switch (error.status) {

            case 401: {
              this.snack.open('Choisir au moins une fonctionnalité', 'OK', { duration: 7000 })
              break;
            }

            case 409: {
              this.snack.open('Le profil existe deja', 'OK', { duration: 7000 })
              break;
            }

            default: {
              this.snack.open('Une erreur est survenue!', 'OK', { duration: 4000 })

              break;
            }
          }
        });
  }

  onChange(event) {
    console.log(event)

    const interests = <FormArray>this.checkboxGroup.get('fonction') as FormArray;
    console.log(interests)

    if (event.checked) {
      interests.push(new FormControl(event.source.value));
    } else {
      const i = interests.controls.findIndex(x => x.value === event.source.value);
      interests.removeAt(i);
    }
}


  getFonctionGroupbytype() {
    this.getItemSub = this.fonctionService.getFonctionGroupbytype()
      .subscribe(data => {

        switch (data.status) {

          default: {

            this.logger.trace('data', data);

            this.itemsFonctionGroupby = data.body;

            // this.fonction = data.body;
            // const formArray = this.checkboxGroup.get('fonction') as FormArray;
            // this.fonction.forEach(x => formArray.push(new FormControl(false)));

            break;
          }


        }
      },
        error => {
          this.logger.error('error');
          this.logger.error(error);

          switch (error.status) {

            default: {

              break;
            }
          }
        });
  }

}
