import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { NGXLogger } from 'ngx-logger';
import { Histmotif, Historiquecarte } from 'src/app/models/historiquecarte';

@Component({
  selector: 'fury-motif',
  templateUrl: './motif.component.html',
  styleUrls: ['./motif.component.scss']
})
export class MotifComponent implements OnInit {

  motif: Historiquecarte;
  motifForm: FormGroup;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<MotifComponent>
    , private logger: NGXLogger
  ) { }

  ngOnInit() {
    this.buildItem(this.data.payload);
  }

  buildItem(value: any) {
    this.motif = value;

    this.motifForm = new FormGroup({
      historiquecarteid: new FormControl(this.motif.historiquecarteid, [Validators.required]),
      historiquecarterefoperation: new FormControl(this.motif.historiquecarterefoperation, [Validators.required]),
      historiquecartereftransaction: new FormControl(this.motif.historiquecartereftransaction, [Validators.required]),
      historiquecarteaction: new FormControl('', [Validators.required]),
      historiquecartemotif: new FormControl('', [Validators.required]),
      historiquecartemontant: new FormControl('', [Validators.required]),
      historiquecarteagentemail: new FormControl(this.motif.historiquecarteagentemail, [Validators.required])
    });
  }



  submit() {

    console.log(this.motifForm.valid);
    console.log(this.motifForm.value);
    this.dialogRef.close(this.motifForm.value);
  }


}
