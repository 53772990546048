import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { StatclientComponent } from './statclient.component';
import { StatlistecommandeComponent } from './statlistecommande/statlistecommande.component';
import { StatuserComponent } from './statuser/statuser.component';


const routes: Routes = [
    {
        path: '',
        component: StatclientComponent,
        children: [
            /*
          {
            path: '',
            redirectTo: 'primary',
            pathMatch: 'full',
            
          },
          
            {
                path: ':category',
                component: InboxMailListComponent,
                data: {
                    scrollDisabled: true
                }
            },
            {
                path: 'mail/:id',
                component: InboxMailComponent,
                data: {
                    scrollDisabled: true
                }
            }

            */
            /*
                            path: 'statcommande/:clientid',
 */

            {
                path: '',
                redirectTo: 'statcommande',
                pathMatch: 'full',

            },
            {
                path: 'statcommande',
                component: StatlistecommandeComponent,

            },
            {
                path: 'statuser',
                component: StatuserComponent,

            },
        ]

    }
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class StatclientRoutingModule {
}