import { DialogData } from './../clienttype-modifier/clienttype-modifier.component';
import { MatDialogRef, MAT_DIALOG_DATA, MatSnackBar } from '@angular/material';
import { Component, OnInit, Inject } from '@angular/core';
import { GenericserviceService } from 'src/app/services/genericservice.service';

@Component({
  selector: 'fury-deletedialogclientype',
  templateUrl: './deletedialogclientype.component.html',
  styleUrls: ['./deletedialogclientype.component.scss']
})
export class DeletedialogclientypeComponent implements OnInit {

  resp : number = 0 ;


  ngOnInit() {
    this.clienttypeService.type='clienttype';
  }

  constructor(  public dialogRef: MatDialogRef<DeletedialogclientypeComponent>,

    @Inject(MAT_DIALOG_DATA) public data: DialogData, private clienttypeService :GenericserviceService, private snackbar: MatSnackBar) {}


  onNoClick(): void {
    this.dialogRef.close();
  }

  deleteClienttype() {
    console.log('-- Delete clienttype ---  '+  this.data.clienttype.clienttypeid);

    if (this.data.clienttype.clienttypeid) {
      
      this.clienttypeService.delete(this.data.clienttype.clienttypeid).subscribe(
        data => {
          console.log('-- Delete clienttype response ---  '+ data.statusText+' '+ data.status);

          switch (data.status) {
            case 200: {
              this.snackbar.open('Success supprimé' , 'OK', {
                duration: 2000
              });
              this.resp = 200;
              break;
            }
            default:
            this.snackbar.open('Echec suppression, clienttype not found' , 'veuillez rafraichir la page svp', {
              duration: 2000
            

              
            });
            this.resp = 502;
          }

         
      
        }
      );

      return this.resp;
    }

}

}
