import { Component, OnInit, ViewChild } from '@angular/core';
import { Subscription } from 'rxjs';
import { NGXLogger } from 'ngx-logger';
import { Router } from '@angular/router';
import { MatDialog, MatSnackBar, MatDialogRef } from '@angular/material';
import { FormBuilder } from '@angular/forms';
import { ConfirmationService } from 'src/app/confirm/confirmation.service';
import { GestionutilisateurService } from './gestionutilisateur.service';
import { ConfigService } from 'ngx-envconfig';
import { Agentstorage } from 'src/app/models/agentstorage';
import { Rolefonction } from 'src/app/models/rolefonction';
import { VoirhabilitationComponent } from './voirhabilitation/voirhabilitation.component';
import { PopupgestionutilisateurComponent } from './popupgestionutilisateur/popupgestionutilisateur.component';
import { Agent } from 'src/app/models/Agent';
import { Role } from 'src/app/models/Role';
import { PopupupdategestionutilisateurComponent } from './popupupdategestionutilisateur/popupupdategestionutilisateur.component';


import * as XLSX from 'xlsx';
import * as FileSaver from 'file-saver';


const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
const EXCEL_EXTENSION = '.xlsx';


@Component({
  selector: 'fury-gestionutilisateur',
  templateUrl: './gestionutilisateur.component.html',
  styleUrls: ['./gestionutilisateur.component.scss']
})
export class GestionutilisateurComponent implements OnInit {


  disabled = false;
  checked = false;

  public items: Agent[];
  temp = [];
  rows = [];
  columns = [];
  scrollBarHorizontal = (window.innerWidth < 1200);
  public getItemSub: Subscription;

  // Habilitation
  public afficherUtilisateur: boolean;
  public creationUtilisateur: boolean;
  public modificationUtilisateur: boolean;
  public suppressionUtilisateur: boolean;

  public keyAfficherUtilisateur = 'afficher utilisateur';
  public keyCreationUtilisateur = 'creation utilisateur';
  public keyModificationUtilisateur = 'modification utilisateur';
  public keySuppressionUtilisateur = 'suppression utilisateur';

  // expandable
  @ViewChild('myTable') table: any;
  expanded: any = {};
  timeout: any;


  constructor(private logger: NGXLogger,
    private route: Router,
    private dialog: MatDialog,
    public formBuilder: FormBuilder,
    private snackbar: MatSnackBar,
    private confirmService: ConfirmationService,
    private utilisateurService: GestionutilisateurService,
    private fb: FormBuilder,
    private snack: MatSnackBar,

    private configService: ConfigService) { }


  ngOnInit() {
    this.verifierDroit();
    this.getallUser();
  }

  updateFilter(event) {
    const val = event.target.value.toLowerCase();
    var columns = Object.keys(this.temp[0]);
    // Removes last "$$index" from "column"
    columns.splice(columns.length - 1);

    // console.log(columns);
    if (!columns.length)
      return;

    const rows = this.temp.filter(function (d) {
      for (let i = 0; i <= columns.length; i++) {
        let column = columns[i];
        // console.log(d[column]);
        if (d[column] && d[column].toString().toLowerCase().indexOf(val) > -1) {
          return true;
        }
      }
    });

    this.items = rows;
  }


  public verifierDroit() {
    this.logger.trace('BEGIN');
    // this.logger.trace('Clé', this.configService.get('keylocalstorage'));

    let agentOnline: Agentstorage;
    agentOnline = JSON.parse(localStorage.getItem(this.configService.get('keylocalstorage')));
    // this.logger.trace(agentOnline);
    let rolefontion: Rolefonction[] = agentOnline.rolefonction;
    // this.logger.trace('rolefontion', rolefontion);

    for (const key in rolefontion) {
      if (rolefontion.hasOwnProperty(key)) {
        // this.logger.trace('KEY', key);
        const element = rolefontion[key].fonction.fonctionname;
        this.logger.trace('element', element);

        if (element == this.keyAfficherUtilisateur) {
          this.logger.trace('true');
          this.afficherUtilisateur = true;
        }
        if (element == this.keyCreationUtilisateur) {
          this.logger.trace('true');
          this.creationUtilisateur = true;
        }
        if (element == this.keyModificationUtilisateur) {
          this.logger.trace('true');
          this.modificationUtilisateur = true;
        }
        if (element == this.keySuppressionUtilisateur) {
          this.logger.trace('true');
          this.suppressionUtilisateur = true;
        }
      }
    }

  }

  voirhabilitation(data: any = {}) {
    let title = 'Voir les habilitations';
    let dialogRef: MatDialogRef<any> = this.dialog.open(VoirhabilitationComponent, {
      width: '720px',
      disableClose: true,
      data: { title: title, payload: data }
    })
    dialogRef.beforeClose()
      .subscribe(res => {
        if (!res) {
          // If user press cancel
          return;
        }

      })

  }

  openPopUp(data: any = {}, isNew?) {
    let title = isNew ? 'Ajouter un utilisateur' : 'Mettre à jour un utilisateur';
    let dialogRef: MatDialogRef<any> = this.dialog.open(PopupgestionutilisateurComponent, {
      width: '720px',
      disableClose: true,
      data: { title: title, payload: data }
    })
    dialogRef.beforeClose()
      .subscribe(res => {
        if (!res) {
          // If user press cancel
          return;
        }
        if (isNew) {
          this.logger.debug('isNew');
          this.getallUser();
        } else {
          this.logger.debug('isNotNew');
          this.getallUser();
        }
      })
  }


  openPopUpdate(data: any = {}, isNew?) {
    let title = isNew ? 'Ajouter un utilisateur' : 'Mettre à jour un utilisateur';
    let dialogRef: MatDialogRef<any> = this.dialog.open(PopupupdategestionutilisateurComponent, {
      width: '720px',
      disableClose: true,
      data: { title: title, payload: data }
    })
    dialogRef.beforeClose()
      .subscribe(res => {
        if (!res) {
          // If user press cancel
          return;
        }
        if (isNew) {
          this.logger.debug('isNew');
          this.getallUser();
        } else {
          this.logger.debug('isNotNew');
          this.getallUser();
        }
      })
  }

  /*createUser() {
    this.dialog.open(PopupgestionutilisateurComponent).afterClosed().subscribe((res) => {
      console.log(res);

      if (!res) {
        return;
      }

      let agent: Agent = new Agent();
      agent = res;

      this.utilisateurService.createUser(agent).subscribe(data => {

        switch (data.status) {

          case 201: {

            this.logger.trace('data');
            this.logger.trace(data);

            this.getallUser();
            this.snackbar.open('Compte crée avec succes ', 'OK', {
              duration: 10000
            });

            break;
          }

        }
      },
        error => {
          switch (error.status) {

            case 409: {
              this.snackbar.open('Ce compte existe deja ', 'OK', {
                duration: 10000
              });
              break;
            }

            default: {

              this.snackbar.open('Echec !! Une erreur est survenue ', 'OK', {
                duration: 10000
              });
              break;
            }
          }
        });


    });
  }*/

  getallUser() {
    this.getItemSub = this.utilisateurService.getAllUser()
      .subscribe(data => {

        switch (data.status) {

          default: {

            this.logger.trace('data', data);
            let response: any = data;
            let resp1: Agent[] = response;

            this.items = this.temp = data.body;
            // this.dataSource = new MatTableDataSource(this.items);


            break;
          }


        }
      },
        error => {
          console.log('error');
          console.log(error);

          switch (error.status) {

            default: {

              break;
            }
          }
        });
  }

  deleteItem(row) {
    this.confirmService.confirm({
      message: `Voulez vous supprimer ce compte ? : ${row.agentemail}?  `
    })
      .subscribe(res => {
        if (res) {

          this.logger.trace("resultat obtenu ");
          this.logger.trace(row);

          this.utilisateurService.deleteUser(row.agentid).subscribe(data => {

            this.getallUser();
            this.snackbar.open('Success ', 'OK', {
              duration: 10000
            });

          },
            error => {


              this.snackbar.open('Echec !! Une erreur est survenue lors du chargement du fichier', 'OK', {
                duration: 10000
              });

              this.logger.error("erreur retour");
              this.logger.error(error);

            }
          );

        }

      })
  }



  // expandable

  onPage(event) {
    clearTimeout(this.timeout);
    this.timeout = setTimeout(() => {
      // console.log('paged!', event);
    }, 100);
  }

  toggleExpandRow(row) {
    // console.log('Toggled Expand Row!', row);
    this.table.rowDetail.toggleExpandRow(row);
  }

  onDetailToggle(event) {
    // console.log('Detail Toggled', event);
  }


  verifyStatusValue(value: Agent) {
    console.log("############################");
    console.log(value.agentisactive);
    console.log("############################");
    return value.agentisactive;

  }


  updateStatus(value: Agent) {
    console.log("##################################");
    console.log(value);
    value.agentisactive = !value.agentisactive;
    value.agentdatecreation = null;
    //value.agentdatefin = null;
    value.agentdatedebut = null;

    let r: Role = value.role;
    if (r != null) {
      value.roleName = r.rolename;
    }

    console.log(" ################# verifier ################");
    console.log((value.agentdatefin != null));

    if (value.agentdatefin != null) {
      let dt: String = value.agentdatefin;
      dt = dt.replace(" ", "T");
      dt = dt + "Z";
      value.agentdatefin = dt;
    }
    console.log("##############VALUE##############");
    console.log(value);

    this.utilisateurService.updateUser(value)
      .subscribe(data => {
        let statuscode = data.status;

        switch (statuscode) {
          case 200: {
            // Actualisé les données
            this.getallUser();
            this.snack.open('Agent modifié!', 'OK', { duration: 4000 });
            break;
          }
          case 201: {
            // Actualisé les données
            this.getallUser();
            this.snack.open('Agent modifié!', 'OK', { duration: 4000 });
            break;
          }

          default: {
            this.snack.open('Erreur!', 'OK', { duration: 4000 })
            break;
          }

        }

      },
        error => {
          let statuscode = error.status;
          this.logger.error('error', error);

          switch (statuscode) {

            default: {
              this.getallUser();
              this.snack.open('Une erreur est survenue!', 'OK', { duration: 4000 })
              break;
            }
          }
        });


  }















  exportToXls() {
    let itemscsv: any[] = [];


    if (this.items.length > 0) {
      this.items.forEach(function (element) {
        console.log(element);

        var my_json = {
          "Matricule": element.agentmatricule,
          "Email": element.agentemail,
          "Nom Prenom": element.agentfirstname+" "+ element.agentlastname,
          "Profil": element.role.rolename,
          "Date creation " : element.agentdatecreation
        };
        console.log("my_json");
        console.log(my_json);
        itemscsv.push(my_json);
      });
      console.log(itemscsv);

      this.exportAsExcelFile(itemscsv, 'Historique Utilisateur');

    }
  }
  public exportAsExcelFile(json: any[], excelFileName: string): void {
    const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(json);
    const workbook: XLSX.WorkBook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
    const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
    this.saveAsExcelFile(excelBuffer, excelFileName);
  }
  private saveAsExcelFile(buffer: any, fileName: string): void {
    const data: Blob = new Blob([buffer], { type: EXCEL_TYPE });
    FileSaver.saveAs(data, fileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION);
  }

}
