import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpHeaders, HttpEvent, HttpParams, HttpRequest } from '@angular/common/http';
import { ConfigService } from 'ngx-envconfig';
import { Agentstorage } from 'src/app/models/agentstorage';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CreditercarteService {

  private findallhistoriquecarte = this.configService.get('ipMS') + 'historiquecarte/getallhistoriquecarte';

  private creditercarteabonnement = this.configService.get('ipMS') + 'carte/credit';


  authorization: string = " ";
  caissierid: string = " ";


  private url = this.configService.get('ipMS') + 'carte/creditercardbyfile/'

  cassieremail: string = " ";


  constructor(private http: HttpClient, private configService: ConfigService) {
    let u = new Agentstorage();
    u = JSON.parse(localStorage.getItem('curentuser'));

    if (u != null) {
      this.authorization = u.token;
      this.caissierid = u.agent.agentid;
      this.cassieremail = u.agent.agentemail ;
    }
  }





  public crediterCarte(value: any): Observable<HttpResponse<any>> {
    console.log(JSON.stringify(value));
    return this.http.post(this.creditercarteabonnement, JSON.stringify(value), {
      observe: 'response',
      headers: new HttpHeaders({ 'Access-Control-Allow-Origin': '*' })
        .set("content-Type", "application/json")
        .set("Authorization", this.authorization)
    });
  }


  pushFileToStorage(file: File): Observable<HttpEvent<{}>> {

    //this.logger.trace("FileNull " + (file == null));
    let formdata: FormData = new FormData();
    formdata.append('file', file);


    let Params = new HttpParams();
    Params = Params.set('formdata', 'value');

    const req = new HttpRequest('POST', this.url + this.cassieremail , formdata, {
      // const req = new HttpRequest('POST', this.repertoireUrl + '/' + 'add' + '/' + repertoire.repertoirename + '/' + file, {

      reportProgress: true,
      responseType: 'text',

    });
    /*this.logger.trace('REQ');
    this.logger.trace(req);*/
    return this.http.request(req);
  }





}
