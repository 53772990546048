import { Injectable } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material';
import { Observable } from 'rxjs';
import { ConfirmComponent } from './confirm.component';

interface confirmData {
  title?: string,
  message?: string
}


@Injectable({
  providedIn: 'root'
})
export class ConfirmationService {


  constructor(private dialog: MatDialog) { }

  public confirm(data:confirmData = {}): Observable<boolean> {
    data.title = data.title || 'Confirmation';
    data.message = data.message || 'Are you sure?';
    let dialogRef: MatDialogRef<ConfirmComponent>;
    dialogRef = this.dialog.open(ConfirmComponent, {
      width: '380px',
      disableClose: true,
      data: {title: data.title, message: data.message}
    });
    return dialogRef.afterClosed();
  }
}
