import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Client } from 'src/app/models/Agent';
import { GestionclientService } from '../../gestionclient/gestionclient.service';
import { NGXLogger } from 'ngx-logger';
import { MatDialogRef } from '@angular/material';
import { Subject, ReplaySubject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'fury-lierclientca',
  templateUrl: './lierclientca.component.html',
  styleUrls: ['./lierclientca.component.scss']
})
export class LierclientcaComponent implements OnInit {


  linkForm: FormGroup;
  clientid: number;
  selectedClient: any;

  clients: Client[] = [];
  private _onDestroy = new Subject<void>();
  public filteredCarteabonnement: ReplaySubject<Client[]> = new ReplaySubject<Client[]>(1);
  public carteabonnementFilterCtrl: FormControl = new FormControl();

  constructor(private clientServ: GestionclientService, private logger: NGXLogger,
    public dialogRef: MatDialogRef<LierclientcaComponent>) { }

  ngOnInit() {
    this.getAllClient();
    this.carteabonnementFilterCtrl.valueChanges
    .pipe(takeUntil(this._onDestroy))
    .subscribe(() => {
      this.filterCarteabonnement();
    });
    this.linkForm = new FormGroup({
      clientid: new FormControl('', [Validators.required])
    });
  }

  private filterCarteabonnement() {
    if (!this.clients) {
      return;
    }
    // get the search keyword
    let search = this.carteabonnementFilterCtrl.value;
    if (!search) {
      this.filteredCarteabonnement.next(this.clients.slice());
      return;
    } else {
      search = search.toLowerCase();
    }
    // filter the banks
    this.filteredCarteabonnement.next(
      this.clients.filter(bank => bank.clientemail.toLowerCase().indexOf(search) > -1)
    );
  }

  getAllClient() {
    this.clientServ.getData()
      .subscribe(data => {
        switch (data.status) {
          case 200: {
            this.logger.trace('allclients', data);
            this.clients = data.body;
            this.filteredCarteabonnement.next(this.clients.slice());
            break;
          }



          default: {

            this.logger.trace('data', data);
            this.clients = data.body;

            break;
          }


        }
      },
        error => {
          this.logger.trace('error');
          this.logger.error(error);

          switch (error.status) {

            default: {

              break;
            }
          }
        });


  }




  submit() {

    this.dialogRef.close(this.linkForm.value);
  }



}
