import { Component, OnInit } from '@angular/core';
import { MatSnackBar, MatDialog, MatDialogRef } from '@angular/material';
import { ConfigService } from 'ngx-envconfig';
import { NGXLogger } from 'ngx-logger';
import { Subscription } from 'rxjs/internal/Subscription';
import { ConfirmationService } from 'src/app/confirm/confirmation.service';
import { Agentstorage } from 'src/app/models/agentstorage';
import { Decouvert } from 'src/app/models/heurecommande';
import { Rolefonction } from 'src/app/models/rolefonction';
import { AdddecouvertComponent } from './adddecouvert/adddecouvert.component';
import { DecouvertService } from './decouvert.service';

@Component({
  selector: 'fury-decouvert',
  templateUrl: './decouvert.component.html',
  styleUrls: ['./decouvert.component.scss']
})
export class DecouvertComponent implements OnInit {



  getItemSub: Subscription;
  items: Decouvert[];
  scrollBarHorizontal = (window.innerWidth < 1200);
  temp = [];
  rows = [];
  columns = [];


  public afficherDecouvert: boolean;
  public creationDecouvert: boolean;
  public modificationDecouvert: boolean;
  public suppressionDecouvert: boolean;

  public keyAfficherDecouvert= 'afficher decouvert';
  public keyCreationDecouvert = 'ajouter decouvert';
  public keyModificationDecouvert = 'modifier decouvert';
  public keySuppressionDecouvert = 'supprimer decouvert';

  constructor(private logger: NGXLogger, private decouvertService: DecouvertService,
    private snack: MatSnackBar, private confirmService: ConfirmationService,
    private dialog: MatDialog,
    private configService: ConfigService) { }

  ngOnInit() {
    this.verifierDroit();
    this.getall();
  }

  public verifierDroit() {
    this.logger.trace('BEGIN');
    // this.logger.trace('Clé', this.configService.get('keylocalstorage'));

    let agentOnline: Agentstorage;
    agentOnline = JSON.parse(localStorage.getItem(this.configService.get('keylocalstorage')));
    // this.logger.trace(agentOnline);
    let rolefontion: Rolefonction[] = agentOnline.rolefonction;
    // this.logger.trace('rolefontion', rolefontion);

    for (const key in rolefontion) {
      if (rolefontion.hasOwnProperty(key)) {
        // this.logger.trace('KEY', key);
        const element = rolefontion[key].fonction.fonctionname;
        this.logger.trace('element', element);

        if (element == this.keyAfficherDecouvert) {
          this.logger.trace('true');
          this.afficherDecouvert = true;
        }
        if (element == this.keyCreationDecouvert) {
          this.logger.trace('true');
          this.creationDecouvert = true;
        }
        if (element == this.keyModificationDecouvert) {
          this.logger.trace('true');
          this.modificationDecouvert = true;
        }
        if (element == this.keySuppressionDecouvert) {
          this.logger.trace('true');
          this.suppressionDecouvert = true;
        }
      }
    }

  }

  updateFilter(event) {
    const val = event.target.value.toLowerCase();
    var columns = Object.keys(this.temp[0]);
    // Removes last "$$index" from "column"
    columns.splice(columns.length - 1);

    // console.log(columns);
    if (!columns.length)
      return;

    const rows = this.temp.filter(function (d) {
      for (let i = 0; i <= columns.length; i++) {
        let column = columns[i];
        // console.log(d[column]);
        if (d[column] && d[column].toString().toLowerCase().indexOf(val) > -1) {
          return true;
        }
      }
    });

    this.items = rows;
  }



  getall() {
    this.getItemSub = this.decouvertService.getAll()
      .subscribe(data => {

        let statuscode = data.status;
        this.logger.trace('DATA', data);

        switch (statuscode) {

          case 200: {
            this.items = data.body;
            this.temp = data.body;
            break;
          }

          case 204: {
            this.snack.open('Aucun decouvert disponible', 'OK', { duration: 4000 })
            break;
          }

          default: {
            this.snack.open('Une erreur est survenue!', 'OK', { duration: 4000 })

            break;
          }

        }
      },
        error => {
          this.logger.error("error");
          this.logger.error(error);

          switch (error.status) {

            default: {
              this.snack.open('Une erreur est survenue!', 'OK', { duration: 4000 })

              break;
            }
          }
        });
  }


  openPopUp(data: any = {}, isNew?) {
    let title = isNew ? 'Ajouter un decouvert' : 'Mettre à jour un Decouvert';
    let dialogRef: MatDialogRef<any> = this.dialog.open(AdddecouvertComponent, {
      width: '720px',
      disableClose: false,
      data: { title: title, payload: data }
    })
    dialogRef.beforeClose()
      .subscribe(res => {
        if (!res) {
          // If user press cancel
          return;
        }

        let url: string = "parameter/decouvert/updatedecouvert";

        if (isNew) {
          url = "parameter/decouvert/adddecouvert"
        }

        this.logger.trace("URL : " + url);

        this.decouvertService.post(res, url)
          .subscribe(data => {
            let statuscode = data.status;

            switch (statuscode) {
              case 200: {
                this.getall();
                this.snack.open('Succes !', 'OK', { duration: 4000 })
                break;
              }

            }

          },
            error => {
              let statuscode = error.status;
              switch (statuscode) {

                case 423: {
                  this.snack.open('Montant incorrecte , veuillez respecter le plafond des decouverts', 'OK', { duration: 4000 })
                  break;
                }
                case 409: {
                  this.snack.open('Ce decouvert existe deja', 'OK', { duration: 4000 })
                  break;
                }

                default: {
                  this.snack.open('Une erreur est survenue!', 'OK', { duration: 4000 })
                  break;
                }
              }

            });
      })
  }


  deleteItem(row) {
    this.confirmService.confirm({ message: `Suppression ${row.decouvertlibelle}?` })
      .subscribe(res => {
        if (res) {
          this.decouvertService.post(row, "parameter/decouvert/deletedecouvert")
            .subscribe(data => {
              let statuscode = data.status;
              switch (statuscode) {
                case 200: {
                  // Actualisé les données
                  this.getall();
                  this.snack.open('Decouvert Supprimé!', 'OK', { duration: 4000 })
                  break;
                }
             
                default: {
                  this.snack.open('Une erreur est survenue!', 'OK', { duration: 4000 })
                  break;
                }
              }

            },
              error => {
                let statuscode = error.status;
                switch (statuscode) {
                
                  default: {
                    this.snack.open('Une erreur est survenue!', 'OK', { duration: 4000 })
                    break;
                  }
                }

              });
        }

      })


  }


}
