import { DatePipe } from '@angular/common';
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MatDialog, MatPaginator, MatSnackBar, MatTableDataSource } from '@angular/material';
import { ConfigService } from 'ngx-envconfig';
import { NGXLogger } from 'ngx-logger';
import { NgxPermissionsService } from 'ngx-permissions';
import { Subscription } from 'rxjs/internal/Subscription';
import { ConfirmationService } from 'src/app/confirm/confirmation.service';
import { Agentstorage } from 'src/app/models/agentstorage';
import { ClientCorporate } from 'src/app/models/Clienttype';
import { Rolefonction } from 'src/app/models/rolefonction';
import { ApploaderService } from 'src/app/services/apploader/apploader.service';
import { AddcorporateComponent } from './addcorporate/addcorporate.component';
import { GestioncorporateService } from './gestioncorporate.service';
import { UpdatecorporateComponent } from './updatecorporate/updatecorporate.component';

import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';

const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
const EXCEL_EXTENSION = '.xlsx';

import * as jsPDF from 'jspdf';
import 'jspdf-autotable';
import { UserOptions } from 'jspdf-autotable';
import { Router } from '@angular/router';

interface jsPDFWithPlugin extends jsPDF {
  autoTable: (options: UserOptions) => jsPDF;
}

@Component({
  selector: 'fury-gestioncorporate',
  templateUrl: './gestioncorporate.component.html',
  styleUrls: ['./gestioncorporate.component.scss']
})
export class GestioncorporateComponent implements OnInit {


  displayedColumns: string[] = ['clienttypeadressepostale', 'clienttypeemail', 'clienttypelocalisation',
  'clienttyperaisonsocial', 'clienttypestatutjuridique', 'clienttypetelephone', 'actions'];
  dataSource = new MatTableDataSource<ClientCorporate>([]);
  @ViewChild(MatPaginator) paginator: MatPaginator;
  public getItemSub: Subscription;
  client: ClientCorporate[] = [];


  public afficherClientCorporate: boolean;
  public creationClientCorporate: boolean;
  public modificationClientCorporate: boolean;
  public suppressionClientCorporate: boolean;

  public keyAfficherClientCorporate = 'afficher client corporate';
  public keyCreationClientCorporate = 'ajouter client corporate';
  public keyModificationClientCorporate = 'modifier client corporate';
  public keySuppressionClientCorporate = 'supprimer client corporate';

  constructor(private dialog: MatDialog, public formBuilder: FormBuilder,
    private snackbar: MatSnackBar, private datePipe: DatePipe,
    private configService: ConfigService,
    private confirmService: ConfirmationService, private loader: ApploaderService,
    private permissionsService: NgxPermissionsService, private logger: NGXLogger,private router: Router,
    private pointservice: GestioncorporateService) { }

  ngOnInit() {
    this.verifierDroit();
    this.getAllCorporate();
  }


  showHistorique(value : ClientCorporate){
    localStorage.setItem('corporate_transaction', JSON.stringify(value));
    this.router.navigate(['/yolinbo/historiquecorporate']);
  }


  getAllCorporate() {
    this.getItemSub = this.pointservice.get('clienttype')
      .subscribe(data => {
        const statuscode = data.status;
        this.logger.trace('DATA', data);
        switch (statuscode) {
          case 200: {
            this.client = data.body;
            this.dataSource.data = this.client;
            this.dataSource.paginator = this.paginator;
            // this.temp = data.body;
            break;
          }

          case 204: {
            // this.snack.open('Aucune TVA disponible', 'OK', { duration: 4000 })
            break;
          }

          default: {
            // this.snack.open('Une erreur est survenue!', 'OK', { duration: 4000 })

            break;
          }

        }
      },
        error => {
          this.logger.error('error');
          this.logger.error(error);

          switch (error.status) {

            default: {

              //    this.snack.open('Une erreur est survenue!', 'OK', { duration: 4000 })

              break;
            }
          }
        });
  }

  createClientCorporate() {
    this.dialog.open(AddcorporateComponent, { width: '720px' }).afterClosed().subscribe((res) => {
      console.log(res);

      if (!res) {
        return;
      }

      const request: any = res;
      let u = new Agentstorage();
      u = JSON.parse(localStorage.getItem('curentuser'));
      if (u != null) {
        request.userpisteaudit = u.agent.agentemail + ' ' + u.agent.agentfirstname + ' ' + u.agent.agentlastname;
      }
      this.pointservice.post(request, 'clienttype').subscribe(data => {
        this.logger.trace('DATA', data);
        this.getAllCorporate();
        this.loader.close();
        this.snackbar.open('Client corporate crée avec succes ', 'OK', {
          duration: 10000
        });
      },
        error => {
          this.logger.error('ERREUR', error);
          switch (error.status) {

            case 409: {
              // this.getallFood();
              this.loader.close();
              this.snackbar.open('Ce client corporate existe deja ', 'OK', {
                duration: 10000
              });
              break;
            }

            default: {
              this.loader.close();

              this.snackbar.open('Echec !! Une erreur est survenue ', 'OK', {
                duration: 10000
              });
              break;
            }
          }
        });
    });
  }

  deleteItem(row) {
    this.confirmService.confirm({
      message: `Voulez vous supprimer le client corporate : ${row.clienttyperaisonsocial}?  `
    })
      .subscribe(res => {
        if (res) {
          let u = new Agentstorage();
          u = JSON.parse(localStorage.getItem('curentuser'));
          if (u != null) {
            row.userpisteaudit = u.agent.agentemail + ' ' + u.agent.agentfirstname + ' ' + u.agent.agentlastname;
          }

          this.pointservice.delete(`clienttype/${row.clienttypeid}`, ).subscribe(data => {
            console.log(data);
            this.getAllCorporate();
            this.snackbar.open('Success ', 'OK', {
              duration: 10000
            });

          },
            error => {

              this.snackbar.open('Echec !! Une erreur est survenue ', 'OK', {
                duration: 10000
              });
              console.log('erreur retour');
              console.log(error);
            }
          );
        }
      });
  }




  openPopUp(value) {
    this.dialog.open(UpdatecorporateComponent,
      {
        // disableClose: true,
        width: '720px',
        data: { title: '', payload: value }
      }
    ).afterClosed().subscribe((res) => {
      console.log(res);

      if (!res) {
        return;
      }

      const request: any = res;

      let u = new Agentstorage();
      u = JSON.parse(localStorage.getItem('curentuser'));
      if (u != null) {
        request.userpisteaudit = u.agent.agentemail + ' ' + u.agent.agentfirstname + ' ' + u.agent.agentlastname;
      }

      this.pointservice.put(request, 'clienttype').subscribe(data => {
        this.logger.trace('DATA', data);


        switch (data.status) {

          case 200: {

            console.log('data');
            console.log(data);

            this.getAllCorporate();
            this.loader.close();
            this.snackbar.open('Client corporate mis à jour avec succès ', 'OK', {
              duration: 10000
            });

            break;
          }

        }
      },
        error => {
          this.logger.error('ERREUR', error);
          switch (error.status) {

            case 409: {
              // this.getallFood();
              this.loader.close();
              this.snackbar.open('Ce client corporate existe déjà ', 'OK', {
                duration: 10000
              });
              break;
            }

            default: {
              this.loader.close();

              this.snackbar.open('Echec !! Une erreur est survenue ', 'OK', {
                duration: 10000
              });
              break;
            }
          }
        });

    });
  }

  public exportCsv() {

    let itemscsv: any[] = [];

    this.client.forEach(function (element) {
      console.log(element);
      var my_json = {
        "Date de creation": element.clienttypedatecreation,
        "Raison sociale": element.clienttyperaisonsocial,
        "Email": element.clienttypeemail,
        "Localisation": element.clienttypelocalisation,
        "Adresse postale": element.clienttypeadressepostale,
        "Contact ": element.clienttypetelephone,
        "Statut juridique": element.clienttypestatutjuridique,
      };
      itemscsv.push(my_json);
    });
    this.exportAsExcelFile(itemscsv, 'Liste corporate');
  }


  public exportAsExcelFile(json: any[], excelFileName: string): void {
    const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(json);
    const workbook: XLSX.WorkBook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
    const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
    this.saveAsExcelFile(excelBuffer, excelFileName);
  }
  private saveAsExcelFile(buffer: any, fileName: string): void {
    const data: Blob = new Blob([buffer], { type: EXCEL_TYPE });
    FileSaver.saveAs(data, fileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION);
  }



  exportToPdf() {

    let itemscsv: any[] = [];
    let itemslocal: any[] = this.client;
    if (this.client.length > 0) {
      this.client.forEach(function (element) {
        var my_json = {
          "Date de creation": element.clienttypedatecreation,
          "Raison sociale": element.clienttyperaisonsocial,
          "Email": element.clienttypeemail,
          "Localisation": element.clienttypelocalisation,
          "Adresse postale": element.clienttypeadressepostale,
          "Contact ": element.clienttypetelephone,
          "Statut juridique": element.clienttypestatutjuridique,
        };
        itemscsv.push(my_json);
      });
    }
    var columns = [
      { title: "Date de creation", dataKey: "Date de creation" },
      { title: "Raison sociale", dataKey: "Raison sociale" },
      { title: "Email", dataKey: "Email" },
      { title: "Localisation", dataKey: "Localisation" },
      { title: "Adresse postale", dataKey: "Adresse postale" },
      { title: "Contact", dataKey: "Contact" },
      { title: "Statut juridique", dataKey: "Statut juridique" }
    ];
    var rows = itemscsv;
    const doc = new jsPDF('portrait', 'px', 'a4') as jsPDFWithPlugin;
    doc.autoTable({
      body: rows,
      columns: columns,
      didParseCell: function (data) {
        if (data.column.raw.displayProperty) {
          var prop = data.column.raw.displayProperty;
          var text = data.cell.raw[prop];
          if (text && text.length > 0) {
            data.cell.text = text;
          }
        }
      }
    });
    doc.save('liste-corporate.pdf');
  }


  public verifierDroit() {
    this.logger.trace('BEGIN');
    // this.logger.trace('Clé', this.configService.get('keylocalstorage'));

    let agentOnline: Agentstorage;
    agentOnline = JSON.parse(localStorage.getItem(this.configService.get('keylocalstorage')));
    // this.logger.trace(agentOnline);
    const rolefontion: Rolefonction[] = agentOnline.rolefonction;
    // this.logger.trace('rolefontion', rolefontion);

    for (const key in rolefontion) {
      if (rolefontion.hasOwnProperty(key)) {
        // this.logger.trace('KEY', key);
        const element = rolefontion[key].fonction.fonctionname;
        this.logger.trace('element', element);

        if (element === this.keyAfficherClientCorporate) {
          this.logger.trace('true');
          this.afficherClientCorporate = true;
        }
        if (element === this.keyCreationClientCorporate) {
          this.logger.trace('true');
          this.creationClientCorporate = true;
        }
        if (element === this.keyModificationClientCorporate) {
          this.logger.trace('true');
          this.modificationClientCorporate = true;
        }
        if (element === this.keySuppressionClientCorporate) {
          this.logger.trace('true');
          this.suppressionClientCorporate = true;
        }
      }
    }

  }

}
