import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DecouvertComponent } from './decouvert.component';
import { AdddecouvertComponent } from './adddecouvert/adddecouvert.component';
import { DeletedecouvertComponent } from './deletedecouvert/deletedecouvert.component';
import { ReactiveFormsModule } from '@angular/forms';
import { MatChipsModule } from '@angular/material';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { FurySharedModule } from 'src/@fury/fury-shared.module';
import { BreadcrumbsModule } from 'src/@fury/shared/breadcrumbs/breadcrumbs.module';
import { FuryCardModule } from 'src/@fury/shared/card/card.module';
import { MaterialModule } from 'src/@fury/shared/material-components.module';

@NgModule({
  declarations: [DecouvertComponent, AdddecouvertComponent, DeletedecouvertComponent],
  entryComponents: [AdddecouvertComponent, DeletedecouvertComponent],
  imports: [
    CommonModule,
    
    ReactiveFormsModule,
    MaterialModule,
    NgxDatatableModule,

    MatChipsModule,
    BreadcrumbsModule,
    FuryCardModule,
    MatChipsModule,
    FurySharedModule,
  ]
})
export class DecouvertModule { }
