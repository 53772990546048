import { Component, OnInit, Inject } from '@angular/core';
import { Carteabonnement } from 'src/app/models/carteabonnement';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { CartestatusService } from '../cartestatus.service';
import { CarteabonnementService } from '../../carteabonnement/carteabonnement.service';

@Component({
  selector: 'fury-changestatus',
  templateUrl: './changestatus.component.html',
  styleUrls: ['./changestatus.component.scss']
})
export class ChangestatusComponent implements OnInit {

  items: Carteabonnement[] = [];

  statusForm: FormGroup;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<ChangestatusComponent>,
    private carteserv: CarteabonnementService) {
  }

  ngOnInit() {

    //this.getAllCarte();

    this.buildItemForm(this.data.payload);



  }

  buildItemForm(item) {

    this.statusForm = new FormGroup({

      statutblocageid: new FormControl(item.statutblocageid, [Validators.required]),
      carteabonnementid: new FormControl(item.carteabonnement.carteabonnementid, [Validators.required]),
      carteabonnementcodereference: new FormControl(item.carteabonnement.carteabonnementcodereference, [Validators.required]),
      status: new FormControl('enabled', [Validators.required]),
      statutblocagemotif: new FormControl(item.statutblocagemotif, [Validators.required]),
      statutblocageoperation: new FormControl('deblocage', [Validators.required])
    });

    console.log(this.statusForm.controls);


  }





  getAllCarte() {
    this.carteserv.getAllCarteabonnement()
      .subscribe(data => {
        switch (data.status) {
          case 200: {
            this.items = data.body;
            break;
          }

        }
      },
        error => {


          switch (error.status) {

            default: {
              /*
              this.snackbar.open('Un erreur est survenue', 'OK', {
                duration: 10000
              });
              */
              break;
            }
          }
        });
  }


  submit() {
    this.dialogRef.close(this.statusForm.value);
  }



}
