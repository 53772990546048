import { ConfigService } from 'ngx-envconfig';
import { Injectable } from '@angular/core';
import { Agentstorage } from '../../models/agentstorage';
import { HttpClient, HttpResponse, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { text } from '@angular/core/src/render3';
@Injectable({
  providedIn: 'root'
})
export class CartestatusService {

  private findallstatusblocage = this.configService.get('ipMS') + 'statusblocage/getallstatutblocage';

  private updatestatusblocage = this.configService.get('ipMS') + 'statusblocage/'


  authorization: string = " ";
  caissierid: string = " ";


  constructor(private http: HttpClient, private configService: ConfigService) {
    let u = new Agentstorage();
    u = JSON.parse(localStorage.getItem('curentuser'));

    if (u != null) {
      this.authorization = u.token;
      this.caissierid = u.agent.agentid;
    }
  }

  getallstatusblocage(): Observable<HttpResponse<any>> {
    return this.http.get(this.findallstatusblocage,
      {
        observe: 'response',
        headers: new HttpHeaders({ 'Access-Control-Allow-Origin': '*' })
          .set("content-Type", "application/json")
          .set("Authorization", this.authorization)
      });
  }


  public statusCarte(value: any, path: any): Observable<HttpResponse<any>> {
    console.log(JSON.stringify(value));
    console.log("path-url :  " + this.updatestatusblocage + path);
    return this.http.post(this.updatestatusblocage + path, JSON.stringify(value), {
      observe: 'response',
      responseType: 'text',
      headers: new HttpHeaders({ 'Access-Control-Allow-Origin': '*' })
        .set("content-Type", "application/json")
        .set("Authorization", this.authorization)
    });
  }


}
