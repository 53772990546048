export class FormuleAbonnement {
  formuleid: string;
  formuleisdeleted: boolean;
  formuledescription: string;
  formuleduree: number;
  formuleisprecompte: boolean;
  formulelibelle: string;
  formulemontant: string;
  formulemontantdecouvert: string;
  formulenom: string;
  userpisteaudit: string;
  lsAbattement: string[] ;
  public constructor() {
  }
}

export class Formule {
  formuleid: string;
  formulenom: string;
  formulemontant: number;
  formulemontantdecouvert: number;
  formuleduree: number;
  formulelibelle: string;
  formuledescription: string;
  formuleisprecompte: boolean;
  formuleisdeleted: boolean;
  formuledatecreation: string;
  formulestatus: string;
}

export class Abattement {
  abattementid: string;
  abattementdate: string;
  abattementdescription: string;
  abattementenable: boolean;
  abattementlibelle: string;
  abattementmontant: number;
  abattementstatut: string;
  abattementtype: string;
  abattementtypecarte: string;
  abattementtypecartelib: string;
  abattementtypemontant: string;
  abattementmontantarticle: number;
}

export class Formuleabat  {
  formuleabatid: string;
  formuleabatcode: string;
  formuleabatdate: string;
  formuleabatlibelle: string;
  abattement: Abattement;
  formule: Formule;
}

export class InitDataFormuleAbat{
  formule : Formule;
  formuleabat: Formuleabat[];
  lsAbattement: string[] ;

}