import { Component, Inject, OnInit } from '@angular/core';
import { Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { NGXLogger } from 'ngx-logger';
import { Subscription } from 'rxjs';

@Component({
  selector: 'fury-show',
  templateUrl: './show.component.html',
  styleUrls: ['./show.component.scss']
})
export class ShowComponent implements OnInit {


   
  getItemSub: Subscription;

  selectedTypecard : any ;
  categorieSelected : any ;


  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<ShowComponent>,
    private logger: NGXLogger) { }

  ngOnInit() {
    this.buildItemForm(this.data.payload);
  }

  buildItemForm(item){
    this.categorieSelected = item ;
    this.logger.trace( this.categorieSelected);
    //this.selectedTypecard  = item.clienttype.clienttypeid ;

    /*
    this.itemForm = this.fb.group({
      categoriecorpoid: [ categorie.categoriecorpoid || '', [Validators.required]],
      categoriecorpolibelle: [ categorie.categoriecorpolibelle || '', [Validators.required]],
      categoriecorpodescription: [categorie.categoriecorpodescription ||  ''],
      clienttype: [ this.selectedTypecard ||'', [Validators.required]],
    });
    */
  }

}
