import { HttpClient, HttpResponse, HttpHeaders, HttpEvent, HttpParams, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ConfigService } from 'ngx-envconfig';
import { Observable } from 'rxjs';
import { Agentstorage } from 'src/app/models/agentstorage';

@Injectable({
  providedIn: 'root'
})
export class AbonnementService {

  private url = this.configService.get('ipMS');

  authorization: string = " ";
  caissierid: string = " ";


  constructor(private http: HttpClient, private configService: ConfigService) {
    let u = new Agentstorage();
    u = JSON.parse(localStorage.getItem('curentuser'));

    if (u != null) {
      this.authorization = u.token;
      this.caissierid = u.agent.agentid;

      console.log("authorization == " + this.authorization);
    }
  }

  /*
  public getAllCommandes(value: any): Observable<HttpResponse<any>> {
    console.log(value);
    return this.http.post(this.getallcomandes, JSON.stringify(value), {
      observe: 'response',
      headers: new HttpHeaders({ 'Access-Control-Allow-Origin': '*' })
        .set("content-Type", "application/json")
        .set('Authorization', this.authorization)
    });
  }
  */


  getAllabonnement(redirection: string): Observable<HttpResponse<any>> {
    return this.http.get(this.url + redirection,
      {
        observe: 'response',
        headers: new HttpHeaders({ 'Access-Control-Allow-Origin': '*' })
          .set("content-Type", "application/json")
          .set("Authorization", this.authorization)
      });
  }

  public post(path: string, value: any): Observable<HttpResponse<any>> {
    console.log(value);
    return this.http.post(this.url + '' + path, JSON.stringify(value), {
      observe: 'response',
      headers: new HttpHeaders({ 'Access-Control-Allow-Origin': '*' })
        .set("content-Type", "application/json")
        .set('Authorization', this.authorization)
    });
  }

  pushFileToStorage(file: File, agentemail: string): Observable<HttpEvent<{}>> {

    //this.logger.trace("FileNull " + (file == null));
    let formdata: FormData = new FormData();
    formdata.append('file', file);

    let Params = new HttpParams();
    Params = Params.set('formdata', 'value');

    const req = new HttpRequest('POST', this.url + 'carte/loadprecomptefile/' + agentemail, formdata, {
      reportProgress: true,
      responseType: 'text',
    });

    return this.http.request(req);
  }

  //''

}
