import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { NGXLogger } from 'ngx-logger';
import { Agentstorage } from 'src/app/models/agentstorage';

@Component({
  selector: 'fury-loadfile',
  templateUrl: './loadfile.component.html',
  styleUrls: ['./loadfile.component.scss']
})
export class LoadfileComponent implements OnInit {

  fileSelected: File;


  constructor(@Inject(MAT_DIALOG_DATA) public defaults: any,
    public dialogRef: MatDialogRef<LoadfileComponent>,
    private logger: NGXLogger) { }

  ngOnInit() {
  }

  public fileChangeEvent($event) {
    console.log('FILECHANGE');

    this.fileSelected = $event.target.files[0];
    let path = URL.createObjectURL($event.target.files[0]);
    console.log('PATH');
    console.log(path);

    const constFileSelected: File = $event.target.files[0];
    console.log(constFileSelected);
    console.log('FILENAME');
    console.log(constFileSelected.name);
    console.log(constFileSelected.type);
    console.log(constFileSelected.size);
    console.log(constFileSelected.lastModified);

    // Fonction pour afficher le contenu du fichier
    // this.check(path, constFileSelected.type);
  }


  submit() {
    
    let value: any = {};
    value.file = this.fileSelected;
    let u = new Agentstorage();
    u = JSON.parse(localStorage.getItem('curentuser'));
    if (u != null) {
      value.agentemail = u.agent.agentemail;
    }
    this.dialogRef.close(value);
    
  }

}
