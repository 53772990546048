import { Component, OnInit, Inject } from '@angular/core';
import { Subscription } from 'rxjs';
import { Categories } from 'src/app/models/categories';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { MenuService } from '../menu.service';
import { NGXLogger } from 'ngx-logger';
import { Heurecommande } from 'src/app/models/heurecommande';
import { Tva } from 'src/app/models/tva';
import { TvaService } from '../../tva/tva.service';
import { HeureService } from '../../heurecmd/heure.service';
import { PointventeService } from '../../gestionpointvente/pointvente.service';
import { Pointvente } from 'src/app/models/pointvente';

@Component({
  selector: 'fury-updatemenu',
  templateUrl: './updatemenu.component.html',
  styleUrls: ['./updatemenu.component.scss']
})
export class UpdatemenuComponent implements OnInit {

  selectedFiles: FileList;
  currentFileUpload: File;


  verifyremise: boolean = false;
  verifyremisemontant: any = 0;


  public getItemSub: Subscription;
  itemCategories: Categories[] = [];

  heurecmd: Heurecommande[] = [];
  tva: Tva[] = [];

  p: Heurecommande;
  pointvente: Pointvente[] = [];

  validite : any[] = ['permanente','date specifique'];
  checkdate : boolean = true ;

  form: FormGroup;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<UpdatemenuComponent>,
    private fb: FormBuilder, private menuservice: MenuService,
    private tvaservice: TvaService,
    private heureservice: HeureService,
    private pointventeservice: PointventeService,
    private logger: NGXLogger) {
  }


  ngOnInit() {
    this.buildItemForm(this.data.payload);
    this.getallCategories();
    this.getallHeurecmd();
    this.getallTva();
    this.getallPointvente();

  }


  buildItemForm(item) {
    this.logger.trace('ITEM', item);
    this.logger.trace('ITEM', item.foodname, item.foodprice);

    if (item.foodremise) {
      this.verifyremise = item.foodremise;
    }

    this.form = this.fb.group({
      foodname: [item.foodname, Validators.compose([Validators.required])],
      fooddescription: [item.fooddescription, Validators.compose([Validators.required])],
      foodprice: [item.foodprice, Validators.compose([Validators.required])],
      //categoriename: [item.categoriename, Validators.compose([Validators.required])],
      categoriename: [item.categoriename, Validators.compose([Validators.required])],
      fooddatecreation: [item.fooddatecreation],
      foodid: [item.foodid, Validators.compose([Validators.required])],
      foodstockdisponible: [item.foodstockdisponible],
      foodtypekey: [ item.foodtypekey , Validators.compose([Validators.required])],
      foodtype: [item.foodtype , Validators.compose([Validators.required])],
      foodpicture: [item.foodpicture],
      foodstockalerte: [item.foodstockalerte, Validators.compose([Validators.required])],
      fooddatemenu: [item.fooddatemenu, Validators.compose([Validators.required])],
      heurecommandeid: [item.heurecommande.heurecommandeid, Validators.compose([Validators.required])],
      tvaid: [item.tva.tvaid, Validators.compose([Validators.required])],
      pointventeid: [item.pointvente.pointventeid, Validators.compose([Validators.required])],
      foodverify: new FormControl(item.foodverify),
      foodremise: new FormControl(item.foodremise),
      duree: [ item.duree || '', Validators.compose([Validators.required])],
      foodmontantremise: [item.foodmontantremise]
    });


    this.form.get('foodtype').valueChanges.subscribe(value => {
      let validity : string = this.form.get('foodtype').value;
       this.logger.info("PARAMETRE CHANGE ----> " + validity);
       if (validity==="permanente") {
         this.form.patchValue({ foodtypekey: "food.key.perm" });
         this.form.patchValue({ fooddatemenu: null });
         this.form.controls["fooddatemenu"].clearValidators();
         this.form.controls["fooddatemenu"].updateValueAndValidity();
         this.checkdate=false ;
       } else if (validity==="date specifique") {
         this.form.patchValue({ foodtypekey: "food.key.date" });
         this.form.controls["fooddatemenu"].setValidators(Validators.required);
         this.form.controls["fooddatemenu"].updateValueAndValidity();
         this.checkdate=true ;
       }
       console.log(this.form.value);
     })

    this.logger.trace('FROM', this.form.value);



    this.form.get('foodremise').valueChanges.subscribe(value => {
      this.verifyremise = this.form.get('foodremise').value;
      this.logger.info("PARAMETRE CHANGE ----> " + this.verifyremise);

      if (this.verifyremise) {
        this.form.controls['foodmontantremise'].setValidators([Validators.required])
      } else {
        this.form.patchValue({ foodmontantremise: item.foodmontantremise });
      }
    })

  }



  submit() {
    this.logger.trace('SEND', this.form.value);
    this.dialogRef.close(this.form.value);
    this.form.disabled;
  }



  getallCategories() {
    this.getItemSub = this.menuservice.getAllCategories()
      .subscribe(data => {

        let responseany: any = data;

        console.log(responseany);

        switch (data.status) {

          default: {

            let response: any = data.body;
            this.itemCategories = response;
            break;
          }

        }
      },
        error => {
          console.log("error");
          console.log(error);

          switch (error.status) {

            default: {

              break;
            }
          }
        });
  }


  getallHeurecmd() {
    this.getItemSub = this.heureservice.get("heurecommande/getall")
      .subscribe(data => {

        let statuscode = data.status;
        this.logger.trace('DATA', data);

        switch (statuscode) {

          case 200: {
            this.heurecmd = data.body;
            break;
          }

          case 204: {
            //this.snack.open('Aucune Heure disponible', 'OK', { duration: 4000 })
            break;
          }

          default: {
            //  this.snack.open('Une erreur est survenue!', 'OK', { duration: 4000 })

            break;
          }

        }
      },
        error => {
          this.logger.error("error");
          this.logger.error(error);

          switch (error.status) {

            default: {
              //  this.snack.open('Une erreur est survenue!', 'OK', { duration: 4000 })

              break;
            }
          }
        });
  }

  getallTva() {
    this.getItemSub = this.tvaservice.get("tva/getall")
      .subscribe(data => {

        let statuscode = data.status;
        this.logger.trace('DATA', data);

        switch (statuscode) {

          case 200: {
            this.tva = data.body;
            //this.temp = data.body;
            break;
          }

          case 204: {
            //this.snack.open('Aucune TVA disponible', 'OK', { duration: 4000 })
            break;
          }

          default: {
            // this.snack.open('Une erreur est survenue!', 'OK', { duration: 4000 })

            break;
          }

        }
      },
        error => {
          this.logger.error("error");
          this.logger.error(error);

          switch (error.status) {

            default: {

              //    this.snack.open('Une erreur est survenue!', 'OK', { duration: 4000 })

              break;
            }
          }
        });
  }




  getallPointvente() {
    this.getItemSub = this.pointventeservice.get("pointvente/getall")
      .subscribe(data => {

        let statuscode = data.status;
        this.logger.trace('DATA', data);

        switch (statuscode) {

          case 200: {
            this.pointvente = data.body;
            //this.temp = data.body;
            break;
          }

          case 204: {
            //this.snack.open('Aucune TVA disponible', 'OK', { duration: 4000 })
            break;
          }

          default: {
            // this.snack.open('Une erreur est survenue!', 'OK', { duration: 4000 })

            break;
          }

        }
      },
        error => {
          this.logger.error("error");
          this.logger.error(error);

          switch (error.status) {

            default: {

              //    this.snack.open('Une erreur est survenue!', 'OK', { duration: 4000 })

              break;
            }
          }
        });
  }

}
